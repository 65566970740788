import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardBody from "components/Card/CardBody.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Search, Close, Save } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

import RichTextEditor, { getTextAlignClassName, getTextAlignStyles,  getTextAlignBlockMetadata, } from 'react-rte';
// var RichTextEditor = require('react-rte');

const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_ALIGNMENT_BUTTONS",
    // "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Inline Title", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    { label: "Align Left", style: "ALIGN_LEFT" },
    { label: "Align Center", style: "ALIGN_CENTER" },
    { label: "Align Right", style: "ALIGN_RIGHT" },
    { label: "Align Justify", style: "ALIGN_JUSTIFY" },
  ],
};
class CatUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      // Plantilla: RichTextEditor.createValueFromString("<p><b>jutta</b> tesche</p>","html"),
      Plantilla: RichTextEditor.createEmptyValue(),
      PlantillaHTML: "",
      Nombre: "",
      ListaConstancias: [],
      ListaConstanciasTemp: [],
      ListaModulos: [],
      Configuracion: {},
      TipoReglamento: "General",
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      showEditarUsuario: false,
      Total: 0,
      TotalSeleccionado: 0,
      Titulo: "Registrar un nuevo Usuario",
      IdNuevo: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    this.ObtenerConfiguracion();
  }

  async ObtenerConfiguracion() {
    this.setState({ cargando: true});
      await fetch(Globales.Url + "Configuracion/Configuracion/ObtenerConfiguracion", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({}),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data.Configuracion)
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje,colorNotificacion: "danger",cargando: false});
            this.showNotification();
          } else {
            switch (this.state.TipoReglamento) {
              case "General":
                if (data.Configuracion.Reglamento !== null && data.Configuracion.Reglamento !== undefined) {
                  this.setState({
                    Configuracion: data.Configuracion,
                    Plantilla: RichTextEditor.createValueFromString(data.Configuracion.Reglamento, "html", { customBlockFn: getTextAlignBlockMetadata, }),
                    PlantillaHTML: data.Configuracion.Reglamento,
                    cargando: false
                  });
                }
                else {
                  this.setState({ Configuracion: data.Configuracion, cargando: false, mensaje: "No hay reglamento registrado", colorNotificacion: "warning", Plantilla: RichTextEditor.createEmptyValue(), PlantillaHTML: "" });
                  this.showNotification();
                }
                break;
              case "Descuentos":
                if (data.Configuracion.ReglamentoDescuentos !== null && data.Configuracion.ReglamentoDescuentos !== undefined) {
                  this.setState({
                    Configuracion: data.Configuracion,
                    Plantilla: RichTextEditor.createValueFromString(data.Configuracion.ReglamentoDescuentos, "html", { customBlockFn: getTextAlignBlockMetadata, }),
                    PlantillaHTML: data.Configuracion.ReglamentoDescuentos,
                    cargando: false
                  });
                }
                else {
                  this.setState({ Configuracion: data.Configuracion, cargando: false, mensaje: "No hay reglamento registrado", colorNotificacion: "warning", Plantilla: RichTextEditor.createEmptyValue(), PlantillaHTML: "" });
                  this.showNotification();
                }
                break;
              case "Pagos":
                if (data.Configuracion.ReglamentoPagos !== null && data.Configuracion.ReglamentoPagos !== undefined) {
                  this.setState({
                    Configuracion: data.Configuracion,
                    Plantilla: RichTextEditor.createValueFromString(data.Configuracion.ReglamentoPagos, "html", { customBlockFn: getTextAlignBlockMetadata, }),
                    PlantillaHTML: data.Configuracion.ReglamentoPagos,
                    cargando: false
                  });
                }
                else {
                  this.setState({ Configuracion: data.Configuracion, cargando: false, mensaje: "No hay reglamento registrado", colorNotificacion: "warning", Plantilla: RichTextEditor.createEmptyValue(), PlantillaHTML: "" });
                  this.showNotification();
                }
                break;
            }
          }
        });
  }

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value } = e.target;

    switch (value) {
      case "General":
        if (this.state.Configuracion.Reglamento !== null && this.state.Configuracion.Reglamento !== undefined) {
          this.setState({
            [name]: value,
            Plantilla: RichTextEditor.createValueFromString(this.state.Configuracion.Reglamento, "html", { customBlockFn: getTextAlignBlockMetadata, }),
            PlantillaHTML: this.state.Configuracion.Reglamento,
            cargando: false
          });
        }
        else {
          this.setState({ [name]: value, cargando: false, mensaje: "No hay reglamento registrado", colorNotificacion: "warning", Plantilla: RichTextEditor.createEmptyValue(), PlantillaHTML: "" });
          this.showNotification();
        }
        break;
      case "Descuentos":
        if (this.state.Configuracion.ReglamentoDescuentos !== null && this.state.Configuracion.ReglamentoDescuentos !== undefined) {
          this.setState({
            [name]: value,
            Plantilla: RichTextEditor.createValueFromString(this.state.Configuracion.ReglamentoDescuentos, "html", { customBlockFn: getTextAlignBlockMetadata, }),
            PlantillaHTML: this.state.Configuracion.ReglamentoDescuentos,
            cargando: false
          });
        }
        else {
          this.setState({ [name]: value, cargando: false, mensaje: "No hay reglamento registrado", colorNotificacion: "warning", Plantilla: RichTextEditor.createEmptyValue(), PlantillaHTML: "" });
          this.showNotification();
        }
        break;
      case "Pagos":
        if (this.state.Configuracion.ReglamentoPagos !== null && this.state.Configuracion.ReglamentoPagos !== undefined) {
          this.setState({
            [name]: value,
            Plantilla: RichTextEditor.createValueFromString(this.state.Configuracion.ReglamentoPagos, "html", { customBlockFn: getTextAlignBlockMetadata, }),
            PlantillaHTML: this.state.Configuracion.ReglamentoPagos,
            cargando: false
          });
        }
        else {
          this.setState({ [name]: value, cargando: false, mensaje: "No hay reglamento registrado", colorNotificacion: "warning", Plantilla: RichTextEditor.createEmptyValue(), PlantillaHTML: "" });
          this.showNotification();
        }
        break;
    }
    
    // this.setState({ [name]: value, });
  };

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  Validaciones(){
    var valido = true
    if(this.state.PlantillaHTML == ""){
      valido = false
    }

    return valido
  }

  async RegistrarReglamento() {
    this.setState({ cargando: true});
    if(!this.Validaciones()){
      this.setState({ mensaje:"El reglamento no puede estar vacio",colorNotificacion: "danger",cargando: false});
      this.showNotification();
    }else{
      await fetch(Globales.Url + "Configuracion/Configuracion/RegistrarReglamento", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({Plantilla: this.state.PlantillaHTML, TipoReglamento: this.state.TipoReglamento}),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje,colorNotificacion: "danger",cargando: false});
            this.showNotification();
          } else {
            //Obtuvo los registros correctamente
            this.setState({mensaje: data.mensaje, colorNotificacion: "success", cargando: false});
            this.showNotification();
            this.ObtenerConfiguracion();
          }
        });
    }
  }

  onChangeEditorText = (value) => {
    let HTML = value.toString('html',{blockStyleFn: getTextAlignStyles})
    
    this.setState({
      Plantilla: value,
      PlantillaHTML: HTML.replace(/<br>/g, '<br/>'),
    });
  };

  render() {
    const { classes } = this.props;
    //console.log(this.state.PlantillaHTML)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Reglamento escolar</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={3} sm={3} md={3}>
                    <FormControl style={{ width: "100%"}} className={classes.formControl} size="small" variant="outlined">
                      <InputLabel id="TipoReglamento">Tipo de reglamento</InputLabel>
                      <Select
                        labelId="TipoReglamento"
                        value={this.state.TipoReglamento}
                        onChange={this.handleChange.bind(this)}
                        name="TipoReglamento"
                        label="Tipo de reglamento"
                      >
                        <MenuItem value={"General"}>General</MenuItem>
                        <MenuItem value={"Pagos"}>Pagos</MenuItem>
                        <MenuItem value={"Descuentos"}>Descuentos</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <RichTextEditor
                      // autoFocus={true}
                      blockStyleFn={getTextAlignClassName}
                      toolbarConfig={toolbarConfig}
                      value={this.state.Plantilla}
                      onChange={this.onChangeEditorText}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem className="pull-right">

            <Button
              color="success"
              round
              size="sm"
              onClick={() =>
                this.RegistrarReglamento()
              }
            >
              <Save />
              GUARDAR
            </Button>
          </GridItem>
        </GridContainer>
        <div id="">

          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatUsuarios);
