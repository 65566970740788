import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, Add, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';

class ModalNuevoRecibo extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      ConceptoPago: "",
      Fecha: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      TipoPago: "Efectivo",
      Monto: "",
      NoCuenta: "",
      Banco: "",
      NoCheque: "",
      NumFolio: "",
      NumMovimiento: "",
      Venta: {},
      MostrarModal:false,
      _id: "",
      disabled:false,
      errorConceptoPago: false,
      errorMonto: false,
      errorFecha: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      cargando: false,
      CuerpoCargando: "Cargando",
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    //console.log(this.props)
    }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  ValidarInfo(){
    let valor1 = 0;
    let valor2 = 0;
    let valor3 = 0;

    if(this.state.ConceptoPago == ""){
      this.setState({errorConceptoPago: true ,mensaje: "Proporcione el concepto de pago", colorNotificacion: "danger" })
      this.showNotification();
      valor1 = 1;
    }else{ 
      valor1 = 0; 
      this.setState({errorConceptoPago: false})
    }

    if(this.state.Monto == ""){
      this.setState({errorMonto: true ,mensaje: "Proporcione una cantidad válida", colorNotificacion: "danger" })
      this.showNotification();
      valor2 = 1;
    }
    else{
      this.setState({errorMonto: false})
      valor2 = 0;
    }

    if(this.state.Fecha == ""){
      this.setState({errorFecha: true ,mensaje: "Complete el campo de fecha", colorNotificacion: "danger" })
      this.showNotification();
      valor3 = 1;
    }
    else{
      this.setState({errorFecha: false})
      valor3 = 0;
    }

    if(valor1 + valor2 + valor3 == 0){
      this.setState({
        Venta: {
          Banco: this.state.Banco,
          Fecha: this.state.Fecha,
          MotivosCancelacion: this.state.ConceptoPago,
          NumeroCheque: this.state.NoCheque,
          NumeroCuenta: this.state.NoCuenta,
          NumeroFolio: this.state.NumFolio,
          NumeroMovimiento: this.state.NumMovimiento,
          Subtotal: parseFloat(this.state.Monto),
          TipoPago: this.state.TipoPago,
          Total: parseFloat(this.state.Monto),
          VentaVirtual: true,
        }
      },()=>{
        this.obtenerListaDetallePagos(this.state.Venta)
      })
    }
  }

  //Obtener ventas totales por periodo---------------------------------------------------------------------------------------------------------------------------------------------------------
  obtenerListaDetallePagos(Venta){
    this.setState({cargando: true})
    fetch(Globales.Url + 'Facturacion/Facturacion/VentaFRegistrar', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Venta: Venta})
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger', ListaAlumnos:[], cargando: false });
          this.showNotification();
         } else { //Filtrado exitoso
            //console.log(data.ListaAlumnos);
            this.setState({colorNotificacion: 'success', mensaje: data.mensaje, cargando: false });
            this.MandarFolio(data.Folios, data.mensaje)
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));; 
  }

  //Mandar mensaje a facturacion principal fuera del modal
  MandarFolio(folio, mensaje) {
    this.setState({mensaje:mensaje, op: 1 });
    this.props.parentCallback(folio, mensaje);
    this.props.eventClick('esc');
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    if(name == "TipoPago"){
      this.setState({
        [name]: value,
        NoCuenta: "",
        Banco: "",
        NoCheque: "",
        NumFolio: "",
        NumMovimiento: "",
      });
    }

    this.setState({[name]: value});
  }

  /* obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  } */

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;

    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    let agregar
    if(this.state.cargando){
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      )
    }

    //console.log("Fecha inicio", this.state.VentasTotalesPorPeriodo)
    let opcionPago;
    switch (this.state.TipoPago){
      case "Tarjeta_de_Crédito":
        opcionPago =
          <>
            <TextField
                id="NoCuenta"
                style={{width: "16em"}}
                variant="outlined"
                size="small"
                className="mt-2"
                label="Número de cuenta"
                //error={this.state.errorConceptoPago}
                //success={!this.state.errorConceptoPago}
                InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.NoCuenta,
                    name: "NoCuenta",
                    id: "NoCuenta",
                }}
            /><br/>
            <TextField
                id="Banco"
                style={{width: "16em"}}
                variant="outlined"
                size="small"
                label="Banco"
                className="mt-2"
                //error={this.state.errorConceptoPago}
                //success={!this.state.errorConceptoPago}
                InputProps={{
                  onChange: this.handleChange.bind(this),
                  value: this.state.Banco,
                  name: "Banco",
                  id: "Banco",
                }}
            /><br/>
          </>
        break;
      case "Tarjeta_de_Débito":
        opcionPago =
          <>
            <TextField
                id="NoCuenta"
                style={{width: "16em"}}
                variant="outlined"
                size="small"
                label="Número de cuenta"
                className="mt-2"
                //error={this.state.errorConceptoPago}
                //success={!this.state.errorConceptoPago}
                InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.NoCuenta,
                    name: "NoCuenta",
                    id: "NoCuenta",
                }}
            /><br/>
            <TextField
                id="Banco"
                style={{width: "16em"}}
                variant="outlined"
                size="small"
                label="Banco"
                className="mt-2"
                //error={this.state.errorConceptoPago}
                //success={!this.state.errorConceptoPago}
                InputProps={{
                  onChange: this.handleChange.bind(this),
                  value: this.state.Banco,
                  name: "Banco",
                  id: "Banco",
                }}
            /><br/>
          </>
        break;
      case "Cheque":
        opcionPago =
          <>
            <TextField
                id="NoCheque"
                style={{width: "16em"}}
                variant="outlined"
                size="small"
                label="Número de cheque"
                className="mt-2"
                //error={this.state.errorConceptoPago}
                //success={!this.state.errorConceptoPago}
                InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.NoCheque,
                    name: "NoCheque",
                    id: "NoCheque",
                }}
            /><br/>
          </>
        break;
      case "Depósito":
        opcionPago =
          <>
            <TextField
                id="NumFolio"
                style={{width: "16em"}}
                variant="outlined"
                size="small"
                label="Número de folio"
                className="mt-2"
                //error={this.state.errorConceptoPago}
                //success={!this.state.errorConceptoPago}
                InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.NumFolio,
                    name: "NumFolio",
                    id: "NumFolio",
                }}
            /><br/>
          </>
        break;
      case "Transferencia":
        opcionPago =
          <>
            <TextField
                id="NumMovimiento"
                style={{width: "16em"}}
                variant="outlined"
                size="small"
                label="Número de movimiento"
                className="mt-2"
                //error={this.state.errorConceptoPago}
                //success={!this.state.errorConceptoPago}
                InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.NumMovimiento,
                    name: "NumMovimiento",
                    id: "NumMovimiento",
                }}
            /><br/>
          </>
        break;
      default:
        opcionPago = <></>
        break;
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showNvoRecibo}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <div className="mt-2" style={{backgroundColor: "#add9ed" ,textAlign: "center", width: "100%"}}>Ingrese la información para generar el recibo</div>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="ConceptoPago"
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Concepto de pago"
                    error={this.state.errorConceptoPago}
                    success={!this.state.errorConceptoPago}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.ConceptoPago,
                      name: "ConceptoPago",
                      id: "ConceptoPago",
                    }}
                  /><br/><br/>
                </GridItem>
                <GridItem xs={12} sm={12}>
                <TextField
                  id="Fecha"
                  style={{width: "16em"}}
                  variant="outlined"
                  type="date"
                  format="DD-MM-YYYY"
                  size="small"
                  label="Fecha"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                     onChange: this.handleChange.bind(this),
                     value: this.state.Fecha,
                     name: "Fecha",
                     id: "Fecha",
                     error: this.state.errorFecha,
                     success: !this.state.errorFecha
                  }}
                />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <FormControl size="small">
                  <div>Forma de pago: </div>
                  <Select
                    value={this.state.TipoPago}
                    onChange={this.handleChange.bind(this)}
                    name="TipoPago"
                    variant="outlined"
                    style={{width: "14em"}}
                    //error={this.state.errorUsuarios}
                    //success={!this.state.errorUsuarios}
                  >
                    <MenuItem disabled>Seleccione un tipo de pago</MenuItem>
                    <MenuItem value={"Efectivo"}>Efectivo</MenuItem>
                    <MenuItem value={"Tarjeta_de_Crédito"}>Tarjeta de Crédito</MenuItem>
                    <MenuItem value={"Tarjeta_de_Débito"}>Tarjeta de Débito</MenuItem>
                    <MenuItem value={"Cheque"}>Cheque</MenuItem>
                    <MenuItem value={"Depósito"}>Depósito</MenuItem>
                    <MenuItem value={"Transferencia"}>Transferencia</MenuItem>
                  </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {opcionPago}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <br/>
                  <div style={{backgroundColor: "#adacac", textAlign: "center", fontWeight: "bold"}}>Monto total</div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <br/>
                  <TextField
                    id="Monto"
                    variant="outlined"
                    size="small"
                    label="Monto"
                    //type="text"
                    style={{marginLeft: "25%"}}
                    error={this.state.errorMonto}
                    success={!this.state.errorMonto}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Monto,
                      name: "Monto",
                      id: "Monto",
                      type: "number"
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridItem>
            <Button
              tabIndex="0"
              id="btnGuardar"
              size="sm"
              color="success"
              className="pull-right  mb-3"
              round
              onClick={() => this.ValidarInfo()}
            >
              <Save />
              &nbsp;GUARDAR
            </Button>
            <Button
              tabIndex="1"
              size="sm"
              color="danger"
              className="pull-right mr-2 mb-3"
              round
              onClick={() => this.props.eventClick("esc")}
            >
              <Clear />
              &nbsp;CERRAR
            </Button>
          </GridItem>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalNuevoRecibo);