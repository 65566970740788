import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, GetApp, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
import ModalInfoAdeudo from './ModalInfoAdeudo.jsx';
import SeleccionarEmpresa from "../../../../views/Generales/ComponentesEmpresas/SeleccionarEmpresas.jsx"
import SeleccionarIdioma from "../../../../views/Generales/ComponentesEmpresas/SeleccionarIdioma.jsx"
import ModalCargando from "../../../../components/Generales/ModalCargando";


class Cobranza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaEmpresas: [],
      Folios: [],
      AlumnoSeleciondado: [],
      VentaSeleccionada: [],
      EstadoAlumno: "Activo",
      //listas que se muestran
      ListaAlumnosActivos: [],
      ListaAlumnosInactivos: [],
      ListaAlumnosAspirantes: [],

      FolioVenta: "",
      IDEmpresa: null,
      idIdioma: "null",
      NombreEmpresa: "",
      FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaFin: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      Disabled: false,
      mensaje: "",
      br: false,
      colorNotificacion: 'success',
      colorTableRow: "white",
      errorFechainicio: false,
      errorFechaFin: false,
      errorFolioVenta: false,
      entroFiltrar: false,
      notificacion: false,
      showDetalleVenta: false,
      cargando: false,
      //listas a mostrar
      ListaALumnosActivos: [],
      ListaALumnosNoActivos: [],
      ListaALumnosAspirantes: [],
      //Totales a mostrar
      Total: 0,
      TotalActivos: 0,
      TotalNoActivos: 0,
      TotalAspirantes: 0,
      CuerpoCargando: "Cargando",


    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    // this.handleChange = this.handleChange.bind(this);

    this.EventoSeleccionarEmpresa = this.EventoSeleccionarEmpresa.bind(this);
    this.EventoSeleccionarIdioma = this.EventoSeleccionarIdioma.bind(this);
    this.handleChangeBusqueda = this.handleChangeBusqueda.bind(this);
    this.EventoModalAdeudo = this.EventoModalAdeudo.bind(this);
  }

  componentDidMount() {
    // this.obtenerEmpresasActivas();
    this.ListadoAdeudosPorIdioma();
  }

  ListadoAdeudosPorPeriodos() {
    this.setState({
      ListaALumnosActivos: [],
      ListaALumnosNoActivos: [],
      ListaALumnosAspirantes: [],
      cargando: true,
    })
    fetch(Globales.Url + 'BitacorasCobranza/Bitacora/ListaAdeudosAlumnosRangoFechas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        FechaInicio: this.state.FechaInicio,
        FechaFinal: this.state.FechaFin,
        Empresa: this.state.IDEmpresa,
        TipoAlumno: this.state.EstadoAlumno,
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            NombreAlumno: "",
            cargando: false,
          });
          this.showNotification();
        } else { //Filtrado exitoso
          this.SepararListaAdeudos(data.Adeudos)
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  ListadoAdeudosPorIdioma() {
    this.setState({
      ListaALumnosActivos: [],
      ListaALumnosNoActivos: [],
      ListaALumnosAspirantes: [],
      cargando: true,
    })
    fetch(Globales.Url + 'BitacorasCobranza/Bitacora/ListaAdeudosAlumnosRangoFechasPorIdioma', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        FechaInicio: this.state.FechaInicio,
        FechaFinal: this.state.FechaFin,
        Empresa: this.state.IDEmpresa,
        IdIdioma: this.state.idIdioma,
        TipoAlumno: this.state.EstadoAlumno,
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            NombreAlumno: "",
            cargando: false,
          });
          this.showNotification();
        } else { //Filtrado exitoso
          this.SepararListaAdeudos(data.Adeudos)
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  SepararListaAdeudos(ListaAdeudos) {

    var AlumnosActivos = []
    var AlumnosNoActivos = []
    var AlumnosAspirantes = []
    var listaFolios = []

    var total = 0
    var totalActivos = 0
    var totalNoActivos = 0
    var totalAspirantes = 0

    var adeudos = []
    adeudos = [...ListaAdeudos]

    // console.log(adeudos)

    adeudos.map((deuda, index) => {
      total += parseFloat(deuda.Monto)

      if (listaFolios.length == 0) {
        listaFolios.push({
          _idAlumno: deuda._idAlumno,
          EstadoAlumno: deuda.EstadoAlumno,
          Empresa: deuda.Empresa,
          NombreAlumno: deuda.NombreAlumno,
          Correo: deuda.Correo,
          Telefono: deuda.Telefono,
          Monto: parseFloat(deuda.Monto),
          FechaPago: deuda.FechaPago,
          Deudas: []
        })
        listaFolios[0].Deudas.push(deuda)
      } else {
        var repetido = false
        listaFolios.map(folio => {
          if (deuda.NombreAlumno == folio.NombreAlumno) {
            //si no esta repetido
            folio.Monto += parseFloat(deuda.Monto)
            folio.Deudas.push(deuda)
            repetido = true
          }
        })

        if (repetido == false) {
          listaFolios.push({
            _idAlumno: deuda._idAlumno,
            EstadoAlumno: deuda.EstadoAlumno,
            Empresa: deuda.Empresa,
            NombreAlumno: deuda.NombreAlumno,
            Correo: deuda.Correo,
            Telefono: deuda.Telefono,
            Monto: parseFloat(deuda.Monto),
            FechaPago: deuda.FechaPago,
            Deudas: []
          })
          listaFolios[listaFolios.length - 1].Deudas.push(deuda)
        }
      }
    })

    // listaFolios.map(aduedo => {
    //   switch (aduedo.EstadoAlumno) {
    //     case "Activo":
    //       AlumnosActivos.push(aduedo)
    //       totalActivos += parseFloat(aduedo.Monto);
    //       break;
    //     case "Inactivo":
    //       AlumnosNoActivos.push(aduedo)
    //       totalNoActivos += parseFloat(aduedo.Monto);
    //       break;
    //     default:
    //       AlumnosAspirantes.push(aduedo)
    //       totalAspirantes += parseFloat(aduedo.Monto);
    //       break;
    //   }
    // })
    
    // total = totalActivos + totalNoActivos + totalAspirantes
    adeudos = adeudos.sort( function (a, b) {
      if (a.FechaPago > b.FechaPago) {
        return -1;
      }
      if (a.FechaPago < b.FechaPago) {
        return 1;
      }
      return 0;
    })

    this.setState({
      Folios: adeudos,
      //listas
      // ListaALumnosActivos: AlumnosActivos,
      // ListaALumnosNoActivos: AlumnosNoActivos,
      // ListaALumnosAspirantes: AlumnosAspirantes,
      //totales
      Total: total,
      // TotalActivos: totalActivos,
      // TotalNoActivos: totalNoActivos,
      // TotalAspirantes: totalAspirantes,

      cargando: false,
    })
  }
  // EXPOTAR A EXCEL

  ExportarExcel() {

    if (this.state.Folios.length == 0) {
      this.setState({ mensaje: "Genere una consulta antes de exportar", colorNotificacion: "danger" });
      this.showNotification();
    } else {
      this.setState({
        cargando: true,
      })
      fetch(Globales.Url + 'BitacorasCobranza/Bitacora/ReporteExcelCobranza', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({
          FechaInicio: this.state.FechaInicio,
          FechaFinal: this.state.FechaFin,
          Empresa: this.state.IDEmpresa,
          IdIdioma: this.state.idIdioma == "null" ? null : this.state.idIdioma,
        })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) { //Error en la respuesta
            this.setState({
              mensaje: data.mensaje,
              colorNotificacion: "danger",
              NombreAlumno: "",
              cargando: false,
            });
            this.showNotification();
          } else { //Filtrado exitoso
            this.DescargarExcel(data.excel)
          }
        })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }
  }

  DescargarExcel(archivoBase64) {
    const url = 'data:text/csv;base64,' + archivoBase64;

    let nombre = 'Reporte de cobranza'
    const linkSource = `${url}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.csv`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    this.setState({
      cargando: false,
      mensaje: "Bitácora exportada exitosamente",
      colorNotificacion: "success",
    });
    this.showNotification();
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, type } = e.target;
    
    if(type=="date"){
      this.setState({ [name]: value });
    }else{
      this.setState({ [name]: value });
    }
    

  }

  EventoSeleccionarEmpresa(idEmpresa) {

    this.setState({ IDEmpresa: idEmpresa });
  }
  EventoSeleccionarIdioma(idIdioma) {

    this.setState({ idIdioma: idIdioma });
  }

  handleChangeBusqueda(e) {
    const { name, id, value } = e.target;
    this.setState({ [name]: value });

  }
  ValidarTipoBusqueda() {
    // if (this.state.IDEmpresa == null) {
    //   this.setState({ mensaje: "Seleccione empresa antes de buscar", colorNotificacion: "danger" });
    //   this.showNotification();
    // } else {
    //   if (this.state.idIdioma != "null") {
    //     this.ListadoAdeudosPorIdioma()
    //   } else {
    //     this.ListadoAdeudosPorPeriodos()
    //   }
    // }

    if (this.state.idIdioma != "null") {
      this.ListadoAdeudosPorIdioma()
    } else {
      this.ListadoAdeudosPorPeriodos()
    }

  }
  OpenModalAdeudo(Adeudo) {
    this.setState({ showDetalleVenta: true, VentaSeleccionada: Adeudo });
  }

  EventoModalAdeudo(mensaje) {
    this.setState({ showDetalleVenta: false, VentaSeleccionada: [] });
  }

  render() {
    const { AlumnoSeleciondado } = this.state;
    let DatosAlumno;
    if (AlumnoSeleciondado.length !== 0) {

      DatosAlumno =
        <>
          {AlumnoSeleciondado.NombreCompleto} &nbsp;&nbsp;&nbsp;
        </>
    }

    let agregar;

    if (this.state.showDetalleVenta == true) {
      agregar =
        <ModalInfoAdeudo
          Titulo={"Detalle de adeudos del alumno"}
          showDetalleVenta={this.state.showDetalleVenta}
          eventClick={this.EventoModalAdeudo}
          Venta={this.state.VentaSeleccionada}
        />
    }

    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    const { classes } = this.props;

    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Bitácora de cobranza</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <h6>Criterio de búsqueda para la(s) venta(s)</h6>
                  </GridItem>
                  <GridItem id="Empresa de búsqueda" xs={12} sm={12} md={4}>
                    <SeleccionarEmpresa
                      // value={this.state.Empresa}
                      eventoClick={this.EventoSeleccionarEmpresa}
                    />
                  </GridItem>
                  <GridItem id="Idioma de búsqueda" xs={12} sm={12} md={6}>
                    <SeleccionarIdioma
                      ExtraAll={true}
                      value={this.state.idIdioma}
                      eventoClick={this.EventoSeleccionarIdioma}
                    />
                  </GridItem>

                  <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={12}>
                    {/*Opcion Fechas*/}   <div >
                      <TextField
                        id="FechaInicio"
                        style={{ width: "18em" }}
                        variant="outlined"
                        type="date"
                        format="DD-MM-YYYY"
                        size="small"
                        label="Fecha de inicio"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaInicio,
                          name: "FechaInicio",
                          id: "FechaInicio",
                          error: this.state.errorFechainicio,
                          success: !this.state.errorFechainicio
                        }}
                      />
                      <TextField
                        className="ml-2"
                        id="FechaFin"
                        style={{ width: "18em" }}
                        variant="outlined"
                        size="small"
                        type="date"
                        format="DD-MM-YYYY"
                        label="Fecha de terminación"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaFin,
                          name: "FechaFin",
                          id: "FechaFin",
                          error: this.state.errorFechaFin,
                          success: !this.state.errorFechaFin
                        }}
                      />
                      <FormControl size="small" variant="outlined">
                        <InputLabel id="auth" style={{ marginLeft: "10px" }}>Estado del alumno</InputLabel>
                        <Select
                          className="ml-2"
                          labelId="auth"
                          fullWidth={true}
                          value={this.state.EstadoAlumno}
                          onChange={this.handleChange.bind(this)}
                          name="EstadoAlumno"
                          label="Estado del alumno"
                          //fullWidth={true}
                          style={{ width: "18em" }}
                        >
                          <MenuItem disabled>Seleccione estado del alumno</MenuItem>
                          <MenuItem value={"Activo"}>Activo</MenuItem>
                          <MenuItem value={"Inactivo"}>inactivo</MenuItem>
                          <MenuItem value={"En_Proceso_de_Inscripción"}>En proceso de inscripcion</MenuItem>

                        </Select>
                      </FormControl>&nbsp;&nbsp;&nbsp;
                    </div>
                  </GridItem>
                  <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={8}>
                    <Button size="sm" className="pull-right" type="submit" color="info" round onClick={() => this.ValidarTipoBusqueda()}><Search />Buscar</Button>
                  </GridItem>
                  <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={4}>
                    <Button size="sm" type="submit" color="info" round onClick={() => this.ExportarExcel()}><GetApp />Exportar</Button>
                  </GridItem>

                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" xs={12} sm={12} md={18}>
          <GridItem xs={12} sm={12} md={18}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>
                  Resumen de bitácora
                  {/* {this.state.EstadoAlumno == "Activo" ? "Alumnos activos" :
                    this.state.EstadoAlumno == "Inactivo" ? "Alumnos inactivo" :
                      "Alumnos en proceso de inscripcion"} */}
                  </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="table-responsive-xl table-wrapper-scroll-y">
                      <table className="table table-sm table-hover">
                        <thead>
                          <th className="">Empresa</th>
                          <th className="">Alumno</th>
                          <th className="">Correo</th>
                          <th className="text-center">Teléfono</th>
                          <th className="text-center">Adeudo</th>
                          <th className="text-center">Fecha limite de pago</th>
                        </thead>
                        <tbody>
                          {this.state.Folios.length == 0 ?
                            <tr><td colspan="6" className="text-center"><h5 >No hay adeudos activos</h5></td></tr> :
                            this.state.Folios.map(Adeudo => {
                              return (
                                <tr >
                                  <td style={{verticalAlign: "middle", cursor: "pointer"}} onClick={() => this.OpenModalAdeudo(Adeudo)} className="">{Adeudo.Empresa}</td>
                                  <td style={{verticalAlign: "middle", cursor: "pointer"}} onClick={() => this.OpenModalAdeudo(Adeudo)} className="">{Adeudo.NombreAlumno}</td>
                                  <td style={{verticalAlign: "middle", cursor: "pointer"}} onClick={() => this.OpenModalAdeudo(Adeudo)} className="">{Adeudo.Correo == null ? "sin asignar" : Adeudo.Correo}</td>
                                  <td style={{verticalAlign: "middle", cursor: "pointer"}} onClick={() => this.OpenModalAdeudo(Adeudo)} className="text-center">{Adeudo.Telefono == null ? "sin asignar" : Adeudo.Telefono}</td>
                                  <td style={{verticalAlign: "middle", cursor: "pointer"}} onClick={() => this.OpenModalAdeudo(Adeudo)} className="text-center">{parseFloat(Adeudo.Monto).toFixed(2)}</td>
                                  <td style={{verticalAlign: "middle", cursor: "pointer"}} onClick={() => this.OpenModalAdeudo(Adeudo)} className="text-center">{
                                    new Date(Adeudo.FechaPago).toLocaleDateString('es-MX', { day: '2-digit', month: '2-digit', year: 'numeric', })
                                  }</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </GridItem>
          <GridItem style={{ backgroundColor: "#f1f1f1", borderRadius: 10, }} xs={12} sm={12} md={12} lg={12}>
            <GridContainer xs={12} sm={12} md={12} lg={12}>
              <GridItem xs={12} sm={12} md={12} lg={10}>
                <h4 className="text-right"><b>Total:</b></h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={2} className="text-center">
                <h4 className="">{convertirPesos(this.state.Total)}</h4>
              </GridItem>
            </GridContainer>
          </GridItem>
                </GridContainer>
              </CardBody>
            </Card>

          </GridItem>

        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Exportar a excel"}
            Cuerpo={"¿Está seguro que desea exportar a excel la?"}
            show={this.state.show}
          // eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Cobranza);