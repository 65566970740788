import CookiesU from 'universal-cookie';
const cookies = new CookiesU();

const Globales = {

  Url: process.env.NODE_ENV === 'production' ? 
  // 'http://34.226.140.130:5001/'  //Servidor de pruebas
  // 'https://schoolapi.dostics.com.mx/'  //Pruebas vertificado
  'https://educationapi.dostics.com.mx/'     //Servidor productivo
  
  : 'http://localhost:5001/',
  // : 'http://3.235.201.128:5001/'  ,//Servidor productivo
  // :"https://schoolapi.dostics.com.mx/",

  token: 'Bearer ' + cookies.get('token')/*.replace(/['"]+/g, '')*/,
  usuario: localStorage.getItem("Usuario"),
  version: "1.1.15",
}
export default Globales;       