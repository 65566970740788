import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalBuscarAlumnos from "../../../components/Generales/ModalBuscarAlumnos";
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalAgregarProducto from "./ModalAgregarProducto.jsx";
import ModalEliminarPago from "./ModalEliminarPago.jsx";
import ModalCobrar from "./ModalCobrar.jsx";
import ModalInscribirAlumno from "./ModalInscribirAlumno.jsx";
import ModalAdelantarColegiatura from "./ModalAdelantarColegiatura";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
import SeleccionarAlumno from "../../Generales/ComponentesEmpresas/SeleccionarAlumno.jsx"

import ReactToPrint from 'react-to-print';
// import ReciboPago from '../FormatosImpresion/RociboPago.jsx'
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from "@material-ui/core/IconButton";

class Cobranza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      cobranzaSeleciondado: {},
      BuscarAlumno: "",
      Activo: 0,
      Total: 0,
      ListaAlumnos: [],
      ListaProducto: [],
      NombreProducto: "",
      AlumnoSeleciondado: [],
      ProductoSelecionado: {},
      ListaPagos: [],
      ListaPagosTemp: [],
      PagoComprasAlumno: [],
      PagoSelecionado: {},
      ListaDescuentosTemp: [],
      DctoPorciento: '',
      DctoDinero: '',
      DiasExt: 0,
      DiasExt2: 0,
      CantidadCorboExt: 0,
      CantidadCorboExt2: 0,
      IVAMostrar: 0,
      ImporteTotal: 0,
      Disabled: false,
      ocultarBotones: true,
      mensaje: "",
      mensajePagos: "",
      br: false,
      colorNotificacion: 'info',
      entroFiltrar: false,
      notificacion: false,
      PagosRealizados: [],
      PagoEliminar: null,
      //banderas
      show: false,
      showCobrar: false,
      showInscripcion: false,
      showColegiatura: false,
      VerImpresion: false,// Para mostrar Archivo de impresion
      cargando: false,
      CuerpoCargando: "Cargando",
      checkedInactivos: false,
      // debugFechaActual: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),

    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChangePagos = this.handleChangePagos.bind(this);
    this.EliminarVenta = this.EliminarVenta.bind(this);

  }

  componentDidMount() {
    this.ObtenerConfiguracion()
  }

  //Función para pasar datos de los alumnos desde el modal hacia la vista
  handleCallback = (AlumnoSeleciondado) => {
    if(AlumnoSeleciondado.length !== 0){
    // if (typeof AlumnoSeleciondado != 'string') {
      this.obtenerPagos(AlumnoSeleciondado);
      this.obtenerIdiomas(AlumnoSeleciondado._id)
      if (AlumnoSeleciondado.AplicaDescuentoTemporal == "true") {
        this.ListaDescuentosActivosAlumno(AlumnoSeleciondado._id);
      }

      this.setState({
        AlumnoSeleciondado: AlumnoSeleciondado,
        ListaDescuentosTemp: [],
        ProductoSelecionado: {},
        NombreProducto: "",
        ListaProducto: []
      })
    } else {
      this.setState({
        AlumnoSeleciondado: AlumnoSeleciondado,
        ListaDescuentosTemp: [],
        ListaPagos: [],
        ListaDescuentosTemp: [],
        PagoSelecionado: {},
        ProductoSelecionado: {},
        NombreProducto: "",
        ListaProducto: []
      })
    }

    //this.ObtenerListaDescuentosAlumno(AlumnoSeleciondado);
  }

  RecuperarListaProd = (ProductoSelecionado) => {
    var total = this.state.Total
    let iva = this.state.IVAMostrar;
    let Producto = {
      _idConcepto: ProductoSelecionado._id,
      Cantidad: 1,
      Descripcion: ProductoSelecionado.Descripcion,
      existencia: parseInt(ProductoSelecionado.Existencia),
      AplicaInventario: ProductoSelecionado.AplicaExistencia,
      Precio: parseFloat(ProductoSelecionado.Precio),
      TipoProducto: 'Producto',
      Pendiente: ProductoSelecionado.Precio,
      PendienteTemp: ProductoSelecionado.Precio,
      NombreAlumno: this.state.AlumnoSeleciondado['NombreCompleto'],
      PagoCompraAlumno: { _idAlumno: this.state.AlumnoSeleciondado['_id'], _idEmpresa: this.state.AlumnoSeleciondado['_idEmpresa'] },
      //argegados extra
      FechaRegistroAdeudo: "0001-01-01",
      CantEdit: true,
      Extemporaneo: false,
      DescuentoTotalPesos: 0,
      descuentopesos: 0,
      descuento: 0,
      Disabled: false,
      hide: false,
      Monto: parseFloat(ProductoSelecionado.Precio),
      Importe: parseFloat(ProductoSelecionado.Precio),
      Subtotal: parseFloat(ProductoSelecionado.Precio),
      //IVA: parseFloat(parseFloat(ProductoSelecionado.Precio) * 0.16).toFixed(2),
      Vencido: false,
    }
    total += parseFloat(ProductoSelecionado.Precio)

    iva += (total / 1.16) * 0.16;

    this.setState({
      PagoComprasAlumno: [...this.state.PagoComprasAlumno, Producto],
      Total: total,
      ImporteTotal: total,
      IVAMostrar: iva
    })
  }


  RecuperarMensajeCobrar = (mensaje) => {
    this.setState({ mensaje: mensaje, colorNotificacion: "success" });
    this.showNotification();

    this.setState({
      _id: '',
      cobranzaSeleciondado: {},
      BuscarAlumno: "",
      Activo: 0,
      Total: 0,
      IVAMostrar: 0,
      ImporteTotal: 0,
      ListaAlumnos: [],
      ListaProducto: [],
      AlumnoSeleciondado: [],
      ProductoSelecionado: [],
      ListaPagos: [],
      PagoComprasAlumno: [],
      PagoSelecionado: [],
      DctoPorciento: '',
      DctoDinero: '',
      ListaPagosTemp: []
    })

  };

  async obtenerIdiomas(idAlumno) {
    this.setState({ Cargando: true });
    await fetch(Globales.Url + 'Idiomas/Idiomas/ObtenerIdiomasInscritoAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idAlumno: idAlumno
      })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ Cargando: false, ocultarBotones: true/* , colorNotificacion: 'danger', mensaje: data.mensaje */ });
          // this.showNotification();
        } else { //Filtrado exitoso
          let conteo = 0;
          if(data.data.length > 1){
            for (let index = 0; index < data.data.length; index++) {
              if(data.data[index].TipoCurso == "Tradicional" || data.data[index].TipoCurso == "ClaseParticular"){
                conteo += 1;
              }
            }
          }
          else{
            if(data.data.TipoCurso == "Tradicional" || data.data.TipoCurso == "ClaseParticular"){
              conteo += 1;
            }
          }

          if(conteo == 0){
            this.setState({ Cargando: false, ocultarBotones: true })
          }
          else{
            this.setState({ Cargando: false, ocultarBotones: false })
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", Cargando: true }));
  }

  async ObtenerConfiguracion() {
    this.setState({ cargando: true });
    await fetch(Globales.Url + "Configuracion/Configuracion/ObtenerConfiguracion", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((data) => {
      // console.log(data)
        if (data.error) {
          //Error al obtener los registros
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else {
          this.setState({
            DiasExt: data.Configuracion.DiasPagoExt !== null ? parseInt(data.Configuracion.DiasPagoExt) : 0,
            DiasExt2: data.Configuracion.DiasPagoExt2 !== null &&  data.Configuracion.DiasPagoExt2 !== undefined ? parseInt(data.Configuracion.DiasPagoExt2) : 0,
            CantidadCorboExt: data.Configuracion.CantidadCobroExt !== null ? parseFloat(data.Configuracion.CantidadCobroExt) : 0,
            CantidadCorboExt2: data.Configuracion.CantidadCobroExt2 !== null && data.Configuracion.CantidadCobroExt2 !== undefined ? parseFloat(data.Configuracion.CantidadCobroExt2) : 0,
            cargando: false
          });
        }
      });
  }
  //Función para obtener los pagos pendientes de los alumnos
  obtenerPagos(AlumnoSeleciondado) {
    this.setState({ cargando: true })

    fetch(Globales.Url + 'Cobranza/Cobranza/ListadoPagos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: AlumnoSeleciondado._id })
    }
    ).then(res => res.json())
      .then(data => {
      // console.log("API: ",data.ListaPagos)
        if (data.error) { //Error en la respuesta
          this.setState({ mensajePagos: data.mensaje, ListaPagos: [], cargando: false });
        } else { //Filtrado exitoso
          if (data.ListaPagos.length > 1) {

            this.state.ListaPagosTemp.map(pago => {
              let pagoFind = data.ListaPagos.findIndex(Lpago => JSON.stringify(Lpago._id) === JSON.stringify(pago._id))
              //console.log(pagoFind)
              if(pagoFind !== -1){
                data.ListaPagos[pagoFind].hide = true
              }
            })
            this.setState({ ListaPagos: data.ListaPagos, Activo: 1, cargando: false });
          }
          else {
            let ListaAuxPagos = [];
            ListaAuxPagos.push(data.ListaPagos);
            this.setState({ ListaPagos: ListaAuxPagos, Activo: 1, cargando: false });
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  async obtenerProductos(text){
    await fetch(Globales.Url + 'Cobranza/Cobranza/ListadoProductos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({BuscarProducto: text})
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
         } else { //Filtrado exitoso
          if(data.ListaProducto.length > 1){
            //console.log(data.ListaProducto);
            this.setState({ListaProducto: data.ListaProducto});
          }
          else {
            let ListaAux = [];
            ListaAux.push(data.ListaProducto);
            //console.log(ListaAux);
            this.setState({ ListaProducto: ListaAux });
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true})); 
  }

  async ListaDescuentosActivosAlumno(idAlumno) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Alumnos/Alumnos/ListaDescuentosActivosAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _idAlumno: idAlumno })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log("API: ", data.ListaDescuentosTemp)
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          //this.showNotification();
        } else { //Filtrado exitoso    
          if (data.ListaDescuentosTemp.length > 1) {
            let aux = [];
            data.ListaDescuentosTemp.map(desc => {
              if(new Date() >= new Date(desc.MesInicioDescuento) && new Date() < new Date(desc.MesTerminacionDescuento)){
                aux.push(desc)
              }
            })
            this.setState({ ListaDescuentosTemp: aux, Activo: 1, cargando: false });
          }
          else {
            let aux = [];
            if(new Date() >= new Date(data.ListaDescuentosTemp.MesInicioDescuento) && new Date() < new Date(data.ListaDescuentosTemp.MesTerminacionDescuento)){
              aux.push(data.ListaDescuentosTemp)
            }
            this.setState({ ListaDescuentosTemp: aux, Activo: 1, cargando: false });
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  //Agregar pagos al apartado de venta
  AgregarVenta(pago, AlumnoSeleciondado) {
    var ListaPagos = this.state.ListaPagos
    var total = this.state.Total
    var iva = this.state.IVAMostrar
    var ImporteTotal = this.state.ImporteTotal;
    let descuento = 0;
    let descontado = 0;
    let CantidadAbonada = 0;
    let YaAplicoDescuento = false;
    ListaPagos.forEach(Lpago => { if (Lpago._id == pago._id) { Lpago.hide = true; pago.hide = true; } });
    this.setState({ ListaPagos: ListaPagos, ListaPagosTemp: [...this.state.ListaPagosTemp, pago] });

    // console.log(pago)
    // console.log(this.state.ListaDescuentosTemp)

    if (pago.TipoConcepto == "Colegiatura") {
      if(this.state.ListaDescuentosTemp.length !== 0){
        this.state.ListaDescuentosTemp.map(desc => {
          if ((pago.NombreConcepto).match(desc.NombreIdioma)) {
            if(new Date(pago.FechaPago) >= new Date(desc.MesInicioDescuento) && new Date(pago.FechaPago) < new Date(desc.MesTerminacionDescuento)){
              if(pago.Abonos !== undefined && pago.Abonos !== null){
                YaAplicoDescuento = true;
                descuento = 0;
                descontado = 0;
              }
              else{
                descuento = parseFloat(desc.Descuento);
                descontado = parseFloat(pago.MontoTotal) * (descuento / 100);
              }
            }
          }
          // else{
          //   if(pago.Abonos !== undefined && pago.Abonos !== null){
          //     YaAplicoDescuento = true;
          //     descuento = 0;
          //     descontado = 0;
          //   }
          //   else{
          //     descuento = 0;
          //     descontado = parseFloat(pago.MontoTotal) * (descuento / 100);
          //   }
          // }
        })
      }
      else{
        if(pago.Abonos !== undefined && pago.Abonos !== null){
          YaAplicoDescuento = true;
          descuento = 0;
          descontado = 0;
        }
        else{
          descuento = 0;
          descontado = parseFloat(pago.MontoTotal) * (descuento / 100);
        }
      }
    }
    else {
      descuento = 0;
    }

    //console.log(YaAplicoDescuento)


    var pagoExtem = null

    let AdeudoAVenta = {
      _idPagoAlumno: pago._id,
      _idConcepto: pago._idConcepto,
      _idAlumno: pago._idAlumno,
      IdGrupo: pago._idGrupo,
      Cantidad: parseFloat(pago.Cantidad),
      AplicaInventario: false,
      Descripcion: pago.NombreConcepto,
      existencia: 0,
      Precio: parseFloat(pago.MontoTotal),
      // Subtotal: ((parseFloat(pago.Monto) - descontado) * 0.84).toFixed(2),
      Pendiente: parseFloat(pago.Monto),
      PendienteTemp: parseFloat(pago.Monto),
      Extemporaneo: false,
      //DescuentoTotalPesos: descontado,
      FechaRegistroAdeudo: pago.FechaRegistro,
      descuentopesos: 0,
      descuento: pago.TipoConcepto == "Colegiatura" ? descuento : 0,
      TipoProducto: 'ConceptoPago',
      NombreAlumno: AlumnoSeleciondado.NombreCompleto,
      PagoCompraAlumno: { _idAlumno: AlumnoSeleciondado._id, _idEmpresa: AlumnoSeleciondado._idEmpresa },
      Disabled: YaAplicoDescuento,
      hide: false,
      YaAplicoDescuento: YaAplicoDescuento,
      //IVA: ((parseFloat(pago.Monto) - descontado) * 0.16).toFixed(2),
      Monto: parseFloat(pago.Monto),
      Importe: parseFloat(pago.Monto),
      CantEdit: false,
      Vencido: false,
    }

    if(descuento !== 0){
      AdeudoAVenta.Monto -= (parseFloat(pago.Monto) * (descuento / 100))/* .toFixed(2) */
      AdeudoAVenta.PendienteTemp -= (parseFloat(pago.Monto) * (descuento / 100))/* .toFixed(2) */
      AdeudoAVenta.Importe -= (parseFloat(pago.Monto) * (descuento / 100))/* .toFixed(2) */
      total += parseFloat(pago.Monto) - (parseFloat(pago.Monto) * (descuento / 100))
    }
    else{
      total += parseFloat(pago.Monto)
    }

    iva = (total / 1.16) * 0.16;
    ImporteTotal = total


    //console.log(total)
    //console.log(ImporteTotal)
// debugger
    //Calculo de los extemporaneos
    let EsExt = false;
    let PagoExtratemp = 0;
    const fechaActual = new Date();
    const fechaDePago = new Date(pago.FechaPago);
    const AñoPAgo = new Date(pago.FechaPago).getFullYear();
    const MesPago = new Date(pago.FechaPago).getMonth()
    EsExt = fechaActual >= fechaDePago;
    
    if (parseInt(this.state.AlumnoSeleciondado.DiaEspecialPago) !== 0) {  //! EXTEMPORANEO ALUMNOS CON CONVENIO
      if (new Date() >= new Date(AñoPAgo, MesPago, parseInt(this.state.AlumnoSeleciondado.DiaEspecialPago))) {
        PagoExtratemp = parseFloat(this.state.CantidadCorboExt) 
      } 
      if (parseInt(this.state.AlumnoSeleciondado.DiaEspecialPago2) !== 0) { //? verificar si alumno 2do dia especial de pago
        if (new Date() >= new Date(AñoPAgo, MesPago, this.state.AlumnoSeleciondado.DiaEspecialPago2)) {
          PagoExtratemp += parseFloat(this.state.CantidadCorboExt2);
        }
      } else { //Si el alumno no tiene segundo dia especial de pago, se mantiene segundo dia registrado en configuraciones
        if (new Date() >= new Date(AñoPAgo, MesPago, this.state.DiasExt2)) { //* segundo costo de extraordinario general
          PagoExtratemp += parseFloat(this.state.CantidadCorboExt2);
        }
      }
    }
    else{ //! EXTEMPORANEO GENERAL
      //? validar que existan fechas de extraordiario
      if (this.state.DiasExt !== 0) {
        //? validar que existan fechas de extraordiario
        if (new Date() >= new Date(AñoPAgo, MesPago, this.state.DiasExt)) { 
          PagoExtratemp = parseFloat(this.state.CantidadCorboExt)
        }
        if (this.state.DiasExt2 !== 0) {
          if (new Date() >= new Date(AñoPAgo, MesPago, this.state.DiasExt2)) {
            PagoExtratemp += parseFloat(this.state.CantidadCorboExt2);
          } 
        }
      }
    }

    if ((pago.Vencido == "true" && pago.TipoConcepto == "Colegiatura") && EsExt) {
      AdeudoAVenta.Vencido = true
      if (pago.ExtPagado !== "true") {
        pagoExtem = {
          _idPagoAlumno: pago._id,
          _idConcepto: pago._idConcepto,
          _idAlumno: pago._idAlumno,
          IdGrupo: pago._idGrupo,
          Cantidad: 1,
          Descripcion: `${pago.NombreConcepto} (EXTEMPORÁNEO)`,
          Precio: PagoExtratemp,
          Pendiente: PagoExtratemp,
          existencia: 0,
          PendienteTemp: PagoExtratemp,
          //Subtotal: parseFloat(PagoExtratemp * 0.84),
          FechaRegistroAdeudo: pago.FechaRegistro,
          Extemporaneo: true,
          //AplicaInventario: false,
          //DescuentoTotalPesos: 0,
          descuentopesos: 0,
          descuento: 0,
          TipoProducto: 'ConceptoPago',
          NombreAlumno: AlumnoSeleciondado.NombreCompleto,
          PagoCompraAlumno: { _idAlumno: AlumnoSeleciondado._id, _idEmpresa: AlumnoSeleciondado._idEmpresa },
          Disabled: false,
          //IVA: parseFloat(PagoExtratemp * 0.16),
          Monto: PagoExtratemp,
          Importe: PagoExtratemp,
          CantEdit: false,
          Vencido: true,
        }
        total += PagoExtratemp
        iva += (total / 1.16) * 0.16;
        ImporteTotal += parseFloat(PagoExtratemp)
      }
      // else {
      //   pagoExtem = {
      //     _idPagoAlumno: pago._id,
      //     _idConcepto: pago._idConcepto,
      //     _idAlumno: pago._idAlumno,
      //     IdGrupo: pago._idGrupo,
      //     Cantidad: 1,
      //     Descripcion: `${pago.NombreConcepto} (EXTEMPORÁNEO)`,
      //     Precio: PagoExtratemp,
      //     Pendiente: PagoExtratemp,
      //     existencia: 0,
      //     PendienteTemp: PagoExtratemp,
      //     //Subtotal: parseFloat(PagoExtratemp * 0.84),
      //     FechaRegistroAdeudo: pago.FechaRegistro,
      //     Extemporaneo: true,
      //     //AplicaInventario: false,
      //     //DescuentoTotalPesos: 0,
      //     descuentopesos: 0,
      //     descuento: 0,
      //     TipoProducto: 'ConceptoPago',
      //     NombreAlumno: AlumnoSeleciondado.NombreCompleto,
      //     PagoCompraAlumno: { _idAlumno: AlumnoSeleciondado._id, _idEmpresa: AlumnoSeleciondado._idEmpresa },
      //     Disabled: false,
      //     //IVA: parseFloat(PagoExtratemp * 0.16),
      //     Monto: PagoExtratemp,
      //     Importe: PagoExtratemp,
      //     CantEdit: false,
      //     Vencido: true,
      //   }
      //   total += PagoExtratemp
      //   iva += (total / 1.16) * 0.16;
      //   ImporteTotal += parseFloat(PagoExtratemp)
      // }
    }

    if (pagoExtem == null) {
      this.setState({
        PagoComprasAlumno: [...this.state.PagoComprasAlumno, AdeudoAVenta],
        Total: total,
        IVAMostrar: iva,
        ImporteTotal: ImporteTotal
      });
    } else {
      this.setState({
        PagoComprasAlumno: [...this.state.PagoComprasAlumno, AdeudoAVenta, pagoExtem],
        Total: total,
        IVAMostrar: iva,
        ImporteTotal: ImporteTotal
      }
      );
    }

  }

  //Eliminar pagos pendientes de la venta
  EliminarVenta(PagoDescontar) {
    var ListaPagos = this.state.ListaPagos
    var ListaPagosTemp = this.state.ListaPagosTemp
    var ListaPagoCompra = this.state.PagoComprasAlumno
    var ListaPagosMostrar = []
    var total = 0
    var iva = 0;
    var ImporteTotal = 0;

    let index = ListaPagoCompra.indexOf(PagoDescontar);
    let index2 = ListaPagosTemp.findIndex(elem => JSON.stringify(elem._id) === JSON.stringify(PagoDescontar._idPagoAlumno))

    if(PagoDescontar.Vencido == true){
      ListaPagoCompra.splice(index, 2);
    }
    else{
      ListaPagoCompra.splice(index, 1);
    }

    ListaPagoCompra.map((pago, index) => {
        total += parseFloat(pago.Monto)
        ImporteTotal += parseFloat(pago.Importe)
    })
    iva = (total / 1.16) * 0.16;

    ListaPagos.forEach((Lpago, index) => {
      if (JSON.stringify(Lpago._id) == JSON.stringify(PagoDescontar._idPagoAlumno)) {
        Lpago.hide = false;
      }
    });

    ListaPagosTemp.splice(index2, 1)

    this.setState({
      PagoEliminar: [],
      ListaPagos: ListaPagos,
      ListaPagosTemp: ListaPagosTemp,
      PagoComprasAlumno: ListaPagoCompra,
      Total: total,
      IVAMostrar: iva,
      ImporteTotal: ImporteTotal
    });
  }

  //Función para abrir el modal de buscar alumnos
  openModalBuscarAlumnos = () => {
    this.setState({ showAgregar: true, Titulo: "Buscar Alumnos", op: 0 });
  }

  openModalBuscarProducto = () => {
    if (this.state.AlumnoSeleciondado.length == 0) {
      this.setState({ colorNotificacion: 'danger', mensaje: "Seleccione el Alumno para la venta del producto" })
      this.showNotification();
    }
    else {
      this.setState({ showAgregarProd: true, Titulo: "Agregar Producto", op1: 0 });
    }
  }

  openModalEliminarPago(ListaPagos) {
    this.setState({ PagoSelecionado: ListaPagos, showEliminarPago: true, Titulo: "Cancelación de pago", op2: 1 });
  }
  openModalCobrar() {
    if (this.state.PagoComprasAlumno.length > 0) {
      // if(true){
      this.setState({ Titulo: "Cobrar", showCobrar: true })
    } else {
      this.setState({ mensaje: "No hay productos en la lista de venta", colorNotificacion: "danger" })
      this.showNotification();
    }
  }

  openModalIncripcion() {
    this.setState({ showInscripcion: true })
  }

  openModalColegiatura() {
    this.setState({ showColegiatura: true })
  }

  /* AgregarDetalleVenta() {
    this.setState({ Activo: 2});
  } */

  //Función que recupera valores para el modal de agregar
  eventoModalcobranza = (ListaAlumnos, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, op: 0 })
    /*     global.entrar = true;
        //this.obtenerCobranza();
        this.limpiar();
        //Validar que el mensaje venga diferente de vacio
        if (ListaAlumnos !== 'esc') {
          this.setState({ ListaAlumnos: ListaAlumnos, colorNotificacion: colorNotificacion })
          this.showNotification();
        } */
  }

  //Función que recupera valores para el modal de agregar producto
  eventoModalProd = (ListaProducto, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregarProd: false, op1: 0 })
  }

  //Función que recupera valores para el modal de agregar producto
  eventoModalgeneral = (mensaje) => {
    //Cambiar valores por default
    this.setState({
      showEliminarPago: false,
      showInscripcion: false,
      showColegiatura: false,
      mensaje: mensaje,
      colorNotificacion: "success",
    })
    if (mensaje != 'esc') {
      this.showNotification();
      this.obtenerPagos(this.state.AlumnoSeleciondado);
    }
  }

  eventoModalCobrar = (mensaje, ListaVentas) => {
    //Cambiar valores por default
    this.setState({ showCobrar: false });
    if (mensaje != "esc") {
      this.setState({
        mensaje: "Se a registrado la venta exitosamente",
        colorNotificacion: "success",
        PagosRealizados: ListaVentas,
        PagoComprasAlumno: [],
        _id: '',
        cobranzaSeleciondado: {},
        BuscarAlumno: "",
        Activo: 0,
        Total: 0,
        IVAMostrar: 0,
        ImporteTotal: 0,
        ListaAlumnos: [],
        ListaProducto: [],
        ProductoSelecionado: [],
        ListaPagos: [],
        PagoSelecionado: [],
        DctoPorciento: '',
        DctoDinero: '',
        ListaPagosTemp: []
      });
      this.obtenerPagos(this.state.AlumnoSeleciondado);
      this.showNotification();
      // this.Imprimir(ListaVentas)
    }
  };

  eventoModalEliminar = (mensaje) => {
    //Cambiar valores por default
    this.setState({
      show: false,
    })
    if (mensaje) {
      this.EliminarVenta(this.state.PagoEliminar)
    }
  }

  eventoModalEliminarPago = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showEliminarPago: false, op: 0 })
    if (mensaje != 'esc') {
      let ListaPagosIndex;
      ListaPagosIndex = this.state.ListaPagos.indexOf(this.state.PagoSelecionado);
      this.state.ListaPagos.splice(ListaPagosIndex, 1);

      this.setState({ ListaPagos: this.state.ListaPagos, mensaje: mensaje, colorNotificacion: "success" });
      this.showNotification();
    }
  }

  // even
  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, checked } = e.target;

    if(name == "checkedInactivos"){
      if (checked) {
        this.setState({ checkedInactivos: true })
      }
      else {
        this.setState({ checkedInactivos: false })
      }
    }
    else{
      this.setState({ [name]: value });
    }
  }


  handleChangePagos(e) {
    const { name, id, value } = e.target;
    var pagos = this.state.PagoComprasAlumno
    var total = 0
    var iva = 0;
    var ImporteTotal = 0;
    var ItemDescuento = 0
    var NuevoValor = value

    if (value == "" || value < 1) {
      NuevoValor = 0;
    }
    //  else {
    switch (name) {
      case "descuentopesos":
        //calcular descuento total
        var ItemDescuento = parseFloat(pagos[id].Pendiente) - (parseFloat(pagos[id].Pendiente) * (pagos[id].descuento / 100))
        let DescuentoPesos = parseFloat(value.replace(/[^0-9\.]/g, ''));

        //console.log(DescuentoPesos)

        if(isNaN(DescuentoPesos)){
          DescuentoPesos = ""
        }
        else if(DescuentoPesos < 0) {
          DescuentoPesos = 0
        }
        else if(DescuentoPesos > ItemDescuento){
          if(ItemDescuento == 0){
            DescuentoPesos = 0;
          }
          else{
            DescuentoPesos = ItemDescuento;
          }
        }

        let prueba = parseFloat((parseFloat(pagos[id].Pendiente) - DescuentoPesos - (parseFloat(pagos[id].Pendiente) * (pagos[id].descuento / 100))).toFixed(2))
        //console.log("ItemDescuento", ItemDescuento)


        pagos[id].descuentopesos = parseFloat(DescuentoPesos.toString());
        pagos[id].Importe = prueba;
        pagos[id].Monto = prueba;

        break;
      case "descuento":
        //calcular descuento total
        let Descuento = parseFloat(value.replace(/[^0-9]/g, ''));
        let DescPesos = 0;

          if(pagos[id].descuentopesos !== 0){
            DescPesos = pagos[id].descuentopesos;
          }

          if (isNaN(Descuento)) {
            Descuento = "";
          } 
          else if(Descuento < 0) {
            Descuento = 0;
          }
          else if(Descuento > 100){
            Descuento = 100;
            DescPesos = 0;
          }

          var ItemDescuento = (parseFloat(pagos[id].Pendiente) * (Descuento / 100))

          let prueba2 = pagos[id].Pendiente - ItemDescuento

          if(DescPesos > prueba2){
            DescPesos = prueba2;
          }

          //console.log( parseFloat((parseFloat(pagos[id].Pendiente) - parseFloat(ItemDescuento) - DescPesos).toFixed(2)))

          pagos[id].descuento = Descuento
          pagos[id].descuentopesos = DescPesos
          pagos[id].Importe = parseFloat((parseFloat(pagos[id].Pendiente) - parseFloat(ItemDescuento) - DescPesos).toFixed(2));
          pagos[id].Monto = parseFloat((parseFloat(pagos[id].Pendiente) - parseFloat(ItemDescuento) - DescPesos).toFixed(2));

        break;
      case "Importe":
        //calcular descuento total
        //var ItemDescuento = (parseFloat(pagos[id].Pendiente) * (pagos[id].descuento / 100)) + pagos[id].descuentopesos
        let Importe = parseFloat(value.replace(/[^0-9\.]/g, ''));

        if(isNaN(Importe)){
          Importe = "";
        }
        // else if(Importe > pagos[id].Monto){
        //   Importe = pagos[id].Monto
        // }

        pagos[id].Importe = Importe
        break;
      case "Cantidad":
        let MaxProductos = 999
        if ( NuevoValor > MaxProductos) {
          NuevoValor = MaxProductos;
        }else if ( NuevoValor == 0) {
          NuevoValor = 1;
        }

        pagos[id].Cantidad = parseFloat(NuevoValor).toFixed()
        pagos[id].Pendiente = parseFloat(NuevoValor) * pagos[id].Precio
        pagos[id].Monto = parseFloat(NuevoValor) * pagos[id].Precio
        pagos[id].Importe = parseFloat(NuevoValor) * pagos[id].Precio
        pagos[id].descuento = 0;
        pagos[id].descuentopesos = 0
        break;
    }
    // }

    pagos.map(pago => {
      if (pago.Monto != "") {
        total += parseFloat(pago.Monto)
        ImporteTotal += parseFloat(pago.Importe)
        iva += (pago.Monto / 1.16) * 0.16;
      }
    })

    //iva = (total / 1.16) * 0.16;

    this.setState({ PagoCompraAlumno: pagos, Total: total, IVAMostrar: iva, ImporteTotal: ImporteTotal });

  }
  replaceNumber(value) {
    return value.replace(/\D/g, "")
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
    // .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
  }

  Imprimir(Venta) {
    this.setState({
      PagosRealizados: Venta,
      VerImpresion: true
    }, () => {
      document.getElementById("imprime").click();
    })
  }

  handleFocus = (event) => event.target.select();

  handleBlur = (e) => {
    const {name, value, id} = e.target;

    //console.log(value)

    let PagoCompras = this.state.PagoComprasAlumno;

    switch(name){
      case "descuento":
        if(value == ""){
          PagoCompras[id].descuento = 0;
          this.setState({PagoComprasAlumno: PagoCompras});
        }
        else if(isNaN(value)){
          PagoCompras[id].descuento = 0;
          this.setState({PagoComprasAlumno: PagoCompras});
        }
        break;

      case "descuentopesos":
        if(value == ""){
          PagoCompras[id].descuentopesos = 0;
          this.setState({PagoComprasAlumno: PagoCompras});
        }
        else if(isNaN(value)){
          PagoCompras[id].descuentopesos = 0;
          this.setState({PagoComprasAlumno: PagoCompras});
        }
        break;

      case "Importe":
        let auximporte = 0;
        if(value == ""){
          PagoCompras[id].Importe = 0;

          PagoCompras.map(pago => {
            if (pago.Monto != "") {
              auximporte += parseFloat(pago.Importe)
            }
          })
          this.setState({PagoComprasAlumno: PagoCompras, ImporteTotal: auximporte});
        }
        else if(isNaN(value)){
          PagoCompras[id].Importe = 0;

          PagoCompras.map(pago => {
            if (pago.Monto != "") {
              auximporte += parseFloat(pago.Importe)
            }
          })
          this.setState({PagoComprasAlumno: PagoCompras, ImporteTotal: auximporte});
        }
        break;
    }
  }

  handleChangeAutocomplete(e, newValue){

    //console.log("Autocomplete seleccionado", newValue)

    if(typeof newValue != 'string'){
    
      this.setState({ProductoSelecionado: newValue})
      this.RecuperarListaProd(newValue)
    }
  }

  InputChange(e, newInputValue){
    //const { name, value2 } = e.target;

    if(newInputValue.length > 0){
      this.obtenerProductos(newInputValue);
    }

    this.setState({NombreProducto: newInputValue});
  }

  handleMouseDownToken(event) {
    event.preventDefault();
  }

  handleClickEliminarTexto(){
    this.setState({NombreProducto: ""});
  }


  render() {
    const { AlumnoSeleciondado, ListaDescuentosTemp, CantidadCorboExt } = this.state;

    //console.log(this.state.PagoComprasAlumno)
    //console.log(this.state.ListaPagos)
    //console.log(this.state.ListaPagosTemp)
    // console.log(AlumnoSeleciondado)
    // console.log({
    //   "DiasExt2": this.state.DiasExt2,
    //   "CantidadCorboExt2": this.state.CantidadCorboExt2
    // })

    // console.log(this.state.ListaDescuentosTemp)
    
    let DatosAlumno;
    if (AlumnoSeleciondado.length !== 0) {
      DatosAlumno =
        <>
          <h4 style={{ fontWeight: 'bold' }}>{AlumnoSeleciondado.NombreCompleto}</h4>
          <p>Matricula {AlumnoSeleciondado.NumeroCuenta}</p>
          <p>Familiares Estudiando {AlumnoSeleciondado.NumeroFamiliaresEstudiando}</p>
        </>
    }

    let PagosAlumno;

    if(this.state.AlumnoSeleciondado.length !== 0){
      if (this.state.ListaPagos.length !== 0) {
        PagosAlumno =
          this.state.ListaPagos.map((ListaPagos) => {
            return (
              <tr key={ListaPagos._id} hidden={ListaPagos.hide == true}>
                <td className="text-center">{ListaPagos.Cantidad}</td>
                <td className="text-left">{ListaPagos.NombreConcepto}</td>
                <td className="text-center">{moment(ListaPagos.FechaPago).format('DD-MM-YYYY')}</td>
                <td className="text-center">{"$" + parseFloat(ListaPagos.MontoTotal).toFixed(2)}</td>
                <td className="text-center">{"$" + parseFloat(ListaPagos.Monto).toFixed(2)}</td>
                <td className="pull-right">
                  <Button data-toggle="tooltip" data-placement="bottom" title="Agregar a la venta" color="info" round justIcon size="sm" className="mr-2" onClick={() => this.AgregarVenta(ListaPagos, AlumnoSeleciondado)}><Add /></Button>
                  <Button data-toggle="tooltip" data-placement="bottom" title="Cancelar pago pendiente" color="danger" round justIcon size="sm" onClick={() => this.openModalEliminarPago(ListaPagos)}><Close /></Button>
                </td>
              </tr>
            )
          })
      }
      else {
        PagosAlumno =
          <tr>
            <td colspan="6" style={{ textAlign: "center", fontWeight: "bold" }}><h4>
              {this.state.mensajePagos}
            </h4>
            </td>
          </tr>
      }
    }
    else{
      PagosAlumno =
          <tr>
            <td colspan="6" style={{ textAlign: "center", fontWeight: "bold" }}><h4>
              {"Seleccione un alumno"}
            </h4>
            </td>
          </tr>
    }

    var PagosVenta;
    // let Descuento = 0;
    // let DescuentoActivo = 0;
    if (this.state.PagoComprasAlumno.length > 0) {
      PagosVenta =
        this.state.PagoComprasAlumno.map((pago, index) => {
          // Descuento = parseFloat(pago.Pendiente) * (pago.descuento / 100);
          // DescuentoActivo = parseFloat(pago.Pendiente) - Descuento;

          let descPesos = 0;
          if(!isNaN(pago.descuentopesos)){
            descPesos = parseFloat(pago.descuentopesos);
          }

          return (
            <tr >
              {pago.CantEdit == false ?
                <td class="text-center" style={{ paddingTop: '2em' }}>{pago.Cantidad}</td> :
                <td>
                  <CustomInput
                    formControlProps={{
                      fullWidth: false,
                    }}
                    inputProps={{
                      onChange: this.handleChangePagos.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: pago.Cantidad,
                      name: "Cantidad",
                      id: index,
                      type: "number",
                    }}
                  />
                </td>
              }
              <td style={{ paddingTop: '2em' }}>{pago.NombreAlumno}</td>
              <td style={{ paddingTop: '2em' }}>{pago.Descripcion}</td>
              <td class="text-center" style={{ paddingTop: '2em' }}>{"$" + parseFloat(pago.Precio).toFixed(2)}</td>
              <td class="text-center" style={{ paddingTop: '2em' }}>{"$" + parseFloat(pago.Pendiente).toFixed(2)}</td>
              <td>
                <CustomInput
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    onChange: this.handleChangePagos.bind(this),
                    onFocus: this.handleFocus.bind(this),
                    onBlur: this.handleBlur.bind(this),
                    disabled: pago.Disabled,
                    value: pago.descuento,
                    name: "descuento",
                    id: index,
                    type: "number",
                  }}
                />
              </td>
              <td>
                <CustomInput
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    onChange: this.handleChangePagos.bind(this),
                    onFocus: this.handleFocus.bind(this),
                    onBlur: this.handleBlur.bind(this),
                    disabled: pago.Disabled,
                    value: pago.descuentopesos,
                    name: "descuentopesos",
                    id: index,
                    type: "number",
                  }}
                />
              </td>
              <td style={{ paddingTop: '2em' }}>{"$" + ((parseFloat(pago.Pendiente) - descPesos - (parseFloat(pago.Pendiente) * (pago.descuento / 100))) / 1.16).toFixed(2)}</td>
              <td style={{ paddingTop: '2em' }}>{"$" + (((parseFloat(pago.Pendiente) - descPesos - (parseFloat(pago.Pendiente) * (pago.descuento / 100))) / 1.16) * 0.16).toFixed(2)}</td>
              <td style={{ paddingTop: '2em' }}>{"$" + (parseFloat(pago.Pendiente) - descPesos - (parseFloat(pago.Pendiente) * (pago.descuento / 100))).toFixed(2)}</td>
              <td>
                <CustomInput
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    onChange: this.handleChangePagos.bind(this),
                    onFocus: this.handleFocus.bind(this),
                    onBlur: this.handleBlur.bind(this),
                    disabled:pago.Extemporaneo ? true : false,
                    value: (pago.Importe),
                    name: "Importe",
                    id: index,
                    type: "text",
                  }}
                />
              </td>
              <td class="pull-right">
                <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar de la venta" color="danger" disabled={pago.Extemporaneo ? true : false} round justIcon size="sm" onClick={() => {
                  this.setState({
                    show: true,
                    PagoEliminar: pago,
                  })
                  // this.EliminarVenta(pago)
                }}><Close /></Button>
              </td>
            </tr>)
        })
    }
    else {
      PagosVenta = (<tr><td colSpan={11}><h4 class="text-center">Favor de agregar productos para pagar</h4></td></tr>)
    }

    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <ModalBuscarAlumnos
          Titulo={this.state.Titulo}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalcobranza}
          op={this.state.op}
          parentCallback={this.handleCallback}
        />
    }

    if (this.state.showAgregarProd == true) {
      agregar =
        <ModalAgregarProducto
          Titulo={this.state.Titulo}
          showAgregarProd={this.state.showAgregarProd}
          eventClick={this.eventoModalProd}
          op1={this.state.op1}
          parentCallback={this.RecuperarListaProd}
        />
    }

    if (this.state.showEliminarPago == true) {
      agregar =
        <ModalEliminarPago
          Titulo={this.state.Titulo}
          PagoSelecionado={this.state.PagoSelecionado}
          showEliminarPago={this.state.showEliminarPago}
          eventClick={this.eventoModalEliminarPago}
          op2={this.state.op2}
        />
    }

    if (this.state.showCobrar == true) {
      agregar = (
        <ModalCobrar
          Titulo={"Cobrar"}
          showCobrar={this.state.showCobrar}
          Total={this.state.Total}
          ImporteTotal={this.state.ImporteTotal}
          ListaVenta={this.state.PagoComprasAlumno}
          eventClick={this.eventoModalCobrar}
          parentCallback={this.RecuperarMensajeCobrar}
        />
      );
    }
    if (this.state.showInscripcion == true) {
      agregar = (
        <ModalInscribirAlumno
          Titulo={"Inscripción"}
          showInscripcion={this.state.showInscripcion}
          AlumnoSeleciondado={this.state.AlumnoSeleciondado}
          eventClick={this.eventoModalgeneral}
        />
      );
    }
    if (this.state.showColegiatura == true) {
      agregar = (
        <ModalAdelantarColegiatura
          Titulo={"Colegiatura"}
          show={this.state.showColegiatura}
          AlumnoSeleciondado={this.state.AlumnoSeleciondado}
          eventClick={this.eventoModalgeneral}
        />
      );
    }
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }


    const { classes } = this.props;
    const handleFocus = (event) => event.target.select();
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>DATOS DEL ALUMNO</h4>
              </CardHeader>
              <CardBody>

                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    {DatosAlumno}
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={4} xl={3}>
                    <SeleccionarAlumno
                      fullWidth={true}
                      inactivo={this.state.checkedInactivos}
                      eventClick={this.handleCallback}
                    />
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checkedInactivos}
                            onChange={this.handleChange.bind(this)}
                            name="checkedInactivos"
                          />
                        }
                        label="Incluir alumnos inactivos en la búsqueda"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <GridContainer xs={12} sm={12} md={12} lg={12}>
                  <GridItem xs={8} sm={8} md={8} lg={9} xl={10}>
                    <h4 className={classes.cardTitle}>PAGOS PENDIENTES DEL ALUMNO</h4>
                  </GridItem>
                  {/* <GridItem xs={4} sm={4} md={4} lg={3} xl={2}>
                    <TextField
                      id="debugFechaActual"
                      fullWidth
                      // style={{width: "16em"}}
                      variant="outlined"
                      type="date"
                      format="DD-MM-YYYY"
                      size="small"
                      label="debugFechaActual"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.debugFechaActual,
                        name: "debugFechaActual",
                        id: "debugFechaActual",
                      }}
                    />
                  </GridItem> */}
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={6} md={8}>
                  </GridItem>

                </GridContainer>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem style={{ marginBottom: "1em" }} xs={12} sm={12} md={12}>
                    <div className="table-responsive table-wrapper-scroll-y">
                      <table className="table-sm table-hover">
                        <thead>
                          <th className="text-center">Cant.</th>
                          <th className="text-left">Descripción</th>
                          <th className="text-center">Fecha límite de pago</th>
                          <th className="text-center">Monto</th>
                          <th className="text-center">Pendiente</th>
                          <th className="text-center"></th>
                        </thead>
                        <tbody>
                          {PagosAlumno}
                        </tbody>
                      </table>
                    </div>
                  </GridItem>
                  {this.state.ocultarBotones == false ? <GridItem xs={12} sm={3} md={12} /* hidden={this.state.AlumnoSeleciondado.length == 0} */>
                    <Button className="pull-right" size="sm" type="submit" style={{ marginRight: "15px", marginTop: 0 }} color="info" round
                      onClick={() => this.openModalColegiatura()} ><AddCircle />Colegiatura</Button>

                    <Button className="pull-right" size="sm" type="submit" style={{ marginRight: "15px", marginTop: 0 }} color="info" round
                      onClick={() => this.openModalIncripcion()}><AddCircle />Inscripcion</Button>
                  </GridItem> : ""}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={18}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">DETALLE DE VENTA</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-right" style={{ marginRight: "15px", marginTop: 0 }}>Total: {convertirPesos(this.state.ImporteTotal)}</h4>
                    <h4 className="pull-right" style={{ marginRight: "15px", marginTop: 0 }}>IVA: {convertirPesos(this.state.IVAMostrar)}</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody style={{paddingTop: "0px"}}>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                      Agregar producto&nbsp;&nbsp;
                      <Autocomplete
                        freeSolo
                        id="Producto"
                        noOptionsText={'No hay opciones'}
                        style={{ width: "25em" }}
                        disableClearable
                        value={this.state.ProductoSelecionado}
                        inputValue={this.state.NombreProducto}
                        onChange={this.handleChangeAutocomplete.bind(this)}
                        onInputChange={this.InputChange.bind(this)}
                        options={this.state.ListaProducto}
                        getOptionLabel={(option) => option.Descripcion}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Nombre del producto"
                            margin="normal"
                            size="small"
                            variant="outlined"
                            InputProps={{ 
                              ...params.InputProps, 
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => this.handleClickEliminarTexto()}
                                    onMouseDown={this.handleMouseDownToken}
                                    edge="end"
                                  >
                                    <Clear />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              type: 'search',
                              name: "NombreProducto",
                              id: "NombreProducto",
                              //error: this.state.errorNombre,
                              //success: !this.state.errorNombre
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="table-responsive table-wrapper-scroll-y">
                      <table className="table-sm table-hover">
                        <thead>
                          <th class="text-center" style={{ width: "5%" }}>Cant.</th>
                          <th class="text-center">Alumno</th>
                          <th class="text-center">Descripción</th>
                          <th class="text-center" style={{ width: '5%' }}>Precio </th>
                          <th class="text-center" style={{ width: '5%' }}>Pendiente</th>
                          <th class="text-center" style={{ width: '7%' }}>Dcto. %</th>
                          <th class="text-center" style={{ width: '7%' }}>Dcto. $</th>
                          <th class="text-center" style={{ width: '7%' }}>Subtotal</th>
                          <th class="text-center" style={{ width: '7%' }}>IVA</th>
                          <th class="text-center" style={{ width: '7%' }}>Total</th>
                          <th class="text-center" style={{ width: '10%' }}>Importe/Abono</th>
                          <th class="text-center" style={{ width: '3%' }}></th>
                        </thead>
                        <tbody>
                          {PagosVenta}
                        </tbody>
                      </table>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button className="pull-right" size="sm" type="submit" style={{ marginRight: "15px", marginTop: 0 }} color="info" round
                      onClick={() => this.openModalCobrar()}><AddCircle />Cobrar</Button>

                    {/* <Button className="pull-right" size="sm" type="submit" style={{ marginRight: "15px", marginTop: 0 }} color="info" round
                      onClick={() => this.openModalBuscarProducto()}><AddCircle />Agregar Producto</Button> */}

                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <ReactToPrint
          trigger={() =>
            <Button id="imprime" style={{ display: 'none' }} color="primary"
            // onClick={() => this.props.eventClick(true)}
            ><i className="fa fa-check"></i>&nbsp;ACEPTAR</Button>
          }
          content={() => this.componentRef}
          copyStyles={true}
          onAfterPrint={() => this.setState({ VerImpresion: false })}
        />

        {
          // this.state.VerImpresion ?
          //   <ReciboPago
          //     ref={el => (this.componentRef = el)}
          //     pagos={this.state.PagosRealizados}
          //   /> : null
        }


        <div id="">
          <ModalPregunta
            Titulo={"Venta"}
            Cuerpo={"¿Está seguro de quitar producto de la venta?"}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Cobranza);