import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Select,MenuItem,InputLabel ,Stack ,FormControl,TablePagination} from "@material-ui/core";
import PresupuestoExcel from './ReporteExcel';
import ReporteIngresosExportacion from '../Reporteexportacion/ReporteExcel';
import Cargando from '../../../../components/Generales/ModalCargando';


class Egresos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Mes:'',
      Tipo:"ingreso",
      DatosReporte:[],
      VerResumen:false,
      Componente:null,
      Fecha: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      MostrarModalCargando: false,
      Categoria:[],
      SubCategoria:[],
      //Ingresos egresos exportacion
      FechaInicialExportacion: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaFinalExportacion: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      TipoExportacion:"ingreso",
      DatosReporteExportacion:[],
      VerReporteExportacion:false,

    };
    this.handleChange = this.handleChange.bind(this);


  }
  componentDidMount() {
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  ObtenerReporteIngresos(){
    this.setState({MostrarModalCargando: true})
    fetch(Globales.Url + 'Ingresos/Ingresos/ListadoIngresosMes', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Fecha:this.state.Fecha, FechaFinal: this.state.FechaFinal})
    }
    ).then(res => res.json())
      .then(data => {
        this.setState({MostrarModalCargando: false})
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
         } else { 
          //console.log(data.data)
           if(data.data.length > 0){
            this.setState({DatosReporte: data.data,VerResumen: true});
           }else{
            this.setState({ colorNotificacion: 'danger',mensaje:"No se encontro información para el reporte"});
            this.showNotification();
            this.setState({VerResumen: false});
           }
        }
      })
  }

  ObtenerReporteEgresos(){
    this.setState({MostrarModalCargando: true})
    fetch(Globales.Url + 'Egresos/Egresos/ListadoEgresosMes', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Fecha:this.state.Fecha, FechaFinal: this.state.FechaFinal})
    }
    ).then(res => res.json())
      .then(data => {
        this.setState({MostrarModalCargando: false})
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
         } else { 
           if(data.data.length > 0){
             //console.log(data.data)
            this.setState({DatosReporte: data.data,VerResumen: true});
           }else{
            this.setState({ colorNotificacion: 'danger',mensaje:"No se encontro información para el reporte"});
            this.showNotification();
            this.setState({VerResumen: false});
           }
        }
      })
  }

  ObtenerReporteEgresosExportacion(){
    fetch(Globales.Url + 'EgresosExportacion/EgresosExportacion/ObtenerListaEgresosExportacionReporte', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({FechaInicial: this.state.FechaInicialExportacion, FechaFinal: this.state.FechaFinalExportacion})
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
         } else { //Filtrado exitoso
          //console.log(data.data)
          if(data.data.length == 0){
            this.setState({ colorNotificacion: 'danger', mensaje: "Datos inexistentes" });
            this.showNotification();
          }else{
            this.setState({DatosReporteExportacion:data.data },()=>{
              this.setState({
                VerReporteExportacion: true
              })
            })
            //this.setState({ DatosReporteExportacion: data.data ,VerReporteExportacion: true});
          }
        }
      })
      .catch(err => console.error(err));
  }
  ObtenerIngresosExportacion(){
    fetch(Globales.Url + 'IngresosExportacion/IngresosExportacion/ObtenerListaIngresosExportacionReporte', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({FechaInicial: this.state.FechaInicialExportacion, FechaFinal: this.state.FechaFinalExportacion})
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
         } else { //Filtrado exitoso
          //console.log(data.data)
          if(data.data.length == 0){
            this.setState({ colorNotificacion: 'danger', mensaje: "Datos inexistentes" });
            this.showNotification();
          }else{
            this.setState({ DatosReporteExportacion: data.data ,VerReporteExportacion: true});
          }
        }
      })
      .catch(err => console.error(err));
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }
  //Función para obtener el texto de los inputs
handleChange(e) {
  const { name, value } = e.target;
  this.setState({ [name]: value });
}
  generarReporte(){
    if(this.state.Tipo == 'ingreso'){
      this.ObtenerReporteIngresos();
    }else{
      this.ObtenerReporteEgresos();
    }
  }

  generarReporteExportacion(){
    if(this.state.TipoExportacion == 'ingreso'){
      this.ObtenerIngresosExportacion();
    }else{
      this.ObtenerReporteEgresosExportacion();
    }
  }

  eventoModalDescarga = (mensaje, colorNotificacion, listaMesesActualizada) => {
    this.setState({ VerReporteExportacion: false, DatosReporteExportacion: [] })
    
  }
   
  eventoModalDescargaIngresosNormal = (mensaje, colorNotificacion, listaMesesActualizada) => {
    this.setState({ VerResumen: false, DatosReporte: [] })
    
  }

  render() {
    return (
      <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>              
              <CardHeader color="info" text>
                                <h4 >
                                    Reportes de Ingresos o Egresos
                                </h4>
                            </CardHeader>
                <CardBody>
                  <GridContainer>
                      <GridItem  xs={12} sm={12} md={3} lg={2}>
                      <FormControl
                            fullWidth
                            style={{marginTop: "27px"}}
                            error= {this.state.errorEstatusFactura}>
                        <InputLabel>Tipo movimiento</InputLabel>
                        <Select
                          id="Tipo"
                          name="Tipo"
                          onChange={this.handleChange}
                          value={this.state.Tipo}
                          inputProps={{
                            required: true,
                            error: this.state.errorTipo,
                            success: !this.state.errorTipo
                          }}
                          >
                          <MenuItem value={'ingreso'}>Ingreso</MenuItem>
                          <MenuItem value={'egreso'}>Egreso</MenuItem>
                        </Select>
                        </FormControl>
                       
                    </GridItem >
                    <GridItem xs={12} sm={12} md={3} lg={2}>
                                        <CustomInput
                                            labelText="Fecha"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.handleChange,
                                                required: true,
                                                value: this.state.Fecha,
                                                name: "Fecha",
                                                id: "Fecha",
                                                type: "date",
                                            }}
                                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} lg={2}>
                                        <CustomInput
                                            labelText="Fecha Final"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.handleChange,
                                                required: true,
                                                value: this.state.FechaFinal,
                                                name: "FechaFinal",
                                                id: "FechaFinal",
                                                type: "date",
                                            }}
                                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2} lg={1}>
                        <Button size="sm" type="submit" className="pull-left mt-5" color="info" round 
                        onClick={()=>{ this.generarReporte()}}>Generar reporte</Button>
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={2} lg={1}>
                        {this.state.Componente == null ? this.state.Componente : null}
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={2} lg={1}>
                      { this.state.VerResumen == true ?
                            <PresupuestoExcel 
                            DatosReporte={this.state.DatosReporte} 
                            Tipo={this.state.Tipo} 
                            eventClick={this.eventoModalDescargaIngresosNormal}
                            c={this.state.Categoria} s={this.state.SubCategoria}/>
                          : null
                      }
                      </GridItem>
                  </GridContainer>
                
                 
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>              
              <CardHeader color="info" text>
                                <h4 >
                                    Reportes de Ingresos o Egresos de Exportación
                                </h4>
                            </CardHeader>
                <CardBody>
                  <GridContainer>
                      <GridItem  xs={12} sm={12} md={3} lg={2}>
                      <FormControl
                            fullWidth
                            style={{marginTop: "27px"}}
                            error= {this.state.errorEstatusFactura}>
                        <InputLabel>Tipo movimiento</InputLabel>
                        <Select
                          id="TipoExportacion"
                          name="TipoExportacion"
                          onChange={this.handleChange}
                          value={this.state.TipoExportacion}
                          inputProps={{
                            required: true,
                            error: this.state.errorTipoExportacion,
                            success: !this.state.errorTipoExportacion
                          }}
                          >
                          <MenuItem value={'ingreso'}>Ingreso Exportación</MenuItem>
                          <MenuItem value={'egreso'}>Egreso Exportación</MenuItem>
                        </Select>
                        </FormControl>
                       
                    </GridItem >
                    <GridItem xs={12} sm={12} md={3} lg={2}>
                                        <CustomInput
                                            labelText="Fecha Inicial"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.handleChange,
                                                required: true,
                                                value: this.state.FechaInicialExportacion,
                                                name: "FechaInicialExportacion",
                                                id: "FechaInicialExportacion",
                                                type: "date",
                                            }}
                                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} lg={2}>
                                        <CustomInput
                                            labelText="Fecha Final"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.handleChange,
                                                required: true,
                                                value: this.state.FechaFinalExportacion,
                                                name: "FechaFinalExportacion",
                                                id: "FechaFinalExportacion",
                                                type: "date",
                                            }}
                                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2} lg={1}>
                        <Button size="sm" type="submit" className="pull-left mt-5" color="info" round 
                        onClick={()=>{ this.generarReporteExportacion()}}>Generar reporte</Button>
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={2} lg={1}>
                        {this.state.Componente == null ? this.state.Componente : null}
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={2} lg={1}>
                      { this.state.VerReporteExportacion == true ?
                            <ReporteIngresosExportacion 
                            DatosReporte={this.state.DatosReporteExportacion} 
                            Tipo={this.state.TipoExportacion}
                            eventClick={this.eventoModalDescarga}
                            c={this.state.Categoria} s={this.state.SubCategoria}/>
                          : null
                      }
                      </GridItem>
                  </GridContainer>
                
                 
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <Cargando
                    show={this.state.MostrarModalCargando}
                    Cuerpo="Cargando"
                />

          <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Egresos);