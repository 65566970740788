import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import ModalPregunta from "components/Generales/ModalPregunta";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import cookie from 'js-cookie'

var Permisos = localStorage.getItem("Permisos") == null ? { Modulo:[] } : JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}
else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

class ModalEliminarPago extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
        BuscarAlumno: "",
      MostrarModal:false,
      _id: null,
      PagoSelecionado: [],
      MotivoCancelacion: "",
      ListaUsuarios: [],
      Usuario: "usuarios",
      Token: "",
      disabled:false,
      errorMotivoCancelacion: false,
      errorToken: false,
      errorUsuarios: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      show:false,
      MensajeEliminar:"",
      Validado:false,
      cargando: false,
      CuerpoCargando: "Cargando",
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    //console.log(this.props)
    if (this.props.op2 == 1) {
      this.setState({       
        //_id: this.props._id,
        PagoSelecionado: this.props.PagoSelecionado
      })
    }

    this.ObtenerUsuarios();
    let IDcookie = JSON.parse(cookie.get('id'));
    this.setState({_id: IDcookie})
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  FormValidacion(){
    let valido = true;


    if(this.state.MotivoCancelacion == ""){
      valido = false;
      this.setState({errorMotivoCancelacion: true});
    }
    
    if(this.state.Token == "" || this.state.Usuario == "usuarios"){
      valido = false;
      this.setState({ErrorDatos:true})
    }

    if(valido){
      let usuario = this.state.ListaUsuarios.find(e => e._id == this.state.Usuario);
      //console.log(usuario)

      if(usuario.Token == this.state.Token){
        this.EliminarPago();
        //console.log("Entro")
      }
      else{
        this.setState({ErrorDatos:true, mensaje: "El token proporcionado no es valido", colorNotificacion: "danger"});
        this.showNotification();
      }
    }
  }

  ValidarModulo(value){
    let valido = false;
    let usuario = this.state.ListaUsuarios.find(e => e._id == this.state.Usuario);

    if(usuario.ListaModulos !== undefined && usuario.ListaModulos !== null){
      if(usuario.ListaModulos.Modulo.length > 1){
        usuario.ListaModulos.Modulo.map(modulo => {
          if(modulo.NumeroModulo == value){
            valido = true
          }
        })
      }
      else{
        if(usuario.ListaModulos.Modulo.NumeroModulo == value){
          valido = true
        }
      }
    }

    return valido
  }


  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------
  EliminarPago() {
    let Metodo = "EliminarPagoSinAutorizar";

    if(this.ValidarModulo("53")){
      Metodo = "EliminarPago";
      //console.log("Es administrador, cancelacion autorizada")
    }

    // if(Autorizado == 0){
    //   Metodo="EliminarPago"
    // }
    // else{
    //   Metodo="EliminarPagoSinAutorizar";
    // }

    this.setState({cargando: true})
    fetch(Globales.Url + 'Cobranza/Cobranza/'+Metodo, {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify(this.state)
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso        
          //console.log(data.ListaProducto);
          this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, cargando: false });
          this.closeModal(data.mensaje);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }

  ObtenerUsuarios(){
    fetch(Globales.Url + 'Cobranza/Cobranza/ListaUsuarios', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify(this.state)
    })
    .then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
         } else { //Filtrado exitoso
            //console.log("📦",data.ListaUsuarios);
            var ListaUsers = []
            data.ListaUsuarios.map( user => {
              if ("Token" in user) {
                ListaUsers.push(user);
              }
            })
            this.setState({ ListaUsuarios: ListaUsers});
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));; 
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  RegresarMensaje(mensaje) {
    this.setState({mensaje:mensaje, op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick('esc');
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  eventoModalEliminar (correcto) {
    // console.log(correcto)
    this.setState({show:false})
    if(correcto == true){
      this.EliminarPago(false)
    }
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;
    //console.log(this.state.PagoSelecionado);

    let agregar;
    if(this.state.cargando){
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      )
    }
    return (
      <div>
        <Modal
          isOpen={this.props.showEliminarPago}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Motivo de cancelación de pago"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.MotivoCancelacion,
                      name: "MotivoCancelacion",
                      id: "MotivoCancelacion",
                      type: "text",
                      error: this.state.errorMotivoCancelacion,
                      success: !this.state.errorMotivoCancelacion
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">
              Autorización del movimiento
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <Select
                    value={this.state.Usuario}
                    onChange={this.handleChange}
                    name="Usuario"
                    fullWidth={true}
                    variant="outlined"
                    // label="Productos"
                    style={{marginTop: "25px"}}
                    error={this.state.ErrorDatos}
                    success={!this.state.errorUsuarios}
                  >
                    <MenuItem value={"usuarios"} disabled={true}>
                    Usuarios
                    </MenuItem>
                    {this.state.ListaUsuarios.map(
                      (usuario) => (
                        <MenuItem value={usuario._id}>
                          {usuario.Usuario}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Token"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      //required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Token,
                      name: "Token",
                      id: "Token",
                      type: "password",
                      error: this.state.ErrorDatos,
                      success: !this.state.errorToken
                    }}
                  />
                </GridItem>
              </GridContainer>
              {/* Botones */}
              <div className='mt-4'>
                  <GridItem>
                    <Button tabIndex="1" size='sm' round onClick={() => this.props.eventClick('esc')}>&nbsp;Salir sin cancelar</Button>
                    <Button tabIndex="0" id="btnGuardar" size='sm' color='danger' className='pull-right' round onClick={this.FormValidacion.bind(this)}>&nbsp;Cancelar pago</Button>
                  </GridItem>
                </div>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar pago"}
            Cuerpo={this.state.MensajeEliminar}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalEliminarPago);