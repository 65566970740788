// core components/views for Admin layout
import PaisesCat from "../views/Modulos/Catalogos/Paises/Paises";
import CatEstados from "../views/Modulos/Catalogos/Estados/Estado";
import CatMunicipios from "../views/Modulos/Catalogos/Municipios/Municipios";
import CatIdiomas from "../views/Modulos/Catalogos/Idiomas/Idiomas";
import CatAulas from "../views/Modulos/Catalogos/Aulas/Aulas";
import CatEmpresas from "../views/Modulos/Catalogos/Empresas/Empresas";
import CatProveedores from "../views/Modulos/Catalogos/Proveedores/Proveedores";
import CatConceptosPago from "../views/Modulos/Catalogos/ConceptosDePagos/ListaConceptosDePagos";
import ConceptodeDescuento from "../views/Modulos/Catalogos/ConceptoDescuento/Conceptodescuento";
import CatTipoGastos from "../views/Modulos/Catalogos/TipoDeGastos/TipoDeGastos";
import CatProductos from "../views/Modulos/Catalogos/Productos/Productos";
import CatUsuarios from "../views/Modulos/Catalogos/Usuarios/Usuarios";
import CatNivel from "../views/Modulos/Catalogos/Niveles/Niveles";
import CatTipoConstancia from "../views/Modulos/Catalogos/TipoConstancia/TipoConstancia";
import CatTipoReglamento from "../views/Modulos/Catalogos/TipoReglamento/TipoReglamento";

// @material-ui/icons
import { Public, AddLocation, Place, TrendingUp, AccountCircle, Translate, MeetingRoom, Domain,
  Group, Receipt, Description, List, Book,InsertDriveFile,Edit } from "@material-ui/icons";

const CatalogosRoutes = [
  {
    path: "/paises",
    name: "Países",
    icon: Public,
    component: PaisesCat,
    layout: "/Catalogos",
    idModulo: "20",
  },
  {
    path: "/estados",
    name: "Estados",
    icon: AddLocation,
    component: CatEstados,
    layout: "/Catalogos",
    idModulo: "21",
  },
  {
    path: "/municipio",
    name: "Municipios",
    icon: Place,
    component: CatMunicipios,
    layout: "/Catalogos",
    idModulo: "22",
  },
  {
    path: "/niveles",
    name: "Niveles",
    icon: TrendingUp,
    component: CatNivel,
    layout: "/Catalogos",
    idModulo: "23",
  },
  {
    path: "/idiomas",
    name: "Idiomas",
    icon: Translate,
    component: CatIdiomas,
    layout: "/Catalogos",
    idModulo: "24",
  },
  {
    path: "/aulas",
    name: "Aulas",
    icon: MeetingRoom,
    component: CatAulas,
    layout: "/Catalogos",
    idModulo: "25",
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: AccountCircle,
    component: CatUsuarios,
    layout: "/Catalogos",
    idModulo: "28",
  },
  {
    path: "/Empresas",
    name: "Empresas",
    icon: Domain,
    component: CatEmpresas,
    layout: "/Catalogos",
    idModulo: "5",
  },
  {
    path: "/Proveedores",
    name: "Proveedores",
    icon: Group,
    component: CatProveedores,
    layout: "/Catalogos",
    idModulo: "26",
  },
  {
    path: "/ConceptodePago",
    name: "Concepto de pagos",
    icon: Receipt,
    component: CatConceptosPago,
    layout: "/Catalogos",
    idModulo: "29",
  },
  {
    path: "/ConceptodeDescuento",
    name: "Concepto de descuento",
    icon: Description,
    component: ConceptodeDescuento,
    layout: "/Catalogos",
    idModulo: "35",
  },
  {
    path: "/TipoDeGastos",
    name: "Tipo de gastos",
    icon: List,
    component: CatTipoGastos,
    layout: "/Catalogos",
    idModulo: "30",
  },
  {
    path: "/TipoDeConstancia",
    name: "Tipo de constancias",
    icon: InsertDriveFile,
    component: CatTipoConstancia,
    layout: "/Catalogos",
    idModulo: "31",
  }, 
  {
    path: "/Reglamento",
    name: "Reglamentos",
    icon: Edit,
    component: CatTipoReglamento,
    layout: "/Catalogos",
    idModulo: "11"
  },
  {
    path: "/Productos",
    name: "Productos y Libros",
    icon: Book,
    component: CatProductos,
    layout: "/Catalogos",
    idModulo: "27",
  },
];

let Permisos =localStorage.getItem("Permisos") == null ? {Modulo:[]}:JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

// console.log("🌌Permisos ", (Permisos).sort((a, b) => a.NumeroModulo - b.NumeroModulo))
var dashboardRoutesAccess = [];

for (var keyRoutes = 0; keyRoutes < CatalogosRoutes.length; keyRoutes++) {
  if (Permisos.find(permiso => permiso.NumeroModulo == CatalogosRoutes[keyRoutes].idModulo)) {
    dashboardRoutesAccess.push(CatalogosRoutes[keyRoutes]);
  }
}

export default dashboardRoutesAccess;
