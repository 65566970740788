import React, { Component } from 'react';

import { Card, CardBody, CardGroup, Col, Container, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap';
import { Error, Warning } from "@material-ui/icons";

import { css } from 'emotion';
// First way to import
import { PulseLoader, SyncLoader } from 'react-spinners';
import Snackbar from "components/Snackbar/Snackbar.jsx";

export default class ModalCargandoDatos extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      Cuerpo:"",
      show: false,
      loading: true,
      loadingOffline: true,
      Interval: null,
    }
  }
  componentDidMount(){
    // this.setState({
    //   Cuerpo: this.props.Cuerpo,
    // })
    // this.state.Interval = setInterval(() => {
    //   if (!navigator.onLine) {
    //     this.setState({
    //       loading: false,
    //       loadingOffline: false,
    //     })
    //   }
    // }, 250)
  }

  componentWillUnmount(){
    // window.clearInterval(this.state.Interval)
  }

  render() {
    var { Cuerpo,show } = this.props
    // var { loading, loadingOffline } = this.state

    // if (Cuerpo != "Cargando") {
    //   loading = false
    // }

    // let agregar 
    // if(!loadingOffline){
    //   agregar = <Snackbar
    //     place="tl"
    //     color={"danger"}
    //     message={"No tiene conexión. Por favor, vuelve a intentarlo"}
    //     open={true}
    //     // closeNotification={() => this.setState({ br: false })}
    //     // close
    //   />
    // }
    
    return (
      <div>
        {/* {agregar} */}
        {/* <Modal isOpen={loading ? this.props.show: true } className="static-modal modal-dialog-centered"> */}
        <Modal isOpen={this.props.show} className="static-modal modal-dialog-centered">

          <ModalBody  >

            <Row className="justify-content-center" style={{margin:"-55px"}}>
              <Col md="11">
                <CardGroup>

                  {/* <Card className={loading ?  "text-white bg-secondary " : "text-white bg-danger "} > */}
                  <Card className={"text-white bg-secondary"} >
                    <CardBody className="text-center">
                      <div className="mt-2">
                        <h4>{this.props.Cuerpo}</h4>

                      </div>
                    </CardBody>
                  </Card>
                  <Card className=" border-secondary">
                    {/* { loading ?  <CardBody >
                      <PulseLoader
                        // className={override}
                        sizeUnit={"px"}
                        size={18}
                        color={'gray'}
                        loading={this.state.loading}

                        className="text-center mt-3"
                      />
                    </CardBody> : <CardBody><div style={{margin: "auto", textAlign: "center"}}><Error fontSize="large" /></div></CardBody>} */}
                    <CardBody >
                      <PulseLoader
                        // className={override}
                        sizeUnit={"px"}
                        size={18}
                        color={'gray'}
                        loading={this.state.loading}

                        className="text-center mt-3"
                      />
                    </CardBody>
                  </Card>

                </CardGroup>
              </Col>
            </Row>





          </ModalBody>

        </Modal>
      </div>
    );
  }
}
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;