import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Edit, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'

class ModalDocumentos extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      _id: '',
      idReglamento: '',
      NumeroReglamento: '',
      NombreReglamento: '',
      ListaIdiomas: [],
      ListaReglamentos: [],
      IdiomaSeleccionado: "",
      disabled: false,
      colorNotificacion: "success",
      mensaje: "",
      ShowToken: false,
      br: false,
      showFirma:false,
      showDescargar:false,
      errorConstancia: false,
      errorIdioma: false,
      cargando: false,
      CuerpoCargando: "Cargando",
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  async componentDidMount() {
    this.setState({ cargando: true });
    await this.ObtenerIdiomasEnCursoAlumno();
    await this.ObtenerlistaReglamentos();
    this.setState({ cargando: false })
  }

  async ObtenerIdiomasEnCursoAlumno() {
    await fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerIdiomasEnCursoAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idAlumno: this.props.idAlumno })
    }
    ).then(res => res.json())
      .then(data => {
        // 
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: "El alumno no está inscrito en ningún idioma", colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          
          if (data.ListaIdiomas.length > 1) {
            this.setState({ ListaIdiomas: data.ListaIdiomas, })
          }
          else {
            let aux = [];
            aux.push(data.ListaIdiomas);
            this.setState({ ListaIdiomas: aux, })
          }

        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async ObtenerlistaReglamentos() {
    await fetch(Globales.Url + "Catalogos/TipoReglamento/ObtenerListaNombreReglamento", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      // body: JSON.stringify({}),
    }).then((res) => res.json())
      .then((data) => {
        // console.log(data)
        if (data.error) {
          //Error al obtener los registros
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger" });
          this.showNotification();
        } else {
          let registros = []
          if (data.data.length >= 1) {
            registros = data.data
          } else {
            registros = [data.data]
          }

          this.setState({
            ListaReglamentos: registros,
          });
        }
      });
  }

  async ObtenerTokenReglamento() {
    this.setState({ cargando: true });
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    
    if(!this.formvalido()){
      this.setState({ mensaje:"Complete todos los campos", colorNotificacion: "danger", Activo: 1, cargando: false });
      this.showNotification();
    }else{
    await fetch(Globales.Url + 'Firmas/Firmas/ObtenerTokenReglamento', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idAlumno: this.props.idAlumno, idReglamento: this.state.idReglamento, idIdioma: this.state.IdiomaSeleccionado !== "" ? this.state.IdiomaSeleccionado : idVacio})
    }
    ).then(res => res.json())
      .then(data => {
        
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.setState({ ShowToken: true, Token: data.data, cargando: false });
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }
  }

  async VerificarFirmaReglamento(idReglamento, ididioma) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Firmas/Firmas/VerificarFirmaReglamento', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idAlumno: this.props.idAlumno, idReglamento: idReglamento, idIdioma: ididioma })
    }
    ).then(res => res.json())
      .then(data => {

        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          if (data.data == 0) {
            this.setState({ showFirma: true, showDescargar: false, cargando: false });
          } else {
            this.setState({ showFirma: false, showDescargar: true, cargando: false });
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }
  formvalido(){
    let valido = true
    if(this.state.idReglamento == ""){
      valido = false
    }
    if (!this.state.showIdioma && this.state.IdiomaSeleccionado == "") {
      valido = false
    }

    return valido
  }

  async ObtenerReglamentoFDF() {
      await fetch(Globales.Url + 'archivos/ObtenerReglamentoFDF', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({
          idAlumno: this.props.NumeroIdAlumno,
          idReglamento: this.state.NumeroReglamento
        })
      }
      ).then(res => res.json())
        .then(data => {
          
          if (data.error) { //Error en la respuesta
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
            this.showNotification();
          } else { //Filtrado exitoso
            this.descargarPDF(data.data)
            this.setState({ cargando: false });
          }
        })
        .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    
  }

  descargarPDF(archivoBase64) {
    // var a = JSON.parse(localStorage.getItem("factid"));
    let nombre = "Reglamento Firmado";
    const linkSource = `${archivoBase64}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  } 

  closeModal() {
    this.props.eventClick(false);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

    // console.log(name);
    if (name == "idReglamento") {

      let indexReglamento = this.state.ListaReglamentos.find((el) =>
        JSON.stringify(value) == JSON.stringify(el._id)
      )

      // console.log(indexReglamento);
      this.setState({ 
        showIdioma: indexReglamento ? true : false,
        NumeroReglamento: indexReglamento ? indexReglamento.NumeroId : ""
      });

      this.VerificarFirmaReglamento(value, idVacio)
    }

    if(name == "IdiomaSeleccionado"){
      this.VerificarFirmaReglamento(this.state.idReglamento, value)
    }

    this.setState({ [name]: value, showFirma: false, showFirma: false });
  }

  // verificarCampos() {
  //   let valido = true;
  //   const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
   
  //   if (JSON.stringify(this.state.IdiomaSeleccionado) == JSON.stringify(idVacio)) {
  //     valido = false;
  //     this.setState({ errorIdioma: true });
  //   }
  //   else {
  //     this.setState({ errorIdioma: false });
  //   }

  //   if (valido) {
  //     // this.props.eventClick(this.state.TipoReglamento, this.state.IdiomaSeleccionado);
  //   }
  //   else {
  //     this.setState({ mensaje: "Complete los campos", colorNotificacion: "danger" });
  //     this.showNotification();
  //   }
  // }

  cerrarModal = () => {
    this.props.eventClick(false)
  }

  render() {
    const { classes } = this.props;
    // console.log(this.state.showIdioma)
    // console.log(this.state.idIdioma)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    if (this.state.ShowToken) {
      agregar = (
        <div>
          <Modal
            isOpen
            className="static-modal"
            size="sm"
            toggle={() => this.setState({ ShowToken: false })}
            backdrop={true}
          >
            <Card>
              <CardHeader color="info">TOKEN DE AUTENTICACION</CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem style={{ textAlign: "center" }} xs={12} sm={12} md={12}>
                    <h3>{this.state.Token}</h3>
                  </GridItem>
                  <Button color="danger" tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.setState({ ShowToken: false })}><Clear />&nbsp;cerrar</Button>
                </GridContainer>
              </CardBody>
            </Card>
          </Modal>
        </div>
      )
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showModalTipoReglamento}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControl} style={{ width: "100%", marginBottom: "10px" }} size="small" variant="outlined">
                    <InputLabel id="TipoReglamento">Tipo de reglamento</InputLabel>
                    <Select
                      labelId="TipoReglamento"
                      //fullWidth={true}
                      value={this.state.idReglamento}
                      onChange={this.handleChange.bind(this)}
                      name="idReglamento"
                      label="Tipo de reglamento"
                    >
                      {this.state.ListaReglamentos.length == 0 ? null :
                          this.state.ListaReglamentos.map((idioma) => {
                            return (<MenuItem value={idioma._id} >{idioma.Nombre}</MenuItem>)
                          })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem hidden={this.state.showIdioma} xs={12} sm={12} md={12}>
                  <FormControl style={{ width: "100%" }} className={classes.formControl} size="small" variant="outlined">
                    <InputLabel id="idioma">Idioma</InputLabel>
                    <Select
                      labelId="idioma"
                      //fullWidth={true}
                      value={this.state.IdiomaSeleccionado}
                      onChange={this.handleChange.bind(this)}
                      name="IdiomaSeleccionado"
                      label="Idioma"
                      error={this.state.errorIdioma}
                    >
                      <MenuItem disabled value="">Seleccione un idioma</MenuItem>
                      {this.state.ListaIdiomas.map(idioma =>
                        <MenuItem value={idioma._id}>{(idioma.Nombre)}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem> 
                <GridItem xs={12} sm={12} md={12}>
                  <Button hidden={!this.state.showFirma} color="info" tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.ObtenerTokenReglamento()}><Edit />&nbsp;Firmar reglamento</Button>
                  <Button hidden={!this.state.showDescargar} color="info" tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.ObtenerReglamentoFDF()}><Save />&nbsp;Generar</Button>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick(false)}><Clear />&nbsp;Cerrar</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalDocumentos);