import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import ModalCargando from "../../../../components/Generales/ModalCargando.js";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalInfoVenta from "./ModalInfoVenta.jsx";
import Facturacion from "./Facturacion.jsx";
import ModalAsignarFolioFiscal from "./ModalAsignarFolioFiscal.jsx";
import SeleccionarAlumno from "../../../Generales/ComponentesEmpresas/SeleccionarAlumno.jsx"
import ModalFactura from "./ModalFactura.jsx"
import ModalAsignarFolioFactura from "./ModalAsignarFolioFactura.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Add, Print, Save, Email } from "@material-ui/icons";
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
import ModalEnviarCorreoFacturas from "./ModalEnviarCorreoFacturas.jsx";
import ExcelReportesListados from "../../FormatosImpresion/ExcelReportesListados.jsx";

var Permisos = localStorage.getItem("Permisos") == null ? { Modulo: [] } : JSON.parse(localStorage.getItem("Permisos"))
if (Permisos.length > 1) {
  Permisos = Permisos
}
else {
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}


class FacturacionListado extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaEmpresas: [],
      Folios: [],
      AlumnoSeleciondado: [],
      VentaSeleccionada: {},
      ArrayIdVentas: [],
      ArrayIdVentasVirtual: [],
      NombreAlumno: [],
      SumaTotalFacturados: {},
      ventaAux: {},
      ArrEmpresas: [],
      opcionBuscar: "FolioFactura",
      FolioFactura: "",
      IDEmpresa: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      NombreEmpresa: "",
      FechaInicio: new Date().toLocaleDateString("fr-CA", {
        timeZone: "America/Mazatlan",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      FechaFin: new Date().toLocaleDateString("fr-CA", {
        timeZone: "America/Mazatlan",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      TotalFS: 0,
      Disabled: false,
      cargando: false,
      mensaje: "",
      mensajePagos: "",
      br: false,
      colorNotificacion: 'success',
      colorTableRow: "white",
      notificacion: false,
      CuerpoCargando: "Cargando",
      botonDesabilitado: false,
      show: false,
      showFacturacion: false
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    //this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.obtenerEmpresasActivas();
    this.ListaGeneralFactura()
  }

  handleCallback = (AlumnoSeleciondado) => {
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    if(JSON.stringify(AlumnoSeleciondado.DatosFiscales) == JSON.stringify(idVacio)){
      this.setState({ mensaje: "El alumno no tiene datos fiscales registrados", colorNotificacion: "danger" , botonDesabilitado: true, AlumnoSeleciondado: AlumnoSeleciondado})
      this.showNotification();
    }
    else{
      this.setState({ AlumnoSeleciondado: AlumnoSeleciondado, botonDesabilitado: false })
    }
  }
  RegresarFolioNvoRecibo = (Folio, mensaje) => {
    this.setState({ Folios: [...this.state.Folios, Folio], Total: this.state.Total + parseFloat(Folio['Total']), mensaje: mensaje, colorNotificacion: "success" })
    this.showNotification();
  }

  //Función para pasar datos de los alumnos desde el modal hacia la vista
  RegresarMensaje = (mensaje) => {
    this.setState({ mensaje: mensaje, colorNotificacion: "success" });
    this.showNotification();
  }

  //Función para obtener los pagos pendientes de los alumnos
  obtenerEmpresasActivas() {
    this.setState({ cargando: true })
    fetch(Globales.Url + 'Facturacion/Facturacion/ListadoEmpresas', {  //ObtenerInformacionMultipleEmpresa  //ListadoEmpresas
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
    }).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso

          if (data.ListaEmpresas.length > 1) {
            this.setState({ ListaEmpresas: data.ListaEmpresas, Activo: 1, cargando: false });
          }
          else {
            let ListaAuxEmpresas = [];
            ListaAuxEmpresas.push(data.ListaEmpresas);
            this.setState({ ListaEmpresas: ListaAuxEmpresas, Activo: 1, cargando: false });
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  obtenerInfoMultiEmpresas(ids) {
    this.setState({ cargando: true })
    fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerInformacionMultipleEmpresa', {  //ObtenerInformacionMultipleEmpresa  //ListadoEmpresas
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ ids: ids })
    }).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          if (data.idEmpresa.length > 1) {
            this.setState({ ArrEmpresas: data.idEmpresa })
          }
          else {
            let aux = [];
            aux.push(data.idEmpresa);
            this.setState({ ArrEmpresas: aux })
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  //Función para obtener las ventas por folio de venta
  buscar() {
    this.setState({ 
      VerResumenListado: false,
      cargando: true
    })
    fetch(Globales.Url + 'Facturacion/Facturas/buscar', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({
        Folio: this.state.FolioFactura,
        Alumno: this.state.AlumnoSeleciondado.length !== 0 ? this.state.AlumnoSeleciondado : null,
        Inicio: this.state.FechaInicio,
        Fin: this.state.FechaFin,
      })
    }).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          let Folios = []
          if(data.data.length !== 0){
            if (data.data.length > 1) {
              Folios = data.data
            }
            else {
              Folios.push(data.data);
            }
          }

          this.setState({
            Folios: Folios,
            cargando: false,
          }, () => this.ExportarExcel())
        }
      })
  }

  ObtenerNombreAlumno(IdAlumno) {
    this.setState({ cargando: true })
    fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerNombreAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: IdAlumno._idAlumno })
    }).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", NombreAlumno: "", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso

          let Folios = this.state.Folios
          Folios.map(folio => {
            if (JSON.stringify(folio._idAlumno) == JSON.stringify(IdAlumno._idAlumno)) {
              folio.NombreAlumno = data.NombreAlumno;
            }
          })

          this.setState({ Folios: Folios, cargando: false })
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  ListaGeneralFactura() {
    this.setState({ 
      VerResumenListado: false,
      cargando: true
    })
    fetch(Globales.Url + 'Facturacion/Facturas/Listar', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Inicio: this.state.FechaInicio, Fin: this.state.FechaFin, Folio: this.state.FolioFactura })
    }).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          // this.showNotification();
        } else { //Filtrado exitoso
          let Folios = []
          if (data.data.length > 1) {
            Folios = data.data
          }
          else {
            Folios.push(data.data);
          }

          this.setState({
            Folios: Folios,
            cargando: false,
          }, () => this.ExportarExcel())
        }
      })
      .catch(err => {
        this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true })
      });
  }


  async ObtenerXml(uuid, notas) {
    this.setState({ cargando: true })
    // fetch(Globales.Url + 'archivos/guardarXmlFactura', {
    fetch(Globales.Url + 'archivos/ObtenerXmlFactura', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        // xml: xml,
        // uuid: "eb200eb5-a215-4d89-8553-1c07de8a60bb", 
        uuid,
      })
    }).then(res => res.json())
      .then(data => {
        // this.setState({ cargando: false })
        // return
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.GuardarDocumentos(data.data, uuid, notas)
          // this.setState({ mensaje: "Descarga completa", colorNotificacion: "danger", Activo: 1, cargando: false });
          // this.showNotification();
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }
  async ImprimirPDF(xml, uuid, Notas) {
    this.setState({ cargando: true })
    fetch(Globales.Url + 'Facturacion/Facturas/ImprimirFactura', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        xml: xml,
        NotasRecibo: Notas,
        // NotasRecibo:"Notas",
      })
    }).then(res => res.json())
      .then(data => {
        // this.setState({ cargando: false })
        // return
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          // this.GuardarDocumentos(data.data, uuid)
          this.descargarPDF('data:application/pdf;base64,' + data.data, uuid);
          this.setState({ cargando: false });
          // this.setState({ mensaje: "Se cancelo la factura exitosamente", colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          // this.showNotification();
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }
  CancelarFacturas() {
    this.setState({ cargando: true })
    fetch(Globales.Url + 'Facturacion/Facturas/CancelacionFacturas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        _id: this.state._id,
        uuid: this.state.uuid
      })
    }).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          let mensaje = data.data == 0 ? "Se cancelo la factura exitosamente" : "Se elimino la factura exitosamente"
          this.setState({ mensaje: mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          this.showNotification();
          this.ListaGeneralFactura()
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }
  //Función para abrir el modal de buscar alumnos
  openModalInfoVenta = (Venta) => {
    this.setState({ VentaSeleccionada: Venta, showInfoVenta: true, Titulo: "Información general", op: 1 });
  }

  openModalFiscal = () => {
    this.setState({ showFiscal: true, Titulo: "Asignar Folio Fiscal", op1: 0 });
  }

  openModalAsignarFactura = () => {
    if (this.state.ArrayIdVentas.length > 0 || this.state.ArrayIdVentasVirtual.length > 0) {
      this.setState({ showAsignarFactura: true, Titulo: "Asignar Folio Factura", op: 1 });
    }
    else {
      this.setState({ mensaje: "Seleccione al menos un recibo", colorNotificacion: "danger" })
      this.showNotification();
    }
    //this.setState({ showAsignarFactura: true, Titulo: "Asignar Folio Factura", op: 1 });
  }

  openModalPregunta = (venta) => {
    this.setState({ show: true, ventaAux: venta });
  }

  //Función que recupera valores para el modal
  eventoModalInfoVenta = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showInfoVenta: false, op: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }

  eventoModalFiscal = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showFiscal: false, op1: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }

  eventoModalcobranza = (ListaAlumnos, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, op: 0 })
    /* if (ListaAlumnos !== 'esc') {
      this.setState({ ListaAlumnos: ListaAlumnos, colorNotificacion: colorNotificacion })
      this.showNotification(); } */
  }

  eventoNvoRecibo = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showNvoRecibo: false, op1: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }

  eventoAsignarFactura = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showAsignarFactura: false, op: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }


  openPregunta = (id, uuid, Estado) => {
    let titulo = ""
    let mensaje = ""
    if (Estado.toUpperCase() == "ACTIVA") {
      titulo = "Cancelar Factura"
      mensaje = "¿Está seguro de cancelar esta factura?"
    } else {
      titulo = "Eliminar Factura"
      mensaje = "¿Está seguro de eliminar esta factura?"
    }
    // return
    this.setState({
      _id: id,
      uuid: uuid,
      TituloPregunta: titulo,
      Pregunta: mensaje,
      show: true
    })
  }
  eventoModalPregunta = (valor) => {
    this.setState({ show: false })
    if (valor) {
      this.CancelarFacturas();
    }
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    if (name == "opciones") {
      this.setState({
        [name]: value,
        opcionBuscar: value,
        FolioFactura: "",
        Folios: [],
        IDEmpresa: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
        AlumnoSeleciondado: [],
        FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        FechaFin: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        NombreAlumno: "",
        NombreEmpresa: "",
        CheckAll: false,
        SumaTotalFacturados: {}
      });
    }
    else {
      this.setState({ [name]: value });
    }
  }

  ValidarModulo(modulo) {
    let valido = false;

    Permisos.map(permiso => {
      if (parseInt(permiso.NumeroModulo) == modulo) {
        valido = true
      }
    })

    return valido
  }
  eventoFacturacion = (exito) => {
    this.setState({ showFacturacion: false })
    if (exito) {
      this.ListaGeneralFactura()
      this.setState({
        uuid: exito.UUID,
        RFC: exito.RFC,
        Notas: exito.Notas,
        ShowEnviarCorreos: true,
        mensaje: "Se realizo la factura exitsamente",
        colorNotificacion: "success",
      })
      this.showNotification();
    }
  }
  eventoEnvioCorreos = (exito) => {
    this.setState({ ShowEnviarCorreos: false })
    if (exito) {
      this.setState({ mensaje: "Se envio la factura correctamente", colorNotificacion: "success" })
      this.showNotification();
    }
  }

  ExportarExcel() {
    this.setState({
      VerResumenListado: false,
      cargando: true
    })

    var Datos = []
    var Titulos = [
      "Fecha",
      "Folio",
      "Receptor",
      "Total",
      "Estado",
    ]
    
    this.state.Folios.map(Venta => {
      Datos.push([
        {
          value: moment(Venta.Fecha).format('DD-MM-YYYY'),
          style: { font: { sz: "12", } },
        },
        {
          value: Venta.Folio,
          style: { font: { sz: "12" } },
        },
        {
          value: Venta.Receptor.RazonSocial,
          style: { font: { sz: "12" } },
        },
        {
          value: Venta.Total.toString(),
          style: { font: { sz: "12" } },
        },
        {
          value: Venta.EstadoTimbrado,
          style: { font: { sz: "12", } },
        },
      ])
    })

    this.setState({
      cargando: false,
      TiulosReporte: Titulos,
      DatosReporte: Datos,
      VerResumenListado: true,
    })
  }

  descargarPDF(archivoBase64, uuid) {
    var a = JSON.parse(localStorage.getItem("factid"));
    let nombre = uuid;
    const linkSource = `${archivoBase64}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  descargarXML(xxmmll, uuid) {
    var a = JSON.parse(localStorage.getItem("factid"));
    let nombre = uuid;
    const linkSource = `${xxmmll}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.xml`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  async GuardarDocumentos(xmm, uuid, notas) {
    if (xmm) {
      ///////////////Descargo xml/////////////////////////
      this.descargarXML('data:application/xml;base64,' + xmm, uuid);
      /////////////descargo pdf//////

      this.ImprimirPDF(xmm, uuid, notas)
    }
  }

  render() {
    const { classes } = this.props;

    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }
    if (this.state.ShowEnviarCorreos == true) {
      agregar = (
        <ModalEnviarCorreoFacturas
          uuid={this.state.uuid}
          RFC={this.state.RFC}
          Notas={this.state.Notas}
          show={this.state.ShowEnviarCorreos}
          eventClick={this.eventoEnvioCorreos}
        />
      );
    }

    // console.log("Folios: ",this.state.Folios)
    // console.log("Alumno: ", this.state.AlumnoSeleciondado)

    let pantalla
    if (this.state.showFacturacion) {
      pantalla = <Facturacion
        op={this.state.op}
        eventClick={this.eventoFacturacion}
      />
    } else {
      pantalla = (
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <CardHeader color="info" text>
                  <h4 className={classes.cardTitle}>Facturación</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    {/* <GridItem xs={12} sm={12} md={12}>
                      <h6>Criterio de búsqueda para la(s) fatura(s)</h6>
                    </GridItem>
                    <GridItem style={{ marginTop: "16px" }} id="Empresa de búsqueda" xs={12} sm={12} md={5}>
                      <FormControl fullWidth variant="outlined" fullWidth size="small">
                        <InputLabel id="SelectEmpresa">Empresa</InputLabel>
                        <Select
                          labelId="SelectEmpresa"
                          label="Empresa"
                          value={this.state.IDEmpresa}
                          onChange={this.handleChange.bind(this)}
                          name="IDEmpresa"
                        >
                          <MenuItem value={this.state.IDEmpresa} disabled={true}>Todas</MenuItem>
                          {this.state.ListaEmpresas.map(Empresa =>
                            <MenuItem value={Empresa._id}>{(Empresa.RazonSocial).toUpperCase()}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </GridItem> */}
                  </GridContainer>
                  <GridContainer>
                    {/*Opcion Folio factura*/}
                    <GridItem style={{ marginTop: "16px" }} id="Parametros de búsqueda" xs={12} sm={12} md={3}>
                      <TextField
                        id="FolioFactura"
                        // style={{ width: "15em" }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Folio"
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FolioFactura,
                          name: "FolioFactura",
                          id: "FolioFactura",
                        }}
                      />
                    </GridItem>
                    {/* Opcion seleccionar alumno */}
                    <GridItem id="Parametros de búsqueda" xs={12} sm={12} md={3}>
                      <SeleccionarAlumno
                        fullWidth={true}
                        inactivo={false}
                        eventClick={this.handleCallback}
                      />
                    </GridItem>
                    {/*Opcion Fechas*/}
                    <GridItem style={{ marginTop: "16px" }} id="Parametros de búsqueda" xs={12} sm={12} md={3}>
                      <TextField
                        id="FechaInicio"
                        // style={{ width: "12.5em", marginTop: "8px" }}
                        fullWidth
                        variant="outlined"
                        type="date"
                        format="DD-MM-YYYY"
                        size="small"
                        label="Fecha Inicial"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaInicio,
                          name: "FechaInicio",
                          id: "FechaInicio",
                          error: this.state.errorFechainicio,
                          success: !this.state.errorFechainicio
                        }}
                      />
                    </GridItem>
                    <GridItem style={{ marginTop: "16px" }} id="Parametros de búsqueda" xs={12} sm={12} md={3}>
                      <TextField
                        id="FechaFin"
                        // style={{ width: "12.5em", marginTop: "8px" }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="date"
                        format="DD-MM-YYYY"
                        label="Fecha Final"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaFin,
                          name: "FechaFin",
                          id: "FechaFin",
                          error: this.state.errorFechaFin,
                          success: !this.state.errorFechaFin
                        }}
                      />
                    </GridItem>
                    <GridItem style={{ marginTop: "16px" }} id="Parametros de búsqueda" xs={12} sm={12} md={12}>
                      <Button size="sm" className="pull-right ml-3" type="submit" color="info" round onClick={() => this.setState({ showFacturacion: true, op: 0 })}><Save />Nueva factura</Button>
                      <Button size="sm" disabled={this.state.botonDesabilitado} className="pull-right" type="submit" color="info" round onClick={() => this.buscar()}><Search />Buscar</Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={18}>
              <Card>
                <CardHeader color="info" text>
                  <h4 className={classes.cardTitle}>Lista de facturas</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="table-responsive-xl table-wrapper-scroll-y">
                        <table className="table table-sm table-hover">
                          <thead>
                            <th className="center">Fecha</th>
                            <th className="center">Folio</th>
                            <th className="center" style={{ width: "50%" }}>Receptor</th>
                            <th className="center">Total</th>
                            <th className="center">Estado</th>
                            <th className="center"></th>
                            {/* <th><Checkbox onChange={this.handleCheckAll.bind(this)} name="CheckAll" checked={this.state.CheckAll} /></th> */}
                            <th></th>
                          </thead>
                          <tbody>
                            {
                              this.state.Folios.length == 0 ?
                                <tr> <td colSpan={9} style={{ textAlign: "center" }}>NO HAY FACTURARAS PARA MOSTRAR</td> </tr>
                                :
                                this.state.Folios.map((Venta, index) => {
                                  return (
                                    <tr key={index} style={{ cursor: "pointer" }} hover>
                                      <td style={{ verticalAlign: "middle" }} >{moment(Venta.Fecha).format('DD-MM-YYYY')}</td>
                                      <td style={{ verticalAlign: "middle" }} >{Venta.Folio}</td>
                                      <td style={{ verticalAlign: "middle" }} >{Venta.Receptor.RazonSocial}</td>
                                      <td style={{ verticalAlign: "middle" }} >{Venta.Total}</td>
                                      <td style={{ verticalAlign: "middle" }} >{Venta.EstadoTimbrado}</td>
                                      <td style={{ verticalAlign: "middle" }} >
                                        <Button size="sm" type="submit" color="danger" className="pull-right" round justIcon onClick={() => this.openPregunta(Venta._id, Venta.UUID, Venta.EstadoTimbrado,)}><Close /></Button>
                                        <Button size="sm" type="submit" color="info" className="pull-right" round justIcon onClick={() => {
                                          this.ObtenerXml(Venta.UUID, Venta.Observaciones,)
                                          // this.setState({ Notas: Venta.Observaciones, })
                                        }}><Print /></Button>
                                        <Button size="sm" type="submit" color="info" className="pull-right" round justIcon onClick={() => this.setState({ uuid: Venta.UUID, Notas: Venta.Observaciones, RFC: Venta.Receptor.RFC, ShowEnviarCorreos: true })}><Email /></Button>
                                      </td>
                                    </tr>)
                                })
                            }
                          </tbody>
                        </table>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{ margin: "1em", marginTop: "0px" }}>
                      {this.state.Folios.length == 0 ? null :
                        this.state.VerResumenListado == true ?
                          <ExcelReportesListados
                            NombreArchivo={"Reporte de facturacion"}
                            TiulosReporte={this.state.TiulosReporte}
                            DatosReporte={this.state.DatosReporte}
                          />
                          : null
                      }
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>

            </GridItem>
          </GridContainer>
          <div id="demo">

          </div>
          <div id="">
            <ModalPregunta
              Titulo={this.state.TituloPregunta}
              Cuerpo={this.state.Pregunta}
              show={this.state.show}
              eventClick={this.eventoModalPregunta}
            />
            {agregar}
          </div>
          <div id="">
            <Snackbar
              place="tr"
              color={this.state.colorNotificacion}
              message={this.state.mensaje}
              open={this.state.br}
              closeNotification={() => this.setState({ br: false })}
              close
            />
          </div>
        </div>
      )
    }

    return pantalla
  }
}

export default withStyles(headerLinksStyle)(FacturacionListado);