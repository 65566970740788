import React, { useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

// material-ui icons
import Globales from "utils/Globales";
import Dropdown from 'react-dropdown';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";


class SeleccionarAlumno extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DatosSeleccionados: {},
            ListaNombres: [],
            errorEmpresa: null,
            Nombre: "",
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
    }

    componentDidMount() {

    }

    //Función para obtener las empresas

    async ListaFiltradoDatosFiscales(texto) {
        // this.setState({cargando: true});
        await fetch(Globales.Url + 'Alumnos/Alumnos/ListaFiltradoDatosFiscales', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ nombre: texto })
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ cargando: false });
                    //this.showNotification();
                } else { //Filtrado exitoso
                    if (data.ListaNombres.length > 1) {
                        this.setState({ ListaNombres: data.ListaNombres });
                    }
                    else {
                        let aux = [];
                        aux.push(data.ListaNombres);
                        this.setState({ ListaNombres: aux });
                    }
                }
            })
            .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }

    handleChangeAutocomplete(e, newValue) {
        // console.log("Autocomplete seleccionado", typeof newValue != 'string')
        if (typeof newValue != 'string') {
            this.setState({ DatosSeleccionados: newValue })
            this.props.eventClick(newValue)
        }else{
            this.props.eventClick(null)
        }
    }

    InputChange(e, newInputValue) {
        //const { name, value2 } = e.target;
        if (newInputValue.length > 0) {
            this.ListaFiltradoDatosFiscales(newInputValue);
        }
        this.setState({ Nombre: newInputValue });
    }

    handleMouseDownToken(event) {
        event.preventDefault();
    }

    handleClickEliminarTexto() {
        this.setState({ NombreAlumno: "", DatosSeleccionados: {} });
        this.props.eventClick([])
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Autocomplete
                    freeSolo
                    id="NombreAlumno"
                    noOptionsText={'No hay opciones'}
                    style={{ maxWidth: this.props.fullWidth ? "100%" : "18.2vw" }}
                    disableClearable
                    value={this.state.DatosSeleccionados}
                    inputValue={this.state.Nombre}
                    onChange={this.handleChangeAutocomplete.bind(this)}
                    onInputChange={this.InputChange.bind(this)}
                    options={this.state.ListaNombres}
                    getOptionLabel={(option) => option.RazonSocial}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Nombre o razón social"
                            margin="normal"
                            size="small"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => this.handleClickEliminarTexto()}
                                            onMouseDown={this.handleMouseDownToken}
                                            edge="end"
                                        >
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                type: 'search',
                                name: "NombreAlumno",
                                id: "NombreAlumno",
                                //error: this.state.errorNombre,
                                //success: !this.state.errorNombre
                            }}
                        />
                    )}
                />
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(SeleccionarAlumno);