// core components/views for Admin layout

import Presupuestos from "views/Modulos/Presupuestos/PresupuestosPrincipal.jsx";
import Reporte from "views/Modulos/Presupuestos/Reporte/Reporte.jsx";
// @material-ui/icons
import { Streetview, Grain, ScatterPlot, Timeline, LinearScale, BarChart, Whatshot, MergeType, Home, Menu,WalletMembership,Contacts } from "@material-ui/icons";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [
  {
    path: "/presupuestos",
    name: "Presupuestos",
    icon: BarChart,
    component: Presupuestos,
    layout: "/presupuestos",
    idModulo:"5d08215a198ea43abc040db2"
  }
  
]

var dashboardRoutesAccess = [];
// for(var keyRoutes  = 0; keyRoutes < dashboardRoutes.length; keyRoutes++){
//   if(cookies.get('TipoUsuario') != undefined){
//     if(cookies.get('TipoUsuario').idModulos.includes(dashboardRoutes[keyRoutes].idModulo) == true){
      dashboardRoutesAccess.push(dashboardRoutes[0]);
//     }
//   }
// }

export default dashboardRoutesAccess;