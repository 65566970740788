import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import ModalPregunta from "components/Generales/ModalPregunta";

const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

// const ClassGrupo = {
//   ActaCerradaMaestro: false,
//   Activo: true,
//   CapacidadAula: 1,
//   Clase: "",
//   Clave: "",
//   Concluido: false,
//   FechaIncioCurso: new Date(),
//   Horario:{
//     DiaClases: 'Lunes_a_Jueves',
//     Formatofin: 'am',
//     Formatoinicio: 'am',
//     HoraFin: '0',
//     HoraInicio: '0'
//   },
//   HorarioGrupo: [],
//   IdLibro: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   ListaNiveles: { ListaNiveles: [] },
//   IdGrupoAnterior: { _increment: '0', _machine: '0', _pid: '0', _timestamp: '0' },
//   IdGrupoSiguiente: { _increment: '0', _machine: '0', _pid: '0', _timestamp: '0' },
//   IdUnidadActual: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   Modalidad:"",
//   NivelesAnteriores: { ObjectId: [] },
//   Nombre: "",
//   _id: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   _idAula: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   _idIdioma: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   _idMaestro: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   _idNivel: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
// }

class ModalSubirNivelGrupo extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal: false,
      _id: '',
      GrupoSeleccionado: {},
      ListaNiveles: [],
      NivelAnterior: {},
      idNivelAnterior: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
      NivelSiguiente: {},
      NivelesAnteriores: [],
      LibrosGrupo: null,
      disabled: false,
      colorNotificacion: "warning",
      mensaje: "¿Está seguro que desea cambiar el nivel del grupo?",
      br: false,
      DesactivarBoton: false,
      NombreGuardado: '',
      SubcategoriaHabilitada: false,
      aviso: "",
      CuerpoCargando: "Cargando",
      show: false

    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    //console.log("GrupoSeleccionado", this.props.GrupoSeleccionado)
    let aux = []
    if (this.props.GrupoSeleccionado.NivelesAnteriores !== null && this.props.GrupoSeleccionado.NivelesAnteriores !== undefined) {
      if (this.props.GrupoSeleccionado.NivelesAnteriores.ObjectId.length > 1) {
        aux = this.props.GrupoSeleccionado.NivelesAnteriores.ObjectId;
      }
      else {
        aux.push(this.props.GrupoSeleccionado.NivelesAnteriores.ObjectId)
      }
    }

    let grupo = {
      ActaCerradaMaestro: this.props.GrupoSeleccionado.ActaCerradaMaestro,
      Activo: true,
      CapacidadAula: this.props.GrupoSeleccionado.CapacidadAula,
      Clase: this.props.GrupoSeleccionado.Clase,
      Clave: this.props.GrupoSeleccionado.Clave,
      Concluido: this.props.GrupoSeleccionado.Concluido,
      FechaIncioCurso: this.props.GrupoSeleccionado.FechaIncioCurso,
      Horario: this.props.GrupoSeleccionado.Horario,
      HorarioGrupo: this.props.GrupoSeleccionado.HorarioGrupo,
      IdGrupoAnterior: this.props.GrupoSeleccionado.IdGrupoAnterior,
      IdGrupoSiguiente: this.props.GrupoSeleccionado.IdGrupoSiguiente,
      IdLibro: this.props.GrupoSeleccionado.IdLibro,
      IdUnidadActual: this.props.GrupoSeleccionado.IdUnidadActual,
      // InfoNivelesAnteriores: null,
      ListaNiveles: this.props.GrupoSeleccionado.ListaNiveles == undefined ? null : this.props.GrupoSeleccionado.ListaNiveles,
      Modalidad:this.props.GrupoSeleccionado.Modalidad,
      NivelesAnteriores: { ObjectId: [] },
      Nombre: this.props.GrupoSeleccionado.Nombre,
      TotalHorasSemana: this.props.GrupoSeleccionado.TotalHorasSemana,
      _id: this.props.GrupoSeleccionado._id,
      _idAula: this.props.GrupoSeleccionado._idAula,
      _idIdioma: this.props.GrupoSeleccionado._idIdioma,
      _idMaestro: this.props.GrupoSeleccionado._idMaestro,
      _idNivel: this.props.GrupoSeleccionado._idNivel,
    }

    // console.log(this.props.GrupoSeleccionado.ListaNiveles);

    this.setState({ GrupoSeleccionado: grupo, NivelesAnteriores: aux, idNivelAnterior: this.props.GrupoSeleccionado._idNivel });
    this.ListaNivelesIdioma(this.props.GrupoSeleccionado);
    this.ObtenerInformacionGrupo(this.props.GrupoSeleccionado._id)
  }

  async ObtenerInformacionGrupo(idGrupo) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Calificaciones/Calificaciones/ObtenerInformacionGrupo', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idGrupo: idGrupo})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log("ObtenerInformacionGrupo: ", data.InfoGrupo);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              this.setState({LibrosGrupo: data.InfoGrupo.Libros})
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  async ListaNivelesIdioma(grupo) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Catalogos/Niveles/ListaGeneralNivelesClase', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: grupo._idIdioma, Clase: null, })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaNiveles: [], cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          if (data.ListaNiveles.length > 1) {
            let nivelAnterior = data.ListaNiveles.find(e => JSON.stringify(e._id) == JSON.stringify(grupo._idNivel))
            let nivelSiguiente = data.ListaNiveles.find(e => parseInt(e.Orden) == parseInt(nivelAnterior.Orden) + 1)
            //console.log("nivelAnterior", nivelAnterior)
            //console.log("nivelSiguiente", nivelSiguiente)
            if (nivelSiguiente !== undefined) {
              this.setState({ NivelAnterior: nivelAnterior, NivelSiguiente: nivelSiguiente, cargando: false });
            }
            else {
              this.setState({ mensaje: "No se puede subir de nivel a este grupo. No existe un nivel sucesor al nivel actual o este nivel no está marcado como último nivel.", colorNotificacion: "danger", disabled: true, cargando: false });
            }
          }
          else {
            this.setState({ mensaje: "No se puede subir de nivel a este grupo. No existe un nivel sucesor al nivel actual o este nivel no está marcado como último nivel.", colorNotificacion: "danger", disabled: true, cargando: false });
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  CambiarNivel() {
    let grupo = this.state.GrupoSeleccionado;

    //console.log([...this.state.NivelesAnteriores, this.state.NivelAnterior._id])

    let aux = idVacio;
    // if (this.state.NivelSiguiente.Libros !== null && this.state.NivelSiguiente.Libros !== undefined) {
    //   if (this.state.NivelSiguiente.Libros.Libro.length > 1) {
    //     aux = this.state.NivelSiguiente.Libros.Libro[0]._id
    //   }
    //   else {
    //     aux = this.state.NivelSiguiente.Libros.Libro._id
    //   }
    // }
    // else if (this.state.NivelAnterior.Libros !== null && this.state.NivelAnterior.Libros !== undefined) {
    //   if (this.state.NivelAnterior.Libros.Libro.length > 1) {
    //     aux = this.state.NivelAnterior.Libros.Libro[0]._id
    //   }
    //   else {
    //     aux = this.state.NivelAnterior.Libros.Libro._id
    //   }
    // }

    this.state.NivelesAnteriores.push(this.state.NivelAnterior._id)

    grupo._idNivel = this.state.NivelSiguiente._id
    grupo.IdLibro = aux;
    grupo.Nombre = "";
    
    grupo.FechaIncioCurso = new Date()
    // console.log(this.state.LibrosGrupo)
    var nivelAnterior ={
      FechaInicio:this.props.GrupoSeleccionado.FechaIncioCurso,
      FechaTermino:new Date(),
      HorarioGrupo:this.props.GrupoSeleccionado.HorarioGrupo,
      Libros: this.state.LibrosGrupo,
      TotalAlumnos: 0,
      _id:idVacio,
      idAula: grupo._idAula,
      idGrupo: grupo._id,
      idMaestro: grupo._idMaestro,
      idNivel: this.state.NivelAnterior._id,
    }
    if (grupo.ListaNiveles == null) {
      grupo.ListaNiveles = {
        NivelGrupo: [nivelAnterior]
      }
    }else if(grupo.ListaNiveles.NivelGrupo.length > 1) {
      grupo.ListaNiveles.NivelGrupo.push(nivelAnterior)
    }else{
      grupo.ListaNiveles = {
        NivelGrupo: [grupo.ListaNiveles.NivelGrupo, nivelAnterior]
      }
    }
    // console.log(grupo.ListaNiveles)

    grupo.NivelesAnteriores = { ObjectId: this.state.NivelesAnteriores }

    grupo.IdUnidadActual = idVacio;

    // console.log("Grupo nuevo", grupo)
    this.RegistrarGrupo(grupo)
  }

  async RegistrarGrupo(grupo) {
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Grupos/Grupos/SubirNivel', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ grupo: grupo, maestroAnterior: JSON.stringify(grupo._idMaestro) !== JSON.stringify(idVacio) ? grupo._idMaestro : idVacio })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaLibros: [], cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.ActualizarUnidadesGrupo(grupo)
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  async ActualizarUnidadesGrupo(grupo) {
    this.setState({cargando: true})
    //console.log(grupo)
    await fetch(Globales.Url + 'Grupos/Grupos/ActualizarUnidadesGrupo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idGrupo: grupo._id, idNivel: grupo._idNivel})
    }
    ).then(res => res.json())
      .then(data => {
      //console.log(data)
        if (data.error) { //Error en la respuesta
          this.setState({ cargando: false, ColorNotificacion: 'danger', mensaje: data.mensaje, });
          this.showNotification();
        } else { //Filtrado exitoso
          this.setState({ cargando: false})
          this.RegresarMensaje("Se cambio el nivel del grupo exitosamente")
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    //this.setState({ op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;
    const { ListaNiveles, ListaLibros, GrupoSeleccionado } = this.state;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    //console.log("GrupoSeleccionado", GrupoSeleccionado)
    //console.log("Niveles", ListaNiveles)
    //console.log("Libros", ListaLibros)
    return (
      <div>
        <Modal
          isOpen={this.props.showSubirNivelGrupo}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color={this.state.colorNotificacion}>{this.props.Titulo}</CardHeader>
            <CardBody>
              {this.state.mensaje}
              <GridItem xs={12} sm={12} md={12}>
                {this.state.disabled !== true ? <Button color="success" tabIndex="1" size='sm' className='pull-right mr-2 mt-3' round onClick={() => this.CambiarNivel()}><Save />&nbsp;Guardar</Button> : ""}
                <Button color="danger" tabIndex="1" size='sm' className='pull-right mr-2 mt-3' round onClick={() => this.props.eventClick("esc")}><Clear />&nbsp;Cancelar</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalSubirNivelGrupo);