import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CardBody from "components/Card/CardBody.jsx";
import ModalMunicipios from "./ModalMunicipios.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Search, Close } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

class CatMunicipio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      _idPais: "",
      _idEstado: "",
      FiltrarNombres: "",
      ListaEstados: [],
      ListaPaises: [],
      ListaMunicipios: [],
      ListaMunicipiosTemp: [],
      MunicipioSeleccionado: {},
      Disabled: false,
      mensaje: "",
      NombreMunicipio: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      Total: 0,
      TotalSeleccionado: 0,
      Titulo: "Registrar un nuevo municipio",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    this.ListaGeneralPaises();
  }

  async ListaGeneralPaises() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Paises/ListaGeneralPaises", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          if (data.ListadePaises.Pais.length === 0) {
            this.setState({
              colorNotificacion: "danger",
              mensaje: "No se encontraron coincidencias",
            });
            this.showNotification();
          } else {
            if(data.ListadePaises.Pais.length > 1){
              this.setState({
                ListaPaises: data.ListadePaises.Pais,
                cargando: false,
                entroEliminar: false,
                FiltrarNombres: "",
              });
            }
            else{
              let aux = [];
              aux.push(data.ListadePaises.Pais)
              this.setState({
                ListaPaises: aux,
                cargando: false,
                entroEliminar: false,
                FiltrarNombres: "",
              });
            }
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ListaGeneralEstados(Pais) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Estados/ListaGeneralEstados", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        _idPais: Pais,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          const TipoData = Array.isArray(data.ListadeEstados.Estado);
          const EstadosData = TipoData
            ? data.ListadeEstados.Estado
            : [{ ...data.ListadeEstados.Estado }];
          if (EstadosData.length > 0) {
            this.setState({
              ListaEstados: EstadosData,
              ListaEstadosTemp: EstadosData,
              Activo: EstadosData.length,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          } else {
            this.setState({
              ListaEstados: [],
              ListaEstadosTemp: [],
              Activo: 0,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
              colorNotificacion: "danger",
              mensaje: "Aun no cuenta con estados registrados",
            });
            this.showNotification();
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ListaGeneralMunicipios(Estado) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Municipios/ListaGeneralMunicipios", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        _idEstado: Estado,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
            _idEstado: "",
          });
          this.showNotification();
        } else {
          const TipoData = Array.isArray(data.ListadeMunicipios.Municipio);
          const MunicipioData = TipoData
            ? data.ListadeMunicipios.Municipio
            : [{ ...data.ListadeMunicipios.Municipio }];
          if (MunicipioData.length > 0) {
            this.setState({
              ListaMunicipios: MunicipioData,
              ListaMunicipiosTemp: MunicipioData,
              Activo: MunicipioData.length,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          } else {
            this.setState({
              ListaMunicipios: [],
              ListaMunicipiosTemp: [],
              Activo: 0,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
              colorNotificacion: "danger",
              mensaje: "Aun no cuenta con municipios asignados",
            });
            this.showNotification();
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "NombrePais") {
      this.setState({
        _idPais: value,
        _id: "",
        _idEstado: "",
        FiltrarNombres: "",
        MunicipioSeleccionado: "",
        ListaEstados: [],
        ListaMunicipios: [],
        ListaMunicipiosTemp: [],
      });
      if (value) {
        this.ListaGeneralEstados(value);
      }
    }
    if (name === "NombreEstado") {
      this.setState({
        _id: "",
        _idEstado: value,
        FiltrarNombres: "",
        MunicipioSeleccionado: "",
        ListaMunicipios: [],
        ListaMunicipiosTemp: [],
      });
      if (value) {
        this.ListaGeneralMunicipios(value);
      }
    }
    if (name === "FiltrarNombres") {
      const Dataorg = this.state.ListaMunicipiosTemp;
      if (value) {
        const NuevaData = Dataorg.filter((Municipio) =>
          String(Municipio.NombreMunicipio)
            .toUpperCase()
            .includes(String(value).toUpperCase())
        );

        if(NuevaData.length === 0){
          this.setState({
            FiltrarNombres: value,
            ListaMunicipios: NuevaData,
            colorNotificacion: "danger",
            mensaje: "No se encontraron coincidencias",
          });
          this.showNotification();
        }
        else{
          this.setState({
            FiltrarNombres: value,
            ListaMunicipios: NuevaData,
          });
        }
      } else {
        this.setState({
          FiltrarNombres: "",
          ListaMunicipios: [...Dataorg],
        });
      }
    }
  };

  eventoModalEliminar(e) {
    this.setState({ show: true, MunicipioSeleccionado: e });
    return;
  }

  eventoModalPregunta = (valor) => {
    if (valor) {
      this.eliminarMunicipio(this.state.MunicipioSeleccionado);
    } else {
      this.setState({ show: false });
    }
  };

  eliminarMunicipio(id) {
    fetch(Globales.Url + "Catalogos/Municipios/EliminarInformacionMunicipio", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ _id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          this.setState({ colorNotificacion: "danger" });
        } else if (data.error) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger" });
        } else {
          //Obtuvo los registros correctamente
          this.setState({ colorNotificacion: "success" });
        }
        this.setState({
          mensaje: data.mensaje
            ? data.mensaje
            : "El municipio no puede ser eliminado.",
          show: false,
          entroEliminar: true,
        });
        this.showNotification();
        this.ListaGeneralMunicipios(this.state._idEstado);
      });
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  eventoModalMunicipio = (mensaje, Color) => {
    this.setState({showAgregar: false})
    //Cambiar valores por default
    if(mensaje !== "esc"){
      this.setState({
        Titulo: "Nuevo municipio",
        mensaje: mensaje,
        NombreEstado: "",
        colorNotificacion: Color,
        op: 0,
        entroEliminar: true,
      });
      this.ListaGeneralMunicipios(this.state._idEstado);
      this.showNotification();
    }
  };

  openModalNuevoEstado = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Registrar nuevo municipio",
      NombreEstado: "",
      op: 0,
    });
  };

  openModalMunicipio = (e) => {
    //Cambiar valores por default
    this.setState({
      NombreMunicipio: e.NombreMunicipio,
      _id: e._id,
      Titulo: "Editar municipio",
      op: 1,
      showAgregar: true,
    });
  };

  render() {
    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }

    if (this.state.showAgregar == true) {
      agregar = (
        <ModalMunicipios
          xs={18}
          Titulo={this.state.Titulo}
          NombreMunicipio={this.state.NombreMunicipio}
          Municipios={this.state.ListaEstadosTemp}
          _id={this.state._id}
          _idEstado={this.state._idEstado}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalMunicipio}
          op={this.state.op}
        />
      );
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text={true}>
                <h4 className={classes.cardTitle}>Filtros</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <FormControl
                      // fullWidth
                      className={classes.formControl}
                      size="small"
                      // style={{ marginBottom: "27px" }}
                      variant="outlined"
                    >
                      <InputLabel>País</InputLabel>
                      <Select
                        value={this.state._idPais}
                        onChange={this.handleChange}
                        name="NombrePais"
                        size="small"
                        label="País"
                        style={{ marginBottom: "10px", width: "18.2vw" }}
                        id="_id"
                        defaultValue={""}
                      >
                        <MenuItem disabled>Seleccione un país</MenuItem>
                        {this.state.ListaPaises.map((Pais, Index) => (
                          <MenuItem
                            key={Index + Pais.NombrePais}
                            value={Pais._id}
                          >
                            {Pais.NombrePais}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl
                      // fullWidth
                      className={classes.formControl}
                      size="small"
                      // style={{ marginBottom: "27px" }}
                      variant="outlined"
                    >
                      <InputLabel>Estado</InputLabel>
                      <Select
                        value={this.state._idEstado}
                        onChange={this.handleChange}
                        size="small"
                        label="Estado"
                        name="NombreEstado"
                        style={{ marginBottom: "10px", width: "18.2vw" }}
                        id="_id"
                        defaultValue={""}
                      >
                        <MenuItem disabled>Seleccione un estado</MenuItem>
                        {this.state.ListaEstados.map((Estado, Index) => (
                          <MenuItem
                            key={Index + Estado.NombreEstado}
                            value={Estado._id}
                          >
                            {Estado.NombreEstado}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      id="FiltrarNombres"
                      style={{ width: "18.2vw" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Nombre del municipio"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        onChange: this.handleChange.bind(this),
                        value: this.state.FiltrarNombres,
                        name: "FiltrarNombres",
                        id: "FiltrarNombres",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button
                      size="sm"
                      type="submit"
                      color="info"
                      round
                      className="pull-right"
                      disabled={!this.state._idEstado}
                      onClick={() => this.openModalNuevoEstado()}
                    >
                      Nuevo
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de municipios</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4
                      className="pull-right"
                      style={{ marginRight: "15px", marginTop: 0 }}
                    >
                      {this.state.ListaMunicipios.length} Municipio(s)
                    </h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="center">Nombre del municipio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ListaMunicipios.map((Municipio, index) => {
                        return (
                          <tr key={Municipio._id + "-" + index}>
                            <td onClick={() =>this.openModalMunicipio({ NombreMunicipio: Municipio.NombreMunicipio, _id: Municipio._id,})} style={{verticalAlign: "middle", cursor: "default"}}>{Municipio.NombreMunicipio}</td>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>
                            <Button
                                color="danger"
                                round
                                className="pull-right"
                                justIcon
                                size="sm"
                                onClick={() =>
                                  this.eventoModalEliminar(Municipio._id)
                                }
                              >
                                <Close />
                              </Button>
                              <Button
                                color="info"
                                round
                                className="pull-right mr-2"
                                justIcon
                                size="sm"
                                onClick={() =>
                                  this.openModalMunicipio({
                                    NombreMunicipio: Municipio.NombreMunicipio,
                                    _id: Municipio._id,
                                  })
                                }
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar municipio"}
            Cuerpo={"¿Está seguro de eliminar el municipio?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatMunicipio);
