import React, { Component } from "react";
import ReactDOM from "react-dom";
import App from './App';

import "assets/css/material-dashboard-react.css?v=1.6.0";
import "components/Generales/Styles/StyleBasics.css";

// console.log(window.document.documentURI);

ReactDOM.render(<App/>, document.getElementById("root"));
