import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, CameraAlt, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
// import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import CSVReader from 'react-csv-reader'
import ExtencionImportarGastos from "./ExtencionImportarGastos";
import FormHelperText from '@material-ui/core/FormHelperText';
import ModalPregunta from "../../../components/Generales/ModalPregunta";

class ModalImportarGastos extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      ListaGastosImp: [],
      IDEmpresa: '',
      ListaEmpresas: [],
      TipoGastoSeleccionado: '',
      ListaTipoGasto: [],
      SubtipoGastoSeleccionado: '',
      ListaTipoSubGasto: [],
      ProveedorSeleccionado: '',
      ListaProveedor: [],
      errorEmpresa: false,
      opcionTipoPago: "Efectivo",
      opcionTipoRecibo: "Factura",
      Subtotal: 0,
      Total: 0,
      TotalBanco: 0,
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: "",
      cargando: false,
      CuerpoCargando: "Cargando"
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    this.obtenerEmpresasActivas(0);
    this.TipoGastoListado(0);
    this.ListaGeneralProveedores(0);
  }

  async obtenerEmpresasActivas(idEmpresa) {
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Facturacion/Facturacion/ListadoEmpresas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, cargando:false });
          this.showNotification();
        } else { //Filtrado exitoso
          if(idEmpresa !== 0){
            if (data.ListaEmpresas.length > 1) {
              data.ListaEmpresas.map(empresa => {
                if(JSON.stringify(empresa._id) == JSON.stringify(idEmpresa)){
                  this.setState({ ListaEmpresas: data.ListaEmpresas, IDEmpresa: empresa, Activo: 1, cargando:false });
                }
              })   
            }
            else {
              if(JSON.stringify(data.ListaEmpresas._id) == JSON.stringify(idEmpresa)){
                let ListaAuxEmpresas = [];
                ListaAuxEmpresas.push(data.ListaEmpresas);
                //console.log(ListaAux);
                this.setState({ ListaEmpresas: ListaAuxEmpresas, IDEmpresa: data.ListaEmpresas, Activo: 1, cargando:false });
              }
            }
          }
          else{
            if (data.ListaEmpresas.length > 1) {
            this.setState({ ListaEmpresas: data.ListaEmpresas, Activo: 1, cargando:false });
            }
            else {
              let ListaAuxEmpresas = [];
              ListaAuxEmpresas.push(data.ListaEmpresas);
              //console.log(ListaAux);
              this.setState({ ListaEmpresas: ListaAuxEmpresas, Activo: 1, cargando:false });
            }
          }
          
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }

async TipoGastoListado(idTipoGasto) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Gastos/Gastos/TipoGastoListado', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              if(idTipoGasto !== 0){
                if (data.ListaTipoGasto.length > 1) {
                  data.ListaTipoGasto.map(tipo => {
                    if(JSON.stringify(tipo._id) == JSON.stringify(idTipoGasto)){
                      this.setState({ ListaTipoGasto: data.ListaTipoGasto, TipoGastoSeleccionado: tipo, Activo: 1, cargando: false });
                    }
                  })
              }
              else {
                if(JSON.stringify(data.ListaTipoGasto._id) == JSON.stringify(idTipoGasto)){
                  let ListaAux = [];
                  ListaAux.push(data.ListaTipoGasto);
                  //console.log(ListaAux);
                  this.setState({ ListaTipoGasto: ListaAux, TipoGastoSeleccionado: data.ListaTipoGasto, Activo: 1, cargando: false });
                }
              }
              }
              else{
                if (data.ListaTipoGasto.length > 1) {
                    this.setState({ ListaTipoGasto: data.ListaTipoGasto, Activo: 1, cargando: false });
                }
                else {
                    let ListaAux = [];
                    ListaAux.push(data.ListaTipoGasto);
                    //console.log(ListaAux);
                    this.setState({ ListaTipoGasto: ListaAux, Activo: 1, cargando: false });
                }
              }
                
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

async ListaGeneralProveedores(IdProveedor) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Gastos/Gastos/ListaGeneralProveedores', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              if(IdProveedor !== 0){
                if (data.ListaProveedor.length > 1) {
                  data.ListaProveedor.map(proveedor => {
                    if(JSON.stringify(proveedor._id) == JSON.stringify(IdProveedor)){
                      this.setState({ ListaProveedor: data.ListaProveedor, ProveedorSeleccionado: proveedor, Activo: 1, cargando: false });
                    }
                  })
                }
                else {
                  if(JSON.stringify(data.ListaProveedor._id) == JSON.stringify(IdProveedor)){
                    let ListaAux = [];
                    ListaAux.push(data.ListaProveedor);
                    //console.log(ListaAux);
                    this.setState({ ListaProveedor: ListaAux, ProveedorSeleccionado: data.ListaProveedor, Activo: 1, cargando: false });
                  }
                }
              }
              else{
                if (data.ListaProveedor.length > 1) {
                    this.setState({ ListaProveedor: data.ListaProveedor, Activo: 1, cargando: false });
                }
                else {
                    let ListaAux = [];
                    ListaAux.push(data.ListaProveedor);
                    //console.log(ListaAux);
                    this.setState({ ListaProveedor: ListaAux, Activo: 1, cargando: false });
                }
              }
                
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

CrearArrGastos(bool){
  let objProducto;
  let aux = [];
  let IDcookie = JSON.parse(cookie.get('id'));
  const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
  let gastoMonto;
  let valido = true;

  if(bool){
    this.state.ListaGastosImp.map(gasto => {
      gastoMonto = (gasto.gasto).replace(/[\$,]/g, "");

      objProducto = {
        Comprobante: gasto.TipoRecibo,
        Descripcion: gasto.concepto,
        Estado: "Pagado",
        FechaCancelacion: "0001-01-01",
        FechaPagado: moment(gasto.fecha, "DD-MM-YYYY").format("YYYY-MM-DD"),
        FechaPago: moment(gasto.fecha, "DD-MM-YYYY").format("YYYY-MM-DD"),
        FechaRegistro: moment(gasto.fecha, "DD-MM-YYYY").format("YYYY-MM-DD"),
        IdUsuario: IDcookie,
        IdUsuarioCancela: idVacio,
        Monto: gastoMonto,
        // NombreEmpresa: this.state.IDEmpresa.RazonSocial,
        // NombreProveedor: this.state.ProveedorSeleccionado !== '' ? this.state.ProveedorSeleccionado.Razonsocial : null,
        // NombreSubtipoGasto: this.state.SubtipoGastoSeleccionado !== '' ? this.state.SubtipoGastoSeleccionado.Nombre : null,
        // NombreTipoGasto: this.state.TipoGastoSeleccionado !== '' ? this.state.TipoGastoSeleccionado.Nombre : null,
        OrigenEfectivo: "Banco",
        SubtipoGasto: gasto.SubtipoGastoSeleccionado !== undefined ? gasto.SubtipoGastoSeleccionado._id : idVacio,
        Subtotal: gasto.subtotal,
        TipoGasto: gasto.TipoGastoSeleccionado !== undefined ? gasto.TipoGastoSeleccionado._id : idVacio,
        TipoPago: gasto.TipoPago,
        _idEmpresa: this.state.IDEmpresa._id,
        _idProveedor: gasto.ProveedorSeleccionado !== undefined ? gasto.ProveedorSeleccionado._id : idVacio,
      }

      aux.push(objProducto)
    })
    // console.log(aux)
    this.ImportarGastos(aux)
    // console.log("Entro a importar")
  }
  else{
    if(this.state.ListaGastosImp.length == 0){
      valido = false;
      this.setState({mensaje: "No hay datos importados", colorNotificacion: "danger"})
      this.showNotification()
      return
    }

    if(this.state.IDEmpresa == ''){
      valido = false;
      this.setState({mensaje: "Seleccione una empresa", colorNotificacion: "danger", errorEmpresa: true})
      this.showNotification()
      return
    }
    else{
      this.setState({errorEmpresa: false})
    }

    
    if(this.state.ListaGastosImp.find(e => e.Error == true) !== undefined){
      valido = false;
      this.setState({mensaje: "Faltan campos por llenar", colorNotificacion: "danger"})
      this.showNotification()
    }
    else if(this.state.ListaGastosImp.find(e => e.AdvertenciaTotales == true) !== undefined){
      valido = false;
      this.setState({show: true});
    }

    if(valido){
      this.state.ListaGastosImp.map(gasto => {
        gastoMonto = (gasto.gasto).replace(/[\$,]/g, "");

        objProducto = {
          Comprobante: gasto.TipoRecibo,
          Descripcion: gasto.concepto,
          Estado: "Pagado",
          FechaCancelacion: "0001-01-01",
          FechaPagado: moment(gasto.fecha, "DD-MM-YYYY").format("YYYY-MM-DD"),
          FechaPago: moment(gasto.fecha, "DD-MM-YYYY").format("YYYY-MM-DD"),
          FechaRegistro: moment(gasto.fecha, "DD-MM-YYYY").format("YYYY-MM-DD"),
          IdUsuario: IDcookie,
          IdUsuarioCancela: idVacio,
          Monto: gastoMonto,
          // NombreEmpresa: this.state.IDEmpresa.RazonSocial,
          // NombreProveedor: this.state.ProveedorSeleccionado !== '' ? this.state.ProveedorSeleccionado.Razonsocial : null,
          // NombreSubtipoGasto: this.state.SubtipoGastoSeleccionado !== '' ? this.state.SubtipoGastoSeleccionado.Nombre : null,
          // NombreTipoGasto: this.state.TipoGastoSeleccionado !== '' ? this.state.TipoGastoSeleccionado.Nombre : null,
          OrigenEfectivo: "Banco",
          SubtipoGasto: gasto.SubtipoGastoSeleccionado !== undefined ? gasto.SubtipoGastoSeleccionado._id : idVacio,
          Subtotal: gasto.subtotal,
          TipoGasto: gasto.TipoGastoSeleccionado !== undefined ? gasto.TipoGastoSeleccionado._id : idVacio,
          TipoPago: gasto.TipoPago,
          _idEmpresa: this.state.IDEmpresa._id,
          _idProveedor: gasto.ProveedorSeleccionado !== undefined ? gasto.ProveedorSeleccionado._id : idVacio,
        }

        aux.push(objProducto)
      })
      // console.log(aux)
      this.ImportarGastos(aux)
      // console.log("Entro a importar")
    }
  }
}

async ImportarGastos(ArrGastos) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Gastos/Gastos/ImportarGastos', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({PagoProveedor: ArrGastos})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
        if (data.error) { //Error en la respuesta
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
            this.showNotification();
        } else { //Filtrado exitoso
          this.setState({cargando: false});
          this.RegresarMensaje(data.mensaje);
        }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    switch(name){
      case "TipoGastoSeleccionado":
        if(JSON.stringify(value) !== ''){
            this.TipoSubGastoListado(value._id, 0);
            this.setState({ [name]: value });
        }
        else{
            this.setState({ [name]: value, ListaTipoSubGasto: [], SubtipoGastoSeleccionado: idVacio });
        }
        break;

      case "Subtotal":
        this.setState({[name]: value, Total: value});
        break;

      default:
        this.setState({[name]: value});
        break;
    }
  }

  // handleChangeEdit(e, item){
  //   const { name, value } = e.target;
  //   let gastos = this.state.ListaGastosImp;
  //   let seleccionado;
  //   // let suma = 0;
  //   // let sumaAux = 0;
  //   // let sumaAuxAux = 0;

  //   seleccionado = gastos.indexOf(item);

  //   switch(name){
  //     case "DescripcionProd":
  //       gastos[seleccionado].concepto = value;

  //       this.setState({ListaGastosImp: gastos})
  //       break;

  //     case "Subtotal":
  //       let cantidad = value.replace(/[^0-9]/g, '');
  //       if(cantidad < 0 || cantidad == ""){
  //         gastos[seleccionado].subtotal = 0;
  //         gastos[seleccionado].total = 0;
  //       }
  //       else{
  //         gastos[seleccionado].subtotal = cantidad;
  //         gastos[seleccionado].total = cantidad;
  //       }
        
  //       this.setState({ListaGastosImp: gastos})
  //       break;

  //       case "opcionTipoPago":
  //         gastos[seleccionado].TipoPago = value;

  //         this.setState({ListaGastosImp: gastos})
  //         break;
        
  //       case "opcionTipoRecibo":
  //         gastos[seleccionado].TipoRecibo = value;

  //         this.setState({ListaGastosImp: gastos})
  //         break;
  //   }
  // }

  // handleFocus = (event) => {
  //   const e = event.target;
  //   e.select();
  // }

  SeleccionarCSV() {
    document.getElementById("inputCsv").click();
  }

  AgregarGastos(gastos){
    this.setState({cargando: true})
    const formato4Digit = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/i;
    // const formato2Digit = /^\d{2}\/\d{2}\/\d{2}$/i;
    // console.log(gastos)
    let FormatoFechaValido = true

    for(let i = 0; i < gastos.length; i++){
      if(formato4Digit.test(gastos[i].fecha) === false/*  || formato2Digit.test(gastos[0].fecha) */ ){
        FormatoFechaValido = false
      }
    }

    if(gastos[0].gasto !== undefined && FormatoFechaValido){  //Verificar que la columna del csv si sea gasto
      gastos.map(gasto => {

        if(gasto.subtotal == undefined && gasto.subtotal == null){
          gasto.subtotal = 0;
        }

        if(gasto.total == undefined && gasto.total == null){
          gasto.total = 0;
        }

        if(gasto.TipoPago == undefined && gasto.TipoPago == null){
          gasto.TipoPago = "Efectivo";
        }

        if(gasto.TipoRecibo == undefined && gasto.TipoRecibo == null){
          gasto.TipoRecibo = "Recibo";
        }

        gasto.Error = true;
        gastos.AdvertenciaTotales = true;
      })

      this.setState({ListaGastosImp: gastos, cargando: false})
    }
    else{
      this.setState({mensaje:"No se pudo importar el archivo, porfavor verifique los datos e intentelo de nuevo", colorNotificacion: "danger", cargando: false});
      this.showNotification();
    }
  }

  // cerrarModal = () => {
  //   this.props.eventClick("esc")
  // }

  RegresarListaGastoIndiv = (lista, index) => {
    this.state.ListaGastosImp[index] = lista
  }

  eventoPregunta = (valor) => {
    this.setState({show: false});
    if(valor){
      this.CrearArrGastos(valor);
    }
  }
 
  render() {
    const { classes } = this.props;
    // console.log(this.state.ListaGastosImp)
    // console.log(moment("1/1/25", "DD-MM-YYYY").isValid())
    // console.log(moment("5/4/25", "DD-MM-YYYY").format("YYYY-MM-DD"))
    

    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header => header.toLowerCase().replace(/\W/g, '')
    };

    let agregar;
    if(this.state.cargando == true){
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    if(this.state.show == true){
      agregar = (
        <ModalPregunta
          show={this.state.show}
          headerColor={"warning"}
          Titulo={"Verificar totales"}
          Cuerpo={"Los totales no coinciden. ¿Desea continuar?"}
          eventClick={this.eventoPregunta}
        />
      )
    }

    let Importado;
    if(this.state.ListaGastosImp.length > 0){
      Importado =
        this.state.ListaGastosImp.map((gasto, index) => {
          return(
            <ExtencionImportarGastos
              ListaTipoGasto={this.state.ListaTipoGasto}
              ListaProveedor={this.state.ListaProveedor}
              Gasto={gasto}
              indexGasto={index}
              eventClick={this.RegresarListaGastoIndiv}
            />
          )
        })
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showModalImportarGasto}
          className="static-modal"
          size="lg"
          // toggle={this.cerrarModal}
          backdrop={true}
          style={{ maxWidth: "1380px", width: "100%" }}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem>
                  <FormControl className={classes.formControl} size="small" variant="outlined" error={this.state.errorEmpresa}>
                    <InputLabel style={{ marginTop: "10px" }} id="empresalabel">Empresa</InputLabel>
                    <Select
                      labelId="empresalabel"
                      //fullWidth={true}
                      value={this.state.IDEmpresa}
                      onChange={this.handleChange.bind(this)}
                      name="IDEmpresa"
                      //fullWidth={true}
                      label="Empresa"
                      style={{ width: "22em", marginTop: "10px" }}
                      // error={this.state.errorEmpresa}
                      // success={!this.state.errorEmpresa}
                    >
                      <MenuItem disabled>Seleccione una empresa</MenuItem>
                      {this.state.ListaEmpresas.map(Empresa =>
                        <MenuItem value={Empresa}>{(Empresa.RazonSocial).toUpperCase()}</MenuItem>
                      )}
                    </Select>
                    {this.state.errorEmpresa ? <FormHelperText>Seleccione una empresa</FormHelperText> : null}
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="table-responsive text-nowrap" style={{ height: "100%", maxHeight: "70vh",overflowY:"scroll" }}>
                    <table className="table table-sm table-wrapper-scroll-x" >
                      <thead>
                        <th>Fecha</th>
                        <th>Descripción</th>
                        <th>Tipo de gasto</th>
                        <th>Subtipo</th>
                        <th>Proveedor</th>
                        <th>Forma de pago</th>
                        <th>Comprobante</th>
                        <th>Subtotal</th>
                        <th>Total</th>
                        <th>Total banco</th>
                      </thead>
                      <tbody>
                        {Importado}
                      </tbody>
                    </table>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>  
          <GridItem xs={12} sm={12} md={12}>
              <div style={{height: "0px", width: "0px", overflow: "hidden"}}><CSVReader inputId={"inputCsv"} onFileLoaded={(data) => this.AgregarGastos(data)} parserOptions={papaparseOptions} /></div>
              <Button color="info" tabIndex="3" size='sm' className='pull-right mb-3' round onClick={() => this.CrearArrGastos()}><Save/>&nbsp;Importar</Button>
              <Button tabIndex="2" color="danger" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cancelar</Button>
              <Button tabIndex="1" size='sm' className='pull-left ml-2 mb-3' onClick={() => this.SeleccionarCSV()} round>Seleccionar archivo</Button>
          </GridItem>   
        </Modal>
        <div id="">
          {agregar}
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalImportarGastos);