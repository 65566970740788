import React from "react";
import { Modal } from "reactstrap";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Close, Save } from "@material-ui/icons";

var ClassLibro = {
  Nombre: "",
  Orden: 0,
  Unidades: { Unidad: [] },
  _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
};
var ClassUnidad = {
  Estado: "Activo",
  IdLibro: { _increment: '0', _machine: '0', _pid: '0', _timestamp: '0' },
  NombreUnidad: "",
  Orden: 1,
  _id: { _increment: '0', _machine: '0', _pid: '0', _timestamp: '0' }
}

class ModalUnidades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      _idLibro: "",
      _idIidioma: "",
      IdNuevo: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      UnidadesSeleccionadas: [],
      ModulosSeleccionados: [],
      ListaLibros: [],
      orden: 0,
      ListaUnidades: [],
      ListaUnidadesTemp: [],
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      CheckAll: false,
      PrimerCarga: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorUsuario: false,
      errorEmpresa: false,
      notificacion: false,
      CuerpoCargando: "Cargando",
    };
    global.entrar = true;
  }

  componentDidMount() {
    this.ListaGenralLibros(this.props.Idioma);
    this.setState({_idIidioma: this.props.Idioma});
    if (this.props.op === 1) {
      let Unidades = []
      if(this.props.LibroSelecionado.Unidades != undefined && this.props.LibroSelecionado.Unidades != null){
        if(this.props.LibroSelecionado.Unidades.Unidad.length > 1){
          Unidades = this.props.LibroSelecionado.Unidades.Unidad
        }
        else{
          Unidades.push(this.props.LibroSelecionado.Unidades.Unidad);
        }
        
      }

      this.setState({PrimerCarga: true, _idLibro: JSON.stringify(this.props.LibroSelecionado._id), UnidadesSeleccionadas: Unidades})
      this.ListarUnidades(this.props.LibroSelecionado._id);
    }
  }

  Validaciones() {
    if (this.state._idLibro === "") {
      this.setState({
        mensaje: "Es necesario seleccionar un libro.",
        colorNotificacion: "danger",
      });
      this.showNotification();
      return;
    }
    if (this.state.UnidadesSeleccionadas.length === 0) {
      this.setState({
        mensaje: "Seleccionar al menos una unidad.",
        colorNotificacion: "danger",
      });
      this.showNotification();
      return;
    }else{
      this.state.UnidadesSeleccionadas.map( (unidad,key) =>{
        //console.log(unidad._id)
        let index = this.state.ListaUnidades.findIndex( item => JSON.stringify(unidad._id)==JSON.stringify(item._id))
        if (index == -1) {
          this.state.UnidadesSeleccionadas.splice(key,1)
        }
      })
    }

    var libro = this.state.ListaLibros.find((e) => JSON.stringify(e._id) == this.state._idLibro);
    if (libro != undefined) {
      let auxLibro = {
        Nombre: libro.Nombre,
        Orden: libro.Orden,
        Unidades: { Unidad: this.state.UnidadesSeleccionadas },
        _id: libro._id,
      }
      this.props.eventClick(auxLibro)
    }
  }

  async ListaGenralLibros(id) {
    this.setState({cargando: true})
    await fetch(Globales.Url + "Catalogos/Niveles/ListaLibrosPorIdioma", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ IdIdioma: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          //console.log(data)
          if (data.ListaLibros.Libro.length > 1) {
            this.setState({ListaLibros: data.ListaLibros.Libro, ListaLibrosTemp: data.ListaLibros.Libro, cargando: false});
          } else {
            let aux = [];
            aux.push(data.ListaLibros.Libro);

            this.setState({ListaLibros: aux, ListaLibrosTemp: aux, cargando: false});
          }  
        }
      })
      .catch((err) => console.error("El error es", err));
  }
  ListarUnidades(_idLibro) {
    this.setState({cargando: true})
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaUnidadesLibro', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({libro: _idLibro})
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data)
        if (data.error) { //Error en la respuesta
          this.setState({ ListaUnidades: [], Unidad: "null", cargando: false, mensaje: data.mensaje, colorNotificacion: "danger" });
          this.showNotification();
        } else { //Filtrado exitoso
          var ListaUnidades = []
          var ListaUnidadesTemp = []

          if (data.data.length > 1) {
            ListaUnidades = data.data
          } 
          else {
            ListaUnidades.push(data.data)
          }


          ListaUnidades.map(unidad => {
            unidad.seleccionado = false
          })

          this.setState({ ListaUnidades: ListaUnidades, cargando: false }, () => {
            if (this.props.op == 1) {
              this.CompararSeleccionados()
            }
          })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  CompararSeleccionados() {
    this.setState({cargando: true})
    let ListaUnidades = this.state.ListaUnidades
    let UnidadesSeleccionadas = this.state.UnidadesSeleccionadas

    //console.log(UnidadesSeleccionadas)

    UnidadesSeleccionadas.map(unidad => {
      let index = ListaUnidades.findIndex(Unit => JSON.stringify(Unit._id) == JSON.stringify(unidad._id))
      
      if( index > -1) {
        ListaUnidades[index].seleccionado = true
      }
    })

    this.setState({ ListaUnidades: ListaUnidades, PrimerCarga: false, cargando: false})
  }


  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  RegresarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.parentCallback(mensaje);
    this.closeModal();
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;

    if (name === "_idLibro") {
      this.ListarUnidades(JSON.parse(value))
    }

    this.setState({[name]: value});
  }

  handleModulo(Info) {
    let Unidades = this.state.ListaUnidades;
    let SeleccionadosOrdenados = [];
    let Seleccionados = this.state.UnidadesSeleccionadas;

    if (Info.seleccionado) {
      let index = Unidades.findIndex(element => element == Info)
      if (index > -1) {
        Unidades[index].seleccionado = false

        let indexSelect = Seleccionados.findIndex(element => JSON.stringify(element._id) == JSON.stringify(Info._id))
        Seleccionados.splice(indexSelect, 1);
      }
    } else {
      let index = Unidades.findIndex(element => element == Info)
      if (index > -1) {
        Unidades[index].seleccionado = true
        Seleccionados.push(Unidades[index])
      }
    }

    SeleccionadosOrdenados = Seleccionados.sort((a, b) => parseInt(a.Orden) - parseInt(b.Orden));

    this.setState({
      ListaUnidades: Unidades,
      UnidadesSeleccionadas: Seleccionados,
    });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;

    //console.log("ListaUnidades: ", this.state.ListaUnidades)
    // console.log(this.state.UnidadesSeleccionadas)

    let agregar;

    if (this.state.cargando) {
      agregar = (
        <ModalCargando Cuerpo={this.state.CuerpoCargando} show={this.state.cargando} />
      );
    }

    let Modulos;
    if (this.state.ListaUnidades.length > 0) {
      Modulos = (
        <div className="table-responsive text-nowrap table-wrapper-scroll-y">
          <table className="table table-sm table-hover">
            <thead>
              <th>Unidad</th>
              <th></th>
            </thead>
            <tbody>
              {this.state.ListaUnidades.map((Modul, index) => {
                return (
                  <tr>
                    <td style={{verticalAlign: "middle", cursor: "default"}}>{Modul.NombreUnidad}</td>
                    <td style={{verticalAlign: "middle", cursor: "default"}}>
                      {" "}
                      <Checkbox
                        id={Modul._id}
                        className="pull-right"
                        checked={Modul.seleccionado ? true : false}
                        onChange={this.handleModulo.bind(this, Modul)}
                        name="checkedInactivos"
                      />{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      Modulos = (
        <div className="my-3" style={{ textAlign: "center" }}>
          NO HAY UNIDADES REGISTRADAS
        </div>
      );
    }
    return (
      <div>
        <Modal
          isOpen={this.props.showModalLibros}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Unidades del libro</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    size="small"
                    style={{ marginBottom: "27px" }}
                  // error={this.state.errorEmpresa}
                  >
                    <InputLabel
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      Libros
                    </InputLabel>
                    <Select
                      value={this.state._idLibro}
                      onChange={this.handleChange.bind(this)}
                      name="_idLibro"
                      id="_idLibro"
                      defaultValue={""}
                      variant="outlined"
                      label="Libro"
                      style={{
                        marginTop: "10px",
                        width: "22em",
                      }}
                    >
                      <MenuItem disabled>Seleccione un libro</MenuItem>
                      {this.state.ListaLibros.map((libro, Index) => (
                        <MenuItem
                          key={Index}
                          value={JSON.stringify(libro._id)}
                        >
                          {libro.Nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Unidades</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {Modulos}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <div>
            <Button
              size="sm"
              type="submit"
              color="info"
              round
              className="pull-right mx-2"
              onClick={() => this.Validaciones()}
            >
              <Save />
              &nbsp;Guardar
            </Button>
            <Button
              tabIndex="1"
              size="sm"
              className="pull-right mx-2"
              round
              onClick={() => this.props.eventClick("esc")}
            >
              <Close />
              &nbsp;Cancelar
            </Button>
          </div>
        </Modal>
        <div id="">
          {agregar}
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(ModalUnidades);
