import React from "react";
import { Modal } from "reactstrap";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { AsYouType } from "libphonenumber-js";

class ModalGuardarEmpresa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      _idEstado: "",
      _idMunicipio: "",
      _idPais: "",
      RFC: "",
      RazonSocial: "",
      RepresentanteLegal: "",
      RegimenFiscal: "",
      PaginaWeb: "",
      TimbresDisponibles:0,
      ListaClavesRegimen: [],
      ListaEstados: [],
      ListaPaises: [],
      ListaMunicipios: [],
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorRFC: false,
      errorRazonSocial: false,
      errorRegimenFiscal: false,
      errorTelefono: false,
      errorPais: false,
      errorEstado: false,
      errorMunicipio: false,
      errorColonia: false,
      errorCodigoPostal: false,
      errorCalle: false,
      entroFiltrar: false,
      notificacion: false,
      Telefono1: "",
      Telefono2: "",
      Calle: "",
      CodigoPostal: "",
      Colonia: "",
      DireccionCompleta: "",
      Estado: "",
      NumeroExterior: "",
      NumeroInterior: "",
      cargando: false,
      EsEditar: false,
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.setState({
      cargando: true,
      entroEliminar: true,
    });
    await this.ObtenerClaveRegimenFiscal()
    await this.ListaGeneralPaises();

    if (this.props.op == 1) {
      const {
        _id,
        RFC,
        RazonSocial,
        RepresentanteLegal,
        RegimenFiscal,
        PaginaWeb,
        Telefono1,
        Telefono2,
        Calle,
        CodigoPostal,
        Colonia,
        Estado,
        NumeroExterior,
        NumeroInterior,
        _idEstado,
        _idMunicipio,
        _idPais,
        IdClienteFactura,
      } = this.props.InfoEmpresa;

      await this.CargarDatos(JSON.stringify(_idPais), JSON.stringify(_idEstado));
      await this.ObtenerTimbresDiponibles(IdClienteFactura)

      this.setState({
        _id: _id || "",
        _idEstado: JSON.stringify(_idEstado) || "",
        _idMunicipio: JSON.stringify(_idMunicipio) || "",
        _idPais: JSON.stringify(_idPais) || "",
        RFC: RFC || "",
        RazonSocial: RazonSocial || "",
        RepresentanteLegal: RepresentanteLegal || "",
        RegimenFiscal: RegimenFiscal || "",
        PaginaWeb: PaginaWeb || "",
        Telefono1: Telefono1 || "",
        Telefono2: Telefono2 || "",
        Calle: Calle || "",
        CodigoPostal: CodigoPostal || "",
        Colonia: Colonia || "",
        Estado: Estado || "",
        NumeroExterior: NumeroExterior || "",
        NumeroInterior: NumeroInterior || "",
      });
    }
    this.setState({
      cargando: false,
    });
  }

  async CargarDatos(pais, estado) {
    await this.ListaGeneralEstados(pais);
    await this.ListaGeneralMunicipios(estado);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  async ValidarEmpresa() {
    let valido = true;

    if (this.state.RFC.length < 12) {
      valido = false;
      this.setState({
        colorNotificacion: "danger",
        errorRFC: true,
        mensaje:
          "El RFC es de captura obligatoria y no puede tener menos de 12 carácteres.",
      });
      this.showNotification();
    }
    else{
      this.setState({errorRFC: false})
    }

    if (this.state.RazonSocial.length < 10) {
      valido = false;
      this.setState({
        colorNotificacion: "danger",
        errorRazonSocial: true,
        mensaje:
          "La razón social es de captura obligatoria, Verifique su información.",
      });
      this.showNotification();
    }
    else{
      this.setState({errorRazonSocial: false})
    }

    if (this.state.RegimenFiscal.length < 1) {
      valido = false;
      this.setState({
        colorNotificacion: "danger",
        errorRegimenFiscal: true,
        mensaje:
          "El regímen fiscal es de captura obligatoria, Verifique su información.",
      });
      this.showNotification();
    }
    else{
      this.setState({errorRegimenFiscal: false})
    }

    if (this.state.Telefono1.length < 10) {
      valido = false;
      this.setState({
        colorNotificacion: "danger",
        errorTelefono: true,
        mensaje: "Se debe capturar el primer número telefónico.",
      });
      this.showNotification();
    }
    else{
      this.setState({errorTelefono: false})
    }

    if (!this.state._idPais) {
      valido = false;
      this.setState({
        colorNotificacion: "danger",
        errorPais: true,
        mensaje: "Se debe seleccionar un país.",
      });
      this.showNotification();
    }
    else{
      this.setState({errorPais: false})
    }

    if (!this.state._idEstado) {
      valido = false;
      this.setState({
        colorNotificacion: "danger",
        errorEstado: true,
        mensaje: "Se debe seleccionar un estado.",
      });
      this.showNotification();
    }
    else{
      this.setState({errorEstado: false})
    }

    if (!this.state._idMunicipio) {
      valido = false;
      this.setState({
        colorNotificacion: "danger",
        errorMunicipio: true,
        mensaje: "Se debe seleccionar un municipio.",
      });
      this.showNotification();
    }
    else{
      this.setState({errorMunicipio: false})
    }

    if (!this.state.Colonia) {
      valido = false;
      this.setState({
        colorNotificacion: "danger",
        errorColonia: true,
        mensaje: "Se debe capturar el nombre de la colonia.",
      });
      this.showNotification();
    }
    else{
      this.setState({errorColonia: false})
    }

    if (this.state.CodigoPostal.length < 5) {
      valido = false;
      this.setState({
        colorNotificacion: "danger",
        errorCodigoPostal: true,
        mensaje:
          "El código postal es de captura obligatoria, con minimo 5 carácteres.",
      });
      this.showNotification();
    }
    else{
      this.setState({errorCodigoPostal: false})
    }

    if (!this.state.Calle) {
      valido = false;
      this.setState({
        colorNotificacion: "danger",
        errorCalle: true,
        mensaje: "Se debe capturar el nombre de la calle.",
      });
      this.showNotification();
    }
    else{
      this.setState({errorCalle: false})
    }

    if(valido){
      await this.RegistrarEmpresa();
    }
  }

  async ListaGeneralPaises() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Paises/ListaGeneralPaises", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          if (data.ListadePaises.Pais.length > 1) {
            this.setState({
              ListaPaises: data.ListadePaises.Pais,
              cargando: false,
              entroEliminar: false,
            });
          } else {
            let aux = []
            aux.push(data.ListadePaises.Pais)
            this.setState({
              ListaPaises: aux,
              cargando: false,
              entroEliminar: false,
            });
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ObtenerTimbresDiponibles(idCliente) {
    this.setState({ cargando: true});
    await fetch(Globales.Url + 'Catalogos/Empresas/ObtenerTimbresDiponibles', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idCliente})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ cargando: false });
                // this.showNotification();
            } else { //Filtrado exitoso
              this.setState({ TimbresDisponibles: data.data == "" ? "No aplica" : data.data, cargando: false });
              // this.setState({ ListaClavesRegimen: data.data });
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  async ObtenerClaveRegimenFiscal() {
    await fetch(Globales.Url + 'Facturacion/Facturas/ObtenerClaveRegimenFiscal', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ cargando: false });
                // this.showNotification();
            } else { //Filtrado exitoso
              this.setState({ ListaClavesRegimen: data.data });
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  RegresarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.eventClick(mensaje, "success");
  }

  async RegistrarEmpresa() {
    this.setState({ cargando: true });
    await fetch(Globales.Url + "Catalogos/Empresas/RegistrarEmpresa", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        Calle: this.state.Calle,
        CodigoPostal: this.state.CodigoPostal,
        Colonia: this.state.Colonia,
        //  DireccionCompleta:this.state,
        //  Estado:this.state._idEstado,
        NumeroExterior: this.state.NumeroExterior || 0,
        NumeroInterior: this.state.NumeroInterior || 0,
        PaginaWeb: this.state.PaginaWeb,
        RazonSocial: this.state.RazonSocial,
        RegimenFiscal: this.state.RegimenFiscal,
        RepresentanteLegal: this.state.RepresentanteLegal,
        RFC: this.state.RFC,
        Telefono1: this.state.Telefono1,
        Telefono2: this.state.Telefono2,
        // Telefonos:this.state.,
        _id: this.state._id,
        _idEstado: this.state._idEstado
          ? JSON.parse(this.state._idEstado)
          : this.state.IdNormal,

        _idMunicipio: this.state._idMunicipio
          ? JSON.parse(this.state._idMunicipio)
          : this.state.IdNormal,

        _idPais: this.state._idPais
          ? JSON.parse(this.state._idPais)
          : this.state.IdNormal,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
          });
          this.showNotification();
        } else {
          //Filtrado exitoso
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "success",
            Activo: 1,
            cargando: false,
            RFC: "",
            RazonSocial: "",
            RepresentanteLegal: "",
            RegimenFiscal: "",
            Telefono: "",
            NumCelular: "",
            OpcionClase: "",
            Empresa: "",
            NumFamiliaresEstudiando: "",
            MedioConocioEscuela: "",
            OpcionNivelRes: "",
            ListaProspecto: {},
            ListaIdiomas: [],
            ListaNiveles: [],
            IDIdioma: "",
            RFCNivel: "",
            ArrRFCNivel: [],
            ArrIdiomaSeleccionados: [],
            ArrEstudiosAnte: [],
            ArrSeguimiento: [],
          });
          this.RegresarMensaje(data.mensaje);
        }
      })
      .catch((err) => console.error(err));
  }

  async ListaGeneralEstados(Pais) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Estados/ListaGeneralEstados", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        _idPais: JSON.parse(Pais),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          const TipoData = Array.isArray(data.ListadeEstados.Estado);
          const EstadosData = TipoData
            ? data.ListadeEstados.Estado
            : [{ ...data.ListadeEstados.Estado }];
          if (EstadosData.length > 0) {
            this.setState({
              ListaEstados: EstadosData,
              cargando: false,
            });
          } else {
            this.setState({
              ListaEstados: [],
              cargando: false,
              colorNotificacion: "danger",
              mensaje: "Aun no cuenta con estados registrados",
            });
            this.showNotification();
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ListaGeneralMunicipios(Estado) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Municipios/ListaGeneralMunicipios", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        _idEstado: JSON.parse(Estado),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
            _idEstado: "",
          });
          this.showNotification();
        } else {
          const TipoData = Array.isArray(data.ListadeMunicipios.Municipio);
          const MunicipioData = TipoData
            ? data.ListadeMunicipios.Municipio
            : [{ ...data.ListadeMunicipios.Municipio }];
          if (MunicipioData.length > 0) {
            this.setState({
              ListaMunicipios: MunicipioData,
              cargando: false,
              entroEliminar: false,
            });
          } else {
            this.setState({
              ListaMunicipios: [],
              cargando: false,
              colorNotificacion: "danger",
              mensaje: "Aun no cuenta con municipios asignados",
            });
            this.showNotification();
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    if (name === "NombrePais") {
      this.setState({
        _idPais: value,
        _idMunicipio: "",
        _idEstado: "",
        ListaEstados: [],
        ListaMunicipios: [],
      });
      if (value) {
        this.ListaGeneralEstados(value);
      } else if (name === "NombreEstado") {
        this.setState({
          _idEstado: value,
          ListaMunicipios: [],
        });
        if (value) {
          this.ListaGeneralMunicipios(value);
        }
      }
    } else if (name === "NombreEstado") {
      this.setState({
        _idEstado: value,
        ListaMunicipios: [],
      });
      if (value) {
        this.ListaGeneralMunicipios(value);
      }
    } else if (name === "CodigoPostal") {
      if (value.length > 5) {
        return;
      }
      this.setState({
        CodigoPostal: value,
      });
    } else if (name === "RFC") {
      if (value.length > 13) {
        return;
      }
      this.setState({
        RFC: value,
        errorRFC: false,
      });
    } else if (name === "RazonSocial") {
      if (value.length > 100) {
        return;
      }
      this.setState({
        RazonSocial: value,
        errorRazonSocial: false,
      });
    } else {
      this.setState({ [name]: value });
    }
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;
    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }
    return (
      <div>
        <Modal
          isOpen={this.props.showAgregar}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Información general</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="RFC"
                    style={{ width: "25em" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="RFC"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.RFC,
                      name: "RFC",
                      id: "RFC",
                      error: this.state.errorRFC,
                      success: !this.state.errorRFC,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="RazonSocial"
                    style={{ width: "25em" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Razon Social"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.RazonSocial,
                      name: "RazonSocial",
                      id: "RazonSocial",
                      error: this.state.errorRazonSocial,
                      success: !this.state.errorRazonSocial,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="RepresentanteLegal"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Representante Legal"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.RepresentanteLegal,
                      name: "RepresentanteLegal",
                      id: "RepresentanteLegal",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined" >
                    <InputLabel style={{ marginTop: "10px" }} id="Claselabel">
                    Régimen Fiscal
                    </InputLabel>
                    <Select
                      labelId="Claselabel"
                      value={this.state.RegimenFiscal}
                      onChange={this.handleChange.bind(this)}
                      name="RegimenFiscal"
                      label="Régimen Fiscal"
                      style={{ width: "21.9em", marginTop: "10px" }}
                      error={this.state.errorPais}
                      success={!this.state.errorPais}
                    >
                      <MenuItem disabled>Seleccione un país</MenuItem>
                      {
                        this.state.ListaClavesRegimen.map(item =>
                          <MenuItem value={item.ClaveRegimen.toString()}>{item.Descripcion}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </GridItem>
                
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="Telefono1"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="tel"
                    size="small"
                    label="Número de teléfono 1"
                    inputProps={{ maxLength: 12 }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: new AsYouType("MX").input(this.state.Telefono1),
                      name: "Telefono1",
                      id: "Telefono1",
                      error: this.state.errorTelefono,
                      success: !this.state.errorTelefono,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="Telefono2"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="tel"
                    size="small"
                    label="Número de teléfono 2"
                    inputProps={{ maxLength: 12 }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: new AsYouType("MX").input(this.state.Telefono2),
                      name: "Telefono2",
                      id: "Telefono2",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                  <TextField
                    id="PaginaWeb"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Pagina Web"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.PaginaWeb,
                      name: "PaginaWeb",
                      id: "PaginaWeb",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                  <TextField
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Timbres disponibles"
                    InputProps={{
                      disabled:true,
                      value: this.state.TimbresDisponibles,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Domicilio fiscal</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    className={classes.formControl}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel style={{ marginTop: "10px" }} id="Claselabel">
                      País
                    </InputLabel>
                    <Select
                      labelId="Claselabel"
                      value={this.state._idPais}
                      onChange={this.handleChange.bind(this)}
                      name="NombrePais"
                      label="Clase"
                      style={{ width: "21.9em", marginTop: "10px" }}
                      error={this.state.errorPais}
                      success={!this.state.errorPais}
                    >
                      <MenuItem disabled>Seleccione un país</MenuItem>
                      {this.state.ListaPaises.map((Pais, Index) => (
                        <MenuItem
                          key={Index + Pais.NombrePais}
                          value={JSON.stringify(Pais._id)}
                        >
                          {Pais.NombrePais}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    className={classes.formControl}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel
                      style={{ marginTop: "10px" }}
                      id="Descubrirlabel"
                    >
                      Estado
                    </InputLabel>
                    <Select
                      labelId="Descubrirlabel"
                      fullWidth={true}
                      value={this.state._idEstado}
                      onChange={this.handleChange}
                      name="NombreEstado"
                      label="Descubrirlabel"
                      error={this.state.errorEstado}
                      success={!this.state.errorEstado}
                      style={{ width: "22em", marginTop: "10px" }}
                    >
                      <MenuItem disabled>Seleccione un estado</MenuItem>
                      {this.state.ListaEstados.map((Estado, Index) => (
                        <MenuItem
                          key={Index + Estado.NombreEstado}
                          value={JSON.stringify(Estado._id)}
                        >
                          {Estado.NombreEstado}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    className={classes.formControl}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel style={{ marginTop: "10px" }}>
                      Municipio
                    </InputLabel>
                    <Select
                      value={this.state._idMunicipio}
                      onChange={this.handleChange}
                      name="_idMunicipio"
                      style={{ width: "22em", marginTop: "10px" }}
                      labelId="Claselabel"
                      label="Clase"
                      error={this.state.errorMunicipio}
                      success={!this.state.errorMunicipio}
                    >
                      <MenuItem disabled>Seleccione un municipio</MenuItem>
                      {this.state.ListaMunicipios.map((Municipio, Index) => (
                        <MenuItem
                          key={Index + Municipio.NombreMunicipio}
                          value={JSON.stringify(Municipio._id)}
                        >
                          {Municipio.NombreMunicipio}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="Colonia"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Colonia"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Colonia,
                      name: "Colonia",
                      id: "Colonia",
                      error: this.state.errorColonia,
                      success: !this.state.errorColonia,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="CodigoCP"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Código Postal"
                    onChange={this.handleChange}
                    value={this.state.CodigoPostal}
                    name={"CodigoPostal"}
                    error={this.state.errorCodigoPostal}
                    success={!this.state.errorCodigoPostal}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="Calle"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Calle"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Calle,
                      name: "Calle",
                      id: "Calle",
                      error: this.state.errorCalle,
                      success: !this.state.errorCalle,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="NumeroExterior"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Num. Ext"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.NumeroExterior,
                      name: "NumeroExterior",
                      id: "NumeroExterior",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="NumeroInterior"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Num. Int."
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.NumeroInterior,
                      name: "NumeroInterior",
                      id: "NumeroInterior",
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <div>
          <Button
              size="sm"
              type="submit"
              color="info"
              round
              className="pull-right mx-2"
              onClick={() => this.ValidarEmpresa()}
            >
              Guardar
            </Button>
            <Button
              tabIndex="1"
              size="sm"
              className="pull-right mx-2"
              round
              onClick={() => this.props.eventClick("esc")}
            >
              &nbsp;Cancelar
            </Button>
          </div>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(ModalGuardarEmpresa);
