import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import Globales from "utils/Globales";
import { Edit, People, DoneOutline, GetApp, Close, AssignmentReturned, AddCircle, PlusOne, DoubleArrow, ArrowUpward, Clear, DoneAll, AssignmentTurnedIn } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import cookie from 'js-cookie'


const validarModo =()=>{
  let User = JSON.parse(localStorage.getItem("Usuario"))
  let permiso = false
  if(User){
    permiso = User.Modo == "true"
  }
  return permiso
}


class ReportePagos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaIdiomas: [],
      ListaAux: [],
      OpcionReportes: "",
      FechaInicio: moment().format("YYYY-MM-DD"),
      FechaFin: moment().format("YYYY-MM-DD"),
      idIdioma: "TODO",
      Campos: [0,1,3,4,5,21,22,23,24,25,26,29,30,31,32,35,37,38],
      IncluirVentasF: false,
      OpcionPeriodo: "",
      OpcionReportes: "",

      //Checkboxes alumno
      matricula: true,
      nombre: true,
      clase: true,
      grupo: true,
      idioma: true,
      FechaNacimiento: false,
      LugarNacimiento: false,
      DatosFiscales: false,
      EstadoAlumno: false,
      FamiliaresEstudiando: false,
      direccion: false,
      telefono: false,
      celular: false,
      Correo: false,
      Descuentos: false,
      FechaRegistro: false,
      medioInformacion: false,

      //Checkboxes Pago
      Folio: true,
      FolioFactura: true,
      FechaPago: true,
      ConceptoPago: true,
      TipoProducto: true,
      FormaPago: true,
      Cantidad: false,
      Precio: false,
      Monto: true,
      Importe: true,
      Descuento: true,
      importeTotal: true,
      FechaCancelacion: false,
      MotivosCancelacion: false,
      MesPago: true,
      Estatus: true,
      PagoAbono: true,
      Parentesco: false,

      errorFechainicio: false,
      errorFechaFin: false,
      errorPeriodo: false,
      colorNotificacion: 'success',
      colorTableRow: "white",

      //Mensaje del cargando default
      CuerpoCargando: "Cargando"
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    
  }

  componentDidMount() {
    this.ListaGeneralIdiomas()
    if(validarModo()){
      this.setState({
        OpcionReportes:"ReportePagos"
      })
    }
  }

  async ListaGeneralIdiomas() {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Prospectos/Prospectos/ListaGeneralIdiomas', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                if (data.ListaIdiomas.length > 1) {
                    this.setState({ ListaIdiomas: data.ListaIdiomas, Activo: 1, cargando: false });
                }
                else {
                    let ListaAuxEmpresas = [];
                    ListaAuxEmpresas.push(data.ListaIdiomas);
                    //console.log(ListaAux);
                    this.setState({ ListaIdiomas: ListaAuxEmpresas, Activo: 1, cargando: false });
                }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

  async ReporteExcelPagoAlumnos() {
    this.setState({cargando: true});
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

    await fetch(Globales.Url + 'Reportes/Reportes/ReporteExcelPagoAlumnos', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({
          FechaInicio: this.state.FechaInicio, 
          FechaFin: this.state.FechaFin, 
          idIdioma: this.state.idIdioma !== "TODO" ? this.state.idIdioma : idVacio, 
          Campos: this.state.Campos.toString(), 
          IncluirVentasF: this.state.IncluirVentasF,
          Usuario: JSON.parse(cookie.get('id'))
        })
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                this.DescargarPDF(data.ReporteExcel, 0);
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

async ReportePeriodoRentabilidadGlobal() {
  this.setState({cargando: true});

  await fetch(Globales.Url + 'Reportes/Reportes/ReportePeriodoRentabilidadGlobal', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              this.DescargarPDF(data.ReporteExcel, 1);
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

async ReporteGraficoRentabilidadGlobal() {
  //this.setState({cargando: true});

  await fetch(Globales.Url + 'Reportes/Reportes/ReporteGraficoRentabilidadGlobal', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin, periodo: this.state.OpcionPeriodo})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              //this.DescargarPDF(data.ReporteExcel, 2);
              this.setState({ListaAux: data.ReporteExcel});
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

DescargarPDF(archivoBase64, int) {
  let url;
  let nombre;
  let fileName;

  switch(int){
    case 0:
      var ArchivoUTF8 = atob(archivoBase64)
      // console.log(ArchivoUTF8)
      var universalBOM = "\uFEFF";
      url = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+ArchivoUTF8)
      // url = 'data:application/csv;base64,' + archivoBase64;
      nombre = 'Reporte pagos de alumnos'
      fileName = `${nombre}.csv`;
      break;
    case 1:
      url = 'data:application/pdf;base64,' + archivoBase64;
      nombre = 'Reporte rentabilidad global'
      fileName = `${nombre}.pdf`;
      break;
    case 2:
      url = 'data:application/pdf;base64,' + archivoBase64;
      nombre = 'Reporte pagos de alumnos'
      fileName = `${nombre}.pdf`;
      break;
  }

  const linkSource = `${url}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  this.setState({
    cargando: false,
    mensaje: "El archivo se generó exitosamente",
    colorNotificacion: "success",
  });
  this.showNotification();
}
  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, checked } = e.target;

    if(name == "IncluirVentasF"){
      if(checked){
        this.setState({IncluirVentasF: true});
      }
      else{
        this.setState({IncluirVentasF: false});
      }
    }
    else{
      this.setState({ [name]: value });
    }
  }

  handleChangeCheck(e) {
    const { name, value, checked } = e.target;
  
    if(checked){
      //console.log(value)
      this.setState({ [name]: true, Campos: [...this.state.Campos, parseInt(value)]});
    }
    else{
      let index = this.state.Campos.findIndex(elem => elem == value)

      this.state.Campos.splice(index, 1);

      this.setState({ [name]: false, Campos: this.state.Campos});
    }
  }

  validarFechas(int){
    let valido = true;

    if(this.state.FechaInicio !== "" || this.state.FechaFin !== ""){
      if(this.state.FechaFin < this.state.FechaInicio){
        valido = false;
        this.setState({ errorFechainicio: false, errorFechaFin: true, mensaje: "La fecha final no puede ser antes de la fecha de inicio", colorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        valido = true;
        this.setState({errorFechainicio: false, errorFechaFin: false});
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos", colorNotificacion: "danger"});
      this.showNotification();
    }

    if(int == 2){
      if(this.state.OpcionPeriodo == ""){
        valido = false;
        this.setState({errorPeriodo: true, mensaje: "Seleccione el periodo", colorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        valido = true;
        this.setState({errorPeriodo: false});
      }
    }
    else{
      this.setState({errorPeriodo: false});
    }

    if(valido){
      switch(int){
        case 0:
          this.ReporteExcelPagoAlumnos();
          break;
        case 1:
          this.ReportePeriodoRentabilidadGlobal();
          break;
        case 2:
          this.ReporteGraficoRentabilidadGlobal();
          break;
      }
    }
  }


  render() {
    const { 
      matricula, 
      nombre, 
      clase, 
      grupo, 
      idioma, 
      FechaNacimiento, 
      LugarNacimiento, 
      DatosFiscales, 
      EstadoAlumno,
      FamiliaresEstudiando,
      direccion,
      telefono,
      celular,
      Correo,
      Descuentos,
      FechaRegistro,
      medioInformacion,
      Folio,
      FolioFactura,
      FechaPago,
      ConceptoPago,
      TipoProducto,
      FormaPago,
      Cantidad,
      Precio,
      Monto,
      Importe,
      Descuento,
      importeTotal,
      FechaCancelacion,
      MotivosCancelacion,
      MesPago,
      Estatus,
      PagoAbono,
      Campos,
      OpcionReportes,
      ListaAux,
      Parentesco,
     } = this.state;

     //console.log(Campos)

    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    let Titulo;
    let Contenido;
    switch(OpcionReportes){
      case "ReportePagos":
        Titulo = "Pagos de alumnos";
        Contenido = 
          <>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{fontWeight: "bold"}}>Seleccione el periodo y el idioma</div>
                <TextField
                  id="FechaInicio"
                  style={{ width: "15em", marginTop: "10px", marginRight: "10px" }}
                  variant="outlined"
                  type="date"
                  format="DD-MM-YYYY"
                  size="small"
                  label="Fecha inicio"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.FechaInicio,
                    name: "FechaInicio",
                    id: "FechaInicio",
                    error: this.state.errorFechainicio,
                    success: !this.state.errorFechainicio
                  }}
                />

                <TextField
                  id="FechaFin"
                  style={{ width: "15em", marginTop: "10px" }}
                  variant="outlined"
                  type="date"
                  format="DD-MM-YYYY"
                  size="small"
                  label="Fecha fin"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.FechaFin,
                    name: "FechaFin",
                    id: "FechaFin",
                    error: this.state.errorFechaFin,
                    success: !this.state.errorFechaFin
                  }}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl className={classes.formControl} size="small" variant="outlined">
              <InputLabel style={{marginTop: "10px"}} id="idioma">Idioma</InputLabel>
                  <Select
                      labelId="idioma"
                      //fullWidth={true}
                      value={this.state.idIdioma}
                      onChange={this.handleChange.bind(this)}
                      name="idIdioma"
                      label="Idioma"
                      style={{ width: "26.9em", marginTop: "10px" }}
                  >

                    <MenuItem value={"TODO"}>Todos los idiomas</MenuItem>
                    {this.state.ListaIdiomas.map(idioma =>
                      <MenuItem value={idioma._id}>{(idioma.Nombre)}</MenuItem>
                    )}

                  </Select>
              </FormControl>
              <FormControlLabel
                  control={<Checkbox checked={this.state.IncluirVentasF} onChange={this.handleChangeCheck.bind(this)} name="IncluirVentasF" />}
                  label="Incluir todo"
                  style={{marginTop: "10px", marginLeft: "10px"}}
                />
              <Button size="sm" type="submit" color="info" round className="ml-3 mt-2" onClick={() => this.validarFechas(0)}><GetApp/>&nbsp;Exportar a excel</Button>
            </GridItem>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Información del alumno</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={matricula} value={"0"} onChange={this.handleChangeCheck.bind(this)} name="matricula" />}
                            label="Matrícula"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={nombre} value={"1"} onChange={this.handleChangeCheck.bind(this)} name="nombre" />}
                            label="Nombre"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={clase} value={"3"} onChange={this.handleChangeCheck.bind(this)} name="clase" />}
                            label="Clase"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={grupo} value={"4"} onChange={this.handleChangeCheck.bind(this)} name="grupo" />}
                            label="Grupo"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={idioma} value={"5"} onChange={this.handleChangeCheck.bind(this)} name="idioma" />}
                            label="Idioma"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FechaNacimiento} value={"6"} onChange={this.handleChangeCheck.bind(this)} name="FechaNacimiento" />}
                            label="Fecha de nacimiento"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={LugarNacimiento} value={"7"} onChange={this.handleChangeCheck.bind(this)} name="LugarNacimiento" />}
                            label="Lugar de nacimiento"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={DatosFiscales} value={"8"} onChange={this.handleChangeCheck.bind(this)} name="DatosFiscales" />}
                            label="Datos fiscales"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={EstadoAlumno} value={"9"} onChange={this.handleChangeCheck.bind(this)} name="EstadoAlumno" />}
                            label="Estado del alumno"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FamiliaresEstudiando} value={"10"} onChange={this.handleChangeCheck.bind(this)} name="FamiliaresEstudiando" />}
                            label="Familiares estudiando"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={direccion} value={"11"} onChange={this.handleChangeCheck.bind(this)} name="direccion" />}
                            label="Dirección"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={telefono} value={"12"} onChange={this.handleChangeCheck.bind(this)} name="telefono" />}
                            label="Teléfono"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={celular} value={"13"} onChange={this.handleChangeCheck.bind(this)} name="celular" />}
                            label="Celular"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Correo} value={"14"} onChange={this.handleChangeCheck.bind(this)} name="Correo" />}
                            label="Correo electronico"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Descuentos} value={"18"} onChange={this.handleChangeCheck.bind(this)} name="Descuentos" />}
                            label="Descuentos"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FechaRegistro} value={"19"} onChange={this.handleChangeCheck.bind(this)} name="FechaRegistro" />}
                            label="Fecha de registro"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={medioInformacion} value={"20"} onChange={this.handleChangeCheck.bind(this)} name="medioInformacion" />}
                            label="Medio por el cual conoció la escuela"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Parentesco} value={"45"} onChange={this.handleChangeCheck.bind(this)} name="Parentesco" />}
                            label="Parentesco"
                          />
                        </FormGroup>
                      </FormControl>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Información del pago</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={Folio} value={"21"} onChange={this.handleChangeCheck.bind(this)} name="Folio" />}
                            label="Folio"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FolioFactura} value={"22"} onChange={this.handleChangeCheck.bind(this)} name="FolioFactura" />}
                            label="Folio de factura"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FechaPago} value={"23"} onChange={this.handleChangeCheck.bind(this)} name="FechaPago" />}
                            label="Fecha de pago"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={ConceptoPago} value={"24"} onChange={this.handleChangeCheck.bind(this)} name="ConceptoPago" />}
                            label="Concepto de pago"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={TipoProducto} value={"25"} onChange={this.handleChangeCheck.bind(this)} name="TipoProducto" />}
                            label="Tipo de producto"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FormaPago} value={"26"} onChange={this.handleChangeCheck.bind(this)} name="FormaPago" />}
                            label="Forma de pago"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Cantidad} value={"27"} onChange={this.handleChangeCheck.bind(this)} name="Cantidad" />}
                            label="Cantidad"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Precio} value={"28"} onChange={this.handleChangeCheck.bind(this)} name="Precio" />}
                            label="Precio"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Monto} value={"29"} onChange={this.handleChangeCheck.bind(this)} name="Monto" />}
                            label="Monto"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Importe} value={"30"} onChange={this.handleChangeCheck.bind(this)} name="Importe" />}
                            label="Importe"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Descuento} value={"31"} onChange={this.handleChangeCheck.bind(this)} name="Descuento" />}
                            label="Descuento"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={importeTotal} value={"32"} onChange={this.handleChangeCheck.bind(this)} name="importeTotal" />}
                            label="importe total"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FechaCancelacion} value={"33"} onChange={this.handleChangeCheck.bind(this)} name="FechaCancelacion" />}
                            label="Fecha de cancelación"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={MotivosCancelacion} value={"34"} onChange={this.handleChangeCheck.bind(this)} name="MotivosCancelacion" />}
                            label="Motivos de cancelación"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={MesPago} value={"35"} onChange={this.handleChangeCheck.bind(this)} name="MesPago" />}
                            label="Mes de pago"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Estatus} value={"37"} onChange={this.handleChangeCheck.bind(this)} name="Estatus" />}
                            label="Estatus"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={PagoAbono} value={"38"} onChange={this.handleChangeCheck.bind(this)} name="PagoAbono" />}
                            label="Pago/Abono"
                          />
                        </FormGroup>
                      </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </>
        break;
      
      case "RentabilidadGlobal":
        Titulo = "Reporte de rentabilidad global";
        Contenido = 
          <>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{fontWeight: "bold"}}>Seleccione el periodo y el idioma</div>
                <TextField
                  id="FechaInicio"
                  style={{ width: "15em", marginTop: "10px", marginRight: "10px" }}
                  variant="outlined"
                  type="date"
                  format="DD-MM-YYYY"
                  size="small"
                  label="Fecha inicio"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.FechaInicio,
                    name: "FechaInicio",
                    id: "FechaInicio",
                    error: this.state.errorFechainicio,
                    success: !this.state.errorFechainicio
                  }}
                />

                <TextField
                  id="FechaFin"
                  style={{ width: "15em", marginTop: "10px" }}
                  variant="outlined"
                  type="date"
                  format="DD-MM-YYYY"
                  size="small"
                  label="Fecha fin"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.FechaFin,
                    name: "FechaFin",
                    id: "FechaFin",
                    error: this.state.errorFechaFin,
                    success: !this.state.errorFechaFin
                  }}
                />
                <Button size="sm" type="submit" color="info" round className="ml-3 mt-3" onClick={() => this.validarFechas(1)}><GetApp/>&nbsp;Obtener reporte</Button>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={12}>
              <FormControl className={classes.formControl} size="small" variant="outlined">
              <InputLabel style={{marginTop: "10px"}} id="opciones2">Periodo grafica</InputLabel>
                  <Select
                      labelId="opciones2"
                      //fullWidth={true}
                      value={this.state.OpcionPeriodo}
                      onChange={this.handleChange.bind(this)}
                      name="OpcionPeriodo"
                      label="Periodo grafica"
                      style={{ width: "26.9em", marginTop: "10px" }}
                      error={this.state.errorPeriodo}
                      success={!this.state.errorPeriodo}
                  >

                    <MenuItem disabled={true} value={""}>Seleccione periodo</MenuItem>
                    <MenuItem value={"Diario"}>Diario</MenuItem>
                    <MenuItem value={"Semanal"}>Semanal</MenuItem>
                    <MenuItem value={"Mensual"}>Mensual</MenuItem>

                  </Select>
              </FormControl>
              <Button size="sm" type="submit" color="info" round className="ml-3 mt-3" onClick={() => this.validarFechas(2)}><GetApp/>&nbsp;Obtener gráfica</Button>
            </GridItem> */}
          </>
        break;
    }

    //console.log(ListaAux)
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card hidden={validarModo()}>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Reporte de pagos</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="opciones">Tipo de reporte</InputLabel>
                        <Select
                            labelId="opciones"
                            //fullWidth={true}
                            value={this.state.OpcionReportes}
                            onChange={this.handleChange.bind(this)}
                            name="OpcionReportes"
                            label="Tipo de reporte"
                            style={{ width: "26.9em", marginTop: "10px" }}
                        >

                          <MenuItem disabled={true} value={""}>Seleccione un tipo de reporte</MenuItem>
                          <MenuItem value={"ReportePagos"}>Pagos de alumnos</MenuItem>
                          <MenuItem value={"RentabilidadGlobal"}>Rentabilidad global</MenuItem>

                        </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card hidden={this.state.OpcionReportes == ""}>
            <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>{Titulo}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {Contenido}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar grupo"}
            Cuerpo={"¿Está seguro que desea eliminar grupo?"}
            show={this.state.show}
          eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(ReportePagos);