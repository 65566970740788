import React, { useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

// material-ui icons
import Globales from "utils/Globales";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import "react-datepicker/dist/react-datepicker.css";
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';


class SeleccionarEmpresa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idEmpresa: null,
            razonSocial: "adasdasd",
            ListaEmpresas: [],
            errorEmpresa: null
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
    }

    componentDidMount() {
        this.obtenerEmpresas();
    }

    //Función para obtener las empresas
    obtenerEmpresas() {
        fetch(Globales.Url + 'CorteCaja/CorteCaja/ListadoEmpresasActivas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
        }
        ).then(res => res.json())
            .then(data => {
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje });
                } else { //Filtrado exitoso
                    if (data.ListaEmpresa.length > 0) {
                        //console.log(data.ListaEmpresa);
                        this.setState({ ListaEmpresas: data.ListaEmpresa })
                    }
                }
            })
            .catch(err => console.error(err));
    }

    
    EmpresaSeleccionada = event => {
        // console.log("select;:",event.target.value._id)
        this.setState({
            // razonSocial: event.target.value.RazonSocial,
            // idEmpresa: event.target.value._id
            idEmpresa: event.target.value
        });
        
        this.props.eventoClick(event.target.value)
    }

    render() {

        var ListaEmpresas = [];
        if (this.state.ListaEmpresas.length > 0) {
            ListaEmpresas = this.state.ListaEmpresas.map((d) => d.RazonSocial);
        }

        const { classes } = this.props;
        return (
            <div>
                {/* <GridItem xs={12} sm={12} md={6} lg={6}> */}
                    <FormControl
                        fullWidth
                        style={{ width: "18.2vw" }}
                        variant="outlined"
                        size="small"
                        error={this.state.errorEmpresa}>
                        <InputLabel id="ILEmpresa">Empresa</InputLabel>
                        <Select
                            value={this.state.idEmpresa}
                            onChange={this.EmpresaSeleccionada}
                            name={this.state.razonSocial}
                            label={this.state.razonSocial}
                            labelId="ILEmpresa"
                            size="small"
                            id="idEmpresa"                            
                            defaultValue={"Seleccione"}
                            inputProps={{
                                required: true,
                                error: this.state.errorEmpresa,
                                success: !this.state.errorEmpresa
                            }}
                        >
                            <MenuItem disabled>Selecionar Empresa</MenuItem>
                            {this.state.ListaEmpresas.map(emp =>
                                <MenuItem value={emp._id}>{(emp.RazonSocial).toUpperCase()}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                {/* </GridItem> */}
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(SeleccionarEmpresa);