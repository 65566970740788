import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from "moment";


class ModalBuscarAlumnos extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      ListaEmpresas: [],
      ListaTipoGasto: [],
      ListaTipoSubGasto: [],
      ListaProveedor: [],
      IDEmpresa: "",
      TipoGastoSeleccionado: "",
      SubtipoGastoSeleccionado: "",
      ProveedorSeleccionado: "",
      errorTipoGasto: false,
      errorConcepto: false,
      errorTotal: false,
      GastoIndiv: {},
      MostrarModal:false,
      _id: "",
      disabled:false,
      cobranza: [],
      colorNotificacion: "success",
      mensaje: "",
      ocultar: false,
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      CuerpoCargando: "Cargando",
      cargando: false
      
    }
    this.handleChange = this.handleChange.bind(this);
    // this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    // document.addEventListener("keydown", this.escFunction, false);
    this.setState({
      ListaTipoGasto: this.props.ListaTipoGasto, 
      ListaProveedor: this.props.ListaProveedor, 
      GastoIndiv: this.props.Gasto,
      errorTipoGasto: true, 
      errorTotal: true
    })
  }

async TipoSubGastoListado(idGasto, idSubGasto) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Gastos/Gastos/TipoSubGastoListado', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({id: idGasto})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
            if(idSubGasto !== 0){
              if (data.ListaTipoSubGasto.length > 1) {
                data.ListaTipoSubGasto.map(subtipo => {
                  if(JSON.stringify(subtipo._id) == JSON.stringify(idSubGasto)){
                    this.setState({ ListaTipoSubGasto: data.ListaTipoSubGasto, SubtipoGastoSeleccionado: subtipo, Activo: 1, cargando: false });
                  }
                })
              }
              else {
                if(JSON.stringify(data.ListaTipoSubGasto._id) == JSON.stringify(idSubGasto)){
                  let ListaAux = [];
                  ListaAux.push(data.ListaTipoSubGasto);
                  //console.log(ListaAux);
                  this.setState({ ListaTipoSubGasto: ListaAux, SubtipoGastoSeleccionado: data.ListaTipoSubGasto, Activo: 1, cargando: false });
                }
              }
            }
            else{
              if (data.ListaTipoSubGasto.length > 1) {
                  this.setState({ ListaTipoSubGasto: data.ListaTipoSubGasto, Activo: 1, cargando: false });
              }
              else {
                  let ListaAux = [];
                  ListaAux.push(data.ListaTipoSubGasto);
                  //console.log(ListaAux);
                  this.setState({ ListaTipoSubGasto: ListaAux, Activo: 1, cargando: false });
              }
            }
              
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  ValidarCampos(){
    this.setState({errorTipoGasto: false, errorConcepto: false, errorTotal: false})
    this.state.GastoIndiv.Error = false;
    this.state.GastoIndiv.AdvertenciaTotales = false;

    // console.log((this.state.GastoIndiv.gasto).replace(/[\$,]/g, ""))

    if(this.state.GastoIndiv.TipoGastoSeleccionado == undefined){
      this.state.GastoIndiv.Error = true;
      this.setState({errorTipoGasto: true})
    }

    if(this.state.GastoIndiv.concepto == ""){
      this.state.GastoIndiv.Error = true;
      this.setState({errorConcepto: true})
    }

    if(parseFloat(this.state.GastoIndiv.total) !== parseFloat((this.state.GastoIndiv.gasto).replace(/[\$,]/g, ""))){
      // this.state.GastoIndiv.Error = true;
      this.state.GastoIndiv.AdvertenciaTotales = true;
      this.setState({errorTotal: true})
    }
    

    this.props.eventClick(this.state.GastoIndiv, this.props.indexGasto)
  }

  handleChange(e) {
    const { name, value } = e.target;
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    switch(name){
      case "TipoGastoSeleccionado":
        if(JSON.stringify(value) !== ''){
            this.TipoSubGastoListado(value._id, 0);
            this.state.GastoIndiv[name] = value
            this.setState({ GastoIndiv: this.state.GastoIndiv });
        }
        else{
            this.state.GastoIndiv[name] = value
            this.setState({ GastoIndiv: this.state.GastoIndiv, ListaTipoSubGasto: [], SubtipoGastoSeleccionado: idVacio });
        }
        break;
        case "DescripcionProd":
          this.state.GastoIndiv.concepto = value
          this.setState({GastoIndiv: this.state.GastoIndiv});
          break;
        case "Subtotal":
          let cantidad = value.replace(/[^0-9\.]/g, '');
          if(cantidad < 0 || cantidad == ""){
            this.state.GastoIndiv.subtotal = 0
            this.state.GastoIndiv.total = 0
          }
          else{
            this.state.GastoIndiv.subtotal = cantidad;
            this.state.GastoIndiv.total = cantidad;
          }
          // this.state.GastoIndiv.subtotal = value
          this.setState({GastoIndiv: this.state.GastoIndiv});
          break;
      default:
        this.state.GastoIndiv[name] = value
        this.setState({GastoIndiv: this.state.GastoIndiv});
        break;
    }

    this.ValidarCampos();
  }

  handleFocus = (event) => {
    const e = event.target;
    e.select();
  }
 
  render() {
    const { classes } = this.props;
    // console.log(this.state.GastoIndiv)

    let agregar;
    if(this.state.cargando == true){
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    // console.log(moment(this.state.GastoIndiv.fecha, "DD/MM/YYYY").format("DD/MM/YYYY"))
    return (
      <tr style={{cursor: "default"}}>
              <td><div style={{marginTop: "15px"}}>{moment(this.state.GastoIndiv.fecha, "DD/MM/YYYY").format("DD/MM/YYYY")}</div></td>
              <td>
                <TextField
                    id="DescripcionProd"
                    style={{ width: "25em", marginTop: "10px"}}
                    variant="outlined"
                    type="text"
                    size="small"
                    helperText={this.state.errorConcepto ? "Proporcione una descripción" : ""}
                    label="Descripción"
                    // onChange={(e) => this.handleChangeEdit(e, gasto)}
                    error={this.state.errorConcepto}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.GastoIndiv.concepto,
                      name: "DescripcionProd",
                      id: "DescripcionProd",
                      // error: this.state.errorConcepto,
                      // success: !this.state.errorConcepto
                    }}
                />
              </td>
              <td>
              <FormControl className={classes.formControl} size="small" variant="outlined" error={this.state.errorTipoGasto}>
                <InputLabel style={{marginTop: "10px"}} id="tipolabel">Tipo de gasto</InputLabel>
                    <Select
                        labelId="tipolabel"
                        //fullWidth={true}
                        value={this.state.GastoIndiv.TipoGastoSeleccionado}
                        onChange={this.handleChange.bind(this)}
                        name="TipoGastoSeleccionado"
                        //fullWidth={true}
                        label="Tipo de gasto"
                        style={{ width: "22em", marginTop: "10px" }}
                        // error={this.state.errorTipoGasto}
                        // success={!this.state.errorTipoGasto}
                    >
                      <MenuItem disabled>Seleccione un tipo de gasto</MenuItem>
                        {this.state.ListaTipoGasto.map(tipo =>
                        <MenuItem value={tipo}>{(tipo.Nombre).toUpperCase()}</MenuItem>
                        )}
                    </Select>
                    {this.state.errorTipoGasto ? <FormHelperText>Seleccione un tipo de gasto</FormHelperText> : null}
                </FormControl>
              </td>
              <td>
              <FormControl className={classes.formControl} size="small" variant="outlined">
                <InputLabel style={{marginTop: "10px"}} id="tipolabel">Subtipo de gasto</InputLabel>
                    <Select
                        labelId="tipolabel"
                        //fullWidth={true}
                        value={this.state.GastoIndiv.SubtipoGastoSeleccionado}
                        onChange={this.handleChange.bind(this)}
                        name="SubtipoGastoSeleccionado"
                        //fullWidth={true}
                        label="Subtipo de gasto"
                        style={{ width: "22em", marginTop: "10px" }}
                        //error={this.state.errorIDIdioma}
                        //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione un subtipo de gasto</MenuItem>
                        {this.state.ListaTipoSubGasto.map(subtipo =>
                        <MenuItem value={subtipo}>{(subtipo.Nombre).toUpperCase()}</MenuItem>
                        )}
                    </Select>
                </FormControl>
              </td>
              <td>
              <FormControl className={classes.formControl} size="small" variant="outlined">
                <InputLabel style={{marginTop: "10px"}} id="proveedorlabel">Proveedor</InputLabel>
                    <Select
                        labelId="proveedorlabel"
                        //fullWidth={true}
                        value={this.state.GastoIndiv.ProveedorSeleccionado}
                        onChange={this.handleChange.bind(this)}
                        name="ProveedorSeleccionado"
                        //fullWidth={true}
                        label="Proveedor"
                        style={{ width: "22em", marginTop: "10px" }}
                        //error={this.state.errorIDIdioma}
                        //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione un proveedor</MenuItem>
                        {this.state.ListaProveedor.map(proveedor =>
                        <MenuItem value={proveedor}>{(proveedor.Razonsocial).toUpperCase()}</MenuItem>
                        )}
                    </Select>
                </FormControl>
              </td>
              <td>
              <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="pagolabel">Tipo de pago</InputLabel>
                    <Select
                        labelId="pagolabel"
                        //fullWidth={true}
                        value={this.state.GastoIndiv.TipoPago}
                        onChange={this.handleChange.bind(this)}
                        name="TipoPago"
                        defaultValue={"Efectivo"}
                        //fullWidth={true}
                        label="Tipo de pago"
                        style={{ width: "22em", marginTop: "10px" }}
                        //error={this.state.errorIDIdioma}
                        //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione tipo de pago</MenuItem>
                        <MenuItem value={"Efectivo"}>Efectivo</MenuItem>
                        <MenuItem value={"Tarjeta_de_Crédito"}>Tarjeta de Crédito</MenuItem>
                        <MenuItem value={"Tarjeta_de_Débito"}>Tarjeta de Débito</MenuItem>
                        <MenuItem value={"Cheque"}>Cheque</MenuItem>
                        <MenuItem value={"Depósito"}>Depósito</MenuItem>
                        <MenuItem value={"Transferencia"}>Transferencia</MenuItem>
                    </Select>
                  </FormControl>
              </td>
              <td>
              <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="recibolabel">Tipo recibo</InputLabel>
                    <Select
                        labelId="recibolabel"
                        //fullWidth={true}
                        value={this.state.GastoIndiv.TipoRecibo}
                        onChange={this.handleChange.bind(this)}
                        name="TipoRecibo"
                        defaultValue={"Recibo"}
                        //fullWidth={true}
                        label="Comprobante"
                        style={{ width: "22em", marginTop: "10px" }}
                        //error={this.state.errorIDIdioma}
                        //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione un tipo de recibo</MenuItem>
                    <MenuItem value={"Factura"}>Factura</MenuItem>
                    <MenuItem value={"Recibo"}>Recibo</MenuItem>
                    </Select>
                  </FormControl>
              </td>
              <td>
                <TextField
                  id="Subtotal"
                  style={{ width: "7em", marginTop: "10px"}}
                  variant="outlined"
                  type="number"
                  size="small"
                  label="Subtotal"
                  // helperText={this.state.errorTotal ? "Los totales no coinciden" : ""}
                  // error={this.state.errorTotal}
                  // onChange={this.handleChange.bind(this)}
                  InputLabelProps={{ shrink: true }} 
                  InputProps={{
                      onChange: this.handleChange.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.GastoIndiv.subtotal,
                      name: "Subtotal",
                      id: "Subtotal",
                      // error: this.state.errorTotal,
                      //success: !this.state.errorDescripcionProducto
                  }}
                />
              </td>
              <td>
                <TextField
                  id="Total"
                  style={{ width: "7em", marginTop: "10px"}}
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Total"
                  disabled
                  helperText={this.state.errorTotal ? "Los totales no coinciden" : ""}
                  error={this.state.errorTotal}
                  //onChange={(e) => this.handleChangeEdit(e, gasto)}
                  InputProps={{
                      //onChange: this.handleChange.bind(this),
                      readOnly: true,
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.GastoIndiv.total,
                      name: "Total",
                      id: "Total",
                      // error: this.state.errorTotal,
                      //success: !this.state.errorDescripcionProducto
                  }}
                />
              </td>
              <td>
                <TextField
                  id="TotalBanco"
                  style={{ width: "7em", marginTop: "10px"}}
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Total banco"
                  //onChange={(e) => this.handleChangeEdit(e, gasto)}
                  // helperText={this.state.errorTotal ? "Los totales no coinciden" : ""}
                  error={this.state.errorTotal}
                  disabled
                  InputProps={{
                      //onChange: this.handleChange.bind(this),
                      readOnly: true,
                      onFocus: this.handleFocus.bind(this),
                      value: (this.state.GastoIndiv.gasto)/* .replace(/[\$,]/g, "") */,
                      name: "TotalBanco",
                      id: "TotalBanco",
                      // error: this.state.errorTotal,
                      //success: !this.state.errorDescripcionProducto
                  }}
                />
              </td>
              {agregar}
            </tr>
    );
  }
}

export default withStyles(basicsStyle)(ModalBuscarAlumnos);