import React from "react";
// core components
import { Form, Modal } from "reactstrap";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Email, Clear, Save, Add } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import Estilo from "./EstiloCorreos.css"
import ModalBuscarCorreosAlumnos from "./ModalBuscarCorreosAlumnos"

import RichTextEditor, { getTextAlignClassName, getTextAlignStyles,  getTextAlignBlockMetadata, } from 'react-rte';
// var RichTextEditor = require('react-rte');

const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_ALIGNMENT_BUTTONS",
    // "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Inline Title", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    { label: "Align Left", style: "ALIGN_LEFT" },
    { label: "Align Center", style: "ALIGN_CENTER" },
    { label: "Align Right", style: "ALIGN_RIGHT" },
    { label: "Align Justify", style: "ALIGN_JUSTIFY" },
  ],
};
class CatUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      // Plantilla: RichTextEditor.createValueFromString("<p><b>jutta</b> tesche</p>","html"),
      Plantilla: RichTextEditor.createEmptyValue(),
      PlantillaHTML: "",
      AlumnoSeleccionado: {},
      NombresAlumnos: [],
      CorreoAlumnos: [],
      AsuntoCorreo: "",
      Configuracion: {},
      ColorBordePaper: "1px solid rgba(0, 0, 0, 0.25)",
      ColorBordePaper2: "0px solid rgba(0, 0, 0, 0.25)",
      Disabled: false,
      mensaje: "",
      errorAsuntoCorreo: false,
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      CuerpoCargando: "Cargando",
      notificacion: false,
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    if(this.props.op == 1){
      this.ObtenerInformacionAlumno(this.props.AlumnoSeleccionado._id)
    }
    this.ObtenerConfiguracion();
  }

  async ObtenerInformacionAlumno(id) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerInformacionAlumno', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({_id: id})
    }
    ).then(res => res.json())
        .then(data => {
          //console.log("API", data.DetalleAlumno)
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              let correo = [];
              
              if(data.DetalleAlumno.ContactosAlumno !== null && data.DetalleAlumno.ContactosAlumno !== undefined){
                if(data.DetalleAlumno.ContactosAlumno.ContactoAlumno.length > 1){
                  data.DetalleAlumno.ContactosAlumno.ContactoAlumno.map(contacto => {
                    if(contacto.EnumTipoContactoAlumno == "Alumno" && (contacto.Correo !== undefined && contacto.Correo !== null)){
                      correo.push(contacto.Correo)
                    }
                    else if(contacto.EnumTipoContactoAlumno == "Mamá" && (contacto.Correo !== undefined && contacto.Correo !== null)){
                      correo.push(contacto.Correo)
                    }
                    else if(contacto.EnumTipoContactoAlumno == "Papá" && (contacto.Correo !== undefined && contacto.Correo !== null)){
                      correo.push(contacto.Correo)
                    }
                    else if(contacto.EnumTipoContactoAlumno == "Otro" && (contacto.Correo !== undefined && contacto.Correo !== null)){
                      correo.push(contacto.Correo)
                    }
                  })

                  if(correo.length !== 0){
                    this.setState({NombresAlumnos: [...this.state.NombresAlumnos, data.DetalleAlumno.Nombre], CorreoAlumnos: correo})
                  }
                  else{
                    this.setState({mensaje: "El alumno no tiene correos registrados", colorNotificacion: "danger"});
                    this.showNotification();
                  }
                }
                else{
                  if(data.DetalleAlumno.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Alumno" && (data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo !== undefined && data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo !== null)){
                    correo.push(data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo)
                  }
                  else if (data.DetalleAlumno.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Mamá" && (data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo !== undefined && data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo !== null)){
                    correo.push(data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo)
                  }
                  else if (data.DetalleAlumno.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Papá" && (data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo !== undefined && data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo !== null)){
                    correo.push(data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo)
                  }
                  else if (data.DetalleAlumno.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Otro" && (data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo !== undefined && data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo !== null)){
                    correo.push(data.DetalleAlumno.ContactosAlumno.ContactoAlumno.Correo)
                  }

                  if(correo.length !== 0){
                    this.setState({NombresAlumnos: [...this.state.NombresAlumnos, data.DetalleAlumno.Nombre], CorreoAlumnos: correo})
                  }
                  else{
                    this.setState({mensaje: "El alumno no tiene correos registrados", colorNotificacion: "danger"});
                    this.showNotification();
                  }
                }
              }
              else{
                this.setState({mensaje: "El alumno no tiene correos registrados", colorNotificacion: "danger"});
                this.showNotification();
              }
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  async ObtenerConfiguracion() {
    this.setState({ cargando: true});
      await fetch(Globales.Url + "Configuracion/Configuracion/ObtenerConfiguracion", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({}),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje,colorNotificacion: "danger",cargando: false});
            this.showNotification();
          } else {
            let ssl;
            let config = {};

            if(data.Configuracion.SSL == "false"){
              ssl = false;
            }
            else if(data.Configuracion.SSL == "true"){
              ssl = true;
            }

            config = {
              Contraseña: data.Configuracion.Contrasena !== null ? data.Configuracion.Contrasena : "",
              Correo: data.Configuracion.Correo !== null ? data.Configuracion.Correo : "",
              CorreoConfirmacion: data.Configuracion.CorreoConfirmacion !== null ? data.Configuracion.CorreoConfirmacion : "",
              Nombreservidor: data.Configuracion.Servidor !== null ? data.Configuracion.Servidor : "",
              Puerto: data.Configuracion.Puerto == "0" ? "" : parseInt(data.Configuracion.Puerto),
              SSL: ssl
            }

            this.setState({Configuracion: config, cargando: false});
          }
        });
  }

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, });
  };

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  Validaciones(){
    var valido = true
    if(this.state.PlantillaHTML == "" || this.state.PlantillaHTML == "<p><br/></p>"){
      valido = false
      this.setState({ColorBordePaper2: "1px solid red", mensaje: "Porporcione el mensaje que desee enviar", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      this.setState({ColorBordePaper2: "0px solid rgba(0, 0, 0, 0.25)"});
    }

    if(this.state.AsuntoCorreo == ""){
      valido = false;
      this.setState({errorAsuntoCorreo: true, mensaje: "Proporcione un asunto para el correo", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      this.setState({errorAsuntoCorreo: false})
    }

    if(this.state.CorreoAlumnos.length == 0){
      valido = false;
      this.setState({ColorBordePaper: "1px solid red", mensaje: "Seleccione un alumno", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      this.setState({ColorBordePaper: "1px solid rgba(0, 0, 0, 0.25)"});
    }

    return valido
  }

  async EnviarCorreo() {
    if(this.Validaciones()){
      // console.log("Entro a envio")
      this.setState({ cargando: true});
      await fetch(Globales.Url + "EnvioCorreos/EnvioCorreos/EnviarCorreo", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({
          MensajeHTML: this.state.PlantillaHTML, 
          Asunto: this.state.AsuntoCorreo, 
          Configuracion: this.state.Configuracion, 
          Correo: this.state.CorreoAlumnos, 
          EsPrueba: false
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje,colorNotificacion: "danger",cargando: false});
            this.showNotification();
          } else {
            this.setState({cargando: false});
            this.props.eventClick(data.mensaje);
          }
        });
    }
  }

  onChangeEditorText = (value) => {
    let HTML = value.toString('html',{blockStyleFn: getTextAlignStyles})
    
    this.setState({
      Plantilla: value,
      PlantillaHTML: HTML.replace(/<br>/g, '<br/>'),
    });
  };

  handleDelete = (e, item) => {
    const { name, value } = e.target;

    this.state.NombresAlumnos.splice(item, 1);
    this.state.CorreoAlumnos.splice(item, 1);

    this.setState({NombresAlumnos: this.state.NombresAlumnos, CorreoAlumnos: this.state.CorreoAlumnos})
  }

  openModal(){
    this.setState({show: true})
  }

  eventoBuscarCorreos = (valor, NombreAlumnos, Correos) => {
    this.setState({show: false});
    //console.log("eventoNombreAlumnos: ",NombreAlumnos)
    //console.log("eventoCorreos: ",Correos)
    if(valor){
      let auxConcatNombreAlumnos = this.state.NombresAlumnos.concat(NombreAlumnos);
      auxConcatNombreAlumnos = [...new Set([...this.state.NombresAlumnos,...NombreAlumnos])];

      let auxConcatCorreoAlumnos = this.state.CorreoAlumnos.concat(Correos);
      auxConcatCorreoAlumnos = [...new Set([...this.state.CorreoAlumnos,...Correos])];
      
      this.setState({NombresAlumnos: auxConcatNombreAlumnos, CorreoAlumnos: auxConcatCorreoAlumnos})
    }
  }

  cerrarModal = () => {
    this.props.eventClick(false)
  }

  render() {
    //const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={this.state.CuerpoCargando} show={this.state.cargando} />
      );
    }

    if(this.state.show == true){
      agregar = (
        <ModalBuscarCorreosAlumnos
          showAgregarCorreos={this.state.show}
          eventClick={this.eventoBuscarCorreos}
          //ListaCorreos={this.state.CorreoAlumnos}
          //NombresAlumnos={this.state.NombresAlumnos}
        />
      )
    }

    let stylepaper = {
      display: 'flex', 
      justifyContent: 'space-between', 
      flexWrap: 'nowrap', 
      listStyle: 'none', 
      padding: 0,
      margin: 0,
      border: this.state.ColorBordePaper
    };

    let stylepaper2 = { 
      padding: 0,
      margin: 0,
      border: this.state.ColorBordePaper2
    };

    let styleUL = {
      display: 'flex', 
      justifyContent: 'flex-start', 
      flexWrap: 'wrap', 
      listStyle: 'none', 
      padding: 0,
      margin: 0
    };

    let stylelabel = {
      color: "rgba(0, 0, 0, 0.54)",
      //padding: 10, 
      marginLeft: 10,
      marginTop: 14,
      // marginBottom: 14,
      fontSize: "1rem", 
      fontFamily: "Roboto, Helvetica, Arial, sans-serif", 
      fontWeight: 400, 
      lineHeight: 1, 
      letterSpacing: "0.00938em"
    }


    //console.log(this.state.Configuracion)
    // console.log(this.state.PlantillaHTML)
    //console.log("NombresAlumnos",this.state.NombresAlumnos)
    // console.log("CorreoAlumnos",this.state.CorreoAlumnos)

    return (
      <div>
        <Modal
          isOpen={this.props.showModalCorreo}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
          style={{ maxWidth: "1580px", width: "100%" }}
        >
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Envío de información</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <Paper variant="outlined" elevation={5} component="div" style={stylepaper}>
                              <ul style={styleUL}>
                                <label style={stylelabel}>Para:</label>
                                {this.state.NombresAlumnos.map((data, index) => {
                                  return (
                                    <li key={index}>
                                      <Chip
                                        variant="outlined"
                                        label={data}
                                        onDelete={(e) => this.handleDelete(e, index)}
                                        style={{marginLeft: 6, marginTop: 6, marginBottom: 6}}
                                      />
                                    </li>
                                  );
                                })}
                              </ul>
                              <Button className="mr-2 mt-2" data-toggle="tooltip" data-placement="bottom" title="Agregar alumnos" size="sm" type="submit" color="info" round justIcon onClick={() => this.openModal()}><Add/></Button>
                            </Paper>
                          <TextField
                            id="AsuntoCorreo"
                            style={{ display: 'flex', flexWrap: 'wrap', marginTop: "10px"}}
                            variant="outlined"
                            type="text"
                            size="small"
                            label="Asunto"
                            InputProps={{
                                onChange: this.handleChange.bind(this),
                                value: this.state.AsuntoCorreo,
                                name: "AsuntoCorreo",
                                id: "AsuntoCorreo",
                                error: this.state.errorAsuntoCorreo,
                                success: !this.state.errorAsuntoCorreo
                            }}
                          />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                    <Paper variant="outlined" elevation={5} component="div" style={stylepaper2}>
                      <RichTextEditor
                        // autoFocus={true}
                        blockStyleFn={getTextAlignClassName}
                        toolbarConfig={toolbarConfig}
                        value={this.state.Plantilla}
                        onChange={this.onChangeEditorText}
                        //editorClassName={Estilo.TextBoxCorreo}
                      />
                    </Paper>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Button color="info" round size="sm" className='pull-right mr-2 mb-3' onClick={() =>this.EnviarCorreo()}><Email />&nbsp;Enviar</Button>
            <Button tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick(false)}><Clear/>&nbsp;Cerrar</Button>
          </GridItem>
        </GridContainer>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatUsuarios);
