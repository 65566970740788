import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
// import { View, Image, StyleSheet } from 'react-native'
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Print, Delete, Refresh, Add, Close, PlusOne } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import ModalPregunta from "components/Generales/ModalPregunta";
import SeleccionarIdioma from "../../../views/Generales/ComponentesEmpresas/SeleccionarIdioma.jsx"
import SeleccionarAula from "../../../views/Generales/ComponentesEmpresas/SeleccionarAula.jsx"
import ModalBuscarMaestros from "components/Generales/ModalBuscarMaestros";
import ModalCargando from "../../../components/Generales/ModalCargando"
//import SeleccionarMaestro from "../../Generales/ComponentesEmpresas/SeleccionarMaestro.jsx"
import Autocomplete from '@material-ui/lab/Autocomplete';
import ModalMotivosCancelacion from './ModalMotivosCancelacion.jsx'
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SeleccionarHorario from "../../Generales/ComponentesEmpresas/SeleccionarHorario.jsx";

// var moment = require('moment')
// let dateNow = moment().format('YYYY-MM-DDTHH:MM:SS')

const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

// const ClassGrupo = {
//   ActaCerradaMaestro: false,
//   Activo: true,
//   CapacidadAula: 1,
//   Clase: "",
//   Clave: "",
//   Concluido: false,
//   FechaIncioCurso: new Date(),
//   Horario:{
//     DiaClases: 'Lunes_a_Jueves',
//     Formatofin: 'am',
//     Formatoinicio: 'am',
//     HoraFin: '0',
//     HoraInicio: '0'
//   },
//   HorarioGrupo: [],
//   IdGrupoAnterior: { _increment: '0', _machine: '0', _pid: '0', _timestamp: '0' },
//   IdGrupoSiguiente: { _increment: '0', _machine: '0', _pid: '0', _timestamp: '0' },
//   IdLibro: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   IdUnidadActual: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   Modalidad:"",
//   NivelesAnteriores: { ObjectId: [] },
//   Nombre: "",
//   _id: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   _idAula: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   _idIdioma: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   _idMaestro: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   _idNivel: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
// }

// const classHorario = {
//   FormatoFin: 'am',
//   FormatoInicio: 'am',
//   HoraFin: "",
//   HoraInicio: "",
//   Jueves: 'false',
//   Lunes: 'false',
//   Martes: 'false',
//   Miercoles: 'false',
//   Sabado: 'false',
//   Viernes: 'false'
// }
// const classGrupoAlumno = {
//   Calificacion: 0,
//   Estado:"En_Curso",
//   FechaAsignacion: new Date(),
//   FechaTruncado: new Date(),
//   NombreGrupo: "",
//   _idGrupo: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   idIdioma: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
// }

class ModalInfoVenta extends Component {

  constructor(props, context) {
    super(props, context);
    // this.escFunction = this.escFunction.bind(this);
    this.state = {
      Metodo: "RegistrarAsesoria",
      idAlumno:[],

      //Clases
      ClassGrupo: {
        ActaCerradaMaestro: false,
        Activo: true,
        CapacidadAula: 1,
        Clase: "",
        Clave: "",
        Concluido: false,
        Curso: "",
        FechaIncioCurso: new Date(),
        Horario:{
          DiaClases: 'Lunes_a_Jueves',
          Formatofin: 'am',
          Formatoinicio: 'am',
          HoraFin: '0',
          HoraInicio: '0'
        },
        HorarioGrupo: [],
        IdGrupoAnterior: { _increment: '0', _machine: '0', _pid: '0', _timestamp: '0' },
        IdGrupoSiguiente: { _increment: '0', _machine: '0', _pid: '0', _timestamp: '0' },
        IdLibro: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
        IdUnidadActual: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
        Modalidad:"",
        NivelesAnteriores: { ObjectId: [] },
        Nombre: "",
        TotalHorasSemana: 0,
        _id: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
        _idAula: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
        _idIdioma: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
        _idMaestro: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
        _idNivel: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
      },
      classHorario: {
        FormatoFin: 'am',
        FormatoInicio: 'am',
        HoraFin: "",
        HoraInicio: "",
        Jueves: 'false',
        Lunes: 'false',
        Martes: 'false',
        Miercoles: 'false',
        Sabado: 'false',
        Viernes: 'false'
      },
      classGrupoAlumno: {
        Calificacion: 0,
        Estado:"En_Curso",
        FechaAsignacion: new Date(),
        FechaTruncado: new Date(),
        NombreGrupo: "",
        _idGrupo: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
        idIdioma: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
      },

      _id: null,
      Capcidad:0,
      Fecha: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      Aula: "null",
      Idioma: "null",
      Maestro: {},
      MaestroAnterior: "",
      Nivel: "null",
      Clase: "null",
      DiasClase: "null",
      Libro: "null",
      Clave:"",
      Unidad: "null",
      NombreMaestro:"",
      Modalidad: "null",
      NivelesAnteriores: [],
      TipoCurso: "null",
      //horario
      HoraFinPM:"am",
      HoraFin: "",
      HoraInicioPM:"am",
      HoraInicio: "",
      DiasClase: "null",
      TotalHorasDia: 0,
      TotalHorasSemana: 0,

      //banderas
      disabledFecha:false,
      showAlumnos: false,
      showMaestros: false,
      cargando: false,
      // notificación
      br: false,
      mensaje: "",
      ColorNotificacion: "",

      ListaIdiomas: [],
      ListaAulas: [],
      ListaNiveles: [],
      ListaLibros: [],
      ListaUnidades: [],
      ListaAlumnos: [],
      ListaMaestros: [],

      //diasClase
      Lunes: false,
      Martes: false,
      Miercoles: false,
      Jueves: false,
      Viernes: false,
      Sabado: false,

      CuerpoCargando: "Cargando",

    }

    this.EventoSeleccionarAula = this.EventoSeleccionarAula.bind(this);
    this.EventoSeleccionarIdioma = this.EventoSeleccionarIdioma.bind(this);
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.cerrarModales = this.cerrarModales.bind(this);

  }

  // escFunction(event) {
  //   if (event.keyCode === 27) {
  //     this.props.eventClick("esc", "esc");
  //   }
  // }

  componentDidMount() {
    this.setState({
      ListaAlumnos: [],
      ListaMaestros: [],
    })
    if (this.props.op == 1) {
      // console.log("PROPS___", this.props.Grupo)
      var horaInicio = "";
      var HoraFin = "";
      var HoraInicioPM = "";
      var HoraFinPM = "";
      let horasDia = 0;
      let diasSemanaCount = 0;

      if(this.props.Grupo.HorarioGrupo.Lunes == "true"){
        diasSemanaCount += 1
      }
      if(this.props.Grupo.HorarioGrupo.Martes == "true"){
        diasSemanaCount += 1
      }
      if(this.props.Grupo.HorarioGrupo.Miercoles == "true"){
        diasSemanaCount += 1
      }
      if(this.props.Grupo.HorarioGrupo.Jueves == "true"){
        diasSemanaCount += 1
      }
      if(this.props.Grupo.HorarioGrupo.Viernes == "true"){
        diasSemanaCount += 1
      }
      if(this.props.Grupo.HorarioGrupo.Sabado == "true"){
        diasSemanaCount += 1
      }

      if(this.props.Grupo.HorarioGrupo.HoraInicio !== null && this.props.Grupo.HorarioGrupo.HoraInicio !== undefined){
        horaInicio = this.props.Grupo.HorarioGrupo.HoraInicio
        // console.log("horaInicio", horaInicio)
      }

      if(this.props.Grupo.HorarioGrupo.HoraFin !== null && this.props.Grupo.HorarioGrupo.HoraFin !== undefined){
        HoraFin = this.props.Grupo.HorarioGrupo.HoraFin
        //console.log("HoraFin", HoraFin)
      }

      if(this.props.Grupo.HorarioGrupo.FormatoInicio !== null && this.props.Grupo.HorarioGrupo.FormatoInicio !== undefined){
        HoraInicioPM = this.props.Grupo.HorarioGrupo.FormatoInicio
      }

      if(this.props.Grupo.HorarioGrupo.FormatoFin !== null && this.props.Grupo.HorarioGrupo.FormatoFin !== undefined){
        HoraFinPM = this.props.Grupo.HorarioGrupo.FormatoFin
      }

      if(this.props.Grupo.TotalHorasSemana !== undefined && this.props.Grupo.TotalHorasSemana !== null){
        if(parseFloat(this.props.Grupo.TotalHorasSemana) !== 0){
          horasDia = parseFloat(this.props.Grupo.TotalHorasSemana) / diasSemanaCount
        }
        else{
          horasDia = this.FormatearHora(horaInicio, HoraFin);
        }
      }
      else{
        horasDia = this.FormatearHora(horaInicio, HoraFin);
      }

      // var Fecha = new Date(this.props.Grupo.FechaIncioCurso)
      // var fechaCurso = new Date(Fecha).setDate( new Date(Fecha).getDate() + 1 )
      // if(fechaCurso < new Date()){
      // this.setState({
      //   disabledFecha:true
      //   })
      // }

      // console.log(horasDia)
      
      this.setState({
        _id: this.props.Grupo._id,
        Fecha: moment(this.props.Grupo.FechaIncioCurso).format("YYYY-MM-DD"),
        Clase: this.props.Grupo.Clase,
        TipoCurso: this.props.Grupo.Curso !== undefined && this.props.Grupo.Curso !== null ? this.props.Grupo.Curso : "null",
        Clave: this.props.Grupo.Clave,
        Modalidad: this.props.Grupo.Modalidad,
        Idioma: JSON.stringify(this.props.Grupo._idIdioma) !== JSON.stringify(idVacio) ? this.props.Grupo._idIdioma : "null",
        MaestroAnterior: this.props.Grupo._idMaestro,
        NivelesAnteriores: this.props.Grupo.NivelesAnteriores !== null && this.props.Grupo.NivelesAnteriores !== undefined ? this.props.Grupo.NivelesAnteriores.ObjectId : [],
        // Capcidad: parseFloat(this.props.Grupo.CapacidadAula),
        HoraInicio: horaInicio,
        HoraFin: HoraFin,
        HoraInicioPM: HoraInicioPM,
        HoraFinPM: HoraFinPM,
        Lunes: this.props.Grupo.HorarioGrupo.Lunes == "false" ? false:true,
        Martes: this.props.Grupo.HorarioGrupo.Martes == "false" ? false:true,
        Miercoles: this.props.Grupo.HorarioGrupo.Miercoles == "false" ? false:true,
        Jueves: this.props.Grupo.HorarioGrupo.Jueves == "false" ? false:true,
        Viernes: this.props.Grupo.HorarioGrupo.Viernes == "false" ? false:true,
        Sabado: this.props.Grupo.HorarioGrupo.Sabado == "false" ? false:true,
        TotalHorasDia: horasDia,
        TotalHorasSemana: horasDia * diasSemanaCount
      }, () => {
        if(JSON.stringify(this.props.Grupo._idMaestro) !== JSON.stringify(idVacio)){
          this.ObtenerInformacionMaestro()
        }
        this.ListarIdiomas()
        this.ListarNiveles()
        this.ListarLibros()
        this.ListarAulas()
        this.ObtenerInformacionGrupo()
      })
      // ListaAulas.push({_id: aulas._id, Nombre: aulas.Nombre})

    } else {
      this.setState({
        MaestroAnterior: idVacio
      })
      this.ListarIdiomas()
      this.ListarAulas()

    }
  }
  FormatearHora(horainicio, horafin){

    var hhi = horainicio.slice(0,2)
    var mmi = horainicio.slice(3,6)

    var hhf = horafin.slice(0,2)
    var mmf = horafin.slice(3,6)

    let horasaux = 0;

    horasaux = ((Math.abs(parseFloat(hhi) - parseFloat(hhf)) * 60) + (parseFloat(mmi) + parseFloat(mmf))) / 60


    
    //console.log(hh)
    return horasaux
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------

  ObtenerInformacionMaestro() {
    fetch(Globales.Url + 'Colaboradores/Colaboradores/ObtenerInformacionMaestro', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      ,body: JSON.stringify({
        idMaestro:this.props.Grupo._idMaestro
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data)
        if (data.error) { //Error en la respuesta
          //console.log('maestro fallido', );
          this.setState({ ColorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Filtrado exitoso
          let aux = [];
          aux.push(data.DetalleMestro);
          
          this.setState({ 
            ListaMaestros: aux,
            Maestro: data.DetalleMestro,
            NombreMaestro: data.DetalleMestro.NombreCompleto
          })

          // this.ListaMaestrosBusquedaFiltrada("Juan")

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  
  ListaMaestrosBusquedaFiltrada(texto) {
    fetch(Globales.Url + 'Alumnos/Alumnos/ListaMaestrosBusquedaFiltrada', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Nombre: (texto).trim() })
    }
    ).then(res => res.json())
      .then(data => {

        //console.log('maestro exitoso', data);

        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje });
        } else { //Filtrado exitoso
          if (data.ListaMaestros.length > 0) {

            this.setState({ ListaMaestros: data.ListaMaestros }, () => {
              if ( JSON.stringify(data.ListaMaestros[0]) == JSON.stringify(this.props.Grupo._idMaestro) ) {
                this.setState({ 
                  Maestro: data.ListaMaestros[0],
                  NombreMaestro: data.ListaMaestros[0].NombreCompleto,
                 })
              }
            })
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  ObtenerInformacionGrupo() {
    this.setState({
      ListaAlumnos: [],
    })
    fetch(Globales.Url + 'Grupos/Grupos/ListaAlumnosGrupoBasica', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      ,body: JSON.stringify({
        idgrupo:this.props.Grupo._id
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          // this.setState({ ColorNotificacion: 'danger', mensaje: data.mensaje });
          
          this.setState({ ListaAlumnos:[] })
        } else { //Filtrado exitoso
          if(data.Alumnos.length > 1){
            data.Alumnos.sort(function (a, b) {
              return (a.NombreCompleto).localeCompare(b.NombreCompleto);
            });
            this.setState({ ListaAlumnos:data.Alumnos })
          }else{
            var aux=[]
            aux.push(data.Alumnos)
            this.setState({ ListaAlumnos:aux })
          }

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  ListarAulas() {
    fetch(Globales.Url + 'Alumnos/Alumnos/ListaGeneralAulas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ ColorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Filtrado exitoso
          //console.log('Filtrado Aulas', data.Aulas);
          var ListaAulas = []
          if(data.Aulas.length > 1){
            ListaAulas = data.Aulas;
          }
          else{
            ListaAulas.push(data.Aulas);
          }

          this.setState({ ListaAulas: ListaAulas }, () => {
            if (this.props.op == 1) {
              this.setState({
                Aula: JSON.stringify(this.props.Grupo._idAula),
              })
            }
          })

          // console.log('Filtrado ListaAulas', ListaAulas);
          // console.log("Aula", JSON.stringify(this.props.cita._idAula))

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  ListarIdiomas() {
    this.setState({cargando: true})
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaGeneralIdiomas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
      //console.log(data)
        if (data.error) { //Error en la respuesta
          this.setState({ ColorNotificacion: 'danger', mensaje: data.mensaje, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          if(data.data.length > 1){
            this.setState({ ListaIdiomas: data.data, cargando: false })
          }
          else{
            let aux = [];
            aux.push(data.data);

            this.setState({ ListaIdiomas: aux, cargando: false })
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  ListarNiveles() {
    this.setState({cargando: true})
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaNivelesIdioma', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idIdioma: this.state.Idioma
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ ListaNiveles: [], Nivel: "null", cargando: false });
          // this.showNotification();
        } else { //Filtrado exitoso
          var ListaNiveles = [];
          if(data.data.length > 1){
            ListaNiveles = data.data;
          }
          else{
            ListaNiveles.push(data.data);
          }
          let NivelOrdenados = ListaNiveles.sort((a, b) => parseFloat(a.Orden) - parseFloat(b.Orden))

          // this.setState({ ListaNiveles: ListaNiveles, cargando: false }, () => {
          this.setState({ ListaNiveles: NivelOrdenados, cargando: false }, () => {
            if (this.props.op == 1) {
              if(JSON.stringify(this.props.Grupo._idIdioma) == JSON.stringify(this.state.Idioma)){
                this.setState({ Nivel: JSON.stringify(this.props.Grupo._idNivel) })
              }
            }
          })
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  ListarLibros() {
    fetch(Globales.Url + 'Idiomas/Idiomas/ObtenerLibrosIdioma', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idIdioma: this.state.Idioma
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data)
        if (data.error) { //Error en la respuesta
          this.setState({ ListaLibros: [], Libro: "null" });
          // this.showNotification();
        } else { //Filtrado exitoso
          // this.setState({  ListaLibros:[], Nivel: "null" });
          var ListaLibros = []
          if(data.data.length > 1){
            ListaLibros = data.data;
          }
          else{
            ListaLibros.push(data.data);
          }

          this.setState({ ListaLibros: ListaLibros },()=>{
            if ( (this.props.op == 1) && (JSON.stringify(this.props.Grupo._idIdioma) == JSON.stringify(this.state.Idioma)) ) {
              if( JSON.stringify(this.props.Grupo.IdLibro) != "{\"_increment\":\"0\",\"_machine\":\"0\",\"_pid\":\"0\",\"_timestamp\":\"0\"}" ){
                this.setState({ Libro: JSON.stringify(this.props.Grupo.IdLibro) },()=>{
                  
                  this.ListarUnidades()
                })
              }
            }
          })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  ListarUnidades() {
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaUnidadesLibro', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        libro: JSON.parse(this.state.Libro)
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ ListaUnidades: [], Unidad: "null" });
          // this.showNotification();
        } else { //Filtrado exitoso
          // this.setState({  ListaUnidades:[], Nivel: "null" });
          var ListaUnidades = []

          if(data.data.length > 1){
            ListaUnidades = data.data;
          }
          else{
            ListaUnidades.push(data.data);
          }

          this.setState({ ListaUnidades: ListaUnidades },()=>{
            if ( (this.props.op == 1) && (JSON.stringify(this.props.Grupo.IdLibro) == this.state.Libro) ) {
              if( JSON.stringify(this.props.Grupo.IdUnidadActual) != "{\"_increment\":\"0\",\"_machine\":\"0\",\"_pid\":\"0\",\"_timestamp\":\"0\"}" ){
                this.setState({ Unidad: JSON.stringify(this.props.Grupo.IdUnidadActual) },()=>{
                  
                  // this.ListarUnidades()
                })
              }
            }
          })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  DardeBajaAlumno(grupoAlumno) {
    this.setState({cargando: true})
    fetch(Globales.Url + 'Grupos/Grupos/EliminarAsignacionGrupoAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        grupo: grupoAlumno,
        _idAlumno: this.state.idAlumno,
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: "Ha ocurrido un error, intentelo mas tarde", ColorNotificacion: 'danger', cargando: false });
          this.showNotification();
          // this.showNotification();
        } else { //Filtrado exitoso
          this.ObtenerInformacionGrupo()
          this.setState({ cargando: false})

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  async ActualizarUnidadesGrupo() {
    this.setState({cargando: true})
    //console.log(grupo)
    await fetch(Globales.Url + 'Grupos/Grupos/ActualizarUnidadesGrupo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idGrupo: this.state._id, idNivel: JSON.parse(this.state.Nivel)})
    }
    ).then(res => res.json())
      .then(data => {
      // console.log(data)
        if (data.error) { //Error en la respuesta
          this.setState({ cargando: false, ColorNotificacion: 'danger', mensaje: data.mensaje, });
          this.showNotification();
        } else { //Filtrado exitoso
          this.setState({ cargando: false, mensaje: data.mensaje, ColorNotificacion: "success" })
          this.showNotification();
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  FormValido() {
    //this.setState({cargando: true})
    var valido = true

    // if (this.props.op !== 1) {
    //   //let fecha = new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
    //   if (moment(this.state.Fecha).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
    //     valido = false
    //     this.setState({ mensaje: "Seleccione una fecha valida", ColorNotificacion: 'danger' });
    //     this.showNotification();
    //     this.setState({ cargando: false })
    //   }
    // }
    if (this.state.Aula == "null") {
      valido = false
      this.setState({ mensaje: "Seleccione un aula ", ColorNotificacion: 'danger' });
      this.showNotification();
      this.setState({cargando: false})
    }
    if (this.state.Clase == "null") {
      valido = false
      this.setState({ mensaje: "Seleccione una clase ", ColorNotificacion: 'danger' });
      this.showNotification();
      this.setState({cargando: false})
    }
    if(this.state.ListaNiveles.length > 0){
      if (this.state.Nivel == "null") {
        valido = false
        this.setState({ mensaje: "Seleccione un nivel ", ColorNotificacion: 'danger' });
        this.showNotification();
        this.setState({cargando: false})
      }
    }
    if (this.state.Idioma == "null") {
      valido = false
      this.setState({ mensaje: "Seleccione un idioma ", ColorNotificacion: 'danger' });
      this.showNotification();
      this.setState({cargando: false})
    }
    if (this.state.Modalidad == "null") {
      valido = false
      this.setState({ mensaje: "Seleccione una modalidad ", ColorNotificacion: 'danger' });
      this.showNotification();
      this.setState({cargando: false})
    }

    if(this.state.HoraInicio == ""){
      valido = false
      this.setState({ mensaje: "Seleccione una hora de inicio", ColorNotificacion: 'danger' });
      this.showNotification();
      this.setState({cargando: false})
    }

    if(this.state.HoraFin == ""){
      valido = false
      this.setState({ mensaje: "Seleccione una hora final", ColorNotificacion: 'danger' });
      this.showNotification();
      this.setState({cargando: false})
    }

    if(!this.state.Lunes && !this.state.Martes && !this.state.Miercoles && !this.state.Jueves && !this.state.Viernes && !this.state.Sabado){
      valido = false
      this.setState({ mensaje: "Seleccione por lo menos un día de clase", ColorNotificacion: 'danger' });
      this.showNotification();
      this.setState({cargando: false})
    }

    if (!Number.isInteger(this.state.TotalHorasSemana)) {
      valido = false
      this.setState({ mensaje: "El total de horas no es número entero", ColorNotificacion: 'danger' });
      this.showNotification();
      this.setState({ cargando: false })
    }

    if (this.state.TipoCurso == "Tradicional") {
      if (this.state.TotalHorasSemana < 3 || this.state.TotalHorasSemana > 6) {
        valido = false
        this.setState({ mensaje: "Favor de seleccionar un horario dentro del rango permitido (3-6 horas por semana)", ColorNotificacion: 'danger' });
        this.showNotification();
        this.setState({ cargando: false })
      }
    }

    if (this.state.TipoCurso == "null") {
      valido = false
      this.setState({ mensaje: "Seleccione un tipo de curso ", ColorNotificacion: 'danger' });
      this.showNotification();
    }

    if (valido) {
      var grupo = this.state.ClassGrupo
      var horario = this.state.classHorario


      if (this.props.op == 1) {
        grupo["_id"] = this.props.Grupo._id
      }
      
      grupo.Clave = this.state.Clave
      grupo.Clase = this.state.Clase
      grupo.Curso = this.state.TipoCurso
      grupo.Modalidad = this.state.Modalidad
      grupo.CapacidadAula = parseFloat(this.state.Capcidad)
      grupo.FechaIncioCurso = this.state.Fecha
      // grupo.NombreMaestro = this.state.Maestro.NombreCompleto
      grupo._idIdioma = this.state.Idioma
      grupo._idAula = JSON.parse(this.state.Aula)
      if(this.state.Nivel != "null"){
        grupo._idNivel = JSON.parse(this.state.Nivel)
      }
      if(this.state.Libro != "null"){
        grupo.IdLibro = JSON.parse(this.state.Libro)
      }
      if(this.state.Unidad != "null"){
        grupo.IdUnidadActual = JSON.parse(this.state.Unidad) 
      }
      if(Object.keys(this.state.Maestro).length != 0){
        grupo._idMaestro = this.state.Maestro._id
      }
      else{
        grupo._idMaestro = idVacio;
      }
      
      horario.Lunes = (this.state.Lunes).toString()
      horario.Martes = this.state.Martes.toString()
      horario.Miercoles = this.state.Miercoles.toString()
      horario.Jueves = this.state.Jueves.toString()
      horario.Viernes = this.state.Viernes.toString()
      horario.Sabado = this.state.Sabado.toString()

      horario.HoraInicio = this.state.HoraInicio
      horario.FormatoInicio = this.state.HoraInicioPM
      horario.HoraFin = this.state.HoraFin
      horario.FormatoFin = this.state.HoraFinPM

      grupo.HorarioGrupo = horario

      grupo.NivelesAnteriores = { ObjectId: this.state.NivelesAnteriores }
      grupo.TotalHorasSemana = this.state.TotalHorasSemana
      // grupo.ListaNiveles = this.props.Grupo.ListaNiveles == undefined ? null : this.props.Grupo.ListaNiveles
      

      // console.log("Grupo", grupo)
      if(this.props.op == 1 && moment(this.state.Fecha).format("YYYY-MM-DD") !== moment(this.props.Grupo.FechaIncioCurso).format("YYYY-MM-DD")){
        this.ActualizarFechaInicioCurso(grupo, this.state.Fecha)
        //console.log("Entro en cambio de fecha")
      }
      else{
        this.GuardarGrupo(grupo)
        //console.log("Entro guardado normal")
      }
    }
  }

  ActualizarFechaInicioCurso(grupo, FechaInicio) {
    this.setState({cargando: true})
    fetch(Globales.Url + 'Grupos/Grupos/ActualizarFechaInicioCurso', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idGrupo: grupo._id,
        FechaInicio: FechaInicio,
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: 'danger', cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.GuardarGrupo(grupo)
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  GuardarGrupo(grupo) {
    // console.log(this.state.MaestroAnterior)
    this.setState({cargando: true})
    fetch(Globales.Url + 'Grupos/Grupos/RegistrarGrupo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        grupo: grupo,
        maestroAnterior: this.state.MaestroAnterior,
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: 'danger', cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.setState({ cargando: false })
          this.props.eventClick('Grupo registrado exitosamente')
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  ExportarHistorial(clave) {
    this.setState({ cargando: true })
    fetch(Globales.Url + 'Grupos/Grupos/ReporteHistorialGrupoNodeJs', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        clave: clave,
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          //console.log(data.Folios)
          this.DescargarReporte(data.PDF)
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  DescargarReporte(archivoBase64) {
    const url = 'data:application/pdf;base64,' + archivoBase64;

    let nombre = 'Historial de grupo '
    const linkSource = `${url}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    this.setState({
      cargando: false,
      mensaje: "Reporte generado exitosamente",
      ColorNotificacion: "success",
    });
    this.showNotification();
  }
  MandarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.eventClick('esc');
  }

  closeModal(mensaje, ColorNotificacion) {
    this.props.eventClick(mensaje, ColorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  OpenModalAbonos(Abonos) {
    this.setState({
      showAbonos: true,
      Abonos: Abonos
    })
  }

  EventoModalAbono() {
    this.setState({
      showAbonos: false,
      Abonos: []
    })
  }

  handleChange(e) {
    const { name, value, type } = e.target;
    if(type == "number"){
      if (value == "") {
        this.setState({[name]: 0,})
      } else if (value.length < 2) {
        const num = "0" + value
        this.setState({[name]: num})
      }
      else{
        this.setState({[name]: value})
      }
     } else if(name == "Idioma"){
      this.setState({[name]: JSON.parse(value), Nivel: "null",Libro: "null", NivelesAnteriores: []}, () => {
        this.ListarLibros()
        this.ListarNiveles()
      })
    }
    else{
      this.setState({
        [name]: value,
      }, () => { 
        if (name == "Libro") {
          this.ListarUnidades()
  
          this.setState({
            Unidad: "null",
          })
        }
  
      });
    }
  }
  handleChangeCheck(e){
    const { name, value, checked } = e.target;

    
    this.setState({[name]: checked}, () => this.setState({TotalHorasSemana: this.state.TotalHorasDia * this.SumarDiasSemana()}))
  }

  eventoModalEliminar(Exito) {
    this.setState({
      show: false,
    });
    if (Exito !== false) {
      this.setState({ cargando: true})
      var grupoAlumno = this.state.classGrupoAlumno

      grupoAlumno._idGrupo = this.props.Grupo._id
      grupoAlumno.NombreGrupo = this.props.Grupo.Nombre
      grupoAlumno.FechaAsignacion = new Date(this.props.Grupo.FechaIncioCurso)
      grupoAlumno.idIdioma = this.props.Grupo._idIdioma

      this.DardeBajaAlumno(grupoAlumno);
    }
  }
  eventoPreguntaActualizarUnidades = (Exito) => {
    this.setState({
      showUnidades: false,
    });
    if (Exito !== false) {
      this.ActualizarUnidadesGrupo();
    }
  }

  EventoSeleccionarIdioma(idIdioma) {
    this.setState({ Idioma: idIdioma });
  }
  EventoSeleccionarAula(Aula) {
    this.setState({ Aula: Aula });
  }
  handleCallback = (AlumnoSeleciondado) => {
    this.setState({ Alumno: AlumnoSeleciondado, showAlumnos: false })
  }
  handleCallbackMaestros = (maestro) => {
    this.setState({ Maestro: maestro, showMaestros: false })
  }
  cerrarModales() {
    this.setState({ showMaestros: false, showAlumnos: false })
  }
  
  handleChangeAutocomplete(e, newValue){
    // console.log("Autocomplete seleccionado", newValue)

    if(newValue !== undefined && newValue !== null){
      if(this.state.MaestroAnterior == ""){
        this.setState({Maestro: newValue, MaestroAnterior: this.props.Grupo._idMaestro})
      }
      else{
        this.setState({Maestro: newValue})
      }
      
    }
  }

  InputChange(e, newInputValue){
    //const { name, value2 } = e.target;
    //console.log(newInputValue)
      //this.ListaMaestrosBusquedaFiltrada(newInputValue);

      this.setState({NombreMaestro: newInputValue});
      this.ListaMaestrosBusquedaFiltrada(newInputValue);
  }

  handleMouseDownToken(event) {
    event.preventDefault();
  }

  handleClickEliminarTexto(){
    this.setState({NombreMaestro: "", Maestro: {}});
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  eventoHorario = (HoraInicio, HoraFin, HoraInicioPM, HoraFinPM, totalhoras) => {

    // console.log({
    //   "HoraFinPM": HoraFinPM,
    //   "HoraInicioPM": HoraInicioPM,
    //   "HoraInicio": HoraInicio,
    //   "HoraFin": HoraFin,
    //   "totalhoras": totalhoras
    // })
    

    this.setState({
      HoraFinPM: HoraFinPM,
      HoraInicioPM: HoraInicioPM,
      HoraInicio: HoraInicio,
      HoraFin: HoraFin,
      TotalHorasDia: totalhoras,
      TotalHorasSemana: totalhoras * this.SumarDiasSemana()
    })
  }

  SumarDiasSemana(){
    let aux = 0
    if(this.state.Lunes){
      aux += 1
    }

    if(this.state.Martes){
      aux += 1
    }

    if(this.state.Miercoles){
      aux += 1
    }

    if(this.state.Jueves){
      aux += 1
    }

    if(this.state.Viernes){
      aux += 1
    }

    if(this.state.Sabado){
      aux += 1
    }

    return aux
  }
  
  render() {
    const { classes } = this.props;

    let disabledEditar = (this.props.op == 1)
    // console.log(this.state);
    //console.log(this.state.NombreMaestro)
    //console.log(this.state.Fecha)

    const idIngles = {
      "_increment": "5933375",
      "_machine": "595175",
      "_pid": "2400",
      "_timestamp": "1373551310"
    }

    let agregar

    if (this.state.showUnidades == true) {
      agregar = (
        <ModalPregunta
          Titulo={"Acturalizar unidades"}
          Cuerpo={"¿Está seguro que desea actualizar unidades del grupo? se actualizaran las calificaciones de los alumnos en este grupo"}
          show={this.state.showUnidades}
          eventClick={this.eventoPreguntaActualizarUnidades}
        />
      );
    }
    
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    if(this.state.show == true){
      agregar = (
        <ModalMotivosCancelacion
          show={this.state.show}
          eventClick={this.eventoModalEliminar}
        />
      )
    }

    // if (this.state.showMaestros == true) {
    //   agregar = (
    //     <ModalBuscarMaestros
    //       showAgregar={this.state.showMaestros}
    //       eventClick={this.cerrarModales}
    //       op={this.state.op}
    //       parentCallback={this.handleCallbackMaestros}
    //     />
    //   );
    // }

    // var Maestro = ""

    // if(this.state.showMaestros == true){
    //   Maestro =(
    //     <SeleccionarMaestro
    //       eventClick={this.handleCallbackMaestros}
    //       Nombre={this.state.Maestro.NombreCompleto}
    //       // showMaestros={this.state.showMaestros}
    //     />)
    // } else {
    //   Maestro =(
    //     <SeleccionarMaestro
    //       eventClick={this.handleCallbackMaestros}
    //       //Nombre={this.state.Maestro.NombreCompleto}
    //     />)
    // }

    // console.log(this.state.Aula)
    //console.log(this.state.Idioma)
    //console.log(this.state.Libro)
    // console.log(this.state.Maestro)
    // console.log(this.state.ListaUnidades)
    //console.log("HoraInicio",this.state.HoraInicio)
    // console.log(this.state.MaestroAnterior)
    // console.log("HoraInicio", this.state.HoraInicio)
    // console.log("HoraFin", this.state.HoraFin)
    // console.log(this.state.ClassGrupo)
    // console.log(this.state.TotalHorasSemana)

    return (
      <div>
        <Modal isOpen={this.props.showGrupo} className="static-modal" style={{ maxWidth: "700px", width: "100%" }} size="lg" toggle={this.cerrarModal} backdrop={true}>
          <Card >
            <CardHeader color={"info"}>
              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} md={10}>
                  <h4>{this.props.op == 1 ? "Actualizar grupo" :
                    "Registar grupo"}</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody xs={12} sm={12} md={12} lg={12}>

              <table className="col-xs-12 col-sm-12 col-md-12">
                <thead>
                  <tr>
                    <th colSpan={4} className="text-center" style={{ backgroundColor: "#f1f1f1", borderRadius: 3, fontWeight: "bold" }}>
                      Información del grupo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold", width: "10em" }}> Clave: </td>
                    <td colSpan={2}>
                      <TextField
                        style={{ width: "25em" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          //required: true,
                          disabled:true,
                          value: this.state.Clave,
                          //error: this.state.errorToken,
                          //success: !this.state.errorToken
                        }}
                      />
                    </td>
                    <td> 
                    <Button  hidden={!(this.props.op == 1)} size='sm' color='info' className='pull-right' 
                      round onClick={() => this.ExportarHistorial(this.state.Clave)}><Print />&nbsp;Historial</Button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Capacidad: </td>
                    <td colSpan={3}>
                      <FormControl
                        fullWidth
                        style={{ width: "25em" }}>
                        <Select
                          value={this.state.Aula}
                          onChange={this.handleChange}
                          name="Aula"
                          defaultValue={"Seleccione"}
                          disabled={true}
                        >
                          <MenuItem value={"null"} disabled={true}>Selecionar aula</MenuItem>
                          {this.state.ListaAulas.length == 0 ? null :
                            this.state.ListaAulas.map((aula) => {
                              return (<MenuItem value={JSON.stringify(aula._id)} >{aula.Capacidad}</MenuItem>)
                            })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Aula:</td>
                    <td colSpan={2}>
                      <FormControl
                        fullWidth
                        style={{ width: "25em" }}>
                        <Select
                          value={this.state.Aula}
                          onChange={this.handleChange}
                          name="Aula"
                          defaultValue={"Seleccione"}
                        >
                          <MenuItem value={"null"} disabled={true}>Selecionar aula</MenuItem>
                          {this.state.ListaAulas.length == 0 ? null :
                            this.state.ListaAulas.map((aula) => {
                              return (<MenuItem value={JSON.stringify(aula._id)} >{aula.Nombre}</MenuItem>)
                            })}
                        </Select>
                      </FormControl>
                    </td>
                    <td rowSpan={2}> 
                      <Button hidden={!(this.props.op == 1)} data-toggle="tooltip" data-placement="bottom" title="Editar grupo" color="info"
                        round size="sm" className="pull-right mr-2" onClick={() => this.setState({ showUnidades: true })}><Refresh /> Actualizar <br />unidades</Button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Idioma:</td>
                    <td colSpan={2}>
                      <FormControl
                        fullWidth
                        style={{ width: "25em" }}
                      >
                        <Select
                          value={this.state.Idioma !== "null" ? JSON.stringify(this.state.Idioma) :  this.state.Idioma}
                          onChange={this.handleChange}
                          defaultValue={"Seleccione"}
                          name="Idioma"
                          disabled={disabledEditar}
                        >
                          <MenuItem value={"null"} disabled={true}>Selecionar idioma</MenuItem>
                          {this.state.ListaIdiomas.length == 0 ? null :
                            this.state.ListaIdiomas.map((idioma) => {
                              return (<MenuItem value={JSON.stringify(idioma._id)} >{idioma.Nombre}</MenuItem>)
                            })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Nivel:</td>
                    <td colSpan={2}>
                      <FormControl
                        fullWidth
                        style={{ width: "25em" }}
                      >
                        <Select
                          value={this.state.Nivel}
                          onChange={this.handleChange}
                          defaultValue={"Seleccione"}
                          name="Nivel"
                          disabled={disabledEditar}
                        >
                          {this.state.ListaNiveles.length == 0 ?
                            <MenuItem value={"null"}>Seleccione un idioma</MenuItem>
                            :
                            <MenuItem value={"null"} disabled={true}>Selecionar nivel</MenuItem>
                          }
                          {this.state.ListaNiveles.length == 0 ? null :
                            this.state.ListaNiveles.map((Nivel) => {
                              return (<MenuItem value={JSON.stringify(Nivel._id)} >{Nivel.Nombre}</MenuItem>)
                            })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Clase:</td>
                    <td colSpan={2}>
                      <FormControl
                        fullWidth
                        style={{ width: "25em" }}
                      >
                        <Select
                          value={this.state.Clase}
                          onChange={this.handleChange}
                          defaultValue={"Seleccione"}
                          name="Clase"
                        >
                          <MenuItem value={"null"} disabled={true}>Seleccione clase</MenuItem>
                          <MenuItem value={"Niño"}>Niño</MenuItem>
                          <MenuItem value={"Adolescente"}>Adolescente</MenuItem>
                          <MenuItem value={"Adulto"}>Adulto</MenuItem>
                          {this.state.Idioma !== "null" ? JSON.stringify(this.state.Idioma) === JSON.stringify(idIngles) ? <MenuItem value={"Cambridge"}>Cambridge</MenuItem> : "" : ""}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Tipo de curso:</td>
                    <td colSpan={2}>
                      <FormControl
                        fullWidth
                        style={{ width: "25em" }}
                      >
                        <Select
                          value={this.state.TipoCurso}
                          onChange={this.handleChange}
                          defaultValue={"Seleccione"}
                          name="TipoCurso"
                        >
                          <MenuItem value={"null"} disabled={true}>Seleccione tipo de curso</MenuItem>
                          <MenuItem value={"Tradicional"}>Tradicional</MenuItem>
                          <MenuItem value={"CursoVerano"}>Curso de verano</MenuItem>
                          <MenuItem value={"ClaseParticular"}>Clase particular</MenuItem>
                          <MenuItem value={"CursoTOEFL"}>Curso TOEFL</MenuItem>
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Modalidad:</td>
                    <td colSpan={2}>
                      <FormControl
                        fullWidth
                        style={{ width: "25em" }}
                      >
                        <Select
                          value={this.state.Modalidad}
                          onChange={this.handleChange}
                          defaultValue={"Seleccione"}
                          name="Modalidad"
                        >
                          <MenuItem value={"null"} disabled={true}>Seleccione modalidad</MenuItem>
                          <MenuItem value={"En_linea"}>En linea</MenuItem>
                          <MenuItem value={"Presencial"}>Presencial</MenuItem>
                          <MenuItem value={"Hibrido"}>Hibrido</MenuItem>
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Maestro: </td>
                    <td colSpan={2} className="align-middle">
                      <Autocomplete
                        freeSolo
                        id="NombreMaestro"
                        noOptionsText={'No hay opciones'}
                        style={{ maxWidth: "25em" }}
                        disableClearable
                        value={this.state.Maestro}
                        inputValue={this.state.NombreMaestro}
                        onChange={this.handleChangeAutocomplete.bind(this)}
                        onInputChange={this.InputChange.bind(this)}
                        options={this.state.ListaMaestros}
                        getOptionLabel={(option) => option.NombreCompleto}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Nombre del maestro"
                            margin="normal"
                            size="small"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => this.handleClickEliminarTexto()}
                                    onMouseDown={this.handleMouseDownToken}
                                    edge="end"
                                  >
                                    <Clear />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              type: 'search',
                              name: "NombreMaestro",
                              id: "NombreMaestro",
                              //error: this.state.errorNombre,
                              //success: !this.state.errorNombre
                            }}
                          />
                        )}
                      />
                    </td>
                    <td rowSpan={2}>
                      {/* <Button tabIndex="2" size='sm' color='info' className='pull-right' round onClick={() => this.setState({ showMaestros: true })}><Search />&nbsp;Buscar</Button> */}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}> Inicio de curso: </td>
                    <td colSpan={2}>
                      <TextField
                        style={{ width: "25em" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          //required: true,
                          disabled: this.state.disabledFecha,
                          onChange: this.handleChange.bind(this),
                          value: this.state.Fecha,
                          name: "Fecha",
                          id: "Fecha",
                          type: "date",
                          //error: this.state.errorToken,
                          //success: !this.state.errorToken
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />

              <table>
                <thead>
                  <tr>
                    <th colSpan={2} className="text-center" style={{ backgroundColor: "#f1f1f1", borderRadius: 3, fontWeight: "bold" }}>
                      Horario del grupo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold", width: "10em" }} > Días de clase: </td>
                    <td>
                      <Checkbox
                        onChange={this.handleChangeCheck.bind(this)}
                        checked={this.state.Lunes}
                        name="Lunes"
                      /> LUN
                      <Checkbox
                        onChange={this.handleChangeCheck.bind(this)}
                        checked={this.state.Martes}
                        name="Martes"
                      /> MAR
                      <Checkbox
                        onChange={this.handleChangeCheck.bind(this)}
                        checked={this.state.Miercoles}
                        name="Miercoles"
                      /> MIE
                      <Checkbox
                        onChange={this.handleChangeCheck.bind(this)}
                        checked={this.state.Jueves}
                        name="Jueves"
                      /> JUE
                      <Checkbox
                        onChange={this.handleChangeCheck.bind(this)}
                        checked={this.state.Viernes}
                        name="Viernes"
                      /> VIE
                      <Checkbox
                        onChange={this.handleChangeCheck.bind(this)}
                        checked={this.state.Sabado}
                        name="Sabado"
                      /> SAB

                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Horario: </td>
                    <td>
                      <SeleccionarHorario
                        op={this.props.op}
                        HorarioGrupo={this.props.Grupo.HorarioGrupo}
                        SalidaFormato24Hrs={false}
                        AgregarLimpiar={false}
                        SalidaTotalHoras={true}
                        eventClick={this.eventoHorario}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Total horas por semana: </td>
                    <td>
                    <TextField
                        style={{ width: "5em" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          //required: true,
                          disabled: true,
                          // onChange: this.handleChange.bind(this),
                          value: (this.state.TotalHorasSemana).toFixed(2),
                          name: "TotalHorasSemana",
                          id: "TotalHorasSemana",
                          //error: this.state.errorToken,
                          //success: !this.state.errorToken
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />

              <table >
                <thead>
                  <th colSpan={2} className="text-center" style={{ backgroundColor: "#f1f1f1", borderRadius: 3, fontWeight: "bold" }}>
                    Unidad actual
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold", width: "10em" }} > Libro: </td>
                    <td>
                      <FormControl
                        fullWidth
                        style={{ width: "25em" }}
                      >
                        <Select
                          value={this.state.Libro}
                          onChange={this.handleChange}
                          name="Libro"
                        >
                          {this.state.ListaLibros.length == 0 ?
                            <MenuItem value={"null"}>Seleccione un idioma</MenuItem>
                            :
                            <MenuItem value={"null"} disabled={true}>Selecionar libro</MenuItem>
                          }
                          {this.state.ListaLibros.length == 0 ? null :
                            this.state.ListaLibros.map((Nivel) => {
                              return (<MenuItem value={JSON.stringify(Nivel._id)} >{Nivel.Nombre}</MenuItem>)
                            })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }} > Unidad: </td>
                    <td>
                      <FormControl
                        fullWidth
                        style={{ width: "25em" }}
                      >
                        <Select
                          value={this.state.Unidad}
                          onChange={this.handleChange}
                          name="Unidad"
                        >
                          {this.state.ListaUnidades.length == 0 ?
                            <MenuItem value={"null"}>Seleccione un libro</MenuItem>
                            :
                            <MenuItem value={"null"} disabled={true}>Selecionar unidad</MenuItem>
                          }
                          {this.state.ListaUnidades.length == 0 ? null :
                            this.state.ListaUnidades.map((Nivel) => {
                              return (<MenuItem value={JSON.stringify(Nivel._id)} >{Nivel.NombreUnidad}</MenuItem>)
                            })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br/>

              <table hidden={!(this.props.op == 1)}>
                <thead>
                <th colSpan={3} className="text-center" style={{ backgroundColor: "#f1f1f1", borderRadius: 3, fontWeight: "bold" }}>
                    Lista de alumnos ({this.state.ListaAlumnos.length})
                  </th>
                </thead>
                <tbody>
                  {this.state.ListaAlumnos.length == 0 ?
                    <tr><td colSpan={3} className="text-center"><h4>no hay alumnos inscritos</h4> </td></tr>
                    :
                    this.state.ListaAlumnos.map( Alumno =>{
                      return(
                        <tr>
                          <td colSpan={2}> {Alumno.NumeroCuenta_NombreCompleto} </td>
                          <td>
                            <Button tabIndex="2" size='sm' color='danger' className='pull-right' round
                              onClick={() => this.setState({idAlumno:Alumno._id, show:true})}><Delete />&nbsp;dar de baja</Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>

              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12}>
                  <Button tabIndex="2" size='sm' color='success' className='pull-right' round onClick={() => this.FormValido()}><Save />&nbsp;Guardar</Button>
                  <Button tabIndex="2" size='sm' color='' className='pull-right' style={{marginRight: "10px"}} round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;Cerrar</Button>
                
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
        {agregar}
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.ColorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalInfoVenta);