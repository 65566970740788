import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import { ExpandLess, Streetview, ExpandMore } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  function handleClick() {
    setOpen(!open);
  }

  const { classes, color, logo, image, logoText, routes } = props;
  const [open, setOpen] = React.useState(false);
  var links = (
    <List aria-labelledby="nested-list-subheader" className={classes.root}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path)
        });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        return (
          Object.keys(prop).length === 6 || Object.keys(prop).length === 5 ?
            <NavLink
              // to={prop.layout + prop.path}
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                {typeof prop.icon === "string" ? (
                  <Icon className={classNames(classes.itemIcon, whiteFontClasses, { [classes.itemIconRTL]: props.rtlActive })}>
                    {prop.icon}
                  </Icon>) : (
                    <prop.icon className={classNames(classes.itemIcon, whiteFontClasses, { [classes.itemIconRTL]: props.rtlActive })} />
                  )
                }
                <ListItemText primary={prop.name} className={classNames(classes.itemText, whiteFontClasses, { [classes.itemTextRTL]: props.rtlActive })} disableTypography={true} />
              </ListItem>
            </NavLink>
            :
            <List>
              <ListItem button className={classes.itemLink + listItemClasses} onClick={handleClick}>
                {typeof prop.icon === "string" ? (
                  <Icon className={classNames(classes.itemIcon, whiteFontClasses, { [classes.itemIconRTL]: props.rtlActive })}>
                    {prop.icon}
                  </Icon>) : (
                    <prop.icon className={classNames(classes.itemIcon, whiteFontClasses, { [classes.itemIconRTL]: props.rtlActive })} />
                  )
                }
                <ListItemText primary={prop.name} className={classNames(classes.itemText, whiteFontClasses, { [classes.itemTextRTL]: props.rtlActive })} disableTypography={true} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <List>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {prop.children.map((itemChildren, keyItem) =>
                      <NavLink
                        to={itemChildren.layout + itemChildren.path}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={keyItem}
                      >
                        <ListItem button className={classes.itemLink + classNames({
                          [" " + classes[color]]: activeRoute(itemChildren.layout + itemChildren.path)
                        }) + ' pl-5'} to={itemChildren.layout + itemChildren.path}>
                          <ListItemText primary={itemChildren.name} className={classNames(classes.itemText, classNames({
                            [" " + classes.whiteFont]: activeRoute(itemChildren.layout + itemChildren.path)
                          }), { [classes.itemTextRTL]: props.rtlActive })} disableTypography={true} />
                        </ListItem>
                      </NavLink>)}
                  </List>
                </Collapse>
              </List>
            </List>
        );
      })}
    </List >
  );
  // var brand = (
  //   <div className={classes.logo}>
  //     <a
  //       // href="#"
  //       style={{ color: '#FFFFFF', textAlign: 'center' }}
  //       className={classNames(classes.logoLink, {
  //         [classes.logoLinkRTL]: props.rtlActive
  //       })}
  //     >
  //       <div className={classes.logoImage}>
  //         <img src={logo} style={{ width:"120px", marginLeft:"-50px"}}  alt="logo" />
  //       </div>
  //       {/* {logoText} */}
  //     </a>
  //   </div>
  // );
  var brand = (
    <div className={classes.logo}>
      <a
        // href="#"
        style={{ color: '#FFFFFF', textAlign: 'center' }}
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
      >
        {/* <div className={classes.logoImage}> */}
          {/* <img src={logo} style={{ width:"120px", marginLeft:"-50px"}}  alt="logo" /> */}
        <div>
          JUTTA TESCHE
        </div>
        {/* {logoText} */}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
