import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import cookie from 'js-cookie'

class ModalEstudiosAnte extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      ListaIdiomas: [],
      IDIdioma: '',
      LugarEstudio: "",
      Duracion: "",
      FechaEstudio: "",
      NombreidiomaSeleccionado: "",
      ObjEstudiosAnte: [],
      disabled:false,
      errorIDIdioma: false,
      errorLugarEstudio: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      cargando: false,
      CuerpoCargando: "Cargando",
      aviso: ""
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {

    this.ListaGeneralIdiomas();
    let IDcookie = JSON.parse(cookie.get('id'));
    this.setState({_id: IDcookie})

  }

  ListaGeneralIdiomas() {
    fetch(Globales.Url + 'Prospectos/Prospectos/ListaGeneralIdiomas', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({prospecto: this.state.ListaProspecto})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1});
                this.showNotification();
            } else { //Filtrado exitoso
                if (data.ListaIdiomas.length > 1) {
                    this.setState({ ListaIdiomas: data.ListaIdiomas, Activo: 1 });
                }
                else {
                    let ListaAuxEmpresas = [];
                    ListaAuxEmpresas.push(data.ListaIdiomas);
                    //console.log(ListaAux);
                    this.setState({ ListaIdiomas: ListaAuxEmpresas, Activo: 1 });
                }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

CrearArrayEstudioAnte(){
  let valor = 0;

  if(this.state.IDIdioma == '' || this.state.LugarEstudio == ""){
    valor = 1;
    this.setState({errorIDIdioma: true, errorLugarEstudio: true, mensaje: "Complete los campos", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor = 0;
    this.setState({errorIDIdioma: false, errorLugarEstudio: false})
  }

  if(valor == 0){
  this.setState({
    ObjEstudiosAnte: {
      Duracion: this.state.Duracion,
      Lugar: this.state.LugarEstudio,
      Nombreidioma: this.state.NombreidiomaSeleccionado,
      TiempoTranscurrido: this.state.FechaEstudio,
      _ididioma: this.state.IDIdioma
    }
  }, () => this.RegresarEstudioAnterior(this.state.ObjEstudiosAnte))
  }
}

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarEstudioAnterior(objeto) {
    this.setState({ op1: 1 });
    this.props.parentCallback(objeto);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    let aux;

    if(name == "IDIdioma"){
      if(JSON.stringify(this.state.IDIdioma) !== JSON.stringify(value)){
        this.state.ListaIdiomas.map(idioma => {
            if(JSON.stringify(value) == JSON.stringify(idioma._id)){
                aux = idioma.Nombre;
            }
        })
        this.setState({ [name]: value, NombreidiomaSeleccionado: aux });
      }
      else{
          this.setState({ [name]: value });
      }
    }

    this.setState({ [name]: value });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;


    let agregar
    if(this.state.cargando){
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      )
    }

    //console.log(this.state.ObjEstudiosAnte)

    return (
      <div>
        <Modal
          isOpen={this.props.showEstudiosAnte}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormControl className={classes.formControl} size="small" variant="outlined">
                <InputLabel style={{marginTop: "10px"}} id="Idiomalabel">Idioma</InputLabel>
                    <Select
                        labelId="Idiomalabel"
                        value={this.state.IDIdioma}
                        onChange={this.handleChange.bind(this)}
                        name="IDIdioma"
                        label="Idioma"
                        style={{width: "23.7vw", marginTop: "10px" }}
                        error={this.state.errorIDIdioma}
                        success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione un idioma</MenuItem>
                        {this.state.ListaIdiomas.map(idioma => {
                            return(<MenuItem value={idioma._id}>{idioma.Nombre}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="LugarEstudio"
                    style={{ width: "23.7vw", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Lugar"
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.LugarEstudio,
                        name: "LugarEstudio",
                        id: "LugarEstudio",
                        error: this.state.errorLugarEstudio,
                        success: !this.state.errorLugarEstudio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <TextField
                      id="Duracion"
                      style={{ width: "15.5em", marginTop: "10px", marginRight: "10px" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Duración"
                      InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.Duracion,
                          name: "Duracion",
                          id: "Duracion",
                          //error: this.state.errorLugarEstudio,
                          //success: !this.state.errorLugarEstudio
                      }}
                    />
                    <TextField
                      id="FechaEstudio"
                      style={{ width: "16.3em", marginTop: "10px" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Fecha (Mes/Año)"
                      InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaEstudio,
                          name: "FechaEstudio",
                          id: "FechaEstudio",
                          //error: this.state.errorLugarEstudio,
                          //success: !this.state.errorLugarEstudio
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Button tabIndex="0" id="btnGuardar" size='sm' color='info' className='pull-right mt-3' round onClick={() => this.CrearArrayEstudioAnte()}><Save/>&nbsp;Guardar</Button>
                  <Button tabIndex="1" size='sm' className='pull-right mx-2 mt-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cancelar</Button>
                </GridItem> 
              </GridContainer>
            </CardBody>
          </Card>  
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalEstudiosAnte);