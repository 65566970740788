import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalEditarInventario from "./ModalEditarInventario.jsx"
import Globales from "utils/Globales";
import { Edit, Info, InfoTwoTone, InfoOutlined, Close, AddCircle, Search, Clear, Filter9Plus, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'

class Prospectos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: '',
            ListaAreas: [],
            AreaSeleccionada: {},
            Disabled: false,
            mensaje: "",
            br: false,
            show: false,
            cargando: false,
            CheckAll: false,
            colorNotificacion: 'success',
            colorTableRow: "white",
            errorIDIdioma: false,
            notificacion: false,
            Total: 0,
            TotalSeleccionado: 0,
            CuerpoCargando: "Cargando",
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
        //this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.ListadoAreas();
  }

    async ListadoAreas() {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Inventario/Inventario/ListadoAreas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify()
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    if (data.ListaAreas.length > 1) {

                        this.setState({ ListaAreas: data.ListaAreas, cargando: false });
                    }
                    else {
                        let aux = [];
                        aux.push(data.ListaAreas);

                        //console.log(ListaAux);
                        this.setState({ ListaAreas: aux, cargando: false });
                        
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async EliminarArea(id) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Inventario/Inventario/EliminarArea', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({idArea: id})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    this.setState({mensaje: data.mensaje, colorNotificacion: "success", cargando: false})
                    this.showNotification();
                    this.ListadoAreas();
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

//Funciones para abrir modales

    openModalEditarInventario = (area) => {
        this.setState({ AreaSeleccionada: area, showEditarInventario: true, op: 1 });
    }

    openModalCrearArea = () => {
        this.setState({ showEditarInventario: true, op: 0 });
    }

    openModalPregunta = (id) => {
        this.setState({ show: true, _id: id});
    }

//Funciónes que recuperan valores para los modales

    eventoEditarInventario = (valor) => {
        //Cambiar valores por default
        this.setState({ showEditarInventario: false, op: 0 })
        if (valor != false) {
            this.setState({ mensaje: valor, colorNotificacion: "success" })
            this.showNotification();
            this.ListadoAreas();
        }
    }

    eventoModalPregunta = (valor) => {
        this.setState({ show: false })
        if(valor !== false){
            this.EliminarArea(this.state._id)
        }
    }

    //Función que habilita la notificación
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        //console.log(e.target);

        this.setState({ [name]: value });
    }

    render() {
    const { classes } = this.props;

    //console.log(this.state.ListaAreas)

        let agregar;
        if (this.state.showEditarInventario == true) {
            agregar =
                <ModalEditarInventario
                    showEditarInventario={this.state.showEditarInventario}
                    eventClick={this.eventoEditarInventario}
                    op={this.state.op}
                    AreaSeleccionada={this.state.AreaSeleccionada}
                />
        }

        if (this.state.cargando == true) {
            agregar = (
              <ModalCargando
                Cuerpo={this.state.CuerpoCargando}
                show={this.state.cargando}
              />
            );
        }

          let Areas;
          if(this.state.ListaAreas.length > 0){
            Areas =
            this.state.ListaAreas.map(area => {
                return (
                    <tr>
                        <td onClick={() => this.openModalEditarInventario(area)}>{area.Nombre}</td>
                        <td>
                            {area.EsAula == "false" ? <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar área" color="danger" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalPregunta(area._id)}><Close /></Button> : ""}
                            <Button data-toggle="tooltip" data-placement="bottom" title="Editar área" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalEditarInventario(area)}><Edit /></Button>
                        </td>
                    </tr>
                )
            })
          }

        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Inventario</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Button size="sm" type="submit" color="info" round onClick={() => this.openModalCrearArea()}><AddCircle/>&nbsp;Nuevo</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader color="info" text>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <h4 className="pull-left">Área</h4>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                                            <table className="table table-sm table-hover">
                                                <thead>
                                                    <th>Nombre</th>
                                                </thead>
                                                <tbody>
                                                {Areas}
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <ModalPregunta
                        Titulo={"Áreas"}
                        Cuerpo={"¿Está seguro de eliminar el área?"}
                        show={this.state.show}
                        eventClick={this.eventoModalPregunta}
                    />
                    {agregar}
                </div>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
                
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(Prospectos);