import React, { useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

// material-ui icons
import Globales from "utils/Globales";
import Dropdown from 'react-dropdown';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";


class SeleccionarMaestro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            MaestroSeleccionado: {},
            ListaMaestros: [],
            errorEmpresa: null,
            NombreMaestro: "",
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
    }

    componentDidMount() {

    }

    //Función para obtener las empresas
    ListaMaestrosBusquedaFiltrada(texto) {
        fetch(Globales.Url + 'Alumnos/Alumnos/ListaMaestrosBusquedaFiltrada', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ Nombre: texto })
        }
        ).then(res => res.json())
            .then(data => {
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje });
                } else { //Filtrado exitoso
                    if (data.ListaMaestros.length > 1) {
                      this.setState({ ListaMaestros: data.ListaMaestros });
                    }
                    else{
                      let aux = [];
                      aux.push(data.ListaMaestros);
                      this.setState({ListaMaestros: aux})
                    }
                }
            })
            .catch(err => console.error(err));
    }

    
    MaestroSeleccionado = event => {       
      this.props.eventoClick(event.target.value)
    }

    handleChangeAutocomplete(e, newValue){

        //console.log("Autocomplete seleccionado", newValue)
        if(typeof newValue != 'string'){
          this.setState({MaestroSeleccionado: newValue})
          this.props.eventClick(newValue)
        }
      }

      InputChange(e, newInputValue){
        //const { name, value2 } = e.target;
        //console.log(newInputValue)
          //this.ListaMaestrosBusquedaFiltrada(newInputValue);

          this.setState({NombreMaestro: newInputValue});
          this.ListaMaestrosBusquedaFiltrada(newInputValue);
      }

      handleMouseDownToken(event) {
        event.preventDefault();
      }
    
      handleClickEliminarTexto(){
        this.setState({NombreMaestro: "", MaestroSeleccionado: { NombreCompleto: "" }});
        this.props.eventClick({ NombreCompleto: "" })
      }

    render() {
        const { classes } = this.props;
        return (
          <Autocomplete
              freeSolo
              id="NombreMaestro"
              noOptionsText={'No hay opciones'}
              style={{ maxWidth: "18.2vw", width: "18.2vw", display: "inline-flex" }}
              disableClearable
              value={this.state.MaestroSeleccionado}
              inputValue={this.state.NombreMaestro}
              onChange={this.handleChangeAutocomplete.bind(this)}
              onInputChange={this.InputChange.bind(this)}
              options={this.state.ListaMaestros}
              getOptionLabel={(option) => option.NombreCompleto}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nombre del maestro"
                  margin="normal"
                  size="small"
                  style={{marginTop: "10px"}}
                  variant="outlined"
                  InputProps={{ 
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.handleClickEliminarTexto()}
                          onMouseDown={this.handleMouseDownToken}
                          edge="end"
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                    type: 'search',
                    name: "NombreMaestro",
                    id: "NombreMaestro",
                    //error: this.state.errorNombre,
                    //success: !this.state.errorNombre
                  }}
                />
              )}
            />
        )
    }
}

export default withStyles(headerLinksStyle)(SeleccionarMaestro);