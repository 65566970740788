import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, Add, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';


class ModalInfoAdeudo extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
        VentaSeleccionada: {},
        checkedInactivos: false,
      MostrarModal:false,
      _id: "",
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    //console.log(this.props)
    if (this.props.op == 1) {
      this.setState({
        VentaSeleccionada: this.props.VentaSeleccionada,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  MandarMensaje(mensaje) {
    this.setState({mensaje:mensaje, op: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick('esc');
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  /* SeleccionarAlumno(ListaAlumnos) {
    this.setState({AlumnoSeleciondado:ListaAlumnos, op: 1 });
    this.props.parentCallback(ListaAlumnos);
    this.props.eventClick('esc');
  } */

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value} = e.target;
    this.setState({
      [name]: value,
    });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    const { classes } = this.props;
    //console.log(this.state.VentaSeleccionada);
    return (
      <div>
        <Modal isOpen={this.props.showInfoAdeudo} className="static-modal" size="lg" toggle={this.cerrarModal} backdrop={true}>
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem style={{ backgroundColor: "#ffabab", borderRadius: 3, }} xs={12} sm={12} md={12} lg={12}>
                <GridContainer style={{marginBottom: "1em"}} xs={12} sm={12} md={12} lg={12}>
                  <GridItem xs={12} sm={12} md={12} lg={12} >
                      {/* <h5 className="text-center"><b>FACTURA CANCELADA</b></h5> */}
                      <h5 className="text-center"><b>PAGO CANCELADO</b></h5>
                  </GridItem>
                  {/* TITULOS DE LOS DATOS */}
                  <GridItem xs={12} sm={12} md={12} lg={3}>
                    <div className="text-center"><b>CANCELÓ</b></div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={3}>
                    <div className="text-center"><b>AUTORIZÓ</b></div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={3}>
                    <div className="text-center"><b>FECHA</b></div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={3}>
                    <div className="text-center"><b>MONTO</b></div>
                  </GridItem>
                  {/* DATOS RECUPERADOS */}
                  <GridItem xs={12} sm={12} md={12} lg={3}>
                    <div className="text-center">{this.state.VentaSeleccionada.NombreCancelo}</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={3}>
                    <div className="text-center">{this.state.VentaSeleccionada.NombreAutorizo !== null && this.state.VentaSeleccionada.NombreAutorizo !== undefined ? this.state.VentaSeleccionada.NombreAutorizo : "Sin especificar"}</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={3}>
                    <div className="text-center">{moment(this.state.VentaSeleccionada.FechaCancelacion).format('DD-MM-YYYY')}</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={3}>
                    <div className="text-center">{convertirPesos(this.state.VentaSeleccionada.Monto)}</div>
                  </GridItem>
                  {/* MOTIVO DE CANCELACION */}
                  <GridItem xs={12} sm={12} md={12} lg={12} >
                    <div className="text-center"><b>MOTIVO DE CANCELACION</b></div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12} >
                    <div className="text-center">{this.state.VentaSeleccionada.MotivoCancelacion}</div>
                  </GridItem>
                </GridContainer>
              </GridItem>
                <GridItem xs={2} style={{marginTop:"2em" }}>
                  <div className="mt-2">Alumno:</div>
                  <div className="mt-2">Fecha limite de pago:</div>
                  <div className="mt-2">Cantidad:</div>
                  <div className="mt-2">Descripción:</div>
                </GridItem>
                <GridItem xs={12} sm={5} style={{marginTop:"2em" }}>
                  <div className="mt-2">{this.state.VentaSeleccionada['NombreAlumno'] !== null ? this.state.VentaSeleccionada['NombreAlumno'] : "Sin Especificar"}</div>
                  <div className="mt-2">{moment(this.state.VentaSeleccionada['FechaPago']).format('DD-MM-YYYY')}</div>
                  <div style={{ marginTop:"2em" }}>{this.state.VentaSeleccionada['Cantidad']}</div>
                  <div className="mt-2">{this.state.VentaSeleccionada['MotivoCancelacion']}</div>
                  <div></div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        <GridItem>
            <Button tabIndex="1" size='sm' color='danger' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CERRAR</Button>
         </GridItem>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalInfoAdeudo);