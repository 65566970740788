import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx";
import Navbar from "components/Navbars/Navbar.jsx";
import logoadministracion from "assets/img/menuPrincipal/administracion.png";
import logoEgreso from "assets/img/menuPrincipal/budget.png"
import logoReportes from "assets/img/menuPrincipal/statistics.png"
import logoPresupuesto from "assets/img/menuPrincipal/money-balance.png"
import logoalumnos from "assets/img/menuPrincipal/group.png"
import logoalumnosAlt from "assets/img/menuPrincipal/language-learning.png"
import logoalumnosAlt2 from "assets/img/menuPrincipal/youth.png"
import logoProspectos from "assets/img/menuPrincipal/add-user.png"
import logoColaboradores from "assets/img/menuPrincipal/team.png"
// import logoCalificaciones from "assets/img/menuPrincipal/calificaciones.png"
import logoColaboradoresAlt from "assets/img/menuPrincipal/team(1).png"
import logoGastos from "assets/img/menuPrincipal/receipt3.png"
import logoCatalogo from "assets/img/menuPrincipal/catalog.png"
import logoCalificaciones from "assets/img/menuPrincipal/exam.png"
import Cargando from '../../components/Generales/ModalCargando';
import routesAdmin from "routes/routesAdministracion.js";
import routesCobranza from "routes/routesCobranza.js";
import routesReportes from "routes/routesReportes.js";
import routesGrupos from "routes/routesGrupos.js";
import routesAlumnos from "routes/routesAlumnos.js";
import routesCitas from "routes/routesCitas";
import routesProspectos from "routes/routesProspectos";
import routesColaboradores from "routes/routesColaboradores";
import routesCalificaciones from "routes/routesCalificaciones";
import routesGastos from "routes/routesGastos";
import routesCatalogos from "routes/routesCatalogos";
import routesInventario from "routes/routesInventario";
import routesAsistencia from "routes/routesAsistencia";

import logocalendario from "assets/img/menuPrincipal/calendario.png"
import logoIdiomas from "assets/img/menuPrincipal/idiomas.png"
import logoInventario from "assets/img/menuPrincipal/check-list.png"
import logoAsistencia from "assets/img/menuPrincipal/attendance.png"

import Snackbar from "components/Snackbar/Snackbar.jsx";
import CookiesU from 'universal-cookie';
const cookies = new CookiesU();

const style = {
  ...imagesStyles
};
const cookie = cookies.getAll()


class SectionCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      rutasAdministracion: [],
      rutasCobranza: [],
      rutasAlumnos: [],
      rutasReportes: [],
      rutasGrupos: [],
      rutasCitas: [],
      rutasProspectos: [],
      rutasColaboradores: [],
      rutasCalificaciones: [],
      rutasGasto: [],
      rutasCatalogos: [],
      rutasInventario: [],
      rutasAsistencia: [],
     
      MostrarModalCargando:false
    };
  }

  componentWillMount() {
    this.setState({
      rutasAdministracion: routesAdmin,
      rutasCobranza:routesCobranza,
      rutasReportes:routesReportes,
      rutasGrupos:routesGrupos,
      rutasAlumnos:routesAlumnos,
      rutasCitas:routesCitas,
      rutasProspectos:routesProspectos,
      rutasColaboradores:routesColaboradores,
      rutasCalificaciones:routesCalificaciones,
      rutasGasto:routesGastos,
      rutasCatalogos:routesCatalogos,
      rutasInventario: routesInventario,
      rutasAsistencia: routesAsistencia
    });
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentDidMount() {
    if (cookies.get('mensaje') !== undefined) {
      this.setState({ mensaje:   cookies.get('mensaje'), colorNotificacion: 'success',MostrarModalCargando:true })
      this.showNotification();
      cookies.remove('mensaje', { path: '' });
    }else{
      this.setState({MostrarModalCargando:false});
    }
  }

  showNotification() {  
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false   });
        this.setState(x);
      }.bind(this),
      2000
    );
  }

  CambiarNavegacion() {
    window.location.href = '#/admin/Administracion';
  }

  CambiarAsesorias() {
    window.location.href = '#/Citas/Citas';
  } 

  CambiarNavCobranza() {
    window.location.href = '#/cobranza/Cobranza';
  }

  CambiarNavProspecto() {
    window.location.href = '#/prospectos/Prospectos';
  }

  CambiarNavAlumnos() {
    window.location.href = '#/alumnos/Alumnos';
  }

  CambiarNavColaboradores() {
    window.location.href = '#/colaboradores/colaboradores';
  }

  CambiarNavGastos() {
    window.location.href = '#/gastos/Gastos';
  }

  CambiarNavPresupuestos() {
    window.location.href = '#/presupuestos/Presupuestos';
  }

  CambiarNavReportes() {
    window.location.href = '#/reportes/Reportes';
  }

  CambiarNavGrupos() {
    window.location.href = '#/Grupos/listado';
  }
  CambiarNavCalificaciones() {
    window.location.href = '#/calificaciones/Calificaciones';
  }
  CambiarNavCatalogos() {
    window.location.href = '#/Catalogos/Paises';
  }

  CambiarNavInventario() {
    window.location.href = '#/inventario/Inventario';
  }

  CambiarNavAsistencia() {
    window.location.href = '#/asistencia/Asistencia';
  }

 
  render() {

    const { classes, ...rest } = this.props;
    return (
      <div className="animated fadeIn">
        <Navbar
          routes={routesAdmin}
        />
        <Container>
          <div className="row mt-5">
            <div hidden={this.state.rutasAdministracion.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Administración</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavegacion.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoadministracion} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración general de la aplicación</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasCobranza.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Cobranza</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavCobranza.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoEgreso} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración de cobranza</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasCitas.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Calendario de citas</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarAsesorias.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logocalendario} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Asignación y calendarización de citas</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasReportes.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Reportes</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavReportes.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoReportes} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Generar reportes</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasProspectos.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Prospectos</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavProspecto.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoProspectos} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración de prospectos</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasGrupos.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Grupos</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavGrupos.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoIdiomas} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración de Grupos</a></center>
                    </CardBody>
                  </a>
                </div>
                
              </Card>
            </div>
            <div hidden={this.state.rutasAlumnos.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Alumnos</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavAlumnos.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoalumnosAlt} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración de alumnos</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasColaboradores.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Colaboradores</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavColaboradores.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoColaboradores} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración de colaboradores</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasCalificaciones.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Calificaciones</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavCalificaciones.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoCalificaciones} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Asignaciones de calificaciones</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasGasto.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Gastos</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavGastos.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoGastos} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración de gastos</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasAsistencia.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Asistencia</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavAsistencia.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoAsistencia} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración de asistencias</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasInventario.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Inventario</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavInventario.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoInventario} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración de inventarios</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasCatalogos.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Catálogos</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavCatalogos.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoCatalogo} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración de catálogos</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
          </div>
        </Container>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        {/* <Cargando
          show={this.state.MostrarModalCargando}
          Cuerpo="Cargando"
        /> */}
      </div >
    )
  }
}


export default withStyles(style)(SectionCards);