import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CardBody from "components/Card/CardBody.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Search, Close } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import ModalProductos from "./ModalProductos";

class CatProductos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      _idIdioma: "",
      _idTipoProducto: "",
      FiltrarNombres: "",
      ListaIdiomas: [],
      ListaTipoProductos: [
        { _id: "Libro", Descripcion: "Libro" },
        { _id: "Producto", Descripcion: "Producto" },
        { _id: "Otro", Descripcion: "Otro" },
        { _id: "TOEFL", Descripcion: "TOEFL" },
      ],
      ListaProductos: [],
      ListaProductosTemp: [],
      InformacionProducto: "",
      ProductoSeleccionado: {},
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      Total: 0,
      TotalSeleccionado: 0,
      Titulo: "Registrar un nuevo producto",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    this.ListaGeneralIdiomas();
    this.ListaGeneralProductos();
  }

  async ListaGeneralProductos() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Productos/ListaDeProductos", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
      //console.log(data);
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          if (data.ListaProductos.Producto.length === 0) {
            this.setState({
              colorNotificacion: "danger",
              mensaje: "No se encontraron coincidencias",
            });
            this.showNotification();
          } else {
            this.setState({
              ListaProductos: data.ListaProductos.Producto,
              ListaProductosTemp: data.ListaProductos.Producto,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ListaGeneralIdiomas() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Idiomas/ListaGeneralIdiomas", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          if (data.ListadeIdiomas.Idioma.length > 1) {
            this.setState({
              ListaIdiomas: data.ListadeIdiomas.Idioma,
              cargando: false,
            });
          } else {
            this.setState({
              ListaIdiomas: data.ListadeIdiomas.Idioma,
              cargando: false,
            });
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  FiltrarProductos(opcion, value) {
    let Data = [];
    if (opcion === 1) {
      Data = this.state.ListaProductosTemp.filter(
        (sa) => JSON.stringify(sa.IdIdioma) === JSON.stringify(value)
      );
      if (this.state._idTipoProducto) {
        Data = Data.filter((sa) => sa.Tipo === this.state._idTipoProducto);
      }
      if (this.state.FiltrarNombres) {
        Data = Data.filter((Produ) =>
          String(Produ.Descripcion)
            .toUpperCase()
            .includes(String(this.state.FiltrarNombres).toUpperCase())
        );
      }
    }
    if (opcion === 2) {
      Data = this.state.ListaProductosTemp.filter(
        (prod) => prod.Tipo === value
      );
      if (this.state._idIdioma) {
        Data = Data.filter(
          (sa) =>
            JSON.stringify(sa.IdIdioma) === JSON.stringify(this.state._idIdioma)
        );
      }
      if (this.state.FiltrarNombres) {
        Data = Data.filter((Produ) =>
          String(Produ.Descripcion)
            .toUpperCase()
            .includes(String(this.state.FiltrarNombres).toUpperCase())
        );
      }
    }
    if (opcion === 3) {
      Data = this.state.ListaProductosTemp.filter((Produ) =>
        String(Produ.Descripcion)
          .toUpperCase()
          .includes(String(value).toUpperCase())
      );
      if (this.state._idIdioma) {
        Data = Data.filter(
          (sa) =>
            JSON.stringify(sa.IdIdioma) === JSON.stringify(this.state._idIdioma)
        );
      }
      if (this.state._idTipoProducto) {
        Data = Data.filter((sa) => sa.Tipo === this.state._idTipoProducto);
      }
    }
    return Data;
  }

  eventoModalCancelar = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: false,
      Titulo: "",
    });
  };

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "FiltrarNombres") {
      const Dataorg = this.FiltrarProductos(3, value || "");
      if (value) {
        if(Dataorg.length === 0){
          this.setState({
            FiltrarNombres: value,
            ListaProductos: Dataorg,
            colorNotificacion: "danger",
            mensaje: "No se encontraron coincidencias",
          });
          this.showNotification();
        }
        else{
          this.setState({
            FiltrarNombres: value,
            ListaProductos: Dataorg,
          });
        }
      } else {
        this.setState({
          FiltrarNombres: "",
          ListaProductos: [...Dataorg],
        });
      }
    }

    if (name === "NombreIdioma") {
      const NuevosProductos = this.FiltrarProductos(1, value);
      this.setState({
        _idIdioma: value,
        ListaProductos: NuevosProductos,
      });
    }

    if (name === "NombreTP") {
      const NuevosProductos = this.FiltrarProductos(2, value);
      this.setState({
        _idTipoProducto: value,
        ListaProductos: NuevosProductos,
      });
    }
  };

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  eventoModalEliminar(e) {
    this.setState({ show: true, ProductoSeleccionado: e, _id: e });
    return;
  }

  eventoModalPregunta = (valor) => {
    if (valor) {
      this.eliminarProducto(this.state._id);
    } else {
      this.setState({ show: false });
    }
  };

  eliminarProducto(id) {
    this.setState({ cargando: true });
    fetch(Globales.Url + "Catalogos/Productos/EliminarProducto", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ _id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger" });
        } else {
          //Obtuvo los registros correctamente
          this.setState({ colorNotificacion: "success" });
        }
        this.setState({
          mensaje: data.mensaje
            ? data.mensaje
            : "El producto no puede ser eliminado.",
          show: false,
          _idIdioma: "",
          _idTipoProducto: "",
          FiltrarNombres: "",
        });
        this.showNotification();
        this.ListaGeneralProductos();
      });
  }

  openModalNuevoProducto = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Registrar nuevo producto",
      op: 0,
    });
  };

  openEditarProducto = (e) => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Modificar producto",
      op: 1,
      InformacionProducto: e,
    });
  };

  eventoModalGuardado = (mensaje, Color) => {
    this.setState({showAgregar: false});
    if(mensaje !== "esc"){
      this.setState({
        showAgregar: false,
        mensaje: mensaje,
        colorNotificacion: Color,
        show: false,
        _idIdioma: "",
        _idTipoProducto: "",
        FiltrarNombres: "",
        entroEliminar: true,
      });
      this.showNotification();
      this.ListaGeneralProductos();
    }
  };

  render() {
    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }

    if (this.state.showAgregar == true) {
      agregar = (
        <ModalProductos
          xs={18}
          Titulo={this.state.Titulo}
          Idiomas={this.state.ListaIdiomas}
          Productos={this.state.ListaProductosTemp}
          TipoProducto={this.state.ListaTipoProductos}
          showAgregar={this.state.showAgregar}
          InformacionProducto={this.state.InformacionProducto}
          eventClick={this.eventoModalGuardado}
          eventCancelar={this.eventoModalCancelar}
          op={this.state.op}
        />
      );
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text={true}>
                <h4 className={classes.cardTitle}>Filtros</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <FormControl
                      // fullWidth
                      className={classes.formControl}
                      size="small"
                      variant="outlined"
                      // style={{ marginBottom: "27px" }}
                    >
                      <InputLabel>Idioma</InputLabel>
                      <Select
                        value={this.state._idIdioma}
                        onChange={this.handleChange}
                        name="NombreIdioma"
                        size="small"
                        label="Idioma"
                        id="_id"
                        defaultValue={""}
                        style={{ width: "18.2vw", marginBottom: "10px" }}
                      >
                        <MenuItem disabled>Seleccione un idioma</MenuItem>
                        {this.state.ListaIdiomas.map((idioma, Index) => (
                          <MenuItem
                            key={Index + idioma.Nombre}
                            value={idioma._id}
                          >
                            {idioma.Nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl
                      // fullWidth
                      className={classes.formControl}
                      size="small"
                      variant="outlined"
                      // style={{ marginBottom: "27px" }}
                    >
                      <InputLabel>Tipo de producto</InputLabel>
                      <Select
                        value={this.state._idTipoProducto}
                        onChange={this.handleChange}
                        name="NombreTP"
                        id="_id"
                        size="small"
                        label="Tipo de producto"
                        defaultValue={""}
                        style={{ width: "18.2vw", marginBottom: "10px" }}
                      >
                        <MenuItem disabled>
                          Seleccione un tipo de producto
                        </MenuItem>
                        {this.state.ListaTipoProductos.map((TP, Index) => (
                          <MenuItem key={Index + TP.Descripcion} value={TP._id}>
                            {TP.Descripcion}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      id="FiltrarNombres"
                      style={{ width: "18.2vw" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Nombre del producto"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        onChange: this.handleChange.bind(this),
                        value: this.state.FiltrarNombres,
                        name: "FiltrarNombres",
                        id: "FiltrarNombres",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button
                      size="sm"
                      type="submit"
                      color="info"
                      round
                      className="pull-right"
                      onClick={() => this.openModalNuevoProducto()}
                    >
                      Nuevo
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de Productos</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4
                      className="pull-right"
                      style={{ marginRight: "15px", marginTop: 0 }}
                    >
                      {this.state.ListaProductos.length} Producto(s)
                    </h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="center">Nombre del producto</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ListaProductos.map((Prod, index) => {
                        return (
                          <tr key={Prod._id + "-" + index}>
                            <td onClick={() => this.openEditarProducto(Prod)} style={{verticalAlign: "middle", cursor: "default"}}>{Prod.Descripcion}</td>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>
                            <Button
                                color="danger"
                                round
                                justIcon
                                className="pull-right"
                                size="sm"
                                onClick={() =>
                                  this.eventoModalEliminar(Prod._id)
                                }
                              >
                                <Close />
                              </Button>
                              <Button
                                color="info"
                                round
                                justIcon
                                className="pull-right mr-2"
                                size="sm"
                                onClick={() => this.openEditarProducto(Prod)}
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar producto"}
            Cuerpo={"¿Está seguro de dar de baja el producto?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatProductos);
