import React, { Component } from "react";
import { Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from "../../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";

class ModalAgregarConcepto extends Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      Nombre: "",
      _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      idPadre: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      Conceptos: [],
      Activo: 1,
      disabled: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton: false,
    };
    this.RegistrarConcepto = this.RegistrarConcepto.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    const { Conceptos, idPadre } = this.props.InformacionModal;
    this.setState({
      Conceptos,
      idPadre,
    });
    //Verificar si es editar
    if (this.props.op == 1) {
      const { Nombre, _id } = this.props.InformacionModal;
      this.setState({
        Nombre: Nombre,
        _id: _id,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  RegistrarConcepto() {
    this.setState({
      disabled: true,
      MostrarModal: true,
      DesactivarBoton: true,
    });
    if (this.state.Nombre) {
      if (this.props.op == 1) {
        const ExisteUno = this.state.Conceptos.filter(
          (Concepto) =>
            String(Concepto.Nombre).toUpperCase() ===
              String(this.state.Nombre).toUpperCase() &&
            Concepto._id !== this.state._id
        );
        if (ExisteUno.length === 0) {
          fetch(
            Globales.Url +
              "Catalogos/ConceptodeDescuento/RegistrarConceptodeDescuento",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: Globales.token,
              },
              body: JSON.stringify({
                _id: this.state._id,
                Nombre: String(this.state.Nombre).toUpperCase(),
                Activo: this.state.Activo,
                idPadre: this.state.idPadre,
              }),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.error) {
                this.setState({
                  colorNotificacion: "danger",
                  mensaje: data.mensaje,
                  DesactivarBoton: false,
                  MostrarModal: false,
                  disabled: false,
                });
                this.showNotification();
              } else {
                this.setState({
                  colorNotificacion: "success",
                  DesactivarBoton: false,
                  Nombre: "",
                  disabled: false,
                  MostrarModal: true,
                });
                this.closeModal(data.mensaje, this.state.colorNotificacion);
              }
            })
            .catch((err) => console.error(err));
        } else {
          this.setState({
            colorNotificacion: "danger",
            mensaje: "El concepto ya se encuentra registrado.",
            DesactivarBoton: false,
            MostrarModal: false,
            disabled: false,
          });
          this.showNotification();
        }
      } else {
        const ExisteUno = this.state.Conceptos.filter(
          (Concepto) =>
            String(Concepto.Nombre).toUpperCase() ===
            String(this.state.Nombre).toUpperCase()
        );
        if (ExisteUno.length === 0) {
          fetch(
            Globales.Url +
              "Catalogos/ConceptodeDescuento/RegistrarConceptodeDescuento",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: Globales.token,
              },
              body: JSON.stringify({
                _id: this.state._id,
                Nombre: String(this.state.Nombre).toUpperCase(),
                Activo: this.state.Activo,
                idPadre: this.state.idPadre,
              }),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.error) {
                this.setState({
                  colorNotificacion: "danger",
                  mensaje: data.mensaje,
                  DesactivarBoton: false,
                  MostrarModal: false,
                  disabled: false,
                });
                this.showNotification();
              } else {
                this.setState({
                  colorNotificacion: "success",
                  DesactivarBoton: false,
                  Nombre: "",
                  disabled: false,
                  MostrarModal: true,
                });
                this.closeModal(data.mensaje, this.state.colorNotificacion);
              }
            })
            .catch((err) => console.error(err));
        } else {
          this.setState({
            colorNotificacion: "danger",
            mensaje: "El concepto ya se encuentra registrado.",
            DesactivarBoton: false,
            MostrarModal: false,
            disabled: false,
          });
          this.showNotification();
        }
      }
    } else {
      this.setState({
        colorNotificacion: "danger",
        mensaje: "Es necesario capturar el nombre del concepto.",
        DesactivarBoton: false,
        MostrarModal: false,
        disabled: false,
      });
      this.showNotification();
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(
      mensaje,
      colorNotificacion,
      this.props.InformacionModal.Tipo
    );
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (name === "Nombre") {
      this.setState({
        Nombre: value,
      });
    }
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    let agregar
    if (this.state.cargando == true) {
      agregar = (
        <Cargando show={this.state.MostrarModal} Cuerpo="Cargando" />
      );
    }
    return (
      <div>
        <Modal
          isOpen={this.props.showAgregar}
          className="static-modal"
          size="sx"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={1} sm={1} md={10} lg={10}>
                    <TextField
                      id="Nombre"
                      style={{ width: "23.8vw", marginBottom: "10px" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Nombre"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.Nombre,
                        name: "Nombre",
                        id: "Nombre",
                        error: this.state.errorUsuario,
                        success: !this.state.errorUsuario,
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    disabled={this.state.disabled}
                    size="sm"
                    color="info"
                    className="pull-right"
                    round
                    onClick={this.RegistrarConcepto.bind(this)}
                  >
                    <Save />
                    &nbsp;GUARDAR
                  </Button>
                  <Button
                    size="sm"
                    className="pull-right"
                    round
                    onClick={() => this.props.eventClick("esc")}
                  >
                    <Clear />
                    &nbsp;CANCELAR
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {agregar}
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAgregarConcepto);
