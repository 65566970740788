import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import Globales from "utils/Globales";
import { Edit, People, DoneOutline, GetApp, Close, AssignmentReturned, AddCircle, PlusOne, DoubleArrow, ArrowUpward, Clear, DoneAll, AssignmentTurnedIn } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import cookie from 'js-cookie'
import SeleccionarAlumno from "../../Generales/ComponentesEmpresas/SeleccionarMaestro.jsx"


class ReporteGastos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      MaestroSelecciondado: "",
      OpcionReportes: "",
      FechaInicio: moment().format("YYYY-MM-DD"),
      FechaFin: moment().format("YYYY-MM-DD"),
      errorFechainicio: false,
      errorFechaFin: false,
      colorNotificacion: 'success',
      colorTableRow: "white",
      //Mensaje del cargando default
      CuerpoCargando: "Cargando"
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);    
  }

  componentDidMount() {
  }


  handleCallback = (MaestroSelecciondado) =>{
    this.setState({MaestroSelecciondado: MaestroSelecciondado})
}

  async ListaLogCalificaciones() {
    this.setState({cargando: true});
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

    await fetch(Globales.Url + 'Reportes/Reportes/ListaLogCalificaciones', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idMaestro: Object.keys(this.state.MaestroSelecciondado).length !== 0 ? this.state.MaestroSelecciondado._id : idVacio, FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                this.DescargarPDF(data.ReporteExcel, 0);
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

async ListaLogAsistencia() {
  this.setState({cargando: true});
  const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
  
  await fetch(Globales.Url + 'Reportes/Reportes/ListaLogAsistencia', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idMaestro: Object.keys(this.state.MaestroSelecciondado).length !== 0 ? this.state.MaestroSelecciondado._id : idVacio, FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              this.DescargarPDF(data.ReporteExcel, 1);
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

DescargarPDF(archivoBase64, int) {
  let nombre;
  var ArchivoUTF8 = atob(archivoBase64)
  var universalBOM = "\uFEFF";
  const url = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+ArchivoUTF8)
  // const url = 'data:application/csv;base64,' + archivoBase64;
  if(int == 0){
    nombre = 'Reporte historial de calificaciones'
  }
  else{
    nombre = 'Reporte historial de asistencias'
  }
  
  const linkSource = `${url}`;
  const fileName = `${nombre}.csv`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  this.setState({
    cargando: false,
    mensaje: "El archivo se generó exitosamente",
    colorNotificacion: "success",
  });
  this.showNotification();
}

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;

    if(name == "OpcionReportes"){
      this.setState({ 
        [name]: value,
        MaestroSelecciondado: {},
        errorFechainicio: false,
        errorFechaFin: false,
      });
    }
    else{
      this.setState({ [name]: value });
    }
  }

  validarFechas(int){
    let valido = true;

    if(this.state.FechaInicio !== "" || this.state.FechaFin !== ""){
      if(this.state.FechaFin < this.state.FechaInicio){
        valido = false;
        this.setState({errorFechainicio: false, errorFechaFin: true, mensaje: "La fecha final no puede ser antes de la fecha de inicio", colorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        valido = true;
        this.setState({errorFechaFin: false});
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos", colorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
      switch(int){
        case 0:
          this.ListaLogCalificaciones();
          break;
        case 1:
          this.ListaLogAsistencia();
          break;
      }
    }
  }


  render() {
    const { OpcionReportes, MaestroSelecciondado } = this.state;
    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    let contenido;
    let Titulo = ""
    switch(OpcionReportes){
      case "Calificaciones":
        Titulo = "Reporte de capturas de calificaciones";
        contenido =
            <>
              <SeleccionarAlumno
                eventClick={this.handleCallback}         
              />
              <div>
              <div style={{fontWeight: "bold"}}>Seleccione el periodo</div>
              <TextField
                id="FechaInicio"
                style={{ width: "15em", marginTop: "10px", marginRight: "10px" }}
                variant="outlined"
                type="date"
                format="DD-MM-YYYY"
                size="small"
                label="Fecha inicio"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  onChange: this.handleChange.bind(this),
                  value: this.state.FechaInicio,
                  name: "FechaInicio",
                  id: "FechaInicio",
                  error: this.state.errorFechainicio,
                  success: !this.state.errorFechainicio
                }}
              />

              <TextField
                id="FechaFin"
                style={{ width: "15em", marginTop: "10px" }}
                variant="outlined"
                type="date"
                format="DD-MM-YYYY"
                size="small"
                label="Fecha fin"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  onChange: this.handleChange.bind(this),
                  value: this.state.FechaFin,
                  name: "FechaFin",
                  id: "FechaFin",
                  error: this.state.errorFechaFin,
                  success: !this.state.errorFechaFin
                }}
              />
              <Button size="sm" type="submit" color="info" round className="ml-3 mt-3" onClick={() => this.validarFechas(0)}><GetApp/>&nbsp;Exportar a excel</Button>
            </div>
            </>;
        break;
      case "Asistenacias":
        Titulo = "Reporte de capturas de asistencias";
        contenido =
            <>
              <SeleccionarAlumno
                eventClick={this.handleCallback}         
              />
              <div>
              <div style={{fontWeight: "bold"}}>Seleccione el periodo</div>
              <TextField
                id="FechaInicio"
                style={{ width: "15em", marginTop: "10px", marginRight: "10px" }}
                variant="outlined"
                type="date"
                format="DD-MM-YYYY"
                size="small"
                label="Fecha inicio"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  onChange: this.handleChange.bind(this),
                  value: this.state.FechaInicio,
                  name: "FechaInicio",
                  id: "FechaInicio",
                  error: this.state.errorFechainicio,
                  success: !this.state.errorFechainicio
                }}
              />

              <TextField
                id="FechaFin"
                style={{ width: "15em", marginTop: "10px" }}
                variant="outlined"
                type="date"
                format="DD-MM-YYYY"
                size="small"
                label="Fecha fin"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  onChange: this.handleChange.bind(this),
                  value: this.state.FechaFin,
                  name: "FechaFin",
                  id: "FechaFin",
                  error: this.state.errorFechaFin,
                  success: !this.state.errorFechaFin
                }}
              />
              <Button size="sm" type="submit" color="info" round className="ml-3 mt-3" onClick={() => this.validarFechas(1)}><GetApp/>&nbsp;Exportar a excel</Button>
            </div>
            </>;
        break;
    }

    //console.log(Campos)
    //console.log(MaestroSelecciondado);
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Reportes maestros</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="opciones">Tipo de reporte</InputLabel>
                        <Select
                            labelId="opciones"
                            //fullWidth={true}
                            value={this.state.OpcionReportes}
                            onChange={this.handleChange.bind(this)}
                            name="OpcionReportes"
                            label="Tipo de reporte"
                            style={{ width: "26.9em", marginTop: "10px" }}
                        >

                          <MenuItem disabled={true} value={""}>Seleccione un tipo de reporte</MenuItem>
                          <MenuItem value={"Calificaciones"}>Historial de calificaciones</MenuItem>
                          <MenuItem value={"Asistenacias"}>Historial de asistencias</MenuItem>

                        </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card hidden={this.state.OpcionReportes == ""}>
            <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>{Titulo}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {contenido}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar grupo"}
            Cuerpo={"¿Está seguro que desea eliminar grupo?"}
            show={this.state.show}
          eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(ReporteGastos);