import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalEnviarCorreoPrueba from "./ModalEnviarCorreoPrueba";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Search, Close, Save, Visibility, VisibilityOff, Email } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";


class CatUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      Configuracion: {},
      ServidorSMTP: "",
      Puerto: "",
      SSL: false,
      CuentaCorreo: "",
      Contraseña: "",
      Correo: "",
      Firma: "",
      DiasExt: "",
      DiasExt2: "",
      CantidadCorboExt: "",
      CantidadCorboExt2: "",
      ImprimirRecibo: false,
      ContraseñaVisible: false,

      errorServidor: false,
      errorPuerto: false,
      errorCuentaCorreo: false,
      errorContraseña: false,
      errorCorreo: false,
      errorFirma: false,
      errorDiasExt: false,
      errorCantidadCorboExt: false,
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    this.ObtenerConfiguracion();
  }

  async ObtenerConfiguracion() {
    this.setState({ cargando: true});
      await fetch(Globales.Url + "Configuracion/Configuracion/ObtenerConfiguracion", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({}),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje,colorNotificacion: "danger",cargando: false});
            this.showNotification();
          } else {
            let ssl;
            let Imprimir;

            if(data.Configuracion.SSL == "false"){
              ssl = false;
            }
            else if(data.Configuracion.SSL == "true"){
              ssl = true;
            }

            if(data.Configuracion.ImprimirInformacionEmpresa == "false"){
              Imprimir = false
            }
            else if(data.Configuracion.ImprimirInformacionEmpresa == "true"){
              Imprimir = true
            }

            this.setState({
              Configuracion: data.Configuracion,
              ServidorSMTP: data.Configuracion.Servidor !== null ? data.Configuracion.Servidor : "",
              Puerto: data.Configuracion.Puerto == "0" ? "" : data.Configuracion.Puerto,
              SSL: ssl,
              CuentaCorreo: data.Configuracion.Correo !== null ? data.Configuracion.Correo : "",
              Contraseña: data.Configuracion.Contrasena !== null ? data.Configuracion.Contrasena : "",
              Correo: data.Configuracion.CorreoConfirmacion !== null ? data.Configuracion.CorreoConfirmacion : "",
              Firma: data.Configuracion.FirmaCorreo !== null ? data.Configuracion.FirmaCorreo : "",
              DiasExt: data.Configuracion.DiasPagoExt !== null ? data.Configuracion.DiasPagoExt : "",
              DiasExt2: data.Configuracion.DiasPagoExt2 !== null ? data.Configuracion.DiasPagoExt2 : "",
              CantidadCorboExt: data.Configuracion.CantidadCobroExt !== null ? data.Configuracion.CantidadCobroExt : "",
              CantidadCorboExt2: data.Configuracion.CantidadCobroExt2 !== null ? data.Configuracion.CantidadCobroExt2 : "",
              ImprimirRecibo: Imprimir,
              cargando: false
            });
          }
        });
  }

  VerificarCorreo(){
    const IsEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let valido = true;

    if(this.state.Contraseña == ""){
      valido = false;
      this.setState({errorContraseña: true, mensaje: "Proporcione la contraseña", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      this.setState({errorContraseña: false})
    }

    if(this.state.CuentaCorreo !== ""){
      if(IsEmail.test(this.state.CuentaCorreo) === false){
        valido = false;
        this.setState({errorCuentaCorreo: true, mensaje: "Proporcione una cuenta de correo valida", colorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        this.setState({errorCuentaCorreo: false})
      }
    }
    else{
      valido = false;
      this.setState({errorCuentaCorreo: true, mensaje: "Proporcione una cuenta de correo valida", colorNotificacion: "danger"});
      this.showNotification();
    }

    if(this.state.Puerto == ""){
      valido = false;
      this.setState({errorPuerto: true, mensaje: "Proporcione el Puerto del Servidor", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      this.setState({errorPuerto: false})
    }

    if(this.state.ServidorSMTP == ""){
      valido = false;
      this.setState({errorServidor: true, mensaje: "Proporcione el Servidor SMTP", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      this.setState({errorServidor: false})
    }

    if(valido){
      return true
    }
  }

  async EnviarCorreoPrueba(correoReceptor) {
      this.setState({ cargando: true});
      let ObjCorreo = {
        Contraseña: this.state.Contraseña,
        Correo: this.state.CuentaCorreo,
        CorreoPruebas: correoReceptor,
        Nombreservidor: this.state.ServidorSMTP,
        Puerto: this.state.Puerto,
        SSL: this.state.SSL
      }
      this.setState({ cargando: true});
      await fetch(Globales.Url + "EnvioCorreos/EnvioCorreos/EnviarCorreo", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({Configuracion: ObjCorreo, EsPrueba: true}),
      })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error al obtener los registros
          this.setState({ mensaje: data.mensaje,colorNotificacion: "danger",cargando: false});
          this.showNotificationLargo();
        } else {
          
          this.setState({cargando: false, mensaje: data.mensaje, colorNotificacion: "success"});
          this.showNotification();
        }
      });
  }

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value, checked } = e.target;

    switch(name){
      case "ImprimirRecibo":
        if(checked){
          this.setState({ [name]: true, });
        }
        else{
          this.setState({ [name]: false, });
        }
        break;
      case "SSL":
        if(checked){
          this.setState({ [name]: true, });
        }
        else{
          this.setState({ [name]: false, });
        }
        break;
      case "Puerto":
        let puerto = value.replace(/[^0-9]/g, '');

        this.setState({ [name]: puerto });
        break;
      default:
        this.setState({ [name]: value, });
        break;
    }
  };

  handleFocus = (event) => event.target.select();

  handleBlur = (e) => {
    const {name, value} = e.target;

    //Quitar el punto decimal del valor
    let NoEsFloat = Math.trunc( value )

    if(NoEsFloat == ""){
      this.setState({[name]: 0});
    }
    else if(isNaN(NoEsFloat)){
      this.setState({[name]: 0});
    }
    else{
      this.setState({[name]: NoEsFloat});
    }
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  showNotificationLargo() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      8000
    );
  }

  Validaciones(){
    var valido = true
    const IsEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if(this.state.CantidadCorboExt == "" || this.state.CantidadCorboExt2 == ""){
      valido = false
      this.setState({errorCantidadCorboExt: true, mensaje: "Proporcione cantidad a añadir para el extratemporaneo", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      this.setState({errorCantidadCorboExt: false});
    }

    if(this.state.DiasExt == "" || this.state.DiasExt2 == ""){
      valido = false
      this.setState({errorDiasExt: true, mensaje: "Proporcione dias a trascurrir para aplicar el pago extratemporaneo", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      this.setState({errorDiasExt: false});
    }

    if(this.state.Correo !== ""){
      if(IsEmail.test(this.state.Correo) === false){
        valido = false
        this.setState({errorCorreo: true, mensaje: "Proporcione un correo valido", colorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        this.setState({errorCorreo: false});
      }
    }
    else{
      valido = false
      this.setState({errorCorreo: true, mensaje: "Proporcione correo copia", colorNotificacion: "danger"});
      this.showNotification();
    }

    // if(this.state.Contraseña == ""){
    //   valido = false;
    //   this.setState({errorContraseña: true, mensaje: "Proporcione la contraseña", colorNotificacion: "danger"});
    //   this.showNotification();
    // }
    // else{
    //   this.setState({errorContraseña: false})
    // }

    if(this.state.CuentaCorreo !== ""){
      if(IsEmail.test(this.state.CuentaCorreo) === false){
        valido = false;
        this.setState({errorCuentaCorreo: true, mensaje: "Proporcione una cuenta de correo valida", colorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        this.setState({errorCuentaCorreo: false});
      }
    }

    // if(this.state.Puerto == ""){
    //   valido = false;
    //   this.setState({errorPuerto: true, mensaje: "Proporcione el Puerto del Servidor", colorNotificacion: "danger"});
    //   this.showNotification();
    // }
    // else{
    //   this.setState({errorPuerto: false})
    // }

    // if(this.state.ServidorSMTP == ""){
    //   valido = false;
    //   this.setState({errorServidor: true, mensaje: "Proporcione el Servidor SMTP", colorNotificacion: "danger"});
    //   this.showNotification();
    // }
    // else{
    //   this.setState({errorServidor: false})
    // }

    if(valido){
      return true
    }
  }

  async RegistrarConfiguracion() {
    if(this.Validaciones()){
      let ObjConfiguracion = {
        CantidadCobroExt: this.state.CantidadCorboExt,
        CantidadCobroExt2: this.state.CantidadCorboExt2,
        ConsecutivoFolio: this.state.Configuracion.ConsecutivoFolio,
        ConsecutivoFolioDevolucion: this.state.Configuracion.ConsecutivoFolioDevolucion,
        ConsecutivoFolioReciboF: this.state.Configuracion.ConsecutivoFolioReciboF,
        ConsecutivoFolioReciboVirtual: this.state.Configuracion.ConsecutivoFolioReciboVirtual,
        ConsecutivoNumeroCuentAlumno: this.state.Configuracion.ConsecutivoNumeroCuentAlumno,
        ConsecutivoNumeroCuentaMaestro: this.state.Configuracion.ConsecutivoNumeroCuentaMaestro,
        Contrasena: this.state.Contraseña,
        Correo: this.state.CuentaCorreo,
        CorreoConfirmacion: this.state.Correo,
        DiasPagoExt: this.state.DiasExt,
        DiasPagoExt2: this.state.DiasExt2,
        FirmaCorreo: this.state.Firma,
        FirmaCorreoHtml: this.state.Configuracion.FirmaCorreoHtml == null ? "" : this.state.Configuracion.FirmaCorreoHtml,
        FolioRetiroCaja: this.state.Configuracion.FolioRetiroCaja,
        ImprimirInformacionEmpresa: this.state.ImprimirRecibo,
        Puerto: this.state.Puerto !== "" ? this.state.Puerto : 0,
        Reglamento: this.state.Configuracion.Reglamento,
        SSL: this.state.SSL,
        Servidor: this.state.ServidorSMTP,
        UltimoFolioCorteCaja: this.state.Configuracion.UltimoFolioCorteCaja,
        _id: this.state.Configuracion._id
      }

      this.setState({ cargando: true});
      await fetch(Globales.Url + "Configuracion/Configuracion/RegistrarConfiguracion", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({Configuracion: ObjConfiguracion}),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje,colorNotificacion: "danger",cargando: false});
            this.showNotification();
          } else {
            //Obtuvo los registros correctamente
            this.setState({mensaje: data.mensaje, colorNotificacion: "success", cargando: false});
            this.showNotification();
            this.ObtenerConfiguracion();
          }
        });
    }
  }

  handleClickShowPassword() {
    if(this.state.ContraseñaVisible){
      this.setState({ContraseñaVisible: false});
    }
    else{
      this.setState({ContraseñaVisible: true});
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  openModal(){
    if(this.VerificarCorreo()){
      this.setState({showModal: true});
    }
  }

  eventoModal = (value) => {
    this.setState({showModal: false});

    if(value !== false){
      //console.log(value)
      this.EnviarCorreoPrueba(value);
    }
  }

  render() {
    const { classes } = this.props;

    //console.log(this.state.Configuracion)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }

    if(this.state.showModal == true){
      agregar = (
        <ModalEnviarCorreoPrueba
          showModal={this.state.showModal}
          eventClick={this.eventoModal}
        />
      )
    }
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
          {/* <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Envío de correos</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      <TextField
                        id="ServidorSMTP"
                        style={{ width: "23vw", marginBottom: "10px"}}
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Servidor SMTP"
                        InputProps={{
                            onChange: this.handleChange.bind(this),
                            value: this.state.ServidorSMTP,
                            name: "ServidorSMTP",
                            id: "ServidorSMTP",
                            error: this.state.errorServidor,
                            success: !this.state.errorServidor
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        id="Puerto"
                        style={{ width: "10vw", marginBottom: "10px", marginRight: "10px"}}
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Puerto"
                        InputProps={{
                            onChange: this.handleChange.bind(this),
                            onFocus: this.handleFocus.bind(this),
                            onBlur: this.handleBlur.bind(this),
                            value: this.state.Puerto,
                            name: "Puerto",
                            id: "Puerto",
                            error: this.state.errorPuerto,
                            success: !this.state.errorPuerto
                        }}
                      />
                      <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.SSL}
                          onChange={this.handleChange.bind(this)}
                          name="SSL"
                          color="primary"
                        />
                      }
                        label="SSL"
                      />
                    </div>
                    <div>
                      <TextField
                        id="CuentaCorreo"
                        style={{ width: "23vw", marginBottom: "10px"}}
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Cuenta de correo"
                        InputProps={{
                            onChange: this.handleChange.bind(this),
                            value: this.state.CuentaCorreo,
                            name: "CuentaCorreo",
                            id: "CuentaCorreo",
                            error: this.state.errorCuentaCorreo,
                            success: !this.state.errorCuentaCorreo
                        }}
                      />
                    </div>
                    <div>
                      <FormControl variant="outlined" size="small" style={{ width: "23vw", marginBottom: "10px"}}>
                        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={this.state.ContraseñaVisible ? 'text' : 'password'}
                          value={this.state.Contraseña}
                          name="Contraseña"
                          onChange={this.handleChange.bind(this)}
                          error={this.state.errorContraseña}
                          success={!this.state.errorContraseña}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword.bind(this)}
                                onMouseDown={this.handleMouseDownPassword.bind(this)}
                                edge="end"
                              >
                                {this.state.ContraseñaVisible ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                  <Button className="pull-left" color="info"round size="sm" onClick={() =>this.openModal()}><Email />Enviar correo de prueba</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card> */}
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Cuenta de correo que recibirá todo correo saliente de parte de la escuela</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="Correo"
                      style={{ width: "23vw"}}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Correo"
                      InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.Correo,
                          name: "Correo",
                          id: "Correo",
                          error: this.state.errorCorreo,
                          success: !this.state.errorCorreo
                      }}
                    />
                  </GridItem>
                  <GridItem style={{marginTop: "10px"}} xs={12} sm={12} md={6}>
                  <Button className="pull-left" color="info"round size="sm" onClick={() =>this.openModal()}><Email />Enviar correo de prueba</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Firma del envio de correo</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="Firma"
                      style={{ width: "23vw"}}
                      variant="outlined"
                      multiline
                      rows={4}
                      size="small"
                      label="Firma correo"
                      InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.Firma,
                          name: "Firma",
                          id: "Firma",
                          error: this.state.errorFirma,
                          success: !this.state.errorFirma
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Pago extemporaneo</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6} lg={4}>
                      <TextField
                        id="DiasExt"
                        style={{ marginBottom: "10px"}}
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Día para generar primer pago extemporaneo"
                        InputProps={{
                            onChange: this.handleChange.bind(this),
                            value: this.state.DiasExt,
                            name: "DiasExt",
                            id: "DiasExt",
                            error: this.state.errorDiasExt,
                            success: !this.state.errorDiasExt
                        }}
                      />
                      <TextField
                        id="CantidadCorboExt"
                        // style={{ width: "23vw"}}
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Cantidad pago primer extratemporaneo"
                        InputProps={{
                            onChange: this.handleChange.bind(this),
                            value: this.state.CantidadCorboExt,
                            name: "CantidadCorboExt",
                            id: "CantidadCorboExt",
                            error: this.state.errorCantidadCorboExt,
                            success: !this.state.errorCantidadCorboExt
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                    <TextField
                        id="DiasExt2"
                        style={{ marginBottom: "10px"}}
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Día para generar segundo pago extemporaneo"
                        InputProps={{
                            onChange: this.handleChange.bind(this),
                            value: this.state.DiasExt2,
                            name: "DiasExt2",
                            id: "DiasExt2",
                            error: this.state.errorDiasExt,
                            success: !this.state.errorDiasExt
                        }}
                      />
                      <TextField
                        id="CantidadCorboExt2"
                        // style={{ width: "23vw"}}
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Cantidad pago segundo extratemporaneo"
                        InputProps={{
                            onChange: this.handleChange.bind(this),
                            value: this.state.CantidadCorboExt2,
                            name: "CantidadCorboExt2",
                            id: "CantidadCorboExt2",
                            error: this.state.errorCantidadCorboExt,
                            success: !this.state.errorCantidadCorboExt
                        }}
                      />
                    </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Impresión de recibo</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.ImprimirRecibo}
                        onChange={this.handleChange.bind(this)}
                        name="ImprimirRecibo"
                        color="primary"
                      />
                    }
                    label="Imprimir información de la empresa"
                  />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Button className="pull-right" color="info"round size="sm" onClick={() =>this.RegistrarConfiguracion()}><Save />GUARDAR</Button>
          </GridItem>
        </GridContainer>
        <div id="">
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatUsuarios);
