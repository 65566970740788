import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalCrearProspectos from "./ModalCrearProspectos.jsx"
import Globales from "utils/Globales";
import { Edit, Info, InfoTwoTone, InfoOutlined, Close, AddCircle, Search, Clear, Filter9Plus, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'

class Prospectos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: '',    
            ListaProspectos: [],
            ListaProspectosTemp: [],
            FiltrarNombres: "",
            Disabled: false,
            mensaje: "",
            br: false,
            show: false,
            cargando: false,
            CheckAll: false,
            colorNotificacion: 'success',
            colorTableRow: "white",
            errorIDIdioma: false,
            entroFiltrar: false,
            notificacion: false,
            Total: 0,
            TotalSeleccionado: 0,
            CuerpoCargando: "Cargando",
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
        //this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.ListaGeneralProspectos();
        //this.EnvioCorreosProspectos();
        let IDcookie = JSON.parse(cookie.get('id'));
        this.setState({_id: IDcookie})
  }

    //Función para pasar datos de los alumnos desde el modal hacia la vista
    RegresarMensaje = (mensaje) => {
        this.setState({ mensaje: mensaje, colorNotificacion: "success" });
        this.showNotification();
        this.ListaGeneralProspectos();
    }

    async ListaGeneralProspectos() {
        this.setState({cargando: true, ListaProspectosTemp: []});
        await fetch(Globales.Url + 'Prospectos/Prospectos/ListaGeneralProspectosNoInscritosInformacionBasica', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify()
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    if (data.ListaProspectos.length > 1) {
                        this.setState({ ListaProspectos: data.ListaProspectos, ListaProspectosTemp: data.ListaProspectos, Activo: 1, cargando: false });
                    }
                    else {
                        let ListaAux = [];
                        ListaAux.push(data.ListaProspectos);
                        //console.log(ListaAux);
                        this.setState({ ListaProspectos: ListaAux, ListaProspectosTemp: ListaAux, Activo: 1, cargando: false });
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    ListaProspectosBusquedaFiltrada(texto) {
        //this.setState({cargando: true});
         fetch(Globales.Url + 'Prospectos/Prospectos/ListaProspectosBusquedaFiltrada', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({Texto: texto})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    if (data.ListaProspectos.length > 1) {
                        this.setState({ ListaProspectos: data.ListaProspectos, Activo: 1, cargando: false });
                    }else {
                        let ListaAux = [];
                        ListaAux.push(data.ListaProspectos);
                        //console.log(ListaAux);
                        this.setState({ ListaProspectos: ListaAux, Activo: 1, cargando: false });
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    // EnvioCorreosProspectos(texto) {
    //     //this.setState({cargando: true});
    //      fetch(Globales.Url + 'Prospectos/Prospectos/EnvioCorreosProspectos', {
    //         method: 'POST',
    //         headers: {
    //             Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
    //         }
    //         , body: JSON.stringify()
    //     }
    //     ).then(res => res.json())
    //         .then(data => {
    //             //console.log(data);
    //             if (data.error) { //Error en la respuesta
    //                 this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
    //                 this.showNotification();
    //             } else { //Filtrado exitoso
    //                 if (data.ListaProspectos.length > 1) {
    //                     this.setState({ ListaProspectos: data.ListaProspectos, Activo: 1, cargando: false });
    //                 }
    //                 else {
    //                     let ListaAux = [];
    //                     ListaAux.push(data.ListaProspectos);
    //                     //console.log(ListaAux);
    //                     this.setState({ ListaProspectos: ListaAux, Activo: 1, cargando: false });
    //                 }
    //             }
    //         })
    //     .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    // }

//Funciones para abrir modales
    openModalEstudiosAnte = () => {
        this.setState({  showEstudiosAnte: true, Titulo: "Agregar estudio anterior", op: 0 });
    }

    openModalEditarProspecto = (id) => {
        this.setState({ _id: id, showEditarProspecto: true, Titulo: "...", op: 1 });
    }

    openModalCrearProspecto = () => {
        this.setState({ showEditarProspecto: true, Titulo: "...", op: 0 });
    }

    openModalPregunta = () => {
        this.setState({ show: true });
    }

//Funciónes que recuperan valores para los modales

    eventoEditarProspeto = () => {
        //Cambiar valores por default
        this.setState({ showEditarProspecto: false, op: 0 })
        // if (mensaje != 'esc') {
        //     this.setState({ mensaje: "El prospecto se registró correctamente", colorNotificacion: "success" })
        //     this.showNotification();
        // }
    }

    eventoModalPregunta = (valor) => {
        this.setState({ show: false })
        if(valor){
            this.ActualizarAcreditacionVentas();
        }
    }

    //Función que habilita la notificación
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        //console.log(e.target);

        if(name == "FiltrarNombres"){
            if(value.length > 2){
                this.setState({ [name]: value });
                this.ListaProspectosBusquedaFiltrada(value)
            }
            else{
                this.setState({ [name]: value, ListaProspectos: this.state.ListaProspectosTemp });
            }
        }

        this.setState({ [name]: value });
    }

    render() {
    const { classes } = this.props;

        let agregar;
        if (this.state.showEditarProspecto == true) {
            agregar =
                <ModalCrearProspectos
                    Titulo={this.state.Titulo}
                    showEditarProspecto={this.state.showEditarProspecto}
                    eventClick={this.eventoEditarProspeto}
                    op={this.state.op}
                    _id={this.state._id}
                    parentCallback={this.RegresarMensaje}
                />
        }

        if (this.state.cargando == true) {
            agregar = (
              <ModalCargando
                Cuerpo={this.state.CuerpoCargando}
                show={this.state.cargando}
              />
            );
          }
        this.state.ListaProspectos.sort((a, b) => {
            let fa = a.ApellidoPaterno.toLowerCase(),
                fb = b.ApellidoPaterno.toLowerCase();
        
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });

        let prospectos;
          if(this.state.ListaProspectos.length > 0){
            prospectos =
            this.state.ListaProspectos.map(user => {
                return(
                    <tr style={{ cursor: "default"}} hover>
                        <td onClick={() => this.openModalEditarProspecto(user._id)} style={{verticalAlign: "middle"}}>{(user.ApellidoPaterno + " " + (user.ApellidoMaterno !== null && user.ApellidoMaterno !== undefined ? user.ApellidoMaterno : "") + " " + user.Nombre).toUpperCase()}</td>
                        <td>
                            <Button data-toggle="tooltip" data-placement="bottom" title="Editar prospecto" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalEditarProspecto(user._id)}><Edit /></Button>
                        </td>
                    </tr>
                )
            })
          }

    //console.log(this.state.ArrIdiomaSeleccionados)
    //console.log(this.state.ListaProspectos)

        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Buscar prospectos</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                            id="FiltrarNombres"
                                            style={{ width: "23vw"}}
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            label="Buscar..."
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search />
                                                    </InputAdornment>
                                                    ),
                                                onChange: this.handleChange.bind(this),
                                                value: this.state.FiltrarNombres,
                                                name: "FiltrarNombres",
                                                id: "FiltrarNombres",
                                                //error: this.state.errorMotivoCantacto,
                                                //success: !this.state.errorMotivoCantacto
                                            }}
                                            />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Button size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.openModalCrearProspecto()}><AddCircle/>&nbsp;Nuevo</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader color="info" text>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <h4 className="pull-left">Prospectos</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <h4 className="pull-right"  style={{marginRight: "15px", marginTop: 0}}>{this.state.ListaProspectos.length} Prospecto(s)</h4>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                                            <table className="table table-sm table-hover">
                                                <thead>
                                                    <th>Nombre</th>
                                                </thead>
                                                <tbody>
                                                {prospectos}
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <ModalPregunta
                        Titulo={"Acreditación"}
                        Cuerpo={"¿Esta seguro de quitar la acreditación a las ventas seleccionadas?"}
                        show={this.state.show}
                        eventClick={this.eventoModalPregunta}
                    />
                    {agregar}
                </div>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
                
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(Prospectos);