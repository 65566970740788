import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Add, Check } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';

const EnumMes = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
]
class ModalBuscarAlumnos extends Component {

  constructor(props, context) {
    super(props, context);
    // this.escFunction = this.escFunction.bind(this);
    this.state = {
      idiomaSeleccionado: "null",
      DiasExt: 0,
      AñoSeleccionado: new Date().getFullYear(),
      ListaIdiomas: [],
      AlumnoSeleciondado: {},
      ListaGrupos: [],
      ListaIdiomaGruposAlumno: [],
      Meses: [
        { Select: false, valor: 1, disabled: false },
        { Select: false, valor: 2, disabled: false },
        { Select: false, valor: 3, disabled: false },
        { Select: false, valor: 4, disabled: false },
        { Select: false, valor: 5, disabled: false },
        { Select: false, valor: 6, disabled: false },
        { Select: false, valor: 7, disabled: false },
        { Select: false, valor: 8, disabled: false },
        { Select: false, valor: 9, disabled: false },
        { Select: false, valor: 10, disabled: false },
        { Select: false, valor: 11, disabled: false },
        { Select: false, valor: 12, disabled: false },
      ],
      idconcepto: null,
      TipoConcepto: "Colegiatura",
      Cargando: false,
      PagosFuturos: [],
      grupos: [],
      PrecioConcepto: 0,
      CuerpoCargando: "Cargando",
      disabled: true

    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  CrearListaPeticion() {
    if (this.state.idiomaSeleccionado == "null") {
      this.setState({ colorNotificacion: 'danger', mensaje: 'Seleccione un idioma antes de continuar' });
      this.showNotification();
    }
    else {
      this.setState({ Cargando: true });
      var ListaPagosfuturos = []
      let DiasExtemp = 1;

      if(parseInt(this.props.AlumnoSeleciondado.DiaEspecialPago) !== 0){
        DiasExtemp = parseInt(this.props.AlumnoSeleciondado.DiaEspecialPago);
      }
      else{
        DiasExtemp = this.state.DiasExt;
      }
      //console.log("Meses: ",this.state.Meses)
      for (var i = 0; i < this.state.Meses.length; i++) {
        if (this.state.Meses[i].Select == true) { 
          let precio = 0;

          if(this.state.TipoConcepto == "Particular"){
            let diasMes = 0;
            let acumuladoDiasSemana = 0;

            if (this.state.idiomaSeleccionado.HorarioGrupo.Lunes == "true") {
              diasMes += this.getAcumuladoDiasSemanas(1, (this.state.Meses[i].valor - 1));
              acumuladoDiasSemana += 1;
            }
            if (this.state.idiomaSeleccionado.HorarioGrupo.Martes == "true") {
              diasMes += this.getAcumuladoDiasSemanas(2, (this.state.Meses[i].valor - 1));
              acumuladoDiasSemana += 1;
            }
            if (this.state.idiomaSeleccionado.HorarioGrupo.Miercoles == "true") {
              diasMes += this.getAcumuladoDiasSemanas(3, (this.state.Meses[i].valor - 1));
              acumuladoDiasSemana += 1;
            }
            if (this.state.idiomaSeleccionado.HorarioGrupo.Jueves == "true") {
              diasMes += this.getAcumuladoDiasSemanas(4, (this.state.Meses[i].valor - 1));
              acumuladoDiasSemana += 1;
            }
            if (this.state.idiomaSeleccionado.HorarioGrupo.Viernes == "true") {
              diasMes += this.getAcumuladoDiasSemanas(5, (this.state.Meses[i].valor - 1));
              acumuladoDiasSemana += 1;
            }
            if (this.state.idiomaSeleccionado.HorarioGrupo.Sabado == "true") {
              diasMes += this.getAcumuladoDiasSemanas(6, (this.state.Meses[i].valor - 1));
              acumuladoDiasSemana += 1;
            }
  
            let HoraPorDia = parseInt(this.state.idiomaSeleccionado.TotalHorasSemana) / acumuladoDiasSemana;
  
            let HorasPorMes = HoraPorDia * diasMes;

            precio = (this.state.PrecioConcepto * HorasPorMes).toFixed(2);
          }
          else{
            precio = this.state.PrecioConcepto
          }

          var pagofuturo = {
            Cantidad: "1",
            Correo: null,
            Devolucion: "false",
            Estado: "No_Pagado",
            EstadoAlumno: "Activo",
            ExtPagado: "false",
            FechaAcreditacion: "0001-01-01T00:00:00",
            FechaCancelacion: "0001-01-01T00:00:00",
            FechaPagado: "0001-01-01T00:00:00",
            FechaPago: new Date(this.state.AñoSeleccionado, (this.state.Meses[i].valor - 1), DiasExtemp).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaRegistro: new Date(this.state.AñoSeleccionado, (this.state.Meses[i].valor - 1), 2).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            IVA: "0",
            Monto: precio,
            MontoTotal: precio,
            NombreAlumno: null,
            NombreConcepto: this.state.TipoConcepto + " " + this.state.idiomaSeleccionado.Nombre + " (" + EnumMes[(this.state.Meses[i].valor - 1)] + " " + this.state.AñoSeleccionado + ")",
            OrdenNivel: "0",
            Telefono: null,
            TieneDescuento: "false",
            TipoConcepto: this.state.TipoConcepto,
            Vencido: "false",
            _idAlumno: this.props.AlumnoSeleciondado._id,
            _idConcepto: this.state.idconcepto,
            _idGrupo: this.state.idiomaSeleccionado.idGrupo,
            _idIdioma: this.state.idiomaSeleccionado._id,
            _idUsuarioAutorizo: { _increment: '0', _machine: '0', _pid: '0', _timestamp: '0' },
            _idUsuarioCancelo: { _increment: '0', _machine: '0', _pid: '0', _timestamp: '0' },
          }
          // console.log("pagofuturo",pagofuturo)
          ListaPagosfuturos.push(pagofuturo)
        }
      }
      // console.log("pagofuturo",ListaPagosfuturos)
      this.setState({
        PagosFuturos: ListaPagosfuturos
      }, () => {
        this.RegistrarPagoFuturo()
      })
    }
  }

  componentDidMount() {
    this.ObtenerConfiguracion()
    .then( () =>{
      this.obtenerIdiomas()
      this.setState({
        AlumnoSeleciondado: this.props.AlumnoSeleciondado._id,
        Cargando: true,
      })
    })
    //console.log("Alumno", this.props.AlumnoSeleciondado)
  }

  async ListaPagosFuturosRealizadosyAbonadosAlumno() {
    this.setState({ Cargando: true });
    await fetch(Globales.Url + 'Cobranza/Cobranza/ListaPagosFuturosRealizadosyAbonadosAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idAlumno: this.state.AlumnoSeleciondado, idConcepto: this.state.idconcepto, año: this.state.AñoSeleccionado })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          let Meses = [
            { Select: false, valor: 1, disabled: false },
            { Select: false, valor: 2, disabled: false },
            { Select: false, valor: 3, disabled: false },
            { Select: false, valor: 4, disabled: false },
            { Select: false, valor: 5, disabled: false },
            { Select: false, valor: 6, disabled: false },
            { Select: false, valor: 7, disabled: false },
            { Select: false, valor: 8, disabled: false },
            { Select: false, valor: 9, disabled: false },
            { Select: false, valor: 10, disabled: false },
            { Select: false, valor: 11, disabled: false },
            { Select: false, valor: 12, disabled: false },
          ]
          this.setState({ Meses: Meses, mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, Cargando: false, disabled: false });
          // this.showNotification();
        } else { //Filtrado exitoso
          //console.log("API", data.Lista)
          let Meses = [
            { Select: false, valor: 1, disabled: false },
            { Select: false, valor: 2, disabled: false },
            { Select: false, valor: 3, disabled: false },
            { Select: false, valor: 4, disabled: false },
            { Select: false, valor: 5, disabled: false },
            { Select: false, valor: 6, disabled: false },
            { Select: false, valor: 7, disabled: false },
            { Select: false, valor: 8, disabled: false },
            { Select: false, valor: 9, disabled: false },
            { Select: false, valor: 10, disabled: false },
            { Select: false, valor: 11, disabled: false },
            { Select: false, valor: 12, disabled: false },
          ]
          if (data.Lista.length > 1) {
            data.Lista.map(pago => {
              // if(JSON.stringify(pago._idGrupo) === JSON.stringify(this.state.idiomaSeleccionado.idGrupo) && pago.Estado == "Pagado"){
              Meses[moment(pago.FechaRegistro).get("month")].disabled = true
              // }
            })
          } else {
            Meses[moment(data.Lista.FechaRegistro).get("month")].disabled = true
          }


          this.setState({ Cargando: false, Meses: Meses, disabled: false })
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", Cargando: true}));
  }

  async ObtenerConfiguracion() {
    this.setState({ Cargando: true });
    await fetch(Globales.Url + "Configuracion/Configuracion/ObtenerConfiguracion", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error al obtener los registros
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Cargando: false });
          this.showNotification();
        } else {
          this.setState({
            DiasExt: data.Configuracion.DiasPagoExt !== null ? parseInt(data.Configuracion.DiasPagoExt) : 0,
            Cargando: false
          });
        }
      });
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------
  async obtenerIdiomas() {
    this.setState({ Cargando: true });
    await fetch(Globales.Url + 'Idiomas/Idiomas/ListaGeneralIdiomas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idAlumno: this.props.AlumnoSeleciondado._id
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ Cargando: false, colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Filtrado exitoso
          //console.log('idiomas exitoso',data);
          this.setState({
            ListaIdiomas: data.data,
            Cargando: false
          }, () => { this.obtenerGrupo() })
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", Cargando: true }));
  }

  async obtenerGrupo() {
    //console.log("Entro en obtenergrupo")
    this.setState({ Cargando: true });
    await fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerInformacionAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        _id: this.props.AlumnoSeleciondado._id
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ Cargando: false, colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Filtrado exitoso
          // console.log("grupos", data.DetalleAlumno)
          var GruposEnCurso = []
          if (data.DetalleAlumno.ListaGrupos !== null && data.DetalleAlumno.ListaGrupos !== undefined) {
            if (data.DetalleAlumno.ListaGrupos.GrupoAlumno.length > 1) {
              data.DetalleAlumno.ListaGrupos.GrupoAlumno.map(grupo => {
                if (grupo.Estado == "En_Curso") {
                  GruposEnCurso.push(grupo)
                }
              })
            } else {
              if (data.DetalleAlumno.ListaGrupos.GrupoAlumno.Estado == "En_Curso") {
                GruposEnCurso.push(data.DetalleAlumno.ListaGrupos.GrupoAlumno);
              }
            }

            if (GruposEnCurso.length == 0) {
              this.setState({
                Cargando: false,
                mensaje: "Este alumno no está inscrito en ningún idioma",
                colorNotificacion: "danger",
                ListaIdiomas: []
              })
              this.showNotification();
            } else {

              for (let i = 0; i < GruposEnCurso.length; i++) {
                this.ObtenerInformacionGrupo(GruposEnCurso[i]._idGrupo)
              }

              this.setState({ Cargando: false, });
            }
          }
          else {
            this.setState({
              Cargando: false,
              mensaje: "Este alumno no está inscrito en ningún idioma",
              colorNotificacion: "danger",
              ListaIdiomas: []
            })
            this.showNotification();
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", Cargando: true }));
  }

  getAcumuladoDiasSemanas(DiaSemana, mes) {
    var d = new Date(this.state.AñoSeleccionado, mes, 1),
        month = d.getMonth(),
        mondays = [];

    // d.setDate(1);

    // Get the first Monday in the month
    while (d.getDay() !== DiaSemana) {
        d.setDate(d.getDate() + 1);
    }

    // Get all the other Mondays in the month
    while (d.getMonth() === month) {
        mondays.push(new Date(d.getTime()));
        d.setDate(d.getDate() + 7);
    }

    return mondays.length;
}

  async ObtenerConceptosIdioma(id, TipoConcepto) {
    this.setState({ Cargando: true });
    await fetch(Globales.Url + 'Cobranza/Cobranza/ListaConceptoPagoIdioma', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idIdioma: id,
        TipoConcepto: TipoConcepto
      })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, Cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          let precio = 0;
          // let diasMes = 0;
          // let acumuladoDiasSemana = 0;

          switch (TipoConcepto) {
            case "Colegiatura":
              if(data.data.CobroColegiatura !== undefined && data.data.CobroColegiatura !== null){
                for(let i = 0; i < data.data.CobroColegiatura.CobroColegiatura.length; i++){
                  if(data.data.CobroColegiatura.CobroColegiatura[i].Clase == this.state.idiomaSeleccionado.Clase){
                    if(parseInt(this.state.idiomaSeleccionado.TotalHorasSemana) == 3){
                      precio = data.data.CobroColegiatura.CobroColegiatura[i].Costo3Hrs
                    }
                    else if(parseInt(this.state.idiomaSeleccionado.TotalHorasSemana) == 4){
                      precio = data.data.CobroColegiatura.CobroColegiatura[i].Costo4Hrs
                    }
                    else if(parseInt(this.state.idiomaSeleccionado.TotalHorasSemana) == 5){
                      precio = data.data.CobroColegiatura.CobroColegiatura[i].Costo5Hrs
                    }
                    else if(parseInt(this.state.idiomaSeleccionado.TotalHorasSemana) == 6){
                      precio = data.data.CobroColegiatura.CobroColegiatura[i].Costo6Hrs
                    }
                    break;
                  }
                }

                this.setState({ idconcepto: data.data._id, PrecioConcepto: precio, Cargando: false }, () => { this.ListaPagosFuturosRealizadosyAbonadosAlumno() });
              }
              else{
                this.setState({ mensaje: "Este idioma no tiene concepto para colegiaturas registrado", colorNotificacion: "danger", Cargando: false });
                this.showNotification();
              }
              break;
            case "Particular":
              if(parseFloat(data.data.CobroUnico) == 0){
                this.setState({ mensaje: "Este idioma no tiene concepto para clase particular registrado", colorNotificacion: "danger", Cargando: false });
                this.showNotification();
              }
              else{
                // if(this.state.idiomaSeleccionado.HorarioGrupo.Lunes == "true"){
                //   diasMes += this.getAcumuladoDiasSemanas(1);
                //   acumuladoDiasSemana += 1;
                // }
                // if(this.state.idiomaSeleccionado.HorarioGrupo.Martes == "true"){
                //   diasMes += this.getAcumuladoDiasSemanas(2);
                //   acumuladoDiasSemana += 1;
                // }
                // if(this.state.idiomaSeleccionado.HorarioGrupo.Miercoles == "true"){
                //   diasMes += this.getAcumuladoDiasSemanas(3);
                //   acumuladoDiasSemana += 1;
                // }
                // if(this.state.idiomaSeleccionado.HorarioGrupo.Jueves == "true"){
                //   diasMes += this.getAcumuladoDiasSemanas(4);
                //   acumuladoDiasSemana += 1;
                // }
                // if(this.state.idiomaSeleccionado.HorarioGrupo.Viernes == "true"){
                //   diasMes += this.getAcumuladoDiasSemanas(5);
                //   acumuladoDiasSemana += 1;
                // }
                // if(this.state.idiomaSeleccionado.HorarioGrupo.Sabado == "true"){
                //   diasMes += this.getAcumuladoDiasSemanas(6);
                //   acumuladoDiasSemana += 1;
                // }
  
                // let HoraPorDia = parseInt(this.state.idiomaSeleccionado.TotalHorasSemana) / acumuladoDiasSemana;
  
                // let HorasPorMes = HoraPorDia * diasMes;


                this.setState({ idconcepto: data.data._id, PrecioConcepto: data.data.CobroUnico, Cargando: false }, () => { this.ListaPagosFuturosRealizadosyAbonadosAlumno() });
              }
              break;
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", Cargando: true}));

  }

  async RegistrarPagoFuturo() {
    this.setState({ Cargando: true });
    await fetch(Globales.Url + 'Cobranza/Cobranza/RegistrarPagoAlumnos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        Pagos: this.state.PagosFuturos,
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ Cargando: false, colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Filtrado exitoso
          this.setState({ Cargando: false });
          this.closeModal("Colegiatura generada exitosamente")
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", Cargando: true}));
  }

  async ObtenerInformacionGrupo(idgrupo) {
    this.setState({ Cargando: true });
    await fetch(Globales.Url + 'Calificaciones/Calificaciones/ObtenerInformacionGrupo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idGrupo: idgrupo
      })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log("ObtenerInformacionGrupo", data.InfoGrupo);
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, Cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          if (data.InfoGrupo.Curso == "Tradicional" || data.InfoGrupo.Curso == "ClaseParticular") {
            this.setState({
              Cargando: false,
              ListaGrupos: [...this.state.ListaGrupos, data.InfoGrupo]
            }, () => {
              this.FiltrarIdiomas()
            });
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", Cargando: true}));
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  SeleccionarAlumno(ListaAlumnos) {
    this.setState({ AlumnoSeleciondado: ListaAlumnos, op: 1 });
    this.props.parentCallback(ListaAlumnos);
    this.props.eventClick('esc');
  }

  closeModal(mensaje) {
    this.props.eventClick(mensaje);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value, id, checked } = e.target;
    if (name == "AñoSeleccionado") {
      if ((value == new Date().getFullYear() + 5)) {
        this.setState({ colorNotificacion: 'danger', mensaje: "No puede generar una colegiatura con tanta anticipacion" });
        this.showNotification();
      } else {
        if ((value < new Date().getFullYear())) {
          this.setState({ colorNotificacion: 'danger', mensaje: "Favor se seleccionar una año valido" });
          this.showNotification();
        } else {
          this.setState({
            [name]: value
          }, () => {
            if (this.state.idiomaSeleccionado != "null") {
              this.ListaPagosFuturosRealizadosyAbonadosAlumno()
            }
          });

        }
      }
    } else if (name == "Meses") {
      var meses = this.state.Meses

      meses[id].Select = !meses[id].Select

      this.setState({
        Meses: meses,
      });
    } else if (name == "idiomaSeleccionado") {
      // console.log(value)
      if(parseInt(value.TotalHorasSemana) !== 0){
        let tipo = ""
        switch (value.Curso) {
          case "Tradicional":
            tipo = "Colegiatura";
            break;
          case "ClaseParticular":
            tipo = "Particular";
            break;
        }

        this.setState({ [name]: value, TipoConcepto: tipo }, () => { this.ObtenerConceptosIdioma(value._id, tipo) });
      }
      else{
        this.setState({
          [name]: value,
          Cargando: false,
          mensaje: "El grupo asociado a este idioma no tiene horas por semana registradas",
          colorNotificacion: "danger",
          disabled: true
        })
        this.showNotification();
      }
    }
    else {
      this.setState({ [name]: value });
    }
  }

  obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  }

  FiltrarIdiomas() {
    var ListaIdiomas = this.state.ListaIdiomas
    var ListaGrupo = this.state.ListaGrupos

    // console.log("ListaIdiomas", ListaIdiomas)
    // console.log("ListaGrupo", ListaGrupo)

    let aux = [];

    ListaGrupo.map(grupo => {
      ListaIdiomas.map(idioma => {
        if (JSON.stringify(grupo._idIdioma) === JSON.stringify(idioma._id)) {
          let obj = {
            Nombre: idioma.Nombre,
            _id: idioma._id,
            idGrupo: grupo._id,
            Curso: grupo.Curso,
            Clase: grupo.Clase,
            TotalHorasSemana: grupo.TotalHorasSemana,
            HorarioGrupo: grupo.HorarioGrupo
          }

          aux.push(obj)
        }
      })
    })

    this.setState({
      Cargando: false,
      ListaIdiomaGruposAlumno: aux,
    })
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;

    let agregar;
    if(this.state.Cargando){
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.Cargando}
        />
      )
    }

    // console.log("ListaGrupos",this.state.ListaGrupos)
    // console.log("ListaIdiomaGruposAlumno",this.state.ListaIdiomaGruposAlumno)
    //console.log("idiomaSeleccionado",this.state.idiomaSeleccionado)
    return (
      <div>
        <Modal isOpen={this.props.show} className="static-modal" size="md" toggle={this.cerrarModal} backdrop={true}>
          <Card >
            <CardHeader color="info">
              Colegiatura Adelantada
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Select
                    value={this.state.idiomaSeleccionado}
                    onChange={this.handleChange}
                    name="idiomaSeleccionado"
                    fullWidth={true}
                    variant="outlined"
                    // label="Productos"
                    style={{ marginTop: "25px" }}
                  //error={this.state.errorUsuarios}
                  //success={!this.state.errorUsuarios}
                  >
                    <MenuItem value={"null"} disabled={true}>Selecionar idioma</MenuItem>
                    {this.state.ListaIdiomaGruposAlumno.length == 0 ? null :
                      this.state.ListaIdiomaGruposAlumno.map((idioma) => {
                        return (<MenuItem value={idioma} >{idioma.Nombre}</MenuItem>)
                      })}
                  </Select>
                </GridItem>
              </GridContainer>
              <GridContainer xs={12} sm={12} md={12} lg={12}>
                <GridItem style={{ margin: '0 auto' }} xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="Año de la inscripción"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.AñoSeleccionado,
                      name: "AñoSeleccionado",
                      id: "AñoSeleccionado",
                      type: "number",
                      error: this.state.errorNumFactura,
                      success: !this.state.errorNumFactura
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer id="ContenedorMeses" xs={12} sm={12} md={12} lg={12}>
                <table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[0].Select}
                          onChange={this.handleChange}
                          id={0}
                          disabled={this.state.Meses[0].disabled || this.state.disabled}
                        />
                        Enero
                      </td>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[6].Select}
                          onChange={this.handleChange}
                          id={6}
                          disabled={this.state.Meses[6].disabled || this.state.disabled}
                        />
                        Julio
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[1].Select}
                          onChange={this.handleChange}
                          id={1}
                          disabled={this.state.Meses[1].disabled || this.state.disabled}
                        />
                        Febrero
                      </td>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[7].Select}
                          onChange={this.handleChange}
                          id={7}
                          disabled={this.state.Meses[7].disabled || this.state.disabled}
                        />
                        Agosto
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[2].Select}
                          onChange={this.handleChange}
                          id={2}
                          disabled={this.state.Meses[2].disabled || this.state.disabled}
                        />
                        Marzo
                      </td>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[8].Select}
                          onChange={this.handleChange}
                          id={8}
                          disabled={this.state.Meses[8].disabled || this.state.disabled}
                        />
                        Septiembre
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[3].Select}
                          onChange={this.handleChange}
                          id={3}
                          disabled={this.state.Meses[3].disabled || this.state.disabled}
                        />
                        Abril
                      </td>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[9].Select}
                          onChange={this.handleChange}
                          id={9}
                          disabled={this.state.Meses[9].disabled || this.state.disabled}
                        />
                        Octubre
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[4].Select}
                          onChange={this.handleChange}
                          id={4}
                          disabled={this.state.Meses[4].disabled || this.state.disabled}
                        />
                        Mayo
                      </td>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[10].Select}
                          onChange={this.handleChange}
                          id={10}
                          disabled={this.state.Meses[10].disabled || this.state.disabled}
                        />
                        Noviembre
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[5].Select}
                          onChange={this.handleChange}
                          id={5}
                          disabled={this.state.Meses[5].disabled || this.state.disabled}
                        />
                        Junio
                      </td>
                      <td>
                        <Checkbox
                          name={"Meses"}
                          value={this.state.Meses[11].Select}
                          onChange={this.handleChange}
                          id={11}
                          disabled={this.state.Meses[11].disabled || this.state.disabled}
                        />
                        Diciembre
                      </td>
                    </tr>
                  </tbody>
                </table>
              </GridContainer>
              <GridContainer xs={12} sm={12} md={12} lg={12}>
                <GridItem xs={12} sm={12} md={12}>
                  <Button className="pull-right" color="info" disabled={this.state.disabled} round size="sm" onClick={() => this.CrearListaPeticion()}><Check /> Aceptar</Button>
                  <Button className="pull-right mr-2" round size="sm" onClick={() => this.closeModal("esc")}><Close /> Cerrar </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>

        <div id="">
          {agregar}
        </div>

      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalBuscarAlumnos);