import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CardBody from "components/Card/CardBody.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import ModalNivel from "./ModalNivelAgregar";
import Globales from "utils/Globales";
import { Edit, Search, Close } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";


class CatUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      _idIdioma: "",
      FiltrarNombres: "",
      ListaNiveles: [],
      ListaIdiomas: [],
      ListaNivelesTemp: [],
      NivelSeleccionado: {},
      ClaseNivel: "TODO",
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      Total: 0,
      TotalSeleccionado: 0,
      Titulo: "Registrar un nuevo nivel",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    this.ListaGeneralIdiomas();
  }

  async ListaGeneralIdiomas() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Idiomas/ListaGeneralIdiomas", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          if (data.ListadeIdiomas.Idioma.length > 1) {
            this.setState({
              ListaIdiomas: data.ListadeIdiomas.Idioma,
              cargando: false,
              entroEliminar: false,
            });
          } else {
            this.setState({
              ListaIdiomas: data.ListadeIdiomas.Idioma,
              cargando: false,
              entroEliminar: false,
            });
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ListaGeneralNiveles() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Niveles/ListaGeneralNivelesClase", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ 
        _id: this.state._idIdioma,
        Clase: this.state.ClaseNivel == "TODO" ? null : this.state.ClaseNivel,
       }),
    })
      .then((res) => res.json())
      .then((data) => {
      //console.log(data)
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          
          const TipoData = Array.isArray(data.ListaNiveles);
          const NivelData = TipoData
            ? data.ListaNiveles
            : [{ ...data.ListaNiveles }];
          if (NivelData.length > 0) {
            let NivelOrdenados = NivelData.sort((a, b) => parseFloat(a.Orden) - parseFloat(b.Orden))
            this.setState({
              ListaNiveles: NivelOrdenados,
              ListaNivelesTemp: NivelOrdenados,
              Activo: NivelData.length,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });

          } else {
            this.setState({
              ListaNiveles: [],
              ListaNivelesTemp: [],
              Activo: 0,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
              colorNotificacion: "danger",
              mensaje: "El idioma no cuenta con niveles asignados",
            });
            this.showNotification();
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "FiltrarNombres") {
      const Dataorg = this.state.ListaNivelesTemp;
      if (value !== "") {
        const NuevaData = Dataorg.filter((Nivel) =>
          String(Nivel.Nombre)
            .toUpperCase()
            .includes(String(value).toUpperCase())
        );

        if(NuevaData.length === 0){
          this.setState({
            FiltrarNombres: value,
            ListaNiveles: NuevaData,
            colorNotificacion: "danger",
            mensaje: "No se encontraron coincidencias",
          });
          this.showNotification();
        }
        else{
          this.setState({
            FiltrarNombres: value,
            ListaNiveles: NuevaData,
          });
        }
        
      } else {
        this.setState({
          FiltrarNombres: "",
          ListaNiveles: [...Dataorg],
        });
      }
    }
    if (name === "_idIdioma") {
      if (value) {
        this.setState({
          _idIdioma: value,
          ListaNiveles: [],
        }, () => this.ListaGeneralNiveles());
        
      } else {
        this.setState({
          _idIdioma: "",
          ListaNiveles: [],
        });
      }
    }
    if(name === "ClaseNivel"){
      this.setState({
        ClaseNivel: value,
        ListaNiveles: [],
      }, () => this.ListaGeneralNiveles());
    }
  };

  eventoModalEliminar(e) {
    this.setState({ show: true, NivelSeleccionado: e });
    return;
  }

  eliminarNivel(id) {
    fetch(Globales.Url + "Catalogos/Niveles/EliminarInformacionNivel", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ _id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger" });
        } else {
          //Obtuvo los registros correctamente
          this.setState({ colorNotificacion: "success" });
        }
        this.setState({
          mensaje: data.mensaje,
          show: false,
          entroEliminar: true,
        });
        this.showNotification();
        this.ListaGeneralNiveles(this.state._idIdioma);
      });
  }

  eventoModalPregunta = (valor) => {
    if (valor) {
      this.eliminarNivel(this.state.NivelSeleccionado);
    } else {
      this.setState({ show: false });
    }
  };

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  eventoModalNivel = (mensaje, Color) => {
    this.setState({showAgregar: false})
    //Cambiar valores por default
    if(mensaje !== "esc"){
      this.setState({
        mensaje: mensaje,
        colorNotificacion: Color,
        op: 0,
        ClaseNivel: "TODO",
      });
      this.showNotification();
      this.ListaGeneralNiveles();
    }
  };

  openModalNuevoNivel = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Nuevo nivel",
      op: 0,
    });
  };

  eventoModalEditar = (e) => {
    //Cambiar valores por default
    //console.log(e);
    this.setState({
      InfoNivel: e,
      _id: e._id,
      Titulo: "Modificar nivel",
      op: 1,
      showAgregar: true,
    });
  };

  render() {
    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }

    if (this.state.showAgregar == true) {
      agregar = (
        <ModalNivel
          xs={18}
          Titulo={this.state.Titulo}
          Idioma={this.state._idIdioma}
          InfoNivel={this.state.InfoNivel}
          Niveles={this.state.ListaNivelesTemp}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalNivel}
          op={this.state.op}
        />
      );
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text={true}>
                <h4 className={classes.cardTitle}>Filtros</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      // fullWidth
                      className={classes.formControl}
                      size="small"
                      variant="outlined"
                      // style={{ marginBottom: "27px" }}
                    >
                      <InputLabel>Idioma</InputLabel>
                      <Select
                        value={this.state._idIdioma}
                        size="small"
                        label="Idioma"
                        onChange={this.handleChange}
                        name="_idIdioma"
                        id="_idIdioma"
                        defaultValue={""}
                        style={{ width: "20vw", marginBottom: "10px" }}
                      >
                        <MenuItem disabled>Seleccione un idioma</MenuItem>
                        {this.state.ListaIdiomas.map((Idioma, Index) => (
                          <MenuItem
                            key={Index + Idioma.Nombre}
                            value={Idioma._id}
                          >
                            {Idioma.Nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* <FormControl
                      // fullWidth
                      className={classes.formControl}
                      size="small"
                      variant="outlined"
                      // style={{ marginBottom: "27px" }}
                    >
                      <InputLabel style={{marginLeft: "10px"}}>Clase</InputLabel>
                      <Select
                        value={this.state.ClaseNivel}
                        size="small"
                        label="Clase"
                        onChange={this.handleChange}
                        name="ClaseNivel"
                        id="ClaseNivel"
                        style={{ width: "8vw", marginBottom: "10px", marginLeft: "10px" }}
                      >
                        <MenuItem disabled>Seleccione una clase</MenuItem>
                        <MenuItem value={"TODO"}>Todos</MenuItem>
                        <MenuItem value={"Adulto"}>Adulto</MenuItem>
                        <MenuItem value={"Adolescente"}>Adolescente</MenuItem>
                        <MenuItem value={"Niño"}>Niño</MenuItem>
                      </Select>
                    </FormControl> */}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="FiltrarNombres"
                      style={{ width: "20vw" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Nombre del nivel"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        onChange: this.handleChange.bind(this),
                        value: this.state.FiltrarNombres,
                        name: "FiltrarNombres",
                        id: "FiltrarNombres",
                      }}
                    />
                    {/* <Button
                      size="sm"
                      type="submit"
                      color="info"
                      round
                      className="ml-2"
                      onClick={() => this.ListaGeneralNiveles()}
                    >
                      <Search />Buscar
                    </Button> */}
                    <Button
                      size="sm"
                      type="submit"
                      color="info"
                      round
                      className="pull-right"
                      disabled={!this.state._idIdioma}
                      onClick={() => this.openModalNuevoNivel()}
                    >
                      Nuevo
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de niveles</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4
                      className="pull-right"
                      style={{ marginRight: "15px", marginTop: 0 }}
                    >
                      {this.state.ListaNiveles.length} Nivel(es)
                    </h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                    <thead>
                      <th>Nombre del nivel</th>
                      <th></th>
                    </thead>
                    <tbody>
                      {this.state.ListaNiveles.map((Nivel, index) => {
                        return (
                          <tr key={Nivel._id + "-" + index}>
                            <td onClick={() => this.eventoModalEditar(Nivel)} style={{verticalAlign: "middle", cursor: "default"}}>{Nivel.Nombre}</td>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>
                            <Button
                                color="danger"
                                round
                                justIcon
                                className="pull-right"
                                size="sm"
                                onClick={() =>
                                  this.eventoModalEliminar(Nivel._id)
                                }
                              >
                                <Close />
                              </Button>
                              <Button
                                color="info"
                                round
                                justIcon
                                className="pull-right mr-2"
                                size="sm"
                                onClick={() => this.eventoModalEditar(Nivel)}
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar Nivel"}
            Cuerpo={"¿Está seguro de dar de baja el nivel?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatUsuarios);
