import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import Button from "components/CustomButtons/Button.jsx";

import { Notifications, Info, Delete } from "@material-ui/icons";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../Generales/ModalCargando"
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Globales from "../../utils/Globales";
import CookiesU from 'universal-cookie';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
const cookies = new CookiesU();

var Permisos = localStorage.getItem("Permisos") == null ? { Modulo:[] } : JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}
else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

var usuario = JSON.parse(localStorage.getItem("NombreUsuario"));
class HeaderLinks extends React.Component {
  state = {
    openCerrarSesion: false,
    open: false,
    openNotifcation: false,
    openProfile: false,
    openNotificacion: false,
    NumNotificaciones: 0,
    ListaNotificaciones: [],
    CuerpoCargando: "Cargando",
    cargando: false
  };

  componentDidMount() {
    this.ObtenerNotificacionesNoVistas();
    let Galletas = cookies.getAll();

    if(Object.keys(Galletas).length == 0){
      window.location.href = '#/'
    }
    else if(Galletas.id == undefined){
      window.location.href = '#/'
    }
  }

  async ObtenerNotificacionesNoVistas() {
    //this.setState({cargando: true});
    await fetch(Globales.Url + 'Configuracion/Configuracion/ObtenerNotificacionesNoVistas', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                //this.showNotification();
            } else { //Filtrado exitoso
                this.setState({NumNotificaciones: data.Notificaciones})
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  async ListaNotificacionesAPI(bool) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Configuracion/Configuracion/ListaNotificaciones', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({conteo: bool == false ? 0 : this.state.ListaNotificaciones.length})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log("API", data.ListaNotificaciones);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
                //this.showNotification();
            } else { //Filtrado exitoso

              if(bool){
                if(data.ListaNotificaciones.length > 1){
                  let aux = [];
                  aux = this.state.ListaNotificaciones.concat(data.ListaNotificaciones);

                  this.setState({ListaNotificaciones: aux, cargando: false});
                }
                else{
                  let aux = [];
                  let aux2 = [];

                  aux.push(data.ListaNotificaciones);

                  aux2 = this.state.ListaNotificaciones.concat(aux);

                  this.setState({ListaNotificaciones: aux2, cargando: false});
                }
              }
              else{
                if(data.ListaNotificaciones.length > 1){
                  this.setState({ListaNotificaciones: data.ListaNotificaciones, cargando: false})
                }
                else{
                  let aux = [];
                  aux.push(data.ListaNotificaciones);

                  this.setState({ListaNotificaciones: aux, cargando: false})
                }
              }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  async ActualizarEstadoNotificacionNoVista(notif) {
    //this.setState({cargando: true});
    await fetch(Globales.Url + 'Configuracion/Configuracion/ActualizarEstadoNotificacionNoVista', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idNotificacion: notif._id})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                //this.showNotification();
            } else { //Filtrado exitoso
                let index = this.state.ListaNotificaciones.indexOf(notif);
                this.state.ListaNotificaciones.splice(index, 1);

                this.setState({ListaNotificaciones: this.state.ListaNotificaciones, NumNotificaciones: this.state.NumNotificaciones - 1});
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}
 
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleToggleProfile = () => {
    this.setState(state => ({ openProfile: !state.openProfile }));
  };

  handleToggleNotificaciones = () => {
    this.setState(state => ({openNotificacion: !state.openNotificacion}))
    this.ObtenerNotificacionesNoVistas();
    this.ListaNotificacionesAPI(false)
  };

  handleCloseProfile = (exito) => {
    this.setState({ openCerrarSesion: false });
    if(exito){
      cookies.remove('Usuario');
      cookies.remove('NombreUsuario');
      cookies.remove('TipoUsuario');
      cookies.remove('id');
      cookies.remove('Correo');
      cookies.remove('Nombre');
      cookies.remove('Telefono');
      cookies.remove('token');
      cookies.remove('FechaServidor');
      cookies.remove('NombreUsuarioCompleto');
      cookies.remove('Permisos');
      Globales.token = ''
      window.location.href = '#/'
    }
  };

  handleChangePassword() {
    window.location.href = '#/CambioContrasena';
  }

  handleMenuPrincipal() {
    window.location.href = '#/MenuPrincipal';
  }

  handleClosePro = event => {
    if (this.anchorProfile.contains(event.target)) {
      return;
    }
    this.setState({ openProfile: false });
  }

  handleCloseNotificacion = event => {
    if (this.anchorNotificacion.contains(event.target)) {
      return;
    }
    this.setState({ openNotificacion: false });
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  ValidarModulo(modulo){
    let valido = false;

    Permisos.map(permiso => {
      if(parseInt(permiso.NumeroModulo) == modulo){
        valido = true
      }
    })

    return valido
  }

  render() {
    const { classes } = this.props;
    const { openNotifcation, openProfile, openNotificacion } = this.state;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    let notificaciones;
    if(this.state.ListaNotificaciones.length !== 0){
      notificaciones =
      this.state.ListaNotificaciones.map(notif => {
        return(
          <>
            <ListItem style={{cursor: "default"}}>
              <ListItemIcon>
                <Info color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={notif.Titulo}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textSecondary"
                    >
                      {`${moment(notif.Fecha).format("DD-MM-YYYY")}`}
                    </Typography>
                    {" — " + notif.Mensaje}
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => this.ActualizarEstadoNotificacionNoVista(notif)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        )
      })
    }
    else{
      notificaciones = (
        <ListItem alignItems="flex-start">
          {/* <ListItemIcon>
            <Info color="primary" />
          </ListItemIcon> */}
          <ListItemText
            //primary={notif.Titulo}
            secondary={
              <React.Fragment>
                {"No tiene notificaciones nuevas"}
              </React.Fragment>
            }
          />
        </ListItem>
      )
    }

    //console.log("ListaNotificaciones: ", this.state.ListaNotificaciones)
    return (
      <div>
        <div id="">
          <ModalPregunta
            Titulo={"Cerrar Sesión"}
            Cuerpo={"¿Está seguro de cerrar sesión?"}
            show={this.state.openCerrarSesion}
            eventClick={this.handleCloseProfile}
          />
        </div>

        <div className={classes.manager}>
        {this.ValidarModulo(11) ? <Button
            buttonRef={node => {
              this.anchorNotificacion = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            aria-owns={openNotifcation ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggleNotificaciones}
            justIcon
          >
            <Badge color="secondary" badgeContent={this.state.NumNotificaciones} max={99}>
              <Notifications/>
            </Badge>
          </Button> : ""}
          <Button
            buttonRef={node => {
              this.anchorProfile = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            aria-owns={openNotifcation ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleMenuPrincipal}
          >
            <p className={classes.linkText}>Menu Principal</p>
          </Button>
          <Button
            buttonRef={node => {
              this.anchorProfile = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            aria-owns={openNotifcation ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggleProfile}
          >
            <p className={classes.linkText}>{cookies.get('NombreUsuario')}</p>
          </Button>
          <Poppers
            style={{ zIndex: 20 }}
            open={openProfile}
            anchorEl={this.anchorProfile}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openProfile }) +
              " " +
              classes.popperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper elevation={3}>
                  <ClickAwayListener onClickAway={this.handleClosePro}>
                    <MenuList role="menu">
                      <MenuItem onClick={this.handleChangePassword} className={classes.dropdownItem} >
                        Cambiar Contraseña
                      </MenuItem>
                      <MenuItem onClick={() => this.setState(state => ({ openCerrarSesion: true }))} className={classes.dropdownItem} >
                        Cerrar Sesión
                      </MenuItem>
                      <MenuItem className={classes.dropdownItem} >
                        Versión: {Globales.version}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
          <Poppers
            open={openNotificacion}
            anchorEl={this.anchorNotificacion}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openNotificacion }) +
              " " +
              classes.popperNav
            }
            style={{zIndex: "5"}}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper elevation={3} style={{width: "27em"}}>
                  <ClickAwayListener onClickAway={this.handleCloseNotificacion}>
                    <List 
                      style={{
                        width: '100%',
                        position: 'relative',
                        overflow: 'auto',
                        overflowX: "clip",
                        overflowY: "auto",
                        maxHeight: 600,
                      }} 
                      disablePadding={true} 
                      dense={true}
                    >
                      {notificaciones}
                      <ListItem style={{cursor: "pointer", margin: 0}} className={classes.dropdownItem} onClick={() => this.ListaNotificacionesAPI(true)}>
                        VER MÁS NOTIFICACIONES...
                      </ListItem>
                    </List>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>

      {agregar}
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
