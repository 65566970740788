import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CardBody from "components/Card/CardBody.jsx";
import ModalEstado from "./ModalEstado.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Search, Close } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

class CatEstados extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      _idPais: "",
      FiltrarNombres: "",
      ListaEstados: [],
      ListaPaises: [],
      ListaEstadosTemp: [],
      EstadoSeleccionado: {},
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      Total: 0,
      TotalSeleccionado: 0,
      Titulo: "Registrar un nuevo Estado",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    this.ListaGeneralPaises();
  }

  async ListaGeneralPaises() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Paises/ListaGeneralPaises", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          if (data.ListadePaises.Pais.length === 0) {
            this.setState({
              colorNotificacion: "danger",
              mensaje: "No se encontraron coincidencias",
            });
            this.showNotification();
          } else {
            if(data.ListadePaises.Pais.length > 1){
              this.setState({
                ListaPaises: data.ListadePaises.Pais,
                cargando: false,
                entroEliminar: false,
                FiltrarNombres: "",
              });
            }
            else{
              let aux = [];
              aux.push(data.ListadePaises.Pais)
              this.setState({
                ListaPaises: aux,
                cargando: false,
                entroEliminar: false,
                FiltrarNombres: "",
              });
            }
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ListaGeneralEstados(Pais) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Estados/ListaGeneralEstados", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        _idPais: Pais,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          const TipoData = Array.isArray(data.ListadeEstados.Estado);
          const EstadosData = TipoData
            ? data.ListadeEstados.Estado
            : [{ ...data.ListadeEstados.Estado }];
          if (EstadosData.length > 0) {
            this.setState({
              ListaEstados: EstadosData,
              ListaEstadosTemp: EstadosData,
              Activo: EstadosData.length,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          } else {
            this.setState({
              ListaEstados: [],
              ListaEstadosTemp: [],
              Activo: 0,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
              colorNotificacion: "danger",
              mensaje: "Aun no cuenta con estados registrados",
            });
            this.showNotification();
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "NombreEstado") {
      this.setState({
        _idPais: value,
        FiltrarNombres: "",
        EstadoSeleccionado: "",
        ListaEstados: [],
        ListaEstadosTemp: [],
      });
      if (value) {
        this.ListaGeneralEstados(value);
      }
    }
    if (name === "FiltrarNombres") {
      const Dataorg = this.state.ListaEstadosTemp;
      if (value) {
        const NuevaData = Dataorg.filter((estado) =>
          String(estado.NombreEstado)
            .toUpperCase()
            .includes(String(value).toUpperCase())
        );

        if(NuevaData.length === 0){
          this.setState({
            FiltrarNombres: value,
            ListaEstados: NuevaData,
            colorNotificacion: "danger",
            mensaje: "No se encontraron coincidencias",
          });
          this.showNotification();
        }
        else{
          this.setState({
            FiltrarNombres: value,
            ListaEstados: NuevaData,
          });
        }
        
      } else {
        this.setState({
          FiltrarNombres: "",
          ListaEstados: [...Dataorg],
        });
      }
    }
  };

  eventoModalEliminar(e) {
    this.setState({ show: true, EstadoSeleccionado: e });
    return;
  }

  eventoModalPregunta = (valor) => {
    if (valor) {
      this.eliminarEstado(this.state.EstadoSeleccionado);
    } else {
      this.setState({ show: false });
    }
  };

  eliminarEstado(id) {
    fetch(Globales.Url + "Catalogos/Estados/EliminarInformacionEstado", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ _id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          this.setState({ colorNotificacion: "danger" });
        } else if (data.error) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger" });
        } else {
          //Obtuvo los registros correctamente
          this.setState({ colorNotificacion: "success" });
        }
        this.setState({
          mensaje: data.mensaje
            ? data.mensaje
            : "El estado no puede ser eliminado, cuenta con municipios asociados.",
          show: false,
          entroEliminar: true,
        });
        this.showNotification();
        this.ListaGeneralEstados(this.state._idPais);
      });
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  eventoModalEstado = (mensaje, Color) => {
    this.setState({showAgregar: false})
    //Cambiar valores por default
    if(mensaje !== "esc"){
      this.setState({
        showAgregar: false,
        Titulo: "Nuevo País",
        mensaje: mensaje,
        NombreEstado: "",
        colorNotificacion: Color,
        op: 0,
        entroEliminar: true,
      });
      this.showNotification();
      this.ListaGeneralEstados(this.state._idPais);
    }
  };

  openModalNuevoEstado = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Registrar nuevo estado",
      NombreEstado: "",
      op: 0,
    });
  };

  openModalEditarEstados = (e) => {
    //Cambiar valores por default
    this.setState({
      NombreEstado: e.NombreEstado,
      _id: e._id,
      Titulo: "Editar estado",
      op: 1,
      showAgregar: true,
    });
  };

  render() {
    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }

    if (this.state.showAgregar == true) {
      agregar = (
        <ModalEstado
          xs={18}
          Titulo={this.state.Titulo}
          NombreEstado={this.state.NombreEstado}
          Estados={this.state.ListaEstadosTemp}
          _id={this.state._id}
          _idPais={this.state._idPais}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalEstado}
          op={this.state.op}
        />
      );
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text={true}>
                <h4 className={classes.cardTitle}>Filtros</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl
                      // fullWidth
                      className={classes.formControl}
                      size="small"
                      // style={{ marginBottom: "27px" }}
                      variant="outlined"
                    >
                      <InputLabel>País</InputLabel>
                      <Select
                        value={this.state._idPais}
                        onChange={this.handleChange}
                        name="NombreEstado"
                        id="_id"
                        size="small"
                        label="País"
                        defaultValue={""}
                        style={{ width: "27.6em", marginBottom: "10px" }}
                      >
                        <MenuItem disabled>Seleccione un país</MenuItem>
                        {this.state.ListaPaises.map((Pais, Index) => (
                          <MenuItem
                            key={Index + Pais.NombrePais}
                            value={Pais._id}
                          >
                            {Pais.NombrePais}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} />
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      id="FiltrarNombres"
                      style={{ width: "23vw" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Nombre del estado"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        onChange: this.handleChange.bind(this),
                        value: this.state.FiltrarNombres,
                        name: "FiltrarNombres",
                        id: "FiltrarNombres",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button
                      size="sm"
                      type="submit"
                      color="info"
                      round
                      className="pull-right"
                      disabled={!this.state._idPais}
                      onClick={() => this.openModalNuevoEstado()}
                    >
                      Nuevo
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de estados</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4
                      className="pull-right"
                      style={{ marginRight: "15px", marginTop: 0 }}
                    >
                      {this.state.ListaEstados.length} Estado(s)
                    </h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="center">Nombre del Estado</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ListaEstados.map((estado, index) => {
                        return (
                          <tr key={estado._id + "-" + index}>
                            <td onClick={() =>this.openModalEditarEstados({NombreEstado: estado.NombreEstado ,_id: estado._id,})} style={{verticalAlign: "middle", cursor: "default"}}>{estado.NombreEstado}</td>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>
                            <Button
                                color="danger"
                                round
                                justIcon
                                className="pull-right"
                                size="sm"
                                onClick={() =>
                                  this.eventoModalEliminar(estado._id)
                                }
                              >
                                <Close />
                              </Button>
                              <Button
                                color="info"
                                round
                                justIcon
                                className="pull-right mr-2"
                                size="sm"
                                onClick={() =>
                                  this.openModalEditarEstados({
                                    NombreEstado: estado.NombreEstado,
                                    _id: estado._id,
                                  })
                                }
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar estado"}
            Cuerpo={"¿Está seguro de dar de baja un estado?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatEstados);
