// core components/views for Admin layout
import VerificarToken from "views/FirmaReglamentos/VerificarToken";
// import SeleccionReglamentos from "views/FirmaReglamentos/SeleccionReglamentos";
import Reglamento from "views/FirmaReglamentos/Reglamento";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [  
  {
    path: "/Token",
    component: VerificarToken,
    layout: "/FirmaReglamento",
  },
  // {
  //   path: "/SeleccionReglamentos",
  //   component: SeleccionReglamentos,
  //   layout: "/FirmaReglamento",
  // },
  {
    path: "/Firmar",
    component: Reglamento,
    layout: "/FirmaReglamento",
  },
]
export default dashboardRoutes;