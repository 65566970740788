import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalPregunta from "components/Generales/ModalPregunta";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Edit, CameraAlt, Info, InfoTwoTone, InfoOutlined, Close, AddCircle, Search, Clear, Remove, Add, Save } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import { AsYouType } from 'libphonenumber-js'
import moment from 'moment';
import cookie from 'js-cookie'
import Persona from '../../../assets/img/persona.png'
import ModalDatosFiscales from "./ModalDatosFiscales.jsx";
import ModalDescuentoTemp from "./ModalDescuentoTemp.jsx";
import ModalCapturarFoto from "./ModalCapturarFoto.jsx";
import ModalInfoGrupo from "./ModalInfoGrupo";
import esLocale from "moment/locale/es";

moment.locale('es', esLocale);

var Permisos = localStorage.getItem("Permisos") == null ? { Modulo:[] } : JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}
else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

class ModalEditarAlumno extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      _id: '',
      MostrarModal:false,
      ObjAlumno: {},
      DetalleAlumno: {},
      ListaEmpresas: [],
      ListaPaises: [],
      ListaPaisesNacimiento: [],
      ListaEstadosNacimiento: [],
      ListaMunicipioNacimiento: [],
      ListaEstados: [],
      ListaMunicipio: [],
      InfoEstadoFiscal: {},
      InfoMunicipioFiscal: {},
      IDEmpresa: "",
      ListaDescuentosTemp: [],
      DescuentoSeleccionado: "",
      FotoAlumno: null,
      NumCuenta: "",
      Nombre: "",
      ApellidoPaterno: "",
      ApellidoMaterno: "",
      OpcionClase: "Niño",
      FechaNacimiento: "",
      Colonia: "",
      CalleNumero: "",
      Correo: "",
      Telefono: "",
      Celular: "",
      opcionParentesco: "Alumno",
      NombreContacto: "",
      CelularContacto: "",
      TelefonoContacto: "",
      CorreoContacto: "",
      Contacto: {
        Celular: "",
        Correo: "",
        EnumTipoContactoAlumno: "Alumno",
        Nombre: "",
        Telefono: ""
      },
      ListaContactos: [
        {
          Celular: "",
          Correo: "",
          EnumTipoContactoAlumno: "Alumno",
          Nombre: "",
          Telefono: ""
        }
      ],
      DiaPago: 0,
      DiaPago2: 0,
      IdPais: "",
      IdPaisNacimiento: "",
      IdEstado: "",
      IdMunicipio: "",
      IdEstadoNacimiento: "",
      IdMunicipioNacimiento: "",
      InfoDatoFiscal: {},
      idDatosFiscales: "",
      ListaGruposAlumno: [],
      GrupoSeleccionado: {},
      disabled:false,
      cargando: false,
      errorNombre: false,
      errorApellidoPaterno: false,
      errorApellidoMaterno: false,
      errorEmpresa: false,
      errorCorreo: false,
      errorTelefono: false,
      errorCelular: false,
      errorNombreContacto: false,
      errorCelularContacto: false,
      errorTelefonoContacto: false,
      errorCorreoContacto: false,
      errorDiasPago: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      TituloModalPregunta: "",
      CuerpoModalPregunta: "",
      opcionModalPregunta: 0,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: "",
      show: false,
      CuerpoCargando: "Cargando",
      showDatosFiscales: false
      
    }
    this.handleChange = this.handleChange.bind(this);
    //this.handleFocus = this.handleFocus.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    //this.ObtenerInformacionAlumno(this.props.AlumnoSeleccionado._id);
    let idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    if(this.props.op == 1){
      this.setState({
        AlumnoSeleccionado: this.props.AlumnoSeleccionado
      })
      this.ObtenerInformacionAlumno(this.props.AlumnoSeleccionado._id);
    }
    else if(this.props.op == 2){
      //console.log(this.props.AlumnoSeleccionado)

      let contacto = {
        Celular: this.props.AlumnoSeleccionado.Celular !== null && this.props.AlumnoSeleccionado.Celular !== undefined ? this.props.AlumnoSeleccionado.Celular : "",
        Correo: this.props.AlumnoSeleccionado.Correo !== null && this.props.AlumnoSeleccionado.Correo !== undefined ? this.props.AlumnoSeleccionado.Correo : "",
        EnumTipoContactoAlumno: "Otro",
        Nombre: this.props.AlumnoSeleccionado.Nombre,
        Telefono: this.props.AlumnoSeleccionado.Telefono !== null && this.props.AlumnoSeleccionado.Telefono !== undefined ? this.props.AlumnoSeleccionado.Telefono : ""
      }

      this.setState({
        DetalleAlumno: this.props.AlumnoSeleccionado,
        ApellidoMaterno: this.props.AlumnoSeleccionado.ApelidoMaterno !== null && this.props.AlumnoSeleccionado.ApelidoMaterno !== undefined ? this.props.AlumnoSeleccionado.ApelidoMaterno : "",
        ApellidoPaterno: this.props.AlumnoSeleccionado.ApellidoPaterno,
        OpcionClase: this.props.AlumnoSeleccionado.Clase,
        Nombre: this.props.AlumnoSeleccionado.Nombre,
        ListaContactos: [contacto]
      })
      if(JSON.stringify(this.props.AlumnoSeleccionado.DatosFiscales) !== JSON.stringify(idVacio)){
        this.ObtenerInformacionDatoFiscal(this.props.AlumnoSeleccionado.DatosFiscales);
      }
      this.obtenerEmpresasActivas();
      this.ListaGeneralPaises(idVacio, idVacio);
    }
    else if(this.props.op == 3){
      this.obtenerEmpresasActivas();
      this.ListaGeneralPaises(idVacio, idVacio);
    }
  }

  async ObtenerInformacionAlumno(id) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerInformacionAlumno', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({_id: id})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              //console.log("API", data.DetalleAlumno)
              let aux = []
              let auxGrupo = [];

              const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

              this.obtenerEmpresasActivas(data.DetalleAlumno._idEmpresa);
              this.ListaGeneralPaises(data.DetalleAlumno.PaisNacimiento, data.DetalleAlumno.Pais);
              this.ListaDescuentosActivosAlumno(data.DetalleAlumno._id);

              if(JSON.stringify(data.DetalleAlumno.EstadoNacimiento) !== JSON.stringify(idVacio)){
                this.ListaEstadosPaisNacimiento(data.DetalleAlumno.PaisNacimiento, data.DetalleAlumno.EstadoNacimiento)
              }
              else{
                this.ListaEstadosPaisNacimiento(data.DetalleAlumno.PaisNacimiento, 0)
              }

              if(JSON.stringify(data.DetalleAlumno.Estado) !== JSON.stringify(idVacio)){
                this.ListaEstadosPais(data.DetalleAlumno.Pais, data.DetalleAlumno.Estado)
              }
              else{
                this.ListaEstadosPais(data.DetalleAlumno.Pais, 0)
              }

              if(JSON.stringify(data.DetalleAlumno.MunicipioNacimiento) !== JSON.stringify(idVacio)){
                this.ListaMunicipiosEstadoNacimiento(data.DetalleAlumno.EstadoNacimiento, data.DetalleAlumno.MunicipioNacimiento)
              }
              else{
                this.ListaMunicipiosEstadoNacimiento(data.DetalleAlumno.EstadoNacimiento, 0)
              }

              if(JSON.stringify(data.DetalleAlumno.Ciudad) !== JSON.stringify(idVacio)){
                this.ListaMunicipiosEstado(data.DetalleAlumno.Estado, data.DetalleAlumno.Ciudad)
              }
              else{
                this.ListaMunicipiosEstado(data.DetalleAlumno.Estado, 0)
              }

              if(JSON.stringify(data.DetalleAlumno.DatosFiscales) !== JSON.stringify(idVacio)){
                this.ObtenerInformacionDatoFiscal(data.DetalleAlumno.DatosFiscales);
              }

              if(data.DetalleAlumno.ContactosAlumno !== undefined && data.DetalleAlumno.ContactosAlumno !== null){
                if(data.DetalleAlumno.ContactosAlumno.ContactoAlumno.length > 1){
                aux = data.DetalleAlumno.ContactosAlumno.ContactoAlumno;
                }
                else{
                  aux.push(data.DetalleAlumno.ContactosAlumno.ContactoAlumno);
                }
              }
              else{
                this.MigrarContactosAlumno(data.DetalleAlumno)
              }

              if(data.DetalleAlumno.ListaGrupos !== undefined && data.DetalleAlumno.ListaGrupos !== null){
                if(data.DetalleAlumno.ListaGrupos.GrupoAlumno.length > 1){
                  data.DetalleAlumno.ListaGrupos.GrupoAlumno.map(grupo => {
                    if(grupo.Estado == "En_Curso"){
                      auxGrupo.push(grupo);
                    }
                  })
                }
                else{
                  if(data.DetalleAlumno.ListaGrupos.GrupoAlumno.Estado == "En_Curso"){
                    auxGrupo.push(data.DetalleAlumno.ListaGrupos.GrupoAlumno)
                  }
                }
              }
            
                this.setState({
                  DetalleAlumno: data.DetalleAlumno,
                  cargando: false,
                //DATOS ALUMNO
                  FotoAlumno: data.DetalleAlumno.Foto !== null && data.DetalleAlumno.Foto !== undefined ? data.DetalleAlumno.Foto : null,
                  Nombre: data.DetalleAlumno.Nombre,
                  ApellidoPaterno: data.DetalleAlumno.ApellidoPaterno,
                  ApellidoMaterno: data.DetalleAlumno.ApelidoMaterno !== null && data.DetalleAlumno.ApelidoMaterno !== undefined ? data.DetalleAlumno.ApelidoMaterno : "",
                  NumCuenta: data.DetalleAlumno.NumeroCuenta,
                  OpcionClase: data.DetalleAlumno.Clase,
                  FechaNacimiento: moment(data.DetalleAlumno.FechaNacimiento).format("YYYY-MM-DD") !== "0001-01-01" ? moment(data.DetalleAlumno.FechaNacimiento).format("YYYY-MM-DD") : "",
                //DATOS DIRECCIÓN Y CONTACTO
                  Colonia: data.DetalleAlumno.Colonia,
                  CalleNumero: data.DetalleAlumno.CalleNumero,
                  Correo: data.DetalleAlumno.Correo !== undefined && data.DetalleAlumno.Correo !== null ? data.DetalleAlumno.Correo : "",
                  Telefono: data.DetalleAlumno.Telefono !== undefined && data.DetalleAlumno.Telefono !== null ? data.DetalleAlumno.Telefono : "",
                  Celular: data.DetalleAlumno.Celular !== undefined && data.DetalleAlumno.Celular !== null ? data.DetalleAlumno.Celular : "",
                //DATOS DE CONTACTO
                  ListaContactos: data.DetalleAlumno.ContactosAlumno !== undefined && data.DetalleAlumno.ContactosAlumno !== null ? aux : this.state.ListaContactos,
                //DATO DIA LIMITE DE PAGO
                  DiaPago: data.DetalleAlumno.DiaEspecialPago !== undefined && data.DetalleAlumno.DiaEspecialPago !== null ? data.DetalleAlumno.DiaEspecialPago : 0,
                  DiaPago2: data.DetalleAlumno.DiaEspecialPago2 !== undefined && data.DetalleAlumno.DiaEspecialPago2 !== null ? data.DetalleAlumno.DiaEspecialPago2 : 0,
                  ListaGruposAlumno: auxGrupo
                })
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

MigrarContactosAlumno(DetalleAlumno){
  //console.log("Entro a migrar contactos", DetalleAlumno)
  let Aux = [];
  let ListaPrimaria = {
    Celular: "",
    Correo: "",
    EnumTipoContactoAlumno: "Alumno",
    Nombre: "",
    Telefono: ""
  };

  ListaPrimaria.Celular = DetalleAlumno.Celular !== null && DetalleAlumno.Celular !== undefined ? DetalleAlumno.Celular : ""
  ListaPrimaria.Correo = DetalleAlumno.Correo !== null && DetalleAlumno.Correo !== undefined ? DetalleAlumno.Correo : ""
  ListaPrimaria.EnumTipoContactoAlumno = "Alumno"
  ListaPrimaria.Nombre = ""
  ListaPrimaria.Telefono = DetalleAlumno.Telefono !== null && DetalleAlumno.Telefono !== undefined ? DetalleAlumno.Telefono : ""

  Aux.push(ListaPrimaria)

  if((DetalleAlumno.TelefonoTutor !== null && DetalleAlumno.TelefonoTutor !== undefined) || (DetalleAlumno.NombreTutor !== null && DetalleAlumno.NombreTutor !== undefined) || (DetalleAlumno.CorreoTutor !== null && DetalleAlumno.CorreoTutor !== undefined)){
    let ListaSecundaria = {
      Celular: "",
      Correo: "",
      EnumTipoContactoAlumno: "Otro",
      Nombre: "",
      Telefono: ""
    };

    ListaSecundaria.Celular = ""
    ListaSecundaria.Correo = DetalleAlumno.CorreoTutor !== null && DetalleAlumno.CorreoTutor !== undefined ? DetalleAlumno.CorreoTutor : ""
    ListaSecundaria.Nombre = DetalleAlumno.NombreTutor !== null && DetalleAlumno.NombreTutor !== undefined ? DetalleAlumno.NombreTutor : ""
    ListaSecundaria.Telefono = DetalleAlumno.TelefonoTutor !== null && DetalleAlumno.TelefonoTutor !== undefined ? DetalleAlumno.TelefonoTutor : ""

    Aux.push(ListaSecundaria)
  }

  this.setState({ListaContactos: Aux})
}

async obtenerEmpresasActivas(idEmpresa) {
  await fetch(Globales.Url + 'Facturacion/Facturacion/ListadoEmpresas', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
  }
  ).then(res => res.json())
      .then(data => {
          // console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1 });
              this.showNotification();
          } else { //Filtrado exitoso

            if (data.ListaEmpresas.length > 1) {
              if(idEmpresa !== undefined){
                data.ListaEmpresas.map(empresa => {
                  if(JSON.stringify(idEmpresa) == JSON.stringify(empresa._id)){
                    this.setState({ IDEmpresa: empresa._id });
                  }
                })
              }

              this.setState({ ListaEmpresas: data.ListaEmpresas});
            }
            else {
              let ListaAuxEmpresas = [];
              ListaAuxEmpresas.push(data.ListaEmpresas);

              if(idEmpresa !== undefined){
                if(JSON.stringify(idEmpresa) == JSON.stringify(data.ListaEmpresas._id)){
                  this.setState({ IDEmpresa: data.ListaEmpresas._id });
                }
              }

              this.setState({ ListaEmpresas: ListaAuxEmpresas});
            }

            }
          })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ListaGeneralPaises( idPaisNacimiento, idPais ) {
  await fetch(Globales.Url + 'Alumnos/Alumnos/ListaGeneralPaises', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
  }
  ).then(res => res.json())
      .then(data => {
          // console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1 });
              this.showNotification();
          } else { //Filtrado exitoso
            const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
            var Pais = "";
            if(data.ListaPaises.length > 1){
              if(JSON.stringify(idPaisNacimiento) !== JSON.stringify(idVacio)){
                data.ListaPaises.map(pais => {
                  if(JSON.stringify(pais._id) == JSON.stringify(idPaisNacimiento)){
                    Pais = pais._id;
                  }
                })
                this.setState({ListaPaisesNacimiento: data.ListaPaises, IdPaisNacimiento: Pais})
              }
              else{
                this.setState({ListaPaisesNacimiento: data.ListaPaises})
              }

              if(JSON.stringify(idPais) !== JSON.stringify(idVacio)){
                data.ListaPaises.map(pais => {
                  if(JSON.stringify(pais._id) == JSON.stringify(idPais)){
                    Pais = pais._id;
                  }
                })
                this.setState({ListaPaises: data.ListaPaises, IdPais: Pais})
              }
              else{
                this.setState({ListaPaises: data.ListaPaises})
              }
            }
            else{
              //console.log("entro aqui")
              let aux = [];
              aux.push(data.ListaPaises);

              if(JSON.stringify(idPaisNacimiento) !== JSON.stringify(idVacio)){
                if(JSON.stringify(idPaisNacimiento) == JSON.stringify(data.ListaPaises._id)){
                  this.setState({ListaPaisesNacimiento: aux, IdPaisNacimiento: data.ListaPaises._id});
                }
                else{
                  this.setState({ListaPaisesNacimiento: aux});
                }
              }
              else{
                this.setState({ListaPaisesNacimiento: aux});
              }

              if(JSON.stringify(idPais) !== JSON.stringify(idVacio)){
                if(JSON.stringify(idPais) == JSON.stringify(data.ListaPaises._id)){
                  this.setState({ListaPaises: aux, IdPais: data.ListaPaises._id});
                }
                else{
                  this.setState({ListaPaises: aux});
                }
              }
              else{
                this.setState({ListaPaises: aux});
              }
            }
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ListaEstadosPaisNacimiento( idPais, idEstado ) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/ListaEstadosPaisNacimiento', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({_idPais: idPais})
  }
  ).then(res => res.json())
      .then(data => {
          // console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              //this.showNotification();
          } else { //Filtrado exitoso
            var idestado = "";
            if(idEstado !== 0){
              if(data.ListaEstadosNacimiento.length > 1){
                data.ListaEstadosNacimiento.map(estado => {
                  if(JSON.stringify(estado._id) == JSON.stringify(idEstado)){
                    idestado = estado._id;
                  }
                })
                this.setState({ ListaEstadosNacimiento: data.ListaEstadosNacimiento, IdEstadoNacimiento: idestado, Activo: 1, cargando: false });
              }
              else{
                let aux = [];
                aux.push(data.ListaEstadosNacimiento);

                if(JSON.stringify(data.ListaEstadosNacimiento._id) == JSON.stringify(idEstado)){
                  idestado = data.ListaEstadosNacimiento._id;
                }
                this.setState({ ListaEstadosNacimiento: aux, IdEstadoNacimiento: idestado, Activo: 1, cargando: false });
              }
            }
            else{
              if(data.ListaEstadosNacimiento.length > 1){
                this.setState({ ListaEstadosNacimiento: data.ListaEstadosNacimiento, Activo: 1, cargando: false });
              }
              else{
                let aux = [];
                aux.push(data.ListaEstadosNacimiento)

                this.setState({ ListaEstadosNacimiento: aux, Activo: 1, cargando: false });
              }
            }
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ListaEstadosPais( idPais, idEstado ) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/ListaEstadosPais', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({_idPais: idPais})
  }
  ).then(res => res.json())
      .then(data => {
          // console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false, cargando: false });
              //this.showNotification();
          } else { //Filtrado exitoso
            var idestado = "";
            if(idEstado !== 0){
              if(data.ListaEstados.length > 1){
                data.ListaEstados.map(estado => {
                  if(JSON.stringify(estado._id) == JSON.stringify(idEstado)){
                    idestado = estado._id;
                  }
                })
                this.setState({ ListaEstados: data.ListaEstados, IdEstado: idestado, Activo: 1, cargando: false });
              }
              else{
                let aux = [];
                aux.push(data.ListaEstados);

                if(JSON.stringify(data.ListaEstados._id) == JSON.stringify(idEstado)){
                  idestado = data.ListaEstados._id;
                }
                this.setState({ ListaEstados: aux, IdEstado: idestado, Activo: 1, cargando: false });
              }
            }
            else{
              if(data.ListaEstados.length > 1){
                this.setState({ ListaEstados: data.ListaEstados, Activo: 1, cargando: false });
              }
              else{
                let aux = [];
                aux.push(data.ListaEstados);

                this.setState({ ListaEstados: aux, Activo: 1, cargando: false });
              }
            }
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ListaMunicipiosEstadoNacimiento( idEstado, idMunicipio ) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/ListaMunicipiosEstadoNacimiento', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({_idEstado: idEstado})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              //this.showNotification();
          } else { //Filtrado exitoso
            var idmunicipio = "";
              if (idMunicipio !== 0) {
                if(data.ListaMunicipioNacimiento.length > 1){
                  data.ListaMunicipioNacimiento.map(ciudad => {
                    if(JSON.stringify(ciudad._id) == JSON.stringify(idMunicipio)){
                      idmunicipio = ciudad._id;
                    }
                  })
                  this.setState({ ListaMunicipioNacimiento: data.ListaMunicipioNacimiento, IdMunicipioNacimiento: idmunicipio, Activo: 1, cargando: false });
                }
                else{
                  let aux = [];
                  aux.push(data.ListaMunicipioNacimiento);

                  if(JSON.stringify(data.ListaMunicipioNacimiento._id) == JSON.stringify(idMunicipio)){
                    idmunicipio = data.ListaMunicipioNacimiento._id;
                  }

                  this.setState({ ListaMunicipioNacimiento: aux, IdMunicipioNacimiento: idmunicipio, Activo: 1, cargando: false });
                }
              }
              else {
                if(data.ListaMunicipioNacimiento.length > 1){
                  this.setState({ ListaMunicipioNacimiento: data.ListaMunicipioNacimiento, Activo: 1, cargando: false });
                }
                else{
                  let aux = [];
                  aux.push(data.ListaMunicipioNacimiento);

                  this.setState({ ListaMunicipioNacimiento: aux, Activo: 1, cargando: false });
                }
              }
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ListaMunicipiosEstado( idEstado, idMunicipio ) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/ListaMunicipiosEstado', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({_idEstado: idEstado})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              //this.showNotification();
          } else { //Filtrado exitoso
            var idmunicipio = "";
            if(data.ListaMunicipio.length > 1){
              if (idMunicipio !== 0) {
                data.ListaMunicipio.map(ciudad => {
                  if(JSON.stringify(ciudad._id) == JSON.stringify(idMunicipio)){
                    idmunicipio = ciudad._id;
                  }
                })
                this.setState({ ListaMunicipio: data.ListaMunicipio, IdMunicipio: idmunicipio, Activo: 1, cargando: false });
              }
              else {
                this.setState({ ListaMunicipio: data.ListaMunicipio, Activo: 1, cargando: false });
              }
            }
            else{
              let aux = [];
              aux.push(data.ListaMunicipio);

              if (idMunicipio !== 0) {
                  if(JSON.stringify(data.ListaMunicipio._id) == JSON.stringify(idMunicipio)){
                    idmunicipio = data.ListaMunicipio._id;
                  }

                this.setState({ ListaMunicipio: aux, IdMunicipio: idmunicipio, Activo: 1, cargando: false });
              }
              else {
                this.setState({ ListaMunicipio: aux, Activo: 1, cargando: false });
              }
            }
              
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ObtenerInformacionDatoFiscal( idFiscal ) {
  const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
  if(JSON.stringify(idFiscal) !== JSON.stringify(idVacio)){
    await fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerInformacionDatoFiscal', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({_id: idFiscal})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                //this.showNotification();
            } else { //Filtrado exitoso
              if(JSON.stringify(idFiscal) !== JSON.stringify(idVacio)){
                if(JSON.stringify(data.InfoDatoFiscal._idciudad) !== JSON.stringify(idVacio)){
                  this.ObtenerInformacionMunicipioFiscal(data.InfoDatoFiscal._idciudad)
                }
                if(JSON.stringify(data.InfoDatoFiscal._idEstado) !== JSON.stringify(idVacio)){
                  this.ObtenerInformacionEstadoFiscal(data.InfoDatoFiscal._idEstado);
                }
                  this.setState({InfoDatoFiscal: data.InfoDatoFiscal, idDatosFiscales: data.InfoDatoFiscal._id, cargando: false});
            }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
}

async ObtenerInformacionMunicipioFiscal( idMunicipio ) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerInformacionMunicipio', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({_id: idMunicipio})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              //this.showNotification();
          } else { //Filtrado exitoso
                this.setState({ InfoMunicipioFiscal: data.InfoMunicipioFiscal, Activo: 1, cargando: false });
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ObtenerInformacionEstadoFiscal( idEstado ) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerInformacionEstado', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({_id: idEstado})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              //this.showNotification();
          } else { //Filtrado exitoso
                this.setState({ InfoEstadoFiscal: data.InfoEstadoFiscal, Activo: 1, cargando: false });
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ListaDescuentosActivosAlumno( idAlumno ) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/ListaDescuentosActivosAlumno', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({_idAlumno: idAlumno})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ cargando: false });
              //this.showNotification();
          } else { //Filtrado exitoso         
              if(data.ListaDescuentosTemp.length > 1){
                this.setState({ ListaDescuentosTemp: data.ListaDescuentosTemp, Activo: 1, cargando: false });
              }
              else{
                let aux = [];
                aux.push(data.ListaDescuentosTemp);
                this.setState({ ListaDescuentosTemp: aux, Activo: 1, cargando: false });
              }
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async EliminarDescuentoTemporal() {
  this.setState({cargando: true});
  let index = this.state.ListaDescuentosTemp.findIndex(e => e._id == this.state.DescuentoSeleccionado._id);

  await fetch(Globales.Url + 'Alumnos/Alumnos/EliminarDescuentoTemporal', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({DescuentoTemp: this.state.ListaDescuentosTemp[index]})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso       
            this.state.ListaDescuentosTemp.splice(index, 1)

            this.setState({ListaDescuentosTemp: this.state.ListaDescuentosTemp, mensaje: data.mensaje, colorNotificacion: "success", cargando: false });
            this.showNotification();
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

CrearObjAlumno(){
  const IsEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
  let valor1 = 0;
  let valor2 = 0;
  let valor3 = 0;
  let valor4 = 0;
  let valor5 = 0;
  let valor6 = 0;
  let valor7 = 0;
  let valor8 = 0;
  let valor9 = 0;

  this.state.ListaContactos.map(contacto => {
    if(((contacto.Celular == null && contacto.Celular == undefined) || contacto.Celular == "") && ((contacto.Telefono == null && contacto.Telefono == undefined) || contacto.Telefono == "")){
      this.setState({
        errorCelularContacto: true,
        errorCorreoContacto: true,
        errorTelefonoContacto: true,
        errorNombreContacto: true,
        mensaje: "Proporcione al menos un numero de telefono o celular",
        colorNotificacion: "danger",
      })
      this.showNotification();
      valor5 = 1;
    }
  })

  this.state.ListaContactos.map(contacto => {
    if(contacto.Correo !== null && contacto.Correo !== "" && contacto.Correo !== undefined){
      if(IsEmail.test(contacto.Correo) === false){
        this.setState({
          errorCelularContacto: true,
          errorCorreoContacto: true,
          errorTelefonoContacto: true,
          errorNombreContacto: true,
          mensaje: "El correo proporcionado no es valido",
          colorNotificacion: "danger",
        })
        this.showNotification();
        valor4 = 1;
      }
    }
  })

  /* if(parseInt(this.state.DiaPago) !== 0){
    if(parseInt(this.state.DiaPago2) == 0){
      valor6 = 1;
      this.setState({errorDiasPago: true, mensaje: "Proporcione los dias limite de pago", colorNotificacion: "danger"})
      this.showNotification();
    }
    else{
      valor6 = 0;
      this.setState({errorDiasPago: false})
    }
  }
  else  */if(parseInt(this.state.DiaPago2) !== 0){
    if(parseInt(this.state.DiaPago) == 0 || this.state.DiaPago == ""){
      valor6 = 1;
      this.setState({errorDiasPago: true, mensaje: "No puede registrar únicamente el segundo dia de pago", colorNotificacion: "danger"})
      this.showNotification();
    }
    else if(parseInt(this.state.DiaPago) >= parseInt(this.state.DiaPago2)){
      valor6 = 1;
      this.setState({errorDiasPago: true, mensaje: "El segundo dia de pago no puede ser menor o igual al primer dia de pago", colorNotificacion: "danger"})
      this.showNotification();
    }
    else{
      valor6 = 0;
      this.setState({errorDiasPago: false})
    }
  }
  else{
    valor6 = 0;
    this.setState({errorDiasPago: false})
  }



  if(this.state.IDEmpresa == ""){
    valor3 = 1;
    this.setState({errorEmpresa: true, mensaje: "Selecione una empresa", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor3 = 0;
    this.setState({errorEmpresa: false})
  }

  if(this.state.ListaContactos.length < 1){
    valor9 = 1;
    this.setState({errorNombreContacto: true, errorCelularContacto: true, errorTelefonoContacto: true, errorCorreoContacto: true, mensaje: "Proporcione uno o más contactos", colorNotificacion: "danger"});
    this.showNotification();
  }
  else{
    valor9 = 0;
    this.setState({errorNombreContacto: false, errorCelularContacto: false, errorTelefonoContacto: false, errorCorreoContacto: false});
  }

  if(this.state.ApellidoPaterno == ""){
    valor2 = 1;
    this.setState({errorApellidoPaterno: true, mensaje: "Proporcione apellido paterno", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor2 = 0;
    this.setState({errorApellidoPaterno: false})
  }

  if(this.state.Nombre == ""){
    valor1 = 1;
    this.setState({errorNombre: true, mensaje: "Proporcione nombre", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor1 = 0;
    this.setState({errorNombre: false})
  }

  if(valor1 + valor2 + valor3 + valor4 + valor5 + valor6 + valor7 + valor8 + valor9 == 0){
    // console.log("Correctoy")
    if(this.props.op == 1){
      this.setState({
      ObjAlumno:{
        ApelidoMaterno: this.state.ApellidoMaterno,
        ApellidoPaterno: this.state.ApellidoPaterno,
        AplicaDescuentoTemporal: this.state.ListaDescuentosTemp.length !== 0 ? true : false,
        CalleNumero: this.state.CalleNumero,
        //Celular: this.state.Celular,
        Ciudad: this.state.IdMunicipio !== "" ? this.state.IdMunicipio : idVacio,
        Clase: this.state.OpcionClase,
        Colonia: this.state.Colonia,
        ContactosAlumno: {ContactoAlumno: this.state.ListaContactos},
        Comentarios: this.state.DetalleAlumno.Comentarios !== null && this.state.DetalleAlumno.Comentarios !== undefined ? this.state.DetalleAlumno.Comentarios : null,
        //Correo: this.state.Correo,
        //CorreoTutor: this.state.CorreoTutor,
        DatosFiscales: this.state.idDatosFiscales !== "" ? this.state.idDatosFiscales : idVacio,
        DiaEspecialPago: parseInt(this.state.DiaPago),
        DiaEspecialPago2: parseInt(this.state.DiaPago2),
        Estado: this.state.IdEstado !== "" ? this.state.IdEstado : idVacio,
        EstadoAlumno: this.state.DetalleAlumno.EstadoAlumno !== null && this.state.DetalleAlumno.EstadoAlumno !== undefined ? this.state.DetalleAlumno.EstadoAlumno : "Inactivo",
        EstadoNacimiento: this.state.IdEstadoNacimiento !== "" ? this.state.IdEstadoNacimiento : idVacio,
        FechaDadoBaja: this.state.DetalleAlumno.FechaDadoBaja !== null && this.state.DetalleAlumno.FechaDadoBaja !== undefined ? this.state.DetalleAlumno.FechaDadoBaja : "0001-01-01",
        FechaNacimiento: this.state.FechaNacimiento != "" && this.state.FechaNacimiento != "0000-12-31" ? this.state.FechaNacimiento : "0001-01-01",
        // FechaRegistro: moment(this.state.DetalleAlumno.FechaRegistro).format("YYYY-MM-DD"),
        FechaRegistro:this.state.DetalleAlumno.FechaRegistro,
        Foto: this.state.FotoAlumno !== null ? this.state.FotoAlumno : null,
        IdiomasInscrito: this.state.DetalleAlumno.IdiomasInscrito !== null && this.state.DetalleAlumno.IdiomasInscrito !== undefined ? this.state.DetalleAlumno.IdiomasInscrito : null,
        ListaGrupos: this.state.DetalleAlumno.ListaGrupos !== null && this.state.DetalleAlumno.ListaGrupos !== undefined ? this.state.DetalleAlumno.ListaGrupos : null,
        Motivos: this.state.DetalleAlumno.Motivos !== null && this.state.DetalleAlumno.Motivos !== undefined ? this.state.DetalleAlumno.Motivos : null,
        MunicipioNacimiento: this.state.IdMunicipioNacimiento !== "" ? this.state.IdMunicipioNacimiento : idVacio,
        Nombre: this.state.Nombre,
        NombreCompleto: this.state.Nombre + " " + this.state.ApellidoPaterno + " " + this.state.ApellidoMaterno,
        //NombreTutor: this.state.NombreTutor,
        NumeroCuenta: this.state.DetalleAlumno.NumeroCuenta,
        NumeroCuenta_NombreCompleto: this.state.DetalleAlumno.NumeroCuenta + " - " + this.state.Nombre + " " + this.state.ApellidoPaterno + " " + this.state.ApellidoMaterno,
        NumeroFamiliaresEstudiando: this.state.DetalleAlumno.NumeroFamiliaresEstudiando,
        Pais: this.state.IdPais !== "" ? this.state.IdPais : idVacio,
        PaisNacimiento: this.state.IdPaisNacimiento !== "" ? this.state.IdPaisNacimiento : idVacio,
        //Telefono: this.state.Telefono,
        //TelefonoTutor: this.state.TelefonoTutor,
        _id: this.state.DetalleAlumno._id,
        _idEmpresa: this.state.IDEmpresa !== "" ? this.state.IDEmpresa : idVacio,
        _idProspecto: this.state.DetalleAlumno._idProspecto
      }
    }, () => this.RegistrarAlumno())
    }
    else if(this.props.op == 2){
      this.setState({
        ObjAlumno:{
          ApelidoMaterno: this.state.ApellidoMaterno,
          ApellidoPaterno: this.state.ApellidoPaterno,
          AplicaDescuentoTemporal: false,
          CalleNumero: this.state.CalleNumero,
          //Celular: this.state.Celular,
          Ciudad: this.state.IdMunicipio !== "" ? this.state.IdMunicipio : idVacio,
          Clase: this.state.OpcionClase,
          Colonia: this.state.Colonia,
          ContactosAlumno: {ContactoAlumno: this.state.ListaContactos},
          //Correo: this.state.Correo,
          //CorreoTutor: this.state.CorreoTutor,
          DatosFiscales: this.state.idDatosFiscales !== "" ? this.state.idDatosFiscales : idVacio,
          DiaEspecialPago: parseInt(this.state.DiaPago),
          DiaEspecialPago2: parseInt(this.state.DiaPago2),
          Estado: this.state.IdEstado !== "" ? this.state.IdEstado : idVacio,
          EstadoAlumno: "Inactivo",
          EstadoNacimiento: this.state.IdEstadoNacimiento !== "" ? this.state.IdEstadoNacimiento : idVacio,
          FechaNacimiento: this.state.FechaNacimiento != "" && this.state.FechaNacimiento != "0000-12-31" ? this.state.FechaNacimiento : "0001-01-01",
          // FechaRegistro: moment(this.state.DetalleAlumno.FechaRegistro).format("YYYY-MM-DD"),
          FechaRegistro:this.state.DetalleAlumno.FechaRegistro,
          Foto: this.state.FotoAlumno !== null ? this.state.FotoAlumno : null,
          IdiomasInscrito: null,
          ListaGrupos: null,
          Motivos: null,
          MunicipioNacimiento: this.state.IdMunicipioNacimiento !== "" ? this.state.IdMunicipioNacimiento : idVacio,
          Nombre: this.state.Nombre,
          NombreCompleto: this.state.Nombre + " " + this.state.ApellidoPaterno + " " + this.state.ApellidoMaterno,
          //NombreTutor: this.state.NombreTutor,
          NumeroFamiliaresEstudiando: this.state.DetalleAlumno.NumeroFamiliaresEstudiando,
          Pais: this.state.IdPais !== "" ? this.state.IdPais : idVacio,
          PaisNacimiento: this.state.IdPaisNacimiento !== "" ? this.state.IdPaisNacimiento : idVacio,
          //Telefono: this.state.Telefono,
          //TelefonoTutor: this.state.TelefonoTutor,
          _idEmpresa: this.state.IDEmpresa !== "" ? this.state.IDEmpresa : idVacio,
          _idProspecto: this.state.DetalleAlumno._idProspecto
        }
      }, () => this.RegistrarAlumno())
    }
    else if(this.props.op == 3){
      this.setState({
        ObjAlumno:{
          ApelidoMaterno: this.state.ApellidoMaterno,
          ApellidoPaterno: this.state.ApellidoPaterno,
          AplicaDescuentoTemporal: false,
          CalleNumero: this.state.CalleNumero,
          Ciudad: this.state.IdMunicipio !== "" ? this.state.IdMunicipio : idVacio,
          Clase: this.state.OpcionClase,
          Colonia: this.state.Colonia,
          ContactosAlumno: {ContactoAlumno: this.state.ListaContactos},
          DatosFiscales: this.state.idDatosFiscales !== "" ? this.state.idDatosFiscales : idVacio,
          DiaEspecialPago: parseInt(this.state.DiaPago),
          DiaEspecialPago2: parseInt(this.state.DiaPago2),
          Estado: this.state.IdEstado !== "" ? this.state.IdEstado : idVacio,
          EstadoAlumno: "Activo",
          EstadoNacimiento: this.state.IdEstadoNacimiento !== "" ? this.state.IdEstadoNacimiento : idVacio,
          FechaNacimiento: this.state.FechaNacimiento != "" && this.state.FechaNacimiento != "0000-12-31" ? this.state.FechaNacimiento : "0001-01-01",
          // FechaRegistro: moment(this.state.DetalleAlumno.FechaRegistro).format("YYYY-MM-DD"),
          FechaRegistro:this.state.DetalleAlumno.FechaRegistro,
          Foto: this.state.FotoAlumno !== null ? this.state.FotoAlumno : null,
          IdiomasInscrito: null,
          ListaGrupos: null,
          Motivos: null,
          MunicipioNacimiento: this.state.IdMunicipioNacimiento !== "" ? this.state.IdMunicipioNacimiento : idVacio,
          Nombre: this.state.Nombre,
          NombreCompleto: this.state.Nombre + " " + this.state.ApellidoPaterno + " " + this.state.ApellidoMaterno,
          NumeroFamiliaresEstudiando: 0,
          Pais: this.state.IdPais !== "" ? this.state.IdPais : idVacio,
          PaisNacimiento: this.state.IdPaisNacimiento !== "" ? this.state.IdPaisNacimiento : idVacio,
          _idEmpresa: this.state.IDEmpresa !== "" ? this.state.IDEmpresa : idVacio,
        }
      }, () => this.RegistrarAlumno())
    }
  }
  
}

async RegistrarAlumno() {
  // console.log("op",this.props.op)
  // console.log("ObjAlumno",this.state.ObjAlumno)
  // return

  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/RegistrarAlumno', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({alumno: this.state.ObjAlumno})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              //this.showNotification();
          } else { //Filtrado exitoso
              this.setState({cargando: false });
              this.RegresarMensaje(data.mensaje);
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  RegresarDatosFiscales = (mensaje, idDatosFiscal, DatosFiscales) => {
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

    this.ObtenerInformacionDatoFiscal(idDatosFiscal);
    this.setState({ idDatosFiscales: idDatosFiscal, mensaje: mensaje, colorNotificacion: "success" });
    this.showNotification();
    if(JSON.stringify(DatosFiscales._idciudad) !== JSON.stringify(idVacio)){
      this.ObtenerInformacionMunicipioFiscal(DatosFiscales._idciudad)
    }
    if(JSON.stringify(DatosFiscales._idEstado) !== JSON.stringify(idVacio)){
      this.ObtenerInformacionEstadoFiscal(DatosFiscales._idEstado);
    }
  }

  RegresarDescuentoTemp = (mensaje) => {
    this.setState({mensaje: mensaje, colorNotificacion: "success"})
    this.showNotification();
    this.ListaDescuentosActivosAlumno(this.state.DetalleAlumno._id);
  }

  RegresarFoto = (foto) => {
    this.setState({FotoAlumno: foto.split(',')[1]})
  }

// Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    this.setState({ op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  openModalDatosFiscales = () => {
    this.setState({ showDatosFiscales: true, Titulo: "Agregar datos fiscales", op: 0 });
  }

  openModalDatosFiscalesEditar = () => {
    this.setState({ showDatosFiscales: true, Titulo: "Editar datos fiscales", op: 1 });
  }

  openModalDescuentoTemp = (int, descuento) => {
    this.setState({ showDescuentoTemp: true, DescuentoSeleccionado: descuento !== 0 ? descuento : "", Titulo: "Descuento temporal", op: int });
  }

  openModalCapturarFoto = () => {
    this.setState({ showCapturarFoto: true, Titulo: "Capturar imagen", op: 0 });
  }

  openModalPregunta = (opcion, descuento, titulo, cuerpo) => {
    if(opcion == 2){
      this.setState({opcionModalPregunta: opcion, DescuentoSeleccionado: descuento, TituloModalPregunta: titulo , CuerpoModalPregunta: cuerpo, show: true });
    }
    else{
      this.setState({opcionModalPregunta: opcion, TituloModalPregunta: titulo , CuerpoModalPregunta: cuerpo, show: true });
    }
}

  openModalInfoGrupo = (grupo) => {
    this.setState({GrupoSeleccionado: grupo, showInfoGrupo: true})
  }

  eventoDatosFiscales = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showDatosFiscales: false, op: 0 })
    if (mensaje != 'esc') {
        this.setState({ mensaje: mensaje, colorNotificacion: "success" })
        this.showNotification();
    }
  }

  eventoDescuentoTemp = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showDescuentoTemp: false, op: 0 })
    if (mensaje != 'esc') {
        this.setState({ mensaje: mensaje, colorNotificacion: "success" })
        this.showNotification();
    }
  }

  eventoCapturarFoto = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showCapturarFoto: false, op: 0 })
    if (mensaje != 'esc') {
        this.setState({ mensaje: mensaje, colorNotificacion: "success" })
        this.showNotification();
    }
  }

  eventoModalPregunta = (valor) => {
    this.setState({ show: false })
    if(valor){
      if(this.state.opcionModalPregunta == 1){
        this.setState({
          InfoDatoFiscal: {}, 
          idDatosFiscales: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
          InfoMunicipioFiscal: {},
          InfoEstadoFiscal: {},
          TituloModalPregunta: "",
          CuerpoModalPregunta: "",
          opcionModalPregunta: 0
        })
      }
      else if(this.state.opcionModalPregunta == 2){
        this.EliminarDescuentoTemporal();
        this.setState({
          TituloModalPregunta: "",
          CuerpoModalPregunta: "",
          opcionModalPregunta: 0
        })
      }
      
    }
}

  eventoModalInfoGrupo = (valor) => {
    this.setState({showInfoGrupo: false, GrupoSeleccionado: {}})
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;

    switch(name){
      case "DiaPago":
        let diapago = value.replace(/[^0-9]/g, '');
        if(diapago > 30){
          this.setState({[name]: 30});
        }
        else{
          this.setState({[name]: diapago});
        }
        break;

        case "DiaPago2":
        let diapago2 = value.replace(/[^0-9]/g, '');
        if(diapago2 > 30){
          this.setState({[name]: 30});
        }
        else{
          this.setState({[name]: diapago2});
        }
        break;

      case "IdPaisNacimiento":
        this.setState({
          [name]: value, 
          IdEstadoNacimiento: "", 
          IdMunicipioNacimiento: "",
          ListaEstadosNacimiento: [],
          ListaMunicipioNacimiento: [],
        });
        this.ListaEstadosPaisNacimiento(value, 0);
        break;

      case "IdEstadoNacimiento":
        this.setState({
          [name]: value,
          IdMunicipioNacimiento: "",
          ListaMunicipioNacimiento: [],
        });
        this.ListaMunicipiosEstadoNacimiento(value, 0);
        break;

      case "IdPais":
        this.setState({
          [name]: value,
          IdEstado: "",
          IdMunicipio: "",
          ListaEstados: [],
          ListaMunicipio: [],
        });
        this.ListaEstadosPais(value, 0);
        break;
      
      case "IdEstado":
        this.setState({
          [name]: value,
          IdMunicipio: "",
          ListaMunicipiosEstado: [],
        });
        this.ListaMunicipiosEstado(value, 0)
        break;
      
      case "FechaNacimiento":
        if(new Date(value) > new Date()){
          this.setState({[name]: moment(new Date()).format("YYYY-MM-DD")});
        }
        else{
          this.setState({[name]: value,});
        }
        break;

      default:
        this.setState({[name]: value,});
        break;
    }
  }

  handleFocus = (event) => event.target.select();

  handleChangeContacto(e, item){
    const { name, value } = e.target;
    const IsEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let index = this.state.ListaContactos.indexOf(item);

    //console.log(item)

    //console.log(index)

    switch(name){
      case "opcionParentescoMap":
        this.state.ListaContactos[index].EnumTipoContactoAlumno = value;

        this.setState({ListaContactos: this.state.ListaContactos});
        break;
      case "NombreContactoMap":
        this.state.ListaContactos[index].Nombre = value;

        this.setState({ListaContactos: this.state.ListaContactos});
        break;
      case "CelularContactoMap":
        this.state.ListaContactos[index].Celular = new AsYouType('MX').input(value);

        this.setState({ListaContactos: this.state.ListaContactos});
        break;
      case "TelefonoContactoMap":
        this.state.ListaContactos[index].Telefono = new AsYouType('MX').input(value);

        this.setState({ListaContactos: this.state.ListaContactos});
        break;
      case "CorreoContactoMap":
          this.state.ListaContactos[index].Correo = value;
          this.setState({ListaContactos: this.state.ListaContactos});
        break;
    }
  }

  AñadirContacto(){
    let contacto = {
      Celular: "",
      Correo: "",
      EnumTipoContactoAlumno: "Alumno",
      Nombre: "",
      Telefono: ""
    }

    this.setState({
      ListaContactos: [...this.state.ListaContactos, contacto],
      errorNombreContacto: false,
      errorCelularContacto: false,
      errorTelefonoContacto: false,
      errorCorreoContacto: false
    });
  }

  EliminarContacto(contacto){
    let index;
    index = this.state.ListaContactos.indexOf(contacto);
    this.state.ListaContactos.splice(index, 1);

    this.setState({ListaContactos: this.state.ListaContactos});
  }

  ValidarModulo(modulo){
    let valido = false;

    Permisos.map(permiso => {
      if(parseInt(permiso.NumeroModulo) == modulo){
        valido = true
      }
    })

    return valido
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  handleBlur = (e) => {
    const { name, value } = e.target;

    if(value == ""){
      this.setState({ [name]: 0 })
    }
    else if(isNaN(value)){
      this.setState({ [name]: 0 })
    }
    else{
      this.setState({ [name]: value })
    }
  }
 
  render() {
    //console.log(this.state.DetalleAlumno)
    //console.log(this.state.ListaDescuentosTemp)
    // console.log(this.state.ListaPaises)

    let agregar;
        if (this.state.showDatosFiscales == true) {
          agregar =
            <ModalDatosFiscales
              Titulo={this.state.Titulo}
              showDatosFiscales={this.state.showDatosFiscales}
              eventClick={this.eventoDatosFiscales}
              op={this.state.op}
              DatosFiscales={this.state.InfoDatoFiscal}
              parentCallback={this.RegresarDatosFiscales}
            />
        }

        if (this.state.showDescuentoTemp == true) {
          agregar =
            <ModalDescuentoTemp
              Titulo={this.state.Titulo}
              showDescuentoTemp={this.state.showDescuentoTemp}
              eventClick={this.eventoDescuentoTemp}
              op={this.state.op}
              _id={this.state.DetalleAlumno._id}
              parentCallback={this.RegresarDescuentoTemp}
              DescuentoSeleccionado={this.state.DescuentoSeleccionado}
              DescuentosTemp={this.state.ListaDescuentosTemp}
            />
        }

        if (this.state.showCapturarFoto == true) {
          agregar =
            <ModalCapturarFoto
              Titulo={this.state.Titulo}
              showCapturarFoto={this.state.showCapturarFoto}
              eventClick={this.eventoCapturarFoto}
              op={this.state.op}
              parentCallback={this.RegresarFoto}
            />
        }

        if(this.state.showInfoGrupo == true){
          agregar = (
            <ModalInfoGrupo
              showInfoGrupo={this.state.showInfoGrupo}
              Titulo={"Información basica del grupo"}
              GrupoSeleccionado={this.state.GrupoSeleccionado}
              eventClick={this.eventoModalInfoGrupo}
            />
          );
        }

        if (this.state.cargando == true) {
          agregar = (
            <ModalCargando
              Cuerpo={this.state.CuerpoCargando}
              show={this.state.cargando}
            />
          );
        }

    const { classes } = this.props;
    const IdVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

    let calle = "";
    let numero = "";
    let colonia = "";
    let codigopostal = "";
    let ciudad = "";
    let estado = "";

    if(this.state.InfoDatoFiscal.Calle !== undefined && this.state.InfoDatoFiscal.Calle !== null){
      calle = this.state.InfoDatoFiscal.Calle;
    }
    if(this.state.InfoDatoFiscal.Numero !== undefined && this.state.InfoDatoFiscal.Numero !== null){
      numero = " No. " + this.state.InfoDatoFiscal.Numero;
    }
    if(this.state.InfoDatoFiscal.Colonia !== undefined && this.state.InfoDatoFiscal.Colonia !== null){
      colonia = " " + this.state.InfoDatoFiscal.Colonia;
    }
    if(this.state.InfoDatoFiscal.CodigoPostal !== undefined && this.state.InfoDatoFiscal.CodigoPostal !== null){
      codigopostal = " C.P. " + this.state.InfoDatoFiscal.CodigoPostal;
    }
    if(Object.keys(this.state.InfoMunicipioFiscal).length !== 0){
      ciudad = this.state.InfoMunicipioFiscal.NombreMunicipio + ", ";
    }
    if(Object.keys(this.state.InfoEstadoFiscal).length !== 0){
      estado = this.state.InfoEstadoFiscal.NombreEstado;
    }

    let DatosFiscales;
    if(Object.keys(this.state.InfoDatoFiscal).length !== 0){
      DatosFiscales = 
      <table>
        <thead>
          <th>Razón social</th>
          <th>RFC</th>
          <th>Correo</th>
          <th>Dirección</th>
          <th>Ciudad</th>
          <th></th>
        </thead>
        <tbody>
          <tr style={{fontSize: "12px"}}>
            <td>{this.state.InfoDatoFiscal.RazonSocial !== undefined && this.state.InfoDatoFiscal.RazonSocial !== null ? this.state.InfoDatoFiscal.RazonSocial : "Sin especificar"}</td>
            <td>{this.state.InfoDatoFiscal.RFC !== undefined && this.state.InfoDatoFiscal.RFC !== null ? this.state.InfoDatoFiscal.RFC : "Sin especificar"}</td>
            <td>{this.state.InfoDatoFiscal.Correo !== undefined && this.state.InfoDatoFiscal.Correo !== null ? this.state.InfoDatoFiscal.Correo : "Sin especificar"}</td>
            <td>{calle + numero + colonia + codigopostal !== "" ? calle + numero + colonia + codigopostal : "Sin especificar"}</td>
            <td>{ciudad + estado !== "" ? ciudad + estado : "Sin especificar"}</td>
            <td>
              <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar datos fiscales" color="danger" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openModalPregunta(1, "Datos fiscales", "¿Está seguro de eliminar los datos fiscales?")}><Close /></Button>
              <Button data-toggle="tooltip" data-placement="bottom" title="Editar datos fiscales" color="info" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openModalDatosFiscalesEditar()}><Edit /></Button>
            </td>
          </tr>
        </tbody>
      </table>
    }
    else{
      DatosFiscales = <>
      <div style={{textAlign: "center"}}>NO TIENE DATOS FISCALES REGISTRADOS</div>
      <Button data-toggle="tooltip" data-placement="bottom" title="Agregar datos fiscales" color="info" tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.openModalDatosFiscales()}><AddCircle/>&nbsp;Agregar</Button>
      </>
    }

    let DescuentoTemp;
    if(this.state.ListaDescuentosTemp.length > 0){
      DescuentoTemp =
      <table>
        <thead>
          <th></th>
          <th style={{fontSize: "14px", textAlign: "center"}}>Concepto</th>
          <th style={{fontSize: "14px", textAlign: "center"}}>Subconcepto</th>
          <th style={{fontSize: "14px", textAlign: "center"}}>Idioma</th>
          <th style={{fontSize: "14px", textAlign: "center"}}>Descuento</th>
          <th style={{fontSize: "14px", textAlign: "center"}}>Fecha inicio</th>
          <th style={{fontSize: "14px", textAlign: "center"}}>Fecha fin</th>
        </thead>
        <tbody>
          {this.state.ListaDescuentosTemp.map(descuento => {
            return(
              <tr>
                <td style={{fontWeight: "bold", textDecoration: "underline"}}>{descuento.Tipo}</td>
                <td style={{fontSize: "12px", textAlign: "center"}}>{descuento.NombreConceptoDescuento}</td>
                <td style={{fontSize: "12px", textAlign: "center"}}>{descuento.NombreSubConceptoDescuento}</td>
                <td style={{fontSize: "12px", textAlign: "center"}}>{descuento.NombreIdioma}</td>
                <td style={{fontSize: "12px", textAlign: "center"}}>{descuento.Descuento + " %"}</td>
                <td style={{fontSize: "12px", textAlign: "center"}}>{(moment(descuento.MesInicioDescuento).format('MMMM YYYY')).toUpperCase()}</td>
                <td style={{fontSize: "12px", textAlign: "center"}}>{(moment(descuento.MesTerminacionDescuento).subtract(1, "day").format('MMMM YYYY')).toUpperCase()}</td>
                {this.ValidarModulo(56) ? <td>
                  <Button data-toggle="tooltip" data-placement="bottom" title="Editar descuento temporal" color="info" round justIcon size="sm" className="mr-2" onClick={() => this.openModalDescuentoTemp(1, descuento)}><Edit /></Button>
                  <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar descuento temporal" color="danger" round justIcon size="sm" className="mr-2" onClick={() => this.openModalPregunta(2, descuento, "Eliminar descuento temporal", "¿Está seguro que desea eliminar la informacion del descuento temporal del alumno?")}><Close /></Button>
                </td> : ""}
              </tr>
            )
            })}
        </tbody>
      </table>   
    }
    else{
      DescuentoTemp =
        <div style={{textAlign: "center"}}>NINGÚN DESCUENTO TEMPORAL</div>
    }

    let Contacto;
      Contacto = 
      this.state.ListaContactos.map((contacto, index) => {
        if(index == 0){
          return(
            <tr>
              <td>
                <FormControl className={classes.formControl} size="small" variant="outlined">
                <InputLabel style={{marginTop: "10px"}} id="parentesco">Parentesco</InputLabel>
                    <Select
                        labelId="parentesco"
                        //fullWidth={true}
                        value={contacto.EnumTipoContactoAlumno}
                        onChange={(e) => this.handleChangeContacto(e, contacto)}
                        name="opcionParentescoMap"
                        label="Parentesco"
                        style={{ width: "8em", marginTop: "10px" }}
                    >
                      <MenuItem disabled>Seleccione parentesco</MenuItem>
                        <MenuItem value={"Alumno"}>Alumno</MenuItem>
                        <MenuItem value={"Mamá"}>Mamá</MenuItem>
                        <MenuItem value={"Papá"}>Papá</MenuItem>
                        <MenuItem value={"Otro"}>Otro</MenuItem>
                    </Select>
                </FormControl>
              </td>
              <td>
                <TextField
                    id="NombreContacto"
                    style={{ width: "10em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Nombre"
                    onChange={(e) => this.handleChangeContacto(e, contacto)}
                    InputProps={{
                      //onChange: this.handleChange.bind(this),
                      value: contacto.Nombre,
                      name: "NombreContactoMap",
                      id: "NombreContactoMap",
                      error: this.state.errorNombreContacto,
                      success: !this.state.errorNombreContacto
                    }}
                />
              </td>
              <td>
                <TextField
                    id="CelularContacto"
                    style={{ width: "10em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Celular"
                    onChange={(e) => this.handleChangeContacto(e, contacto)}
                    InputProps={{
                      //onChange: this.handleChange.bind(this),
                      value:  contacto.Celular,
                      name: "CelularContactoMap",
                      id: "CelularContactoMap",
                      error: this.state.errorTelefonoContacto,
                      success: !this.state.errorTelefonoContacto
                    }}
                />
              </td>
              <td>
                <TextField
                    id="TelefonoContacto"
                    style={{ width: "10em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Teléfono"
                    onChange={(e) => this.handleChangeContacto(e, contacto)}
                    InputProps={{
                      //onChange: this.handleChange.bind(this),
                      value:  contacto.Telefono,
                      name: "TelefonoContactoMap",
                      id: "TelefonoContactoMap",
                      error: this.state.errorTelefonoContacto,
                      success: !this.state.errorTelefonoContacto
                    }}
                />
              </td>
              <td>
                <TextField
                    id="CorreoContacto"
                    style={{ width: "10em", marginTop: "10px" }}
                    variant="outlined"
                    type="email"
                    size="small"
                    label="Correo"
                    onChange={(e) => this.handleChangeContacto(e, contacto)}
                    InputProps={{
                      //onChange: this.handleChange.bind(this),
                      value: contacto.Correo,
                      name: "CorreoContactoMap",
                      id: "CorreoContactoMap",
                      error: this.state.errorCorreoContacto,
                      success: !this.state.errorCorreoContacto
                    }}
                />
              </td>
            </tr>
          )
        }
        else{
          return(
            <tr>
              <td>
                <FormControl className={classes.formControl} size="small" variant="outlined">
                <InputLabel style={{marginTop: "10px"}} id="parentesco">Parentesco</InputLabel>
                    <Select
                        id={index}
                        labelId="parentesco"
                        //fullWidth={true}
                        value={contacto.EnumTipoContactoAlumno}
                        onChange={(e) => this.handleChangeContacto(e, contacto)}
                        name="opcionParentescoMap"
                        label="Parentesco"
                        style={{ width: "8em", marginTop: "10px" }}
                    >
                      <MenuItem disabled>Seleccione parentesco</MenuItem>
                        <MenuItem value={"Alumno"}>Alumno</MenuItem>
                        <MenuItem value={"Mamá"}>Mamá</MenuItem>
                        <MenuItem value={"Papá"}>Papá</MenuItem>
                        <MenuItem value={"Otro"}>Otro</MenuItem>
                    </Select>
                </FormControl>
              </td>
              <td>
                <TextField
                    id={index}
                    style={{ width: "10em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Nombre"
                    onChange={(e) => this.handleChangeContacto(e, contacto)}
                    InputProps={{
                      //onChange: this.handleChange.bind(this),
                      value: contacto.Nombre,
                      name: "NombreContactoMap",
                      id: "NombreContactoMap",
                      //error: this.state.errorNombreContacto,
                      //success: !this.state.errorNombreContacto
                    }}
                />
              </td>
              <td>
                <TextField
                    id={index}
                    style={{ width: "10em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Celular"
                    onChange={(e) => this.handleChangeContacto(e, contacto)}
                    InputProps={{
                      //onChange: this.handleChange.bind(this),
                      value:  contacto.Celular,
                      name: "CelularContactoMap",
                      id: "CelularContactoMap",
                      //error: this.state.errorTelefonoContacto,
                      //success: !this.state.errorTelefonoContacto
                    }}
                />
              </td>
              <td>
                <TextField
                    id={index}
                    style={{ width: "10em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Teléfono"
                    onChange={(e) => this.handleChangeContacto(e, contacto)}
                    InputProps={{
                      //onChange: this.handleChange.bind(this),
                      value:  contacto.Telefono,
                      name: "TelefonoContactoMap",
                      id: "TelefonoContactoMap",
                      //error: this.state.errorTelefonoContacto,
                      //success: !this.state.errorTelefonoContacto
                    }}
                />
              </td>
              <td>
                <TextField
                    id={index}
                    style={{ width: "10em", marginTop: "10px" }}
                    variant="outlined"
                    type="email"
                    size="small"
                    label="Correo"
                    onChange={(e) => this.handleChangeContacto(e, contacto)}
                    InputProps={{
                      //onChange: this.handleChange.bind(this),
                      value: contacto.Correo,
                      name: "CorreoContactoMap",
                      id: "CorreoContactoMap",
                      //error: this.state.errorCorreoContacto,
                      //success: !this.state.errorCorreoContacto
                    }}
                />
                <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar contacto" style={{marginTop: "15px"}} color="danger" round justIcon size="sm" className="ml-3" onClick={() => this.EliminarContacto(contacto)}><Close /></Button>
              </td>
            </tr>
          )
        }
      })

    let Grupos;
    if(this.state.ListaGruposAlumno.length !== 0){
      Grupos = (
        this.state.ListaGruposAlumno.map(grupo => {
          return(
            <GridItem xs={12} sm={12} md={12} lg={12} style={{display: "flex", alignItems: "baseline", justifyContent: "flex-start"}}>
              <h6>{grupo.NombreGrupo}</h6>
              <Button data-toggle="tooltip" data-placement="bottom" title="Ver información del grupo" color="info" round justIcon size="sm" className="ml-2" onClick={() => this.openModalInfoGrupo(grupo)}><Info /></Button>
            </GridItem>
          );
        })
      );
    }

    //console.log("AlumnoSeleccionado", this.state.AlumnoSeleccionado)
    //console.log("Detalle alumno", this.state.DetalleAlumno)
    //console.log(this.state.ListaPaises)
    //console.log(this.state.IdPais)
    //console.log(this.state.ListaEstados)
    //console.log(this.state.ListaMunicipio)
    //console.log(this.state.InfoDatoFiscal)
    //console.log(this.state.idDatosFiscales)
    //console.log(this.state.ListaDescuentosTemp)
    //console.log(this.state.FotoAlumno)
    //console.log(this.state.ListaContactos)
    //console.log("Id municipio",this.state.IdMunicipioNacimiento)
    //console.log("ListaMunicipio",this.state.ListaMunicipioNacimiento)
    // console.log(this.state.ListaGruposAlumno)

    // console.log({"DiaPago": this.state.DiaPago, "DiaPago2": this.state.DiaPago2})

    return (
      <div>
        <Modal
          isOpen={this.props.showModalEditarAlumno}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
          style={{ maxWidth: "920px", width: "100%" }}
        >
          <Card>
              <CardHeader color="info" text>
                  <h4 className={classes.cardTitle}>Información general</h4>
              </CardHeader>
              <CardBody>
                  <GridContainer>
                      <GridItem xs={12} sm={12} md={6} lg={4}>
                      {this.state.FotoAlumno == null ?
                              <img className="overflow-hidden mt-3" style={{ width: "200x", height: "200px", borderRadius: "5px", marginLeft: "5.5em" }} src={Persona} />
                              : <img className="overflow-hidden mt-3" style={{ width: "200x", height: "200px", borderRadius: "5px", marginLeft: "5.5em" }} src={`data:image/png;base64,${this.state.FotoAlumno}`} />}
                        <Button color="info" round size="sm" style={{marginLeft: "14.4em"}} className="mt-2" onClick={() => this.openModalCapturarFoto()}><CameraAlt />&nbsp;Capturar</Button>      
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6} style={{marginLeft: "10.8em"}}>
                        <div hidden={this.state.DetalleAlumno.NumeroCuenta == null}>Número de cuenta:<b>{" " + this.state.DetalleAlumno.NumeroCuenta}</b></div>
                        <div hidden={moment(this.state.DetalleAlumno.FechaRegistro).format("YYYY-MM-DD") == "0001-01-01" || this.state.DetalleAlumno.FechaRegistro == undefined}>Fecha de registro:<b>{" " + moment(this.state.DetalleAlumno.FechaRegistro).format("DD-MM-YYYY")}</b></div>
                        <TextField
                              id="Nombre"
                              style={{ width: "25em", marginTop: "10px" }}
                              variant="outlined"
                              type="text"
                              size="small"
                              label="Nombre"
                              InputProps={{
                                  onChange: this.handleChange.bind(this),
                                  value: this.state.Nombre,
                                  name: "Nombre",
                                  id: "Nombre",
                                  error: this.state.errorNombre,
                                  success: !this.state.errorNombre
                              }}
                          />
                          <TextField
                              id="ApellidoPaterno"
                              style={{ width: "25em", marginTop: "10px" }}
                              variant="outlined"
                              type="text"
                              size="small"
                              label="Apellido paterno"
                              InputProps={{
                                  onChange: this.handleChange.bind(this),
                                  value: this.state.ApellidoPaterno,
                                  name: "ApellidoPaterno",
                                  id: "ApellidoPaterno",
                                  error: this.state.errorApellidoPaterno,
                                  success: !this.state.errorApellidoPaterno
                              }}
                          />

                          <TextField
                              id="ApellidoMaterno"
                              style={{ width: "25em", marginTop: "10px" }}
                              variant="outlined"
                              type="text"
                              size="small"
                              label="Apellido materno"
                              InputProps={{
                                  onChange: this.handleChange.bind(this),
                                  value: this.state.ApellidoMaterno,
                                  name: "ApellidoMaterno",
                                  id: "ApellidoMaterno",
                                  error: this.state.errorApellidoMaterno,
                                  success: !this.state.errorApellidoMaterno
                              }}
                          />

                          <FormControl className={classes.formControl} size="small" variant="outlined">
                          <InputLabel style={{marginTop: "10px"}} id="Claselabel">Clase</InputLabel>
                              <Select
                                  labelId="Claselabel"
                                  //fullWidth={true}
                                  value={this.state.OpcionClase}
                                  onChange={this.handleChange.bind(this)}
                                  name="OpcionClase"
                                  label="Clase"
                                  style={{ width: "21.9em", marginTop: "10px" }}
                              >
                                <MenuItem disabled>Seleccione clase</MenuItem>
                                  <MenuItem value={"Niño"}>Niño</MenuItem>
                                  <MenuItem value={"Adolescente"}>Adolescente</MenuItem>
                                  <MenuItem value={"Adulto"}>Adulto</MenuItem>
                              </Select>
                          </FormControl>

                          <FormControl className={classes.formControl} size="small" variant="outlined">
                          <InputLabel style={{marginTop: "10px"}} id="Empresa">Empresa</InputLabel>
                              <Select
                                  labelId="Empresa"
                                  //fullWidth={true}
                                  value={this.state.IDEmpresa}
                                  onChange={this.handleChange.bind(this)}
                                  name="IDEmpresa"
                                  label="Empresa"
                                  style={{ width: "21.9em", marginTop: "10px" }}
                                  error={this.state.errorEmpresa}
                                  success={!this.state.errorEmpresa}
                              >
                                <MenuItem disabled>Seleccione una empresa</MenuItem>
                                  {this.state.ListaEmpresas.map(Empresa =>
                                    <MenuItem value={Empresa._id}>{(Empresa.RazonSocial).toUpperCase()}</MenuItem>
                                  )}
                              </Select>
                          </FormControl>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}><h6 className="mt-3"><b style={{fontWeight: "bold"}}>Grupos:</b></h6></GridItem>
                            {Grupos}
                          </GridContainer>
                      </GridItem>
                  </GridContainer>
              </CardBody>
          </Card>
          {this.state.DetalleAlumno.EstadoAlumno == "Inactivo" ? <Card>
            <CardHeader color="info">Información de su baja</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={12}>
                  <div className="mt-3" style={{display: "flex"}}><h6><b style={{fontWeight: "bold"}}>Fecha de baja: </b></h6> <div className="ml-3">{moment(this.state.DetalleAlumno.FechaDadoBaja).format("DD/MM/YYYY")}</div></div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={12}>
                <div className="mt-3" style={{display: "flex"}}><h6><b style={{fontWeight: "bold"}}>Motivos: </b></h6> <div className="ml-3">{this.state.DetalleAlumno.Motivos !== null && this.state.DetalleAlumno.Motivos !== undefined ? this.state.DetalleAlumno.Motivos : ""}</div></div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card> : null}
          <Card>
            <CardHeader color="info">Lugar y fecha de nacimiento</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined" style={{marginLeft: "3em"}}>
                  <InputLabel style={{marginTop: "10px"}} id="Pais">País</InputLabel>
                      <Select
                          labelId="Pais"
                          //fullWidth={true}
                          value={this.state.IdPaisNacimiento}
                          onChange={this.handleChange.bind(this)}
                          name="IdPaisNacimiento"
                          label="País"
                          style={{ width: "21.9em", marginTop: "10px" }}
                      >
                        <MenuItem disabled>Seleccione un país</MenuItem>
                          {this.state.ListaPaisesNacimiento.map(pais =>
                            <MenuItem value={pais._id}>{(pais.NombrePais)}</MenuItem>
                          )}
                      </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="Estado">Estado</InputLabel>
                      <Select
                          labelId="Estado"
                          //fullWidth={true}
                          value={this.state.IdEstadoNacimiento}
                          onChange={this.handleChange.bind(this)}
                          name="IdEstadoNacimiento"
                          label="Estado"
                          style={{ width: "21.9em", marginTop: "10px" }}
                      >
                        <MenuItem disabled>Seleccione un estado</MenuItem>
                          {this.state.ListaEstadosNacimiento.map(estado =>
                            <MenuItem value={estado._id}>{(estado.NombreEstado)}</MenuItem>
                          )}
                      </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined" style={{marginLeft: "3em"}}>
                  <InputLabel style={{marginTop: "10px"}} id="Ciudad">Ciudad</InputLabel>
                      <Select
                          labelId="Ciudad"
                          //fullWidth={true}
                          value={this.state.IdMunicipioNacimiento}
                          onChange={this.handleChange.bind(this)}
                          name="IdMunicipioNacimiento"
                          label="Ciudad"
                          style={{ width: "21.9em", marginTop: "10px" }}
                      >
                        <MenuItem disabled>Seleccione un municipio</MenuItem>
                          {this.state.ListaMunicipioNacimiento.map(Ciudad =>
                            <MenuItem value={Ciudad._id}>{(Ciudad.NombreMunicipio)}</MenuItem>
                          )}
                      </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                      id="FechaNacimiento"
                      style={{ width: "25em", marginTop: "10px" }}
                      variant="outlined"
                      type="date"
                      format="DD-MM-YYYY"
                      size="small"
                      label="Fecha de nacimiento"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.FechaNacimiento,
                        name: "FechaNacimiento",
                        id: "FechaNacimiento",
                        //error: this.state.errorFechainicio,
                        //success: !this.state.errorFechainicio
                      }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Dirección</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined" style={{marginLeft: "3em"}}>
                  <InputLabel style={{marginTop: "10px"}} id="Pais">País</InputLabel>
                      <Select
                          labelId="Pais"
                          //fullWidth={true}
                          value={this.state.IdPais}
                          onChange={this.handleChange.bind(this)}
                          name="IdPais"
                          label="País"
                          style={{ width: "21.9em", marginTop: "10px" }}
                      >
                        <MenuItem disabled>Seleccione un país</MenuItem>
                          {this.state.ListaPaises.map(pais =>
                            <MenuItem value={pais._id}>{(pais.NombrePais)}</MenuItem>
                          )}
                      </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="Estado">Estado</InputLabel>
                      <Select
                          labelId="Estado"
                          //fullWidth={true}
                          value={this.state.IdEstado}
                          onChange={this.handleChange.bind(this)}
                          name="IdEstado"
                          label="Estado"
                          style={{ width: "21.9em", marginTop: "10px" }}
                      >
                        <MenuItem disabled>Seleccione un estado</MenuItem>
                          {this.state.ListaEstados.map(estado =>
                            <MenuItem value={estado._id}>{(estado.NombreEstado)}</MenuItem>
                          )}
                      </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined" style={{marginLeft: "3em"}}>
                  <InputLabel style={{marginTop: "10px"}} id="Ciudad">Ciudad</InputLabel>
                      <Select
                          labelId="Ciudad"
                          //fullWidth={true}
                          value={this.state.IdMunicipio}
                          onChange={this.handleChange.bind(this)}
                          name="IdMunicipio"
                          label="Ciudad"
                          style={{ width: "21.9em", marginTop: "10px" }}
                      >
                        <MenuItem disabled>Seleccione un municipio</MenuItem>
                          {this.state.ListaMunicipio.map(Ciudad =>
                            <MenuItem value={Ciudad._id}>{(Ciudad.NombreMunicipio)}</MenuItem>
                          )}
                      </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                      id="Colonia"
                      style={{ width: "25em", marginTop: "10px" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Colonia"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.Colonia,
                        name: "Colonia",
                        id: "Colonia",
                        //error: this.state.errorFechainicio,
                        //success: !this.state.errorFechainicio
                      }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                      id="CalleNumero"
                      style={{ width: "25em", marginTop: "10px", marginLeft: "3em" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Calle y numero"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.CalleNumero,
                        name: "CalleNumero",
                        id: "CalleNumero",
                        //error: this.state.errorFechainicio,
                        //success: !this.state.errorFechainicio
                      }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
          <CardHeader color="info">Contacto</CardHeader>
          <CardBody>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <div className="table-responsive table-wrapper-scroll-y">
                <table className="table table-hover">
                  <thead>
                    <th>Parentesco</th>
                    <th>Nombre</th>
                    <th>Celular</th>
                    <th>Teléfono</th>
                    <th>Correo</th>
                  </thead>
                  <tbody>
                    {Contacto}
                  </tbody>
                </table>
              </div>
              <Button color="info" tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.AñadirContacto()}><AddCircle/>&nbsp;Agregar</Button>
                </GridItem>
            </GridContainer>
          </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Datos fiscales</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {DatosFiscales}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Días de límite de pago</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                        id="DiaPago"
                        style={{ width: "15em", marginTop: "10px"}}
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Primer dia limite de pago"
                        disabled={this.ValidarModulo(57) ? false : true}
                        inputProps={{ maxLength: 2 }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          onFocus: this.handleFocus.bind(this),
                          onBlur: this.handleBlur.bind(this),
                          value: this.state.DiaPago,
                          name: "DiaPago",
                          id: "DiaPago",
                          // error: this.state.errorDiasPago,
                          //success: !this.state.errorFechainicio
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                        id="DiaPago2"
                        style={{ width: "15em", marginTop: "10px"}}
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Segundo dia limite de pago"
                        disabled={this.ValidarModulo(57) ? false : true}
                        inputProps={{ maxLength: 2 }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          onFocus: this.handleFocus.bind(this),
                          onBlur: this.handleBlur.bind(this),
                          value: this.state.DiaPago2,
                          name: "DiaPago2",
                          id: "DiaPago2",
                          error: this.state.errorDiasPago,
                          //success: !this.state.errorFechainicio
                        }}
                    />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Descuentos temporales</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {DescuentoTemp}
                  {this.ValidarModulo(56) ? <Button color="info" tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.openModalDescuentoTemp(0, 0)}><AddCircle/>&nbsp;Agregar</Button> : ""}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Button color="info" tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.CrearObjAlumno()}><Save/>&nbsp;Guardar</Button>
                <Button tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick("esc")}><Close/>&nbsp;Cancelar</Button>
            </GridItem>
          </GridContainer>   
        </Modal>
        <div id="">
            <ModalPregunta
                Titulo={this.state.TituloModalPregunta}
                Cuerpo={this.state.CuerpoModalPregunta}
                show={this.state.show}
                eventClick={this.eventoModalPregunta}
            />
            {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalEditarAlumno);