import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalInfoVenta from "../Facturacion/ModalInfoVenta.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import ModalFechaAcreditacion from "./ModalFechaAcreditacion.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'

class Acreditacion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: '',
            ListaEmpresas: [],
            Folios: [],
            idsAcreditacion: [],
            FolioAux: {},
            IDEmpresa: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
            FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaFin: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            opcionTipoPago: "Tarjeta_de_Crédito",
            opcionAcreditado: false,
            Fecha: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            Disabled: false,
            mensaje: "",
            mensajePagos: "",
            TituloAcreditacion: "Pagos no acreditados",
            br: false,
            show: false,
            cargando: false,
            CheckAll: false,
            colorNotificacion: 'success',
            colorTableRow: "white",
            errorFolioVenta: false,
            entroFiltrar: false,
            notificacion: false,
            Total: 0,
            TotalSeleccionado: 0,
            CuerpoCargando: "Cargando",
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
        //this.handleChange = this.handleChange.bind(this);
    }



    componentDidMount() {
        this.obtenerEmpresasActivas();
        let IDcookie = JSON.parse(cookie.get('id'));
        // this.setState({_id: IDcookie})
        this.ObtenerListaVentasRangoFechaSinAcreditarTipoPago(false)
        this.setState({ _id: IDcookie, opcionTipoPago: "Tarjeta_de_Crédito", })
    }

    //Función para pasar datos de los alumnos desde el modal hacia la vista
    RegresarMensaje = (mensaje) => {
        this.setState({ mensaje: mensaje, colorNotificacion: "success" });
        this.showNotification();
    }

    RegresarFecha = (fecha) => {
        this.setState({ fecha: fecha });
        this.ActualizarAcreditacionVentas(fecha);
    }

    //Función para obtener los pagos pendientes de los alumnos
    obtenerEmpresasActivas() {
        fetch(Globales.Url + 'Facturacion/Facturacion/ListadoEmpresas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify()
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1 });
                    this.showNotification();
                } else { //Filtrado exitoso

                    if (data.ListaEmpresas.length > 1) {
                        this.setState({ ListaEmpresas: data.ListaEmpresas, Activo: 1 });
                    }
                    else {
                        let ListaAuxEmpresas = [];
                        ListaAuxEmpresas.push(data.ListaEmpresas);
                        //console.log(ListaAux);
                        this.setState({ ListaEmpresas: ListaAuxEmpresas, Activo: 1 });
                    }
                }
            })
            .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }

    opcionBuscarAPI() {
        if (this.state.opcionAcreditado == false) {
            this.ObtenerListaVentasRangoFechaSinAcreditarTipoPago(false);
        }
        else {
            this.ObtenerListaVentasRangoFechaSinAcreditarTipoPago(true);
        }
    }

    OpcionAcreditacion(folio) {
        //this.setState({FolioAux: folio})

        if (this.state.idsAcreditacion.length !== 0) {
            if (this.state.opcionAcreditado == false) {
                this.openModalFecha();
            }
            else {
                this.openModalPregunta();
            }
        }
        else {
            this.setState({ mensaje: "No hay acreditaciones seleccionadas", colorNotificacion: "danger" })
            this.showNotification();
        }
    }


    /************************************ LLAMAR A LA API CONSULTA **********************************************/
    ObtenerListaVentasRangoFechaSinAcreditarTipoPago(boolean) {
        let valor1 = 0;
        let valor2 = 0;
        let valor3 = 0;

        // if(this.state.FechaInicio == "" || this.state.FechaFin == ""){
        //   valor1 = 1
        //   this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos de fechas", colorNotificacion: "danger"})
        //   this.showNotification();
        // }
        // else{
        //   valor1 = 0
        //   this.setState({errorFechainicio: false, errorFechaFin: false})
        // }

        // if(this.state.FechaInicio > this.state.FechaFin){
        //   valor2 = 1
        //   this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", colorNotificacion: "danger"})
        //   this.showNotification();
        // }
        // else{
        //   valor2 = 0
        //   this.setState({errorFechainicio: false, errorFechaFin: false})
        // }

        // if(this.state.FechaFin > cookie.get('FechaServidor')){
        //   valor3 = 1
        //   this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha final no puede ser mayor a la fecha actual", colorNotificacion: "danger"})
        //   this.showNotification();
        // }
        // else{
        //   valor3 = 0
        //   this.setState({errorFechainicio: false, errorFechaFin: false})
        // }

        if (valor1 + valor2 + valor3 == 0) {
            this.setState({
                cargando: true,
                Total: 0,
                TotalSeleccionado: 0,
            });
            fetch(Globales.Url + 'Acreditaciones/Acreditaciones/ObtenerListaVentasRangoFechaSinAcreditarTipoPago', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                }
                , body: JSON.stringify({
                    Inicio: this.state.FechaInicio,
                    Fin: this.state.FechaFin,
                    tipo: this.state.opcionTipoPago,
                    acreditado: boolean,
                    idEmpresa: this.state.IDEmpresa,
                    Usuario: JSON.parse(cookie.get('id'))
                })
            }).then(res => res.json())
                .then(data => {
                    //console.log(data);
                    if (data.error) { //Error en la respuesta
                        this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
                        this.showNotification();
                    } else { //Filtrado exitoso

                        //console.log(data.Folios)
                        if (data.Folios.length > 1) {
                            data.Folios.map(folio => {
                                folio.Seleccionado = false
                                this.ObtenerNombreAlumno(folio);
                            })

                            this.setState({
                                Folios: data.Folios,
                                Activo: 1,
                                cargando: false,
                                CheckAll: false
                            }, () => {
                                this.ContarTotales()
                            });
                        }
                        else {
                            this.ObtenerNombreAlumno(data.Folios);
                            let AuxVentaFolio = [];
                            data.Folios.Seleccionado = false;
                            AuxVentaFolio.push(data.Folios);

                            this.setState({
                                Folios: AuxVentaFolio,
                                Activo: 1,
                                cargando: false,
                                CheckAll: false
                            }, () => {
                                this.ContarTotales()
                            });
                        }
                    }
                })
                .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
        }
    }

    RegresarListaVentasRangoFechaSinAcreditarTipoPago(boolean) {
        this.setState({
            cargando: true,
            Total: 0,
            TotalSeleccionado: 0,
        });
        fetch(Globales.Url + 'Acreditaciones/Acreditaciones/RegresarListaVentasRangoFechaSinAcreditarTipoPago', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({
                Inicio: this.state.FechaInicio,
                Fin: this.state.FechaFin,
                tipo: this.state.opcionTipoPago,
                acreditado: boolean,
                idEmpresa: this.state.IDEmpresa,
                Usuario: JSON.parse(cookie.get('id'))
            })
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ Folios: [], Activo: 1, cargando: false });
                } else { //Filtrado exitoso

                    //console.log(data.Folios)
                    if (data.Folios.length > 1) {
                        data.Folios.map(folio => {
                            folio.Seleccionado = false
                            this.ObtenerNombreAlumno(folio);
                        })

                        this.setState({
                            Folios: data.Folios,
                            Activo: 1,
                            cargando: false,
                            CheckAll: false
                        }, () => {
                            this.ContarTotales()
                        });
                    }
                    else {
                        this.ObtenerNombreAlumno(data.Folios);
                        let AuxVentaFolio = [];
                        data.Folios.Seleccionado = false;
                        AuxVentaFolio.push(data.Folios);

                        this.setState({
                            Folios: AuxVentaFolio,
                            Activo: 1,
                            cargando: false,
                            CheckAll: false
                        }, () => {
                            this.ContarTotales()
                        });
                    }
                }
            })
            .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }

    ObtenerNombreAlumno(IdAlumno) {
        this.setState({ cargando: true })
        fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerNombreAlumno', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ _id: IdAlumno._idAlumno })
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", NombreAlumno: "", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso

                    let Folios = this.state.Folios
                    Folios.map(folio => {
                        if (JSON.stringify(folio._idAlumno) == JSON.stringify(IdAlumno._idAlumno)) {
                            folio.NombreAlumno = data.NombreAlumno;
                        }
                    })

                    this.setState({ Folios: Folios, cargando: false })
                }
            })
            .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }

    ActualizarAcreditacionVentas(fecha) {
        this.setState({ cargando: true });
        fetch(Globales.Url + 'Acreditaciones/Acreditaciones/ActualizarAcreditacionVentas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({
                ventas: this.state.idsAcreditacion,
                fechaAcreditacion: fecha,
                acreditadas: this.state.opcionAcreditado,
            })
        }).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso

                    if (this.state.opcionAcreditado == false) {
                        this.RegresarListaVentasRangoFechaSinAcreditarTipoPago(false);
                    }
                    else {
                        this.RegresarListaVentasRangoFechaSinAcreditarTipoPago(true);
                    }

                    this.setState({ mensaje: data.mensaje, colorNotificacion: "success", idsAcreditacion: [], Activo: 1, cargando: false });
                    this.showNotification();
                }
            })
            .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }


    //Función para abrir el modal de buscar alumnos
    openModalInfoVenta = (Venta) => {
        this.setState({ VentaSeleccionada: Venta, showInfoVenta: true, Titulo: "Información general", op: 1 });
    }

    openModalFecha = () => {
        this.setState({ showFecha: true, Titulo: "Fecha acreditación", op: 0 });
    }

    openModalPregunta = () => {
        this.setState({ show: true });
    }


    //Función que recupera valores para el modal de agregar
    /*  eventoModalcobranza = (ListaAlumnos, colorNotificacion) => {
       //Cambiar valores por default
       this.setState({ showAgregar: false, op: 0 })
     } */


    //Función que recupera valores para el modal
    eventoModalInfoVenta = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showInfoVenta: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalFecha = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showFecha: false, op: 0 })
        // if (mensaje != 'esc') {
        //     this.setState({ mensaje: mensaje, colorNotificacion: "success" })
        //     this.showNotification();
        // }
    }

    eventoModalPregunta = (valor) => {
        this.setState({ show: false })
        if (valor) {
            this.ActualizarAcreditacionVentas(new Date());
        }
    }

    //Función que habilita la notificación
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        //console.log(e.target);

        if (name == "opcionAcreditado") {
            if (value == false) {
                this.setState({
                    [name]: value,
                    Folios: [],
                    TituloAcreditacion: "Pagos no acreditados"
                });
            }
            else {
                this.setState({
                    [name]: value,
                    Folios: [],
                    TituloAcreditacion: "Pagos acreditados"
                });
            }
        }
        else {
            this.setState({ [name]: value });
        }
    }

    handleCheckAll(e) {
        const { checked } = e.target;
        let aux = [];
        let folios;

        if (checked) {
            folios = this.state.Folios;
            folios.map(folio => {
                folio.Seleccionado = true;
                aux.push(folio._id);
            })

            this.setState({
                idsAcreditacion: aux,
                Folios: folios,
                CheckAll: true,
            })
        }
        else {
            folios = this.state.Folios;
            folios.map(folio => {
                folio.Seleccionado = false
            })

            this.setState({
                idsAcreditacion: [],
                Folios: folios,
                CheckAll: false,
            })
        }
        this.ContarTotales()
    }

    handleCheckBox(e, item) {
        const { checked } = e.target;
        let folios;
        let seleccionado;
        let idIndex;

        if (checked) {
            folios = this.state.Folios
            seleccionado = folios.indexOf(item);
            folios[seleccionado].Seleccionado = true

            this.setState({
                idsAcreditacion: [...this.state.idsAcreditacion, item._id],
                Folios: folios,
                CheckAll: false,
            })
        }
        else {
            idIndex = this.state.idsAcreditacion.indexOf(item._id);
            this.state.idsAcreditacion.splice(idIndex, 1);

            folios = this.state.Folios
            seleccionado = folios.indexOf(item);
            folios[seleccionado].Seleccionado = false

            this.setState({
                idsAcreditacion: [...this.state.idsAcreditacion],
                Folios: folios,
                CheckAll: false,
            })
        }

        this.ContarTotales()
    }

    ContarTotales() {
        var Folios = this.state.Folios
        var suma = 0;
        let sumaSelect = 0;

        Folios.map(folio => {
            suma += parseFloat(folio.Total);
            if (folio.Seleccionado == true) {
                sumaSelect += parseFloat(folio.Total);
            }
        })

        this.setState({
            Total: suma,
            TotalSeleccionado: sumaSelect
        })
    }

    render() {
        //console.log(this.state.Folios)
        const convertirPesos = (number) => {
            return new Intl.NumberFormat("ES-MX", {
                style: 'currency',
                currency: 'MXN'
            }).format(number);
        }

        let agregar;
        if (this.state.showInfoVenta == true) {
            agregar =
                <ModalInfoVenta
                    Titulo={this.state.Titulo}
                    showInfoVenta={this.state.showInfoVenta}
                    eventClick={this.eventoModalInfoVenta}
                    op={3}
                    VentaSeleccionada={this.state.VentaSeleccionada}
                    NombreAlumno={this.state.NombreAlumno}
                //parentCallback={this.eventoModalInfoVenta}
                />
        }

        if (this.state.showFecha == true) {
            agregar =
                <ModalFechaAcreditacion
                    Titulo={this.state.Titulo}
                    showFecha={this.state.showFecha}
                    eventClick={this.eventoModalFecha}
                    parentCallback={this.RegresarFecha}
                />
        }

        if (this.state.cargando == true) {
            agregar = (
                <ModalCargando
                    Cuerpo={this.state.CuerpoCargando}
                    show={this.state.cargando}
                />
            );
        }

        let Folios;
        let checkbox;
        let Aux;
        let thead;

        if (this.state.opcionAcreditado == false) {
            thead =
                <thead>
                    <th className="center">Empresa</th>
                    <th className="center">Folio</th>
                    <th className="center">Fecha</th>
                    <th className="center">Alumno</th>
                    <th className="center">Monto</th>
                    <th><Checkbox onChange={this.handleCheckAll.bind(this)} name="CheckAll" checked={this.state.CheckAll} /></th>
                </thead>
        }
        else {
            thead =
                <thead>
                    <th className="center">Empresa</th>
                    <th className="center">Folio</th>
                    <th className="center">Fecha</th>
                    <th className="center">Fecha acreditación</th>
                    <th className="center">Alumno</th>
                    <th className="center">Monto</th>
                    <th><Checkbox onChange={this.handleCheckAll.bind(this)} name="CheckAll" checked={this.state.CheckAll} /></th>
                </thead>
        }


        if (this.state.Folios.length !== 0) {
            Folios =
                this.state.Folios.map((folio, index) => {
                    if (folio.FolioRecibo !== null) {
                        if (folio.RazonSocial !== undefined) {
                            Aux =
                                <td style={{ cursor: "pointer", paddingTop: "10px", paddingBottom: "10px", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{folio.RazonSocial}</td>
                        }
                        else if (this.state.NombreEmpresa !== "") {
                            Aux =
                                <td style={{ cursor: "pointer", paddingTop: "10px", paddingBottom: "10px", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{this.state.NombreEmpresa}</td>
                        }
                        else {
                            Aux =
                                <td style={{ cursor: "pointer", paddingTop: "10px", paddingBottom: "10px", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{"Sin Especificar"}</td>
                        }

                        if (this.state.opcionAcreditado == false) {
                            return (
                                <tr key={index} hover>
                                    {Aux}
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{folio.FolioRecibo}</td>
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{moment(folio.Fecha).format('DD-MM-YYYY')}</td>
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{folio.NombreAlumno != null ? folio.NombreAlumno : "Sin Especificar"}</td>
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{convertirPesos(folio.Total)}</td>
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }}><Checkbox onChange={(e) => this.handleCheckBox(e, folio)} name="checkedindiv" checked={folio.Seleccionado} /></td>
                                </tr>
                            )
                        }
                        else {
                            return (
                                <tr key={index} hover>
                                    {Aux}
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{folio.FolioRecibo}</td>
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{moment(folio.Fecha).format('DD-MM-YYYY')}</td>
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{moment(folio.FechaAcreditacion).format('DD-MM-YYYY')}</td>
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{folio.NombreAlumno != null ? folio.NombreAlumno : "Sin Especificar"}</td>
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{convertirPesos(folio.Total)}</td>
                                    <td style={{ cursor: "pointer", verticalAlign: "middle" }}><Checkbox onChange={(e) => this.handleCheckBox(e, folio)} name="checkedindiv" checked={folio.Seleccionado} /></td>
                                </tr>
                            )
                        }

                    } else {
                        return (
                            <tr>
                                <td colSpan={7} style={{ textAlign: "center" }}>{("No hay ventas por acreditar").toLocaleUpperCase()}</td>
                            </tr>
                        )
                    }
                })
        }
        else {
            Folios =
                <tr>
                    <td colSpan={7} style={{ textAlign: "center" }}>{("No hay ventas por acreditar").toLocaleUpperCase()}</td>
                </tr>
        }

        //console.log(this.state.Folios);
        // console.log(this.state.idsAcreditacion)
        //console.log(this.state.idsAdeudos)

        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Acreditación de pagos</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem id="Empresa de búsqueda" xs={12} sm={12} md={6}>
                                        <FormControl size="small">
                                            <div>Empresa</div>
                                            <Select
                                                fullWidth={true}
                                                value={this.state.IDEmpresa}
                                                onChange={this.handleChange.bind(this)}
                                                name="IDEmpresa"
                                                //fullWidth={true}
                                                variant="outlined"
                                                // label="Productos"
                                                style={{ width: "22em" }}
                                            //error={this.state.errorUsuarios}
                                            //success={!this.state.errorUsuarios}
                                            >
                                                <MenuItem value={this.state.IDEmpresa} disabled={true}>Todas</MenuItem>
                                                {this.state.ListaEmpresas.map(Empresa =>
                                                    <MenuItem value={Empresa._id}>{(Empresa.RazonSocial).toUpperCase()}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={12}>
                                        {/*Opcion Fechas*/}   <div >
                                            <TextField
                                                id="FechaInicio"
                                                style={{ width: "15em" }}
                                                variant="outlined"
                                                type="date"
                                                format="DD-MM-YYYY"
                                                size="small"
                                                label="Fecha de inicio"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    onChange: this.handleChange.bind(this),
                                                    value: this.state.FechaInicio,
                                                    name: "FechaInicio",
                                                    id: "FechaInicio",
                                                    error: this.state.errorFechainicio,
                                                    success: !this.state.errorFechainicio
                                                }}
                                            />
                                            <TextField
                                                className="ml-2"
                                                id="FechaFin"
                                                style={{ width: "15em" }}
                                                variant="outlined"
                                                size="small"
                                                type="date"
                                                format="DD-MM-YYYY"
                                                label="Fecha de terminación"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    onChange: this.handleChange.bind(this),
                                                    value: this.state.FechaFin,
                                                    name: "FechaFin",
                                                    id: "FechaFin",
                                                    error: this.state.errorFechaFin,
                                                    success: !this.state.errorFechaFin
                                                }}
                                            />

                                            <FormControl size="small" variant="outlined">
                                                <InputLabel id="auth" style={{ marginLeft: "10px" }}>Método de pago</InputLabel>
                                                <Select
                                                    className="ml-2"
                                                    fullWidth={true}
                                                    value={this.state.opcionTipoPago}
                                                    onChange={this.handleChange.bind(this)}
                                                    name="opcionTipoPago"
                                                    //fullWidth={true}
                                                    label="Método de pago"
                                                    style={{ width: "13em" }}
                                                >
                                                    <MenuItem disabled>Seleccione un metodo de pago</MenuItem>
                                                    <MenuItem value={"Tarjeta_de_Crédito"}>Tarjeta de Crédito</MenuItem>
                                                    <MenuItem value={"Tarjeta_de_Débito"}>Tarjeta de Débito</MenuItem>
                                                    <MenuItem value={"Cheque"}>Cheque</MenuItem>
                                                    <MenuItem value={"Depósito"}>Depósito</MenuItem>
                                                    <MenuItem value={"Transferencia"}>Transferencia</MenuItem>

                                                </Select>
                                            </FormControl>
                                            <FormControl size="small" variant="outlined">
                                                <InputLabel id="auth2" style={{ marginLeft: "10px" }}>Estado de acreditación</InputLabel>
                                                <Select
                                                    className="ml-2"
                                                    labelId="auth2"
                                                    fullWidth={true}
                                                    value={this.state.opcionAcreditado}
                                                    onChange={this.handleChange.bind(this)}
                                                    name="opcionAcreditado"
                                                    label="Estado de acreditación"
                                                    //fullWidth={true}
                                                    style={{ width: "13em" }}
                                                >
                                                    <MenuItem disabled>Seleccione estado de acreditación</MenuItem>
                                                    <MenuItem value={false}>Sin acreditar</MenuItem>
                                                    <MenuItem value={true}>Acreditados</MenuItem>

                                                </Select>
                                            </FormControl>&nbsp;&nbsp;&nbsp;
                                            <Button size="sm" type="submit" color="info" round onClick={() => this.opcionBuscarAPI()}><Search />Buscar</Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={18}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>{this.state.TituloAcreditacion}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="table-responsive-xl table-wrapper-scroll-y">
                                            <table className="table table-sm table-hover">
                                                {thead}
                                                <tbody>
                                                    {Folios}
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer style={{ backgroundColor: "#f1f1f1", borderRadius: 3, }} xs={12} sm={12} md={12}>
                                            <GridItem style={{}} xs={12} sm={12} md={10} >
                                                <h6 className="text-right">Total:</h6>
                                            </GridItem>
                                            <GridItem style={{}} xs={12} sm={12} md={2} >
                                                <h6 className="text-center">{convertirPesos(this.state.Total)}</h6>
                                            </GridItem>
                                            <GridItem style={{}} xs={12} sm={12} md={10} >
                                                <h6 className="text-right">Total Seleccionado:</h6>
                                            </GridItem>
                                            <GridItem style={{}} xs={12} sm={12} md={2} >
                                                <h6 className="text-center">{convertirPesos(this.state.TotalSeleccionado)}</h6>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <div>
                            <Button size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.OpcionAcreditacion()}>Acreditar / Quitar acreditación</Button>
                        </div>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <ModalPregunta
                        Titulo={"Acreditación"}
                        Cuerpo={"¿Esta seguro de quitar la acreditación a las ventas seleccionadas?"}
                        show={this.state.show}
                        eventClick={this.eventoModalPregunta}
                    />
                    {agregar}
                </div>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(Acreditacion);