import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalConceptoDescuento from "./ModalConceptoDescuento";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Close, Add } from "@material-ui/icons";

class CatConcepto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      idPadre: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      SiesPadre: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      FiltrarNombres: "",
      ListaConcepto: [],
      ListaConceptoTemp: [],
      ListaConceptoPadre: [],
      ListaConceptoPadreTemp: [],
      ConceptoSeleccionado: {},
      RowSeleccionado: "",
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      SeCargoYa: 0,
      Titulo: "Registrar",
      SeElimina: false,
      InformacionModal: "",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.eventoModalPregunta = this.eventoModalPregunta.bind(this);
  }

  componentDidMount() {
    this.ListaGeneralConceptos();
  }

  async ListaGeneralConceptos(Idpadre) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(
      Globales.Url +
        "Catalogos/ConceptodeDescuento/ListaGeneralConceptosdeDescuento",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({
          idPadre: Idpadre
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
            ListaConcepto: [false],
            ListaConceptoTemp: [false],
          });
          this.showNotification();
        } else if (data.Existen === 1) {
          if (
            !Idpadre ||
            JSON.stringify(this.state.SiesPadre) === JSON.stringify(Idpadre)
          ) {
            if (data.ListadeConcepto.ConceptoDescuento.length > 1) {
              this.setState({
                ListaConceptoPadre: data.ListadeConcepto.ConceptoDescuento,
                ListaConceptoPadreTemp: data.ListadeConcepto.ConceptoDescuento,
                Activo: 1,
                cargando: false,
                entroEliminar: false,
                FiltrarNombres: "",
              });
            } else {
              this.setState({
                ListaConceptoPadre: [data.ListadeConcepto.ConceptoDescuento],
                ListaConceptoPadreTemp: [
                  data.ListadeConcepto.ConceptoDescuento,
                ],
                cargando: false,
                entroEliminar: false,
                FiltrarNombres: "",
              });
            }
          } else {
            if (data.ListadeConcepto.ConceptoDescuento.length > 1) {
              this.setState({
                ListaConcepto: data.ListadeConcepto.ConceptoDescuento,
                ListaConceptoTemp: data.ListadeConcepto.ConceptoDescuento,
                Activo: 1,
                cargando: false,
                entroEliminar: false,
                FiltrarNombres: "",
              });
            } else {
              this.setState({
                ListaConcepto: [data.ListadeConcepto.ConceptoDescuento],
                ListaConceptoTemp: [data.ListadeConcepto.ConceptoDescuento],
                cargando: false,
                entroEliminar: false,
                FiltrarNombres: "",
              });
            }
          }
        } else {
          this.setState({
            ListaConcepto: [],
            ListaConceptoTemp: [],
            cargando: false,
            entroEliminar: false,
            colorNotificacion: "danger",
            mensaje: "No se encontraron conceptos.",
            FiltrarNombres: "",
          });
          // this.showNotification();
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ValidarHijos(Idpadre) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(
      Globales.Url + "Catalogos/ConceptodeDescuento/ValidarEliminacion",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({
          idPadre: Idpadre,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            cargando: false,
            SeElimina: false,
          });
          this.showNotification();
          return false;
        } else if (data.Eliminar) {
          this.setState({
            SeElimina: true,
          });
          return true;
        } else {
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            cargando: false,
            SeElimina: false,
          });
          this.showNotification();
          return false;
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  //Función para obtener el texto de los inputs
  MedioClick(e, InfoConcepto, Index) {
    const id = JSON.stringify(InfoConcepto._id);
    if (this.state.RowSeleccionado) {
      const Tabla = document.getElementById(this.state.RowSeleccionado);
      Tabla.style.backgroundColor = "#FFFFFF";
    }
    const element = document.getElementById(id + "-" + Index);
    element.style.backgroundColor = "#CAE9FD";
    this.ListaGeneralConceptos(JSON.parse(id));
    this.setState({
      RowSeleccionado: id + "-" + Index,
      idPadre: InfoConcepto._id,
      ConceptoSeleccionado: InfoConcepto,
    });
  }

  eventoModalEliminar(e) {
    this.setState({ show: true, ConceptoSeleccionado: e });
    return;
  }

  async eventoModalPregunta(valor) {
    if (valor) {
      if (
        JSON.stringify(this.state.ConceptoSeleccionado.idPadre) ===
        JSON.stringify(this.state.SiesPadre)
      ) {
        const Eliminar = await this.ValidarHijos(
          this.state.ConceptoSeleccionado._id
        );
        if (!Eliminar && !this.state.SeElimina) {
          this.setState({
            mensaje:
              "El concepto no puede ser eliminado, porque cuenta con subconceptos asociados.",
            show: false,
          });
          this.showNotification();
          return;
        }
      }
      this.EliminarConcepto(this.state.ConceptoSeleccionado._id);
    } else {
      this.setState({ show: false });
    }
  }

  EliminarConcepto(id) {
    fetch(
      Globales.Url +
        "Catalogos/ConceptodeDescuento/EliminarInformacionConceptoDescuento",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({ _id: id }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error === undefined) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger" });
        } else if (data.error) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger" });
        } else {
          //Obtuvo los registros correctamente
          this.setState({ colorNotificacion: "success" });
        }
        if (this.state.RowSeleccionado) {
          const Tabla = document.getElementById(this.state.RowSeleccionado);
          Tabla.style.backgroundColor = "#FFFFFF";
        }
        this.setState({
          mensaje: data.mensaje
            ? data.mensaje
            : "El concepto no puede ser eliminado, porque cuenta con Subconceptos asociados.",
          show: false,
          ListaConcepto: [],
          ListaConceptoTemp: [],
          ConceptoSeleccionado: "",
          idPadre: {
            _increment: "0",
            _machine: "0",
            _pid: "0",
            _timestamp: "0",
          },
          RowSeleccionado: "",
        });
        this.showNotification();
        this.ListaGeneralConceptos();
      });
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  eventoModalConcepto = (mensaje, Color, Tipo) => {
    this.setState({showAgregar: false})
    //Cambiar valores por default
    if(mensaje !== "esc"){
      this.setState({
        Titulo: "Nuevo Concepto",
        mensaje: mensaje,
        InformacionModal: "",
        op: 0,
        entroEliminar: true,
        colorNotificacion: Color,
      });
      this.showNotification();
      // this.ListaGeneralConceptos();
      // console.log(Tipo);
      this.ListaGeneralConceptos(Tipo == 2 ? this.state.idPadre : undefined);
      
    }
  };

  openModalNuevoConcepto = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Nuevo Concepto",
      InformacionModal: {
        Tipo: 1,
        Conceptos: this.state.ListaConceptoPadreTemp,
        idPadre: this.state.idPadre,
      },
      op: 0,
    });
  };

  openModalNuevoSubConcepto = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Nuevo SubConcepto",
      InformacionModal: {
        Tipo: 2,
        Conceptos: this.state.ListaConceptoTemp,
        idPadre: this.state.idPadre,
      },
      op: 0,
    });
  };

  openModalEditarConcepto = (e) => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Editar Concepto",
      InformacionModal: {
        Tipo: 1,
        Conceptos: this.state.ListaConceptoPadreTemp,
        ...e,
      },
      op: 1,
    });
  };

  openModalEditarSubConcepto = (e) => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Editar SubConcepto",
      InformacionModal: {
        Tipo: 2,
        Conceptos: this.state.ListaConceptoTemp,
        ...e,
      },
      op: 1,
    });
  };

  render() {
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }
    let agregar;
    if (this.state.showAgregar == true) {
      agregar = (
        <ModalConceptoDescuento
          xs={18}
          Titulo={this.state.Titulo}
          InformacionModal={this.state.InformacionModal}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalConcepto}
          op={this.state.op}
        />
      );
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 className="pull-left">Lista de conceptos</h4>
                    <div className="pull-right">
                      <Button
                        color="info"
                        round
                        justIcon
                        size="sm"
                        onClick={() => this.openModalNuevoConcepto()}
                      >
                        <Add />
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="center">Nombre</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ListaConceptoPadre.map((Concepto, index) => {
                        return (
                          <tr
                            key={JSON.stringify(Concepto._id) + "-" + index}
                            id={JSON.stringify(Concepto._id) + "-" + index}
                          >
                            <td
                              onClickCapture={(e) =>
                                this.MedioClick(e, Concepto, index)
                              }
                              width="50%"
                              style={{verticalAlign: "middle", cursor: "pointer"}}
                            >
                              {Concepto.Nombre}
                            </td>
                            <td style={{verticalAlign: "middle", cursor: "pointer"}}>
                            <Button
                                color="danger"
                                round
                                justIcon
                                className="pull-right"
                                size="sm"
                                onClick={() =>
                                  this.eventoModalEliminar(Concepto)
                                }
                              >
                                <Close />
                              </Button>
                              <Button
                                color="info"
                                round
                                justIcon
                                className="pull-right mr-2"
                                size="sm"
                                onClick={() =>
                                  this.openModalEditarConcepto(Concepto)
                                }
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 className="pull-left">Lista de subconceptos</h4>
                    <div className="pull-right">
                      <Button
                        color="info"
                        round
                        justIcon
                        size="sm"
                        disabled={!this.state.RowSeleccionado}
                        onClick={() => this.openModalNuevoSubConcepto()}
                      >
                        <Add />
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table
                    className="table table-sm table-hover"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th className="center">Nombre</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ListaConcepto.map((SubConcepto, index) => {
                        return (
                          <tr key={SubConcepto._id + "-" + index}>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>{SubConcepto.Nombre.slice(0, 30)}</td>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>
                            <Button
                                color="danger"
                                round
                                justIcon
                                className="pull-right"
                                size="sm"
                                onClick={() =>
                                  this.eventoModalEliminar(SubConcepto)
                                }
                              >
                                <Close />
                              </Button>
                              <Button
                                color="info"
                                round
                                justIcon
                                className="pull-right mr-2"
                                size="sm"
                                onClick={() =>
                                  this.openModalEditarSubConcepto(SubConcepto)
                                }
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar"}
            Cuerpo={"¿Está seguro de dar de baja?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatConcepto);
