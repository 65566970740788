import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import InputAdornment from '@material-ui/core/InputAdornment';

class ModalDardeBajaAlumno extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      _id: '',
      EstadoAlumno: "Activo",
      ClaseAlumno: "TODOS",
      FiltrarNombres: "",
      SoloDeudores: false,
      CheckAll: false,
      ListaAlumnos: [],
      ListaCorreos: [],
      NombresAlumnos: [],
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,  
      CuerpoCargando: "Cargando",
    }
    this.handleChange = this.handleChange.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick(false);
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //this.ObtenerCorreosAlumnos()
    // if(this.props.ListaCorreos.length !== 0){
    //   this.setState({ListaCorreos: this.props.ListaCorreos, NombresAlumnos: this.props.NombresAlumnos})
    // }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  async ObtenerCorreosAlumnos(texto) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'EnvioCorreos/EnvioCorreos/ObtenerCorreosAlumnos', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({EstadoAlumno: this.state.EstadoAlumno, ClaseAlumno: this.state.ClaseAlumno !== "TODOS" ? this.state.ClaseAlumno : null, SoloDeudores: this.state.SoloDeudores, Texto: texto})
    }
    ).then(res => res.json())
        .then(data => {
          // console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, CheckAll: false, colorNotificacion: "danger", cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              if(data.Alumnos.length > 0){
                data.Alumnos.map(alum => {
                  if(alum.ContactosAlumno.ContactoAlumno.length > 1){
                    for(let i = 0; i < alum.ContactosAlumno.ContactoAlumno.length; i++){
                      alum.Seleccionado = false
                      if(alum.ContactosAlumno.ContactoAlumno[i].EnumTipoContactoAlumno == "Alumno" && (alum.ContactosAlumno.ContactoAlumno[i].Correo !== undefined && alum.ContactosAlumno.ContactoAlumno[i].Correo !== null)){
                        alum.Correo = alum.ContactosAlumno.ContactoAlumno[i].Correo
                        break;
                      }
                      else if(alum.ContactosAlumno.ContactoAlumno[i].EnumTipoContactoAlumno == "Mamá" && (alum.ContactosAlumno.ContactoAlumno[i].Correo !== undefined && alum.ContactosAlumno.ContactoAlumno[i].Correo !== null)){
                        alum.Correo = alum.ContactosAlumno.ContactoAlumno[i].Correo
                        break;
                      }
                      else if(alum.ContactosAlumno.ContactoAlumno[i].EnumTipoContactoAlumno == "Papá" && (alum.ContactosAlumno.ContactoAlumno[i].Correo !== undefined && alum.ContactosAlumno.ContactoAlumno[i].Correo !== null)){
                        alum.Correo = alum.ContactosAlumno.ContactoAlumno[i].Correo
                        break;
                      }
                      else if(alum.ContactosAlumno.ContactoAlumno[i].EnumTipoContactoAlumno == "Otro" && (alum.ContactosAlumno.ContactoAlumno[i].Correo !== undefined && alum.ContactosAlumno.ContactoAlumno[i].Correo !== null)){
                        alum.Correo = alum.ContactosAlumno.ContactoAlumno[i].Correo
                        break;
                      }
                        alum.Correo = null
                    }

                  }
                  else{
                    if(alum.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Alumno" && (alum.ContactosAlumno.ContactoAlumno.Correo !== undefined && alum.ContactosAlumno.ContactoAlumno.Correo !== null)){
                      alum.Correo = alum.ContactosAlumno.ContactoAlumno.Correo
                    }
                    else if(alum.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Mamá" && (alum.ContactosAlumno.ContactoAlumno.Correo !== undefined && alum.ContactosAlumno.ContactoAlumno.Correo !== null)){
                      alum.Correo = alum.ContactosAlumno.ContactoAlumno.Correo
                    }
                    else if(alum.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Papá" && (alum.ContactosAlumno.ContactoAlumno.Correo !== undefined && alum.ContactosAlumno.ContactoAlumno.Correo !== null)){
                      alum.Correo = alum.ContactosAlumno.ContactoAlumno.Correo
                    }
                    else if(alum.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Otro" && (alum.ContactosAlumno.ContactoAlumno.Correo !== undefined && alum.ContactosAlumno.ContactoAlumno.Correo !== null)){
                      alum.Correo = alum.ContactosAlumno.ContactoAlumno.Correo
                    }
                    else{
                      alum.Correo = null
                    }

                    alum.Seleccionado = false
                  }
                })

                this.setState({ListaAlumnos: data.Alumnos, CheckAll: false, cargando: false});
              }
              else{

                if(data.Alumnos.ContactosAlumno.ContactoAlumno.length > 1){

                  for(let i = 0; i < data.Alumnos.ContactosAlumno.ContactoAlumno.length; i++){
                    data.Alumnos.Seleccionado = false
                    if(data.Alumnos.ContactosAlumno.ContactoAlumno[i].EnumTipoContactoAlumno == "Alumno" && (data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo !== undefined && data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo !== null)){
                      data.Alumnos.Correo = data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo
                      break;
                    }
                    else if(data.Alumnos.ContactosAlumno.ContactoAlumno[i].EnumTipoContactoAlumno == "Mamá" && (data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo !== undefined && data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo !== null)){
                      data.Alumnos.Correo = data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo
                      break;
                    }
                    else if(data.Alumnos.ContactosAlumno.ContactoAlumno[i].EnumTipoContactoAlumno == "Papá" && (data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo !== undefined && data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo !== null)){
                      data.Alumnos.Correo = data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo
                      break;
                    }
                    else if(data.Alumnos.ContactosAlumno.ContactoAlumno[i].EnumTipoContactoAlumno == "Otro" && (data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo !== undefined && data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo !== null)){
                      data.Alumnos.Correo = data.Alumnos.ContactosAlumno.ContactoAlumno[i].Correo
                      break;
                    }

                    data.Alumnos.Correo = null

                  }
                }
                else{
                  if(data.Alumnos.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Alumno" && (data.Alumnos.ContactosAlumno.ContactoAlumno.Correo !== undefined && data.Alumnos.ContactosAlumno.ContactoAlumno.Correo !== null)){
                    data.Alumnos.Correo = data.Alumnos.ContactosAlumno.ContactoAlumno.Correo
                  }
                  else if(data.Alumnos.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Mamá" && (data.Alumnos.ContactosAlumno.ContactoAlumno.Correo !== undefined && data.Alumnos.ContactosAlumno.ContactoAlumno.Correo !== null)){
                    data.Alumnos.Correo = data.Alumnos.ContactosAlumno.ContactoAlumno.Correo
                  }
                  else if(data.Alumnos.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Papá" && (data.Alumnos.ContactosAlumno.ContactoAlumno.Correo !== undefined && data.Alumnos.ContactosAlumno.ContactoAlumno.Correo !== null)){
                    data.Alumnos.Correo = data.Alumnos.ContactosAlumno.ContactoAlumno.Correo
                  }
                  else if(data.Alumnos.ContactosAlumno.ContactoAlumno.EnumTipoContactoAlumno == "Otro" && (data.Alumnos.ContactosAlumno.ContactoAlumno.Correo !== undefined && data.Alumnos.ContactosAlumno.ContactoAlumno.Correo !== null)){
                    data.Alumnos.Correo = data.Alumnos.ContactosAlumno.ContactoAlumno.Correo
                  }
                  else{
                    data.Alumnos.Correo = null
                  }

                  data.Alumnos.Seleccionado = false
                }
                
                let aux = [];
                aux.push(data.Alumnos);

                this.setState({ListaAlumnos: aux, CheckAll: false, cargando: false});
              }
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value, checked, id } = e.target;
    
    switch(name){
      case "SoloDeudores":
        if(checked){
          this.setState({[name]: true});
        }
        else{
          this.setState({[name]: false});
        }
        break;
      case "Seleccionado":
        if(checked){
          let lista = [];

          this.state.ListaAlumnos[id].Seleccionado = true;
          this.state.ListaAlumnos.map(alum => {
            if(alum.Correo !== null){
              lista.push(alum)
            }
          })

        let TodoTrue = lista.every(elem => elem.Seleccionado == true);

          this.setState({ListaAlumnos: this.state.ListaAlumnos, CheckAll: TodoTrue});
        }
        else{
          this.state.ListaAlumnos[id].Seleccionado = false;

          this.setState({ListaAlumnos: this.state.ListaAlumnos, CheckAll: false});
        }
        break;
      case "CheckAll":
        if(checked){
          if(this.state.ListaAlumnos.length !== 0){
            this.state.ListaAlumnos.map(alumno => {
              if(alumno.Correo !== null){
                alumno.Seleccionado = true
              }
            })
            this.setState({ListaAlumnos: this.state.ListaAlumnos, CheckAll: true});
          }
        }
        else{
          if(this.state.ListaAlumnos.length !== 0){
            this.state.ListaAlumnos.map(alumno => {
              if(alumno.Correo !== null){
                alumno.Seleccionado = false
              }
            })
            this.setState({ListaAlumnos: this.state.ListaAlumnos, CheckAll: false});
          }
        }
        break;
      case "FiltrarNombres":
        if(value.length > 0){
          this.setState({ [name]: value });
          this.ObtenerCorreosAlumnos(value)
        }
        else{
          this.setState({ [name]: value, ListaAlumnos: [] });
        }
        
        break;
      default:
        this.setState({[name]: value});
        break;
    }
  }

  RegresarAlumnos(){
    let valido = true;
    if(this.state.ListaAlumnos.length == 0){
      valido = false;
      this.setState({mensaje: "No hay alumnos seleccionados", colorNotificacion: "danger"});
      this.showNotification();
    }
    else if(this.state.ListaAlumnos.every(elem => elem.Seleccionado == false)){
      valido = false;
      this.setState({mensaje: "No hay alumnos seleccionados", colorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
      let Nombres = [];
      let Correos = [];

      this.state.ListaAlumnos.map(alumno => {
        if(alumno.Seleccionado == true){
          Nombres.push(alumno.Nombre)
          Correos.push(alumno.Correo)
        }
      })

      this.props.eventClick(true, Nombres, Correos);
    }
  }

  cerrarModal = () => {
    this.props.eventClick(false)
  }

 
  render() {
    const { classes } = this.props;
    // console.log(this.state.ListaAlumnos)
    //console.log(this.state.ListaCorreos)
    //console.log(this.state.NombresAlumnos)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={this.state.CuerpoCargando} show={this.state.cargando} />
      );
    }

    let Alumnos;
    if(this.state.ListaAlumnos.length > 0){
      Alumnos = (
        this.state.ListaAlumnos.map((alumno, index) => {
          return(
            <tr>
              <td style={{verticalAlign: "middle", cursor: "default"}}>{alumno.NombreCompleto}</td>
              <td style={{verticalAlign: "middle", cursor: "default"}}>{alumno.EstadoAlumno}</td>
              <td style={{verticalAlign: "middle", cursor: "default"}}>{alumno.Clase}</td>
              <td style={{verticalAlign: "middle", cursor: "default"}}>{alumno.Correo !== null ? alumno.Correo : "Sin especificar"}</td>
              <td style={{verticalAlign: "middle"}}>
                <Checkbox
                  checked={alumno.Seleccionado}
                  onChange={this.handleChange.bind(this)}
                  name="Seleccionado"
                  color="primary"
                  id={index}
                  disabled={alumno.Correo == null}
                />
              </td>
            </tr>
          )
        })
      );
    }
    else{
      Alumnos = (
        <tr>
          <td colSpan={5} style={{verticalAlign: "middle", textAlign: "center", cursor: "default"}}>NO HAY ALUMNOS</td>
        </tr>
      )
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showAgregarCorreos}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
          style={{ maxWidth: "1200px", width: "100%" }}
        >
          <Card>
            <CardHeader color="info">Agregar alumnos</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                      <InputLabel style={{marginBottom: "10px"}} id="estadolabel">Estado alumno</InputLabel>
                        <Select
                            labelId="estadolabel"
                            //fullWidth={true}
                            value={this.state.EstadoAlumno}
                            onChange={this.handleChange.bind(this)}
                            name="EstadoAlumno"
                            //fullWidth={true}
                            label="Estado alumno"
                            style={{ width: "15em", marginBottom: "10px" }}
                            //error={this.state.errorIDIdioma}
                            //success={!this.state.errorIDIdioma}
                        >
                            <MenuItem disabled>Seleccione un estado</MenuItem>
                            <MenuItem value={"Activo"}>Activo</MenuItem>
                            <MenuItem value={"Inactivo"}>Inactivo</MenuItem>
                        </Select>
                  </FormControl>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginBottom: "10px", marginLeft: "10px"}} id="Claselabel">Clase</InputLabel>
                      <Select
                          labelId="Claselabel"
                          //fullWidth={true}
                          value={this.state.ClaseAlumno}
                          onChange={this.handleChange.bind(this)}
                          name="ClaseAlumno"
                          //fullWidth={true}
                          label="Clase"
                          style={{ width: "12em", marginBottom: "10px", marginLeft: "10px" }}
                          //error={this.state.errorIDIdioma}
                          //success={!this.state.errorIDIdioma}
                      >
                          <MenuItem value={"TODOS"}>Todos</MenuItem>
                          <MenuItem value={"Niño"}>Niño</MenuItem>
                          <MenuItem value={"Adolescente"}>Adolescente</MenuItem>
                          <MenuItem value={"Adulto"}>Adulto</MenuItem>
                      </Select>
                  </FormControl>
                  <FormControlLabel
                    style={{ marginLeft: "10px" }}
                    control={
                      <Checkbox
                        checked={this.state.SoloDeudores}
                        onChange={this.handleChange.bind(this)}
                        name="SoloDeudores"
                        color="primary"
                      />
                    }
                    label="Mostrar solo deudores" 
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="FiltrarNombres"
                    style={{ width: "23vw"}}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Matricula o nombre"
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                              <Search />
                          </InputAdornment>
                          ),
                      onChange: this.handleChange.bind(this),
                      value: this.state.FiltrarNombres,
                      name: "FiltrarNombres",
                      id: "FiltrarNombres",
                      //error: this.state.errorMotivoCantacto,
                      //success: !this.state.errorMotivoCantacto
                    }}
                  />
                  {/* <Button color="info" size='sm' className='ml-2 mt-1' round onClick={() => this.ObtenerCorreosAlumnos()}><Search/>&nbsp;Buscar</Button> */}
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="table-responsive-xl table-wrapper-scroll-y">
                    <table className="table table-sm table-hover">
                      <thead>
                        <th>Nombre</th>
                        <th>Estado</th>
                        <th>Clase</th>
                        <th>Correo</th>
                        <th>
                        <Checkbox
                          checked={this.state.CheckAll}
                          onChange={this.handleChange.bind(this)}
                          name="CheckAll"
                          color="primary"
                          disabled={this.state.ListaAlumnos.length == 0 ? true : false}
                        />
                        </th>
                      </thead>
                      <tbody>
                        {Alumnos}
                      </tbody>
                    </table>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button color="info" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.RegresarAlumnos()}><Save/>&nbsp;Agregar</Button>
                  <Button size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick(false)}><Clear/>&nbsp;Cancelar</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalDardeBajaAlumno);