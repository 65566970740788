import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalPregunta from "components/Generales/ModalPregunta";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, CameraAlt, Clear, Search, Edit, Close, AddCircle, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import ModalAgregarObjeto from "./ModalAgregarObjeto";

class ModalDocumentos extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      _id: '',
      AreaSeleccionada: {},
      ObjetoSeleccionado: {},
      NombreArea: "",
      ObjetosArea: [],
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      cargando: false,
      CuerpoCargando: "Cargando",
    }
    this.handleChange = this.handleChange.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick(false);
    }
  }

  componentDidMount() {
    if(this.props.op == 1){

      let aux = [];
      if(this.props.AreaSeleccionada.Objetos !== undefined && this.props.AreaSeleccionada.Objetos !== null){
        if(this.props.AreaSeleccionada.Objetos.ObjetoEnArea.length > 0){

          aux = this.props.AreaSeleccionada.Objetos.ObjetoEnArea;
        }
        else{
          aux.push(this.props.AreaSeleccionada.Objetos.ObjetoEnArea);
        }
      }

      //console.log(aux)
      //console.log(this.props.AreaSeleccionada)
      this.setState({
        AreaSeleccionada: this.props.AreaSeleccionada, 
        NombreArea: this.props.AreaSeleccionada.Nombre,
        ObjetosArea: aux
      }, () => {
        if(this.state.ObjetosArea.length !== 0){
            this.state.ObjetosArea.map((objeto, index) => {
            this.ObtenerInformacionObjeto(objeto.IdObjeto, index);
          })
        }
      })
    }
  }

  async ObtenerInformacionObjeto(id, index) {
    this.setState({cargando: true});
  
    await fetch(Globales.Url + 'Inventario/Inventario/ObtenerInformacionObjeto', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idObjeto: id})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              this.state.ObjetosArea[index].Caracteristicas = data.InfoObjeto.Caracteristicas;
              this.state.ObjetosArea[index].MarcaModelo = data.InfoObjeto.MarcaModelo;
              this.state.ObjetosArea[index].Nombre = data.InfoObjeto.Nombre;

              this.setState({ObjetosArea: this.state.ObjetosArea, cargando: false});
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  CrearArea(){
    let valido = true;
    let Objeto = {};
    let aux = [];

    if(this.state.NombreArea == ""){
      valido = false;
      this.setState({errorNombreArea: true, mensaje: "Proporcione un nombre para el área", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      this.setState({errorNombreArea: false});
    }

    if(this.state.ObjetosArea.length !== 0){
      this.state.ObjetosArea.map(objeto => {
        Objeto = {
          Cantidad: objeto.Cantidad,
          IdObjeto: objeto.IdObjeto
        }

        aux.push(Objeto);
      })
    }

    if(valido){

      if(this.props.op == 1){
        let Area = {
          EsAula: this.state.AreaSeleccionada.EsAula,
          Nombre: this.state.NombreArea,
          Objetos: {ObjetoEnArea: aux},
          _id: this.state.AreaSeleccionada._id
        }

        this.RegistrarArea(Area);
      }
      else{
        let Area = {
          EsAula: false,
          Nombre: this.state.NombreArea,
          Objetos: {ObjetoEnArea: aux},
        }

        this.RegistrarArea(Area);
      }    
    }
  }

  async RegistrarArea(Area) {
    this.setState({cargando: true});

    await fetch(Globales.Url + 'Inventario/Inventario/RegistrarArea', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({Area: Area})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                this.props.eventClick(data.mensaje)
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  openModalAgregarObjeto(){
    this.setState({showAgregarObjeto: true, op: 0});
  }

  openModalEditarObjeto(objeto){
    this.setState({ObjetoSeleccionado: objeto, showAgregarObjeto: true, op: 1});
  }

  openModalPregunta(objeto){
    this.setState({show: true, ObjetoSeleccionado: objeto});
  }

  eventoModalPregunta = (valor) => {
    this.setState({ show: false })
    if(valor !== false){
        let index = this.state.ObjetosArea.indexOf(this.state.ObjetoSeleccionado);
        this.state.ObjetosArea.splice(index,1);

        this.setState({ObjetosArea: this.state.ObjetosArea});
    }
}

  eventoAgregarObjeto = (valor) => {
    this.setState({ showAgregarObjeto: false })
    if (valor != false) {
        if(this.state.op == 1){
          let index = this.state.ObjetosArea.indexOf(this.state.ObjetoSeleccionado);
          // this.state.ObjetosArea.splice(index, 1);
          this.state.ObjetosArea[index] = valor;

          this.setState({ObjetosArea: this.state.ObjetosArea})
        }
        else{
          this.setState({ObjetosArea: [...this.state.ObjetosArea, valor]})
        }
    }
}

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({[name]: value});
  }

  cerrarModal = () => {
    this.props.eventClick(false)
  }
 
  render() {
    const { classes } = this.props;
    //console.log(this.state.ObjetosArea)

    let agregar;
    if (this.state.showAgregarObjeto == true) {
      agregar = (
        <ModalAgregarObjeto
          showAgregarObjeto={this.state.showAgregarObjeto}
          eventClick={this.eventoAgregarObjeto}
          ObjetoSeleccionado={this.state.ObjetoSeleccionado}
          op={this.state.op}
        />
      );
    }

    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    let Objetos;
    if(this.state.ObjetosArea.length !== 0){
      Objetos = (
        this.state.ObjetosArea.map(objeto => {
          return(
            <tr>
              <td>{objeto.Cantidad}</td>
              <td>{objeto.Nombre}</td>
              <td>
                <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar objeto" color="danger" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalPregunta(objeto)}><Close /></Button>
                <Button data-toggle="tooltip" data-placement="bottom" title="Editar objeto" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalEditarObjeto(objeto)}><Edit /></Button>
              </td>
            </tr>
          )
        })
      )
    }
    else{
      Objetos = (
        <tr>
          <td style={{textAlign: "center", marginTop: "10px"}} colSpan={2}>NO HAY OBJETOS REGISTRADOS</td>
        </tr>
      );
    }

    let disabled;
    if(this.props.op == 1){
      if(this.state.AreaSeleccionada.EsAula == "false"){
        disabled = false;
      }
      else{
        disabled = true;
      }
    }
    else{
      disabled = false;
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showEditarInventario}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">Información del área</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                    id="NombreArea"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Nombre del área"
                    disabled={disabled}
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.NombreArea,
                        name: "NombreArea",
                        id: "NombreArea",
                        error: this.state.errorNombreArea,
                        success: !this.state.errorNombreArea
                    }}
                />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} style={{marginTop: "8px"}}>
                  Objetos en el área
                  </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Button tabIndex="1" size='sm' color="info" className='pull-right' round onClick={() => this.openModalAgregarObjeto()}><AddCircle/>&nbsp;Agregar objeto</Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                      <thead>
                          <th>Cantidad</th>
                          <th>Nombre del objeto</th>
                      </thead>
                      <tbody>
                      {Objetos}
                      </tbody>
                  </table>
                </div>
                </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button tabIndex="1" size='sm' color="info" className='pull-right ml-2 mt-3' round onClick={() => this.CrearArea()}><Save/>&nbsp;Guardar</Button>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick(false)}><Clear/>&nbsp;Cerrar</Button>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>  
        </Modal>
        <div id="">
            <ModalPregunta
                Titulo={"Áreas"}
                Cuerpo={"¿Está seguro de eliminar el objeto?"}
                show={this.state.show}
                eventClick={this.eventoModalPregunta}
            />
            {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalDocumentos);