import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'

class ModalDardeBajaAlumno extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      Motivos: "",
      errorMotivos: false,
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      CuerpoCargando: "Cargando",
      aviso: ""
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    if(this.props.op == 0){
      this.setState({_id: this.props._id})
    }
  }

  async DarBajaAlumno(id, motivo) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Alumnos/Alumnos/DarBajaAlumno', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({id: id, motivo: motivo})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              this.setState({cargando: false})
                this.RegresarMensaje(data.mensaje);
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    this.setState({ op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      aviso: ""
    });
  }

  ValidarDarDeBaja(){
    let valor = 0;
    if(this.state.Motivos == ""){
      valor = 1;
      this.setState({errorMotivos: true, mensaje: "Proporcione el motivo para dar de baja al alumno", colorNotificacion: "danger"})
    }
    else{
      valor = 0;
      this.setState({errorMotivos: false})
    }

    if(valor == 0){
      this.DarBajaAlumno(this.state._id, this.state.Motivos);
    }
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;
    //console.log(this.state._id)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showDardeBajaAlumno}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{fontWeight: "bold"}}>Motivos par dar de baja al alumno</div>
                  <TextField
                    id="Motivos"
                    //label="Motivo"
                    fullWidth={true}
                    multiline
                    rows={4}
                    //defaultValue="Default Value"
                    variant="outlined"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Motivos,
                      name: "Motivos",
                      id: "Motivos",
                      error: this.state.errorMotivos,
                      success: !this.state.errorMotivos
                  }}
                  />
                </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                  <Button color="danger" tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.op == 1 ? this.props.eventClick(this.state.Motivos) : this.ValidarDarDeBaja()}><Save/>&nbsp;Dar de baja</Button>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cancelar</Button>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>     
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalDardeBajaAlumno);