import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalDardeBajaAlumno from "./ModalDardeBajaAlumno.jsx";
import ModalEditarAlumno from "./ModalEditarAlumno.jsx";
import ModalInscribirProspecto from "./ModalInscribirProspecto.jsx";
import ModalAsignarGrupo from "./ModalAsignarGrupo.jsx"
import ModalDocumentos from "./ModalDocumentos";
import ModalEnviarCorreo from "./ModalEnviarCorreo";
import Globales from "utils/Globales";
import { Edit, GroupAdd, Info, InfoTwoTone, Description, Close, AddCircle, Search, Clear, Email, Add, Mail } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'

class Alumnos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: '',    
            EstadoAlumno: "Activo",
            ClaseAlumno: "Vacio",
            FiltrarNombres: "",
            ListaAlumnos: [],
            AlumnoSeleccionado: {},
            Disabled: false,
            mensaje: "",
            br: false,
            show: false,
            cargando: false,
            CheckAll: false,
            colorNotificacion: 'success',
            colorTableRow: "white",
            errorIDIdioma: false,
            entroFiltrar: false,
            notificacion: false,
            CuerpoCargando: "Cargando",
            Total: 0,
            TotalSeleccionado: 0,
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
        //this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
  }

    //Función para pasar datos de los alumnos desde el modal hacia la vista
    RegresarMensaje = (mensaje) => {
        this.setState({ mensaje: mensaje, colorNotificacion: "success" });
        this.showNotification();
        this.ListaAlumnosBusquedaFiltradaNombreYEstado(this.state.FiltrarNombres, this.state.EstadoAlumno,this.state.ClaseAlumno);
    }

    InscribirAlumnoProspecto = (prospecto) => {
        this.setState({
            AlumnoSeleccionado: {
                ApelidoMaterno: prospecto.ApellidoMaterno !== null && prospecto.ApellidoMaterno !== undefined ? prospecto.ApellidoMaterno : "",
                ApellidoPaterno: prospecto.ApellidoPaterno,
                Celular: prospecto.Celular !== null && prospecto.Celular !== undefined ? prospecto.Celular : "",
                Clase: prospecto.Clase,
                Correo: prospecto.Correo !== null && prospecto.Correo !== undefined ? prospecto.Correo : "",
                DatosFiscales: prospecto._idInstitucion !== null && prospecto._idInstitucion !== undefined ? prospecto._idInstitucion : { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
                Nombre: prospecto.Nombre,
                NumeroFamiliaresEstudiando: prospecto.NumeroFamiliaresEstudiando,
                Telefono: prospecto.Telefono !== null && prospecto.Telefono !== undefined ? prospecto.Telefono : "",
                _idProspecto: prospecto._id !== null && prospecto._id !== undefined ? prospecto._id : { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
            }
        }, () => this.openModalInscribirAlumno())
    }

    async ListaAlumnosBusquedaFiltradaNombreYEstado(Texto, Estado,Clase) {
        // if(this.state.ListaAlumnos == 0){
        //     this.setState({cargando: true});
        // }
        await fetch(Globales.Url + 'Alumnos/Alumnos/ListaAlumnosBusquedaFiltradaNombreYEstado', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({Texto: Texto, Estado: Estado,Clase: Clase == "Vacio"? null : Clase})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ ListaAlumnos: [], mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    if (data.ListaAlumnos.length > 1) {
                        this.setState({ ListaAlumnos: data.ListaAlumnos, Activo: 1, cargando: false });
                    }
                    else {
                        let ListaAux = [];
                        ListaAux.push(data.ListaAlumnos);
                        //console.log(ListaAux);
                        this.setState({ ListaAlumnos: ListaAux, Activo: 1, cargando: false });
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

//Funciones para abrir modales

    openModalDardeBajaAlumno = (id) => {
        this.setState({ _id: id, showDardeBajaAlumno: true, Titulo: "Alumno", op: 0 });
    }

    openModalEditarAlumno = (alumno) => {
        this.setState({ AlumnoSeleccionado: alumno, showModalEditarAlumno: true, Titulo: "...", op: 1 });
    }

    openModalInscribirAlumno = () => {
        this.setState({ showModalEditarAlumno: true, Titulo: "...", op: 2 });
    }

    openModalInscribirNuevoAlumno = () => {
        this.setState({ showModalEditarAlumno: true, Titulo: "...", op: 3 });
    }

    openModalInscribirProspecto = () => {
        this.setState({ showModalInscribirProspecto: true, Titulo: "Búsqueda de prospecto", op: 0 });
    }

    openModalPregunta = () => {
        this.setState({ show: true });
    }

    openModalAsignarGrupo = (alumno) => {
        this.setState({AlumnoSeleccionado: alumno, showModalAsignarGrupo: true})
    }

    openshowModalDocumentos = (alumno) => {
        this.setState({ AlumnoSeleccionado: alumno, showModalDocumentos: true})
    }

    openshowModalCorreo = (alumno) => {
        if(alumno == 0){
            this.setState({ showModalCorreo: true, op: 0})
        }
        else{
            this.setState({ AlumnoSeleccionado: alumno, showModalCorreo: true, op: 1})
        }
        
    }

//Funciónes que recuperan valores para los modales

    eventoDardeBajaAlumno = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showDardeBajaAlumno: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalEditarAlumno = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showModalEditarAlumno: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalInscribirProspecto = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showModalInscribirProspecto: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalPregunta = (valor) => {
        this.setState({ show: false })
        if(valor){
            this.ActualizarAcreditacionVentas();
        }
    }

    eventoModalAsignarGrupo = (valor) => {
        //Cambiar valores por default
        this.setState({ showModalAsignarGrupo: false, op: 0 })
        if (valor) {
            this.setState({ mensaje: "Se guardaron los cambios", colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalDocumentos = (valor) => {
        //Cambiar valores por default
        this.setState({ showModalDocumentos: false, op: 0 })
        if (valor) {
            this.setState({ mensaje: "El reporte se generó exitosamente", colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalCorreos = (valor) => {
        //Cambiar valores por default
        this.setState({ showModalCorreo: false, op: 0 })
        if (valor !== false) {
            this.setState({ mensaje: valor, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    //Función que habilita la notificación
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        //console.log(e.target);

        if(name == "FiltrarNombres"){
            if(value.length > 0){
                this.setState({ [name]: value });
                this.ListaAlumnosBusquedaFiltradaNombreYEstado(value, this.state.EstadoAlumno,this.state.ClaseAlumno)
            }
            else{
                this.setState({ [name]: value, ListaAlumnos: [] });
            }
        }
        if(name == "EstadoAlumno"){
            this.setState({ [name]: value });
            this.ListaAlumnosBusquedaFiltradaNombreYEstado(this.state.FiltrarNombres, value,this.state.ClaseAlumno);
        }
        
        if(name == "ClaseAlumno"){
            this.setState({ [name]: value });
            this.ListaAlumnosBusquedaFiltradaNombreYEstado(this.state.FiltrarNombres, this.state.EstadoAlumno,value);
        }

        this.setState({ [name]: value });
    }

    render() {
    const { classes } = this.props;

        let agregar;
        if (this.state.showDardeBajaAlumno == true) {
            agregar =
                <ModalDardeBajaAlumno
                    Titulo={this.state.Titulo}
                    showDardeBajaAlumno={this.state.showDardeBajaAlumno}
                    eventClick={this.eventoDardeBajaAlumno}
                    op={this.state.op}
                    _id={this.state._id}
                    parentCallback={this.RegresarMensaje}
                />
        }

        if (this.state.showModalEditarAlumno == true) {
            agregar =
                <ModalEditarAlumno
                    Titulo={this.state.Titulo}
                    showModalEditarAlumno={this.state.showModalEditarAlumno}
                    eventClick={this.eventoModalEditarAlumno}
                    op={this.state.op}
                    AlumnoSeleccionado={this.state.AlumnoSeleccionado}
                    parentCallback={this.RegresarMensaje}
                />
        }

        if (this.state.showModalInscribirProspecto == true) {
            agregar =
                <ModalInscribirProspecto
                    Titulo={this.state.Titulo}
                    showModalInscribirProspecto={this.state.showModalInscribirProspecto}
                    eventClick={this.eventoModalInscribirProspecto}
                    op={this.state.op}
                    parentCallback={this.InscribirAlumnoProspecto}
                />
        }

        if(this.state.showModalAsignarGrupo == true){
            agregar = 
            <ModalAsignarGrupo
                Titulo={"Asignación de grupos"}
                showModalAsignarGrupo={this.state.showModalAsignarGrupo}
                eventClick={this.eventoModalAsignarGrupo}
                AlumnoSeleccionado={this.state.AlumnoSeleccionado}
            />
        }

        if(this.state.showModalDocumentos == true){
            agregar = (
                <ModalDocumentos
                    Titulo={"Impresión de documentos"}
                    showModalDocumentos={this.state.showModalDocumentos}
                    eventClick={this.eventoModalDocumentos}
                    AlumnoSeleccionado={this.state.AlumnoSeleccionado}
                    EstadoAlumno={this.state.EstadoAlumno}
                />
            )
        }

        if(this.state.showModalCorreo == true){
            agregar = (
                <ModalEnviarCorreo
                    showModalCorreo={this.state.showModalCorreo}
                    eventClick={this.eventoModalCorreos}
                    AlumnoSeleccionado={this.state.AlumnoSeleccionado}
                    op={this.state.op}
                />
            )
        }

        if (this.state.cargando == true) {
            agregar = (
              <ModalCargando
                Cuerpo={this.state.CuerpoCargando}
                show={this.state.cargando}
              />
            );
          }

    let alumnos;
    if(this.state.ListaAlumnos.length > 0){
        if(this.state.EstadoAlumno == "Activo"){
            alumnos =
            this.state.ListaAlumnos.map(alumno => {
                return(
                    <tr style={{ cursor: "default"}} hover>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>{alumno.NumeroCuenta}</td>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>{alumno.NombreCompleto}</td>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>{alumno.Clase}</td>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>{alumno.EstadoAlumno}</td>
                        <td style={{verticalAlign: "middle"}}>
                            <Button data-toggle="tooltip" data-placement="bottom" title="Dar de baja al alumno" color="danger" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalDardeBajaAlumno(alumno._id)}><Close /></Button>
                            <Button data-toggle="tooltip" data-placement="bottom" title="Editar alumno" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalEditarAlumno(alumno)}><Edit /></Button>
                            <Button data-toggle="tooltip" data-placement="bottom" title="Asignar grupo alumno" color="info" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openModalAsignarGrupo(alumno)}><GroupAdd /></Button>
                            <Button data-toggle="tooltip" data-placement="bottom" title="Descargar documentos" color="info" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openshowModalDocumentos(alumno)}><Description /></Button>
                            <Button data-toggle="tooltip" data-placement="bottom" title="Enviar correo" color="info" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openshowModalCorreo(alumno)}><Email /></Button>
                        </td>
                    </tr>
                )
            })
        }
        else if(this.state.EstadoAlumno == "En_Proceso_de_Inscripción"){
            alumnos =
            this.state.ListaAlumnos.map(alumno => {
                return(
                    <tr style={{ cursor: "default"}} hover>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>{alumno.NumeroCuenta}</td>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>{alumno.NombreCompleto}</td>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>{alumno.Clase}</td>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>En Proceso de Inscripción</td>
                        <td>
                            <Button color="danger" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalDardeBajaAlumno(alumno._id)}><Close /></Button>
                            <Button color="info" round justIcon size="sm" className="mr-2 pull-right"  onClick={() => this.openModalEditarAlumno(alumno)}><Edit /></Button>
                            <Button color="info" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openModalAsignarGrupo(alumno)}><GroupAdd /></Button>
                        </td>
                    </tr>
                )
            })
        }
        else if(this.state.EstadoAlumno == "Inactivo"){
            alumnos =
            this.state.ListaAlumnos.map(alumno => {
                return(
                    <tr style={{ cursor: "default"}} hover>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>{alumno.NumeroCuenta}</td>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>{alumno.NombreCompleto}</td>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>{alumno.Clase}</td>
                        <td onClick={() => this.openModalEditarAlumno(alumno)} style={{verticalAlign: "middle"}}>Inactivo</td>
                        <td>
                            <Button data-toggle="tooltip" data-placement="bottom" title="Editar alumno" color="info" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openModalEditarAlumno(alumno)}><Edit /></Button>
                            <Button data-toggle="tooltip" data-placement="bottom" title="Asignar grupo alumno" color="info" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openModalAsignarGrupo(alumno)}><GroupAdd /></Button>
                            <Button data-toggle="tooltip" data-placement="bottom" title="Descargar documentos" color="info" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openshowModalDocumentos(alumno)}><Description /></Button>
                        </td>
                    </tr>
                )
            })
        }
    }
    else{
        alumnos=
        <tr>
            <td style={{textAlign: "center", marginTop: "10px"}} colSpan={5}>NO HAY ALUMNOS</td>
        </tr>
    }


//console.log(this.state.ListaAlumnos)
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Buscar Alumnos</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                    <FormControl className={classes.formControl} size="small" variant="outlined">
                                        <InputLabel style={{marginBottom: "10px"}} id="estadolabel">Estado alumno</InputLabel>
                                            <Select
                                                labelId="estadolabel"
                                                //fullWidth={true}
                                                value={this.state.EstadoAlumno}
                                                onChange={this.handleChange.bind(this)}
                                                name="EstadoAlumno"
                                                //fullWidth={true}
                                                label="Estado alumno"
                                                style={{ width: "15em", marginBottom: "10px" }}
                                                //error={this.state.errorIDIdioma}
                                                //success={!this.state.errorIDIdioma}
                                            >
                                                <MenuItem disabled>Seleccione un estado</MenuItem>
                                                <MenuItem value={"Activo"}>Activo</MenuItem>
                                                <MenuItem value={"En_Proceso_de_Inscripción"}>En proceso de inscripción</MenuItem>
                                                <MenuItem value={"Inactivo"}>Inactivo</MenuItem>
                                            </Select>
                                    </FormControl>
                                    <FormControl className={classes.formControl} size="small" variant="outlined">
                                    <InputLabel style={{marginBottom: "10px", marginLeft: "10px"}} id="Claselabel">Clase</InputLabel>
                                        <Select
                                            labelId="Claselabel"
                                            //fullWidth={true}
                                            value={this.state.ClaseAlumno}
                                            onChange={this.handleChange.bind(this)}
                                            name="ClaseAlumno"
                                            //fullWidth={true}
                                            label="Clase"
                                            style={{ width: "12em", marginBottom: "10px", marginLeft: "10px" }}
                                            //error={this.state.errorIDIdioma}
                                            //success={!this.state.errorIDIdioma}
                                        >
                                            <MenuItem value={"Vacio"}>Todos</MenuItem>
                                            <MenuItem value={"Niño"}>Niño</MenuItem>
                                            <MenuItem value={"Adolescente"}>Adolescente</MenuItem>
                                            <MenuItem value={"Adulto"}>Adulto</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                            id="FiltrarNombres"
                                            style={{ width: "23vw"}}
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            label="Matricula o nombre"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search />
                                                    </InputAdornment>
                                                    ),
                                                onChange: this.handleChange.bind(this),
                                                value: this.state.FiltrarNombres,
                                                name: "FiltrarNombres",
                                                id: "FiltrarNombres",
                                                //error: this.state.errorMotivoCantacto,
                                                //success: !this.state.errorMotivoCantacto
                                            }}
                                            />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Button size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.openModalInscribirNuevoAlumno()}><AddCircle/>&nbsp;Nuevo alumno</Button>
                                        <Button size="sm" type="submit" color="info" round className="pull-right mr-2" onClick={() => this.openModalInscribirProspecto()}><AddCircle/>&nbsp;Inscribir prospecto</Button>
                                        <Button className="pull-right mr-2" color="info"round size="sm" onClick={() =>this.openshowModalCorreo(0)}><Email />Enviar correos</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader color="info" text>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <h4 className="pull-left">Alumnos</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                    <h4 className="pull-right" style={{marginRight: "15px", marginTop: 0}}>{this.state.ListaAlumnos.length} Alumno(s)</h4>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="table-responsive-xl table-wrapper-scroll-y">
                                            <table className="table table-sm table-hover">
                                                <thead>
                                                    <th>No. Cuenta</th>
                                                    <th>Nombre completo</th>
                                                    <th>Clase</th>
                                                    <th>Estado</th>
                                                    <th></th>
                                                </thead>
                                                <tbody>
                                                {alumnos}
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <ModalPregunta
                        Titulo={"Acreditación"}
                        Cuerpo={"¿Esta seguro de quitar la acreditación a las ventas seleccionadas?"}
                        show={this.state.show}
                        eventClick={this.eventoModalPregunta}
                    />
                    {agregar}
                </div>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
                
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(Alumnos);