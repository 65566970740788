import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import ModalCargando from "../../../components/Generales/ModalCargando";

class ModalInscribirProspecto extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      BuscarProspecto: "",
      ListaProspectos: [],
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: "",
      CuerpoCargando: "Cargando",
      cargando: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    if(this.props.op == 1){
      this.setState({
        _id: this.props._id
      })
    }
  }

ListaProspectosBusquedaFiltrada(texto) {
  if(this.state.ListaProspectos == 0){
    this.setState({cargando: true});
  }
   fetch(Globales.Url + 'Prospectos/Prospectos/ListaProspectosBusquedaFiltrada', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Texto: texto})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              if (data.ListaProspectos.length > 1) {
                  this.setState({ ListaProspectos: data.ListaProspectos, Activo: 1, cargando: false });
              }
              else {
                  let ListaAux = [];
                  ListaAux.push(data.ListaProspectos);
                  //console.log(ListaAux);
                  this.setState({ ListaProspectos: ListaAux, Activo: 1, cargando: false });
              }
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarProspecto(prospecto) {
    this.setState({ op1: 1 });
    this.props.parentCallback(prospecto);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    if(name == "BuscarProspecto"){
      this.setState({[name]: value});
      this.ListaProspectosBusquedaFiltrada(value)
    }

    this.setState({[name]: value});
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

 
  render() {
    const { classes } = this.props;
    //console.log(this.state._id)
    //console.log(this.state.ListaProspectos)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    this.state.ListaProspectos.sort((a, b) => {
      let fa = a.Nombre.toLowerCase(),
          fb = b.Nombre.toLowerCase();
  
      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
  });

    let Prospectos;
    if(this.state.ListaProspectos.length > 0){
      Prospectos =
        this.state.ListaProspectos.map(prospecto => {
          return(
            <tr style={{ cursor: "default"}} hover>
              <td style={{fontSize: "12px"}}>{prospecto.NombreCompleto}</td>
              <td style={{fontSize: "12px"}}>{prospecto.Correo !== null && prospecto.Correo !== undefined ? prospecto.Correo : ""}</td>
              <td style={{fontSize: "12px"}}>{prospecto.Telefono !== null && prospecto.Telefono !== undefined ? prospecto.Telefono : ""}</td>
              <td style={{fontSize: "12px"}}>{prospecto.Celular !== null && prospecto.Celular !== undefined ? prospecto.Celular : ""}</td>
              <td style={{fontSize: "12px"}}>{prospecto.Clase}</td>
              <td className="pull-right"><Button data-toggle="tooltip" data-placement="bottom" title="Registrar prospecto" color="info" round justIcon size="sm" className="mr-1" onClick={() => this.RegresarProspecto(prospecto)}><ArrowForwardIos /></Button></td>
            </tr>
          )
        })
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showModalInscribirProspecto}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="BuscarProspecto"
                    label="Buscar..."
                    style={{width: "100%"}}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                        ),
                      onChange: this.handleChange.bind(this),
                      value: this.state.BuscarProspecto,
                      name: "BuscarProspecto",
                      id: "BuscarProspecto",
                      //error: this.state.errorMotivoCantacto,
                      //success: !this.state.errorMotivoCantacto
                  }}
                  />
                </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div style={{height: "2em"}}></div>
                    <table className="table-hover">
                      <thead>
                        <td style={{fontWeight: "bold", fontSize: "14px"}}>Nombre completo</td>
                        <td style={{fontWeight: "bold", fontSize: "14px"}}>Correo</td>
                        <td style={{fontWeight: "bold", fontSize: "14px"}}>Teléfono</td>
                        <td style={{fontWeight: "bold", fontSize: "14px"}}>Celular</td>
                        <td style={{fontWeight: "bold", fontSize: "14px"}}>Clase</td>
                      </thead>
                      <tbody>
                        <tr><td colSpan={6}><Divider/></td></tr>
                        {Prospectos}
                      </tbody>
                    </table>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>  
          <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Button tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick("esc")}>&nbsp;Cancelar</Button>
                </GridItem>
              </GridContainer>  
        </Modal>
        <div>
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalInscribirProspecto);