import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import ModalPregunta from "components/Generales/ModalPregunta";

class ModalRealizarCorte extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      ObjCaja: {},
      InfoCorte: {},

  //Billetes
      Billete1000: 0,
      Billete500: 0,
      Billete200: 0,
      Billete100: 0,
      Billete50: 0,
      Billete20: 0,

  //Monedas
      Moneda100: 0,
      Moneda20: 0,
      Moneda10: 0,
      Moneda5: 0,
      Moneda2: 0,
      Moneda1: 0,
      Centavo50: 0,
      
  //Total
      SumaTotal: 0,
      TotalGastos: 0,
      TotalGastosCancalados: 0,
      SaldoCajaTemp: 0,
      SaldoCaja: 0,
      Observaciones: "",
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: "",
      CuerpoCargando: "Cargando",
      show: false
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.eventoModalPregunta = this.eventoModalPregunta.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    this.ObtenerInformacionParaCorte();
  }

  async ObtenerInformacionParaCorte() {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'CorteCaja/CorteCaja/ObtenerInformacionParaCorte', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log("InfoCorte: ",data.InfoCorte);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", InfoCorte: [], cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              this.setState({InfoCorte: data.InfoCorte, cargando: false}, () => {
                this.ObtenerListaGastosDesdeFecha(data.InfoCorte);
              });
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  async ObtenerListaGastosDesdeFecha(Corte) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'CorteCaja/CorteCaja/ObtenerListaGastosDesdeFecha', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({Fecha: moment(Corte.FechaCorteAnterior).format("YYYY-MM-DD")})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log("ListaGastos: ", data.ListaGastos);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaNiveles: [], cargando: false });
                // this.showNotification();
            } else { //Filtrado exitoso
              let sumaGastos = 0;
              let sumaGastosCancelados = 0;
              //let total = 0;
              //let aux = []
              if(data.ListaGastos.length > 1){
                data.ListaGastos.map(gasto => {
                  if(moment(gasto.FechaRegistro) >= moment(Corte.FechaCorteAnterior)){
                    if(gasto.TipoPago == "Efectivo" && gasto.Estado !== "Cancelado" && gasto.OrigenEfectivo == "Caja"){
                      sumaGastos += parseFloat(gasto.Monto)
                      //aux.push(gasto)
                    }

                    if(gasto.TipoPago == "Efectivo" && gasto.Estado == "Cancelado" && gasto.OrigenEfectivo == "Caja"){
                      sumaGastosCancelados += parseFloat(gasto.Monto)
                      //aux.push(gasto)
                    }
                  }
                })
              }
              else{
                if(moment(data.ListaGastos.FechaRegistro) >= moment(Corte.FechaCorteAnterior)){
                  if(data.ListaGastos.TipoPago == "Efectivo" && data.ListaGastos.Estado !== "Cancelado" && data.ListaGastos.OrigenEfectivo == "Caja"){
                    sumaGastos = parseFloat(data.ListaGastos.Monto)
                    //aux.push(data.ListaGastos)
                  }

                  if(data.ListaGastos.TipoPago == "Efectivo" && data.ListaGastos.Estado == "Cancelado" && data.ListaGastos.OrigenEfectivo == "Caja"){
                    sumaGastosCancelados = parseFloat(data.ListaGastos.Monto)
                    //aux.push(data.ListaGastos)
                  }
                }
              }

              //console.log("AUX: ", aux)

              this.state.InfoCorte.Efectivo -= sumaGastos
              this.state.InfoCorte.SaldoFinal -= sumaGastos
              this.state.InfoCorte.Gastos = sumaGastos
              this.state.InfoCorte.GastosCancelados = sumaGastosCancelados
                this.setState({ cargando: false, InfoCorte: this.state.InfoCorte, TotalGastos: sumaGastos, TotalGastosCancalados: sumaGastosCancelados});
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  ValidarCorteCaja(){
    let caja = this.state.InfoCorte;
    let DesgloseEfectivo = {
      Billete100: parseInt(this.state.Billete100),
      Billete1000: parseInt(this.state.Billete1000),
      Billete20: parseInt(this.state.Billete20),
      Billete200: parseInt(this.state.Billete200),
      Billete50: parseInt(this.state.Billete50),
      Billete500: parseInt(this.state.Billete500),
      Centavo50: parseInt(this.state.Centavo50),
      Moneda1: parseInt(this.state.Moneda1),
      Moneda10: parseInt(this.state.Moneda10),
      Moneda100: parseInt(this.state.Moneda100),
      Moneda2: parseInt(this.state.Moneda2),
      Moneda20: parseInt(this.state.Moneda20),
      Moneda5: parseInt(this.state.Moneda5),
      SumaTotal: parseFloat(this.state.SumaTotal)
    };

    let objCaja = {
      Cheques: caja.Cheques,
      Credito: caja.Credito,
      Debito: caja.Debito,
      Deposito: caja.Deposito,
      Depositos: caja.Depositos,
      Desglose: DesgloseEfectivo,
      Efectivo: caja.Efectivo,
      Egresos: this.state.TotalGastos,
      FaltanteSobrante: this.state.SumaTotal !== 0 ? Math.abs(parseFloat(caja.Efectivo) - this.state.SumaTotal) : 0,
      Fecha: moment(new Date()).format("YYYY-MM-DD"),
      FechaCorteAnterior: caja.FechaCorteAnterior,
      FolioFinal: caja.FolioFinal,
      FolioInicio: caja.FolioInicio,
      Gastos: this.state.TotalGastos,
      GastosCancelados: this.state.TotalGastosCancalados,
      Ingresos: caja.Ingresos,
      NombreUsuario: cookie.get('NombreUsuarioCompleto'),
      Observaciones: this.state.Observaciones,
      Retiros: caja.Retiros,
      SaldoCaja: parseFloat(this.state.SaldoCaja),
      SaldoFinal: caja.SaldoFinal,
      SaldoInicial: caja.SaldoInicial,
      TotalDepositos: Math.round(caja.TotalDepositos),
      TotalGastos: Math.round(this.state.TotalGastos),
      TotalGastosCancelados: Math.round(this.state.TotalGastosCancalados),
      TotalRetiros: Math.round(caja.TotalRetiros),
      TotalVentas: Math.round(caja.TotalVentas),
      TotalVentasCanceladas: Math.round(caja.TotalVentasCanceladas),
      Transferencias: Math.round(caja.Transferencias),
      Usuario: JSON.parse(cookie.get('id')),
      UsuarioCorteAnterior: caja.UsuarioCorteAnterior,
      Ventas: caja.Ventas,
      VentasCanceladas: caja.VentasCanceladas,
      VentasEfectivo: caja.VentasEfectivo,
      _id: caja._id,
      _idEmpresa: caja._idEmpresa
    }

    //console.log(objCaja)

    this.setState({ObjCaja: objCaja, show: true})
  }

  async RealizarCorteCaja() {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'CorteCaja/CorteCaja/RealizarCorteCaja', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({caja: this.state.ObjCaja})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaNiveles: [], cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              this.setState({cargando: false});
              this.RegresarReporte(data.mensaje, data.ReporteCorteCaja)
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarReporte(mensaje, reporte) {
    //this.setState({ op1: 1 });
    this.props.parentCallback(mensaje, reporte);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  eventoModalPregunta(valor) {
    this.setState({show: false,});
    if (valor) {
      this.RealizarCorteCaja()
    }
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    let Cantidad = 0;

    switch(name){
      case "SaldoCajaTemp":
        Cantidad = value.replace(/[^0-9\.\$]/g, '');
        if(Cantidad < 0){
          this.setState({[name]: 0, SaldoCaja: 0});
        }
        else{
          this.setState({[name]: Cantidad, SaldoCaja: Cantidad});
        }
        break;

      case "Observaciones":
        this.setState({[name]: value});
        break;
      
      default:
        Cantidad = parseInt(value.replace(/[^0-9]/g, ''));

        if(Cantidad < 0){
          this.setState({[name]: 0}, () => 
          this.setState({
            SumaTotal: ((1000*this.state.Billete1000) +
              (500*this.state.Billete500) +
              (200*this.state.Billete200) + 
              (100*this.state.Billete100) +
              (50*this.state.Billete50) + 
              (20*this.state.Billete20) + 
              (100*this.state.Moneda100) + 
              (20*this.state.Moneda20) + 
              (10*this.state.Moneda10) + 
              (5*this.state.Moneda5) + 
              (2*this.state.Moneda2) + 
              (1*this.state.Moneda1) + 
              (0.5*this.state.Centavo50))
          }));
        }
        else{
          this.setState({[name]: Cantidad}, () => 
          this.setState({
            SumaTotal: ((1000*this.state.Billete1000) +
              (500*this.state.Billete500) +
              (200*this.state.Billete200) + 
              (100*this.state.Billete100) +
              (50*this.state.Billete50) + 
              (20*this.state.Billete20) + 
              (100*this.state.Moneda100) + 
              (20*this.state.Moneda20) + 
              (10*this.state.Moneda10) + 
              (5*this.state.Moneda5) + 
              (2*this.state.Moneda2) + 
              (1*this.state.Moneda1) + 
              (0.5*this.state.Centavo50))
          }));
        }
        break;
    }
  }

  handleFocus = (event) => {
    const e = event.target;
    const { name, value } = event.target;

    if(name == "SaldoCajaTemp"){
      this.setState({SaldoCajaTemp: this.state.SaldoCaja}, () => e.select())
    }
    else{
      e.select()
    }
  }

  handleBlur = (event) => {
    const { name, value } = event.target;
    let int = 0;
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    if(name == "SaldoCajaTemp"){
      if(isNaN(value)){
      this.setState({[name]: convertirPesos(0)});
      }
      else{
        this.setState({[name]: convertirPesos(value)});
      }
    }
    else{
      int = parseInt(value);
      if(isNaN(int)){
        this.setState({[name]: 0});
        }
    }  
  }
 
  cerrarModal = () => {
    this.props.eventClick("esc")
  }
  
  render() {
    const { classes } = this.props;
    const { InfoCorte, SumaTotal, SaldoCaja, SaldoCajaTemp, Observaciones } = this.state;
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    if (this.state.show == true) {
      agregar = (
        <ModalPregunta
            Titulo={"Realizar Corte de Caja"}
            Cuerpo={"¿Seguro que desea realizar el corte de caja?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
      );
    }

    //console.log(InfoCorte)
    //console.log(SumaTotal)
    //console.log(SaldoCaja)
    //console.log(SaldoCajaTemp)
    return (
      <div>
        <Modal
          isOpen={this.props.showRealizarCorte}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="table-responsive-lg text-nowrap">
                    <table className="col-xs-12 col-sm-12 col-md-12 table">
                      <thead class="thead-dark">
                        <th style={{textAlign: "center"}}>Billetes</th>
                        <th style={{textAlign: "center"}}>Monedas</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <table className="table">
                              <thead class="thead-light">
                                <th style={{width: "4em"}}>Denominación</th>
                                <th style={{paddingLeft: "1.5rem"}}>Cantidad</th>
                                <th style={{paddingLeft: "0.6rem"}}>Total</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(1000)}</td>
                                  <td>
                                    <TextField
                                      id="Billete1000"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Billete1000,
                                          name: "Billete1000",
                                          id: "Billete1000",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Billete1000) ? convertirPesos(1000 * parseInt(this.state.Billete1000)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(500)}</td>
                                  <td>
                                    <TextField
                                      id="Billete500"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Billete500,
                                          name: "Billete500",
                                          id: "Billete500",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Billete500) ? convertirPesos(500 * parseInt(this.state.Billete500)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(200)}</td>
                                  <td>
                                    <TextField
                                      id="Billete200"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Billete200,
                                          name: "Billete200",
                                          id: "Billete200",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Billete200) ? convertirPesos(200 * parseInt(this.state.Billete200)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(100)}</td>
                                  <td>
                                    <TextField
                                      id="Billete100"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Billete100,
                                          name: "Billete100",
                                          id: "Billete100",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Billete100) ? convertirPesos(100 * parseInt(this.state.Billete100)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(50)}</td>
                                  <td>
                                    <TextField
                                      id="Billete50"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Billete50,
                                          name: "Billete50",
                                          id: "Billete50",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Billete50) ? convertirPesos(50 * parseInt(this.state.Billete50)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(20)}</td>
                                  <td>
                                    <TextField
                                      id="Billete20"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Billete20,
                                          name: "Billete20",
                                          id: "Billete20",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Billete20) ? convertirPesos(20 * parseInt(this.state.Billete20)) : "$0.00"}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table className="table">
                              <thead class="thead-light">
                                <th style={{width: "4em"}}>Denominación</th>
                                <th style={{paddingLeft: "1.5rem"}}>Cantidad</th>
                                <th style={{paddingLeft: "0.6rem"}}>Total</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(100)}</td>
                                  <td>
                                    <TextField
                                      id="Moneda100"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Moneda100,
                                          name: "Moneda100",
                                          id: "Moneda100",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Moneda100) ? convertirPesos(100 * parseInt(this.state.Moneda100)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(20)}</td>
                                  <td>
                                    <TextField
                                      id="Moneda20"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Moneda20,
                                          name: "Moneda20",
                                          id: "Moneda20",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Moneda20) ? convertirPesos(20 * parseInt(this.state.Moneda20)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(10)}</td>
                                  <td>
                                    <TextField
                                      id="Moneda10"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Moneda10,
                                          name: "Moneda10",
                                          id: "Moneda10",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Moneda10) ? convertirPesos(10 * parseInt(this.state.Moneda10)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(5)}</td>
                                  <td>
                                    <TextField
                                      id="Moneda5"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Moneda5,
                                          name: "Moneda5",
                                          id: "Moneda5",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Moneda5) ? convertirPesos(5 * parseInt(this.state.Moneda5)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(2)}</td>
                                  <td>
                                    <TextField
                                      id="Moneda2"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Moneda2,
                                          name: "Moneda2",
                                          id: "Moneda2",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Moneda2) ? convertirPesos(2 * parseInt(this.state.Moneda2)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>{convertirPesos(1)}</td>
                                  <td>
                                    <TextField
                                      id="Moneda1"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Moneda1,
                                          name: "Moneda1",
                                          id: "Moneda1",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Moneda1) ? convertirPesos(1 * parseInt(this.state.Moneda1)) : "$0.00"}</td>
                                </tr>
                                <tr>
                                  <td style={{textAlign: "center"}}>$0.50</td>
                                  <td>
                                    <TextField
                                      id="Centavo50"
                                      style={{ width: "7em"}}
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      //label="Cantidad"
                                      InputProps={{
                                          onChange: this.handleChange.bind(this),
                                          onFocus: this.handleFocus.bind(this),
                                          onBlur: this.handleBlur.bind(this),
                                          value: this.state.Centavo50,
                                          name: "Centavo50",
                                          id: "Centavo50",
                                          //error: this.state.errorConcepto,
                                          //success: !this.state.errorConcepto
                                      }}
                                    />
                                  </td>
                                  <td>{!isNaN(this.state.Centavo50) ? convertirPesos(0.5 * parseInt(this.state.Centavo50)) : "$0.00"}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <div className="table-responsive-lg text-nowrap">
                              <table className="table">
                                <thead>
                                  <th style={{textAlign: "center"}}>Cheques</th>
                                  <th style={{textAlign: "center"}}>Transferencias</th>
                                  <th style={{textAlign: "center"}}>Dépositos</th>
                                  <th style={{textAlign: "center"}}>T. de crédito</th>
                                  <th style={{textAlign: "center"}}>T. de débito</th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign: "center"}}>{Object.keys(InfoCorte).length !== 0 ? convertirPesos(InfoCorte.Cheques) : convertirPesos(0)}</td>
                                    <td style={{textAlign: "center"}}>{Object.keys(InfoCorte).length !== 0 ? convertirPesos(InfoCorte.Transferencias) : convertirPesos(0)}</td>
                                    <td style={{textAlign: "center"}}>{Object.keys(InfoCorte).length !== 0 ? convertirPesos(InfoCorte.Deposito) : convertirPesos(0)}</td>
                                    <td style={{textAlign: "center"}}>{Object.keys(InfoCorte).length !== 0 ? convertirPesos(InfoCorte.Credito) : convertirPesos(0)}</td>
                                    <td style={{textAlign: "center"}}>{Object.keys(InfoCorte).length !== 0 ? convertirPesos(InfoCorte.Debito) : convertirPesos(0)}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <div className="table-responsive-lg text-nowrap">
                              <table className="table">
                                <thead>
                                  <th style={{textAlign: "center"}}>Efectivo</th>
                                  <th style={{textAlign: "center"}}>Total entregado</th>
                                  <th style={{textAlign: "center"}}>Diferencia</th>
                                  <th style={{textAlign: "center"}}>Saldo en caja</th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign: "center"}}>{Object.keys(InfoCorte).length !== 0 ? convertirPesos(InfoCorte.Efectivo) : convertirPesos(0)}</td>
                                    <td style={{textAlign: "center"}}>{convertirPesos(SumaTotal)}</td>
                                    <td style={{textAlign: "center"}}>{SumaTotal !== 0 ? convertirPesos(Math.abs(parseFloat(InfoCorte.Efectivo) - SumaTotal)) : "$0.00"}</td>
                                    <td style={{textAlign: "center"}}>
                                      <TextField
                                        id="SaldoCajaTemp"
                                        style={{ width: "10em"}}
                                        variant="outlined"
                                        type="text"
                                        size="small"
                                        //label="Cantidad"
                                        InputProps={{
                                            onChange: this.handleChange.bind(this),
                                            onFocus: this.handleFocus.bind(this),
                                            onBlur: this.handleBlur.bind(this),
                                            value: SaldoCajaTemp,
                                            name: "SaldoCajaTemp",
                                            id: "SaldoCajaTemp",
                                            //error: this.state.errorConcepto,
                                            //success: !this.state.errorConcepto
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} style={{textAlign: "center"}}>
                          <TextField
                              id="Observaciones"
                              style={{ width: "30em"}}
                              variant="outlined"
                              type="text"
                              size="small"
                              label="Observaciones"
                              InputProps={{
                                  onChange: this.handleChange.bind(this),
                                  value: Observaciones,
                                  name: "Observaciones",
                                  id: "Observaciones",
                                  //error: this.state.errorConcepto,
                                  //success: !this.state.errorConcepto
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card> 
          <GridItem xs={12} sm={12} md={12}>
              <Button disabled={Object.keys(InfoCorte).length == 0} color="info" tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.ValidarCorteCaja()}><Save/>&nbsp;Realizar corte</Button>
              <Button tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cancelar</Button>
          </GridItem>    
        </Modal>
        <div id="">
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalRealizarCorte);