import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TextField from "@material-ui/core/TextField";
import { CancelPresentation, Save, Clear, Check, TransferWithinAStationSharp } from '@material-ui/icons';

export default class ModalPreguntaAfectaEfectivo extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      FechaInscripcion: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      errorFecha: false,
    }
    this.handleChange = this.handleChange.bind(this);
    // this.escFunction = this.escFunction.bind(this);
  }
  // escFunction(event) {
  //   if (event.keyCode === 27) {
  //     this.props.eventClick()
  //   }
  // }
  // componentDidMount() {

  //   document.addEventListener("keydown", this.escFunction, false);
  // }
  // componentWillUnmount() {
  //   document.removeEventListener("keydown", this.escFunction, false);
  // }

  handleChange(e){
    const {name,value} = e.target;

    this.setState({[name]: value});
  }

  VerificarFecha(){
    let valido = true;

    if(this.state.FechaInscripcion == ""){
      this.setState({errorFecha: true});
    }

    if(valido){
      this.props.eventClick(this.state.FechaInscripcion)
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} className="static-modal" backdrop={"static"}>
          <Card >
            <CardHeader color={(this.props.headerColor == null || this.props.headerColor == undefined? "danger":this.props.headerColor)}>
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              {this.props.Cuerpo}
              {this.props.FechaOp == 1 ? 
              <>
                <br/>
                <br/>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        id="FechaInscripcion"
                        style={{ width: "30em", marginTop: "10px" }}
                        variant="outlined"
                        type="date"
                        format="DD-MM-YYYY"
                        size="small"
                        label="Fecha para inscripción"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaInscripcion,
                          name: "FechaInscripcion",
                          id: "FechaInscripcion",
                          error: this.state.errorFecha,
                          success: !this.state.errorFecha
                        }}
                    />
                  </GridItem>
                  <br/>
                  <br/>
                </>
              : ""}
              <GridItem>
                <Button size="sm" color="success" round className="pull-right" onClick={() => this.props.FechaOp == 1 ? this.VerificarFecha() : this.props.eventClick(true)}><Check/>&nbsp;{this.props.btnAceptar}</Button>
                <Button size="sm" color="danger" round className="pull-right mr-2" onClick={() => this.props.eventClick(false)}><Clear/>&nbsp;{this.props.btnCancelar}</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
      </div>
    );
  }
}