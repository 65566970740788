import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import ModalPregunta from "components/Generales/ModalPregunta";

class ModalMovimientoCaja extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      ListaEmpresas: [],
      IDEmpresa: '',
      TipoMovimientoCaja: "Depósito",
      Concepto: "",
      Monto: 0,
      errorEmpresa: false,
      errorConcepto: false,
      errorMonto: false,
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: "",
      CuerpoCargando: "Cargando",
      show: false
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.eventoModalPregunta = this.eventoModalPregunta.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    this.obtenerEmpresasActivas();
  }

  obtenerEmpresasActivas() {
    this.setState({cargando:true})
    fetch(Globales.Url + 'Facturacion/Facturacion/ListadoEmpresas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, cargando:false });
          this.showNotification();
        } else { //Filtrado exitoso

          if (data.ListaEmpresas.length > 1) {
            this.setState({ ListaEmpresas: data.ListaEmpresas, Activo: 1, cargando:false });
          }
          else {
            let ListaAuxEmpresas = [];
            ListaAuxEmpresas.push(data.ListaEmpresas);
            //console.log(ListaAux);
            this.setState({ ListaEmpresas: ListaAuxEmpresas, Activo: 1, cargando:false });
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  CrearobjMovimientoCaja(){
    let valor1 = 0;
    let valor2 = 0;
    let valor3 = 0;

    if(this.state.Monto == 0 || this.state.Monto == ""){
      valor1 = 1;
      this.setState({errorMonto: true, mensaje: "Proporcione un monto válido", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      valor1 = 0;
      this.setState({errorMonto: false});
    }

    if(this.state.Concepto == ""){
      valor2 = 1;
      this.setState({errorConcepto: true, mensaje: "Proporcione el concepto para el movimiento", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      valor2 = 0;
      this.setState({errorConcepto: false});
    }

    if(this.state.IDEmpresa == ''){
      valor3 = 1;
      this.setState({errorEmpresa: true, mensaje: "Seleccione una empresa", colorNotificacion: "danger"});
      this.showNotification();
    }
    else{
      valor3 = 0;
      this.setState({errorEmpresa: false});
    }

    if(valor1 + valor2 + valor3 == 0){
      let objMovimientoCaja = {
        Concepto: this.state.Concepto,
        Fecha: moment(new Date()).format("YYYY-MM-DD"),
        IdUsuario: JSON.parse(cookie.get('id')),
        Monto: parseFloat(this.state.Monto).toFixed(2),
        Tipo: this.state.TipoMovimientoCaja,
        Usuario: cookie.get('NombreUsuarioCompleto'),
        _idEmpresa: this.state.IDEmpresa
      }

      //console.log("Movimiento caja", objMovimientoCaja)
      this.RegistrarMovimientoCaja(objMovimientoCaja);
    }
  }

  async RegistrarMovimientoCaja(movimiento) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'CorteCaja/CorteCaja/RegistrarMovimientoCaja', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({movimiento: movimiento})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaLibros: [], cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              this.setState({cargando: false})
              this.RegresarMensaje(data.mensaje)
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    //this.setState({ op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  eventoModalPregunta(valor) {
    this.setState({show: false,});
    if (valor) {
      return("lol")
    }
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    let monto = 0;

    switch(name){
      case "Monto":
        monto = value.replace(/[^0-9\.]/g, '');
        this.setState({[name]: monto});
        break;

      default:
        this.setState({[name]: value});
        break;
    }
  }

  handleFocus = (event) => {
    const e = event.target;
    e.select()
  }
 
  cerrarModal = () => {
    this.props.eventClick("esc")
  }
  
  render() {
    const { classes } = this.props;
    const { Monto } = this.state;


    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    if (this.state.show == true) {
      agregar = (
        <ModalPregunta
            Titulo={"Cambiar nivel grupo"}
            Cuerpo={"¿Está seguro que desea cambiar el nievel del grupo? Una vez cambiado, no podrá modificar las calificaciones de los niveles anteriores."}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
      );
    }

    //console.log(Monto)
    return (
      <div>
        <Modal
          isOpen={this.props.showMovimientoCaja}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="empresalabel">Empresa</InputLabel>
                      <Select
                          labelId="empresalabel"
                          //fullWidth={true}
                          value={this.state.IDEmpresa}
                          onChange={this.handleChange.bind(this)}
                          name="IDEmpresa"
                          //autoWidth={true}
                          label="Empresa"
                          style={{ width: "28.6em", marginTop: "10px" }}
                          error={this.state.errorEmpresa}
                          success={!this.state.errorEmpresa}
                      >
                        <MenuItem disabled>Seleccione una empresa</MenuItem>
                          {this.state.ListaEmpresas.map(Empresa =>
                          <MenuItem value={Empresa._id}>{(Empresa.RazonSocial).toUpperCase()}</MenuItem>
                          )}
                      </Select>
                  </FormControl>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="Tipolabel">Tipo de movimiento</InputLabel>
                      <Select
                          labelId="Tipolabel"
                          //fullWidth={true}
                          value={this.state.TipoMovimientoCaja}
                          onChange={this.handleChange.bind(this)}
                          name="TipoMovimientoCaja"
                          //autoWidth={true}
                          label="Tipo de movimiento"
                          style={{ width: "28.6em", marginTop: "10px" }}
                          //error={this.state.errorEmpresa}
                          //success={!this.state.errorEmpresa}
                      >
                        <MenuItem disabled>Seleccione una opción</MenuItem>
                          <MenuItem value={"Depósito"}>Depósito</MenuItem>
                          <MenuItem value={"Retiro"}>Retiro</MenuItem>
                      </Select>
                  </FormControl>
                  <TextField
                    id="Concepto"
                    style={{ width: "100%", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Concepto"
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.Concepto,
                        name: "Concepto",
                        id: "Concepto",
                        error: this.state.errorConcepto,
                        success: !this.state.errorConcepto
                    }}
                  />
                  <TextField
                    id="Monto"
                    style={{ width: "100%", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Monto"
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        value: this.state.Monto,
                        name: "Monto",
                        id: "Monto",
                        error: this.state.errorMonto,
                        success: !this.state.errorMonto
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card> 
          <GridItem xs={12} sm={12} md={12}>
              <Button color="info" tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.CrearobjMovimientoCaja()}><Save/>&nbsp;Guardar</Button>
              <Button tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cancelar</Button>
          </GridItem>    
        </Modal>
        <div id="">
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalMovimientoCaja);