import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
// import { View, Image, StyleSheet } from 'react-native'

import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, Add, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
// import PlaceHolder from '../../../../assets/img/placeholder.jpg'
import Persona from '../../../../assets/img/persona.png'
import Accordion from '../../../../components/Accordion/Accordion.jsx'


class ModalInfoVenta extends Component {

  constructor(props, context) {
    super(props, context);
    // this.escFunction = this.escFunction.bind(this);
    this.state = {
      Abonos: [],

    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    // console.log(this.props.abonos)
    // this.DarFormatoAbonos()
    if(this.props.Abonos.Abono.length > 1){
      this.setState({
        Abonos: this.props.Abonos.Abono,
      })
    }else{
      var Abono =[]
      Abono.push(this.props.Abonos.Abono)
      this.setState({
        Abonos: Abono,
      })
    }
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------
  DarFormatoAbonos() {
    // var Listabonos = this.props.abonos
  }

  MandarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.eventClick('esc');
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  /* SeleccionarAlumno(ListaAlumnos) {
    this.setState({AlumnoSeleciondado:ListaAlumnos, op: 1 });
    this.props.parentCallback(ListaAlumnos);
    this.props.eventClick('esc');
  } */

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  AgregarFolio() {
    let Aux = "";
    Aux = this.state.AgregarFolio;


    this.setState({ NewFoliosFactura: [...this.state.NewFoliosFactura, Aux], AgregarFolio: "", errorAgregarFolio: false });
  }
  openModalAbonos(){
    this.setState({showAbonos:false});
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes,Abonos } = this.props;
    // console.log(this.state.Abonos)
    

    return (
      <div>
        <Modal isOpen={this.props.showAbonos} className="static-modal" size="sm" toggle={this.cerrarModal} backdrop={true}>
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridContainer xs={12} sm={12} md={12} lg={12}>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <table>
                    <thead>
                      <th>Fecha</th>
                      <th>Cantidad</th>
                    </thead>
                    <tbody>
                      {
                        this.state.Abonos.map(abono => {
                          return (
                            <tr>
                              <td>
                          {new Date(abono.FechaAbono).toLocaleDateString('es-MX', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                              </td>
                              <td>
                                {abono.Cantidad}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridItem>
            <Button tabIndex="1" size='sm' color='danger' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CERRAR</Button>
          </GridItem>
        </Modal>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalInfoVenta);