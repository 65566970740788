import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Search, Close, Save } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

import RichTextEditor, { getTextAlignClassName, getTextAlignStyles,  getTextAlignBlockMetadata, } from 'react-rte';
// var RichTextEditor = require('react-rte');


const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_ALIGNMENT_BUTTONS",
    // "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Negritas", style: "BOLD" },
    { label: "Cursiva", style: "ITALIC" },
    { label: "Subrayar", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Titulo 1', style: 'header-one' },
    { label: 'Titulo 2', style: 'header-two' },
    { label: "Titulo 3", style: "header-three" },
    { label: "Titulo 4", style: "header-four" },
    { label: "Cuerpo", style: "unstyled" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "Lista ordenada", style: "unordered-list-item" },
    { label: "Lista Numerada", style: "ordered-list-item" },
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    { label: "Alinear a la izquierda", style: "ALIGN_LEFT" },
    { label: "Centrar", style: "ALIGN_CENTER" },
    { label: "Alinear a la derecha", style: "ALIGN_RIGHT" },
    { label: "Justificar", style: "ALIGN_JUSTIFY" },
  ],
};
class CatUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      // Plantilla: RichTextEditor.createValueFromString("<p><b>jutta</b> tesche</p>","html"),
      Plantilla: RichTextEditor.createEmptyValue(),
      PlantillaHTML: "",
      Nombre: "",
      ListaConstancias: [],
      ListaConstanciasTemp: [],
      ListaModulos: [],
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      showEditarUsuario: false,
      Total: 0,
      TotalSeleccionado: 0,
      Titulo: "Registrar un nuevo Usuario",
      IdNuevo: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    if (this.props.op == 1) {
      this.ObtenerContancia(this.props._id)
    }
  }

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, });
  };

  eventoModalPregunta = (valor) => {
    this.setState({ show: false });
    if(valor){
      this.props.eventClick("esc")
    }
  };

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  eventoModalEliminar(e) {
    this.setState({ show: true, UsuarioSeleccionado: e });
    return;
  }

  ObtenerContancia(id) {
    this.setState({ cargando: true});
    fetch(Globales.Url + "Catalogos/TipoReglamento/ObtenerTipoReglamento", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ _id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger",cargando: false});
          this.showNotification();
        } else {
          //Obtuvo los registros correctamente
          this.setState({
            _id: data.data._id,
            Nombre: data.data.Nombre,
            Plantilla: RichTextEditor.createValueFromString(data.data.Plantilla, "html", { customBlockFn: getTextAlignBlockMetadata, }),
            PlantillaHTML: data.data.Plantilla,
            // PlantillaHTML: "<body style='line-height 1.5;'>" + data.data.Plantilla + "</body>",
            cargando: false,
          });
        }
        this.setState({
          mensaje: data.mensaje,
          show: false,
          entroEliminar: true,
        });
      });
  }
  Validaciones(){
    var valido = true
    if(this.state.Nombre == ""){
      valido = false
    }
    if(this.state.PlantillaHTML == ""){
      valido = false
    }

    return valido
  }
  RegistrarContancia() {
    this.setState({ cargando: true});
    if(!this.Validaciones()){
      this.setState({ mensaje:"Complete todos los campos",colorNotificacion: "danger",cargando: false});
      this.showNotification();
    }else{
      fetch(Globales.Url + "Catalogos/TipoReglamento/RegistrarTipoReglamento", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({
          Nombre: this.state.Nombre,
          Plantilla: this.state.PlantillaHTML,
          _id: this.state._id,
          }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje,colorNotificacion: "danger",cargando: false});
            this.showNotification();
          } else {
            //Obtuvo los registros correctamente
            this.props.eventClick("Se guardo la constancia exitosamente")
          }
          this.setState({
            mensaje: data.mensaje,
            show: false,
            entroEliminar: true,
          });
        });
    }
    
  }


  RegresarMensaje = (mensaje) => {
    this.setState({ mensaje: mensaje, colorNotificacion: "success" });
    this.showNotification();
    this.ListaGeneralUsuarios();
  };

  eventoEditarUsuario = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showEditarUsuario: false, op: 0 });
    if (mensaje != "esc") {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" });
      this.showNotification();
    }
  };

  openModalUsuario = (Tp, Id) => {
    this.setState({
      showEditarUsuario: true,
      Titulo: Tp ? "Registrar Usuario" : "Editar Usuario",
      op: Tp ? 0 : 1,
      UsuarioSeleccionado: Tp ? this.state.IdNuevo : Id,
    });
  };

  onChangeEditorText = (value) => {
    let HTML = value.toString('html', { blockStyleFn: getTextAlignStyles })
    // console.log(HTML);

    this.setState({
      Plantilla: value,
      PlantillaHTML: HTML.replace(/<br>/g, '<br/>'),
    });
  };

  render() {
    const { classes } = this.props;
    //console.log(this.state.PlantillaHTML)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }

    const controlStyle={
      backgroundColor: "#fdfdfd",
      borderRadius: 2,
      border:"1px solid #20272F",
      marginRight: "3px",
    }
    const customControl = [
      () => <button style={controlStyle} onClick={() => {
        let valor = this.state.PlantillaHTML + "<p> [#Fecha]</p>"
        this.setState({
          PlantillaHTML: valor,
          Plantilla: RichTextEditor.createValueFromString(valor, "html", { customBlockFn: getTextAlignBlockMetadata, })
        })
      }}>Fecha</button>,
      () => <button style={controlStyle} onClick={() => {
        let valor = this.state.PlantillaHTML + "<p> [#Nombre]</p>"
        this.setState({
          PlantillaHTML: valor,
          Plantilla: RichTextEditor.createValueFromString(valor, "html", { customBlockFn: getTextAlignBlockMetadata, })
        })
      }}>Nombre</button>,
      () => <button style={controlStyle} onClick={() => {
        let valor = this.state.PlantillaHTML + "<p> [#Idioma]</p>"
        this.setState({
          PlantillaHTML: valor,
          Plantilla: RichTextEditor.createValueFromString(valor, "html", { customBlockFn: getTextAlignBlockMetadata, })
        })
      }}>Idioma</button>,
      () => <button style={controlStyle} onClick={() => {
        let valor = this.state.PlantillaHTML + "<p> [#FechaVencimientoDesc]</p>"
        this.setState({
          PlantillaHTML: valor,
          Plantilla: RichTextEditor.createValueFromString(valor, "html", { customBlockFn: getTextAlignBlockMetadata, })
        })
      }}>FechaVencimientoDesc</button>,
  ];
    // document.getElementsByName
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">DOCUMENTO</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="Nombre"
                      style={{ width: "25em", marginTop: "10px" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Nombre"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        disabled: this.props.op == 1,
                        value: this.state.Nombre,
                        name: "Nombre",
                        id: "Nombre",
                        error: this.state.errorNombre,
                        success: !this.state.errorNombre
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <RichTextEditor
                      // autoFocus={true}
                      customControls={customControl}
                      blockStyleFn={getTextAlignClassName}
                      toolbarConfig={toolbarConfig}
                      value={this.state.Plantilla}
                      onChange={this.onChangeEditorText}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="info"
                  round
                  size="sm"
                  className="pull-right mt-4 mb-3"
                  onClick={() =>
                    this.RegistrarContancia()
                  }
                >
                  <Save />
                  GUARDAR
                </Button>
                <Button
                  color="danger"
                  round
                  size="sm"
                  className="pull-right mr-2 mt-4 mb-3 "
                  onClick={() =>
                    this.setState({show: true})
                  }
                >
                  <Close />
                  CERRAR
                </Button>
              </GridItem>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Salir sin guardar"}
            Cuerpo={"¿Está seguro que desea salir sin guardar el documento?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />

          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatUsuarios);
