import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import ModalDatosFiscales from './ModalDatosFiscales';
import { Save, Clear, Edit, Check, ArrowForward, ArrowForwardIos, Add, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';

const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
const PublicoGeneral = {
  // ClaveRegimen:"621", // PERIOSIDAD - "Incorporación Fiscal" 
  ClaveRegimen: "616", // En general - "Sin obligaciones fiscales" 
  CodigoPostal: "80220",
  RFC: "XAXX010101000",
  RazonSocial: "PÚBLICO EN GENERAL",
  _id: idVacio,
}
const PublicoGeneralCFDI = "S01"

const ClassPeriosidad = {
  Periodicidad: null,
  Meses: null,
  Año: new Date().getFullYear(),
}
const AñoActual = new Date().getFullYear()

class ModalFactura extends Component {

  constructor(props, context) {
    super(props, context);
    // this.escFunction = this.escFunction.bind(this);
    this.state = {
      UsoCFDI: "",
      ListaCFDI: [],
      ListaClavesRegimen:[],
      ListaFormassPago:[],
      Factura:{
        idAlumno: null,
        FechaFacturacion: new Date().toLocaleString('sv', { year: 'numeric', month: '2-digit', day: '2-digit',hour: '2-digit',minute: '2-digit'}).replace(' ', 'T'),
        Empresa:{
          _id: null,
          Calle:"RIO ELOTA #100 PTE",
          CodigoPostal:"80220",
          Colonia:"GUADALUPE",
          Estado:"Activo",
          PaginaWeb:"www.juttatesche.com.mx",
          RFC:"EIJ980819DQ0",
          Telefono:"6677124135",
          RazonSocial:"ESCUELA DE IDIOMAS JUTTA TESCHE",
          RegimenFiscal:"REGIMEN DE INCORPORACION FISCAL",
          ClaveRegimen: "601",
        },
        ClienteFiscal: {
          _id:null,
          RFC: "",
          RazonSocial: "",
          CodigoPostal: "",
          ClaveRegimen: "",
        },
        Global:null,
        MetodoDePago:0,
        TipoComprobante:0,
        TipoMoneda:0,
        FormaDePago:9,
        UsoDeCFDI: "",
        ListaConceptos: [],
        ListaDetalle: [],
        Consecutivo: 0,
        Total: 0,
        IVA: 0,
        Observaciones:"",
      },
      ListaDetalle: [],
      ClientePublico:false,
      colorNotificacion: "success",
      mensaje: "",
      Error: {},
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      cargando: false,
      CuerpoCargando: "Cargando",
    }
    this.handleChange = this.handleChange.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      // this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    // console.log(this.props)
    }

  async componentWillMount() {
    this.setState({cargando: true});
    document.removeEventListener("keydown", this.escFunction, false);
    // console.log(this.props.Factura);
    this.setState({
      Factura:this.props.Factura
    })

    await this.props.Factura.ListaConceptos.map(async (Venta, index) => {
      await this.obtenerListaDetallePagos(Venta._id, index)
    })

    await this.ObtenerUsoCFDI()
    await this.ObtenerClaveRegimenFiscal()
    await this.ObtenerMetodosDePago()
    this.setState({ cargando: false });
  }

  //Obtener ventas totales por periodo---------------------------------------------------------------------------------------------------------------------------------------------------------
  async obtenerListaDetallePagos(Venta) {
    // console.log(Venta);
    this.setState({MostrarModalCargando: true})
    fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerDetallePagos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({_id: Venta})
    }
    ).then(res => res.json())
      .then(async data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger', ListaAlumnos: [], MostrarModalCargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          let ListaAux = [];
          if (data.PagoComprasAlumno.length > 1) {
            ListaAux = data.PagoComprasAlumno
          } else {
            ListaAux = [data.PagoComprasAlumno];
          }
          let ListaFiltro = await ListaAux.filter(pago => pago.Monto > 0)
          
          let detalle = this.state.ListaDetalle
          this.state.ListaDetalle = [...detalle, ...ListaFiltro]
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", MostrarModalCargando: true}));; 
  }

  async ObtenerMetodosDePago() {
    await fetch(Globales.Url + 'Facturacion/Facturas/ObtenerMetodosDePago', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ cargando: false });
                // this.showNotification();
            } else { //Filtrado exitoso
              this.setState({ ListaFormassPago: data.data });
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  async ObtenerUsoCFDI() {
    await fetch(Globales.Url + 'Facturacion/Facturas/ObtenerUsoCFDI', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ cargando: false });
                // this.showNotification();
            } else { //Filtrado exitoso
              this.setState({ ListaCFDI: data.data });
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  async ObtenerClaveRegimenFiscal() {
    await fetch(Globales.Url + 'Facturacion/Facturas/ObtenerClaveRegimenFiscal', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ cargando: false });
                // this.showNotification();
            } else { //Filtrado exitoso
              this.setState({ ListaClavesRegimen: data.data });
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  handleChange(e) {
    const { name, value} = e.target;
    
      this.setState({[name]: value});
  }
  handleFactura=(e)=> {
    const { name, value} = e.target;
    let {Factura}= this.state
    if(name == "MetodoDePago" && value == 1) {
      Factura.FormaDePago = 9
    }
    Factura[name] = value
    this.setState({ Factura });
  }
  handleGlobal = (e) => {
    const { name, value } = e.target;
    let { Factura } = this.state
    if(Factura.Global){
      Factura.Global[name] = value
      this.setState({ Factura });
    }
  }

  async ValidarDatosFiscales() {
    let valido = true
    let DatosFiscales = this.state.Factura.ClienteFiscal
    let Factura = this.state.Factura
    let Error = {}
    
    //! validar que todos los datos esten competos
    if (!DatosFiscales.ClaveRegimen || DatosFiscales.ClaveRegimen == "") {
      valido = false
    }
    if (!DatosFiscales.CodigoPostal || DatosFiscales.CodigoPostal == "") {
      valido = false
    }
    if (!DatosFiscales.RazonSocial || DatosFiscales.RazonSocial == "") {
      valido = false
    }
    if (!DatosFiscales.RFC || DatosFiscales.RFC == "") {
      valido = false
    }
    if (Factura.UsoDeCFDI == "") {
      valido = false
    }
    if (Factura.FormaDePago == 9) {
      valido = false
    }
                                    //! validar que no sea generico
    if (DatosFiscales.RFC == "XAXX010101000") {
      if (!Factura.Global){
        valido = false
      }else {
        if (Factura.Global.Periodicidad == null) {
          valido = false
        }
        if (Factura.Global.Meses == null) {
          valido = false
        }
      }
    }

    this.setState({
      Error: Error
    })
    return valido
  }


  async Guardar(){
    let passDatosFiscales = await this.ValidarDatosFiscales()
    if(passDatosFiscales){
      let factura = this.state.Factura
      factura.ListaDetalle = this.state.ListaDetalle
      this.setState({ cargando:true })
      await fetch(Globales.Url + 'Facturacion/Facturas/Facturar', {
        method: 'POST', headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }, body: JSON.stringify(this.state.Factura)
      }).then(res => res.json())
        .then(data => {
          // console.log(data);
          if (data.error) { //Error en la respuesta
            this.setState({ cargando: false, mensaje: data.mensaje, colorNotificacion: "danger" });
            this.showNotification();
          } else { //Filtrado exitoso
            var bytes = new TextEncoder().encode(data.data.xml);
            var base64String = btoa(String.fromCharCode.apply(null, bytes));
            this.GuardarXml(base64String, data.data.uuid, data.data.rfc, data.data.Observaciones)
          }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }else{
      this.setState({ mensaje: "Complete todos los campos", colorNotificacion: "danger" });
      this.showNotification();
    }

  }
  async GuardarXml(xml,UUID,RFC,Notas) {
    this.setState({ cargando: true })
    await fetch(Globales.Url + 'archivos/guardarXmlFactura', {
      method: 'POST', headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({
        xml, uuid: UUID
      })
    }).then(res => res.json())
      .then(data => {
        // console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ cargando: false, mensaje: data.mensaje, colorNotificacion: "danger" });
          this.showNotification();
        } else { //Filtrado exitoso
          this.props.eventClick({ UUID, RFC, Notas })
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }
  
  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }
  cerrarModal = () => {
    this.props.eventClick(false)
  }
  eventDatosFiscales = (DatosFiscales) => {
    this.setState({ ShowDatosFiscales: false })
    if (DatosFiscales) {
      let factura = this.state.Factura
      factura.ClienteFiscal = DatosFiscales
      this.setState({ Factura: factura })
    }
  }
  SeleccionarPublicoGeneral = (e) => {
    let { checked } = e.target

    let factura = this.state.Factura
    if(checked){
      let ClientePublico = JSON.parse(JSON.stringify(PublicoGeneral))
      let UsoCFDI = JSON.parse(JSON.stringify(PublicoGeneralCFDI))
      let Preiocidad = JSON.parse(JSON.stringify(ClassPeriosidad))
      factura.ClienteFiscal = ClientePublico
      factura.UsoDeCFDI = UsoCFDI
      factura.Global = Preiocidad

      this.setState({ ClientePublico: true, Factura: factura })
    } else {
      factura.Global = null
      this.setState({ ClientePublico: false, Factura: factura })
    }
  }

  render() {
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }
    // console.log(this.state.Factura.FechaFacturacion,);

    const { classes } = this.props;

    let agregar;
    if(this.state.ShowDatosFiscales){
      agregar = (
        <ModalDatosFiscales
          idFiscal={this.state.Factura.ClienteFiscal._id}
          eventClick={this.eventDatosFiscales}
          show={this.state.ShowDatosFiscales}
        />
      )
    }

    if(this.state.cargando){
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      )
    }
    
    const Separador = (titulo) => {
      return (
        <div>
          <h5 className="text-body" >{titulo}</h5>
          <div className="row" style={{ marginBottom: "15px" }}>
            <div className="col-12">
              <div className="bg-dark" style={{ height: "1px" }}></div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Modal
          isOpen={this.props.show}
          className="static-modal"
          size="xl"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">Facturar</CardHeader>
            <CardBody>
              {Separador("Receptor")}
              <GridContainer id="DATOS FISCALES">
                <GridItem style={{ marginBottom:"15px",marginRight: "20px"}} xs={12} sm={12} md={12}>
                  <div>
                    <Checkbox onChange={this.SeleccionarPublicoGeneral} name="checkedindiv" checked={this.state.ClientePublico} /> Público en general
                  </div>
                </GridItem>
                <GridItem style={{textAlign:'center',}} xs={12} sm={12} md={4}>
                  <b>Razón social</b>
                </GridItem>
                <GridItem style={{textAlign:'center',}} xs={12} sm={12} md={2}>
                  <b>RFC</b>
                </GridItem>
                <GridItem style={{textAlign:'center',}} xs={12} sm={12} md={2}>
                  <b>Código postal</b>
                </GridItem>
                <GridItem style={{textAlign:'center',}} xs={12} sm={12} md={3}>
                  <b>Régimen fiscal</b>
                </GridItem>
                <GridItem style={{textAlign:'center',}} xs={12} sm={12} md={4}>
                {this.state.Factura.ClienteFiscal.RazonSocial}
                </GridItem>
                <GridItem style={{textAlign:'center',}} xs={12} sm={12} md={2}>
                {this.state.Factura.ClienteFiscal.RFC}
                </GridItem>
                <GridItem style={{textAlign:'center',}} xs={12} sm={12} md={2}>
                {this.state.Factura.ClienteFiscal.CodigoPostal}
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl fullWidth size="small">
                    <Select
                      fullWidth={true}
                      value={this.state.Factura.ClienteFiscal.ClaveRegimen}
                      disabled
                    >
                      {
                        this.state.ListaClavesRegimen.map(item =>
                          <MenuItem value={item.ClaveRegimen.toString()}>{item.Descripcion}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem style={{ margin: "15px" }} xs={12} sm={12} md={12}>
                  <Button
                    tabIndex="0"
                    id="btnGuardar"
                    size="sm"
                    color="success"
                    hidden={this.state.ClientePublico}
                    className="pull-right  mb-3"
                    round
                    onClick={() => this.setState({ ShowDatosFiscales: true })}
                  >
                    <Edit />
                    &nbsp;Editar datos fiscales
                  </Button>
                </GridItem>
                <GridItem hidden xs={12} sm={12} md={12}>
                  <table>
                    <thead>
                        <th style={{ textAlign: 'center' }} scope="NombreComercial">Razon social</th>
                        <th style={{ textAlign: 'center' }} scope="RFC">RFC</th>
                        <th style={{ textAlign: 'center' }} scope="CodigoPostal">Codigo postal</th>
                    </thead>
                    <tbody>
                      <td style={{ textAlign: 'center', border: "0px" }}>{this.state.Factura.ClienteFiscal.RazonSocial}</td>
                      <td style={{ textAlign: 'center', bordercolor: "#FFFFFF" }}>{this.state.Factura.ClienteFiscal.RFC}</td>
                      <td style={{ textAlign: 'center', bordercolor: "#FFFFFF" }}>{this.state.Factura.ClienteFiscal.CodigoPostal}</td>
                    </tbody>
                    <br />
                    <thead>
                        <th style={{ textAlign: 'center' }} colSpan={2} scope="ClaveRgimen">Regimen fiscal</th>
                        <th style={{ textAlign: 'center' }} scope=""></th>
                    </thead>
                    <tbody>
                      <td style={{ textAlign: 'center', border: "0px" }} colSpan={2}>
                        <FormControl fullWidth size="small">
                          <Select
                            fullWidth={true}
                            value={this.state.Factura.ClienteFiscal.ClaveRegimen}
                            readOnly
                            // variant="outlined"
                          >
                            {
                              this.state.ListaClavesRegimen.map(item =>
                                <MenuItem value={item.ClaveRegimen.toString()}>{item.Descripcion}</MenuItem>
                              )
                            }
                          </Select>
                        </FormControl>
                      </td>
                      <td style={{ textAlign: 'center', border: "0px" }}>
                      </td>
                    </tbody>
                  </table>
                </GridItem>
              </GridContainer>

              {Separador("Detalle de la factura")}
              <GridContainer id="DATOS FACTURA">
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth size="small">
                    <div>Fecha</div>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="datetime-local"
                      InputProps={{
                        onChange: this.handleFactura.bind(this),
                        value: this.state.Factura.FechaFacturacion,
                        name: "FechaFacturacion",
                        //error: this.state.errorFechainicio,
                        //success: !this.state.errorFechainicio
                      }}
                    />
                  </FormControl>
                </GridItem>
                  <GridItem  xs={12} sm={12} md={6}>
                    <FormControl fullWidth size="small">
                      <div>Tipo de comprobante</div>
                      <Select
                        fullWidth={true}
                        value={this.state.Factura.TipoComprobante}
                        disabled
                      >
                        <MenuItem value={0}>Ingreso</MenuItem>
                      </Select>
                    </FormControl>
                </GridItem>
                  <GridItem  xs={12} sm={12} md={6}>
                    <FormControl fullWidth size="small">
                      <div>Tipo de moneda</div>
                      <Select
                        fullWidth={true}
                        value={this.state.Factura.TipoMoneda}
                        disabled
                      >
                        <MenuItem value={0}>MXN</MenuItem>
                      </Select>
                    </FormControl>
                </GridItem>
                  <GridItem  xs={12} sm={12} md={6}>
                    <FormControl fullWidth size="small">
                      <div>Método de pago</div>
                      <Select
                        fullWidth={true}
                        value={this.state.Factura.MetodoDePago}
                        disabled
                      >
                      <MenuItem value={0}>Pago en una sola exhibición</MenuItem>
                      </Select>
                    </FormControl>
                </GridItem>
                  <GridItem  xs={12} sm={12} md={6}>
                    <FormControl fullWidth size="small">
                      <div>Forma de pago</div>
                      <Select
                        fullWidth={true}
                        value={this.state.Factura.FormaDePago}
                        onChange={this.handleFactura.bind(this)}
                        name="FormaDePago"
                        variant="outlined"
                        error={this.state.Error.FormaDePago}
                      >
                        {
                          this.state.ListaFormassPago.map( item =>
                          <MenuItem value={item.FormaDePago}>{item.Descripcion}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                </GridItem>
                  <GridItem  xs={12} sm={12} md={6}>
                    <FormControl fullWidth size="small">
                      <div>Uso de CFDI:</div>
                      <Select
                        fullWidth={true}
                        value={this.state.Factura.UsoDeCFDI}
                        onChange={this.handleFactura.bind(this)}
                        disabled={this.state.ClientePublico}
                        name="UsoDeCFDI"
                        variant="outlined"
                      >
                        <MenuItem disabled>Seleccione Uso de CFDI</MenuItem>
                        {
                          this.state.ListaCFDI.map( cfdi =>
                            <MenuItem value={cfdi.Clave}>{cfdi.Nombre}</MenuItem>
                            )
                        }
                      </Select>
                    </FormControl>
                </GridItem>
                
              </GridContainer>
              
              {
                this.state.ClientePublico ?
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth size="small">
                        <div>Periodicidad</div>
                        <Select
                          fullWidth={true}
                          value={this.state.Factura.Global.Periodicidad}
                          onChange={this.handleGlobal.bind(this)}
                          name="Periodicidad"
                          variant="outlined"
                        >
                          <MenuItem value={"01"}>Diario</MenuItem>
                          <MenuItem value={"02"}>Semanal</MenuItem>
                          <MenuItem value={"03"}>Quincenal</MenuItem>
                          <MenuItem value={"04"}>Mensual</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth size="small">
                        <div>Mes</div>
                        <Select
                          fullWidth={true}
                          value={this.state.Factura.Global.Meses}
                          onChange={this.handleGlobal.bind(this)}
                          name="Meses"
                          variant="outlined"
                        >
                          <MenuItem value={"01"}>Enero</MenuItem>
                          <MenuItem value={"02"}>Febrero</MenuItem>
                          <MenuItem value={"03"}>Marzo</MenuItem>
                          <MenuItem value={"04"}>Abril</MenuItem>
                          <MenuItem value={"05"}>Mayo</MenuItem>
                          <MenuItem value={"06"}>Junio</MenuItem>
                          <MenuItem value={"07"}>Julio</MenuItem>
                          <MenuItem value={"08"}>Agosto</MenuItem>
                          <MenuItem value={"09"}>Septiembre</MenuItem>
                          <MenuItem value={"10"}>Octubre</MenuItem>
                          <MenuItem value={"11"}>Noviembre</MenuItem>
                          <MenuItem value={"12"}>Diciembre</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth size="small">
                        <div>Año</div>
                        <Select
                          fullWidth={true}
                          value={this.state.Factura.Global.Año}
                          onChange={this.handleGlobal.bind(this)}
                          name="Año"
                          variant="outlined"
                        >
                          <MenuItem value={AñoActual}>{AñoActual}</MenuItem>
                          <MenuItem value={AñoActual-1}>{AñoActual-1}</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer> : null
              }
              <GridContainer>
                
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <div>Notas</div>
                    <TextField
                      // style="field-sizing: content "
                      variant="outlined"
                      size="small"
                      type="textarea"
                      InputProps={{
                        onChange: this.handleFactura.bind(this),
                        value: this.state.Factura.Observaciones,
                        name: "Observaciones",
                        //error: this.state.errorFechainicio,
                        //success: !this.state.errorFechainicio
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridItem>
            <Button
              tabIndex="0"
              id="btnGuardar"
              size="sm"
              color="success"
              className="pull-right  mb-3"
              round
              onClick={() => this.Guardar(false)}
            >
              <Save />
              &nbsp;Facturar
            </Button>
            <Button
              tabIndex="1"
              size="sm"
              color="danger"
              className="pull-right mr-2 mb-3"
              round
              onClick={() => this.props.eventClick(false)}
            >
              <Clear />
              &nbsp;CERRAR
            </Button>
          </GridItem>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalFactura);