import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import ModalBuscarMaestros from "../../../components/Generales/ModalBuscarMaestros";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, GetApp, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
import SeleccionarMaestro from "../../Generales/ComponentesEmpresas/SeleccionarMaestro.jsx"
import ModalSeleccionarNIvelGrupo from "./ModalSeleccionarNIvelGrupo";


class Facturacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaEmpresas: [],
      Folios: [],
      FoliosCancelados: [],
      MaestroSeleccionado: { NombreCompleto: "" },
      GrupoSeleccionado: [],
      NombreAlumno: [],
      SumaTotalFacturados: {},
      opcionBuscar: "FolioVenta",
      NombreGrupo: "",
      IDEmpresa: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      NombreEmpresa: "",
      FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaFin: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      idUsuario: null,
      NumEstado: 0,
      NumTipoFormato: 0,
      ArrIdGrupo: [],

      Disabled: false,
      cargando: false,
      showGrupo: false,
      errorFechainicio: false,
      errorFechaFin: false,

      mensaje: "",
      mensajePagos: "",
      br: false,
      ColorNotificacion: 'success',
      colorTableRow: "white",

      ListaIdiomas:[],
      ListaAulas:[],
      ListaNiveles:[],
      GruposFiltrados: [],
      Clase:"Todos",
      Idioma:"null",
      Aula:"null",
      Nivel: "null",
      HoraInicio:0,
      HoraInicioPM:false,
      HoraFin:12,
      HoraFinPM:true,

      //Mensaje del cargando default
      CuerpoCargando: "Cargando"
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
    this.EventoSeleccionarAula = this.EventoSeleccionarAula.bind(this);
    this.EventoSeleccionarIdioma = this.EventoSeleccionarIdioma.bind(this);
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    
  }

  componentDidMount() {
    let IDcookie = JSON.parse(cookie.get('id'));
    this.setState({ idUsuario: IDcookie })
    this.ObtenerGrupos(0)
    this.ListarAulas()
    this.ListarIdiomas()

  }

  handleCallback = (MaestroSeleccionado) => {
    this.setState({ MaestroSeleccionado: MaestroSeleccionado },()=>{
      this.filtrarGrupos()
    })
  }

  //Función para pasar datos de los alumnos desde el modal hacia la vista
  RegresarMensaje = (mensaje) => {
    this.setState({ mensaje: mensaje, ColorNotificacion: "success" });
    this.showNotification();
    this.filtrarGrupos()
  }

  ListarAulas() {
    fetch(Globales.Url + 'Alumnos/Alumnos/ListaGeneralAulas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ ColorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Filtrado exitoso
          // console.log('Filtrado exitoso', data.Aulas);
          var ListaAulas = []

          if(data.Aulas.length > 1){
            ListaAulas = data.Aulas;
          }
          else{
            ListaAulas.push(data.Aulas);
          }

          this.setState({ ListaAulas: ListaAulas }, () => {
            if(this.props.op == 1){
              this.setState({
                Aula: JSON.stringify(this.props.cita._idAula),
              })
            }
          })
          //console.log('Filtrado ListaAulas',ListaAulas);
          // console.log("Aula", JSON.stringify(this.props.cita._idAula))

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
  }

  ListarIdiomas() {
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaGeneralIdiomas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ ColorNotificacion: 'danger', mensaje: data.mensaje, });
          this.showNotification();
        } else { //Filtrado exitoso
          var ListaIdiomas = []
          if(data.data.length > 1){
            ListaIdiomas = data.data;
          }
          else{
            ListaIdiomas.push(data.data);
          }
          
          this.setState({ ListaIdiomas: ListaIdiomas })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
  }

  ListarNiveles() {
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaNivelesIdioma', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ 
         idIdioma: JSON.parse(this.state.Idioma) 
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({  ListaNiveles:[], Nivel: "null" });
          // this.showNotification();
        } else { //Filtrado exitoso
          // this.setState({  ListaNiveles:[], Nivel: "null" });
          var ListaNiveles = []

          if(data.data.length > 1){
            ListaNiveles = data.data;
          }
          else{
            ListaNiveles.push(data.data);
          }
          
          this.setState({ ListaNiveles: ListaNiveles })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
  }

  ObtenerGrupos(estado) {

    this.setState({cargando:true, Folios: []})
    fetch(Globales.Url + 'Calificaciones/Calificaciones/ListaNombresGruposPorEstado', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ estado: estado })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          //console.log(data.Grupos)

          if(estado !== 2){
            let ListaAux;
            if (data.Grupos.length > 1) {

              ListaAux = data.Grupos.sort(function(a,b){
                return new Date(b.FechaTerminoCurso) - new Date(a.FechaTerminoCurso)
              })

              this.setState({ Folios: data.Grupos, cargando: false, CheckAll: false });
            }
            else {
              let AuxVentaFolio = [];
              AuxVentaFolio.push(data.Grupos);
  
              this.setState({ Folios: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
            }
          }
          else{
            let ListaAux;
            if (data.Grupos.length > 1) {

              ListaAux = data.Grupos.sort(function(a,b){
                return new Date(b.FechaTerminoCurso) - new Date(a.FechaTerminoCurso)
              })
  
              this.setState({ Folios: ListaAux, cargando: false, CheckAll: false });
            }
            else {
              let AuxVentaFolio = [];
              AuxVentaFolio.push(data.Grupos); 
  
              this.setState({ Folios: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
            }
          }
          this.filtrarGrupos()
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
  }

  filtrarGrupos() {
    let grupos = this.state.Folios;

    var ListaGrupos = []

    //VALIDAR EL NOMBRE DEL GRUPO EN LA LISTA
    grupos.map(grupo => {
      if (this.state.NombreGrupo == "") {
        ListaGrupos.push(grupo)
      } else {
        var Nombre = (grupo.Nombre).toUpperCase()
        var NombreGrupo = (this.state.NombreGrupo).toUpperCase()
        if (Nombre.includes(NombreGrupo)) {
          ListaGrupos.push(grupo)
        }
      }
    })

    // VALIDAR QUE EL MAESTRO SELECCIONADO SEA EL MISMO
    var gruposAux = []
    ListaGrupos.map(grupo => {
      if (this.state.MaestroSeleccionado.NombreCompleto != '') {
        var GrupoMaestro = JSON.stringify(grupo._idMaestro)
        var MaestroSeleccionado = JSON.stringify(this.state.MaestroSeleccionado._id)
        if (GrupoMaestro == MaestroSeleccionado) {
          gruposAux.push(grupo)
        }
      } else {
        gruposAux.push(grupo)
      }
    })
    // ListaGrupos = gruposAux

    if(this.state.Idioma !== "null"){
      ListaGrupos = this.FiltraGruposIdioma(gruposAux)
      ListaGrupos = this.FiltraGruposAula(ListaGrupos)
      ListaGrupos = this.FiltraGruposNivel(ListaGrupos)
      // ListaGrupos = this.FiltraGruposHora(ListaGrupos)
    }
    else{
      ListaGrupos = this.FiltraGruposIdioma(gruposAux)
      ListaGrupos = this.FiltraGruposAula(ListaGrupos)
    }

    let aux = [];
    for(let i = 0; i < ListaGrupos.length; i++){
      aux.push(ListaGrupos[i]._id)
    }

    //console.log("ListaGrupos: ",ListaGrupos)
    //console.log("ArrIds: ",aux)
    

    this.setState({
      GruposFiltrados: ListaGrupos,
      ArrIdGrupo: aux
    })
  }
  FiltraGruposIdioma(ListaGrupos){
    var gruposAux = []
    if (this.state.Idioma != "null") {
      this.ListarNiveles()

      ListaGrupos.map(grupo => {
        var _idIdioma = JSON.stringify(grupo._idIdioma)
        if (_idIdioma == this.state.Idioma) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
    }else{
      this.setState({
        Nivel:"null",
        ListaNiveles:[],
      })

      return ListaGrupos
    }
  }

  FiltraGruposNivel(ListaGrupos){
    var gruposAux = []
    if (this.state.Nivel != "null") {
      //console.log(this.state.Nivel)
      ListaGrupos.map(grupo => {
        var _idNivel = JSON.stringify(grupo._idNivel)
        if (_idNivel == this.state.Nivel) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
    }else{
      return ListaGrupos
    }
  }
  FiltraGruposAula(ListaGrupos){
    var gruposAux = []
    if (this.state.Aula != "null") {
      ListaGrupos.map(grupo => {
        var _idAula = JSON.stringify(grupo._idAula)
        if (_idAula == this.state.Aula) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
    }else{
      return ListaGrupos
    }
  }

  FiltraGruposAula(ListaGrupos){
    var gruposAux = []
    if (this.state.Aula != "null") {
      ListaGrupos.map(grupo => {
        var _idAula = JSON.stringify(grupo._idAula)
        if (_idAula == this.state.Aula) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
    }else{
      return ListaGrupos
    }
  }

  /*----------------------------CONTROL DE PAGOS---------------------------------*/
  async ReportePorGrupo() {
    let valido = true;

    if(this.state.FechaInicio !== "" && this.state.FechaFin !== ""){
      if(this.state.FechaInicio > this.state.FechaFin){
        valido = false;
        this.setState({errorFechainicio: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", ColorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        this.setState({errorFechainicio: false, errorFechaFin: false})
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos requeridos", ColorNotificacion: "danger"});
      this.showNotification();
    }

    if(this.state.ArrIdGrupo.length == 0){
      valido = false;
      this.setState({mensaje: "No hay grupos seleccionados", ColorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Reportes/Reportes/FormatoControlPagosLote', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: this.state.ArrIdGrupo, FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.DescargarReporte(data.Reporte, 0);
          //this.openPdf(data.Reporte);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
    }
  }

  async ReporteGrupoIndividual(idGrupo) {
    let valido = true;

    if(this.state.FechaInicio !== "" && this.state.FechaFin !== ""){
      if(this.state.FechaInicio > this.state.FechaFin){
        valido = false;
        this.setState({errorFechainicio: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", ColorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        this.setState({errorFechainicio: false, errorFechaFin: false})
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos requeridos", ColorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Reportes/Reportes/ObtenerFormatoControlPagos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: idGrupo, FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.DescargarReporte(data.Reporte, 1);
          this.openPdf(data.Reporte);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
    }
  }

  /*----------------------------CALIFICACIONES POR GRUPO---------------------------------*/
  async BoletaCalificacionesLoteGrupos() {
    let valido = true;

    if(valido){
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Reportes/Reportes/BoletaCalificacionesLoteGrupos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: this.state.ArrIdGrupo })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.DescargarReporte(data.Reporte, 2);
          //this.openPdf(data.Reporte);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
    }
  }

  async ReporteListaAsistenciasLotesGrupos() {
    let valido = true;

    if(valido){
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Reportes/Reportes/ReporteListaAsistenciasLotesGrupos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: this.state.ArrIdGrupo })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.DescargarReporte(data.Reporte, 3);
          // this.openPdf(data.Reporte);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
    }
  }

  async BoletaCalificacionesGrupoIndv(idGrupo) {
    let valido = true;

    if(valido){
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Reportes/Reportes/BoletaCalificacionesGrupo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: idGrupo })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.DescargarReporte(data.Reporte, 4);
          this.openPdf(data.Reporte);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
    }
  }

  async ReporteCalidicacionesGrupoIndv(idGrupo,idNivel) {
    let valido = true;

    if(valido){
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Reportes/Reportes/ReporteCalidicacionesGrupo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: idGrupo, idNivel: idNivel })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.DescargarReporte(data.Reporte, 5);
          this.openPdf(data.Reporte);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
    }
  }

  /*----------------------------LISTA DE ASISTENCIA---------------------------------*/

  async ReporteListaAsistenciaLoteGrupo() {
    let valido = true;

    if(this.state.FechaInicio !== "" && this.state.FechaFin !== ""){
      if(this.state.FechaInicio > this.state.FechaFin){
        valido = false;
        this.setState({errorFechainicio: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", ColorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        this.setState({errorFechainicio: false, errorFechaFin: false})
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos requeridos", ColorNotificacion: "danger"});
      this.showNotification();
    }

    if(this.state.ArrIdGrupo.length == 0){
      valido = false;
      this.setState({mensaje: "No hay grupos seleccionados", ColorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Reportes/Reportes/ReporteListaAsistenciaLoteGrupo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: this.state.ArrIdGrupo, FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.DescargarReporte(data.Reporte, 6);
          // this.openPdf(data.Reporte);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
    }
  }

  async ReporteInasistenciasAlumnosLoteGrupoFechas() {
    let valido = true;

    if(this.state.FechaInicio !== "" && this.state.FechaFin !== ""){
      if(this.state.FechaInicio > this.state.FechaFin){
        valido = false;
        this.setState({errorFechainicio: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", ColorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        this.setState({errorFechainicio: false, errorFechaFin: false})
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos requeridos", ColorNotificacion: "danger"});
      this.showNotification();
    }

    if(this.state.ArrIdGrupo.length == 0){
      valido = false;
      this.setState({mensaje: "No hay grupos seleccionados", ColorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Reportes/Reportes/ReporteInasistenciasAlumnosLoteGrupoFechas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: this.state.ArrIdGrupo, FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.DescargarReporte(data.Reporte, 7);
          // this.openPdf(data.Reporte);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
    }
  }

  async ReporteListaAsistenciaBlancoIndv(idGrupo) {
    let valido = true;

    if(this.state.FechaInicio !== "" && this.state.FechaFin !== ""){
      if(this.state.FechaInicio > this.state.FechaFin){
        valido = false;
        this.setState({errorFechainicio: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", ColorNotificacion: "danger"});
        this.showNotification();
      }
      else if(this.state.FechaInicio){
        this.setState({errorFechainicio: false, errorFechaFin: false})
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos requeridos", ColorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Reportes/Reportes/ReporteListaAsistenciaBlanco', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: idGrupo, FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.DescargarReporte(data.Reporte, 8);
          this.openPdf(data.Reporte);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
    }
  }

  async ReporteInasistenciasAlumnosRangoFechasIndv(idGrupo,idNivel) {
    let valido = true;

    if(this.state.FechaInicio !== "" && this.state.FechaFin !== ""){
      if(this.state.FechaInicio > this.state.FechaFin){
        valido = false;
        this.setState({errorFechainicio: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", ColorNotificacion: "danger"});
        this.showNotification();
      }
      else if(this.state.FechaInicio){
        this.setState({errorFechainicio: false, errorFechaFin: false})
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos requeridos", ColorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Reportes/Reportes/ReporteInasistenciasAlumnosRangoFechas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: idGrupo,idNivel: idNivel, FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.DescargarReporte(data.Reporte, 9);
          this.openPdf(data.Reporte);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", ColorNotificacion: "danger", br: true, CuerpoCargando: "Error"}));
    }
  }

  openPdf(basePdf) {
    let byteCharacters = atob(basePdf);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: 'application/pdf;base64' });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }  

  DescargarReporte(archivoBase64, int) {
    const url = 'data:application/pdf;base64,' + archivoBase64;
    let nombre;

    switch(int){
      case 0:
        nombre = 'FormatoControlPagosLote'
        break;
      case 1:
        nombre = 'ObtenerFormatoControlPagos'
        break;
      case 2:
        nombre = 'BoletaCalificacionesLoteGrupos'
        break;
      case 3:
        nombre = 'ReporteListaCalificacionesLotesGrupos'
        break;
      case 4:
        nombre = 'BoletaCalificacionesGrupo'
        break;
      case 5:
        nombre = 'ReporteCalificacionesGrupo'
        break;
      case 6:
        nombre = 'ReporteListaAsistenciaLoteGrupo'
        break;
      case 7:
        nombre = 'ReporteInasistenciasAlumnosLoteGrupoFechas'
        break;
      case 8:
        nombre = 'ReporteListaAsistenciaBlanco'
        break;
      case 9:
        nombre = 'ReporteInasistenciasAlumnosRangoFechas'
        break;
    }

    const linkSource = `${url}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    this.setState({
      cargando: false,
      mensaje: "Reporte generado exitosamente",
      ColorNotificacion: "success",
    });
    this.showNotification();
  }
  //Función para abrir el modal de buscar alumnos

  openModalBuscarMaestro = () => {
    this.setState({ showAgregar: true, Titulo: "Buscar Alumnos", op: 1 });
  }

  //Función que recupera valores para el modal de agregar
  /*  eventoModalcobranza = (ListaAlumnos, ColorNotificacion) => {
     //Cambiar valores por default
     this.setState({ showAgregar: false, op: 0 })
   } */


  //Función que recupera valores para el modal
  eventoModalInfoVenta = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showInfoVenta: false, op: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, ColorNotificacion: "success" })
      this.showNotification();
    }
  }


  eventoModalcobranza = (ListaAlumnos, ColorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, op: 0 })
    /* if (ListaAlumnos !== 'esc') {
      this.setState({ ListaAlumnos: ListaAlumnos, ColorNotificacion: ColorNotificacion })
      this.showNotification(); } */
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, type } = e.target;
    //console.log(e.target);

    switch(name){
      case "Idioma":
        this.setState({ Idioma: value, Nivel: "null"}, () => { this.filtrarGrupos() });
        break;
      
      case "NumEstado":
        this.setState({ [name]: value });
        this.ObtenerGrupos(value);
        break;

      default:
        this.setState({ [name]: value }, () => { this.filtrarGrupos() });
        break;
    }

  }

  EventoSeleccionarIdioma(idIdioma) {
    this.setState({ Idioma: idIdioma });
  }
  EventoSeleccionarAula(Aula) {
    this.setState({ Aula: Aula });
  }
  
  CerrarModalAgregar = (mensaje) =>{
    this.setState({ 
      showGrupo:false,
      mensaje: mensaje,
      ColorNotificacion: "success",
    });
    if(mensaje != "esc" ){
      this.showNotification();
      this.ObtenerGrupos()
    }
  }
  
  eventoClickAgregar = (mensaje) => {
    // console.log("MENSAJE:",mensaje)
    this.setState({ 
      GrupoSeleccionado:[],
      showGrupo:false,
      mensaje: mensaje,
      ColorNotificacion: "success",
    });
    if(mensaje != "esc"){
      this.showNotification();
      this.ObtenerGrupos()
    }
  }

  eventoClickCerrar = (mensaje) =>{
    this.props.eventClick(mensaje)
  }
  
  eventoClickNiveles = (mensaje) =>{
    this.setState({
      ShowNiveles: false,
    });
    if (mensaje != "esc") {
      switch(this.state.OpcionNiveles){
        case 1:
          this.ReporteCalidicacionesGrupoIndv(mensaje._id, mensaje.idNivel)
          break;
        case 2:
          this.ReporteInasistenciasAlumnosRangoFechasIndv(mensaje._id, mensaje.idNivel)
          break;
      }
    }
  }

  eventoModalEliminar = (Exito) => {
    this.setState({
      show: false,
    });
    if (Exito) {
      this.EliminarGrupos(this.state.GrupoSeleccionado._id)
    }
  }

  render() {
    const { MaestroSeleccionado } = this.state;
    let DatosAlumno;
    if (MaestroSeleccionado.NombreCompleto != "") {

      DatosAlumno =
        <>
          <TextField
            style={{ width: "25em"}}
            variant="outlined"
            size="small"
            // label="Maestro"
            InputProps={{
              disabled: true,
              value: MaestroSeleccionado.NombreCompleto,
              name: "NombreGrupo",
              id: "NombreGrupo",
            }}
          />
        </>

    } else {
      DatosAlumno =
        <>
        <TextField
            style={{ width: "25em" }}
            variant="outlined"
            size="small"
            label="Maestro"
            InputProps={{
              disabled: true,
              value: MaestroSeleccionado.NombreCompleto,
              // value: this.state.NombreGrupo,
            }}
          />
        </>
    }

    let agregar;

    if (this.state.showAgregar == true) {
      agregar =
        <ModalBuscarMaestros
          Titulo={this.state.Titulo}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalcobranza}
          op={this.state.op}
          ocultar={true}
          parentCallback={this.handleCallback}
        />
    }


    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }
    if(this.state.ShowNiveles){
      agregar = (
        <ModalSeleccionarNIvelGrupo
          Grupo={this.state.GrupoSeleccionado}
          eventClick={this.eventoClickNiveles}
          show={this.state.ShowNiveles}
        />
      );
    }

    const { classes } = this.props;

    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    // console.log(this.state.GrupoSeleccionado)
    // console.log("IDs ", this.state.ArrIdGrupo)

    let Botones;
    let BotonesIndv;

    switch(this.state.NumTipoFormato){
      case 0:
        Botones = <Button size="sm" type="submit" color="info" style={{marginTop: "3em"}} onClick={() => this.ReportePorGrupo()} round><GetApp />{` Obtener formato por grupos`}</Button>;
        break;
      case 1:
        Botones = (
          <>
            <Button size="sm" type="submit" color="info" style={{marginTop: "3em", marginRight: "10px"}} onClick={() => this.BoletaCalificacionesLoteGrupos()} round><GetApp />{` Obtener lista de calificaciones`}</Button>
            <Button size="sm" type="submit" color="info" style={{marginTop: "3em"}} onClick={() => this.ReporteListaAsistenciasLotesGrupos()} round><GetApp />{` Obtener reporte`}</Button>
          </>
        );
        break;
      case 2:
        Botones = (
          <>
            <Button size="sm" type="submit" color="info" style={{marginTop: "3em", marginRight: "10px"}} onClick={() => this.ReporteListaAsistenciaLoteGrupo()} round><GetApp />{` Obtener lista de asistencia`}</Button>
            <Button size="sm" type="submit" color="info" style={{marginTop: "3em"}} onClick={() => this.ReporteInasistenciasAlumnosLoteGrupoFechas()} round><GetApp />{` Obtener reporte`}</Button>
          </>
        );
        break;
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Tipo de formato de control de pagos</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={5}>
                  <FormControl
                      fullWidth
                      size="small"
                      style={{ width: "25em" }}
                      >
                      <InputLabel style={{paddingLeft: "20px", top: "-10px"}} id="label1">Tipo de formato</InputLabel>
                      <Select
                          labelId="label1"
                          value={this.state.NumTipoFormato}
                          onChange={this.handleChange.bind(this)}  
                          size="small"  
                          variant="outlined"     
                          name="NumTipoFormato"  
                          label="Tipo de formato"
                      >
                        <MenuItem disabled>Seleccione un tipo</MenuItem>
                      <MenuItem value={0}>Control de pagos</MenuItem> 
                      <MenuItem value={1}>Calificaciones por grupo</MenuItem> 
                      <MenuItem value={2}>Lista de asistencia</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer hidden={this.state.NumTipoFormato !== 0 && this.state.NumTipoFormato !== 2} justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Período para el formato</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={5}>
                      <TextField
                        id="FechaInicio"
                        style={{ width: "15em", marginRight: "10px" }}
                        variant="outlined"
                        type="date"
                        format="DD-MM-YYYY"
                        size="small"
                        label="Fecha inicio"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaInicio,
                          name: "FechaInicio",
                          id: "FechaInicio",
                          error: this.state.errorFechainicio,
                          success: !this.state.errorFechainicio
                        }}
                      />
                      <TextField
                        id="FechaFin"
                        style={{ width: "15em" }}
                        variant="outlined"
                        type="date"
                        format="DD-MM-YYYY"
                        size="small"
                        label="Fecha fin"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaFin,
                          name: "FechaFin",
                          id: "FechaFin",
                          error: this.state.errorFechaFin,
                          success: !this.state.errorFechaFin
                        }}
                      />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Grupos</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={5}>
                  <FormControl
                      fullWidth
                      size="small"
                      style={{ width: "25em" }}
                      >
                      <InputLabel style={{paddingLeft: "20px", top: "-10px"}} id="label1">Estado</InputLabel>
                      <Select
                          labelId="label1"
                          value={this.state.NumEstado}
                          onChange={this.handleChange.bind(this)}  
                          size="small"  
                          variant="outlined"     
                          name="NumEstado"
                          label="Estado"
                      >
                        <MenuItem disabled>Seleccione un estado</MenuItem>
                      <MenuItem value={0}>En curso</MenuItem> 
                      <MenuItem value={2}>Finalizado</MenuItem> 
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem style={{ marginTop: "13px" }} id="Parametros de búsqueda" xs={12} sm={12} md={4}>
                  <SeleccionarMaestro
                      eventClick={this.handleCallback}
                    />
                  </GridItem>
                  <GridItem style={{ marginTop: "2em" }} id="tipo de búsqueda" xs={12} sm={12} md={5}>
                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: "25em" }}
                        >
                        <Select
                            value={this.state.Idioma}
                            onChange={this.handleChange.bind(this)}  
                            size="small"  
                            variant="outlined"     
                            name="Idioma"  
                        >
                        <MenuItem value={"null"}>Todos los idiomas</MenuItem> 
                        {this.state.ListaIdiomas.length == 0 ? null :
                            this.state.ListaIdiomas.map((idioma) => {
                                return (<MenuItem value={JSON.stringify(idioma._id)} >{idioma.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ marginTop: "2em" }} id="tipo de búsqueda" xs={12} sm={12} md={5}>
                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: "25em" }}
                        >
                        <Select
                            value={this.state.Nivel}
                            onChange={this.handleChange.bind(this)}   
                            variant="outlined"    
                            name="Nivel"  
                        >
                        <MenuItem value={"null"}>Todos los niveles</MenuItem>
                        {this.state.ListaNiveles.length == 0 ? null :
                            this.state.ListaNiveles.map((idioma) => {
                                return (<MenuItem value={JSON.stringify(idioma._id)} >{idioma.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ marginTop: "2em" }} id="tipo de búsqueda" xs={12} sm={12} md={5}>
                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: "25em" }}
                        >
                        <Select
                            value={this.state.Aula}
                            onChange={this.handleChange.bind(this)}   
                            variant="outlined"    
                            name="Aula"  
                        >
                          <MenuItem value={"null"}>Todas las aulas</MenuItem> 
                        {this.state.ListaAulas.length == 0 ? null :
                            this.state.ListaAulas.map((aula) => {
                                return (<MenuItem value={aula._id} >{aula.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem  hidden={(this.props.op == 2)} id="tipo de búsqueda" xs={12} sm={12} md={6}>
                    {Botones}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Lista de grupos</h4>
              </CardHeader>
              <CardBody xs={12} sm={12} md={12}>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="table table-wrapper-scroll-y">
                      <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                        <thead>
                          <th className="center">Clase</th>
                          <th className="center">Grupo</th>
                        </thead>
                        <tbody>
                       { this.state.GruposFiltrados.length > 0 ?  
                          this.state.GruposFiltrados.map((grupo, index) => {
                            switch(this.state.NumTipoFormato){
                              case 0:
                                BotonesIndv = <Button color="info" className="mr-2 pull-right" round size="sm" onClick={() => this.ReporteGrupoIndividual(grupo._id)}><GetApp />{" Obtener formato"}</Button>;
                                break;
                              case 1:
                                BotonesIndv = (
                                  <>
                                    <Button size="sm" type="submit" className="mr-2 pull-right" color="info" onClick={() => this.setState({ GrupoSeleccionado: grupo, ShowNiveles: true,OpcionNiveles:1 })} round><GetApp />{` Obtener reporte`}</Button>
                                    <Button size="sm" type="submit" className="mr-2 pull-right" color="info" onClick={() => this.BoletaCalificacionesGrupoIndv(grupo._id)} round><GetApp />{` Obtener lista de calificaciones`}</Button>
                                  </>
                                );
                                break;
                              case 2:
                                BotonesIndv = (
                                  <>
                                    <Button size="sm" type="submit" color="info" className="mr-2 pull-right" onClick={() => this.setState({ GrupoSeleccionado: grupo, ShowNiveles: true,OpcionNiveles:2 })} round><GetApp />{` Obtener reporte`}</Button>
                                    <Button size="sm" type="submit" color="info" className="mr-2 pull-right" onClick={() => this.ReporteListaAsistenciaBlancoIndv(grupo._id)} round><GetApp />{` Obtener lista de asistencia`}</Button>
                                  </>
                                );
                                break;
                            }
                            return (
                              <tr hover onClick={() => this.setState({ op:1,GrupoSeleccionado:grupo, showGrupo:true })}>
                                <td >{grupo.Clase}</td>
                                <td >{grupo.Nombre}</td>
                                <td>
                                  {BotonesIndv}
                                </td>
                              </tr>
                            )
                          })
                          : <tr><td colSpan="3"className="text-center"><h5>No hay grupos disponibles</h5></td></tr>}
                        </tbody>
                      </table>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            {/* <div>
              <Button size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.ExportarCorte()}>Imprimir corte de caja</Button>

            </div> */}

          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar grupo"}
            Cuerpo={"¿Está seguro que desea eliminar grupo?"}
            show={this.state.show}
          eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.ColorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Facturacion);