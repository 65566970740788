import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Comment, PostAdd, GetApp, PersonAddDisabled, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import Tooltip from '@material-ui/core/Tooltip';
import ModalComentarioAlumno from "./ModalComentarioAlumno"
import ModalPregunta from "components/Generales/ModalPregunta";

class ModalConsultaAlumnos extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      ListaAlumnos: [],
      AlumnoSeleccinado: {},
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: "",
      show: false,
      checked: false,
      indeterminate: false,
      CuerpoCargando: "Cargando",

      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    this.ListaCompletaAlumnosGrupo(this.props.idGrupo);
  }

//   async ListaCompletaAlumnosGrupo(idGrupo) {
//     this.setState({cargando: true});
//     await fetch(Globales.Url + 'Calificaciones/Calificaciones/ListaCompletaAlumnosGrupo', {
//         method: 'POST',
//         headers: {
//             Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
//         }
//         , body: JSON.stringify({idGrupo: idGrupo})
//     }
//     ).then(res => res.json())
//         .then(data => {
//             //console.log(data);
//             if (data.error) { //Error en la respuesta
//                 this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaAlumnos: [], Activo: 1, cargando: false });
//                 this.showNotification();
//             } else { //Filtrado exitoso
//               //console.log("API", data.ListaAlumnos)
//               if(data.ListaAlumnos.length > 1){
//                 data.ListaAlumnos.map(alumno => {
//                   if(alumno.ListaGrupos.GrupoAlumno.Estado !== "En_Curso"){
//                     alumno.ListaGrupos.GrupoAlumno.Show = false;
//                   }
//                 })

//                 this.setState({ListaAlumnos: data.ListaAlumnos, cargando: false})
//               }
//               else{
//                 let aux = [];
//                 if(data.ListaAlumnos.ListaGrupos.GrupoAlumno.Estado !== "En_Curso"){
//                   data.ListaAlumnos.ListaGrupos.GrupoAlumno.Show = false;
//                 }

//                 aux.push(data.ListaAlumnos);
//                 this.setState({ListaAlumnos: aux, cargando: false})
//               }
                
//             }
//           })
//     .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
// }

async ListaCompletaAlumnosGrupo(idGrupo) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Calificaciones/Calificaciones/ListaCompletaAlumnosGrupo', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idGrupo: idGrupo})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaAlumnos: [], Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
            let aux = [];
            if(data.ListaAlumnos.length > 1){
              data.ListaAlumnos.map(alumno => {
                if(alumno.ListaGrupos.GrupoAlumno.length > 1){
                  alumno.ListaGrupos.GrupoAlumno.map(grupo => {
                    if(grupo.Estado !== "En_Curso"){
                      grupo.Show = false;
                    }
                  })
                }
                else{
                  if(alumno.ListaGrupos.GrupoAlumno.Estado !== "En_Curso"){
                    alumno.ListaGrupos.GrupoAlumno.Show = false;
                  }
                }
              })

              this.setState({ListaAlumnos: data.ListaAlumnos, cargando: false})
            }
            else{
              if(data.ListaAlumnos.ListaGrupos.GrupoAlumno.length > 1){
                data.ListaAlumnos.ListaGrupos.GrupoAlumno.map(grupo => {
                  if(grupo.Estado !== "En_Curso"){
                    grupo.Show = false;
                  }
                })
              }
              else{
                if(data.ListaAlumnos.ListaGrupos.GrupoAlumno.Estado !== "En_Curso"){
                  data.ListaAlumnos.ListaGrupos.GrupoAlumno.Show = false;
                }
              }

              let aux = [];
              aux.push(data.ListaAlumnos);

              this.setState({ListaAlumnos: aux, cargando: false})
            }
          }
        })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    this.setState({ op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  async GenerarBoletaAlumno(idAlumno, Grupo) {
    if(Grupo.length > 1){
      Grupo=Grupo[0]._idGrupo
    }else{
      Grupo=Grupo._idGrupo
    }
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Calificaciones/Calificaciones/GenerarBoletaAlumno', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idAlumno: idAlumno, idGrupo: Grupo})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
  
                this.DescargarPDF(data.BoletaAlumno);
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  
  DescargarPDF(archivoBase64) {
    const url = 'data:application/pdf;base64,' + archivoBase64;
    let nombre = 'BoletaAlumno'
    
    const linkSource = `${url}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  
    this.setState({
      cargando: false,
      mensaje: "El archivo se generó exitosamente",
      colorNotificacion: "success",
    });
    this.showNotification();
  }

  async EliminarAsignacionGrupoAlumno(alumno) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Calificaciones/Calificaciones/EliminarAsignacionGrupoAlumno', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({grupo: alumno.ListaGrupos.GrupoAlumno, _idAlumno: alumno._id})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              let AlumnosIndex;
                this.setState({mensaje: data.mensaje, colorNotificacion: "success", cargando: false});
                this.showNotification();
                
                AlumnosIndex = this.state.ListaAlumnos.indexOf(alumno);
                this.state.ListaAlumnos[AlumnosIndex].ListaGrupos.GrupoAlumno.Estado = "Finalizado";
                this.state.ListaAlumnos[AlumnosIndex].ListaGrupos.GrupoAlumno.Show = true;

                this.setState({ListaAlumnos: this.state.ListaAlumnos, indeterminate: true})
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  ComentarioGuardado = (mensaje) => {
    this.setState({mensaje: mensaje, color: "success"});
    this.showNotification();
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value, checked } = e.target;
    let Alumnos = this.state.ListaAlumnos;
    if(checked){
      Alumnos.map(alumno => {
        if(alumno.ListaGrupos.GrupoAlumno.Estado != "En_Curso"){
          alumno.ListaGrupos.GrupoAlumno.Show = true;
        }
      })
    }
    else{
      Alumnos.map(alumno => {
        if(alumno.ListaGrupos.GrupoAlumno.Estado !== "En_Curso"){
          alumno.ListaGrupos.GrupoAlumno.Show = false;
        }
      })
    }
    this.setState({checked: checked, indeterminate: false, ListaAlumnos: Alumnos});
  }

  openModalComentario(Alumno){
    this.setState({AlumnoSeleccinado: Alumno, showModalComentario: true, Titulo: "Observaciones", op: 1});
  }

  openModalPregunta = (Alumno) => {
    this.setState({ AlumnoSeleccinado: Alumno, show: true });
}

eventoModalPregunta = (valor) => {
  this.setState({ show: false })
  if(valor){
      this.EliminarAsignacionGrupoAlumno(this.state.AlumnoSeleccinado);
  }
}

  eventoComentario = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showModalComentario: false, op: 0 })
    if (mensaje != 'esc') {
        this.setState({ mensaje: mensaje, colorNotificacion: "success" })
        this.showNotification();
    }
}

cerrarModal = () => {
  this.props.eventClick("esc")
}
 
  render() {
    const { classes } = this.props;
    const {ListaAlumnos, AlumnoSeleccinado} = this.state;
    //console.log(ListaAlumnos)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    if(this.state.showModalComentario == true){
      agregar = (
        <ModalComentarioAlumno
          Titulo={this.state.Titulo}
          showModalComentario={this.state.showModalComentario}
          eventClick={this.eventoComentario}
          op={this.state.op}
          parentCallback={this.ComentarioGuardado}
          AlumnoSeleccinado={AlumnoSeleccinado}
        />
      )
    }

    let alumnos;
    if(ListaAlumnos.length > 0){
      alumnos =
        ListaAlumnos.map(alumno => {
          let encurso = true;
          let calificacion;
          let Grupo;
          // console.log(alumno.ListaGrupos.GrupoAlumno);
          if(alumno.ListaGrupos.GrupoAlumno.length > 1){
            alumno.ListaGrupos.GrupoAlumno.map(grupo => {
              if(grupo.Estado == "En_Curso"){
                encurso = false;
              }
              calificacion = grupo.Calificacion
              Grupo = grupo
            })
          }
          else{
            if(alumno.ListaGrupos.GrupoAlumno.Estado == "En_Curso"){
              encurso = false;
            }
            calificacion = alumno.ListaGrupos.GrupoAlumno.Calificacion;
            Grupo = alumno.ListaGrupos.GrupoAlumno;
          }

          return(
            <tr hidden={encurso && this.state.checked == false} style={encurso == true ? {color: "red", cursor: "default"} : {cursor: "default"}} hover>
              <td style={{verticalAlign: "middle"}}>{alumno.NombreCompleto}</td>
              <td style={{verticalAlign: "middle"}}>{calificacion !== "-1" ? calificacion : "SC"}</td>
              <td>
                <Button hidden={encurso} data-toggle="tooltip" data-placement="bottom" title="Dar de baja del grupo" color="danger" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openModalPregunta(alumno)}><Clear /></Button>
                <Button data-toggle="tooltip" data-placement="bottom" title="Descargar boleta" color="info" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.GenerarBoletaAlumno(alumno._id, Grupo)}><GetApp /></Button>
                <Button data-toggle="tooltip" data-placement="bottom" title="Agregar comentario" color="info" round justIcon size="sm" className="pull-right mr-2" onClick={() => this.openModalComentario(alumno)}><Comment /></Button>
              </td>
            </tr>
          )
        })
    }

    //console.log("ListaAlumnos", ListaAlumnos)
    return (
      <div>
        <Modal
          isOpen={this.props.showConsultaAlumno}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.checked}
                        onChange={this.handleChange.bind(this)}
                        name="MostrarAlumnos"
                        color="primary"
                        indeterminate={this.state.indeterminate}
                      />
                    }
                    label="Mostrar todos los alumnos"
                  />
                  <div className="table-responsive-lg text-nowrap">
                    <table className="table table-hover">
                      <thead>
                        <th>Nombre completo</th>
                        <th>Promedio</th>
                      </thead>
                      <tbody>
                        {alumnos}
                      </tbody>
                    </table>
                  </div>
                  
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>   
          <GridItem xs={12} sm={12} md={12}>
              <Button tabIndex="1" size='sm' className='pull-right mb-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cerrar</Button>
          </GridItem>  
        </Modal>
        <div id="">
            <ModalPregunta
                Titulo={"Dar de baja del grupo"}
                Cuerpo={"Al eliminar la asignación del grupo se eliminará la colegiatura correspondiente, ¿Seguro que desea eliminar la asignación del grupo?"}
                show={this.state.show}
                eventClick={this.eventoModalPregunta}
            />
            {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalConsultaAlumnos);