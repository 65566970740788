import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import ModalIdioma from "./ModalIdiomas.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Search, Close } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

class CatIdiomas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      FiltrarNombres: "",
      ListaIdiomas: [],
      ListaIdiomasTemp: [],
      IdiomaSeleccionado: {},
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      Total: 0,
      Consecutivo: 0,
      TotalSeleccionado: 0,
      Titulo: "Registrar un nuevo idioma",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    //this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.ListaGeneralIdiomas();
  }

  async ListaGeneralIdiomas() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Idiomas/ListaGeneralIdiomas", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log("🚀 ~ file: Idiomas.jsx ~ line 66 ~ CatIdiomas ~ .then ~ data", data)
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          if (data.ListadeIdiomas.Idioma.length > 1) {
            this.setState({
              ListaIdiomas: data.ListadeIdiomas.Idioma,
              ListaIdiomasTemp: data.ListadeIdiomas.Idioma,
              Activo: 1,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          } else {
            this.setState({
              ListaIdiomas: data.ListadeIdiomas.Idioma,
              ListaIdiomasTemp: [...data.ListadeIdiomas.Idioma],
              Activo: data.ListadeIdiomas.Idioma.length,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: ""
            });
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    if (name === "FiltrarNombres") {
      const Dataorg = this.state.ListaIdiomasTemp;
      if (value) {
        const NuevaData = Dataorg.filter((Idioma) =>
          String(Idioma.Nombre)
            .toUpperCase()
            .includes(String(value).toUpperCase())
        );

        if(NuevaData.length === 0){
          this.setState({
            FiltrarNombres: value,
            ListaIdiomas: NuevaData,
            colorNotificacion: "danger",
            mensaje: "No se encontraron coincidencias",
          });
          this.showNotification();
        }
        else{
          this.setState({
            FiltrarNombres: value,
            ListaIdiomas: NuevaData,
          });
        }
      } else {
        this.setState({
          FiltrarNombres: "",
          ListaIdiomas: [...Dataorg],
        });
      }
    }
  }

  eventoModalEliminar(e) {
    this.setState({ show: true, IdiomaSeleccionado: e });
    return;
  }

  eventoModalPregunta = (valor) => {
    if (valor) {
      this.eliminarIdioma(this.state.IdiomaSeleccionado);
    } else {
      this.setState({ show: false });
    }
  };

  eliminarIdioma(id) {
    fetch(Globales.Url + "Catalogos/Idiomas/EliminarIdioma", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ _id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error === undefined) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger" });
        } else if (data.error) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger" });
        } else {
          //Obtuvo los registros correctamente
          this.setState({ colorNotificacion: "success" });
        }
        this.setState({
          mensaje: data.mensaje
            ? data.mensaje
            : "El idioma no puede ser eliminado, porque cuenta con niveles asignados.",
          show: false
        });
        this.showNotification();
        this.ListaGeneralIdiomas();
      });
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  eventoModalIdioma = (mensaje, Color) => {
    //Cambiar valores por default
    this.setState({showAgregar: false})
    if(mensaje !== "esc"){
      this.setState({
        Titulo: "Nuevo idioma",
        mensaje: mensaje,
        Nombre: "",
        colorNotificacion: Color,
        op: 0,
        entroEliminar: true,
      });
      this.showNotification();
      this.ListaGeneralIdiomas();
    }
    
  };

  openModalNuevoIdioma = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Nuevo idioma",
      Nombre: "",
      op: 0,
    });
  };

  openModalEditarIdioma = (e) => {
    //Cambiar valores por default
    this.setState({
      Nombre: e.Nombre,
      _id: e._id,
      Descripcion: e.Descripcion,
      Consecutivo: e.Consecutivo,
      Titulo: "Editar Idioma",
      op: 1,
      showAgregar: true,
    });
  };

  render() {
    const { classes } = this.props;
    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }
    if (this.state.showAgregar == true) {
      agregar = (
        <ModalIdioma
          xs={18}
          Titulo={this.state.Titulo}
          Nombre={this.state.Nombre}
          Descripcion={this.state.Descripcion}
          Consecutivo={this.state.Consecutivo}
          Idiomas={this.state.ListaIdiomasTemp}
          _id={this.state._id}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalIdioma}
          op={this.state.op}
        />
      );
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text={true}>
                <h4 className={classes.cardTitle}>Filtro</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      id="FiltrarNombres"
                      style={{ width: "23vw" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Nombre del idioma"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        onChange: this.handleChange.bind(this),
                        value: this.state.FiltrarNombres,
                        name: "FiltrarNombres",
                        id: "FiltrarNombres",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button
                      size="sm"
                      type="submit"
                      color="info"
                      round
                      className="pull-right"
                      onClick={() => this.openModalNuevoIdioma()}
                    >
                      Nuevo
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de idiomas</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4
                      className="pull-right"
                      style={{ marginRight: "15px", marginTop: 0 }}
                    >
                      {this.state.ListaIdiomas.length} Idioma(s)
                    </h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="center">Nombre</th>
                        <th className="center">Descripción</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ListaIdiomas.map((Idioma, index) => {
                        return (
                          <tr key={Idioma._id + "-" + index}>
                            <td onClick={() =>this.openModalEditarIdioma({ Nombre: Idioma.Nombre, Consecutivo: Idioma.Consecutivo, _id: Idioma._id, Descripcion: Idioma.Descripcion})} style={{verticalAlign: "middle", cursor: "default"}}>{Idioma.Nombre}</td>
                            <td onClick={() =>this.openModalEditarIdioma({ Nombre: Idioma.Nombre, Consecutivo: Idioma.Consecutivo, _id: Idioma._id, Descripcion: Idioma.Descripcion})} style={{verticalAlign: "middle", cursor: "default"}}>{Idioma.Descripcion}</td>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>
                            <Button
                                color="danger"
                                round
                                justIcon
                                className="pull-right"
                                size="sm"
                                onClick={() =>
                                  this.eventoModalEliminar(Idioma._id)
                                }
                              >
                                <Close />
                              </Button>
                              <Button
                                color="info"
                                round
                                justIcon
                                className="pull-right mr-2"
                                size="sm"
                                onClick={() =>
                                  this.openModalEditarIdioma({
                                    Nombre: Idioma.Nombre,
                                    Consecutivo: Idioma.Consecutivo,
                                    _id: Idioma._id,
                                    Descripcion: Idioma.Descripcion
                                  })
                                }
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar idioma"}
            Cuerpo={"¿Está seguro de dar de baja el idioma?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatIdiomas);
