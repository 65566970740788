import React, { Component } from "react";
import { Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from "../../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear } from "@material-ui/icons";
import { convertirPesos } from "../../../../components/Generales/Utileria";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { number } from "prop-types";

class ModalAgregarConcepto extends Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      Concepto: "Colegiatura",
      Tipo: "Adulto",
      _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      _idIdioma: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      ListaConceptos: [],
      
      //Costo default
      CostoNiño: 0,
      CostoAdulto: 0,
      CostoAdolescente: 0,
      CostoNiñoOrg: 0,
      CostoAdultoOrg: 0,
      CostoAdolescenteOrg: 0,

      //Colegiaturas
      CostoColegiaturaNiño: {Clase: "Niño", Costo3Hrs: 0, Costo4Hrs: 0, Costo5Hrs: 0, Costo6Hrs: 0},
      CostoColegiaturaAdolescente: {Clase: "Adolescente", Costo3Hrs: 0, Costo4Hrs: 0, Costo5Hrs: 0, Costo6Hrs: 0},
      CostoColegiaturaAdulto: {Clase: "Adulto", Costo3Hrs: 0, Costo4Hrs: 0, Costo5Hrs: 0, Costo6Hrs: 0},
      CostoColegiaturaCambridge: {Clase: "Cambridge", Costo3Hrs: 0, Costo4Hrs: 0, Costo5Hrs: 0, Costo6Hrs: 0},
      // Costo3hrs: 0,
      // Costo4hrs: 0,
      // Costo5hrs: 0,
      // Costo6hrs: 0,

      //Particular y CursoTOEFL
      CostoUnico:0,


      disabled: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton: false,
      ErrorAdolesente:false,
      ErrorNiños:false,
      ErrorAdulto:false,
      ErrorCosto3hrs: false,
      ErrorCosto4hrs: false,
      ErrorCosto5hrs: false,
      ErrorCosto6hrs: false,
      ErrorCostoPorHora: false,
      ErrorCostoUnico: false
    };
    this.RegistrarConceptoPago = this.RegistrarConceptoPago.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    this.setState({ ListaConceptos: this.props.Conceptos || [],  _idIdioma:this.props._idIdioma,})
    //Verificar si es editar
    if (this.props.op == 1) {
      //console.log(this.props.InfoConcepto)
        const { _id, Concepto, CobroClases, CobroColegiatura, CobroUnico} = this.props.InfoConcepto

      switch (Concepto) {
        case "Colegiatura":
          if(CobroColegiatura !== undefined && CobroColegiatura !== null){
            this.setState({
              Concepto: Concepto,
              _id: _id,
              CostoColegiaturaNiño: CobroColegiatura.CobroColegiatura[0],
              CostoColegiaturaAdolescente: CobroColegiatura.CobroColegiatura[1],
              CostoColegiaturaAdulto: CobroColegiatura.CobroColegiatura[2],
              CostoColegiaturaCambridge: CobroColegiatura.CobroColegiatura[3] !== undefined && CobroColegiatura.CobroColegiatura[3] !== null ? CobroColegiatura.CobroColegiatura[3] : {Clase: "Cambridge", Costo3Hrs: 0, Costo4Hrs: 0, Costo5Hrs: 0, Costo6Hrs: 0},
            });
          }
          else{
            this.setState({
              Concepto: Concepto,
              _id: _id,
              mensaje: "Este concepto no contiene el nuevo formato de pagos registrados",
              colorNotificacion: "warning",
            });
            this.showNotification();
          }
          break;
        case "Particular":
          this.setState({
            Concepto: Concepto,
            _id: _id,
            CostoUnico: CobroUnico
          });
          break;
        case "CursoTOEFL":
          this.setState({
            Concepto: Concepto,
            _id: _id,
            CostoUnico: CobroUnico
          });
          break;
        default:
          this.setState({
            CostoNiño:CobroClases? CobroClases.CobroClase[2].Costo : 0,
            CostoAdulto: CobroClases? CobroClases.CobroClase[0].Costo : 0,
            CostoAdolescente: CobroClases? CobroClases.CobroClase[1].Costo : 0,
            CostoNiñoOrg: CobroClases? CobroClases.CobroClase[2].Costo : 0,
            CostoAdultoOrg: CobroClases? CobroClases.CobroClase[0].Costo : 0,
            CostoAdolescenteOrg: CobroClases? CobroClases.CobroClase[1].Costo : 0,
            Concepto: Concepto,
            _id: _id,
          });
          break;
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  async ValidarData() {
    const idIngles = {
      "_increment": "5933375",
      "_machine": "595175",
      "_pid": "2400",
      "_timestamp": "1373551310"
    }

    if (this.props.op == 1) {
      const Existe = this.state.ListaConceptos.filter(sa => sa.Concepto === this.state.Concepto && sa._id !== this.state._id)
      if (Existe.length > 0) {
        this.setState({
          colorNotificacion: "danger",
          mensaje:
            "El concepto de pago ya fue agregado.",
        });
        this.showNotification()
        return;
      }
    } else {
      const Existe = this.state.ListaConceptos.filter(sa => sa.Concepto === this.state.Concepto)
      if (Existe.length > 0) {
        this.setState({
          colorNotificacion: "danger",
          mensaje:
            "El concepto de pago ya fue agregado.",
        });
        this.showNotification()
        return;
      }
    }

    let ObjConcepto = {};

    switch (this.state.Concepto) {
      case "Colegiatura":
        let aux = []
        //Costo Colegiatura Cambridge si el idioma es ingles
        if (JSON.stringify(this.state._idIdioma) === JSON.stringify(idIngles)) {
          if (this.state.CostoColegiaturaCambridge.Costo3Hrs == 0) {
            this.setState({
              colorNotificacion: "danger",
              ErrorCosto3hrs: true,
              mensaje:
                "Proporcione el costo de 3 horas para la clase niño.",
            });
            // this.showNotification()
            // return;
          }

          if (this.state.CostoColegiaturaCambridge.Costo4Hrs == 0) {
            this.setState({
              colorNotificacion: "danger",
              ErrorCosto3hrs: true,
              mensaje:
                "Proporcione el costo de 4 horas para la clase niño.",
            });
            // this.showNotification()
            // return;
          }

          if (this.state.CostoColegiaturaCambridge.Costo5Hrs == 0) {
            this.setState({
              colorNotificacion: "danger",
              ErrorCosto3hrs: true,
              mensaje:
                "Proporcione el costo de 5 horas para la clase niño.",
            });
            // this.showNotification()
            // return;
          }

          if (this.state.CostoColegiaturaCambridge.Costo6Hrs == 0) {
            this.setState({
              colorNotificacion: "danger",
              ErrorCosto3hrs: true,
              mensaje:
                "Proporcione el costo de 6 horas para la clase niño.",
            });
            // this.showNotification()
            // return;
          }
        }
        //Costo Colegiatura Niño
        if (this.state.CostoColegiaturaNiño.Costo3Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 3 horas para la clase niño.",
          });
          // this.showNotification()
          // return;
        }

        if (this.state.CostoColegiaturaNiño.Costo4Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 4 horas para la clase niño.",
          });
          // this.showNotification()
          // return;
        }

        if (this.state.CostoColegiaturaNiño.Costo5Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 5 horas para la clase niño.",
          });
          // this.showNotification()
          // return;
        }

        if (this.state.CostoColegiaturaNiño.Costo6Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 6 horas para la clase niño.",
          });
          // this.showNotification()
          // return;
        }

        //Costo Colegiatura Adolecente
        if (this.state.CostoColegiaturaAdolescente.Costo3Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 3 horas para la clase adolecente.",
          });
          // this.showNotification()
          // return;
        }

        if (this.state.CostoColegiaturaAdolescente.Costo4Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 4 horas para la clase adolecente.",
          });
          // this.showNotification()
          // return;
        }

        if (this.state.CostoColegiaturaAdolescente.Costo5Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 5 horas para la clase adolecente.",
          });
          // this.showNotification()
          // return;
        }

        if (this.state.CostoColegiaturaAdolescente.Costo6Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 6 horas para la clase adolecente.",
          });
          // this.showNotification()
          // return;
        }

        //Costo Colegiatura Adulto
        if (this.state.CostoColegiaturaAdulto.Costo3Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 3 horas para la clase adulto.",
          });
          // this.showNotification()
          // return;
        }

        if (this.state.CostoColegiaturaAdulto.Costo4Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 4 horas para la clase adulto.",
          });
          // this.showNotification()
          // return;
        }

        if (this.state.CostoColegiaturaAdulto.Costo5Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 5 horas para la clase adulto.",
          });
          // this.showNotification()
          // return;
        }

        if (this.state.CostoColegiaturaAdulto.Costo6Hrs == 0) {
          this.setState({
            colorNotificacion: "danger",
            // ErrorCosto3hrs: true,
            mensaje:
              "Proporcione el costo de 6 horas para la clase adulto.",
          });
          // this.showNotification()
          // return;
        }


        if (JSON.stringify(this.state._idIdioma) === JSON.stringify(idIngles)) {
          aux = [
            this.state.CostoColegiaturaNiño,
            this.state.CostoColegiaturaAdolescente,
            this.state.CostoColegiaturaAdulto,
            this.state.CostoColegiaturaCambridge
          ]
        }
        else{
          aux = [
            this.state.CostoColegiaturaNiño,
            this.state.CostoColegiaturaAdolescente,
            this.state.CostoColegiaturaAdulto,
          ]
        }

        ObjConcepto = {
          CobroColegiatura: {
            CobroColegiatura: aux
          },
          Concepto: this.state.Concepto,
          _id: this.state._id,
          _ididioma: this.state._idIdioma,
        }

        break;

      case "Particular":
        if (this.state.CostoUnico == 0) {
          this.setState({
            colorNotificacion: "danger",
            ErrorCostoUnico: true,
            mensaje:
              "Proporcione el costo para las clases particulares.",
          });
          // this.showNotification()
          // return;
        }


        ObjConcepto = {
          CobroUnico: this.state.CostoUnico,
          Concepto: this.state.Concepto,
          _id: this.state._id,
          _ididioma: this.state._idIdioma,
        }
        break;

      case "CursoTOEFL":
        if (this.state.CostoUnico == 0) {
          this.setState({
            colorNotificacion: "danger",
            ErrorCostoUnico: true,
            mensaje:
              "Proporcione el costo para el curso TOEFL.",
          });
          // this.showNotification()
          // return;
        }

        ObjConcepto = {
          CobroUnico: this.state.CostoUnico,
          Concepto: this.state.Concepto,
          _id: this.state._id,
          _ididioma: this.state._idIdioma,
        }

        break;

      default:
        if (Number(this.state.CostoAdultoOrg) <= 0) {
          this.setState({
            colorNotificacion: "danger",
            ErrorAdulto: true,
            mensaje:
              "Proporcione el costo, para el alumno adulto.",
          });
          // this.showNotification()
          // return;
        }

        if (Number(this.state.CostoAdolescenteOrg) <= 0) {
          this.setState({
            colorNotificacion: "danger",
            ErrorAdolesente: true,
            mensaje:
              "Proporcione el costo, para el alumno adolescente.",
          });
          // this.showNotification()
          // return;
        }

        if (Number(this.state.CostoNiñoOrg) <= 0) {
          this.setState({
            colorNotificacion: "danger",
            ErrorNiños: true,
            mensaje:
              "Proporcione el costo, para el alumno niño.",
          });
          // this.showNotification()
          // return;
        }

        ObjConcepto = {
          CobroClases: {
            CobroClase: [
              { Clase: 'Adulto', Costo: Number(this.state.CostoAdultoOrg) },
              { Clase: 'Adolescente', Costo: Number(this.state.CostoAdolescenteOrg) },
              { Clase: 'Niño', Costo: Number(this.state.CostoNiñoOrg) },
            ]
          },
          Concepto: this.state.Concepto,
          _id: this.state._id,
          _ididioma: this.state._idIdioma,
        }
        break;
    }

   this.RegistrarConceptoPago(ObjConcepto);
  }

  async RegistrarConceptoPago(concepto) {
    this.setState({
      disabled: true,
      MostrarModal: true,
      DesactivarBoton: true,
    });
    await fetch(Globales.Url + "Catalogos/ConceptoPago/RegistrarConceptoPago", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify(concepto),
    })
      .then((res) => res.json())
      .then((data) => {
      // console.log(data)
        if (data.error) {
          this.setState({
            colorNotificacion: "danger",
            mensaje: data.mensaje,
            DesactivarBoton: false,
            MostrarModal: false,
            disabled: false,
          });
          this.showNotification();
        } else {
          this.setState({
            colorNotificacion: "success",
            DesactivarBoton: false,
            Concepto: "",
            disabled: false,
            MostrarModal: true,
          });
          this.closeModal(data.mensaje, this.state.colorNotificacion);
        }
      })
      .catch((err) => console.error(err));
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  NotshowNotification() {
    var x = [];
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { id, name, value } = e.target;
    let Cantidad = 0;
    // this.NotshowNotification()
    if (name == "Concepto") {
      this.setState({ [name]: value });
    } else {
      Cantidad = value.replace(/[^0-9\.]/g, '');
      // Cantidad = Cantidad.indexOf("$") === -1 ? Cantidad : Cantidad.slice(1);

      switch (this.state.Concepto) {
        case "Colegiatura":
          switch (id) {
            case "Adulto":
              if (Cantidad < 0) {
                this.state.CostoColegiaturaAdulto[name] = 0
              } else {
                this.state.CostoColegiaturaAdulto[name] = Cantidad
              }
              this.setState({ CostoColegiaturaAdulto: this.state.CostoColegiaturaAdulto });
              break;
            case "Adolecente":
              if (Cantidad < 0) {
                this.state.CostoColegiaturaAdolescente[name] = 0
              } else {
                this.state.CostoColegiaturaAdolescente[name] = Cantidad
              }
              this.setState({ CostoColegiaturaAdolescente: this.state.CostoColegiaturaAdolescente });
              break;
            case "Niño":
              if (Cantidad < 0) {
                this.state.CostoColegiaturaNiño[name] = 0
              } else {
                this.state.CostoColegiaturaNiño[name] = Cantidad
              }
              this.setState({ CostoColegiaturaNiño: this.state.CostoColegiaturaNiño });
              break;
            case "Cambridge":
              if (Cantidad < 0) {
                this.state.CostoColegiaturaCambridge[name] = 0
              } else {
                this.state.CostoColegiaturaCambridge[name] = Cantidad
              }
              this.setState({ CostoColegiaturaCambridge: this.state.CostoColegiaturaCambridge });
              break;
          }
          break;
        case "Particular":
          if (Cantidad < 0) {
            this.setState({ [name]: 0});
          } else {
            this.setState({ [name]: Cantidad, ErrorCostoUnico: false});
          }
          break;
        case "CursoTOEFL":
          if (Cantidad < 0) {
            this.setState({ [name]: 0});
          } else {
            this.setState({ [name]: Cantidad, ErrorCostoUnico: false});
          }
          break;
        default:
          if (Cantidad < 0) {
            this.setState({ [name]: 0, [name + 'Org']: 0 });
          } else {
            this.setState({ [name]: Cantidad, [name + 'Org']: Cantidad, ErrorAdulto: false, ErrorAdolesente: false, ErrorNiños: false });
          }
          break;
      }
    }
  }

  handleFocus = (event) => event.target.select();

  handleBlur = (event) => {
    const { id, name, value } = event.target;
    // let Valor = value.indexOf("$") > -1?  value.slice(1): value.indexOf(",") > -1? value.replace(/(,)/gm,""):value;  

    switch (this.state.Concepto) {
      case "Colegiatura":
        switch (id) {
          case "Adulto":
            if (isNaN(value)) {
              this.state.CostoColegiaturaAdulto[name] = 0
            } else if (value == "") {
              this.state.CostoColegiaturaAdulto[name] = 0
            }
            else {
              this.state.CostoColegiaturaAdulto[name] = value
            }

            this.setState({ CostoColegiaturaAdulto: this.state.CostoColegiaturaAdulto });
            break;
          case "Adolecente":
            if (isNaN(value)) {
              this.state.CostoColegiaturaAdolescente[name] = 0
            } else if (value == "") {
              this.state.CostoColegiaturaAdolescente[name] = 0
            }
            else {
              this.state.CostoColegiaturaAdolescente[name] = value
            }

            this.setState({ CostoColegiaturaAdolescente: this.state.CostoColegiaturaAdolescente });
            break;
          case "Niño":
            if (isNaN(value)) {
              this.state.CostoColegiaturaNiño[name] = 0
            } else if (value == "") {
              this.state.CostoColegiaturaNiño[name] = 0
            }
            else {
              this.state.CostoColegiaturaNiño[name] = value
            }

            this.setState({ CostoColegiaturaNiño: this.state.CostoColegiaturaNiño });
            break;
          case "Cambridge":
            if (isNaN(value)) {
              this.state.CostoColegiaturaCambridge[name] = 0
            } else if (value == "") {
              this.state.CostoColegiaturaCambridge[name] = 0
            }
            else {
              this.state.CostoColegiaturaCambridge[name] = value
            }

            this.setState({ CostoColegiaturaCambridge: this.state.CostoColegiaturaCambridge });
            break;
        }
        break;
      default:
        if (isNaN(value)) {
          this.setState({ [name]: 0 });
        } else if (value == 0) {
          this.setState({ [name]: 0 });
        }
        else {
          this.setState({ [name]: value });
        }
        break;
    }
  };

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;
    let agregar
    if (this.state.cargando == true) {
      agregar = (
        <Cargando show={this.state.MostrarModal} Cuerpo="Cargando" />
      );
    }

    let CantidadSwitch;
    const idIngles = {
      "_increment": "5933375",
      "_machine": "595175",
      "_pid": "2400",
      "_timestamp": "1373551310"
    }

    switch (this.state.Concepto) {
      case "Colegiatura":
        CantidadSwitch = (
          <>
            {/* Adulto */}
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer>
                <GridItem style={{ marginTop: "10px" }} xs={2} sm={2} md={2} lg={2}>
                  Adulto
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 3hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 3 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaAdulto.Costo3Hrs,
                      name: "Costo3Hrs",
                      id: "Adulto",
                      error: this.state.ErrorCosto3hrs,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 4hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 4 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaAdulto.Costo4Hrs,
                      name: "Costo4Hrs",
                      id: "Adulto",
                      error: this.state.ErrorCosto4hrs,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 5hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 5 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaAdulto.Costo5Hrs,
                      name: "Costo5Hrs",
                      id: "Adulto",
                      error: this.state.ErrorCosto5hrs,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 6hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 6 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaAdulto.Costo6Hrs,
                      name: "Costo6Hrs",
                      id: "Adulto",
                      error: this.state.ErrorCosto6hrs,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            {/* Adolecente */}
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer>
                <GridItem style={{ marginTop: "10px" }} xs={2} sm={2} md={2} lg={2}>
                  Adolecente
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 3hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 3 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaAdolescente.Costo3Hrs,
                      name: "Costo3Hrs",
                      id: "Adolecente",
                      error: this.state.ErrorCosto3hrs,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 4hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 4 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaAdolescente.Costo4Hrs,
                      name: "Costo4Hrs",
                      id: "Adolecente",
                      error: this.state.ErrorCosto4hrs,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 5hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 5 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaAdolescente.Costo5Hrs,
                      name: "Costo5Hrs",
                      id: "Adolecente",
                      error: this.state.ErrorCosto5hrs,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 6hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 6 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaAdolescente.Costo6Hrs,
                      name: "Costo6Hrs",
                      id: "Adolecente",
                      error: this.state.ErrorCosto6hrs,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            {/* Niño */}
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer>
                <GridItem style={{ marginTop: "10px" }} xs={2} sm={2} md={2} lg={2}>
                  Niño
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 3hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 3 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaNiño.Costo3Hrs,
                      name: "Costo3Hrs",
                      id: "Niño",
                      error: this.state.ErrorCosto3hrs,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 4hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 4 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaNiño.Costo4Hrs,
                      name: "Costo4Hrs",
                      id: "Niño",
                      error: this.state.ErrorCosto4hrs,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 5hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 5 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaNiño.Costo5Hrs,
                      name: "Costo5Hrs",
                      id: "Niño",
                      error: this.state.ErrorCosto5hrs,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <TextField
                    id="Costo 6hrs"
                    style={{ marginBottom: "10px", width: "100%" }}
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Costo 6 horas"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.CostoColegiaturaNiño.Costo6Hrs,
                      name: "Costo6Hrs",
                      id: "Niño",
                      error: this.state.ErrorCosto6hrs,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            {/* Cambridge (Solo idioma ingles) */}
            {JSON.stringify(this.state._idIdioma) === JSON.stringify(idIngles) ?
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <GridContainer>
                  <GridItem style={{ marginTop: "10px" }} xs={2} sm={2} md={2} lg={2}>
                    Cambridge
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} lg={2}>
                    <TextField
                      id="Costo 3hrs"
                      style={{ marginBottom: "10px", width: "100%" }}
                      fullWidth
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Costo 3 horas"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        value: this.state.CostoColegiaturaCambridge.Costo3Hrs,
                        name: "Costo3Hrs",
                        id: "Cambridge",
                        error: this.state.ErrorCosto3hrs,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} lg={2}>
                    <TextField
                      id="Costo 4hrs"
                      style={{ marginBottom: "10px", width: "100%" }}
                      fullWidth
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Costo 4 horas"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        value: this.state.CostoColegiaturaCambridge.Costo4Hrs,
                        name: "Costo4Hrs",
                        id: "Cambridge",
                        error: this.state.ErrorCosto4hrs,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} lg={2}>
                    <TextField
                      id="Costo 5hrs"
                      style={{ marginBottom: "10px", width: "100%" }}
                      fullWidth
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Costo 5 horas"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        value: this.state.CostoColegiaturaCambridge.Costo5Hrs,
                        name: "Costo5Hrs",
                        id: "Cambridge",
                        error: this.state.ErrorCosto5hrs,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} lg={2}>
                    <TextField
                      id="Costo 6hrs"
                      style={{ marginBottom: "10px", width: "100%" }}
                      fullWidth
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Costo 6 horas"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        value: this.state.CostoColegiaturaCambridge.Costo6Hrs,
                        name: "Costo6Hrs",
                        id: "Cambridge",
                        error: this.state.ErrorCosto6hrs,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              : ""}
          </>
        )
        break;

      case "Particular":
        CantidadSwitch = (
          <GridItem xs={3} sm={3} md={3} lg={3}>
            <TextField
              id="Costo por hora"
              style={{ width: "100%", }}
              variant="outlined"
              type="text"
              size="small"
              label="Costo por hora"
              // inputProps={{ maxLength: 3 }}
              InputProps={{
                onChange: this.handleChange.bind(this),
                onBlur: this.handleBlur.bind(this),
                onFocus: this.handleFocus.bind(this),
                value: this.state.CostoUnico,
                name: "CostoUnico",
                id: "CostoUnico",
                error: this.state.ErrorCostoUnico,
              }}
            />
          </GridItem>
        )
        break;

      case "CursoTOEFL":
        CantidadSwitch = (
          <GridItem xs={3} sm={3} md={3} lg={3}>
            <TextField
              id="CostoUnico"
              style={{ width: "100%", }}
              variant="outlined"
              type="text"
              size="small"
              label="Costo"
              // inputProps={{ maxLength: 3 }}
              InputProps={{
                onChange: this.handleChange.bind(this),
                onBlur: this.handleBlur.bind(this),
                onFocus: this.handleFocus.bind(this),
                value: this.state.CostoUnico,
                name: "CostoUnico",
                id: "CostoUnico",
                error: this.state.ErrorCostoUnico,
              }}
            />
          </GridItem>
        )
        break;

      default:
        CantidadSwitch = <>
          <GridItem xs={1} sm={1} md={10} lg={10}>
            <TextField
              id="Costo Adulto"
              style={{ width: "20em", marginBottom: "10px" }}
              variant="outlined"
              type="text"
              size="small"
              label="Costo Adulto"
              InputProps={{
                onChange: this.handleChange.bind(this),
                onBlur: this.handleBlur.bind(this),
                onFocus: this.handleFocus.bind(this),
                value: this.state.CostoAdulto,
                name: "CostoAdulto",
                id: "CostoAdulto",
                error: this.state.ErrorAdulto,
                success: !this.state.ErrorAdulto,
              }}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={10} lg={10}>
            <TextField
              id="Costo Adolescente"
              style={{ width: "20em", marginBottom: "10px" }}
              variant="outlined"
              type="text"
              size="small"
              label="Costo Adolescente"
              InputProps={{
                onChange: this.handleChange.bind(this),
                onBlur: this.handleBlur.bind(this),
                onFocus: this.handleFocus.bind(this),
                value: this.state.CostoAdolescente,
                name: "CostoAdolescente",
                id: "CostoAdolescente",
                error: this.state.ErrorAdolesente,
                success: !this.state.ErrorAdolesente,
              }}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={10} lg={10}>
            <TextField
              id="Costo Niño"
              style={{ width: "20em", marginBottom: "10px" }}
              variant="outlined"
              type="text"
              size="small"
              label="Costo Niño"
              InputProps={{
                onChange: this.handleChange.bind(this),
                onBlur: this.handleBlur.bind(this),
                onFocus: this.handleFocus.bind(this),
                value: this.state.CostoNiño,
                name: "CostoNiño",
                id: "CostoNiño",
                error: this.state.ErrorNiños,
                success: !this.state.ErrorNiños,
              }}
            />
          </GridItem>
        </>
        break;
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showAgregar}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <FormControl
                    // fullWidth
                    className={classes.formControl}
                    size="small"
                    style={{ width: "100%", marginBottom: "10px", maxWidth: "22em" }}
                    variant="outlined"
                  >
                    <InputLabel>Concepto</InputLabel>
                    <Select
                      value={this.state.Concepto}
                      onChange={this.handleChange}
                      size="small"
                      name="Concepto"
                      label="Concepto"
                      id="_id"
                      defaultValue={""}
                      disabled={this.props.op == 1 ? true : false}
                    >
                      <MenuItem disabled>Seleccione un concepto</MenuItem>
                      <MenuItem
                        key={1}
                        value={'Asesoría'}
                      >
                        {'Asesoría'}
                      </MenuItem>
                      <MenuItem
                        key={2}
                        value={'Colegiatura'}
                      >
                        {'Colegiatura'}
                      </MenuItem>
                      <MenuItem
                        key={3}
                        value={'Inscripción'}
                      >
                        {'Inscripción'}
                      </MenuItem>
                      <MenuItem
                        key={4}
                        value={'Particular'}
                      >
                        {'Clase particular'}
                      </MenuItem>
                      <MenuItem
                        key={5}
                        value={'CursoVerano'}
                      >
                        {'Curso de verano'}
                      </MenuItem>
                      {JSON.stringify(this.state._idIdioma) === JSON.stringify(idIngles) ? <MenuItem
                        key={6}
                        value={'CursoTOEFL'}
                      >
                        {'Curso TOEFL'}
                      </MenuItem> : ""}
                    </Select>
                  </FormControl>
                </GridItem>
                {CantidadSwitch}
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    disabled={this.state.disabled}
                    size="sm"
                    color="info"
                    className="pull-right"
                    round
                    onClick={this.ValidarData.bind(this)}
                  >
                    <Save />
                    &nbsp;GUARDAR
                  </Button>
                  <Button
                    size="sm"
                    className="pull-right"
                    round
                    onClick={() => this.props.eventClick("esc")}
                  >
                    <Clear />
                    &nbsp;CANCELAR
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {agregar}
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAgregarConcepto);
