import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'

class ModalComentarioAlumno extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      AlumnoSeleccionado: {},
      Observaciones: "",
      errorObservaciones: false,
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: "",
      cargando: false,
      CuerpoCargando: "Cargando",
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    if(this.props.AlumnoSeleccinado.ListaGrupos.GrupoAlumno.Observaciones !== undefined && this.props.AlumnoSeleccinado.ListaGrupos.GrupoAlumno.Observaciones !== null){
      this.setState({AlumnoSeleccionado: this.props.AlumnoSeleccinado, Observaciones: this.props.AlumnoSeleccinado.ListaGrupos.GrupoAlumno.Observaciones})
    }
    else{
      this.setState({AlumnoSeleccionado: this.props.AlumnoSeleccinado})
    }
  }

  async GuardarObservacionCalificacionGrupos(Grupo, idAlumno) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Calificaciones/Calificaciones/GuardarObservacionCalificacionGrupos', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({Grupo: Grupo, idAlumno: idAlumno})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                this.setState({cargando: false}) 
                this.RegresarMensaje(data.mensaje);
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    //this.setState({ op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  ValidarObservacion(){
    let valor = 0;
    let Observaciones = this.state.AlumnoSeleccionado;
    if(this.state.Observaciones == ""){
      valor = 1;
      this.setState({errorObservaciones: true, mensaje: "Proporcione", colorNotificacion: "danger"})
    }
    else{
      valor = 0;
      this.setState({errorObservaciones: false})
    }

    if(valor == 0){
      Observaciones.ListaGrupos.GrupoAlumno.Observaciones = this.state.Observaciones;

      this.GuardarObservacionCalificacionGrupos(Observaciones.ListaGrupos.GrupoAlumno, Observaciones._id);
    }
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      aviso: ""
    });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;

    let agregar;
    if(this.state.cargando){
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      )
    }
    //console.log(this.state.AlumnoSeleccionado)
    return (
      <div>
        <Modal
          isOpen={this.props.showModalComentario}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="Observaciones"
                    label="Observación"
                    fullWidth={true}
                    multiline
                    rows={4}
                    //defaultValue="Default Value"
                    variant="outlined"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Observaciones,
                      name: "Observaciones",
                      id: "Observaciones",
                      error: this.state.errorObservaciones,
                      success: !this.state.errorObservaciones
                  }}
                  />
                </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                  <Button color="info" tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.ValidarObservacion()}><Save/>&nbsp;Guardar</Button>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cancelar</Button>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>     
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalComentarioAlumno);