import React from "react";
// core components

import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { Modal } from 'reactstrap';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { Clear } from '@material-ui/icons';

// material-ui icons
import { CloudDownload } from "@material-ui/icons";
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;



class PresupuestoExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
            DatosReporte:[],
            IngresosInfo:null,
            VerReporte:false,
            Columnas:[],
            Categoria:[],
            SubCategoria:[],
        };
    }

    componentDidMount(){
        //console.log(this.props)
        this.AjustarDatos(this.props.DatosReporte)
    }
    AjustarDatos(datos){
        var columns=[]
        columns.push("NÚMERO FACTURA")
        columns.push("F. REGISTRO")
        if(this.props.Tipo == "egreso"){
            columns.push("F. EGRESO")
            columns.push("PROVEEDOR")
            columns.push("RFC PROVEEDOR")
            columns.push("MONTO")
            columns.push("ESTATUS FACTURA")
            columns.push("CUENTA ORIGEN")
        }else{
            columns.push("F. INGRESO")
            columns.push("SOCIO")
            columns.push("RFC SOCIO")
            columns.push("MONTO")
            columns.push("CUENTA DESTINO")
            columns.push("ESTATUS FACTURA")
            columns.push("FORMA DE PAGO")
        }
    columns.push("CATEGORÍA")
    columns.push("SUBCATEGORÍA")
    columns.push("COMENTARIOS")

        var ingresosColumnas=[];
        datos.map((e,i)=>{
            var CajaTemporal = [];
            CajaTemporal.push({
                value: e.NumeroFactura== null ||e.NumeroFactura == undefined ? '': e.NumeroFactura.toString(),
                style: {
                    alignment: {
                        horizontal: "left"
                    },                                           
                }
            }); 
            CajaTemporal.push({
                value: e.FechaRegistro == null ||e.FechaRegistro == undefined ? '': new Date(e.FechaRegistro).toLocaleDateString(),
                style: {
                    alignment: {
                        horizontal: "center"
                    },                                           
                }
            });  
            if(this.props.Tipo == "egreso"){
                CajaTemporal.push({
                    value: e.FechaEgreso == null ||e.FechaEgreso == undefined ? '': new Date(e.FechaEgreso).toLocaleDateString(),
                    style: {
                        alignment: {
                            horizontal: "center"
                        },                                           
                    }
                });  
                CajaTemporal.push({
                    value: e.NombreEmpresa== null ||e.NombreEmpresa == undefined ? '': e.NombreEmpresa.toString(),
                    style: {
                        alignment: {
                            horizontal: "left"
                        },                                           
                    }
                });  
                CajaTemporal.push({
                    value: e.Rfc== null ||e.Rfc == undefined ? '': e.Rfc.toString(),
                    style: {
                        alignment: {
                            horizontal: "center"
                        },                                           
                    }
                });  
                CajaTemporal.push({
                    value: e.MontoPagar== null ||e.MontoPagar == undefined ? '': "$"+e.MontoPagar.toFixed(2).toString(),
                    style: {
                        alignment: {
                            horizontal: "right"
                        },                                           
                    }
                });  
                CajaTemporal.push({
                    value: e.EstatusFactura== null ||e.EstatusFactura == undefined ? '': e.EstatusFactura.toString(),
                    style: {
                        alignment: {
                            horizontal: "center"
                        },                                           
                    }
                });  
                CajaTemporal.push({
                    value: e.CuentaBancoOrigen== null ||e.CuentaBancoOrigen == undefined ? '': e.CuentaBancoOrigen.toString(),
                    style: {
                        alignment: {
                            horizontal: "center"
                        },                                           
                    }
                });  
                CajaTemporal.push({
                    value: e.Cat == null || e.Cat.length==0 ? '': e.Cat[0].Nombre.toString(),
                    style: {
                        alignment: {
                            horizontal: "left"
                        },                                           
                    }
                });   
                CajaTemporal.push({
                    value: e.SubCat == null || e.SubCat.length==0  ? '': e.SubCat[0].Nombre.toString(),
                    style: {
                        alignment: {
                            horizontal: "left"
                        },                                           
                    }
                });
                CajaTemporal.push({
                    value: e.Comentarios== null ||e.Comentarios == undefined ? '': e.Comentarios.toString(),
                    style: {
                        alignment: {
                            horizontal: "left"
                        },                                           
                    }
                });   
            }else{
                CajaTemporal.push({
                    value: e.FechaIngreso == null ||e.FechaIngreso == undefined ? '': new Date(e.FechaIngreso).toLocaleDateString(),
                    style: {
                        alignment: {
                            horizontal: "center"
                        },                                           
                    }
                });  
                CajaTemporal.push({
                    value:e.NombreEmpresa== null ||e.NombreEmpresa == undefined ? '': e.NombreEmpresa.toString(),
                    style: {
                        alignment: {
                            horizontal: "left"
                        },                                           
                    }
                });  
                CajaTemporal.push({
                    value: e.Rfc== null ||e.Rfc == undefined ? '': e.Rfc.toString(),
                    style: {
                        alignment: {
                            horizontal: "center"
                        },                                           
                    }
                });  
                CajaTemporal.push({
                    value: e.MontoRecibir== null ||e.MontoRecibir == undefined ? '': "$"+e.MontoRecibir.toFixed(2).toString(),
                    style: {
                        alignment: {
                            horizontal: "right"
                        },                                           
                    }
                });  
                CajaTemporal.push({
                    value:e.CuentaBancoOrigen== null ||e.CuentaBancoOrigen == undefined ? '': e.CuentaBancoOrigen.toString(),
                    style: {
                        alignment: {
                            horizontal: "center"
                        },                                           
                    }
                }); 
                CajaTemporal.push({
                    value:e.EstatusFactura== null ||e.EstatusFactura == undefined ? '': e.EstatusFactura.toString(),
                    style: {
                        alignment: {
                            horizontal: "center"
                        },                                           
                    }
                });  
               
                CajaTemporal.push({
                    value:e.FormaPago== null ||e.FormaPago == undefined ? '': e.FormaPago.toString(),
                    style: {
                        alignment: {
                            horizontal: "center"
                        },                                           
                    }
                }); 
                CajaTemporal.push({
                    value: e.Cat == null || e.Cat.length==0 ? '': e.Cat[0].Nombre.toString(),
                    style: {
                        alignment: {
                            horizontal: "left"
                        },                                           
                    }
                });   
                CajaTemporal.push({
                    value: e.SubCat == null || e.SubCat.length==0  ? '': e.SubCat[0].Nombre.toString(),
                    style: {
                        alignment: {
                            horizontal: "left"
                        },                                           
                    }
                });
                CajaTemporal.push({
                    value: e.Comentarios== null ||e.Comentarios == undefined ? '': e.Comentarios.toString(),
                    style: {
                        alignment: {
                            horizontal: "left"
                        },                                           
                    }
                });   
            }
            ingresosColumnas.push(CajaTemporal)
        })
        this.setState({
            //PresupuestoInfo: datos.Presupuesto,
            Columnas:columns,
            IngresosInfo:ingresosColumnas
        },()=>{
            this.EstablecerDatos()
        })
    }

    EstablecerDatos(){
        this.state.DatosReporte.push(
              {
                xSteps: 4, // Will start putting cell with 1 empty cell on left most
                // ySteps: 5, //will put space of 5 rows,
                columns: [],
                data: [
                    [
                        { xSteps: 1 },
                        { xSteps: 1 },
                        {
                            value: this.props.Tipo == "egreso" ? "REPORTE DE EGRESOS" : "REPORTE DE INGRESOS",
                            style: {
                                alignment: {
                                    horizontal: "center"
                                },
                                font: {
                                    sz: "22", bold: true,
                                    color: {
                                        rgb: "0080D7"
                                    }
                                }
                                
                            }
                        },
                        { xSteps: 1 },
                        { xSteps: 1 },
                        { xSteps: 1 },  
                        { xSteps: 1 },  
                        { xSteps: 1 },  
                        { xSteps: 1 },  
                        { xSteps: 1 },    
                        { xSteps: 1 },    
                        { xSteps: 1 },    
                        { xSteps: 1 },    
                    ]                    
                ]                    
            })

            this.state.DatosReporte.push(
                {
                    // xSteps: 1,
                    ySteps:2,
                    columns: this.state.Columnas,
                    //data: this.state.Datos
                        data: []                            
                }
            )

              this.state.DatosReporte.push(
                {
                   //ySteps: 0, //will put space of 5 rows,
                  columns: [],
                  //data: this.state.ingresosColumnas
                  data:[]
                                  
              })


              this.state.DatosReporte.push(
                {
                   //ySteps: 0, //will put space of 5 rows,
                  columns: [],
                  data: this.state.IngresosInfo
                                  
              })
            this.setState({VerReporte:true})
    }



    render() {
        const { classes } = this.props;
        return (
            <div className={classes.sections}>
            <div className={classes.container}>
            <Modal isOpen={this.state.VerReporte} className='static-modal' >
                <Card >
                <CardHeader color='info'>
                    DESCARGAR REPORTE
                </CardHeader>
                <CardBody>                                    
                    <GridContainer >
                        <GridItem md={12} style={{ textAlign:"center" }}>
                            <ExcelFile  filename="REPORTE AMHPAC" element={ <Button color="success" round size="md">  <CloudDownload/>DESCARGAR</Button> }>
                                <ExcelSheet dataSet={this.state.DatosReporte} name="REPORTE" />
                            </ExcelFile>
                        </GridItem>
                    </GridContainer> 
                    {/* Botones */}
                    <div className='mt-4'>
                    <GridItem>
                        <Button tabIndex="1" size='sm' color='warning' className='pull-right' round onClick={() => this.props.eventClick('esc', 'warning', [])}><Clear />&nbsp;CERRAR</Button>
                    </GridItem>
                    </div>              
                </CardBody>
                </Card >
                
            </Modal >
            
            </div >
      </div>
        )
    }
}

export default withStyles(headerLinksStyle)(PresupuestoExcel);