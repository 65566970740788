import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import cookie from 'js-cookie'

class ModalFechaAcreditacion extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
        BuscarAlumno: "",
      MostrarModal:false,
      _id: null,
      PagoSelecionado: [],
      MotivoCancelacion: "",
      ListaUsuarios: [],
      Usuario: "usuarios",
      fecha: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      disabled:false,
      errorMotivoCancelacion: false,
      errorToken: false,
      errorUsuarios: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    //Verificar si es editar

    let IDcookie = JSON.parse(cookie.get('id'));
    this.setState({_id: IDcookie})
  }

  componentWillUnmount() {
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  RegresarMensaje() {
    this.setState({ op1: 1 });
    this.props.parentCallback(this.state.fecha);
    this.props.eventClick('Exito');
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;
    //console.log(this.state.PagoSelecionado);
    //console.log(this.state.ListaUsuarios)
    return (
      <div>
        <Modal
          isOpen={this.props.showFecha}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  Especifique fecha de acreditación para los pagos seleccionados.
                <CustomInput
                    labelText="Fecha de acreditacion"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      //required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.fecha,
                      name: "fecha",
                      id: "fecha",
                      type: "date",
                      //error: this.state.errorToken,
                      //success: !this.state.errorToken
                    }}
                  />
                </GridItem>
                
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Button tabIndex="0" id="btnGuardar" size='sm' color='info' className='pull-right' round onClick={() => this.RegresarMensaje("yei")}><Save/>&nbsp;Autorizar</Button>
                  <Button tabIndex="1" size='sm' className='pull-right mr-2' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cerrar</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
              {/* Botones */}

        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalFechaAcreditacion);