import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
// import { View, Image, StyleSheet } from 'react-native'
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, Block, ArrowForwardIos, Edit, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalPreguntaEditable from "../../Generales/ComponentesEmpresas/ModalPreguntaEditable";
import ModalMotivosCancelacion from "./ModalMotivosCancelacion";


// const ClassAsesoria = {
//   Estado: "Agendada",
//   Fecha: new Date(),
//   Modalidad: "",
//   MotivoCita: "",
//   MotivosCancelacion:"",
//   NombreAlumno:"",
//   NombreAula:"",
//   NombreIdioma:"",
//   NombreMaestro:"",
//   _id: null,
//   _idAlumno: null,
//   _idAula:null,
//   _idIdioma: null,
//   _idMaestro: null,
// }

class ModalInfoVenta extends Component {

  constructor(props, context) {
    super(props, context);
    //this.escFunction = this.escFunction.bind(this);
    this.state = {
      ClassAsesoria: {
        Estado: "Agendada",
        Fecha: new Date(),
        Modalidad: "",
        MotivoCita: "",
        MotivosCancelacion:"",
        NombreAlumno:"",
        NombreAula:"",
        NombreIdioma:"",
        NombreMaestro:"",
        _id: null,
        _idAlumno: null,
        _idAula:null,
        _idIdioma: null,
        _idMaestro: null,
      },

      colorNotificacion: "success",
      mensaje: "",
      ImagenAlumno: "",
      Deudas: [],
      MensajeEliminar: "",
      OpEliminar: 0,
      show:false,
      showMotivos:false,
      Motivos:"",
      CuerpoCargando: "Cargando",

    }

    this.eventoModalCorarInasistencia = this.eventoModalCorarInasistencia.bind(this);
    this.eventoModalInasistencia = this.eventoModalInasistencia.bind(this);
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.eventoModalmotivos = this.eventoModalmotivos.bind(this);
    this.CallbackMotivos = this.CallbackMotivos.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    
    
  }

  // escFunction(event) {
  //   if (event.keyCode === 27) {
  //     this.props.eventClick("esc", "esc");
  //   }
  // }

  componentDidMount() {
    //console.log(this.props.cita)
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------
  buscarCita() {
    fetch(Globales.Url + 'Citas/Citas/ObtenerInformacionAsesoria', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        _id: this.props.cita._id
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger' });
          this.showNotification();
        } else { //Filtrado exitoso
          //console.log("CITA:_",data);
          // this.props.eventClick('Se a marcado inasistencia exitosamente')
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }
  MarcarInasistencia() {
    fetch(Globales.Url + 'Citas/Citas/MarcarInasistenciaAsesoria', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idAlumno: this.props.cita._idAlumno,
        idCita: this.props.cita._id
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger' });
          this.showNotification();
        } else { //Filtrado exitoso
          this.props.eventClick('Se a marcado inasistencia exitosamente')
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }

  ActualizarEstado(Estado) {
    fetch(Globales.Url + 'Citas/Citas/ActualizarEstado', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        Estado: Estado,
        idCita: this.props.cita._id
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger' });
          this.showNotification();
        } else { //Filtrado exitoso
          this.props.eventClick('la cita se ha actualizado exitosamente')
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }

  CancelarAsesoria() {
    var cita = this.state.ClassAsesoria

    cita._id = this.props.cita._id
    cita.Estado= "Cancelada"
    cita.Fecha = new Date(this.props.cita.Fecha)
    cita._idAlumno = this.props.cita._idAlumno
    cita.NombreAlumno = this.props.cita.NombreAlumno
    cita._idMaestro = this.props.cita._idMaestro
    cita.NombreMaestro = this.props.cita.NombreMaestro
    cita._idIdioma = this.props.cita._idIdioma
    cita.NombreIdioma = this.props.cita.NombreIdioma
    cita._idAula = this.props.cita._idAula
    cita.NombreAula = this.props.cita.NombreAula
    cita.Modalidad = this.props.cita.Modalidad
    cita.MotivoCita = this.props.cita.MotivoCita
    cita.MotivosCancelacion = this.state.Motivos
    
    // fetch(Globales.Url + 'Citas/Citas/CancelarAseosirasFuturasSemanaAsesoriaInasistencia', {
    fetch(Globales.Url + 'Citas/Citas/RegistrarAsesoria', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idCita: this.props.cita._id,
        Cita: cita
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger' });
          this.showNotification();
        } else { //Filtrado exitoso
          this.props.eventClick('la cita se ha cancelado exitosamente')
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }
  MandarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.eventClick('esc');
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  OpenModalAbonos(Abonos) {
    this.setState({
      showAbonos: true,
      Abonos: Abonos
    })
  }

  EventoModalAbono() {
    this.setState({
      showAbonos: false,
      Abonos: []
    })
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  OpenModalEliminar(){
    this.setState({
      show: true,
      MotivosCancelacion: "¿Está seguro que desea cancelar la cita?",
      OpEliminar:0,
    });
  }
  eventoModalEliminar(Exito) {
    this.setState({
      show: false,
    });
    if (Exito) {
      if( this.state.OpEliminar == 0){
        this.setState({ showMotivos: true })
      }else{
        this.ActualizarEstado('Cancelada')
      }
    }
  }
  eventoModalInasistencia(Exito) {
    this.setState({
      showInasistencia: false,
    });
    if (Exito) {
      this.setState({ showCobrarInasistencia: true })
    }
  }
  eventoModalCorarInasistencia(Exito) {
    this.setState({
      showInasistencia: false,
    });
    if (Exito) {
      this.MarcarInasistencia()
    }else{
      this.ActualizarEstado('Inasistencia')
    }
  }
  eventoModalmotivos(mensaje) {
    this.setState({
      showMotivos: false,
    });
    if (mensaje != 'esc') {
      this.setState({ Motivos: mensaje }, () => {
        this.CancelarAsesoria();
      })
    }
  }
  CalcularHorasTranscurridas (FechaInicio,FechaFin){
    var diferencia = (new Date(FechaFin).getTime() - new Date(FechaInicio).getTime()) / 1000
    diferencia /= (60*60)
    diferencia = Math.abs(Math.round(diferencia))

    return Math.abs(Math.round(diferencia))
  }
  Editar(){
    if(false){
    // if(new Date(this.props.cita.Fecha) <= new Date()){
      this.setState({ mensaje: "No se puede reagendar una cita pasada", colorNotificacion: 'danger' });
      this.showNotification();
    }else{
      this.props.eventClick('Editar')
    }
  }

  CallbackMotivos = (Motivos) =>{
    this.setState({
      Motivos: Motivos,
    });

  }
  /* obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  } */

  cerrarModal = () => {
    this.props.eventClick('esc')
  }

  render() {
    const { classes } = this.props;
    let agregar
    
    if (this.state.show == true) {
      agregar = (
        <ModalPregunta
          Titulo={"Cancelar cita"}
          Cuerpo={this.state.MotivosCancelacion}
          show={this.state.show}
          eventClick={this.eventoModalEliminar}
        />
      );
    }
    if (this.state.showInasistencia == true) {
      agregar = (
        <ModalPregunta
          Titulo={"Marcar inasistencia"}
          Cuerpo={"¿Está seguro que desea marcar inasistencia la cita?"}
          show={this.state.showInasistencia}
          eventClick={this.eventoModalInasistencia}
        />
      );
    }
    if (this.state.showCobrarInasistencia == true) {
      agregar = (
        <ModalPreguntaEditable
          Titulo={"Cobro por inasistencia"}
          Cuerpo={"¿Desea generar cobro por inasistencia?"}
          btnCancelar={"NO"}
          btnAceptar={"SI"}
          show={this.state.showCobrarInasistencia}
          eventClick={this.eventoModalCorarInasistencia}
        />
      );
    }

    if (this.state.showMotivos == true) {
      agregar = (
        <ModalMotivosCancelacion
          show={this.state.showMotivos}
          eventClick={this.eventoModalmotivos}
          parentCallback={this.CallbackMotivos}
        />
      );
    }
    if (this.state.cargando == true) {
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    var TextoEstado = ""
    switch (this.props.cita.Estado) {
      case "Agendada":
        TextoEstado = "Cita agendada"
        break;
      case "Atendida":
        TextoEstado = "Cita atendida"
        break;
      case "Cancelada":
        TextoEstado = "Cita cancelada"
        break;
      case "Inasistencia":
        TextoEstado = "Cita con inasistencia"
        break;
    }
    var colorHead = ""
    if (this.props.cita.ColorEstado == "") {
      colorHead = "info"
    } else {
      colorHead = this.props.cita.ColorEstado
    }

    return (
      <div>
        <Modal isOpen={this.props.show} className="static-modal" size="md" toggle={this.cerrarModal} backdrop={true}>
          <Card >
            <CardHeader color={colorHead}>
              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} md={10}>
                  <h4>{TextoEstado}</h4>
                </GridItem>
              </GridContainer>

              {/* Detalle de cita */}
            </CardHeader>
            <CardBody>

              {/* <div className="text-center">
                <h4 style={{ color: this.props.cita.ColorEstado }}><b>
                  {TextoEstado}
                </b></h4>
              </div> */}
              <table>
                <tbody>
                  <tr>
                    <td><b>Fecha:</b></td>
                    <td>{new Date(this.props.cita.Fecha).toLocaleDateString('en-GB')}</td>
                    <td rowSpan={2}>
                      <Button hidden={!(this.props.cita.Estado == "Agendada")} style={{ width: "10em" }} tabIndex="2" size='sm' color='danger' className='pull-right '
                        // round onClick={() => this.MarcarInasistencia()}><Block />&nbsp;Inasistencia</Button>
                        round onClick={() => this.setState({ showInasistencia: true })}><Block />&nbsp;Inasistencia</Button>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Hora:</b></td>
                    <td>{new Date(this.props.cita.Fecha).toLocaleTimeString('en-US')}</td>
                  </tr>
                  <tr>
                    <td><b>Motivo de cita:</b></td>
                    <td>{(this.props.cita.MotivoCita)}</td>
                  </tr>
                  <tr>
                    <td><b>Modalidad:</b></td>
                    <td>{this.props.cita.Modalidad == "En_linea" ? "En linea":this.props.cita.Modalidad}</td>
                    <td rowSpan={2}>
                      <Button hidden={!(this.props.cita.Estado == "Agendada")} style={{ width: "10em" }} tabIndex="2" size='sm' color='danger' className='pull-right'
                        round onClick={() => this.OpenModalEliminar()}> <Clear />&nbsp; Cancelar</Button>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Aula:</b></td>
                    <td>{this.props.cita.Modalidad == "En_linea" ? "No Aplica" : this.props.cita.NombreAula}</td>
                  </tr>
                  <tr>
                    <td><b>Idioma:</b></td>
                    <td>{this.props.cita.NombreIdioma}</td>
                    <td rowSpan={2}>
                      <Button hidden={!(this.props.cita.Estado == "Agendada")} style={{ width: "10em" }} tabIndex="2" size='sm' color='success' className='pull-right '
                        round onClick={() => this.ActualizarEstado('Atendida')}><Check />&nbsp; Atendida</Button>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Maestro:</b></td>
                    <td>{this.props.cita.NombreMaestro}</td>
                  </tr>
                  <tr>
                    <td><b>Alumno:</b></td>
                    <td>{this.props.cita.NombreAlumno}</td>
                  </tr>
                </tbody>
              </table>

              {
                this.props.cita.MotivosCancelacion == null && this.props.cita.Estado == "Cancelada" ? 
               <div className="text-center">
                <h4 style={{ color: this.props.cita.ColorEstado }}><b>
                  Cancelación de emergencia
                </b></h4>
              </div> 
              : null
              }
              {
                this.props.cita.MotivosCancelacion == null ? null : 
               <div className="text-center">
                <h4 style={{ color: this.props.cita.ColorEstado }}><b>
                  {this.props.cita.MotivosCancelacion}
                </b></h4>
              </div> 
              }
              
              <br/>

          <table>
            <tbody >
              <tr>
                <td>
                  {/* marcar como atendida para pruebas */}
                  {/* <Button style={{ width: "10em" }} tabIndex="2" size='sm' color='success' className='pull-right '
                        round onClick={() => this.ActualizarEstado('Agendada')}><Check />&nbsp; Agendada</Button> */}
                        
                  <Button tabIndex="2" size='sm' color='' style={{ width: "10em" }} className='pull-right ' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;Cerrar</Button>
                </td>
                <td>
                  <Button style={{ width: "10em" }} tabIndex="2" size='sm' color='info' className='pull-right' 
                    hidden={!(this.props.cita.Estado == "Agendada")} 
                    round onClick={() => this.Editar()}><Edit />&nbsp;Editar</Button>
                </td>
              </tr>
            </tbody>
          </table>
            </CardBody>
          </Card>
        {agregar}
        <div hidden={!this.state.br} id="">
          <Snackbar
            place="tr"
            color={"danger"}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
          
        </Modal>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalInfoVenta);