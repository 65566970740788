import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ModalCargando from './ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";


class ModalBuscarAlumnos extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
        BuscarAlumno: "",
        ListaAlumnos: [],
        AlumnoSeleciondado:{},
        checkedInactivos: false,
      MostrarModal:false,
      _id: "",
      disabled:false,
      cobranza: [],
      colorNotificacion: "success",
      mensaje: "",
      ocultar: false,
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      CuerpoCargando: "Cargando",
      cargando: false
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    if (this.props.op == 1) {
      this.setState({
        ocultar: this.props.ocultar
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------
  obtenerMaestros(){
    this.setState({cargando:true})
    fetch(Globales.Url + 'Alumnos/Alumnos/ListaMaestrosBusquedaFiltrada', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        Nombre: this.state.BuscarAlumno
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', ListaAlumnos: [], cargando:false });
          this.showNotification();
         } else { //Filtrado exitoso
          if(data.ListaMaestros.length > 1){
            this.setState({colorNotificacion: 'success', ListaAlumnos: data.ListaMaestros, cargando:false });
          }
          else {
            let ListaAux = [];
            ListaAux.push(data.ListaMaestros);
            //console.log(ListaAux);
            this.setState({ ListaAlumnos: ListaAux, cargando:false });
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true})); 
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  SeleccionarAlumno(ListaAlumnos) {
    this.setState({AlumnoSeleciondado:ListaAlumnos, op: 1 });
    this.props.parentCallback(ListaAlumnos);
    this.props.eventClick('esc');
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value, checked } = e.target;
    if(name == "checkedInactivos"){
      this.setState({
        checkedInactivos: checked,
      },()=>{
        this.obtenerMaestros.bind(this)
      });
    }else if(name == "BuscarAlumno"){
      this.setState({
        BuscarAlumno: value,
      },()=>{
        this.obtenerMaestros.bind(this)
      });
    }
    this.obtenerMaestros.bind()
    
  }

  obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  }

 
  render() {
    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal" size="lg">
          <Card >
            <CardHeader color="info">
              {"Buscar maestro"}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Buscar maestro"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.BuscarAlumno,
                      name: "BuscarAlumno",
                      id: "BuscarAlumno",
                      type: "text",
                      error: this.state.errorNumFactura,
                      success: !this.state.errorNumFactura
                    }}
                  />
                </GridItem>
              </GridContainer>
              {/* Botones */}
              <div className='mt-4'>
                  <GridItem>
                  <Button tabIndex="1" size='sm' color='danger' className='pull-right' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
                    <Button tabIndex="0" disabled={this.state.disable} id="btnGuardar" size='sm' color='info' className='pull-right' round onClick={this.obtenerMaestros.bind(this)}><Search />&nbsp;Buscar</Button>
                  </GridItem>
                </div>
            </CardBody>
            <CardBody>
            <table className="col-xs-12 col-sm-12 col-md-12">
                      <thead>           
                        {/* <th className="center">Id</th> */}
                        <th className="center">Nombre Completo</th>
                      </thead>
                      <tbody>
                      {this.state.ListaAlumnos.map(ListaAlumnos => {
                          return (
                            <tr key={ListaAlumnos}>
                              <td>{ListaAlumnos.NombreCompleto}</td>
                              <td className="pull-right">
                                <Button color="success" round justIcon size="sm" onClick={() => this.SeleccionarAlumno(ListaAlumnos)} ><ArrowForwardIos /></Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className='mt-4'>
                  <GridItem>
                    {/* <Button tabIndex="0" disabled={this.state.disable} id="btnGuardar" size='sm' color='info' className='pull-right' round onClick={this.registrarCobranza.bind(this)}><Save />&nbsp;Seleccionar</Button> */}
                    {/* <Button tabIndex="1" size='sm' color='warning' className='pull-right' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button> */}
                  </GridItem>
                </div>
            </CardBody>
          </Card>
          {agregar}
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalBuscarAlumnos);