import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp, LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'

let tableFloatTheadTable= {
  position: "sticky",
  // position: "-webkit-sticky",
  borderTop: "none",
  borderBottom: "none",
  zIndex: 10,
  backgroundColor: "#fff",
  top: 0,
}

let th = {
  position: "sticky",
  // top: "50px",
  top: "0",
  background: "white",
  paddingTop: "50px",
}

class ModalCalificarGrupo extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      GrupoSeleccionado: {},
      NombreNivel: "",
      InfoGrupo: {},
      CalificacionUnidades: [],
      ListaAlumnos: [],
      UnidadesLibro: [],
      ListaNiveles: [],
      UnidadesLibroActuales: [],
      NivelSeleccionado: "",
      errorObservaciones: false,
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: "",
      CuerpoCargando: "Cargando",
      cargando: false
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    //console.log(this.props.GrupoSeleccionado)
    this.ObtenerInformacionGrupo(this.props.GrupoSeleccionado._id);
    //this.ObtenerInformacionNivelUnidades(this.props.GrupoSeleccionado._idNivel);
    this.setState({NivelSeleccionado: JSON.stringify(this.props.GrupoSeleccionado._idNivel)})
  }

  ListarNiveles(grupo) {
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaNivelesIdioma', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ 
         idIdioma: grupo._idIdioma
      })
    }
    ).then(res => res.json())
      .then(data => {
      // console.log(data)
        if (data.error) { //Error en la respuesta
          this.setState({  ListaNiveles:[] });
          // this.showNotification();
        } else { //Filtrado exitoso
          let aux = [];
          //console.log(grupo.ListaNiveles)
          
          if(grupo.ListaNiveles !== null && grupo.ListaNiveles !== undefined){
            if(grupo.ListaNiveles.NivelGrupo.length > 1){
              grupo.ListaNiveles.NivelGrupo.map(nivel => {
                aux.push(nivel.idNivel);
              })
              aux.push(grupo._idNivel)
            }
            else{
              aux.push(grupo.ListaNiveles.NivelGrupo.idNivel);
              aux.push(grupo._idNivel);
            }
          }
          else{
            aux.push(grupo._idNivel);
          }

          // console.log("aux", aux)

          let ListaNiveles = [];
          if(data.data.length > 1){
            data.data.map(nivel => {
              aux.map(anteriores => {
                if(JSON.stringify(nivel._id) === JSON.stringify(anteriores)){
                  ListaNiveles.push(nivel)
                }
              })
            })
          }
          else{
            aux.map(anteriores => {
              if(JSON.stringify(data.data._id) === JSON.stringify(anteriores)){
                ListaNiveles.push(data.data)
              }
            })
          }
          
			let ListaNivelesUnicos = [
				...new Set(ListaNiveles.map((o) => JSON.stringify(o))),
			].map((s) => JSON.parse(s));

          this.setState({ ListaNiveles: ListaNivelesUnicos })
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  async ObtenerInformacionGrupo(idGrupo) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Calificaciones/Calificaciones/ObtenerInformacionGrupo', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idGrupo: idGrupo})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log("ObtenerInformacionGrupo: ", data.InfoGrupo);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              let aux = []
              if(data.InfoGrupo.Libros !== undefined && data.InfoGrupo.Libros !== null){
                if(data.InfoGrupo.Libros.Libro.length > 1){
                  data.InfoGrupo.Libros.Libro.map(libro => {
                    if(libro.Unidades !== undefined && libro.Unidades !== null){
                      if(libro.Unidades.Unidad.length > 1){
                        aux = aux.concat(libro.Unidades.Unidad)
                      }
                      else{
                        aux.push(libro.Unidades.Unidad)
                      }
                    }
                  })
                }
                else{
                  if(data.InfoGrupo.Libros.Libro.Unidades !== undefined && data.InfoGrupo.Libros.Libro.Unidades !== null){
                    if(data.InfoGrupo.Libros.Libro.Unidades.Unidad.length > 1){
                      aux = data.InfoGrupo.Libros.Libro.Unidades.Unidad
                    }
                    else{
                      aux.push(data.InfoGrupo.Libros.Libro.Unidades.Unidad)
                    }
                  }
                }
              }

              // console.log("Unidades", aux)
             
                this.setState({
                  InfoGrupo: data.InfoGrupo, 
                  UnidadesLibroActuales: aux,
                  UnidadesLibro: aux,
                  NombreNivel: data.InfoGrupo.NombreNivel !== null && data.InfoGrupo.NombreNivel !== undefined ? (data.InfoGrupo.NombreNivel).toLowerCase() : (data.InfoGrupo.NombreIdioma).toLowerCase()
                }, () => {
                  
                  this.ListarNiveles(data.InfoGrupo);
                  this.ListaCompletaAlumnosGrupo(idGrupo)
                }) 
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

RecuperarLibros(idnivel){
  if(idnivel === JSON.stringify(this.state.InfoGrupo._idNivel)){
    this.setState({UnidadesLibro: this.state.UnidadesLibroActuales}, () => {
      this.ObjCalificaciones(this.state.InfoGrupo._idNivel)
    })
  }
  else{
    let aux = []
    if(this.state.InfoGrupo.ListaNiveles !== undefined && this.state.InfoGrupo.ListaNiveles !== null){
      if(this.state.InfoGrupo.ListaNiveles.NivelGrupo.length > 1){
        this.state.InfoGrupo.ListaNiveles.NivelGrupo.map(nivelAnt => {
          if(JSON.stringify(nivelAnt.idNivel) === idnivel){
            if(nivelAnt.Libros !== undefined && nivelAnt.Libros !== null){
              if(nivelAnt.Libros.Libro.length > 1){
                nivelAnt.Libros.Libro.map(libro => {
                  if(libro.Unidades !== undefined && libro.Unidades !== null){
                    if(libro.Unidades.Unidad.length > 1){
                      aux = aux.concat(libro.Unidades.Unidad)
                    }
                    else{
                      aux.push(libro.Unidades.Unidad)
                    }
                  }
                })
              }
              else{
                if(nivelAnt.Libros.Libro.Unidades !== undefined && nivelAnt.Libros.Libro.Unidades !== null){
                  if(nivelAnt.Libros.Libro.Unidades.Unidad.length > 1){
                    aux = aux.concat(nivelAnt.Libros.Libro.Unidades.Unidad)
                  }
                  else{
                    aux.push(nivelAnt.Libros.Libro.Unidades.Unidad)
                  }
                }
              }
            }
          }
        })
      }
      else{
        if(JSON.stringify(this.state.InfoGrupo.ListaNiveles.NivelGrupo.idNivel) === idnivel){
          if(this.state.InfoGrupo.ListaNiveles.NivelGrupo.Libros !== undefined && this.state.InfoGrupo.ListaNiveles.NivelGrupo.Libros !== null){
            if(this.state.InfoGrupo.ListaNiveles.NivelGrupo.Libros.Libro.length > 1){
              this.state.InfoGrupo.ListaNiveles.NivelGrupo.Libros.Libro.map(libro => {
                if(libro.Unidades !== undefined && libro.Unidades !== null){
                  if(libro.Unidades.Unidad.length > 1){
                    aux = aux.concat(libro.Unidades.Unidad)
                  }
                  else{
                    aux.push(libro.Unidades.Unidad)
                  }
                }
              })
            }
            else{
              if(this.state.InfoGrupo.ListaNiveles.NivelGrupo.Libros.Libro.Unidades !== undefined && this.state.InfoGrupo.ListaNiveles.NivelGrupo.Libros.Libro.Unidades !== null){
                if(this.state.InfoGrupo.ListaNiveles.NivelGrupo.Libros.Libro.Unidades.Unidad.length > 1){
                  aux = aux.concat(this.state.InfoGrupo.ListaNiveles.NivelGrupo.Libros.Libro.Unidades.Unidad)
                }
                else{
                  aux.push(this.state.InfoGrupo.ListaNiveles.NivelGrupo.Libros.Libro.Unidades.Unidad)
                }
              }
            }
          }
        }
      }
    }

    let ListaUnidadesUnicos = [
      ...new Set(aux.map((o) => JSON.stringify(o))),
    ].map((s) => JSON.parse(s));
    // console.log("aux", aux)
    this.setState({UnidadesLibro: ListaUnidadesUnicos}, () => {
      this.ObjCalificaciones(JSON.parse(idnivel))
    })
  }
}

// async ObtenerInformacionNivelUnidades(idnivel, int) {
//   this.setState({cargando: true});
//   await fetch(Globales.Url + 'Idiomas/Idiomas/ObtenerInformacionNivelUnidades', {
//       method: 'POST',
//       headers: {
//           Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
//       }
//       , body: JSON.stringify({idNivel: idnivel})
//   }
//   ).then(res => res.json())
//       .then(data => {
//           //console.log("API: ", data.NivelUnidades);
//           if (data.error) { //Error en la respuesta
//               this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false, UnidadesLibro: [] });
//               this.showNotification();
//           } else { //Filtrado exitoso
//               if(data.NivelUnidades.length > 1){
//                 this.setState({UnidadesLibro: data.NivelUnidades, cargando: false}, () => {
//                   if(int == 1){
//                     this.ObjCalificaciones(data.NivelUnidades._id)
//                   }
//                 })
//               }
//               else{
//                 let aux = [];
//                 aux.push(data.NivelUnidades)
//                 this.setState({UnidadesLibro: aux}, () => {
//                   if(int == 1){
//                     this.ObjCalificaciones(data.NivelUnidades._id)
//                   }
//                 }) 
//               }
//           }
//         })
//   .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
// }

async ListaCompletaAlumnosGrupo(idGrupo) {
  //this.setState({cargando: true});
  await fetch(Globales.Url + 'Calificaciones/Calificaciones/ListaCompletaAlumnosGrupo', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idGrupo: idGrupo})
  }
  ).then(res => res.json())
      .then(data => {
          // console.log("API", data.ListaAlumnos);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaAlumnos: [], Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
            let aux = [];
            if(data.ListaAlumnos.length > 1){
              data.ListaAlumnos.map(alumno => {
                if(alumno.ListaGrupos.GrupoAlumno.length > 1){
                  alumno.ListaGrupos.GrupoAlumno.map(grupo => {
                    if(this.props.op == 0){
                      if(grupo.Estado == "En_Curso"){
                        aux.push(alumno);
                      }
                    }
                    else{
                      if(grupo.Estado == "Finalizado"){
                        aux.push(alumno);
                      }
                    }
                  })
                }
                else{
                  if(this.props.op == 0){
                    if(alumno.ListaGrupos.GrupoAlumno.Estado == "En_Curso"){
                      aux.push(alumno);
                    }
                  }
                  else{
                    if(alumno.ListaGrupos.GrupoAlumno.Estado == "Finalizado"){
                      aux.push(alumno);
                    }
                  }
                }
              })
            }
            else{
              if(data.ListaAlumnos.ListaGrupos.GrupoAlumno.length > 1){
                data.ListaAlumnos.ListaGrupos.GrupoAlumno.map(grupo => {
                  if(this.props.op == 0){
                    if(grupo.Estado == "En_Curso"){
                      aux.push(data.ListaAlumnos);
                    }
                  }
                  else{
                    if(grupo.Estado == "Finalizado"){
                      aux.push(data.ListaAlumnos);
                    }
                  }
                })
              }
              else{
                if(this.props.op == 0){
                  if(data.ListaAlumnos.ListaGrupos.GrupoAlumno.Estado == "En_Curso"){
                    aux.push(data.ListaAlumnos);
                  }
                }
                else{
                  if(data.ListaAlumnos.ListaGrupos.GrupoAlumno.Estado == "Finalizado"){
                    aux.push(data.ListaAlumnos);
                  }
                }
              }
            }

            this.setState({ListaAlumnos: aux}, () => this.ObjCalificaciones(this.state.InfoGrupo._idNivel))
          }
        })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

ObjCalificaciones(nivel){
  let auxCalificaciones = [];
  let obj = {};
  //console.log("nivel", nivel)
  // console.log("UnidadesLibro", this.state.UnidadesLibro)
  //console.log("ListaAlumnos", this.state.ListaAlumnos)

  if(this.state.UnidadesLibro.length > 0 ){
    this.state.ListaAlumnos.map(alumno => {
    this.state.UnidadesLibro.map(unidad => {
        obj = {
          CalificacionAsignada: 0,
          IdAlumno: alumno._id,
          IdGrupo: this.state.InfoGrupo._id,
          IdNivel: nivel,
          IdUnidad: unidad._id
        }
        auxCalificaciones.push(obj);
      })
    })
    this.CalificaionesAlumnosGrupo(this.props.GrupoSeleccionado._id, auxCalificaciones)
    // console.log("auxCalificaciones", auxCalificaciones)
  }
  else{
    this.setState({mensaje: "El libro asignado a este grupo no tiene unidades registradas o actualice las unidades de este grupo", colorNotificacion: "danger", cargando: false, disabled: true});
    this.showNotification();
  }
}

async CalificaionesAlumnosGrupo(idGrupo, auxCalificaciones) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Calificaciones/Calificaciones/CalificaionesAlumnosGrupo', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idGrupo: idGrupo})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log("CalificaionesAlumnosGrupo: ", data);
          if (data.error) { //Error en la respuesta

              this.setState({ CalificacionUnidades: auxCalificaciones, cargando: false });
          } else { //Filtrado exitoso

            if( data.CalificacionUnidades.length > 1){
              auxCalificaciones.map(aux => {
                data.CalificacionUnidades.map(calif => {
                  if(JSON.stringify(aux.IdAlumno) == JSON.stringify(calif.IdAlumno)){
                    if(JSON.stringify(aux.IdUnidad) == JSON.stringify(calif.IdUnidad)){
                      aux.CalificacionAsignada = parseInt(calif.CalificacionAsignada);
                      aux._id = calif._id;
                    }
                  }
                })
              })       
              this.setState({cargando: false, CalificacionUnidades: auxCalificaciones})
            }
            else{
              let ListaAux = [];
              ListaAux.push(data.CalificacionUnidades);


              auxCalificaciones.map(aux => {
                ListaAux.map(calif => {
                  if(JSON.stringify(aux.IdAlumno) == JSON.stringify(calif.IdAlumno)){
                    if(JSON.stringify(aux.IdUnidad) == JSON.stringify(calif.IdUnidad)){
                      aux.CalificacionAsignada = parseInt(calif.CalificacionAsignada);
                      aux._id = calif._id;
                    }
                  }
                })
              })  

              this.setState({cargando: false, CalificacionUnidades: auxCalificaciones})
            }
               
          }
        })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

CrearObjLogGuardar(){
  let IDcookie = JSON.parse(cookie.get('id'));
  let Log = {};
  let aux = [];
  Log = {
    Fecha: moment(new Date()).format("YYYY-MM-DD"),
    _idGrupo: this.state.InfoGrupo._id,
    _idIdioma: this.state.InfoGrupo._idIdioma,
    _idMaestro: this.state.InfoGrupo._idMaestro,
    _idNivel: JSON.parse(this.state.NivelSeleccionado),
    _idUsuario: IDcookie,
  }

  this.state.CalificacionUnidades.map(calif => {
    if(calif.CalificacionAsignada !== 0){
      aux.push(calif);
    }
  })

  //console.log(aux)

  this.CalificarUnidadAlumnos(aux, Log)
}

async CalificarUnidadAlumnos(Calificaciones, Log) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Calificaciones/Calificaciones/CalificarUnidadAlumnos', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Calificaciones: Calificaciones, Log: Log})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log("API", data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaAlumnos: [], Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso

              this.setState({cargando: false})
              this.RegresarMensaje(data.mensaje);
          }
        })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    //this.setState({ op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;

    if(name == "NivelSeleccionado"){
      this.RecuperarLibros(value,0)
    }

    this.setState({[name]: value});
  }

  handleChangeEdit(e, item){
    const { name, value } = e.target;
    const CalificacionUnidades = this.state.CalificacionUnidades;
    let seleccionado;
    let calificacion = parseInt(value.replace(/[^0-9]/g, ''));

      seleccionado = CalificacionUnidades.indexOf(item);

      switch(name){
        case "Calificacion":
          if(calificacion >= 100){
            CalificacionUnidades[seleccionado].CalificacionAsignada = 100;

            this.setState({CalificacionUnidades: CalificacionUnidades});
          }
          else{
            CalificacionUnidades[seleccionado].CalificacionAsignada = parseInt(calificacion);

            this.setState({CalificacionUnidades: CalificacionUnidades});
          }
          break;
      }
  }

  handleFocus = (event) => {
    const e = event.target;
    e.select()
  }

  handleBlur = (event, item) => {
    //console.log(event.target)
    const { name, value } = event.target;
    const CalificacionUnidades = this.state.CalificacionUnidades;
    let seleccionado;

    seleccionado = CalificacionUnidades.indexOf(item);

    //console.log("HandleBlur", CalificacionUnidades[seleccionado].CalificacionAsignada)

    if(isNaN(CalificacionUnidades[seleccionado].CalificacionAsignada)){
      CalificacionUnidades[seleccionado].CalificacionAsignada = 0;
      this.setState({CalificacionUnidades: CalificacionUnidades});
    }
  }
 
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
  ObtenerPromedio(IdAlumno){
    const { CalificacionUnidades, UnidadesLibro } = this.state;
    let suma = 0 
    let unidades = 0
    let respuesta = 0

    // console.log(IdAlumno);
    CalificacionUnidades.map(calif => {
      if(JSON.stringify(IdAlumno) == JSON.stringify(calif.IdAlumno)&& parseFloat(calif.CalificacionAsignada)>0){
        // console.log(calif.CalificacionAsignada);
        suma += parseFloat(calif.CalificacionAsignada)
        unidades++
      }
    })
    
    respuesta = suma / unidades
    // console.log(respuesta);
    return unidades > 0 ? parseFloat(respuesta).toFixed(2):0
  }

  render() {
    const { classes, GrupoSeleccionado } = this.props;
    const { InfoGrupo, NombreNivel, ListaAlumnos, CalificacionUnidades, UnidadesLibro } = this.state;

    // console.log(UnidadesLibro)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }


    let unidades;
    if(UnidadesLibro.length > 0){
      unidades =
        UnidadesLibro.map((unidad, index) => {
          if(index < UnidadesLibro.length){
            return(
              <>
                <th style={th} >{unidad.NombreUnidad}</th>
              </>
            )
          }
        })
    }

    // console.log(CalificacionUnidades)
    let calificaciones;
    if(CalificacionUnidades.length > 0){
      calificaciones =
        ListaAlumnos.map(alumno => {
          return(
            <tr>
              <td>{alumno.NumeroCuenta_NombreCompleto}</td>
              {
                CalificacionUnidades.map(calif => {
                  if(JSON.stringify(alumno._id) == JSON.stringify(calif.IdAlumno)){
                    return(
                      <td>
                        <TextField
                            id="Calificacion"
                            style={{ width: "5.5em"}}
                            variant="outlined"
                            type="number"
                            size="small"
                            //label="Precio"
                            onChange={(e) => this.handleChangeEdit(e, calif)}
                            onBlur={(e) => this.handleBlur(e, calif)}
                            InputProps={{
                              //onChange: this.handleChange.bind(this),
                              onFocus: this.handleFocus.bind(this),
                              //onBlur: this.handleBlur.bind(this),
                              value: calif.CalificacionAsignada,
                              name: "Calificacion",
                              id: "Calificacion",
                              //error: this.state.errorFechainicio,
                              //success: !this.state.errorFechainicio
                            }}
                        />
                      </td>                 
                    )
                  }
                })
              }
              <td>{this.ObtenerPromedio(alumno._id)}</td>
            </tr>
          )
        })
    }

    //console.log("Grupo Seleccionado", GrupoSeleccionado)
    //console.log("Info grupo", InfoGrupo)
    //console.log("Alumnos", ListaAlumnos)
    // console.log("Calificacion", CalificacionUnidades)
    //console.log("Unidades Libro", this.state.UnidadesLibro)
    return (
      <div>
        <Modal
          isOpen={this.props.showCalificarGrupo}
          className="static-modal"
          size="lg"
          //toggle={this.cerrarModal}
          backdrop={"static"}
          style={{ maxWidth: "1680px", width: "100%",maxHeight: "100%" }}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p style={{fontWeight: "bold"}}>0 = Sin Calificación / No Aplica</p>
                </GridItem>
                  <GridItem xs={12} sm={12} md={12} style={{display: "flex", justifyContent: "left", marginBottom: "10px"}}>
                    <FormControl
                      //fullWidth
                      size="small"
                      style={{ width: "15em" }}
                    >
                    <InputLabel style={{paddingLeft: "20px", top: "-10px"}} id="label3">Nivel</InputLabel>
                      <Select
                          labelId="label3"
                          label="Nivel"
                          value={this.state.NivelSeleccionado}
                          onChange={this.handleChange.bind(this)}   
                          variant="outlined"    
                          name="NivelSeleccionado"  
                      >
                      {this.state.ListaNiveles.length == 0 ? null :
                          this.state.ListaNiveles.map((nivel) => {
                              return (<MenuItem value={JSON.stringify(nivel._id)} >{nivel.Nombre}</MenuItem>)
                          })}
                      </Select>
                    </FormControl>
                    {/* <h6 style={{textAlign: "center", fontWeight: "bold"}}>{this.capitalizeFirstLetter(NombreNivel)}</h6> */}
                  </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="table-responsive-xl">
                    <table className="col-xs-12 col-sm-12 col-md-12 table table-hover">
                      <thead style={tableFloatTheadTable} class="thead-light">
                        <th style={th} >Alumnos</th>
                        {unidades}
                        <th style={th} >Promedio</th>
                      </thead>
                      <tbody>
                        {calificaciones}
                      </tbody>
                    </table>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>    
          <GridItem xs={12} sm={12} md={12}>
              <Button color="info" disabled={this.state.disabled} tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.CrearObjLogGuardar()}><Save/>&nbsp;Guardar</Button>
              <Button tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cancelar</Button>
          </GridItem> 
        </Modal>
        <div>
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalCalificarGrupo);