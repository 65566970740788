import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'

class ModalDescuentoTemp extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      ObjDescuentoTemp: {},
      idAlumno: {},
      TipoDescuento: "Beca",
      OpcionMesInicio: "",
      OpcionMesFin: "",
      AñoInicio: moment(new Date()).format("YYYY"),
      AñoFin: moment(new Date()).format("YYYY"),
      ListaConceptos: [],
      ListaIdiomas: [],
      IdiomaSeleccionado: "",
      ConceptoSeleccionado: "",
      ListaSubConceptos: [],
      SubconceptoSeleccionado: "",
      DescuentoSeleccionado: {},
      DescuentoPorcentaje: 0,
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      errorConcepto: false,
      errorSubconcepto: false,
      errorIdioma: false,
      errorDescuento: false,
      errorFechaInicio: false,
      errorFechaFin: false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: "",
      CuerpoCargando: "Cargando",
      cargando: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    //console.log(this.props.DescuentoSeleccionado)
    //console.log(this.props.DescuentosTemp)
    if(this.props.op == 1){
      this.setState({
        DescuentoSeleccionado: this.props.DescuentoSeleccionado,
        TipoDescuento: this.props.DescuentoSeleccionado.Tipo,
        ConceptoSeleccionado: JSON.stringify(this.props.DescuentoSeleccionado._idConceptoDescuento),
        SubconceptoSeleccionado: JSON.stringify(this.props.DescuentoSeleccionado._idSubConceptoDescuento),
        IdiomaSeleccionado: JSON.stringify(this.props.DescuentoSeleccionado._idIdioma),
        DescuentoPorcentaje: parseFloat(this.props.DescuentoSeleccionado.Descuento),
        OpcionMesInicio: new Date(this.props.DescuentoSeleccionado.MesInicioDescuento).getMonth(),
        AñoInicio: moment(this.props.DescuentoSeleccionado.MesInicioDescuento).format("YYYY"),
        OpcionMesFin: (new Date(this.props.DescuentoSeleccionado.MesTerminacionDescuento).getMonth() - 1),
        AñoFin: moment(this.props.DescuentoSeleccionado.MesTerminacionDescuento).format("YYYY"),
      })
      this.ListaSubConceptoDescuento(this.props.DescuentoSeleccionado._idConceptoDescuento)
    }
    this.ListaGeneralConceptoDescuento();
    this.ObtenerIdiomasEnCursoAlumno(this.props._id);
  }

  async ListaGeneralConceptoDescuento() {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Alumnos/Alumnos/ListaGeneralConceptoDescuento', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              if(data.ListaConceptos.length > 1){
                this.setState({ListaConceptos: data.ListaConceptos, cargando: false})
              }
              else{
                let aux = [];
                aux.push(data.ListaConceptos);

                this.setState({ListaConceptos: aux, cargando: false})
              }             
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ListaSubConceptoDescuento(id, idsub) {
  //this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/ListaSubConceptoDescuento', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({id: id})
  }
  ).then(res => res.json())
      .then(data => {
          // console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
            if(data.ListaSubConceptos.length > 1){
              this.setState({ListaSubConceptos: data.ListaSubConceptos, cargando: false})
            }
            else{
              let aux = [];
              aux.push(data.ListaSubConceptos);
              this.setState({ListaSubConceptos: aux, cargando: false})
            }
          }
        })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ObtenerIdiomasEnCursoAlumno(id) {
  //this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerIdiomasEnCursoAlumno', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idAlumno: id})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: "El alumno no está inscrito en ningún idioma", colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
            if(data.ListaIdiomas.length > 1){
              this.setState({ListaIdiomas: data.ListaIdiomas, cargando: false})
            }
            else{
              let aux = [];
              aux.push(data.ListaIdiomas);
              this.setState({ListaIdiomas: aux, cargando: false})
            }
              
          }
        })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

CrearObjDescuentoTem(){
  let valor1 = 0;
  let valor2 = 0;
  let valor3 = 0;
  let valor4 = 0;
  let valor5 = 0;
  let valor6 = 0;
  let valor7 = 0;

  if(this.props.op !== 1){
    this.props.DescuentosTemp.map(desc => {
      if(JSON.stringify(desc._idIdioma) === this.state.IdiomaSeleccionado){
        valor7 = 1;
        this.setState({mensaje: "El idioma seleccionado ya tiene asignado un descuento temporal/beca", colorNotificacion: "danger", errorIdioma: true});
        this.showNotification();
      }
    })
  }

  if(this.state.OpcionMesFin === ""){
    valor6 = 1;
    this.setState({errorFechaFin: true, mensaje: "Proporcione un mes de terminación", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor6 = 0;
    this.setState({errorFechaFin: false})
  }

  if(this.state.OpcionMesInicio === ""){
    valor5 = 1;
    this.setState({errorFechaInicio: true, mensaje: "Proporcione un mes de inicio", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor5 = 0;
    this.setState({errorFechaInicio: false})
  }

  if(this.state.DescuentoPorcentaje == 0){
    valor4 = 1;
    this.setState({errorDescuento: true, mensaje: "Proporcione un descuento", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor4 = 0;
    this.setState({errorDescuento: false})
  }

  if(this.state.IdiomaSeleccionado == ""){
    valor3 = 1;
    this.setState({errorIdioma: true, mensaje: "Seleccione idioma", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor3 = 0;
    this.setState({errorIdioma: false})
  }

  if(this.state.SubconceptoSeleccionado == ""){
    valor2 = 1;
    this.setState({errorSubconcepto: true, mensaje: "Seleccione un subconcepto", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor2 = 0;
    this.setState({errorSubconcepto: false})
  }

  if(this.state.ConceptoSeleccionado == ""){
    valor1 = 1;
    this.setState({errorConcepto: true, mensaje: "Seleccione un concepto", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor1 = 0;
    this.setState({errorConcepto: false})
  }

  if(valor1 + valor2 + valor3 + valor4 + valor5 + valor6 + valor7 == 0){
    let NombreConceptoDescuento = "";
    let NombreIdioma = "";
    let NombreSubConceptoDescuento = "";

    this.state.ListaConceptos.map(concepto => {
      if(JSON.stringify(concepto._id) === this.state.ConceptoSeleccionado){
        NombreConceptoDescuento = concepto.Nombre;
      }
    })

    this.state.ListaSubConceptos.map(subcon => {
      if(JSON.stringify(subcon._id) === this.state.SubconceptoSeleccionado){
        NombreSubConceptoDescuento = subcon.Nombre;
      }
    })

    this.state.ListaIdiomas.map(idioma => {
      if(JSON.stringify(idioma._id) === this.state.IdiomaSeleccionado){
        NombreIdioma = idioma.Nombre;
      }
    })

    //console.log("Entro a registrar")

    if(this.props.op !== 1){
      this.setState({
        ObjDescuentoTemp: {
          Descuento: parseFloat(this.state.DescuentoPorcentaje),
          Estado: "Vigente",
          FechaAsignacion: moment(new Date()).format("YYYY-MM-DD"),
          FechaCancelacion: "0001-01-01",
          MesInicioDescuento: new Date(this.state.AñoInicio, this.state.OpcionMesInicio, 1),
          MesTerminacionDescuento: new Date(this.state.AñoFin, (this.state.OpcionMesFin + 1), 1),
          NombreConceptoDescuento: NombreConceptoDescuento,
          NombreIdioma: NombreIdioma,
          NombreNivel: null,
          NombreSubConceptoDescuento: NombreSubConceptoDescuento,
          Tipo: this.state.TipoDescuento,
          _idAlumno: this.props._id,
          _idConceptoDescuento: JSON.parse(this.state.ConceptoSeleccionado),
          _idIdioma: JSON.parse(this.state.IdiomaSeleccionado),
          _idNivel: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
          _idSubConceptoDescuento: JSON.parse(this.state.SubconceptoSeleccionado),
          _idUsuarioCancelacion: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
          _idUsuarioRegistro: JSON.parse(cookie.get('id'))
        }
      }, () => this.RegistrarDescuentoTemporal())
    }
    else{
      this.setState({
        ObjDescuentoTemp: {
          Descuento: parseFloat(this.state.DescuentoPorcentaje),
          Estado: "Vigente",
          FechaAsignacion: this.props.DescuentoSeleccionado.FechaAsignacion,
          FechaCancelacion: "0001-01-01",
          MesInicioDescuento: new Date(this.state.AñoInicio, this.state.OpcionMesInicio, 1),
          MesTerminacionDescuento: new Date(this.state.AñoFin, (this.state.OpcionMesFin + 1), 1),
          NombreConceptoDescuento: NombreConceptoDescuento,
          NombreIdioma: NombreIdioma,
          NombreNivel: null,
          NombreSubConceptoDescuento: NombreSubConceptoDescuento,
          Tipo: this.state.TipoDescuento,
          _id: this.props.DescuentoSeleccionado._id,
          _idAlumno: this.props._id,
          _idConceptoDescuento: JSON.parse(this.state.ConceptoSeleccionado),
          _idIdioma: JSON.parse(this.state.IdiomaSeleccionado),
          _idNivel: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
          _idSubConceptoDescuento: JSON.parse(this.state.SubconceptoSeleccionado),
          _idUsuarioCancelacion: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
          _idUsuarioRegistro: JSON.parse(cookie.get('id'))
        }
      }, () => this.RegistrarDescuentoTemporal())
    }
    
  }
}

async RegistrarDescuentoTemporal() {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/RegistrarDescuentoTemporal', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({DescuentoTemp: this.state.ObjDescuentoTemp})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              this.setState({cargando: false});
              this.RegresarMensaje(data.mensaje)
          }
        })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    this.setState({ op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;

    switch(name){
      case "ConceptoSeleccionado":
        this.ListaSubConceptoDescuento(JSON.parse(value));
        this.setState({[name]: value, SubconceptoSeleccionado: ""});
        break;
      case "DescuentoPorcentaje":
        let descuento = value.replace(/[^0-9]/g, '');
        if(descuento > 100){
          this.setState({[name]: 100});
        }
        else{
          this.setState({[name]: descuento});
        }
        break;   
      default:
        this.setState({[name]: value});
        break;
    }
  }

  handleFocus = (event) => event.target.select();
  
  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;
    let AñoMax = moment(new Date()).add(5,'y').format("YYYY");


    //console.log(this.state._id)
    //console.log(this.state.ListaConceptos)
    //console.log("Concepto", this.state.ConceptoSeleccionado)
    //console.log("SubConcepto", this.state.SubconceptoSeleccionado)
    //console.log("Idioma", this.state.IdiomaSeleccionado)
    //console.log(this.props.DescuentoSeleccionado)
    //console.log("OpcionMesInicio", this.state.OpcionMesInicio)
    //console.log("OpcionMesFin", this.state.OpcionMesFin)
    // console.log("DescCompleto", this.state.ObjDescuentoTemp)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showDescuentoTemp}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="TipoDescuento">Tipo de descuento</InputLabel>
                    <Select
                        labelId="TipoDescuento"
                        //fullWidth={true}
                        value={this.state.TipoDescuento}
                        onChange={this.handleChange.bind(this)}
                        name="TipoDescuento"
                        label="Tipo de descuento"
                        style={{ width: "28.8em", marginTop: "10px" }}
                        //error={this.state.errorEmpresa}
                        //success={!this.state.errorEmpresa}
                    >
                      <MenuItem disabled>Seleccione un tipo de descuento</MenuItem>
                      <MenuItem value={"Beca"}>Beca</MenuItem>

                  </Select>
                </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="ConceptoDesc">Concepto de descuento</InputLabel>
                      <Select
                          labelId="ConceptoDesc"
                          //fullWidth={true}
                          value={this.state.ConceptoSeleccionado}
                          onChange={this.handleChange.bind(this)}
                          name="ConceptoSeleccionado"
                          label="Concepto de descuento"
                          style={{ width: "13.5em", marginTop: "10px" }}
                          error={this.state.errorConcepto}
                          success={!this.state.errorConcepto}
                      >
                        <MenuItem disabled>Seleccione un concepto</MenuItem>
                        {this.state.ListaConceptos.map(concepto => {
                          return(<MenuItem value={JSON.stringify(concepto._id)}>{concepto.Nombre}</MenuItem>)
                        })}

                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="Subconcepto">Subconcepto</InputLabel>
                      <Select
                          labelId="Subconcepto"
                          //fullWidth={true}
                          value={this.state.SubconceptoSeleccionado}
                          onChange={this.handleChange.bind(this)}
                          name="SubconceptoSeleccionado"
                          label="Subconcepto"
                          style={{ width: "13.5em", marginTop: "10px" }}
                          error={this.state.errorSubconcepto}
                          success={!this.state.errorSubconcepto}
                      >
                        <MenuItem disabled>Seleccione un subconcepto</MenuItem>
                        {this.state.ListaSubConceptos.map(subconcepto => {
                          return(<MenuItem value={JSON.stringify(subconcepto._id)}>{subconcepto.Nombre}</MenuItem>)
                        })}
                      
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="idioma">Idioma</InputLabel>
                      <Select
                          labelId="idioma"
                          //fullWidth={true}
                          value={this.state.IdiomaSeleccionado}
                          onChange={this.handleChange.bind(this)}
                          name="IdiomaSeleccionado"
                          label="Idioma"
                          style={{ width: "13.5em", marginTop: "10px" }}
                          error={this.state.errorIdioma}
                          success={!this.state.errorIdioma}
                      >
                        <MenuItem disabled>Seleccione un idioma</MenuItem>
                        {this.state.ListaIdiomas.map(idioma => {
                          return(<MenuItem value={JSON.stringify(idioma._id)}>{idioma.Nombre}</MenuItem>)
                        })}

                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                    id="DescuentoPorcentaje"
                    style={{ width: "15.3em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="% de descuento"
                    inputProps={{ maxLength: 3 }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.DescuentoPorcentaje,
                      name: "DescuentoPorcentaje",
                      id: "DescuentoPorcentaje",
                      error: this.state.errorDescuento,
                      success: !this.state.errorDescuento
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Fechas en las que aplicará el descuento</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={7} md={7}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="MesInicio">Mes de inicio</InputLabel>
                      <Select
                          labelId="MesInicio"
                          //fullWidth={true}
                          value={this.state.OpcionMesInicio}
                          onChange={this.handleChange.bind(this)}
                          name="OpcionMesInicio"
                          label="Mes de inicio"
                          style={{ width: "17em", marginTop: "10px" }}
                          error={this.state.errorFechaInicio}
                          success={!this.state.errorFechaInicio}
                      >
                        <MenuItem disabled>Seleccione un mes</MenuItem>
                      <MenuItem value={0}>Enero</MenuItem>
                      <MenuItem value={1}>Febrero</MenuItem>
                      <MenuItem value={2}>Marzo</MenuItem>
                      <MenuItem value={3}>Abril</MenuItem>
                      <MenuItem value={4}>Mayo</MenuItem>
                      <MenuItem value={5}>Junio</MenuItem>
                      <MenuItem value={6}>Julio</MenuItem>
                      <MenuItem value={7}>Agosto</MenuItem>
                      <MenuItem value={8}>Septiembre</MenuItem>
                      <MenuItem value={9}>Octubre</MenuItem>
                      <MenuItem value={10}>Noviembre</MenuItem>
                      <MenuItem value={11}>Diciembre</MenuItem>

                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                <TextField
                    id="AñoInicio"
                    style={{ width: "12em", marginTop: "10px", marginRight: "10px" }}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Año de inicio"
                    inputProps={{
                      min: 1950,
                      max: parseInt(AñoMax),
                      onKeyDown: (event) => {
                         event.preventDefault();
                      },
                    }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.AñoInicio,
                      name: "AñoInicio",
                      id: "AñoInicio",
                      //error: this.state.errorFechaFin,
                      //success: !this.state.errorFechaFin
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={7} md={7}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="MesFin">Mes de terminación</InputLabel>
                      <Select
                          labelId="MesFin"
                          //fullWidth={true}
                          value={this.state.OpcionMesFin}
                          onChange={this.handleChange.bind(this)}
                          name="OpcionMesFin"
                          label="Mes de terminacion"
                          style={{ width: "17em", marginTop: "10px" }}
                          error={this.state.errorFechaFin}
                          success={!this.state.errorFechaFin}
                      >
                        <MenuItem disabled>Seleccione un mes</MenuItem>
                      <MenuItem value={0}>Enero</MenuItem>
                      <MenuItem value={1}>Febrero</MenuItem>
                      <MenuItem value={2}>Marzo</MenuItem>
                      <MenuItem value={3}>Abril</MenuItem>
                      <MenuItem value={4}>Mayo</MenuItem>
                      <MenuItem value={5}>Junio</MenuItem>
                      <MenuItem value={6}>Julio</MenuItem>
                      <MenuItem value={7}>Agosto</MenuItem>
                      <MenuItem value={8}>Septiembre</MenuItem>
                      <MenuItem value={9}>Octubre</MenuItem>
                      <MenuItem value={10}>Noviembre</MenuItem>
                      <MenuItem value={11}>Diciembre</MenuItem>

                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                <TextField
                    id="AñoFin"
                    style={{ width: "12em", marginTop: "10px", marginRight: "10px" }}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Año de terminación"
                    inputProps={{
                      min: 1950,
                      max: parseInt(AñoMax),
                      onKeyDown: (event) => {
                         event.preventDefault();
                      },
                    }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.AñoFin,
                      name: "AñoFin",
                      id: "AñoFin",
                      //error: this.state.errorNombre,
                      //success: !this.state.errorNombre
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button color="info" tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.CrearObjDescuentoTem()}><Save/>&nbsp;Registrar</Button>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cancelar</Button>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>     
        </Modal>
        <div>
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalDescuentoTemp);