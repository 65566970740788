import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CardBody from "components/Card/CardBody.jsx";
import ModalConceptoDePago from "./ModalConceptoDePago.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import { convertirPesos } from "../../../../components/Generales/Utileria";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Search, Close } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

class CatConceptoPago extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      _idIdioma: "",
      FiltrarNombres: "",
      ListaConceptos: [],
      ListaIdiomas: [],
      ListaConceptosTemp: [],
      ConceptoSeleccionado: {},
      Disabled: false,
      mensaje: "",
      InfoConcepto: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      Titulo: "Registrar un nuevo Concepto",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    this.ListaGeneralIdiomas();
  }

  async ListaGeneralIdiomas() {
    await fetch(Globales.Url + "Idiomas/Idiomas/ListaGeneralIdiomas", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.NotshowNotification();
        } else {
          if(data.data.length >1){
            this.setState({ 
              ListaIdiomas: data.data,
              Cargando: false
            })
          }else{
            let aux = [data.data]
            this.setState({ 
              ListaIdiomas: aux,
              Cargando: false
            })
        }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ListaGeneralConceptos(_Idioma) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(
      Globales.Url + "Catalogos/ConceptoPago/ListaGeneralConceptosPago",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({
          _idIdioma: _Idioma,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.ListaConceptoPago.ConceptoPago)
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.NotshowNotification();
        } else {
          const TipoData = Array.isArray(data.ListaConceptoPago.ConceptoPago);
          const ConceptosData = TipoData
            ? data.ListaConceptoPago.ConceptoPago
            : [{ ...data.ListaConceptoPago.ConceptoPago }];
          if (ConceptosData.length > 0) {
            this.setState({
              ListaConceptos: ConceptosData,
              ListaConceptosTemp: ConceptosData,
              Activo: ConceptosData.length,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          } else {
            this.setState({
              ListaConceptos: [],
              ListaConceptosTemp: [],
              Activo: 0,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
              colorNotificacion: "danger",
              mensaje: "Aun no cuenta con conceptos registrados",
            });
            this.showNotification();
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "NombreConcepto") {
      this.setState({
        _idIdioma: value,
        FiltrarNombres: "",
        ConceptoSeleccionado: "",
        ListaConceptos: [],
        ListaConceptosTemp: [],
      });
      if (value) {
        this.ListaGeneralConceptos(value);
      }
    }
    if (name === "FiltrarNombres") {
      const Dataorg = this.state.ListaConceptosTemp;
      if (value) {
        const NuevaData = Dataorg.filter((Concepto) =>
          String(Concepto.Concepto)
            .toUpperCase()
            .includes(String(value).toUpperCase())
        );
        
        if(NuevaData.length === 0){
          this.setState({
            FiltrarNombres: value,
            ListaConceptos: NuevaData,
            colorNotificacion: "danger",
            mensaje: "No se encontraron coincidencias",
          });
          this.showNotification();
        }
        else{
          this.setState({
            FiltrarNombres: value,
            ListaConceptos: NuevaData,
          });
        }
      } else {
        this.setState({
          FiltrarNombres: "",
          ListaConceptos: [...Dataorg],
        });
      }
    }
  };

  eventoModalEliminar(e) {
    this.setState({ show: true, ConceptoSeleccionado: e });
    return;
  }

  eventoModalPregunta = (valor) => {
    if (valor) {
      this.eliminarConcepto(this.state.ConceptoSeleccionado);
    } else {
      this.setState({ show: false });
    }
  };

  eliminarConcepto(id) {
    fetch(
      Globales.Url + "Catalogos/ConceptoPago/EliminarInformacionConceptoPago",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({ _id: id }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger" });
        } else {
          //Obtuvo los registros correctamente
          this.setState({ colorNotificacion: "success" });
        }
        this.setState({
          mensaje: data.mensaje
            ? data.mensaje
            : "Ocurrio un error, comunicarse con el administrador del sistema.",
          show: false,
          entroEliminar: true,
        });
        this.showNotification();
        this.ListaGeneralConceptos(this.state._idIdioma);
      });
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  NotshowNotification() {
    var x = [];
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  eventoModalConceptoDePago = (mensaje, Color) => {
    this.setState({showAgregar: false})
    //Cambiar valores por default
    if(mensaje !== "esc"){
      this.setState({
        Titulo: "Nuevo Concepto",
        mensaje: mensaje,
        NombreConcepto: "",
        colorNotificacion: Color,
        op: 0,
        entroEliminar: true,
      });
      this.showNotification();
      this.ListaGeneralConceptos(this.state._idIdioma);
      }
  };

  openModalNuevoConcepto = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Registrar nuevo concepto",
      NombreConcepto: "",
      op: 0,
    });
  };

  openModalEditarConceptos = (e) => {
    //Cambiar valores por default
    this.setState({
      InfoConcepto: e,
      Titulo: "Editar Concepto",
      op: 1,
      showAgregar: true,
    });
  };

  render() {
    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }

    if (this.state.showAgregar == true) {
      agregar = (
        <ModalConceptoDePago
          xs={18}
          Titulo={this.state.Titulo}
          InfoConcepto={this.state.InfoConcepto}
          Conceptos={this.state.ListaConceptosTemp}
          _idIdioma={this.state._idIdioma}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalConceptoDePago}
          op={this.state.op}
        />
      );
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text={true}>
                <h4 className={classes.cardTitle}>Filtros</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl
                      // fullWidth
                      className={classes.formControl}
                      size="small"
                      //style={{ marginBottom: "27px" }}
                      variant="outlined"
                    >
                      <InputLabel>Idioma</InputLabel>
                      <Select
                        value={this.state._idIdioma}
                        onChange={this.handleChange}
                        name="NombreConcepto"
                        size="small"  
                        style={{ width: "27.6em", marginBottom: "10px" }}
                        label="Idioma"
                        id="_id"
                        defaultValue={""}
                      >
                        <MenuItem disabled>Seleccione un idioma</MenuItem>
                        {this.state.ListaIdiomas.map((idioma, Index) => (
                          <MenuItem
                            key={Index + idioma.Nombre}
                            value={idioma._id}
                          >
                            {idioma.Nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} />
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      id="FiltrarNombres"
                      style={{ width: "23vw" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Nombre del concepto de pago"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        onChange: this.handleChange.bind(this),
                        value: this.state.FiltrarNombres,
                        name: "FiltrarNombres",
                        id: "FiltrarNombres",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button
                      size="sm"
                      type="submit"
                      color="info"
                      round
                      className="pull-right"
                      disabled={!this.state._idIdioma}
                      onClick={() => this.openModalNuevoConcepto()}
                    >
                      Nuevo
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de Conceptos</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4
                      className="pull-right"
                      style={{ marginRight: "15px", marginTop: 0 }}
                    >
                      {this.state.ListaConceptos.length} Concepto(s)
                    </h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="center">Concepto</th>
                        <th className="center">Niño</th>
                        <th className="center">Adolescente</th>
                        <th className="center">Adulto</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ListaConceptos.map((Concepto, index) => {

                        let NombreVariable = "";
                        let cobro = 0;

                        switch (Concepto.Concepto) {
                          case "Colegiatura":
                            NombreVariable = Concepto.Concepto;
                            cobro = "-"
                            break;
                          case "CursoVerano":
                            NombreVariable = "Curso de verano";
                            break;
                          case "CursoTOEFL":
                            NombreVariable = "Curso TOEFL";
                            cobro = convertirPesos(Concepto.CobroUnico)
                            break;
                          case "Particular":
                            NombreVariable = "Clase particular";
                            cobro = convertirPesos(Concepto.CobroUnico)
                            break;
                          default:
                            NombreVariable = Concepto.Concepto;
                            break;
                        }

                        return (
                          <tr key={Concepto._id + "-" + index}>
                            <td onClick={() =>this.openModalEditarConceptos(Concepto)} style={{verticalAlign: "middle", cursor: "default"}}>{NombreVariable}</td>
                            <td onClick={() =>this.openModalEditarConceptos(Concepto)} style={{verticalAlign: "middle", cursor: "default"}}>
                              {Concepto.CobroClases
                                ? convertirPesos(
                                    Number(
                                      Concepto.CobroClases.CobroClase[2].Costo
                                    )
                                  )
                                : cobro}
                            </td>
                            <td onClick={() =>this.openModalEditarConceptos(Concepto)} style={{verticalAlign: "middle", cursor: "default"}}>
                              {Concepto.CobroClases
                                ? convertirPesos(
                                    Number(
                                      Concepto.CobroClases.CobroClase[1].Costo
                                    )
                                  )
                                : cobro}
                            </td>
                            <td onClick={() =>this.openModalEditarConceptos(Concepto)} style={{verticalAlign: "middle", cursor: "default"}}>
                              {Concepto.CobroClases
                                ? convertirPesos(
                                    Number(
                                      Concepto.CobroClases.CobroClase[0].Costo
                                    )
                                  )
                                : cobro}
                            </td>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>
                            <Button
                                color="danger"
                                round
                                justIcon
                                className="pull-right"
                                size="sm"
                                onClick={() =>
                                  this.eventoModalEliminar(Concepto._id)
                                }
                              >
                                <Close />
                              </Button>
                              <Button
                                color="info"
                                round
                                justIcon
                                className="pull-right mr-2"
                                size="sm"
                                onClick={() =>
                                  this.openModalEditarConceptos(Concepto)
                                }
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar Concepto"}
            Cuerpo={"¿Está seguro de dar de baja el Concepto?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatConceptoPago);
