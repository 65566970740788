import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalEditarColaborador from "./ModalEditarColaborador.jsx";
import Globales from "utils/Globales";
import { Edit, GetApp, InfoTwoTone, Description, AddCircle, Undo, Close, Unarchive, Search, Clear, Restore, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
import ModalDocumentos from './ModalDocumentos.jsx'

class Colaboradores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: '',    
            EstadoColaborador: "Activo",
            TipoColaborador: "Todos",
            FiltrarNombres: "",
            ListaColaboradores: [],
            ListaGrupos: [],
            ListaColaboradoresTemp: [],
            ColaboradorSeleccionado: {},
            PDFMaestro: null,
            Disabled: false,
            mensaje: "",
            br: false,
            show: false,
            cargando: false,
            CheckAll: false,
            colorNotificacion: 'success',
            colorTableRow: "white",
            errorIDIdioma: false,
            entroFiltrar: false,
            notificacion: false,
            Total: 0,
            TotalSeleccionado: 0,
            Titulo: "",
            Cuerpo: "",
            op:0,
            CuerpoCargando: "Cargando",
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
        //this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.ListaGeneralMaestrosEstado(this.state.EstadoColaborador);
  }

    //Función para pasar datos de los alumnos desde el modal hacia la vista
    RegresarMensaje = (mensaje) => {
        this.setState({ 
            mensaje: mensaje, 
            colorNotificacion: "success",
            EstadoColaborador: "Activo",
            TipoColaborador: "Todos",
            FiltrarNombres: "",
        });
        this.showNotification();
        this.ListaGeneralMaestrosEstado(this.state.EstadoColaborador);
    }

    async ListaGeneralMaestrosEstado(Estado) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Colaboradores/Colaboradores/ListaGeneralMaestrosEstado', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({Estado: Estado})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    //console.log(data.ListaColaboradores)
                    let aux = [];
                    if(Estado == "Inactivo"){
                        if (data.ListaColaboradores.length > 1) {
                            data.ListaColaboradores.map(colaborador => {
                                if(colaborador.EstadoActivo !== "Inactivo"){
                                    aux.push(colaborador)
                                }
                            })

                            this.setState({ ListaColaboradores: aux, ListaColaboradoresTemp: aux, cargando: false });
                        }
                        else {
                            let ListaAux = [];
                            if(data.ListaColaboradores.EstadoActivo !== "Inactivo"){
                                ListaAux.push(data.ListaColaboradores);
                            }
                            
                            this.setState({ ListaColaboradores: ListaAux, ListaColaboradoresTemp: ListaAux, cargando: false });
                        }
                    }
                    else{
                        if (data.ListaColaboradores.length > 1) {
                            this.setState({ ListaColaboradores: data.ListaColaboradores, ListaColaboradoresTemp: data.ListaColaboradores, cargando: false });
                        }
                        else {
                            let ListaAux = [];
                            ListaAux.push(data.ListaColaboradores);
                            //console.log(ListaAux);
                            this.setState({ ListaColaboradores: ListaAux, ListaColaboradoresTemp: ListaAux, Activo: 1, cargando: false });
                        }
                    }
                    
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async EliminarInformacionMaestro(idColaborador) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Colaboradores/Colaboradores/EliminarInformacionMaestro', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({id: idColaborador})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    //console.log(data.ListaColaboradores)
                    this.setState({cargando: false, TipoColaborador: "Todos", mensaje: "El colaborador se dio de baja correctamente", colorNotificacion: "success", FiltrarNombres: ""});
                    this.showNotification();
                    this.ListaGeneralMaestrosEstado("Activo");
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async DesactivarInformacionMaestro(idColaborador) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Colaboradores/Colaboradores/DesactivarInformacionMaestro', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({id: idColaborador})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    //console.log(data.ListaColaboradores)
                    this.setState({cargando: false, TipoColaborador: "Todos", mensaje: data.mensaje, colorNotificacion: "success", FiltrarNombres: ""});
                    this.showNotification();
                    this.ListaGeneralMaestrosEstado("Inactivo");
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async PonerActivoMaestro(idColaborador) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Colaboradores/Colaboradores/PonerActivoMaestro', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({id: idColaborador})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    //console.log(data.ListaColaboradores)
                    this.setState({cargando: false, TipoColaborador: "Todos", mensaje: data.mensaje, colorNotificacion: "success", FiltrarNombres: ""});
                    this.showNotification();
                    this.ListaGeneralMaestrosEstado("Inactivo");
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async ReporteMaestro(idMaestro) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Colaboradores/Colaboradores/ReporteMaestro', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({id: idMaestro})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    //console.log(data.PDFMaestro)
                    this.DescargarReporte(data.PDFMaestro);
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async ListaInformacionGruposMaestros(Colaborador) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Colaboradores/Colaboradores/ListaInformacionGruposMaestros', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({id: Colaborador._idUsuario, estado: 0})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log("API", data.ListaGrupos);
                if (data.error) { //Error en la respuesta
                    this.setState({cargando: false})
                    this.openModalPregunta(Colaborador._id, "Colaboradores", "¿Está seguro de dar de baja al colaborador?");
                } else { //Filtrado exitoso
                //console.log(data.ListaGrupos)
                  if(data.ListaGrupos.length > 1){
                    let aux = [];
                    data.ListaGrupos.map (grupo => {
                      if(grupo.Activo == "true"){
                        aux.push(grupo)
                      }
                    })

                    if(aux.length !== 0){
                        this.setState({ListaGrupos: aux, mensaje: "No es posible dar de baja a maestros con grupos asignados", colorNotificacion: "danger", cargando: false});
                        this.showNotification();
                    }
                    else{
                        this.setState({cargando: false})
                        this.openModalPregunta(Colaborador._id, "Colaboradores", "¿Está seguro de dar de baja al colaborador?");
                    }
                  }
                  else{
                    if(data.ListaGrupos.Activo == "true"){
                        let aux = [];
                        let idAux = [];
                        aux.push(data.ListaGrupos);
                        idAux.push(data.ListaGrupos._id);
                        this.setState({ListaGrupos: aux, mensaje: "No es posible dar de baja a maestros con grupos asignados", colorNotificacion: "danger", cargando: false});
                        this.showNotification();
                    }
                    else{
                        this.setState({cargando: false})
                        this.openModalPregunta(Colaborador._id, "Colaboradores", "¿Está seguro de dar de baja al colaborador?");
                    }
                  }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
      }

    DescargarReporte(archivoBase64) {
        const url = 'data:application/pdf;base64,' + archivoBase64;
    
        let nombre = 'Reporte maestro'
        const linkSource = `${url}`;
        const downloadLink = document.createElement("a");
        const fileName = `${nombre}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    
        this.setState({
          cargando: false,
          mensaje: "Reporte generado exitosamente",
          colorNotificacion: "success",
        });
        this.showNotification();
      }

    ValidarSiTieneGrupo(Colaborador){
        const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
        if(JSON.stringify(Colaborador._idUsuario) !== JSON.stringify(idVacio)){
            this.ListaInformacionGruposMaestros(Colaborador)
        }
        else{
            this.openModalPregunta(Colaborador._id, "Colaboradores", "¿Está seguro de dar de baja al colaborador?");
        }


        // if(grupos !== null && grupos !== undefined){
        //     this.setState({mensaje: "No es posible dar de baja a maestros con grupos asignados", colorNotificacion: "danger"})
        //     this.showNotification();
        // }
        // else{
        //     this.openModalPregunta(idColaborador, "Colaboradores", "¿Está seguro de dar de baja al colaborador?");
        // }
    }

//Funciones para abrir modales

    openModalNuevoColaborador = () => {
        this.setState({  showModalEditarColaborador: true, Titulo: "...", op: 0 });
    }

    openModalEditarColaborador = (colaborador) => {
        this.setState({ ColaboradorSeleccionado: colaborador, showModalEditarColaborador: true, Titulo: "...", op: 1 });
    }

    openModalPregunta = (id, tiutlo, cuerpo, op) => {
        this.setState({ _id: id, Titulo: tiutlo, Cuerpo: cuerpo,  show: true, op: op });
    }

    openshowModalDocumentos = (maestro) => {
        this.setState({ ColaboradorSeleccionado: maestro, showModalDocumentos: true})
    }

//Funciónes que recuperan valores para los modales

    eventoModalEditarColaborador = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showModalEditarColaborador: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalPregunta = (valor) => {
        this.setState({ show: false })
        if(valor){
            if(this.state.EstadoColaborador == "Activo"){
                this.EliminarInformacionMaestro(this.state._id);
            }
            else if (this.state.EstadoColaborador == "Inactivo"){
                if(this.state.op == 0){
                    this.DesactivarInformacionMaestro(this.state._id)
                }
                else{
                    this.PonerActivoMaestro(this.state._id)
                }
                
            }
            
        }
    }

    eventoModalDocumentos = (valor) => {
        //Cambiar valores por default
        this.setState({ showModalDocumentos: false, op: 0 })
        if (valor) {
            this.setState({ mensaje: "El reporte se generó exitosamente", colorNotificacion: "success" })
            this.showNotification();
        }
    }

    //Función que habilita la notificación
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        // console.log(value);

        if(name == "FiltrarNombres"){
            if(value != ""){
                let ListaColaboradoresTemp = this.state.ListaColaboradoresTemp
                if(this.state.TipoColaborador !== "Todos"){
                    ListaColaboradoresTemp = this.state.ListaColaboradoresTemp.filter(e => ((e.Tipo).toUpperCase()) == (value).toUpperCase());
                }
                let aux = ListaColaboradoresTemp.filter(e => ((e.NumeroCuenta + " - " + e.NombreCompleto).toUpperCase()).includes((value).toUpperCase()));
                this.setState({ [name]: value, ListaColaboradores: aux});
            }
            else{
                this.setState({ [name]: value, ListaColaboradores: this.state.ListaColaboradoresTemp });
            }
        }

        if(name == "EstadoColaborador"){
            this.setState({ 
                [name]: value,
                TipoColaborador: "",
                FiltrarNombres: "",
            });
            this.ListaGeneralMaestrosEstado(value);
        }

        if(name == "TipoColaborador"){
            this.setState({ListaColaboradores: this.state.ListaColaboradoresTemp}, () => {
                if(this.state.TipoColaborador !== "Todos"){
                    let aux = this.state.ListaColaboradores.filter(e => ((e.Tipo).toUpperCase()) == (value).toUpperCase());

                    this.setState({ 
                        [name]: value,
                        FiltrarNombres: "",
                        ListaColaboradores: aux
                    });
                }
            })

                     
        }

        this.setState({ [name]: value });
    }

    render() {
    const { classes } = this.props;

    //console.log(this.state.ListaGrupos)

        let agregar;
        if (this.state.showModalEditarColaborador == true) {
            agregar =
                <ModalEditarColaborador
                    Titulo={this.state.Titulo}
                    showModalEditarColaborador={this.state.showModalEditarColaborador}
                    eventClick={this.eventoModalEditarColaborador}
                    op={this.state.op}
                    ColaboradorSeleccionado={this.state.ColaboradorSeleccionado}
                    parentCallback={this.RegresarMensaje}
                />
        }

        if (this.state.cargando == true) {
            agregar = (
              <ModalCargando
                Cuerpo={this.state.CuerpoCargando}
                show={this.state.cargando}
              />
            );
          };

          if(this.state.showModalDocumentos == true){
            agregar = (
                <ModalDocumentos
                    Titulo={"Impresión de documentos"}
                    showModalDocumentos={this.state.showModalDocumentos}
                    eventClick={this.eventoModalDocumentos}
                    ColaboradorSeleccionado={this.state.ColaboradorSeleccionado}
                    EstadoColaborador={this.state.EstadoColaborador}
                />
            )
        }

    let colaboradores;
    let Botones;
    if(this.state.ListaColaboradores.length > 0){
        colaboradores =
            this.state.ListaColaboradores.map(colaboradores => {

                if(this.state.EstadoColaborador == "Activo"){
                    Botones=
                    <td>
                        <Button data-toggle="tooltip" data-placement="bottom" title="Dar de baja al colaborador" color="danger" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.ValidarSiTieneGrupo(colaboradores)}><Close /></Button>
                        <Button data-toggle="tooltip" data-placement="bottom" title="Editar colaborador" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalEditarColaborador(colaboradores)}><Edit /></Button>
                        <Button data-toggle="tooltip" data-placement="bottom" title="Descargar información del colaborador" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.ReporteMaestro(colaboradores._id)}><GetApp /></Button>
                        {colaboradores.Tipo == "Maestro" ? <Button data-toggle="tooltip" data-placement="bottom" title="Descargar documentos" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openshowModalDocumentos(colaboradores._id)}><Description /></Button> : ""}
                       
                    </td>
                }
                else if(this.state.EstadoColaborador == "Inactivo"){
                    Botones =
                    <td>
                        <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar colaborador" color="danger" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalPregunta(colaboradores._id, "Colaboradores", "¿Está seguro que desea eliminar al colaborador?", 0)}><Close /></Button>
                        <Button data-toggle="tooltip" data-placement="bottom" title="Reactivar colaborador" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalPregunta(colaboradores._id, "Colaboradores", "¿Está seguro que desea reactivar al colaborador?", 1)}><Restore /></Button>
                        <Button data-toggle="tooltip" data-placement="bottom" title="Descargar información del colaborador" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.ReporteMaestro(colaboradores._id)}><GetApp /></Button>
                    </td>
                }



                return(
                    <tr style={{ cursor: "default"}} hover>
                        <td onClick={() => this.openModalEditarColaborador(colaboradores)} style={{verticalAlign: "middle"}}>{colaboradores.NumeroCuenta}</td>
                        <td onClick={() => this.openModalEditarColaborador(colaboradores)} style={{verticalAlign: "middle"}}>{colaboradores.NombreCompleto}</td>
                        <td onClick={() => this.openModalEditarColaborador(colaboradores)} style={{verticalAlign: "middle"}}>{colaboradores.Tipo}</td>
                        <td onClick={() => this.openModalEditarColaborador(colaboradores)} style={{verticalAlign: "middle"}}>{colaboradores.Telefono !== null && colaboradores.Telefono !== null ? colaboradores.Telefono : ""}</td>
                        <td onClick={() => this.openModalEditarColaborador(colaboradores)} style={{verticalAlign: "middle"}}>{colaboradores.Correo !== null && colaboradores.Correo !== null ? colaboradores.Correo : ""}</td>
                        {Botones}
                    </tr>
                )
            })
    }
    else{
        colaboradores=
        <tr>
            <td style={{textAlign: "center", marginTop: "10px"}} colSpan={5}>NO HAY COLABORADORES</td>
        </tr>
    }


// console.log(this.state.ListaColaboradores)
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Colaboradores</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                    <FormControl className={classes.formControl} size="small" variant="outlined">
                                        <InputLabel style={{marginBottom: "10px"}} id="estadolabel">Estado colaborador</InputLabel>
                                            <Select
                                                labelId="estadolabel"
                                                //fullWidth={true}
                                                value={this.state.EstadoColaborador}
                                                onChange={this.handleChange.bind(this)}
                                                name="EstadoColaborador"
                                                //fullWidth={true}
                                                label="Estado colaborador"
                                                style={{ width: "15em", marginBottom: "10px" }}
                                                //error={this.state.errorIDIdioma}
                                                //success={!this.state.errorIDIdioma}
                                            >
                                                <MenuItem disabled>Seleccione un estado</MenuItem>
                                                <MenuItem value={"Activo"}>Activo</MenuItem>
                                                <MenuItem value={"Inactivo"}>Inactivo</MenuItem>
                                            </Select>
                                    </FormControl>
                                    <FormControl className={classes.formControl} size="small" variant="outlined">
                                    <InputLabel style={{marginBottom: "10px", marginLeft: "10px"}} id="Tipolabel">Tipo</InputLabel>
                                        <Select
                                            labelId="Tipolabel"
                                            //fullWidth={true}
                                            value={this.state.TipoColaborador}
                                            onChange={this.handleChange.bind(this)}
                                            name="TipoColaborador"
                                            //fullWidth={true}
                                            label="Tipo"
                                            style={{ width: "12em", marginBottom: "10px", marginLeft: "10px" }}
                                            //error={this.state.errorIDIdioma}
                                            //success={!this.state.errorIDIdioma}
                                        >
                                            <MenuItem value={"Todos"}>Todos</MenuItem>
                                            <MenuItem value={"Maestro"}>Maestro</MenuItem>
                                            <MenuItem value={"Administrativo"}>Administrativo</MenuItem>
                                            <MenuItem value={"Dirección"}>Dirección</MenuItem>
                                            <MenuItem value={"Secretaría"}>Secretaría</MenuItem>
                                            <MenuItem value={"Intendencia"}>Intendencia</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                            id="FiltrarNombres"
                                            style={{ width: "23vw"}}
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            label="Matricula o nombre"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search />
                                                    </InputAdornment>
                                                    ),
                                                onChange: this.handleChange.bind(this),
                                                value: this.state.FiltrarNombres,
                                                name: "FiltrarNombres",
                                                id: "FiltrarNombres",
                                                //error: this.state.errorMotivoCantacto,
                                                //success: !this.state.errorMotivoCantacto
                                            }}
                                            />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Button size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.openModalNuevoColaborador()}><AddCircle/>&nbsp;Nuevo</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader color="info" text>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <h4 className="pull-left">Colaboradores</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                    <h4 className="pull-right" style={{marginRight: "15px", marginTop: 0}}>{this.state.ListaColaboradores.length} Colaborador(es)</h4>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="table-responsive-xl table-wrapper-scroll-y">
                                            <table className="table table-sm table-hover">
                                                <thead>
                                                    <th>No. Cuenta</th>
                                                    <th>Nombre completo</th>
                                                    <th>Tipo</th>
                                                    <th>Teléfono</th>
                                                    <th>Correo</th>
                                                    <th></th>
                                                </thead>
                                                <tbody>
                                                {colaboradores}
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <ModalPregunta
                        Titulo={this.state.Titulo}
                        Cuerpo={this.state.Cuerpo}
                        show={this.state.show}
                        eventClick={this.eventoModalPregunta}
                    />
                    {agregar}
                </div>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(Colaboradores);