import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, Add, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';


class ModalAsignarFolioFactura extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      ConceptoPago: "",
      ArrayIdVentas: [],
      ArrayIdVentasVirtual: [],
      ArrayFolios: {},
      Folios: "",
      TotalSeleccioado: 0,
      MostrarModal:false,
      _id: "",
      disabled:false,
      errorFolios: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      cargando: false,
      CuerpoCargando: "Cargando",

      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    // if (event.keyCode === 27) {
    //   this.props.eventClick("esc", "esc");
    // }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    if(this.props.op == 1){
      this.setState({
        ArrayIdVentas: this.props.ArrayIdVentas,
        ArrayIdVentasVirtual: this.props.ArrayIdVentasVirtual,
        TotalSeleccioado: this.props.TotalSeleccioado
      })
    }
    //Verificar si es editar
    //console.log(this.props)
    }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  /* ValidarInfo(){
    let valor1 = 0;
    let valor2 = 0;
    let valor3 = 0;

    if(this.state.ConceptoPago == ""){
      this.setState({errorConceptoPago: true ,mensaje: "Proporcione el concepto de pago", colorNotificacion: "danger" })
      this.showNotification();
      valor1 = 1;
    }else{ 
      valor1 = 0; 
      this.setState({errorConceptoPago: false})
    }

    if(this.state.Monto == ""){
      this.setState({errorMonto: true ,mensaje: "Proporcione una cantidad válida", colorNotificacion: "danger" })
      this.showNotification();
      valor2 = 1;
    }
    else{
      this.setState({errorMonto: false})
      valor2 = 0;
    }

    if(this.state.Fecha == ""){
      this.setState({errorFecha: true ,mensaje: "Complete el campo de fecha", colorNotificacion: "danger" })
      this.showNotification();
      valor3 = 1;
    }
    else{
      this.setState({errorFecha: false})
      valor3 = 0;
    }

    if(valor1 + valor2 + valor3 == 0){
      this.setState({
        Venta: {
          Banco: this.state.Banco,
          Fecha: this.state.Fecha,
          MotivosCancelacion: this.state.ConceptoPago,
          NumeroCheque: this.state.NoCheque,
          NumeroCuenta: this.state.NoCuenta,
          NumeroFolio: this.state.NumFolio,
          NumeroMovimiento: this.state.NumMovimiento,
          Subtotal: parseFloat(this.state.Monto),
          TipoPago: this.state.TipoPago,
          Total: parseFloat(this.state.Monto),
          VentaVirtual: true,
        }
      },()=>{
        this.obtenerListaDetallePagos(this.state.Venta)
      })
    }
  } */

  //Obtener ventas totales por periodo---------------------------------------------------------------------------------------------------------------------------------------------------------
  AsignarFolioFactura(){
    let valor = 0;

    if(this.state.Folios == ""){
      this.setState({errorFolios: true ,mensaje: "Proporcione un folio", colorNotificacion: "danger" })
      this.showNotification();
      valor = 1;
    }else{ 
      valor = 0; 
      this.setState({errorFolios: false})
    }

    if(valor == 0){
    this.setState({cargando: true})
    fetch(Globales.Url + 'Facturacion/Facturacion/ActualizarFolioFacturaVariasVenta', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      ,body: JSON.stringify({folios: this.state.Folios, idVentas: this.state.ArrayIdVentas, VentasV: this.state.ArrayIdVentasVirtual})
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger', ListaAlumnos:[], cargando: false });
          this.showNotification();
         } else { //Filtrado exitoso
            //console.log(data.ListaAlumnos);
            this.setState({colorNotificacion: 'success', mensaje: data.mensaje, cargando: false });
            this.MandarMensaje(data.mensaje)
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));; 
    }
  }

  //Mandar mensaje a facturacion principal fuera del modal
  MandarMensaje(mensaje) {
    this.setState({mensaje:mensaje, op: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick('esc');
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value} = e.target;
      this.setState({[name]: value});
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    const { classes } = this.props;

    let agregar;
    if(this.state.cargando){
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      )
    }

    //console.log(this.state.ArrayIdVentas)
    //console.log(this.state.ArrayIdVentasVirtual)
    //console.log("Fecha inicio", this.state.VentasTotalesPorPeriodo)
    return (
      <div>
        <Modal
          isOpen={this.props.showAsignarFactura}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={3}>
                  <div className="mb-2">RECIBOS:</div>
                  <div className="mb-2">TOTAL:</div>
                  <div>FOLIO:</div>
                </GridItem>
                <GridItem xs={4}>
                  <div className="mb-2">{this.state.ArrayIdVentas.length + this.state.ArrayIdVentasVirtual.length}</div>
                  <div className="mb-2">{convertirPesos(this.state.TotalSeleccioado)}</div>
                  <div>
                    <TextField
                      id="Folios"
                      style={{ width: "15em" }}
                      variant="outlined"
                      size="small"
                      label="FOLIO DE FACTURA"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.Folios,
                        name: "Folios",
                        id: "Folios",
                        error: this.state.errorFolios,
                        success: !this.state.errorFolios
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridItem>
            <Button
              tabIndex="0"
              id="btnGuardar"
              size="sm"
              color="success"
              className="pull-right  mb-3"
              round
              onClick={() => this.AsignarFolioFactura()}
            >
              <Save />
              &nbsp;ASIGNAR
            </Button>
            <Button
              tabIndex="1"
              size="sm"
              color="danger"
              className="pull-right mr-2 mb-3"
              round
              onClick={() => this.props.eventClick("esc")}
            >
              <Clear />
              &nbsp;CERRAR
            </Button>
          </GridItem>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAsignarFolioFactura);