import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, Add, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';


class ModalAsignarFolioFactura extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      errorFolios: false,
      colorNotificacion: "success",
      mensaje: "",
      Correo: "",
      br: false,
      DesactivarBoton:false,
      cargando: false,
      CuerpoCargando: "Cargando",

      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick(false);
    }
  }

  async componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    // console.log(this.props)
    this.setState({
      uuid: this.props.uuid,
    }, async() => {
      await this.ObtenerXml(this.props.uuid)
    })
    await this.ObtenerInformacionDatoFiscalPorRFC(this.props.RFC)
    await this.ObtenerConfiguracion()
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }


  async ObtenerConfiguracion() {
    this.setState({ cargando: true});
      await fetch(Globales.Url + "Configuracion/Configuracion/ObtenerConfiguracion", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({}),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje,colorNotificacion: "danger",cargando: false});
            this.showNotification();
          } else {
            let ssl;
            let config = {};

            if(data.Configuracion.SSL == "false"){
              ssl = false;
            }
            else if(data.Configuracion.SSL == "true"){
              ssl = true;
            }

            config = {
              Contraseña: data.Configuracion.Contrasena !== null ? data.Configuracion.Contrasena : "",
              Correo: data.Configuracion.Correo !== null ? data.Configuracion.Correo : "",
              CorreoConfirmacion: data.Configuracion.CorreoConfirmacion !== null ? data.Configuracion.CorreoConfirmacion : "",
              Nombreservidor: data.Configuracion.Servidor !== null ? data.Configuracion.Servidor : "",
              Puerto: data.Configuracion.Puerto == "0" ? "" : parseInt(data.Configuracion.Puerto),
              SSL: ssl
            }

            this.setState({Configuracion: config, cargando: false});
          }
        });
  }
  async ObtenerXml(uuid) {
    this.setState({ cargando: true })
      fetch(Globales.Url + 'archivos/ObtenerXmlFactura', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ 
        uuid,
      })
    }
    ).then(res => res.json())
      .then(data => {
        // this.setState({ cargando: false })
        // return
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          // this.setState({ xml: 'data:application/xml;base64,' +data.data, });
          this.setState({ xml:'data:application/xml;base64,' + data.data, });
          this.ImprimirPDF(data.data,uuid)
          // this.setState({ mensaje: "Se cancelo la factura exitosamente", colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          // this.showNotification();
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async ImprimirPDF(xml,uuid,Notas) {
    this.setState({ cargando: true })
    // fetch(Globales.Url + 'archivos/guardarXmlFactura', {
      fetch(Globales.Url + 'Facturacion/Facturas/ImprimirFactura', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ 
        xml: xml,
        NotasRecibo:this.props.Notas,
        uuid,
      })
    }
    ).then(res => res.json())
      .then(data => {
        // this.setState({ cargando: false })
        // return
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          // this.GuardarDocumentos(data.data, uuid)
          // this.setState({ pdf: 'data:application/pdf;base64,' +data.data, cargando: false  });
          this.setState({ pdf: data.data, cargando: false  });
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }
  
  async ObtenerInformacionDatoFiscalPorRFC(rfc) {
    this.setState({ cargando: true })
    // fetch(Globales.Url + 'archivos/guardarXmlFactura', {
      fetch(Globales.Url + 'Facturacion/Facturas/ObtenerInformacionDatoFiscalPorRFC', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ 
        rfc,
      })
    }
    ).then(res => res.json())
      .then(data => {
        // this.setState({ cargando: false })
        // return
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          // console.log(data.data);
          if(data.data){
            this.setState({ Correo: data.data.Correo, cargando: false  });
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }
  async EnviarCorreo() {
    this.setState({ cargando: true,errorCorreo: false  })

    if(this.state.Correo == ""){
      this.setState({ mensaje: "ingrese un correo para el envio de factura", colorNotificacion: "danger", errorCorreo: true, cargando: false });
      this.showNotification();
      return
    }

    fetch(Globales.Url + 'EnvioCorreos/EnvioCorreos/EnviarFactura', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        xml: this.state.xml,
        pdf: this.state.pdf,
        uuid: this.state.uuid,
        Correo: this.state.Correo,
        Configuracion: this.state.Configuracion,
      })
    }
    ).then(res => res.json())
      .then(data => {
        // this.setState({ cargando: false })
        // return
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.props.eventClick(true);
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }
  //Mandar mensaje a facturacion principal fuera del modal
  MandarMensaje(mensaje) {
    this.setState({mensaje:mensaje, op: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick(false);
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value} = e.target;
      this.setState({[name]: value});
  }

  cerrarModal = () => {
    this.props.eventClick(false)
  }
 
  render() {
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    const { classes } = this.props;

    let agregar;
    if(this.state.cargando){
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      )
    }

    //console.log(this.state.ArrayIdVentas)
    //console.log(this.state.ArrayIdVentasVirtual)
    //console.log("Fecha inicio", this.state.VentasTotalesPorPeriodo)
    return (
      <div>
        <Modal
          isOpen={this.props.show}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">Envio de factura por correo</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <div>
                    <TextField
                    fullWidth
                      id="Folios"
                      variant="outlined"
                      size="small"
                      label="Correo"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.Correo,
                        name: "Correo",
                        id: "Correo",
                        error: this.state.errorCorreo,
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridItem>
            <Button
              tabIndex="0"
              id="btnGuardar"
              size="sm"
              color="success"
              className="pull-right  mb-3"
              round
              onClick={() => this.EnviarCorreo()}
            >
              <Save />
              &nbsp;Enviar
            </Button>
            <Button
              tabIndex="1"
              size="sm"
              color="danger"
              className="pull-right mr-2 mb-3"
              round
              onClick={() => this.props.eventClick(false)}
            >
              <Clear />
              &nbsp;CERRAR
            </Button>
          </GridItem>
        </Modal>



        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAsignarFolioFactura);