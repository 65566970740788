import React from "react";
// core components
import { Modal } from 'reactstrap';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { Clear } from '@material-ui/icons';

import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";

// material-ui icons
import { CloudDownload } from "@material-ui/icons";
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;



class PresupuestoExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            DatosReporte:[],
            PresupuestoInfo:null,
            VerReporte:false,
            IngresosColumnas:[],
            EgresosColumnas:[],
            Columnas:[],
        };

    }

    componentDidMount(){
        this.AjustarDatos(this.props.DatosReporte)
    }

    convertirPesos(number) {
        return new Intl.NumberFormat("ES-MX",{
            style: 'currency',
            currency: 'MXN'
        }).format(number);                  
    }

    AjustarDatos(datos){
        
        //Formar las columnas de los meses
        var columns=[]
        columns.push("")
        columns.push("DETALLE")
        columns.push("ANUAL")
        columns.push("ENERO PRES")
        columns.push("ENERO REAL")
        columns.push("FEBRERO PRES")
        columns.push("FEBRERO REAL")
        columns.push("MARZO PRES")
        columns.push("MARZO REAL")
        columns.push("ABRIL PRES")
        columns.push("ABRIL REAL")
        columns.push("MAYO PRES")
        columns.push("MAYO REAL")
        columns.push("JUNIO PRES")
        columns.push("JUNIO REAL")
        columns.push("JULIO PRES")
        columns.push("JULIO REAL")
        columns.push("AGOSTO PRES")
        columns.push("AGOSTO REAL")
        columns.push("SEPTIEMBRE PRES")
        columns.push("SEPTIEMBRE REAL")
        columns.push("OCTUBRE PRES")
        columns.push("OCTUBRE REAL")
        columns.push("NOVIEMBRE PRES")
        columns.push("NOVIEMBRE REAL")
        columns.push("DICIEMBRE PRES")   
        columns.push("DICIEMBRE REAL")       
        columns.push("EJERCIDO")        
        columns.push("POR EJERCER")        

        //Formar los datos de los ingresos
        var ingresosColumnas=[];
        datos.ListaIngresos.map(ingreso=>{
            var CajaTemporal = [];
            if(ingreso.EsPrincipal){
                CajaTemporal.push({
                    value: ingreso.NombreCategoria,
                    style: {
                        alignment: {
                            horizontal: "left"
                        },
                        font: {
                            bold: true                        
                        }                         
                    }
                });
            }
            else{
                CajaTemporal.push({
                    value: ingreso.NombreCategoria,
                    style: {
                        alignment: {
                            horizontal: "right"
                        },                                           
                    }
                });
            }          
            CajaTemporal.push({
                //value: "$"+(parseFloat(ingreso.Detalle).toFixed(2)).toString(),
                value: this.convertirPesos(ingreso.Detalle).toString(),
                style: {
                    alignment: {
                        horizontal: "right"
                    }                        
                }
            })
            //Colocar en negrita
            if(!ingreso.EsPrincipal){
                CajaTemporal.push({
                    //value: "$"+(parseFloat(ingreso.TotalAnual).toFixed(2)).toString(),
                    value: this.convertirPesos(ingreso.TotalAnual).toString(),
                    style: {
                        alignment: {
                            horizontal: "right"
                        }                        
                    }
                })
            }
            else{
                CajaTemporal.push({
                    //value: "$"+(parseFloat(ingreso.TotalAnual).toFixed(2)).toString(),
                    value: this.convertirPesos(ingreso.TotalAnual).toString(),
                    style: {
                        alignment: {
                            horizontal: "right"
                        },
                        font: {
                            bold: true                        
                        }                            
                    }             
                })
            }
            if(!ingreso.EsPrincipal){
                ingreso.ListaMontosMes.map(valor =>{
                    CajaTemporal.push({
                       // value: "$"+(parseFloat(valor).toFixed(2)).toString(),
                        value: this.convertirPesos(valor).toString(),
                        style: {
                            alignment: {
                                horizontal: "right"
                            }                        
                        }
                    })
                })       
            }
            else{
                if(ingreso.TieneSubcategorias==false){
                    ingreso.ListaMontosMes.map(valor =>{
                        CajaTemporal.push({
                            //value: "$"+(parseFloat(valor).toFixed(2)).toString(),
                            value: this.convertirPesos(valor).toString(),
                            style: {
                                alignment: {
                                    horizontal: "right"
                                }                        
                            }
                        })
                    })       
                }
            }
            ingresosColumnas.push(CajaTemporal)
        })

        //Formar los datos de los egresos
        var egresosColumnas=[];
        datos.ListaEgresos.map(egreso=>{
            var CajaTemporal = []
            if(egreso.EsPrincipal){
                CajaTemporal.push({
                    value: egreso.Nombre,
                    style: {
                        alignment: {
                            horizontal: "left"
                        },
                        font: {
                            bold: true                        
                        }                         
                    }
                });
            }
            else{
                CajaTemporal.push({
                    value: egreso.Nombre,
                    style: {
                        alignment: {
                            horizontal: "right"
                        },                                           
                    }
                });
            }
            CajaTemporal.push({
                //value: "$"+(parseFloat(egreso.Detalle).toFixed(2)).toString(),
                value: this.convertirPesos(egreso.Detalle).toString(),
                style: {
                    alignment: {
                        horizontal: "right"
                    }                        
                }
            })
            if(egreso.EsPrincipal){
                CajaTemporal.push({
                    //value: "$"+(parseFloat(egreso.TotalAnual).toFixed(2)).toString(),
                    value: this.convertirPesos(egreso.TotalAnual).toString(),
                    style: {
                        alignment: {
                            horizontal: "right"
                        }       ,
                        font: {
                            bold: true                        
                        }                  
                    }
                })
            }
            else{
                CajaTemporal.push({
                    //value: "$"+(parseFloat(egreso.TotalAnual).toFixed(2)).toString(),
                    value: this.convertirPesos(egreso.TotalAnual).toString(),
                    style: {
                        alignment: {
                            horizontal: "right"
                        }                
                    }
                })
            }
            if(!egreso.EsPrincipal){
                egreso.ListaMontosMes.map(valor =>{
                    CajaTemporal.push({
                        //value: "$"+(parseFloat(valor).toFixed(2)).toString(),
                        value: this.convertirPesos(valor).toString(),
                        style: {
                            alignment: {
                                horizontal: "right"
                            }                        
                        }
                    })
                })            
            }   
            else{
                if(egreso.TieneSubcategorias==false){
                    egreso.ListaMontosMes.map(valor =>{
                        CajaTemporal.push({
                            //value: "$"+(parseFloat(valor).toFixed(2)).toString(),
                            value: this.convertirPesos(valor).toString(),
                            style: {
                                alignment: {
                                    horizontal: "right"
                                }                        
                            }
                        })
                    })          
                }
            }
            egresosColumnas.push(CajaTemporal)
        })

        this.setState({
            PresupuestoInfo: datos.Presupuesto,
            IngresosColumnas:ingresosColumnas,
            EgresosColumnas:egresosColumnas,
            Columnas:columns
        },()=>{
            this.EstablecerDatos()
        })
    }

    EstablecerDatos(){

        this.state.DatosReporte.push(
              {
                xSteps: 4, // Will start putting cell with 1 empty cell on left most
                // ySteps: 5, //will put space of 5 rows,
                columns: [],
                data: [
                    [
                        { xSteps: 1 },
                        { xSteps: 1 },
                        {
                            value: "ASOCIACIÓN MEXICANA DE HORTICULTURA PROTEGIDA AC",
                            style: {
                                alignment: {
                                    horizontal: "center"
                                },
                                font: {
                                    sz: "22", bold: true,
                                    color: {
                                        rgb: "0080D7"
                                    }
                                }
                                
                            }
                        },
                        { xSteps: 1 },
                        { xSteps: 1 },
                        { xSteps: 1 },  
                        { xSteps: 1 },  
                        { xSteps: 1 },  
                        { xSteps: 1 },  
                        { xSteps: 1 },    
                        { xSteps: 1 },    
                        { xSteps: 1 },    
                        { xSteps: 1 },    
                    ]                    
                ]                    
            })
            this.state.DatosReporte.push(
                {
                    // xSteps: 1,
                    //ySteps:2,
                    columns: [],
                    //data: this.state.Datos
                        data: [
                            [
                            {
                                value: "NOMBRE:",
                                style: {
                                    alignment: {
                                        horizontal: "left"
                                    },
                                    font: {
                                     bold: true
                                    }                                    
                                }
                            },
                            {
                                value: this.state.PresupuestoInfo.Nombre,
                                style: {
                                    alignment: {
                                        horizontal: "left"
                                    }                                  
                                }
                            }]                           
                        ]                            
                }
            )
            this.state.DatosReporte.push(
                {
                    // xSteps: 1,
                    //ySteps:2,
                    columns: [],
                    //data: this.state.Datos
                        data: [
                            [
                            {
                                value: "AÑO:",
                                style: {
                                    alignment: {
                                        horizontal: "left"
                                    },
                                    font: {
                                     bold: true
                                    }                                    
                                }
                            },
                            {
                                value: this.state.PresupuestoInfo.Año.toString(),
                                style: {
                                    alignment: {
                                        horizontal: "left"
                                    }                                  
                                }
                            }]                           
                        ]                            
                }
            )
            this.state.DatosReporte.push(
                {
                    // xSteps: 1,
                    ySteps:2,
                    columns: this.state.Columnas,
                    //data: this.state.Datos
                        data: []                            
                }
            )
            var listaCoIn=[];
            for (let index = 0; index < 29; index++) {
                if(index==0){
                    listaCoIn.push( {                           
                        value: "INGRESOS",
                        style: { alignment: { horizontal: "center" },fill:{ fgColor: { rgb: "BCBCBC" } } }
                    })
                }
                else{
                    listaCoIn.push({                           
                        value: "",
                        style: { alignment: { horizontal: "center" },fill:{ fgColor: { rgb: "BCBCBC" } } }
                    })
                }
                
            }
            this.state.DatosReporte.push(
                {
                   //ySteps: 0, //will put space of 5 rows,
                  columns: [],
                  data: [
                    listaCoIn                  
                ]      
                                  
              })
              this.state.DatosReporte.push(
                {
                   //ySteps: 0, //will put space of 5 rows,
                  columns: [],
                  data: this.state.IngresosColumnas
                                  
              })

              //Egresos +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
              this.state.DatosReporte.push(
                {
                    // xSteps: 1,
                    ySteps:2,
                    columns: this.state.Columnas,
                    //data: this.state.Datos
                        data: []                            
                }
            )
            var listaCoEgre=[];
            for (let index1 = 0; index1 < 29; index1++) {
                if(index1==0){
                    listaCoEgre.push( {                           
                        value: "EGRESOS",
                        style: { alignment: { horizontal: "center" },fill:{ fgColor: { rgb: "BCBCBC" } } }
                    })
                }
                else{
                    listaCoEgre.push({                           
                        value: "",
                        style: { alignment: { horizontal: "center" },fill:{ fgColor: { rgb: "BCBCBC" } } }
                    })
                }
                
            }
            this.state.DatosReporte.push(
                {
                   //ySteps: 0, //will put space of 5 rows,
                  columns: [],
                  data: [ listaCoEgre ]
              })


              this.state.DatosReporte.push(
                {
                   //ySteps: 0, //will put space of 5 rows,
                  columns: [],
                  data: this.state.EgresosColumnas
                                  
              })
            this.setState({VerReporte:true})

    }


    render() {

        const { classes } = this.props;
        return (               
        <div className={classes.sections}>
            <div className={classes.container}>
            <Modal isOpen={this.state.VerReporte} className='static-modal' >
                <Card >
                <CardHeader color='info'>
                    DESCARGAR PRESUPUESTO
                </CardHeader>
                <CardBody>                                    
                    <GridContainer >
                        <GridItem md={12} style={{ textAlign:"center" }}>
                            <ExcelFile  filename="PRESUPUESTO" element={ <Button color="success" round size="md">  <CloudDownload/>DESCARGAR</Button> }>
                                <ExcelSheet dataSet={this.state.DatosReporte} name="REPORTE" />
                            </ExcelFile>
                        </GridItem>
                    </GridContainer> 
                    {/* Botones */}
                    <div className='mt-4'>
                    <GridItem>
                        <Button tabIndex="1" size='sm' color='warning' className='pull-right' round onClick={() => this.props.eventClick('esc', 'warning', [])}><Clear />&nbsp;CERRAR</Button>
                    </GridItem>
                    </div>              
                </CardBody>
                </Card >
                
            </Modal >
            
            </div >
      </div>
                
        )
    }
}

export default withStyles(headerLinksStyle)(PresupuestoExcel);