import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, Add, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';


class ModalAsignarFolioFiscal extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      FechaInicio: "",
      FechaFin: "",
      VentasTotalesPorPeriodo: {},
      MostrarModal:false,
      _id: "",
      disabled:false,
      errorFechas: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      cargando: false,
      CuerpoCargando: "Cargando",
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    // if (event.keyCode === 27) {
    //   this.props.eventClick("esc", "esc");
    // }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    //console.log(this.props)
    }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  //Obtener ventas totales por periodo---------------------------------------------------------------------------------------------------------------------------------------------------------
  obtenerListaDetallePagos(){
    let valor = 0;

    if(this.state.FechaInicio == '' && this.state.FechaFin == ''){
      this.setState({errorFechas: true ,mensaje: "Complete los campos de fechas", colorNotificacion: "danger" })
      this.showNotification();
      valor = 1;
    }else if(new Date(this.state.FechaInicio) > new Date(this.state.FechaFin)){
      this.setState({mensaje: "La fecha inicial no puede ser mayor a la fecha final", colorNotificacion: "danger", errorFechas: true})
      this.showNotification();
      valor = 1;
    } else{ valor= 0; this.setState({errorFechas: false})}

if(valor == 0){
    this.setState({cargando: true})
    fetch(Globales.Url + 'Facturacion/Facturacion/VentasTotalizadoPorPeriodo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Inicio: this.state.FechaInicio, Fin: this.state.FechaFin})
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger', ListaAlumnos:[], cargando: false });
          this.showNotification();
         } else { //Filtrado exitoso
            //console.log(data.ListaAlumnos);
            this.setState({colorNotificacion: 'success', VentasTotalesPorPeriodo: data.VentasTotalesPorPeriodo, cargando: false });
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));; 
    }
  }

  AsignarFolioFiscal(){
    this.setState({cargando: true})
    fetch(Globales.Url + 'Facturacion/Facturacion/AsignarFolioFiscalReciboVentas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Inicio: this.state.FechaInicio, Fin: this.state.FechaFin})
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger', cargando: false });
          this.showNotification();
         } else { //Filtrado exitoso
            //console.log(data.ListaAlumnos);
            this.setState({mensaje: data.mensaje ,colorNotificacion: 'success', cargando: false });
            this.MandarMensaje(data.mensaje);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));; 
  }

  //Mandar mensaje a facturacion principal fuera del modal
  MandarMensaje(mensaje) {
    this.setState({mensaje:mensaje, op: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick('esc');
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value} = e.target;
      this.setState({[name]: value});
  }

  /* obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  } */

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;

    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    //console.log("Fecha inicio", this.state.VentasTotalesPorPeriodo)

  //console.log(this.state.errorFechaInicio)
  //console.log(this.state.errorFechaFin)
  //console.log(this.state.FechaInicio)

    let agregar;
    if(Object.keys(this.state.VentasTotalesPorPeriodo).length !== 0){
      agregar=
        <>
          <div className="mb-2">{this.state.VentasTotalesPorPeriodo['TotalRecibos']}</div>
          <div>{convertirPesos(this.state.VentasTotalesPorPeriodo['TotalVenta'])}</div>
        </>
    }

    let modal;
    if(this.state.cargando){
      modal = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      )
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showFiscal}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <TextField
                    id="FechaInicio"
                    style={{ width: "15em" }}
                    variant="outlined"
                    type="date"
                    format="DD-MM-YYYY"
                    size="small"
                    label="Fecha Inicial"
                    className="mt-3"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.FechaInicio,
                      name: "FechaInicio",
                      id: "FechaInicio",
                      error: this.state.errorFechas,
                      success: !this.state.errorFechas
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <TextField
                    id="FechaFin"
                    style={{ width: "15em" }}
                    variant="outlined"
                    size="small"
                    type="date"
                    format="DD-MM-YYYY"
                    label="Fecha Final"
                    className="mt-3"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.FechaFin,
                      name: "FechaFin",
                      id: "FechaFin",
                      error: this.state.errorFechas,
                      success: !this.state.errorFechas
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <Button
                    tabIndex="0"
                    id="btnBuscar"
                    size="sm"
                    color="info"
                    className="pull-right mr-3 mt-2"
                    round
                    onClick={() => this.obtenerListaDetallePagos()}
                  >
                    <Search />
                    &nbsp;Buscar
                  </Button>
                </GridItem><br/>
                <GridItem xs={4}>
                  <div className="mb-2">Total de folios:</div>
                  <div>Total venta:</div>
                </GridItem>
                <GridItem xs={3}>
                  {agregar}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridItem>
            <Button
              tabIndex="0"
              id="btnGuardar"
              size="sm"
              color="success"
              className="pull-right  mb-3"
              round
              onClick={() => this.AsignarFolioFiscal()}
            >
              <Save />
              &nbsp;Asignar
            </Button>
            <Button
              tabIndex="1"
              size="sm"
              color="danger"
              className="pull-right mr-2 mb-3"
              round
              onClick={() => this.props.eventClick("esc")}
            >
              <Clear />
              &nbsp;Cerrar
            </Button>
          </GridItem>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {modal}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAsignarFolioFiscal);