const convertirPesos = (number) => {
  return new Intl.NumberFormat("ES-MX", {
    style: "currency",
    currency: "MXN",
  }).format(number);
};

module.exports = {
  convertirPesos
};
