import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import { Save, Clear } from '@material-ui/icons';
import Cargando from '../../../components/Generales/ModalCargando';
import 'react-tagsinput/react-tagsinput.css'
import basicsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx';

class ModalEditarMeses extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
        Meses:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
        ListaValoresMeses:[0,0,0,0,0,0,0,0,0,0,0,0],
        ListaErrores:[false,false,false,false,false,false,false,false,false,false,false,false]
    }
    this.handleChange = this.handleChange.bind(this);
    this.registrarMeses = this.registrarMeses.bind(this);
  }

  
  componentDidMount() {
   this.setState({ListaValoresMeses:this.props.ListaValoresMeses})
  }

  registrarMeses(){
    //Validar que los campos no esten vacios
    var permitir= true;
    var ListaErroresLocal=[false,false,false,false,false,false,false,false,false,false,false,false];
    this.state.ListaValoresMeses.map((valor,i)=>{
      if(valor==0){
        permitir= false;
        ListaErroresLocal[i] = true;
      }
    })
    if(permitir){
        this.props.eventClick("Se actualizo el presupuesto de la categoría por mes", 'success', this.state.ListaValoresMeses);
    }
    else{
      this.setState({ListaErrores: ListaErroresLocal})
    }
  }



  closeModal(mensaje, colorNotificacion,listaMesesActualizada) {
    this.props.eventClick(mensaje, colorNotificacion, listaMesesActualizada);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value, id } = e.target;
    var lista = this.state.ListaValoresMeses;    
    lista[parseInt(id)] = this.replaceNumber(value=="" || value == "e" ? "0" : value);
    if(lista[parseInt(id)].length>1){
      lista[parseInt(id)] = lista[parseInt(id)].replace(/^0+/, '');
  }
    this.setState({
      ListaValoresMeses: lista
    });
  }

  replaceNumber(value){
    return value.replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1.$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
}

  render() {
    const { classes } = this.props;
    const handleFocus = (event) => event.target.select();
   

    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <Modal isOpen={this.props.showEditarMeses} className='static-modal' >
            <Card >
              <CardHeader color='info'>
                {this.props.NombreCategoria}
              </CardHeader>
              <CardBody>                
                <GridContainer>
                  {/* Nombre de atributo */}
                  {
                      this.state.Meses.map((mes,i)=>{
                        return (
                            <GridItem xs={12} sm={12} md={4} lg={4}>
                                        <CustomInput
                                            labelText={mes}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.handleChange,
                                                onFocus: handleFocus,
                                                required: true,
                                                value: this.state.ListaValoresMeses[i],
                                                name: mes,
                                                id: i,
                                                type: "text",
                                                error: this.state.ListaErrores[i]                                                
                                            }}
                                        />
                                    </GridItem>
                        )
                      })
                  }
                  
                </GridContainer> 
                 {/* Botones */}
                 <div className='mt-4'>
                  <GridItem>
                    <Button tabIndex="0" disabled={this.state.disable} id="btnGuardar" size='sm' color='info' className='pull-right' round onClick={this.registrarMeses.bind(this)}><Save />&nbsp;GUARDAR</Button>
                    <Button tabIndex="1" size='sm' color='warning' className='pull-right' round onClick={() => this.props.eventClick('esc', 'warning', [])}><Clear />&nbsp;CANCELAR</Button>

                  </GridItem>
                </div>              
              </CardBody>
            </Card >
            <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />
          </Modal >
          <div id=''>
            <Snackbar
              place='tr'
              color={this.state.colorNotificacion}
              message={this.state.mensaje}
              open={this.state.br}
              closeNotification={() => this.setState({ br: false })}
              close
            />
          </div>
        </div >
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalEditarMeses);
