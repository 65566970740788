import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, GetApp, Add, AssignmentTurnedIn } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
import ModalEditarAsistencia from "./ModalEditarAsistencia";
import SeleccionarMaestro from "../../Generales/ComponentesEmpresas/SeleccionarMaestro.jsx"


class Facturacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaEmpresas: [],
      Folios: [],
      FoliosCancelados: [],
      MaestroSeleccionado: { NombreCompleto: "" },
      GrupoSeleccionado: [],
      NombreAlumno: [],
      SumaTotalFacturados: {},
      opcionBuscar: "FolioVenta",
      NombreGrupo: "",
      IDEmpresa: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      NombreEmpresa: "",
      FechaInicio: "",
      FechaFin: "",
      idUsuario: null,

      Disabled: false,
      cargando: false,
      showGrupo: false,

      mensaje: "",
      mensajePagos: "",
      br: false,
      ColorNotificacion: 'success',
      colorTableRow: "white",

      ListaIdiomas:[],
      ListaAulas:[],
      ListaNiveles:[],
      GruposFiltrados: [],
      Clase:"Todos",
      Idioma:"null",
      Aula:"null",
      Nivel: "null",
      HoraInicio:0,
      HoraInicioPM:false,
      HoraFin:12,
      HoraFinPM:true,
      CuerpoCargando: "Cargando",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
    this.EventoSeleccionarAula = this.EventoSeleccionarAula.bind(this);
    this.EventoSeleccionarIdioma = this.EventoSeleccionarIdioma.bind(this);
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    
  }

  componentDidMount() {
    let IDcookie = JSON.parse(cookie.get('id'));
    if(cookie.get('TipoUsuario') == "Maestro"){
      this.ListaInformacionGruposMaestros(IDcookie, 0)
    }
    else{
      this.ObtenerGrupos(0)
    } 

    this.setState({ idUsuario: IDcookie })
    this.ListarAulas()
    this.ListarIdiomas()

  }

  handleCallback = (MaestroSeleccionado) => {
    this.setState({ MaestroSeleccionado: MaestroSeleccionado },()=>{
      this.filtrarGrupos()
    })
  }

  //Función para pasar datos de los alumnos desde el modal hacia la vista
  RegresarMensaje = (mensaje) => {
    this.setState({ mensaje: mensaje, ColorNotificacion: "success" });
    this.showNotification();
    this.filtrarGrupos()
  }

  ListarAulas() {
    fetch(Globales.Url + 'Alumnos/Alumnos/ListaGeneralAulas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ ColorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Filtrado exitoso
          // console.log('Filtrado exitoso', data.Aulas);
          var ListaAulas = []

          if(data.Aulas.length > 1){
            ListaAulas = data.Aulas;
          }
          else{
            ListaAulas.push(data.Aulas);
          }

          this.setState({ ListaAulas: ListaAulas }, () => {
            if(this.props.op == 1){
              this.setState({
                Aula: JSON.stringify(this.props.cita._idAula),
              })
            }
          })
          //console.log('Filtrado ListaAulas',ListaAulas);
          // console.log("Aula", JSON.stringify(this.props.cita._idAula))

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  ListarIdiomas() {
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaGeneralIdiomas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ ColorNotificacion: 'danger', mensaje: data.mensaje, });
          this.showNotification();
        } else { //Filtrado exitoso
          var ListaIdiomas = []
          if(data.data.length > 1){
            ListaIdiomas = data.data;
          }
          else{
            ListaIdiomas.push(data.data);
          }
          
          this.setState({ ListaIdiomas: ListaIdiomas })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  ListarNiveles() {
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaNivelesIdioma', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ 
         idIdioma: JSON.parse(this.state.Idioma) 
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({  ListaNiveles:[], Nivel: "null" });
          // this.showNotification();
        } else { //Filtrado exitoso
          // this.setState({  ListaNiveles:[], Nivel: "null" });
          var ListaNiveles = []

          if(data.data.length > 1){
            ListaNiveles = data.data;
          }
          else{
            ListaNiveles.push(data.data);
          }
          
          this.setState({ ListaNiveles: ListaNiveles })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  // EliminarGrupos(idGrupo) {

  //   this.setState({cargando:true, Folios: []})
  //   fetch(Globales.Url + 'Grupos/Grupos/EliminarInformacionGrupo', {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
  //     }
  //     , body: JSON.stringify({ idGrupo: idGrupo, })
  //   }
  //   ).then(res => res.json())
  //     .then(data => {
  //       //console.log(data);
  //       if (data.error) { //Error en la respuesta
          
  //         this.setState({ mensaje: data.mensaje, ColorNotificacion: 'danger', cargando: false });
  //         // this.setState({ mensaje: data.mensaje, ColorNotificacion: '#f44336', cargando: false });
  //         this.showNotification();
  //       } else { //Filtrado exitoso
  //         this.setState({ mensaje: "El grupo se dio de baja exitosamente", ColorNotificacion: "success", cargando: false });
  //         this.showNotification();
  //         this.ObtenerGrupos()
          
  //       }
  //     })
  //     .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  // }

  async ListaInformacionGruposMaestros(idUsuario, estado) {
    this.setState({cargando:true, Folios: []})
    await fetch(Globales.Url + 'Colaboradores/Colaboradores/ListaInformacionGruposMaestros', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({id: idUsuario, estado: estado})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              //this.showNotification();
            } else { //Filtrado exitoso
              if(estado !== 2){
                let ListaAux;
                if (data.ListaGrupos.length > 1) {
    
                  this.setState({ Folios: data.ListaGrupos, Activo: 1, cargando: false, CheckAll: false });
                }
                else {
                  let AuxVentaFolio = [];
                  AuxVentaFolio.push(data.ListaGrupos);  
      
                  this.setState({ Folios: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
                }
              }
              else{
                let aux = [];
                let ListaAux;
                if (data.ListaGrupos.length > 1) {
    
                  ListaAux = data.ListaGrupos.sort(function(a,b){
                    return new Date(b.FechaTerminoCurso) - new Date(a.FechaTerminoCurso)
                  })
    
                  for(let i = 0; i < 50; i++){
                    aux.push(ListaAux[i])
                  }
      
                  this.setState({ Folios: aux, Activo: 1, cargando: false, CheckAll: false });
                }
                else {
                  let AuxVentaFolio = [];
                  AuxVentaFolio.push(data.ListaGrupos);
      
                  this.setState({ Folios: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
                }
              }
              this.filtrarGrupos() 
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  ObtenerGrupos() {

    this.setState({cargando:true, Folios: []})
    fetch(Globales.Url + 'Grupos/Grupos/ListaGeneralGrupos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Inicio: this.state.FechaInicio, Fin: this.state.FechaFin, idEmpresa: this.state.IDEmpresa })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso

          //console.log(data.Grupos)

          if (data.Grupos.length > 1) {

            /*Suma los totales*/
            let suma = 0;
            data.Grupos.map(ventas => {
              suma += parseFloat(ventas.Total);
            })

            this.setState({ Folios: data.Grupos, Total: suma, Activo: 1, cargando: false, CheckAll: false });
          }
          else {
            let AuxVentaFolio = [];
            AuxVentaFolio.push(data.Grupos);

            let suma = 0;
            suma += parseFloat(AuxVentaFolio[0].Total);

            this.setState({ Folios: AuxVentaFolio, Total: suma, Activo: 1, cargando: false, CheckAll: false });
          }
          this.filtrarGrupos()
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  filtrarGrupos() {
    let grupos = this.state.Folios;

    var ListaGrupos = []

    // VALIDAR EL NOMBRE DEL GRUPO EN LA LISTA
    grupos.map(grupo => {
      if (this.state.NombreGrupo == "") {
        ListaGrupos.push(grupo)
      } else {
        var Nombre = (grupo.Nombre).toUpperCase()
        var NombreGrupo = (this.state.NombreGrupo).toUpperCase()
        if (Nombre.includes(NombreGrupo)) {
          ListaGrupos.push(grupo)
        }
      }
    })

    // VALIDAR QUE EL MAESTRO SELECCIONADO SEA EL MISMO
    var gruposAux = []
    ListaGrupos.map(grupo => {
      if (this.state.MaestroSeleccionado.NombreCompleto != '') {
        var GrupoMaestro = JSON.stringify(grupo._idMaestro)
        var MaestroSeleccionado = JSON.stringify(this.state.MaestroSeleccionado._id)
        if (GrupoMaestro == MaestroSeleccionado) {
          gruposAux.push(grupo)
        }
      } else {
        gruposAux.push(grupo)
      }
    })
    // ListaGrupos = gruposAux
    if(this.state.Idioma !== "null"){
      ListaGrupos = this.FiltraGruposIdioma(gruposAux)
      ListaGrupos = this.FiltraGruposNivel(ListaGrupos)
      ListaGrupos = this.FiltraGruposAula(ListaGrupos)
      // ListaGrupos = this.FiltraGruposHora(ListaGrupos)
    }
    else{
      ListaGrupos = this.FiltraGruposIdioma(gruposAux)
      ListaGrupos = this.FiltraGruposAula(ListaGrupos)
    }
    
    
    

    this.setState({
      GruposFiltrados: ListaGrupos,
    })
  }
  FiltraGruposIdioma(ListaGrupos){
    var gruposAux = []
    if (this.state.Idioma != "null") {
      this.ListarNiveles()

      ListaGrupos.map(grupo => {
        var _idIdioma = JSON.stringify(grupo._idIdioma)
        if (_idIdioma == this.state.Idioma) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
    }else{
      this.setState({
        Nivel:"null",
        ListaNiveles:[],
      })

      return ListaGrupos
    }
  }
  FiltraGruposAula(ListaGrupos){
    var gruposAux = []
    if (this.state.Aula != "null") {
      ListaGrupos.map(grupo => {
        var _idAula = JSON.stringify(grupo._idAula)
        if (_idAula == this.state.Aula) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
    }else{
      return ListaGrupos
    }
  }
  FiltraGruposNivel(ListaGrupos){
    var gruposAux = []
    if (this.state.Nivel != "null") {
      //console.log(this.state.Nivel)
      ListaGrupos.map(grupo => {
        var _idNivel = JSON.stringify(grupo._idNivel)
        if (_idNivel == this.state.Nivel) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
    }else{
      return ListaGrupos
    }
  }
  FiltraGruposHora(ListaGrupos){
    var gruposAux = []
    
    var inicio = parseFloat(this.state.HoraInicio)
      var fin = parseFloat(this.state.HoraFin)

      if (this.state.HoraInicioPM == true) {
        inicio += 12
      }
      if (this.state.HoraFinPM == true) {
        fin += 12
      }

      ListaGrupos.map(grupo => {
        var HoraInicio = parseFloat(grupo.Horario.HoraInicio)
        var HoraFin = parseFloat(grupo.Horario.HoraFin)

        if (grupo.Horario.Formatofin == "pm") {
          HoraFin +=12
        }
        if (grupo.Horario.Formatoinicio == "pm") {
          HoraInicio +=12
        }

        if (HoraInicio >= inicio && HoraFin <= fin) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
  }

  ReporteAlumnosPorGrupo(idGrupo) {
    this.setState({cargando:true})
    fetch(Globales.Url + 'Grupos/Grupos/ReporteAlumnosPorGrupo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idGrupo: idGrupo, })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: "danger", cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso

          this.DescargarReporte(data.Reporte);
          
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  DescargarReporte(archivoBase64) {
    const url = 'data:application/pdf;base64,' + archivoBase64;

    let nombre = 'Reporte de alumnos por grupo'
    const linkSource = `${url}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    this.setState({
      cargando: false,
      mensaje: "Reporte generado exitosamente",
      ColorNotificacion: "success",
    });
    this.showNotification();
  }
  //Función para abrir el modal de buscar alumnos

  openModalBuscarMaestro = () => {
    this.setState({ showAgregar: true, Titulo: "Buscar Alumnos", op: 1 });
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, type } = e.target;
    //console.log(e.target);
    if (type == "number") {
      if( value == ""){
        this.setState({ [name]: 0 });
      }else{
        if ((parseFloat(value) < 0) || (parseFloat(value) > 12)) {
          this.setState({ mensaje: "Seleccione una hora valida", ColorNotificacion: "danger" });
          this.showNotification();
        } else {
          if( name =="Idioma" ) {
            this.setState({ 
              Idioma: value,
              Nivel: "null", 
            }, () => { this.filtrarGrupos() });
          }else{
            this.setState({ [name]: value }, () => { this.filtrarGrupos() });

          }
        }
      }

    }else{
      this.setState({ [name]: value }, () => { this.filtrarGrupos() });
    }

  }

  EventoSeleccionarIdioma(idIdioma) {
    this.setState({ Idioma: idIdioma });
  }
  EventoSeleccionarAula(Aula) {
    this.setState({ Aula: Aula });
  }

  eventoModalEliminar = (Exito) => {
    this.setState({
      show: false,
    });
    if (Exito) {
      this.EliminarGrupos(this.state.GrupoSeleccionado._id)
    }
  }

  openEditarAsistencia = (grupo) => {
    this.setState({ showEditarAsistencia: true, GrupoSeleccionado: grupo, op: 1 });
  }

  eventoEditarAsistencia = (valor) => {
    //Cambiar valores por default
    this.setState({ showEditarAsistencia: false, op: 0 })
    if (valor != false) {
        this.setState({mensaje: valor, ColorNotificacion: "success"});
        this.showNotification();
    }
}

  render() {
    const { MaestroSeleccionado } = this.state;
    let DatosAlumno;
    if (MaestroSeleccionado.NombreCompleto != "") {

      DatosAlumno =
        <>
          <TextField
            style={{ width: "25em"}}
            variant="outlined"
            size="small"
            // label="Maestro"
            InputProps={{
              disabled: true,
              value: MaestroSeleccionado.NombreCompleto,
              name: "NombreGrupo",
              id: "NombreGrupo",
            }}
          />
        </>

    } else {
      DatosAlumno =
        <>
        <TextField
            style={{ width: "25em" }}
            variant="outlined"
            size="small"
            label="Maestro"
            InputProps={{
              disabled: true,
              value: MaestroSeleccionado.NombreCompleto,
              // value: this.state.NombreGrupo,
            }}
          />
        </>
    }

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    if(this.state.showEditarAsistencia == true){
      agregar = (
        <ModalEditarAsistencia
          showEditarAsistencia={this.state.showEditarAsistencia}
          eventClick={this.eventoEditarAsistencia}
          op={this.state.op}
          GrupoSeleccionado={this.state.GrupoSeleccionado}
        />
      );
    }

    const { classes } = this.props;

    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    let autocompleteAdmin;
    if(cookie.get('TipoUsuario') !== "Maestro"){
      autocompleteAdmin = (
        <GridItem style={{ marginTop: "13px" }} id="Parametros de búsqueda" xs={12} sm={12} md={4}>
          <SeleccionarMaestro
            eventClick={this.handleCallback}
          />
        </GridItem>
      );
    }
  
    //console.log(this.state.Nivel)
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Asistencia</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={5}>
                    {/*Opcion FolioVenta*/}
                    <TextField
                      id="FolioVenta"
                      style={{ width: "18.2vw" }}
                      variant="outlined"
                      size="small"
                      label="Nombre del grupo"
                      error={this.state.errorFolioVenta}
                      success={!this.state.errorFolioVenta}
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.NombreGrupo,
                        name: "NombreGrupo",
                        id: "NombreGrupo",
                      }}
                    />
                  </GridItem>

                  {autocompleteAdmin}
                  <GridItem style={{ marginTop: "2em" }} id="tipo de búsqueda" xs={12} sm={12} md={5}>
                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: "18.2vw" }}
                        >
                        <Select
                            value={this.state.Idioma}
                            onChange={this.handleChange.bind(this)}  
                            size="small"  
                            variant="outlined"     
                            name="Idioma"  
                        >
                        <MenuItem value={"null"}>Todos los idiomas</MenuItem> 
                        {this.state.ListaIdiomas.length == 0 ? null :
                            this.state.ListaIdiomas.map((idioma) => {
                                return (<MenuItem value={JSON.stringify(idioma._id)} >{idioma.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ marginTop: "2em" }} id="tipo de búsqueda" xs={12} sm={12} md={5}>
                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: "18.2vw" }}
                        >
                        <Select
                            value={this.state.Nivel}
                            onChange={this.handleChange.bind(this)}   
                            variant="outlined"    
                            name="Nivel"  
                        >
                        <MenuItem value={"null"}>Todos los niveles</MenuItem>
                        {this.state.ListaNiveles.length == 0 ? null :
                            this.state.ListaNiveles.map((idioma) => {
                                return (<MenuItem value={JSON.stringify(idioma._id)} >{idioma.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ marginTop: "2em" }} id="tipo de búsqueda" xs={12} sm={12} md={5}>
                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: "18.2vw" }}
                        >
                        <Select
                            value={this.state.Aula}
                            onChange={this.handleChange.bind(this)}   
                            variant="outlined"    
                            name="Aula"  
                        >
                          <MenuItem value={"null"}>Todas las aulas</MenuItem> 
                        {this.state.ListaAulas.length == 0 ? null :
                            this.state.ListaAulas.map((aula) => {
                                return (<MenuItem value={JSON.stringify(aula._id)} >{aula.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Lista de grupos</h4>
              </CardHeader>
              <CardBody xs={12} sm={12} md={12}>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="table-wrapper-scroll-y">
                      <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                        <thead>
                          <th className="center">Clase</th>
                          <th className="center">Grupo</th>
                        </thead>
                        <tbody>
                       { this.state.GruposFiltrados.length > 0 ?  
                          this.state.GruposFiltrados.map((grupo, index) => {
                            return (
                              <tr style={{cursor: "default"}} hover onClick={() => this.openEditarAsistencia(grupo)}>
                                <td >{grupo.Clase}</td>
                                <td >{grupo.Nombre}</td>
                                <td className="pull-right">
                                  <Button data-toggle="tooltip" data-placement="bottom" title="Tomar asistencias" color="info" round justIcon size="sm" className="mr-2" /* onClick={() => this.openEditarAsistencia(grupo)} */><AssignmentTurnedIn /></Button>
                                </td>
                              </tr>
                            )
                          })
                          : <tr><td colSpan="3"className="text-center"><h5>No hay grupos disponibles</h5></td></tr>}
                        </tbody>
                      </table>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            {/* <div>
              <Button size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.ExportarCorte()}>Imprimir corte de caja</Button>

            </div> */}

          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar grupo"}
            Cuerpo={"¿Está seguro que desea eliminar grupo?"}
            show={this.state.show}
          eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.ColorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Facturacion);