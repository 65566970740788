import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.jsx";
import { Close } from "@material-ui/icons";

const Horas = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
const Minutos = ["00","10","20","30","40","50"]

export default class SeleccionarHorario extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      HoraFinPM:"am",
      HoraFin: "",
      MinutoFin: "",
      HoraInicioPM:"am",
      HoraInicio: "",
      MinutoInicio: "",
      errorFecha: false,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    if(this.props.op == 1){
      if(this.props.HorarioGrupo.HoraFin !== null && this.props.HorarioGrupo.HoraFin !== undefined){
        this.setState({
          HoraFinPM: this.props.HorarioGrupo.FormatoFin,
          HoraFin: this.DividirHorario("Hora", this.props.HorarioGrupo.HoraFin),
          MinutoFin: this.DividirHorario("Minuto", this.props.HorarioGrupo.HoraFin),
          HoraInicioPM: this.props.HorarioGrupo.FormatoInicio,
          HoraInicio: this.DividirHorario("Hora", this.props.HorarioGrupo.HoraInicio),
          MinutoInicio: this.DividirHorario("Minuto", this.props.HorarioGrupo.HoraInicio),
        })
      }
    }
  }

  DividirHorario(tipo, Horario){
    if(tipo == "Hora"){
      if(Horario.length == 4){
        const hh = "0" + Horario.slice(0,1)

        return hh
      }
      else{
        return Horario.slice(0,2)
      }
    }
    else{
      if(Horario.length == 4){
        return Horario.slice(2,6)
      }
      else{
        return Horario.slice(3,6)
      }
    }
  }

  handleChange(e){
    const {name,value} = e.target;
    //console.log(value.length);
      if (value.length == 1) {
        const num = "0" + value
        this.setState({[name]: num}, () => {this.FormatearHorario()})
      }
      else if(value.length > 2){
        this.setState({[name]: value}, () => {this.FormatearHorario()})
      } else{
        this.setState({[name]: value}, () => {this.FormatearHorario()})
      }
    
  }

  FormatearHorario(){
    let horaInicio = "";
    let horaFin = "";
    let totalhoras = 0;

    if(this.props.SalidaFormato24Hrs){
      if(this.state.HoraInicio !== "" && this.state.MinutoInicio !== ""){
        if(this.state.HoraInicioPM == "pm"){
          if(this.state.HoraInicio !== "12"){
            horaInicio = (parseInt(this.state.HoraInicio) + 12) + ":" + this.state.MinutoInicio;
          }
          else{
            horaInicio = this.state.HoraInicio + ":" + this.state.MinutoInicio;
          }
        }
        else{
          if(this.state.HoraInicio !== "12"){
            horaInicio = this.state.HoraInicio + ":" + this.state.MinutoInicio;
          }
          else{
            horaInicio = "00" + ":" + this.state.MinutoInicio;
          }
        }
      }

      if(this.state.HoraFin !== "" && this.state.MinutoFin !== ""){
        if(this.state.HoraFinPM == "pm"){
          if(this.state.HoraFin !== "12"){
            horaFin = (parseInt(this.state.HoraFin) + 12)+ ":" + this.state.MinutoFin;
          }
          else{
            horaFin = this.state.HoraFin + ":" + this.state.MinutoFin;
          }
        }
        else{
          if(this.state.HoraFin !== "12"){
            horaFin = this.state.HoraFin + ":" + this.state.MinutoFin;
          }
          else{
            horaFin = "00" + ":" + this.state.MinutoFin;
          }
        }
      }
    }
    else{
      if(this.state.HoraInicio !== "" && this.state.MinutoInicio !== ""){
        horaInicio = this.state.HoraInicio + ":" + this.state.MinutoInicio;
      }
      
      if(this.state.HoraFin !== "" && this.state.MinutoFin !== ""){
        horaFin = this.state.HoraFin + ":" + this.state.MinutoFin;
      }
    }

    if(this.props.SalidaTotalHoras){
      let auxMinutos1 = 0;
      let auxMinutos2 = 0;
      let horaInicio = 0;
      let horaFin = 0;

      //Convertir hora inicio a formato 24 horas
      if(this.state.HoraInicio !== "" && this.state.HoraFin !== ""){
        if(this.state.HoraInicioPM == "pm"){
          if(this.state.HoraInicio !== "12"){
            horaInicio = (parseInt(this.state.HoraInicio) + 12);
          }
          else{
            horaInicio = parseInt(this.state.HoraInicio);
          }
        }
        else{
          if(this.state.HoraInicio !== "12"){
            horaInicio = parseInt(this.state.HoraInicio);
          }
        }

        //Convertir hora fin a formato 24 horas
        if(this.state.HoraFinPM == "pm"){
          if(this.state.HoraFin !== "12"){
            horaFin = (parseInt(this.state.HoraFin) + 12);
          }
          else{
            horaFin = parseInt(this.state.HoraFin);
          }
        }
        else{
          if(this.state.HoraFin !== "12"){
            horaFin = parseInt(this.state.HoraFin);
          }
        }

        auxMinutos1 = Math.abs(horaInicio - horaFin) * 60
      }

      if(this.state.MinutoInicio !== "" && this.state.MinutoFin !== ""){
        auxMinutos2 = parseFloat(this.state.MinutoInicio) + parseFloat(this.state.MinutoFin)
      }

      // if(this.state.HoraInicio !== "" && this.state.HoraFin !== ""){
      //   auxMinutos1 = Math.abs(parseFloat(this.state.HoraInicio) - parseFloat(this.state.HoraFin)) * 60
      // }

      // if(this.state.MinutoInicio !== "" && this.state.MinutoFin !== ""){
      //   auxMinutos2 = parseFloat(this.state.MinutoInicio) + parseFloat(this.state.MinutoFin)
      // }

      totalhoras = ((auxMinutos1 + auxMinutos2) / 60)
    }

    // console.log(totalhoras)
    
    this.props.eventClick(horaInicio, horaFin, this.state.HoraInicioPM, this.state.HoraFinPM, totalhoras)
  }

  LimpiarHorarios(){
    this.setState({
      HoraFinPM:"am",
      HoraFin: "",
      MinutoFin: "",
      HoraInicioPM:"am",
      HoraInicio: "",
      MinutoInicio: "",
    })

    this.props.eventClick("", "", "am", "am")
  }

  render() {
    return (
      <div style={{display: "flex"}}>
        <div style={{display: "flex" , border: "1px solid #bdbdbd", borderRadius: "5px", padding: "5px", maxWidth: "30em"}}>
          <div>
        <FormControl
          style={{ width: "4em", marginLeft: "5px" }}
          size="small"
        >
          <Select
            value={this.state.HoraInicio}
            onChange={this.handleChange.bind(this)}
            name="HoraInicio"
          >
            {
              Horas.map( hora =>{
                return <MenuItem value={hora.toString()}>{hora}</MenuItem>
              })
            }
            
          </Select>
        </FormControl>
        <b> : </b>
        <FormControl
          style={{ width: "4em", marginLeft: "5px" }}
          size="small"
        >
          <Select
            value={this.state.MinutoInicio}
            onChange={this.handleChange.bind(this)}
            name="MinutoInicio"
          >
            {
              Minutos.map( minuto =>{
                return <MenuItem value={minuto.toString()}>{minuto}</MenuItem>
              })
            }
            
          </Select>
        </FormControl>
        <FormControl
          style={{ width: "4em", marginLeft: "5px" }}
          size="small"
        >
          <Select
            value={this.state.HoraInicioPM}
            onChange={this.handleChange.bind(this)}
            name="HoraInicioPM"
          >
            <MenuItem value={"am"}>AM</MenuItem>
            <MenuItem value={"pm"}>PM</MenuItem>
          </Select>
        </FormControl>
          </div>
        <b style={{ marginLeft: "auto" }}>&nbsp; A &nbsp;</b>
        <div>

        <FormControl
          style={{ width: "4em", marginLeft: "5px" }}
          size="small"
        >
          <Select
            value={this.state.HoraFin}
            onChange={this.handleChange.bind(this)}
            name="HoraFin"
          >
            {
              Horas.map( hora =>{
                return <MenuItem value={hora.toString()}>{hora}</MenuItem>
              })
            }
            
          </Select>
        </FormControl>
        <b>: </b>
        <FormControl
          style={{ width: "4em", marginLeft: "5px" }}
          size="small"
        >
          <Select
            value={this.state.MinutoFin}
            onChange={this.handleChange.bind(this)}
            name="MinutoFin"
          >
            {
              Minutos.map( minuto =>{
                return <MenuItem value={minuto.toString()}>{minuto}</MenuItem>
              })
            }
            
          </Select>
        </FormControl>
        <FormControl
          style={{ width: "4em", marginLeft: "5px" }}
          size="small"
        >
          <Select
            value={this.state.HoraFinPM}
            onChange={this.handleChange.bind(this)}
            name="HoraFinPM"
          >
            <MenuItem value={"am"}>AM</MenuItem>
            <MenuItem value={"pm"}>PM</MenuItem>
          </Select>
        </FormControl>
        </div>
        </div>
       {this.props.AgregarLimpiar ? <Button data-toggle="tooltip" data-placement="bottom" title="Limpiar filtro de horario" color="danger" className="ml-3" round justIcon size="sm"  onClick={() => this.LimpiarHorarios()}><Close /></Button> : ""}
      </div>
    );
  }
}