import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, Block, Add, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';

import ReactToPrint from 'react-to-print';
import ModalEliminarVenta from "../Cancelaciones/ModalEliminarVenta"

class ModalInfoVenta extends Component {

  constructor(props, context) {
    super(props, context);
    // this.escFunction = this.escFunction.bind(this);
    this.state = {
        BuscarAlumno: "",
        VentaSeleccionada: {},
        NombreAlumno: "",
        AgregarFolio: "",
        NewFoliosFactura: [""],
        PagoComprasAlumno: [],
        checkedInactivos: false,
      MostrarModal:false,
      _id: "",
      disabled:false,
      errorNumFactura: false,
      errorAgregarFolio: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      VerImpresion:false,
      showEliminar: false,
      op: 0,
      MostrarModalCargando: false,
      CuerpoCargando: "Cargando",
      
    }
    this.eventoModalCancelarVenta = this.eventoModalCancelarVenta.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    
  }

  // escFunction(event) {
  //   if (event.keyCode === 27) {
  //     this.props.eventClick("esc", "esc");
  //   }
  // }

  componentDidMount() {
    //console.log(this.props.op)
    // document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        VentaSeleccionada: this.props.VentaSeleccionada,
        NombreAlumno: this.props.NombreAlumno
      })
    }
    if (this.props.op == 2) {
      this.setState({
        VentaSeleccionada: this.props.VentaSeleccionada,
        NombreAlumno: this.props.NombreAlumno
      })
    }
    if (this.props.op == 3) {
      this.setState({
        op: this.props.op,
        VentaSeleccionada: this.props.VentaSeleccionada,
        NombreAlumno: this.props.NombreAlumno
      })
    }
    if(Object.keys(this.props.VentaSeleccionada).length !== 0){
      this.obtenerListaDetallePagos(this.props.VentaSeleccionada['_id']);
    }
  }

  // componentWillUnmount() {
  //   document.removeEventListener("keydown", this.escFunction, false);
  // }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------
  obtenerListaDetallePagos(Venta){
    this.setState({MostrarModalCargando: true})
    fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerDetallePagos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({_id: Venta})
    }
    ).then(res => res.json())
      .then(data => {
      //console.log("API: ",data.PagoComprasAlumno)
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger', ListaAlumnos:[], MostrarModalCargando: false });
          this.showNotification();
         } else { //Filtrado exitoso
          if(data.PagoComprasAlumno.length > 1){
            this.setState({colorNotificacion: 'success', PagoComprasAlumno: data.PagoComprasAlumno, MostrarModalCargando: false });
          }
          else {
            let ListaAux = [];
            ListaAux.push(data.PagoComprasAlumno);
            this.setState({ PagoComprasAlumno: ListaAux, MostrarModalCargando: false });
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", MostrarModalCargando: true}));; 
  }

  ActualizarFolioFactura(){
    let valor = 0;

    this.state.NewFoliosFactura.map(folio => {
      if(folio.trim() == ""){
        valor = 1;
        this.setState({errorAgregarFolio: true, mensaje: "El campo folio no puede ser vacio", colorNotificacion: "danger"});
        this.showNotification();
      }
    })


    if(valor == 0){
      this.setState({MostrarModalCargando: true})
    fetch(Globales.Url + 'Facturacion/Facturacion/ActualizarFolioFactura', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({_id: this.state.VentaSeleccionada['_id'], folio: this.state.NewFoliosFactura, ventavirtual: this.state.VentaSeleccionada['VentaVirtual']})
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, MostrarModalCargando: false });
          this.showNotification();
         } else { //Filtrado exitoso
            this.setState({ mensaje: data.mensaje, colorNotificacion: "success", Activo: 1, MostrarModalCargando: false });
            this.MandarMensaje(data.mensaje);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", MostrarModalCargando: true}));; 
    }
  }

  MandarMensaje(mensaje) {
    this.setState({mensaje:mensaje, op: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick('esc');
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  /* SeleccionarAlumno(ListaAlumnos) {
    this.setState({AlumnoSeleciondado:ListaAlumnos, op: 1 });
    this.props.parentCallback(ListaAlumnos);
    this.props.eventClick('esc');
  } */

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const {name, value} = e.target;

    this.setState({[name]: value,});
  }

  handleChangeFolio(e, item) {
    const { name, value} = e.target;

    //let index = this.state.NewFoliosFactura.findIndex(item);
    this.state.NewFoliosFactura[item] = value;
    
    this.setState({NewFoliosFactura: this.state.NewFoliosFactura});
  }

  AgregarFolio(){
    let Aux = "";
    Aux = this.state.AgregarFolio;

    this.setState({NewFoliosFactura: [Aux ,...this.state.NewFoliosFactura], AgregarFolio: "", errorAgregarFolio: false});
  }

  EliminarFolio(folio){
    let FolioIndex = this.state.NewFoliosFactura.indexOf(folio);
    this.state.NewFoliosFactura.splice(FolioIndex, 1);

    this.setState({NewFoliosFactura: [...this.state.NewFoliosFactura]});
  }

  /* obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  } */
  Imprimir(){
    this.setState({
      // PagosRealizados: Venta,
      VerImpresion:true
  }, () =>{
      document.getElementById("imprime").click();
  })
  }
   eventoModalCancelarVenta(Mensaje){
    this.setState({showEliminar:false})
    if(Mensaje != 'esc'){
      this.props.eventClick(Mensaje)
    }
    
  }
  ReimprimirPago() {
    //this.RegresarMensaje(true);
   this.setState({MostrarModalCargando:true});
   fetch(Globales.Url + 'Cobranza/Cobranza/ReimprimirReciboPago', {
     method: 'POST',
     headers: {
       Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
     }
     , body: JSON.stringify({
      Folio: this.state.VentaSeleccionada['FolioRecibo'], 
     })
   })
     .then(res => res.json())
     .then(data => {
       if (data.error) {
         //Error en la respuesta
         this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, MostrarModalCargando: false });
         this.showNotification();
       } else {
         //Filtrado exitoso;
         this.setState({MostrarModalCargando: false})
         this.openPdf(data.Recibo)
       }
     })
     .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", MostrarModalCargando: true}));;
 }

 openPdf(basePdf) {
   let byteCharacters = atob(basePdf);
   let byteNumbers = new Array(byteCharacters.length);
   for (let i = 0; i < byteCharacters.length; i++) {
     byteNumbers[i] = byteCharacters.charCodeAt(i);
   }
   let byteArray = new Uint8Array(byteNumbers);
   let file = new Blob([byteArray], { type: 'application/pdf;base64' });
   let fileURL = URL.createObjectURL(file);
   window.open(fileURL);
 }

 cerrarModal = () => {
  this.props.eventClick("esc")
}
 
  openCancelarVenta = () => {
    if (this.state.VentaSeleccionada['FolioFactura']) {
      this.setState({ mensaje: "Venta facturada, por favor cancele la factura para poder realizar la cancelación", colorNotificacion: "danger", });
      this.showNotification();
      return
    }
    this.setState({ showEliminar: true })
  }
  render() {
    const { classes } = this.props;

    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    let agregar;
    if(this.state.VentaSeleccionada['FolioFactura']){
      let Aux = [];
      let suma;
        Aux.push((this.state.VentaSeleccionada.FolioFactura || {}).string); //Verifica que exista un objeto en FolioFactura (string) antes de arrojar un error Undefined
        for (let i = 0; i < Aux.length; i++){
          suma = Aux[i] + " ";
        }
        agregar =
          <div className="mt-3">
            {suma}
          </div>
    }
    else{
      if(true){
      // if( this.props.op == 2 || this.props.op == 3 || this.state.VentaSeleccionada['Cancelada'] == "true"){
        agregar =
        <div className="mt-3">
            {"sin asignar"}
          </div>
      }else{
      agregar = 
        this.state.NewFoliosFactura.map((folio, index) => {
          if(index == 0){
            return(
              <div key={index}>
                <TextField
                  id="NewFolio"
                  style={{width: "80%", marginBottom: "10px"}}
                  variant="outlined"
                  size="small"
                  label="Folio"
                  error={this.state.errorAgregarFolio}
                  success={!this.state.errorAgregarFolio}
                  onChange={(e) => this.handleChangeFolio(e, index)}
                  InputProps={{
                    //onChange: this.handleChangeFolio.bind(this),
                    value: folio,
                    name: "NewFolio",
                    id: "NewFolio",
                  }}
                /> &nbsp;&nbsp;
                <Button data-toggle="tooltip" data-placement="bottom" title="Agregar otro folio" color="success" round justIcon size="sm" onClick={() => this.AgregarFolio()}><Add /></Button>
              </div>
            )
          }
          else{
            return(
              <div key={index}>
                <TextField
                  id="NewFolio"
                  style={{width: "80%", marginBottom: "10px"}}
                  variant="outlined"
                  size="small"
                  label="Folio"
                  //error={this.state.errorAgregarFolio}
                  //success={!this.state.errorAgregarFolio}
                  onChange={(e) => this.handleChangeFolio(e, index)}
                  InputProps={{
                    //onChange: this.handleChange.bind(this),
                    value: folio,
                    name: "NewFolio",
                    id: "NewFolio",
                  }}
                /> &nbsp;&nbsp;
                <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar folio" color="danger" round justIcon size="sm" onClick={() => this.EliminarFolio(folio)}><Close /></Button>
              </div>
            )
          }
          
        })
      }
    }

    let bannerFacturado;
    if(this.props.op !== 2 && this.props.op !== 3){
      if(this.state.VentaSeleccionada['FolioFactura'] !== null && this.state.VentaSeleccionada['FolioFactura'] !== undefined){
        if(this.state.VentaSeleccionada['FolioFactura'].string !== "No Facturado"){
          bannerFacturado = <div className="mt-2" style={{backgroundColor: "#adedb7" ,textAlign: "center", width: "100%", fontWeight: "bold"}}>Esta venta ya ha sido facturada</div>
        }
      }
    }
    else{
      bannerFacturado = "";
    }

    let cancelado;
    if(this.state.VentaSeleccionada.Cancelada == "true"){
      cancelado =
      <GridItem style={{ backgroundColor: "#ffabab", borderRadius: 3, }} xs={12} sm={12} md={12} lg={12}>
      <GridContainer style={{marginBottom: "1em"}} xs={12} sm={12} md={12} lg={12}>
        <GridItem xs={12} sm={12} md={12} lg={12} >
          <h5 className="text-center"><b>FACTURA CANCELADA</b></h5>
        </GridItem>
        {/* TITULOS DE LOS DATOS */}
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <div className="text-center"><b>CANCELÓ</b></div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <div className="text-center"><b>AUTORIZÓ</b></div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <div className="text-center"><b>FECHA</b></div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <div className="text-center"><b>MONTO</b></div>
        </GridItem>
        {/* DATOS RECUPERADOS */}
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <div className="text-center">{this.state.VentaSeleccionada.NombreUsuarioCancelo}</div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <div className="text-center">{this.state.VentaSeleccionada.NombreUsuarioAutorizo !== null ? this.state.VentaSeleccionada.NombreUsuarioAutorizo : "Sin especificar"}</div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <div className="text-center">{moment(this.state.VentaSeleccionada.FechaCancelacion).format('DD-MM-YYYY')}</div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <div className="text-center">{convertirPesos(this.state.VentaSeleccionada.Total)}</div>
        </GridItem>
        {/* MOTIVO DE CANCELACION */}
        <GridItem xs={12} sm={12} md={12} lg={12} >
          <div className="text-center"><b>MOTIVO DE CANCELACION</b></div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} >
          <div className="text-center">{this.state.VentaSeleccionada.MotivosCancelacion}</div>
        </GridItem>
      </GridContainer>
    </GridItem>
    }


    //console.log("Venta: ",this.state.VentaSeleccionada)
    //console.log("Pago: ",this.state.PagoComprasAlumno)

    let Venta;
    if(this.state.PagoComprasAlumno.length > 0){
      Venta = 
        this.state.PagoComprasAlumno.map(pago => {
          return(
          <tr>
            <td>{pago.Cantidad}</td>
            <td style={{width: "43%", fontSize: "13px"}}>{pago.Descripcion}</td>
            <td style={{fontSize: "13px", textAlign:"center"}}>{convertirPesos(pago.Precio)}</td>
            <td style={{fontSize: "13px", textAlign:"center"}}>{convertirPesos(pago.Pendiente)}</td>
            <td style={{fontSize: "13px", textAlign:"center"}}>{convertirPesos(pago.Monto)}</td>
            <td style={{fontSize: "13px", textAlign:"center"}}>{pago.Descuento !== "0" ? convertirPesos(parseFloat(pago.Pendiente) * (parseFloat(pago.Descuento) / 100)) : convertirPesos(pago.DescuentoPesos)}</td> 
            <td style={{fontSize: "13px", textAlign:"center"}}>{convertirPesos(pago.Monto)}</td>
        </tr> )
        })
        
    }
    else{
      Venta = <tr><td colSpan={7} style={{ textAlign: "center" }}>No hay detalle de la venta</td></tr>
    }

    let metodopago;

    if(this.state.VentaSeleccionada['TipoPago'] == "Tarjeta_de_Crédito"){
      metodopago = "Tarjeta de Crédito";
    }
    else if(this.state.VentaSeleccionada['TipoPago'] == "Tarjeta_de_Débito"){
      metodopago = "Tarjeta de Débito";
    }
    else{
      metodopago = this.state.VentaSeleccionada['TipoPago'];
    }

    let EspaciosAgregados = "";
    if(this.props.op !== 2 && this.props.op !== 3){
      EspaciosAgregados =
      this.state.NewFoliosFactura.map(index => {
        return(<><br/><br/></>)
      })
    }

    let modales;
    if(this.state.showEliminar == true){
      modales = (
        <ModalEliminarVenta
            Titulo={"Cancelar venta"}
            PagoSelecionado={this.state.VentaSeleccionada}
            showEliminar={this.state.showEliminar}
            eventClick={this.eventoModalCancelarVenta}
            op2={1}
          />
      )
    }

    if(this.state.MostrarModalCargando == true){
      modales = (
        <Cargando
          show={this.state.MostrarModalCargando}
          Cuerpo="Cargando"
        />
      )
    }


    return (
      <div>
        <Modal isOpen={this.props.showInfoVenta} className="static-modal" size="lg" toggle={this.cerrarModal} backdrop={true}>
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            {bannerFacturado}
            <CardBody>
              <GridContainer>
                  {cancelado}
                <GridItem xs={2} style={{marginTop:"2em" }}>
                  <div>Folio(s) Factura:</div>
                  {/* {EspaciosAgregados} */}
                  <div style={this.props.op !== 2 && this.props.op !== 3 ? {marginTop:"1.2em" } : {marginTop:".5rem"}}>Folios de venta:</div>
                  <div className="mt-2">Alumno</div>
                  <div className="mt-2">Fecha:</div>
                  <div className="mt-2">Tipo de pago:</div>
                  <div className="mt-2">Banco:</div>
                  <div className="mt-2">Número de referencia:</div>
                </GridItem>
                <GridItem xs={12} sm={5} style={this.props.op !== 2 && this.props.op !== 3 ? {marginTop:"1.3em" } : {marginTop:".8rem"}}>
                  {/* txtFolio */}
                  {/*AQUI VA LA CONDICION SI SE MUESTRA EL FOLIO SI YA TENIA O EL TEXTBOX PARA AGREAGAR UNO*/}
                  {agregar}
                  {/* {EspaciosAgregados} */}
                  <div style={this.props.op !== 2 && this.props.op !== 3 ? {marginTop: "10px"} : {marginTop:".5rem"}}>{this.state.VentaSeleccionada['FolioRecibo']}</div>
                  <div className="mt-2">{this.state.VentaSeleccionada['NombreAlumno'] !== null ? this.state.VentaSeleccionada['NombreAlumno'] : "Sin Especificar"}</div>
                  <div className="mt-2">{moment(this.state.VentaSeleccionada['Fecha']).format('DD-MM-YYYY')}</div>
                  <div className="mt-2">{metodopago}</div>
                  <div className="mt-2">{this.state.VentaSeleccionada['Banco'] !== undefined ? this.state.VentaSeleccionada['Banco'] : "Sin Especificar"}</div>
                  <div></div>
                </GridItem>
                <GridItem xs={2} style={{marginTop:"1.7em" }}>
                   <div className="mt-3">&nbsp;</div>
                  <div className="mt-2">Descuento:</div>
                  <div className="mt-2">IVA:</div>
                  <div className="mt-2">Subtotal:</div>
                  <div className="mt-2">Total:</div>
                </GridItem>
                <GridItem xs={2} style={{marginTop:"2em" }}>
                   <div className="mt-3">&nbsp;</div>
                  <div className="mt-2">{convertirPesos(this.state.VentaSeleccionada['Descuento'])}</div>
                  <div className="mt-2">{convertirPesos(this.state.VentaSeleccionada['Iva'])}</div>
                  <div className="mt-2">{convertirPesos(this.state.VentaSeleccionada['Subtotal'])}</div>
                  <div className="mt-2">{convertirPesos(this.state.VentaSeleccionada['Total'])}</div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Detalle de la venta</CardHeader>
            <CardBody>
            <table className="col-xs-12 col-sm-12 col-md-12">
              <thead>           
                <th style={{fontSize: "14px"}} className="center">Cant.</th>
                <th style={{fontSize: "14px"}} className="center">Descripción</th>
                <th style={{fontSize: "14px", textAlign:"center"}} className="center">Precio<br/>
                Unitario</th>
                <th style={{fontSize: "14px", textAlign:"center"}} className="center">Pendiente</th>
                <th style={{fontSize: "14px", textAlign:"center"}} className="center">Importe/<br/>Abono</th>
                <th style={{fontSize: "14px", textAlign:"center"}} className="center">Descuento</th>
                <th style={{fontSize: "14px", textAlign:"center"}} className="center">Importe<br/>Total</th>
              </thead>
              <tbody>
                    {Venta}
              </tbody>
            </table>
            </CardBody>
          </Card>
        <GridItem>
            <Button hidden={!(this.props.op == 2)} tabIndex="0" id="btnGuardar" size='sm' color='success' className='pull-right  mb-3' round onClick={() => this.ReimprimirPago()}><Save />&nbsp;Reimprimir recibo</Button>
            {/* <Button hidden={!(this.props.op == 2)} tabIndex="0" id="btnGuardar" size='sm' color='success' className='pull-right  mb-3' round onClick={() => this.Imprimir()}><Save />&nbsp;Reimprimir recibo</Button> */}
            <Button hidden={ !(this.props.op ==2) || this.state.VentaSeleccionada['Cancelada'] == "true"} tabIndex="0" id="btnGuardar" size='sm' color='danger' className='pull-right  mb-3' 
            round onClick={this.openCancelarVenta}><Block />&nbsp;Cancelar Venta</Button>

            <Button hidden={this.state.VentaSeleccionada['Cancelada'] == "true" || this.props.op ==2 || this.props.op ==3 || (this.state.VentaSeleccionada['FolioFactura'] !== null && this.state.VentaSeleccionada['FolioFactura'] !== undefined)} tabIndex="0" id="btnGuardar" size='sm' color='success' className='pull-right  mb-3' round onClick={() => this.ActualizarFolioFactura()}><Save />&nbsp;REGISTRAR FACTURA</Button>
            <Button tabIndex="1" size='sm' color='danger' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CERRAR</Button>
         </GridItem>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {modales}
        </div>
        
        <ReactToPrint
          trigger={() =>
            <Button id="imprime" style={{ display: 'none' }} color="primary"
            // onClick={() => this.props.eventClick(true)}
            ><i className="fa fa-check"></i>&nbsp;ACEPTAR</Button>
          }
          content={() => this.componentRef}
          copyStyles={true}
          onAfterPrint={() => this.setState({ VerImpresion: false })}
        />
        {
          // this.state.VerImpresion ?
          //   <ReimpresionRecibo
          //     ref={el => (this.componentRef = el)}
          //     VentaSeleccionada={this.state.VentaSeleccionada}
          //     PagoComprasAlumno={this.state.PagoComprasAlumno}
          //   /> : null
        }
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalInfoVenta);