import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalInfoVenta from "../Facturacion/ModalInfoVenta.jsx";
import ModalInfoAdeudo from "./ModalInfoAdeudo.jsx";
import ModalToken from "./ModalToken.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'

class Cancelaciones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: '',
            ListaEmpresas: [],
            FoliosRecibo: [],
            FoliosAdeudo: [],
            idsRecibos: [],
            idsAdeudos: [],
            IDEmpresa: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
            FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaFin: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            opcionRecibo: "recibo",
            opcionautorizacion: 0,
            Disabled: false,
            mensaje: "",
            mensajePagos: "",
            br: false,
            show: false,
            cargando: false,
            CheckAll: false,
            colorNotificacion: 'success',
            colorTableRow: "white",
            errorFolioVenta: false,
            entroFiltrar: false,
            notificacion: false,
            CuerpoCargando: "Cargando",
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
        //this.handleChange = this.handleChange.bind(this);
    }



    componentDidMount() {
        this.obtenerEmpresasActivas();
        let IDcookie = JSON.parse(cookie.get('id'));
        this.setState({ _id: IDcookie })
    }

    //Función para pasar datos de los alumnos desde el modal hacia la vista
    RegresarMensaje = (mensaje) => {
        this.setState({ mensaje: mensaje, colorNotificacion: "success" });
        this.showNotification();
    }

    /* RecuperarMensajeEliminar = (mensaje) =>{
      this.setState({mensaje: mensaje, colorNotificacion: "success"})
      this.showNotification();
    
      let ListaPagosIndex = this.state.ListaPagos.indexOf(this.state.PagoSelecionado);
        this.state.ListaPagos.splice(ListaPagosIndex, 1);
    } */

    //Función para obtener los pagos pendientes de los alumnos
    obtenerEmpresasActivas() {
        fetch(Globales.Url + 'Facturacion/Facturacion/ListadoEmpresas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify()
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1 });
                    this.showNotification();
                } else { //Filtrado exitoso

                    if (data.ListaEmpresas.length > 1) {
                        this.setState({ ListaEmpresas: data.ListaEmpresas, Activo: 1 });
                    }
                    else {
                        let ListaAuxEmpresas = [];
                        ListaAuxEmpresas.push(data.ListaEmpresas);
                        //console.log(ListaAux);
                        this.setState({ ListaEmpresas: ListaAuxEmpresas, Activo: 1 });
                    }
                }
            })
            .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }

    AsignarOpcionesAPI() {
        if (this.state.opcionRecibo == "recibo") {
            if (this.state.opcionautorizacion == 0) {
                this.ListaVentasCanceladasRangoFecha();
            }
            else if (this.state.opcionautorizacion == 1) {
                this.ListaVentasCanceladasRangoFechaEstadoAutorizacion(false);
            }
            else {
                this.ListaVentasCanceladasRangoFechaEstadoAutorizacion(true);
            }
        }
        else if (this.state.opcionRecibo == "adeudo") {
            if (this.state.opcionautorizacion == 0) {
                this.VentasListadoPorPeriodoAdeudo();
            }
            else if (this.state.opcionautorizacion == 1) {
                this.ObtenerListaAdeudosCanceladosFechasEstadoAutorizacion(false);
            }
            else {
                this.ObtenerListaAdeudosCanceladosFechasEstadoAutorizacion(true);
            }
        }
    }

    AutorizacionOpciones() {
        if (this.state.opcionRecibo == "recibo") {
            this.ActualizarAutorizacionCancelacionVentas();
        }
        else if (this.state.opcionRecibo == "adeudo") {
            this.ActualizarAutorizacionCancelacionAdeudos();
        }
    }

    /************************************ LLAMAR A LA API CONSULTA SI ES RECIBO **********************************************/
    ListaVentasCanceladasRangoFecha() {
        let valor1 = 0;
        let valor2 = 0;
        let valor3 = 0;

        if (this.state.FechaInicio == "" || this.state.FechaFin == "") {
            valor1 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos de fechas", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor1 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (this.state.FechaInicio > this.state.FechaFin) {
            valor2 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor2 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (new Date(this.state.FechaFin) > new Date()) {
            valor3 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha final no puede ser mayor a la fecha actual", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor3 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (valor1 + valor2 + valor3 == 0) {
            this.setState({ cargando: true });
            fetch(Globales.Url + 'Cancelaciones/Cancelaciones/ListaVentasCanceladasRangoFecha', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                }
                , body: JSON.stringify({ Inicio: this.state.FechaInicio, Fin: this.state.FechaFin, idEmpresa: this.state.IDEmpresa })
            }
            ).then(res => res.json())
                .then(data => {
                    //console.log(data);
                    if (data.error) { //Error en la respuesta
                        this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", FoliosRecibo: [], Activo: 1, cargando: false });
                        this.showNotification();
                    } else { //Filtrado exitoso

                        //console.log(data.FoliosRecibo)
                        if (data.FoliosRecibo.length > 1) {
                            data.FoliosRecibo.map(folio => {
                                folio.Seleccionado = false
                            })

                            this.setState({ FoliosRecibo: data.FoliosRecibo, Activo: 1, cargando: false, CheckAll: false });
                        }
                        else {
                            let AuxVentaFolio = [];
                            data.FoliosRecibo.Seleccionado = false;
                            AuxVentaFolio.push(data.FoliosRecibo);

                            this.setState({ FoliosRecibo: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
                        }
                    }
                })
                .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
        }
    }

    ListaVentasCanceladasRangoFechaEstadoAutorizacion(boolean) {
        let valor1 = 0;
        let valor2 = 0;
        let valor3 = 0;

        if (this.state.FechaInicio == "" || this.state.FechaFin == "") {
            valor1 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos de fechas", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor1 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (this.state.FechaInicio > this.state.FechaFin) {
            valor2 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor2 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (new Date(this.state.FechaFin) > new Date()) {
            valor3 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha final no puede ser mayor a la fecha actual", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor3 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (valor1 + valor2 + valor3 == 0) {
            this.setState({ cargando: true });
            fetch(Globales.Url + 'Cancelaciones/Cancelaciones/ListaVentasCanceladasRangoFechaEstadoAutorizacion', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                }
                , body: JSON.stringify({
                    Inicio: this.state.FechaInicio,
                    Fin: this.state.FechaFin,
                    autorizadas: boolean,
                    idEmpresa: this.state.IDEmpresa,
                    Usuario: JSON.parse(cookie.get('id'))
                })
            }
            ).then(res => res.json())
                .then(data => {
                    //console.log(data);
                    if (data.error) { //Error en la respuesta
                        this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", FoliosRecibo: [], Activo: 1, cargando: false });
                        this.showNotification();
                    } else { //Filtrado exitoso
                        if (data.FoliosRecibo.length > 1) {
                            data.FoliosRecibo.map(folio => {
                                folio.Seleccionado = false;
                            })
                            this.setState({ FoliosRecibo: data.FoliosRecibo, Activo: 1, cargando: false, CheckAll: false });
                        }
                        else {
                            let AuxVentaFolio = [];
                            data.FoliosRecibo.Seleccionado = false;
                            AuxVentaFolio.push(data.FoliosRecibo);

                            this.setState({ FoliosRecibo: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
                        }
                    }
                })
                .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
        }
    }

    /********************************** LLAMAR A LA API CONSULTA SI ES ADEUDO **************************************************/
    VentasListadoPorPeriodoAdeudo() {
        let valor1 = 0;
        let valor2 = 0;
        let valor3 = 0;

        if (this.state.FechaInicio == "" || this.state.FechaFin == "") {
            valor1 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos de fechas", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor1 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (this.state.FechaInicio > this.state.FechaFin) {
            valor2 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor2 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (new Date(this.state.FechaFin) > new Date()) {
            valor3 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha final no puede ser mayor a la fecha actual", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor3 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (valor1 + valor2 + valor3 == 0) {
            this.setState({ cargando: true });
            fetch(Globales.Url + 'Cancelaciones/Cancelaciones/ObtenerListaAdeudosCanceladosFechas', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                }
                , body: JSON.stringify({ Inicio: this.state.FechaInicio, Fin: this.state.FechaFin })
            }
            ).then(res => res.json())
                .then(data => {
                    //console.log(data);
                    if (data.error) { //Error en la respuesta
                        this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", FoliosAdeudo: [], Activo: 1, cargando: false });
                        this.showNotification();
                    } else { //Filtrado exitoso

                        //console.log(data.FoliosAdeudo)
                        if (data.FoliosAdeudo.length > 1) {
                            data.FoliosAdeudo.map(folios => {
                                folios.Seleccionado = false;
                            })

                            this.setState({ FoliosAdeudo: data.FoliosAdeudo, Activo: 1, cargando: false, CheckAll: false });
                        }
                        else {
                            let AuxVentaFolio = [];
                            data.FoliosAdeudo.Seleccionado = false;
                            AuxVentaFolio.push(data.FoliosAdeudo);

                            this.setState({ FoliosAdeudo: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
                        }
                    }
                })
                .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
        }
    }

    ObtenerListaAdeudosCanceladosFechasEstadoAutorizacion(boolean) {
        let valor1 = 0;
        let valor2 = 0;
        let valor3 = 0;

        if (this.state.FechaInicio == "" || this.state.FechaFin == "") {
            valor1 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos de fechas", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor1 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (this.state.FechaInicio > this.state.FechaFin) {
            valor2 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor2 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (new Date(this.state.FechaFin) > new Date()) {
            valor3 = 1
            this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha final no puede ser mayor a la fecha actual", colorNotificacion: "danger" })
            this.showNotification();
        }
        else {
            valor3 = 0
            this.setState({ errorFechainicio: false, errorFechaFin: false })
        }

        if (valor1 + valor2 + valor3 == 0) {
            this.setState({ cargando: true });
            fetch(Globales.Url + 'Cancelaciones/Cancelaciones/ObtenerListaAdeudosCanceladosFechasEstadoAutorizacion', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                }
                , body: JSON.stringify({
                    Inicio: this.state.FechaInicio,
                    Fin: this.state.FechaFin,
                    autorizadas: boolean,
                    Usuario: JSON.parse(cookie.get('id'))
                })
            }
            ).then(res => res.json())
                .then(data => {
                    //console.log(data);
                    if (data.error) { //Error en la respuesta
                        this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", FoliosAdeudo: [], Activo: 1, cargando: false });
                        this.showNotification();
                    } else { //Filtrado exitoso

                        //console.log(data.FoliosAdeudo)
                        if (data.FoliosAdeudo.length > 1) {
                            data.FoliosAdeudo.map(folios => {
                                folios.Seleccionado = false;
                            })

                            this.setState({ FoliosAdeudo: data.FoliosAdeudo, Activo: 1, cargando: false, CheckAll: false });
                        }
                        else {
                            let AuxVentaFolio = [];
                            data.FoliosAdeudo.Seleccionado = false;
                            AuxVentaFolio.push(data.FoliosAdeudo);

                            this.setState({ FoliosAdeudo: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
                        }
                    }
                })
                .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
        }
    }

    /******************************************** LLAMAR A LA API PARA AUTORIZAR LAS CANCELACIONES *********************************************************/
    ActualizarAutorizacionCancelacionVentas() {
        this.setState({ cargando: true });
        fetch(Globales.Url + 'Cancelaciones/Cancelaciones/ActualizarAutorizacionCancelacionVentas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ ventas: this.state.idsRecibos, _idAutorizacion: this.state._id })
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", FoliosAdeudo: [], Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "success", Activo: 1, cargando: false });
                    this.showNotification();
                    this.AsignarOpcionesAPI();
                }
            })
            .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }

    ActualizarAutorizacionCancelacionAdeudos() {
        this.setState({ cargando: true });
        fetch(Globales.Url + 'Cancelaciones/Cancelaciones/ActualizarAutorizacionCancelacionAdeudos', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ adeudos: this.state.idsAdeudos, _idAutorizacion: this.state._id })
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", FoliosAdeudo: [], Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "success", Activo: 1, cargando: false });
                    this.showNotification();
                    this.AsignarOpcionesAPI()
                }
            })
            .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }

    //Función para abrir el modal de buscar alumnos
    openModalInfoVenta = (Venta) => {
        this.setState({ VentaSeleccionada: Venta, showInfoVenta: true, Titulo: "Información general", op: 1 });
    }

    openModalNvoRecibo = () => {
        this.setState({ show: true });
    }

    openModalInfoAdeudo = (Venta) => {
        this.setState({ VentaSeleccionada: Venta, showInfoAdeudo: true, Titulo: "Información del adeudo", op: 1 });
    }

    openModalToken = () => {
        if (this.state.opcionRecibo == "recibo") {
            if (this.state.idsRecibos.length !== 0) {
                this.setState({ showToken: true, Titulo: "Autorización de información", op: 0 });
            }
            else {
                this.setState({ mensaje: "No hay recibos seleccionados", colorNotificacion: "danger" })
                this.showNotification();
            }
        }
        else {
            if (this.state.idsAdeudos.length !== 0) {
                this.setState({ showToken: true, Titulo: "Autorización de información", op: 0 });
            }
            else {
                this.setState({ mensaje: "No hay adeudos seleccionados", colorNotificacion: "danger" })
                this.showNotification();
            }
        }
    }


    //Función que recupera valores para el modal de agregar
    /*  eventoModalcobranza = (ListaAlumnos, colorNotificacion) => {
       //Cambiar valores por default
       this.setState({ showAgregar: false, op: 0 })
     } */


    //Función que recupera valores para el modal
    eventoModalInfoVenta = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showInfoVenta: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalAutorizar = (valor) => {
        this.setState({ show: false })
        if (valor) {
            this.AutorizacionOpciones();
        }
    }

    eventoModalInfoAdeudo = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showInfoAdeudo: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalToken = (valor) => {
        //Cambiar valores por default
        this.setState({ showToken: false, op: 0 })
        if (valor) {
            this.AutorizacionOpciones();
        }
    }

    //Función que habilita la notificación
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        //console.log(e.target);
        if (name == "opcionautorizacion") {
            this.setState({
                [name]: value,
                FolioVenta: "",
                FoliosRecibo: [],
                FoliosAdeudo: [],
                NombreAlumno: "",
                NombreEmpresa: "",
                CheckAll: false,
                Total: 0
            });
        }
        else if (name == "opcionRecibo") {
            this.setState({
                [name]: value,
                FolioVenta: "",
                FoliosRecibo: [],
                FoliosAdeudo: [],
                NombreAlumno: "",
                NombreEmpresa: "",
                CheckAll: false,
                Total: 0
            });
        }
        else {
            this.setState({ [name]: value });
        }
    }

    handleCheckAll(e) {
        const { checked } = e.target;
        let aux = [];
        let folios;

        if (checked) {
            switch (this.state.opcionRecibo) {
                case "recibo":
                    folios = this.state.FoliosRecibo;
                    folios.map(folio => {
                        if (JSON.stringify(folio.IdUsuarioAutoriza) === JSON.stringify({ _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" })) {
                            folio.Seleccionado = true;
                            aux.push(folio._id);
                        }
                    })

                    this.setState({
                        idsRecibos: aux,
                        Folios: folios,
                        CheckAll: true,
                    })
                    break;
                case "adeudo":
                    folios = this.state.FoliosAdeudo;
                    folios.map(folio => {
                        if (JSON.stringify(folio._idUsuarioAutorizo) === JSON.stringify({ _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" })) {
                            folio.Seleccionado = true;
                            aux.push(folio._id);
                        }
                    })

                    this.setState({
                        idsAdeudos: aux,
                        Folios: this.state.FoliosRecibo,
                        CheckAll: true,
                    })
                    break;
            }
        }
        else {
            switch (this.state.opcionRecibo) {
                case "recibo":
                    folios = this.state.FoliosRecibo;
                    folios.map(folio => {
                        folio.Seleccionado = false
                    })

                    this.setState({
                        idsRecibos: [],
                        Folios: folios,
                        CheckAll: false,
                    })
                    break;
                case "adeudo":
                    folios = this.state.FoliosAdeudo;
                    folios.map(folio => {
                        folio.Seleccionado = false
                    })

                    this.setState({
                        idsAdeudos: [],
                        Folios: folios,
                        CheckAll: false,
                    })
                    break;
            }
        }
    }

    handleCheckBox(e, item) {
        const { checked } = e.target;
        let folios;
        let seleccionado;
        let idIndex;

        if (checked) {
            switch (this.state.opcionRecibo) {
                case "recibo":
                    folios = this.state.FoliosRecibo
                    seleccionado = folios.indexOf(item);
                    folios[seleccionado].Seleccionado = true

                    this.setState({
                        idsRecibos: [...this.state.idsRecibos, item._id],
                        FoliosRecibo: folios,
                        CheckAll: false,
                    })

                    break;
                case "adeudo":
                    folios = this.state.FoliosAdeudo
                    seleccionado = folios.indexOf(item);
                    folios[seleccionado].Seleccionado = true

                    this.setState({
                        idsAdeudos: [...this.state.idsAdeudos, item._id],
                        FoliosAdeudo: folios,
                        CheckAll: false,
                    })
                    break;
            }
        }
        else {
            switch (this.state.opcionRecibo) {
                case "recibo":
                    idIndex = this.state.idsRecibos.indexOf(item._id);
                    this.state.idsRecibos.splice(idIndex, 1);

                    folios = this.state.FoliosRecibo
                    seleccionado = folios.indexOf(item);
                    folios[seleccionado].Seleccionado = false

                    this.setState({
                        idsRecibos: [...this.state.idsRecibos],
                        FoliosRecibo: folios,
                        CheckAll: false,
                    })
                    break;
                case "adeudo":
                    idIndex = this.state.idsAdeudos.indexOf(item._id);
                    this.state.idsAdeudos.splice(idIndex, 1);

                    folios = this.state.FoliosAdeudo
                    seleccionado = folios.indexOf(item);
                    folios[seleccionado].Seleccionado = false

                    this.setState({
                        idsAdeudos: [...this.state.idsAdeudos],
                        FoliosAdeudo: folios,
                        CheckAll: false,
                    })
                    break;
            }
        }
    }

    render() {

        const convertirPesos = (number) => {
            return new Intl.NumberFormat("ES-MX", {
                style: 'currency',
                currency: 'MXN'
            }).format(number);
        }

        let agregar;
        if (this.state.showInfoVenta == true) {
            agregar =
                <ModalInfoVenta
                    Titulo={this.state.Titulo}
                    showInfoVenta={this.state.showInfoVenta}
                    eventClick={this.eventoModalInfoVenta}
                    op={3}
                    VentaSeleccionada={this.state.VentaSeleccionada}
                    NombreAlumno={this.state.NombreAlumno}
                //parentCallback={this.eventoModalInfoVenta}
                />
        }

        if (this.state.cargando == true) {
            agregar = (
                <ModalCargando
                    Cuerpo={this.state.CuerpoCargando}
                    show={this.state.cargando}
                />
            );
        }

        if (this.state.showInfoAdeudo == true) {
            agregar =
                <ModalInfoAdeudo
                    Titulo={this.state.Titulo}
                    showInfoAdeudo={this.state.showInfoAdeudo}
                    eventClick={this.eventoModalInfoAdeudo}
                    op={1}
                    VentaSeleccionada={this.state.VentaSeleccionada}
                //NombreAlumno={this.state.NombreAlumno}
                //parentCallback={this.eventoModalInfoVenta}
                />
        }

        if (this.state.showToken == true) {
            agregar =
                <ModalToken
                    Titulo={this.state.Titulo}
                    showToken={this.state.showToken}
                    eventClick={this.eventoModalToken}
                //op={this.state.op}
                />
        }

        let FoliosRecibo;
        let checkbox;
        let Aux;
        if (this.state.FoliosRecibo.length !== 0) {
            FoliosRecibo =
                this.state.FoliosRecibo.map((folio, index) => {
                    if (folio.FolioRecibo !== null) {
                        if (folio.RazonSocial !== undefined) {
                            Aux =
                                <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{folio.RazonSocial}</td>
                        }
                        else if (this.state.NombreEmpresa !== "") {
                            Aux =
                                <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{this.state.NombreEmpresa}</td>
                        }
                        else {
                            Aux =
                                <td style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(folio)}>{"Sin Especificar"}</td>
                        }

                        if (JSON.stringify(folio.IdUsuarioAutoriza) === JSON.stringify({ _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" })) {
                            checkbox = <td style={{ cursor: "pointer" }}><Checkbox onChange={(e) => this.handleCheckBox(e, folio)} name="checkedindiv" checked={folio.Seleccionado} /></td>;
                        }
                        else {
                            checkbox = <td style={{ cursor: "pointer" }}></td>
                        }
                        return (
                            <tr key={index} hover>
                                {Aux}
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px" }} onClick={() => this.openModalInfoVenta(folio)}>{folio.FolioRecibo}</td>
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px" }} onClick={() => this.openModalInfoVenta(folio)}>{moment(folio.FechaCancelacion).format('DD-MM-YYYY hh:mm:ss')}</td>
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px" }} onClick={() => this.openModalInfoVenta(folio)}>{folio.NombreAlumno != null ? folio.NombreAlumno : "Sin Especificar"}</td>
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px", width: "15vw" }} onClick={() => this.openModalInfoVenta(folio)}>{folio.MotivosCancelacion}</td>
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px" }} onClick={() => this.openModalInfoVenta(folio)}>{convertirPesos(folio.MontoDevuelto)}</td>
                                {checkbox}
                            </tr>
                        )
                    } else {
                        return (
                            <tr>
                                <td colSpan={7} style={{ textAlign: "center" }}>NO HAY VENTAS CANCELADAS</td>
                            </tr>
                        )
                    }
                })
        }
        else {
            FoliosRecibo =
                <tr>
                    <td colSpan={7} style={{ textAlign: "center" }}>NO HAY VENTAS CANCELADAS</td>
                </tr>
        }

        let FoliosAdeudo;
        if (this.state.FoliosAdeudo.length !== 0) {
            FoliosAdeudo =
                this.state.FoliosAdeudo.map((folio, index) => {
                    if (folio.FolioRecibo !== null) {
                        if (JSON.stringify(folio._idUsuarioAutorizo) === JSON.stringify({ _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" })) {
                            checkbox = <td style={{ cursor: "pointer" }}><Checkbox onChange={(e) => this.handleCheckBox(e, folio)} name="checkedindiv" checked={folio.Seleccionado} /></td>;
                        }
                        else {
                            checkbox = <td style={{ cursor: "pointer" }}></td>;
                        }

                        return (
                            <tr key={index} hover>
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px" }} onClick={() => this.openModalInfoVenta(folio)}>{moment(folio.FechaCancelacion).format('DD-MM-YYYY hh:mm:ss')}</td>
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px" }} onClick={() => this.openModalInfoAdeudo(folio)}>{folio.NombreConcepto}</td>
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px" }} onClick={() => this.openModalInfoAdeudo(folio)}>{folio.NombreAlumno != null ? folio.NombreAlumno : "Sin Especificar"}</td>
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px" }} onClick={() => this.openModalInfoAdeudo(folio)}>{folio.MotivoCancelacion}</td>
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px" }} onClick={() => this.openModalInfoAdeudo(folio)}>{convertirPesos(folio.Monto)}</td>
                                <td style={{ cursor: "pointer", verticalAlign: "middle", fontSize: "13px" }} onClick={() => this.openModalInfoAdeudo(folio)}>{convertirPesos(folio.MontoTotal)}</td>
                                {checkbox}
                            </tr>
                        )
                    } else {
                        return (
                            <tr>
                                <td colSpan={7} style={{ textAlign: "center" }}>NO HAY ADEUDOS CANCELADOS</td>
                            </tr>
                        )
                    }
                })
        }
        else {
            FoliosAdeudo =
                <tr>
                    <td colSpan={7} style={{ textAlign: "center" }}>NO HAY ADEUDOS CANCELADOS</td>
                </tr>
        }

        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Cancelaciones</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={10}>
                                        <h6>Información de cancelaciones realizadas</h6>
                                    </GridItem>
                                    <GridItem id="Empresa de búsqueda" xs={12} sm={12} md={6}>
                                        <FormControl size="small">
                                            <div>Empresa</div>
                                            <Select
                                                fullWidth={true}
                                                value={this.state.IDEmpresa}
                                                onChange={this.handleChange.bind(this)}
                                                name="IDEmpresa"
                                                //fullWidth={true}
                                                variant="outlined"
                                                // label="Productos"
                                                style={{ width: "22em" }}
                                            //error={this.state.errorUsuarios}
                                            //success={!this.state.errorUsuarios}
                                            >
                                                <MenuItem value={this.state.IDEmpresa} disabled={true}>Todas</MenuItem>
                                                {this.state.ListaEmpresas.map(Empresa =>
                                                    <MenuItem value={Empresa._id}>{(Empresa.RazonSocial).toUpperCase()}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={12}>
                                        {/*Opcion Fechas*/}   <div >
                                            <TextField
                                                id="FechaInicio"
                                                style={{ width: "15em" }}
                                                variant="outlined"
                                                type="date"
                                                format="DD-MM-YYYY"
                                                size="small"
                                                label="Fecha de inicio"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    onChange: this.handleChange.bind(this),
                                                    value: this.state.FechaInicio,
                                                    name: "FechaInicio",
                                                    id: "FechaInicio",
                                                    error: this.state.errorFechainicio,
                                                    success: !this.state.errorFechainicio
                                                }}
                                            />
                                            <TextField
                                                className="ml-2"
                                                id="FechaFin"
                                                style={{ width: "15em" }}
                                                variant="outlined"
                                                size="small"
                                                type="date"
                                                format="DD-MM-YYYY"
                                                label="Fecha de terminación"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    onChange: this.handleChange.bind(this),
                                                    value: this.state.FechaFin,
                                                    name: "FechaFin",
                                                    id: "FechaFin",
                                                    error: this.state.errorFechaFin,
                                                    success: !this.state.errorFechaFin
                                                }}
                                            />

                                            <FormControl size="small" variant="outlined">
                                                <InputLabel id="tipo" style={{ marginLeft: "10px" }}>Tipo de cancelación</InputLabel>
                                                <Select
                                                    labelId="tipo"
                                                    className="ml-2"
                                                    fullWidth={true}
                                                    value={this.state.opcionRecibo}
                                                    onChange={this.handleChange.bind(this)}
                                                    name="opcionRecibo"
                                                    //fullWidth={true}
                                                    // variant="outlined"
                                                    label="Tipo de cancelación"
                                                    style={{ width: "13em" }}
                                                >
                                                    <MenuItem disabled>Seleccione una opción</MenuItem>
                                                    <MenuItem value={"recibo"}>Recibo</MenuItem>
                                                    <MenuItem value={"adeudo"}>Adeudo</MenuItem>

                                                </Select>
                                            </FormControl>
                                            <FormControl size="small" variant="outlined">
                                                <InputLabel id="auth" style={{ marginLeft: "10px" }}>Estado autorización</InputLabel>
                                                <Select
                                                    className="ml-2"
                                                    labelId="auth"
                                                    fullWidth={true}
                                                    value={this.state.opcionautorizacion}
                                                    onChange={this.handleChange.bind(this)}
                                                    name="opcionautorizacion"
                                                    label="Estado autorización"
                                                    //fullWidth={true}
                                                    style={{ width: "13em" }}
                                                >
                                                    <MenuItem value={0}>Todo</MenuItem>
                                                    <MenuItem value={1}>No autorizada</MenuItem>
                                                    <MenuItem value={2}>Autorizada</MenuItem>

                                                </Select>
                                            </FormControl>&nbsp;&nbsp;&nbsp;
                                            <Button size="sm" type="submit" color="info" round onClick={() => this.AsignarOpcionesAPI()}><Search />Buscar</Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={18}>
                        <Card>
                            <CardHeader color="info" text hidden={this.state.opcionRecibo !== "recibo"}>
                                <h4 className={classes.cardTitle}>Ventas canceladas</h4>
                            </CardHeader>
                            <CardHeader color="info" text hidden={this.state.opcionRecibo !== "adeudo"}>
                                <h4 className={classes.cardTitle}>Adeudos cancelados</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="table-responsive-xl table-wrapper-scroll-y">
                                            <table className="table table-sm table-hover">
                                                <thead hidden={this.state.opcionRecibo !== "recibo"}>
                                                    <th className="center">Empresa</th>
                                                    <th className="center">Folio</th>
                                                    <th className="center">Fecha de cancelación</th>
                                                    <th className="center">Alumno</th>
                                                    <th className="center">Motivos</th>
                                                    <th className="center">Devolución</th>
                                                    <th><Checkbox onChange={this.handleCheckAll.bind(this)} name="CheckAll" checked={this.state.CheckAll} /></th>
                                                </thead>
                                                <thead hidden={this.state.opcionRecibo !== "adeudo"}>
                                                    <th className="center">Fecha de cancelación</th>
                                                    <th className="center">Descripción</th>
                                                    <th className="center">Alumno</th>
                                                    <th className="center">Motivos</th>
                                                    <th className="center">Monto</th>
                                                    <th className="center">Monto cancelado</th>
                                                    <th><Checkbox onChange={this.handleCheckAll.bind(this)} name="CheckAll" checked={this.state.CheckAll} /></th>
                                                </thead>
                                                <tbody hidden={this.state.opcionRecibo !== "recibo"}>
                                                    {FoliosRecibo}
                                                </tbody>
                                                <tbody hidden={this.state.opcionRecibo !== "adeudo"}>
                                                    {FoliosAdeudo}
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <div>
                            <Button size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.openModalToken()}>Autorizar</Button>
                        </div>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <ModalPregunta
                        Titulo={"Autorización"}
                        Cuerpo={"¿Seguro que desea autorizar las cancelaciones seleccionadas?"}
                        show={this.state.show}
                        eventClick={this.eventoModalAutorizar}
                    />
                    {agregar}
                </div>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(Cancelaciones);