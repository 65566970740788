// core components/views for Admin layout

import ReportePagos from "views/Modulos/Reportes/ReportePagos.jsx";
import ReporteGastos from "views/Modulos/Reportes/ReporteGastos.jsx";
import ReporteCobranza from "views/Modulos/Reportes/ReporteCobranza.jsx";
import ReporteAlumnos from "views/Modulos/Reportes/ReporteAlumnos.jsx";
import ReporteMaestros from "views/Modulos/Reportes/ReporteMaestros.jsx";
import ReporteListas from "views/Modulos/Reportes/ReporteListas.jsx"
import ReporteLibros from "views/Modulos/Reportes/ReporteLibros.jsx"

// @material-ui/icons
import { Receipt, LocalOffer, Group, School,FormatListBulleted, Book, Whatshot, MergeType, Home, Menu,WalletMembership,Contacts } from "@material-ui/icons";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const validarModo =(modulo)=>{
  let User = JSON.parse(localStorage.getItem("Usuario"))
  let permiso = false
  if(User){
    permiso = User.Modo != "true" ? modulo : false
  }
  return permiso
}

const dashboardRoutes = [  
  {
    path: "/Alumnos",
    name: "Alumnos",
    icon: Group,
    component: ReporteAlumnos,
    layout: "/reportes",
    idModulo:"8"
  },
  {
    path: "/Maestros",
    name: "Maestros",
    icon: School,
    component: ReporteMaestros,
    layout: "/reportes",
    idModulo:"81"
  },
  {
    path: "/Listas",
    name: "Listas",
    icon: FormatListBulleted,
    component: ReporteListas,
    layout: "/reportes",
    // idModulo:"8"
    idModulo: validarModo("8")
  },
  {
    path: "/Cobranza",
    name: "Cobranza",
    icon: LocalOffer,
    component: ReporteCobranza,
    layout: "/reportes",
    idModulo:"8"
  },
  {
    path: "/Gastos",
    name: "Gastos",
    icon: Receipt,
    component: ReporteGastos,
    layout: "/reportes",
    idModulo:"81"
  },
  {
    path: "/Pagos",
    name: "Pagos",
    icon: Receipt,
    component: ReportePagos,
    layout: "/reportes",
    idModulo:"81"
  },
  {
    path: "/Libros",
    name: "Libros",
    icon: Book,
    component: ReporteLibros,
    layout: "/reportes",
    // idModulo: "81"
    idModulo: validarModo("81")
  },
]

let Permisos =localStorage.getItem("Permisos") == null ? {Modulo:[]}:JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

// console.log("🌌Permisos ", (Permisos).sort((a, b) => a.NumeroModulo - b.NumeroModulo))

var dashboardRoutesAccess = [];

for (var keyRoutes = 0; keyRoutes < dashboardRoutes.length; keyRoutes++) {
  if (Permisos.find(permiso => permiso.NumeroModulo == dashboardRoutes[keyRoutes].idModulo)) {
    dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
  }
}

export default dashboardRoutesAccess;