import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import EditarContancia from "./EditarReglamento";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Search, Close } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

class CatUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      FiltrarNombres: "",
      ListaConstancias: [],
      ListaConstanciasTemp: [],
      ListaModulos: [],
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      showEditarUsuario: false,
      Total: 0,
      TotalSeleccionado: 0,
      Titulo: "Registrar un nuevo Usuario",
      IdNuevo: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    this.ObtenerListaNombreReglamento();
    // this.ListaGeneralModulos();

  }

  async ObtenerListaNombreReglamento() {
    this.setState({ cargando: true });
    await fetch(Globales.Url + "Catalogos/TipoReglamento/ObtenerListaNombreReglamento", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            ListaConstancias: [],
            ListaConstanciasTemp: [],
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            cargando: false,
          });
          this.showNotification();
        } else {
          let registros = []
          if (data.data.length >= 1) {
            registros = data.data
          } else {
            registros = [data.data]
          }
          
          this.setState({
            ListaConstancias: registros,
            ListaConstanciasTemp: registros,
            cargando: false,
          });

        }
      })
      .catch((err) => console.error("El error es", err));
  }

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "FiltrarNombres") {
      const Dataorg = this.state.ListaConstanciasTemp;
      if (value) {
        const NuevaData = Dataorg.filter((constancia) =>
          String(
            constancia.Nombre
          )
            .toUpperCase()
            .includes(String(value).toUpperCase())
        );
        this.setState({
          FiltrarNombres: value,
          ListaConstancias: NuevaData,
        });
      } else {
        this.setState({
          FiltrarNombres: "",
          ListaConstancias: [...Dataorg],
        });
      }
    }
  };

  eventoModalPregunta = (valor) => {
    if (valor) {
      this.eliminarUsuario(this.state.idConstancia);
    } else {
      this.setState({ show: false });
    }
  };

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  eventoModalEliminar(e) {
    this.setState({ show: true, idConstancia: e });
    return;
  }

  eliminarUsuario(id) {
    fetch(Globales.Url + "Catalogos/TipoReglamento/EliminarInformacionTipoReglamento", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ _id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.table(data)
        if (data.error) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger" });
        } else {
          //Obtuvo los registros correctamente
          this.setState({ colorNotificacion: "success" });
        }
        this.setState({
          mensaje: data.mensaje,
          show: false,
          entroEliminar: true,
        });
        this.showNotification();
        this.ObtenerListaNombreReglamento();
      });
  }

  RegresarMensaje = (mensaje) => {
    this.setState({ mensaje: mensaje, colorNotificacion: "success" });
    this.showNotification();
    this.ListaGeneralUsuarios();
  };

  eventoEditarUsuario = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showEditarUsuario: false, op: 0 });
    if (mensaje != "esc") {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" });
      this.showNotification();
      this.ObtenerListaNombreReglamento();
    }
  };

  openModalUsuario = (Tp, Id) => {
    this.setState({
      showEditarUsuario: true,
      Titulo: Tp ? "Registrar Usuario" : "Editar Usuario",
      op: Tp ? 0 : 1,
      idConstancia: Tp ? this.state.IdNuevo : Id,
    });
  };

  render() {
    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }

    if (this.state.showEditarUsuario) {
      return (
        <EditarContancia
          show={this.state.showEditarUsuario}
          eventClick={this.eventoEditarUsuario}
          op={this.state.op}
          _id={this.state.idConstancia}
        />)
    }
    return (
      <div>
        <GridContainer  justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text={true}>
                <h4 className={classes.cardTitle}>Filtros</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      id="FiltrarNombres"
                      style={{ width: "23vw" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Nombre del reglamento"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        onChange: this.handleChange.bind(this),
                        value: this.state.FiltrarNombres,
                        name: "FiltrarNombres",
                        id: "FiltrarNombres",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button
                      size="sm"
                      type="submit"
                      color="info"
                      round
                      className="pull-right"
                      onClick={() => this.openModalUsuario(1)}
                    >
                      Nuevo
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 className="pull-left">Lista de reglamento</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                    <tbody>
                      {this.state.ListaConstancias.map((contancia, index) => {
                        return (
                          <tr>
                            <td onClick={() =>this.openModalUsuario(null, contancia._id)} style={{verticalAlign: "middle", cursor: "default"}}>
                              {contancia.Nombre}
                            </td>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>
                              <Button
                                color="info"
                                round
                                justIcon
                                className="pull-right mr-2"
                                size="sm"
                                onClick={() =>
                                  this.openModalUsuario(null, contancia._id)
                                }
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar tipo de constancia"}
            Cuerpo={"¿Está seguro de elimnar tipo de constancia?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />

          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatUsuarios);
