import React from "react";
import { createContext } from 'react';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import logo from "assets/img/logo.png";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Search, Close, Save } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

import RichTextEditor, { getTextAlignClassName, getTextAlignStyles,  getTextAlignBlockMetadata, } from 'react-rte';
import CanvasDraw from "react-canvas-draw";
// var RichTextEditor = require('react-rte');

import CookiesU from 'universal-cookie';
const cookies = new CookiesU();

class CatUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      // Plantilla: RichTextEditor.createValueFromString("<p><b>jutta</b> tesche</p>","html"),
      // Plantilla: RichTextEditor.createEmptyValue(),
      // PlantillaHTML: "",
      plantilla: "",
      Nombre: "",
      ListaConstancias: [],
      ListaConstanciasTemp: [],
      ListaModulos: [],
      Firma: "",
      FirmaSrc: "",
      errores: {},
      disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      showEditarUsuario: false,
      Total: 0,
      TotalSeleccionado: 0,
      Titulo: "Registrar un nuevo Usuario",
      IdNuevo: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
  }

  componentDidMount() {
    this.ReglamentoEscolarCatalogo();
  }

  async ReglamentoEscolarCatalogo() {
    this.setState({ cargando: true});
      await fetch(Globales.Url + "Catalogos/TipoReglamento/ReglamentoEscolarCatalogo", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({_idAlumno: cookies.get("idAlumnoFirma"),
         idTipo: cookies.get("idReglamento"),
         idIdioma: cookies.get("idIdioma"),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
            this.showNotification();
          } else {
            if (data.data !== null && data.data !== undefined) {

              let divPlantilla = document.getElementById("plantilla")
              divPlantilla.innerHTML = data.data
              this.setState({ plantilla: data.data, cargando: false, });
            }
            else {
              this.setState({ cargando: false, mensaje: "No hay reglamento registrado", colorNotificacion: "warning" });
            }
          }
        });
  }

  //Función para obtener el texto de los inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, });
  };

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  Validaciones(){
    var valido = true
    if(this.state.Firma == ""){
      valido = false
    }

    return valido
  }

  IncrustarFirmaAlumno() {
    let firma = "<div style='text-align: center;'>" +
      " <img src='" + this.state.Firma + "' width='250' style='border-bottom: 1px solid black;'/>" +
      "<div/>" +
      "Firma del alumno o tutor" +
      "</div>"
    return firma
  }

  async GenerarPdf() {
    this.setState({ cargando: true});
    // let plantilla = "<img src='" + logo + "' width='55px' /><br/>" + this.state.plantilla + this.IncrustarFirmaAlumno()
    let plantilla = this.state.plantilla + this.IncrustarFirmaAlumno()
      await fetch(Globales.Url + "archivos/GenerarReglamentoPDF", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({
          plantilla: plantilla,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
            this.showNotification();
          } else {
            // this.descargarPDF(data.data)
            // this.openPdf(data.data)
            this.guardarReglamentoPDF(data.data)
            this.setState({ cargando: false });
          }
        });
  }

  async guardarReglamentoPDF(pdf) {
    this.setState({ cargando: true});
      await fetch(Globales.Url + "archivos/guardarReglamentoPDF", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({
          pdf: pdf,
          idAlumno: cookies.get("NumeroIdAlumno"),
          idReglamento: cookies.get("NumeroIdReglamento"),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
            this.showNotification();
          } else {
            // this.openPdf(data.data)
            this.RegistrarFirmaReglamento()
            // this.setState({ cargando: false });
          }
        });
  }

  async RegistrarFirmaReglamento() {
    this.setState({ cargando: true});
      await fetch(Globales.Url + "Firmas/Firmas/RegistrarReglamentoFirmadoAlumno", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({
          idAlumno: cookies.get("idAlumnoFirma"),
          idReglamento: cookies.get("idReglamento"),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          if (data.error) {
            //Error al obtener los registros
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
            this.showNotification();
          } else {
            cookies.remove('idAlumnoFirma');
            cookies.remove('idReglamento');
            cookies.remove('idIdioma');
            window.location.href = '#/FirmaReglamento/Token';
            window.location.reload()
            this.setState({ cargando: false });
          }
        });
  }

  openPdf(basePdf) {
    let byteCharacters = atob(basePdf.replace(/^data:application\/\w+;base64,/, ""));
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: 'application/pdf;base64' });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }  

  descargarPDF(archivoBase64) {
    // var a = JSON.parse(localStorage.getItem("factid"));
    let nombre = "reglamento prueba";
    const linkSource = `${archivoBase64}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  } 

  async Guardar() {
    this.setState({ cargando: true});
    if(!this.Validaciones()){
      this.setState({ mensaje:"Proporcione firma",colorNotificacion: "danger",cargando: false});
      this.showNotification();
    }else{
      this.setState({ShowFirma:true})
      await this.GenerarPdf()
    }
    this.setState({ cargando: false});
  }

  render() {
    const { classes } = this.props;

// console.log(this.IncrustarFirmaAlumno());

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Reglamento escolar</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer style={{display: "flex", justifyContent: "center"}}>
                  <GridItem style={{ justifyContent: "center",border: "1px solid black",  }} xs={12} sm={12} md={12}>
                    <div style={{ 
                      margin: 'auto', padding: "10px", maxWidth: "815px", lineHeight: 1.5
                      // boxShadow: "3px 3px 3px 3px #D3DDE8", backgroundColor:"#FFF"
                       }} id="REGLAMENTO">

                      <div id="plantilla"> </div>
                      <div id="FirmaAlumno"></div>
                      <div id="FirmaAdmin"> </div>
                    </div>
                  </GridItem>
                  <GridContainer>
                    <GridItem style={{display: "flex", flexDirection: "column", alignItems: "center"}} xs={12} sm={12} md={12}>
                      <CanvasDraw
                        ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
                        brushRadius={3}
                        lazyRadius={0} //Suavisado (estabilizador)
                        canvasWidth={500}
                        canvasHeight={300}
                        style={{ border: '1px solid ' + (this.state.errores.Firma ? "red" : "#bfc3cf"), borderRadius: '7px' }}
                        hideGrid
                        hideInterface
                        disabled={this.state.disabled}
                        onChange={() => this.setState({ Firma: this.saveableCanvas.getDataURL() })}
                      />
                      <Button
                        color="danger"
                        onClick={() => {
                          this.saveableCanvas.eraseAll()
                          this.setState({ Firma: "" })
                        }}
                      >
                        <Close />
                        Borrar todo
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem className="pull-right">

            <Button
              color="success"
              onClick={() =>
                this.Guardar()
              }
            >
              <Save />
              GUARDAR
            </Button>
          </GridItem>
        </GridContainer>
        <div id="">

          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatUsuarios);
