import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
// import { View, Image, StyleSheet } from 'react-native'
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Print, Delete, Refresh, Add, Close, PlusOne } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import ModalPregunta from "components/Generales/ModalPregunta";
import SeleccionarIdioma from "../../Generales/ComponentesEmpresas/SeleccionarIdioma.jsx"
import SeleccionarAula from "../../Generales/ComponentesEmpresas/SeleccionarAula.jsx"
import ModalBuscarMaestros from "components/Generales/ModalBuscarMaestros";
import ModalCargando from "../../../components/Generales/ModalCargando.js"

// var moment = require('moment')
// let dateNow = moment().format('YYYY-MM-DDTHH:MM:SS')

const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

class ModalInfoVenta extends Component {

  constructor(props, context) {
    super(props, context);
    // this.escFunction = this.escFunction.bind(this);
    this.state = {
      Metodo: "RegistrarAsesoria",
      idAlumno:[],
      _id: null,
      Capcidad:0,
      Fecha: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      Aula: "null",
      Idioma: "null",
      Maestro: {},
      MaestroAnterior: "",
      Nivel: "null",
      Clase: "null",
      DiasClase: "null",
      Libro: "null",
      Clave:"",
      Unidad: "null",
      NombreMaestro:"",
      Modalidad: "null",
      ListaNiveles: [],
      //horario
      HoraFinPM:"am",
      HoraFin: "",
      HoraInicioPM:"am",
      HoraInicio: "",
      DiasClase: "null",

      //banderas
      disabledFecha:false,
      showAlumnos: false,
      showMaestros: false,
      cargando: false,
      // notificación
      br: false,
      mensaje: "",
      ColorNotificacion: "",

      ListaIdiomas: [],
      ListaAulas: [],
      ListaNiveles: [],
      ListaLibros: [],
      ListaUnidades: [],
      ListaAlumnos: [],
      ListaMaestros: [],
      NivelGrupo: [],

      //diasClase
      Lunes: false,
      Martes: false,
      Miercoles: false,
      Jueves: false,
      Viernes: false,
      Sabado: false,

      //Mensaje del cargando default
      CuerpoCargando: "Cargando"
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  // escFunction(event) {
  //   if (event.keyCode === 27) {
  //     this.props.eventClick("esc", "esc");
  //   }
  // }

  componentDidMount() {
    let aux = []
    if(this.props.Grupo.ListaNiveles == null){
      let respuesta = {
        _id: this.props.Grupo._id,
        idNivel: this.props.Grupo._idNivel
      }
      this.props.eventClick(respuesta)
    }
    let niveles = this.props.Grupo.ListaNiveles

    // console.log("PROPS___", niveles)
    if (niveles !== undefined && niveles !== null) {
      if (niveles.NivelGrupo.length > 1) {
        niveles.NivelGrupo.map(nivel => {
          aux.push(nivel.idNivel)
        })
      } else {
        aux.push(niveles.NivelGrupo.idNivel)
      }
    }
    aux.push(this.props.Grupo._idNivel)

    this.setState({
      _id: this.props.Grupo._id,
      NivelGrupo: aux,
      Nivel: JSON.stringify(this.props.Grupo._idNivel),
      Idioma: this.props.Grupo._idIdioma,
    }, () => {
      this.ListarNiveles()
    })
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------

  ObtenerInformacionGrupo() {
    fetch(Globales.Url + 'Grupos/Grupos/ListaAlumnosGrupoBasica', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      ,body: JSON.stringify({
        idgrupo:this.props.Grupo._id
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          // this.setState({ ColorNotificacion: 'danger', mensaje: data.mensaje });
          
          this.setState({ ListaAlumnos:[] })
        } else { //Filtrado exitoso
          if(data.Alumnos.length > 1){
            data.Alumnos.sort(function (a, b) {
              return (a.NombreCompleto).localeCompare(b.NombreCompleto);
            });
            this.setState({ ListaAlumnos:data.Alumnos })
          }else{
            var aux=[]
            aux.push(data.Alumnos)
            this.setState({ ListaAlumnos:aux })
          }

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }
  ListarNiveles() {
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaNivelesIdioma', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idIdioma: this.state.Idioma
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ ListaNiveles: [], Nivel: "null" });
          // this.showNotification();
        } else { //Filtrado exitoso
          var ListaNiveles = [];
          if(data.data.length > 1){
            ListaNiveles = data.data;
          }
          else{
            ListaNiveles.push(data.data);
          }

          let NivelGrupo = this.state.NivelGrupo
          let aux = []
          ListaNiveles.forEach( (nivel,key)=>{
            let index = NivelGrupo.findIndex( Item => JSON.stringify(nivel._id) == JSON.stringify(Item))
            // console.log(index," , ",nivel.Nombre)
            if(index != -1){
              // ListaNiveles.splice(key, 1);
              aux.push(nivel);
            }
          })

          // console.log(data.data)

          this.setState({ ListaNiveles: aux })
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }


  DescargarReporte(archivoBase64) {
    const url = 'data:application/pdf;base64,' + archivoBase64;

    let nombre = 'Historial de grupo '
    const linkSource = `${url}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    this.setState({
      cargando: false,
      mensaje: "Reporte generado exitosamente",
      ColorNotificacion: "success",
    });
    this.showNotification();
  }
  MandarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.eventClick('esc');
  }

  closeModal(mensaje, ColorNotificacion) {
    this.props.eventClick(mensaje, ColorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value, type } = e.target;

    //console.log(name + ": " + value)
    //console.log(name + ": " + typeof value)
      this.setState({
        [name]: value,
      });
  }
  handleChangeCheck(e){
    const { name, value, checked } = e.target;

    
    this.setState({
      [name]: checked,
    })
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
  
  EnviarInformacion(){
    if(this.state.Nivel == "null"){
      this.setState({
        ColorNotificacion: "warning",
        mensaje:"Selecciones nivel de grupo"
      })
      this.showNotification()
    }else{
      let respuesta = {
        _id: this.props.Grupo._id,
        idNivel: JSON.parse(this.state.Nivel)
      }
      this.props.eventClick(respuesta)
    }
  }


  render() {
    const { classes } = this.props;
    // console.log(this.state);
    //console.log(this.state.NombreMaestro)
    //console.log(this.state.Fecha)

    let agregar

    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }
    //console.log(this.state.Idioma)
    // console.log(this.state.ListaUnidades)
    return (
      <div>
        <Modal isOpen={this.props.show} className="static-modal" style={{ maxWidth: "700px", width: "100%" }} size="lg" toggle={this.cerrarModal} backdrop={true}>
          <Card >
            <CardHeader color={"info"}>
              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} md={10}>
                  <h4>Seleccionar nivel</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody xs={12} sm={12} md={12} lg={12}>

              <table className="col-xs-12 col-sm-12 col-md-12">
                <thead>
                  <tr>
                    <th colSpan={4} className="text-center" style={{ backgroundColor: "#f1f1f1", borderRadius: 3, fontWeight: "bold" }}>
                      Información del grupo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Nivel:</td>
                    <td colSpan={2}>
                      <FormControl
                        fullWidth
                        style={{ width: "25em" }}
                      >
                        <Select
                          value={this.state.Nivel}
                          onChange={this.handleChange}
                          defaultValue={"Seleccione"}
                          name="Nivel"
                        >
                          {this.state.ListaNiveles.length == 0 ?
                            <MenuItem value={"null"}>Cargando...</MenuItem>
                            :
                            <MenuItem value={"null"} disabled={true}>Selecionar nivel</MenuItem>
                          }
                          {this.state.ListaNiveles.length == 0 ? null :
                            this.state.ListaNiveles.map((Nivel) => {
                              return (<MenuItem value={JSON.stringify(Nivel._id)} >{Nivel.Nombre}</MenuItem>)
                            })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                </tbody>
              </table>

              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12}>
                  <Button tabIndex="2" size='sm' color='success' className='pull-right' round onClick={() => this.EnviarInformacion()}><Save />&nbsp;Guardar</Button>
                  <Button tabIndex="2" size='sm' color='' className='pull-right' style={{marginRight: "10px"}} round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;Cerrar</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
        {agregar}
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.ColorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalInfoVenta);