import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalTipoDeGasto from "./ModalTipoGastos";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Edit, Close, Add } from "@material-ui/icons";

class CatTipoGasto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      IdGasto: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      SiesPadre: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      FiltrarNombres: "",
      ListaTipoGasto: [],
      ListaTipoGastoTemp: [],
      ListaTipoGastoPadre: [],
      ListaTipoGastoPadreTemp: [],
      TipoGastoSeleccionado: {},
      RowSeleccionado: "",
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorIDIdioma: false,
      entroEliminar: false,
      notificacion: false,
      SeCargoYa: 0,
      Titulo: "Registrar",
      SeElimina: false,
      InformacionModal: "",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.eventoModalPregunta = this.eventoModalPregunta.bind(this);
  }

  componentDidMount() {
    this.ListaTipoGasto();
  }

  async ListaTipoGasto() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/TipoDeGastos/ListadoTipoGasto", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
      //console.log(data)
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
            ListaTipoGasto: [false],
            ListaTipoGastoTemp: [false],
          });
          this.showNotification();
        } else if (data.Existen === 1) {
          if (data.ListaDeTipoGasto.TipoGasto.length > 1) {
            this.setState({
              ListaTipoGastoPadre: data.ListaDeTipoGasto.TipoGasto,
              ListaTipoGastoPadreTemp: data.ListaDeTipoGasto.TipoGasto,
              Activo: 1,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          } else {
            this.setState({
              ListaTipoGastoPadre: [data.ListaDeTipoGasto.TipoGasto],
              ListaTipoGastoPadreTemp: [data.ListaDeTipoGasto.TipoGasto],
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          }
        } else {
          this.setState({
            ListaTipoGasto: [],
            ListaTipoGastoTemp: [],
            cargando: false,
            entroEliminar: false,
            colorNotificacion: "danger",
            mensaje: "No se encontraron tipo de gastos.",
            FiltrarNombres: "",
          });
          this.showNotification();
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ListaSubtipoGasto(IdGasto) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/TipoDeGastos/ListadoTipoSubGasto", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        IdGasto: IdGasto || this.state.IdGasto,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
            ListaTipoGasto: [false],
            ListaTipoGastoTemp: [false],
          });
          this.showNotification();
        } else if (data.Existen === 1) {
          if (data.ListaDeTipoGasto.TipoGasto.length > 1) {
            this.setState({
              ListaTipoGasto: data.ListaDeTipoGasto.TipoGasto,
              ListaTipoGastoTemp: data.ListaDeTipoGasto.TipoGasto,
              Activo: 1,
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          } else {
            this.setState({
              ListaTipoGasto: [data.ListaDeTipoGasto.TipoGasto],
              ListaTipoGastoTemp: [data.ListaDeTipoGasto.TipoGasto],
              cargando: false,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          }
        } else {
          this.setState({
            ListaTipoGasto: [],
            ListaTipoGastoTemp: [],
            cargando: false,
            entroEliminar: false,
            colorNotificacion: "danger",
            mensaje: "No se encontraron Subtipos de gastos relacionados.",
            FiltrarNombres: "",
          });
          this.showNotification();
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ValidarHijos(IdGasto) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/TipoDeGastos/ValidarEliminacion", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        IdGasto: IdGasto,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            cargando: false,
            SeElimina: false,
          });
          this.showNotification();
          return false;
        } else if (data.Eliminar) {
          this.setState({
            SeElimina: true,
          });
          return true;
        } else {
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            cargando: false,
            SeElimina: false,
          });
          this.showNotification();
          return false;
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  //Función para obtener el texto de los inputs
  MedioClick(e, InfoConcepto, Index) {
    const id = JSON.stringify(InfoConcepto._id);
    if (this.state.RowSeleccionado) {
      const Tabla = document.getElementById(this.state.RowSeleccionado);
      Tabla.style.backgroundColor = "#FFFFFF";
    }
    const element = document.getElementById(id + "-" + Index);
    element.style.backgroundColor = "#CAE9FD";
    this.ListaSubtipoGasto(JSON.parse(id));
    this.setState({
      RowSeleccionado: id + "-" + Index,
      IdGasto: InfoConcepto._id,
      TipoGastoSeleccionado: InfoConcepto,
    });
  }

  eventoModalEliminar(e) {
    this.setState({ show: true, TipoGastoSeleccionado: e });
    return;
  }

  async eventoModalPregunta(valor) {
    if (valor) {
      this.setState({ cargando: true });
      if (
        JSON.stringify(this.state.TipoGastoSeleccionado.IdGasto) ===
        JSON.stringify(this.state.SiesPadre)
      ) {
        const Eliminar = await this.ValidarHijos(
          this.state.TipoGastoSeleccionado._id
        );
        if (!Eliminar && !this.state.SeElimina) {
          this.setState({
            mensaje:
              "El tipo de gasto no puede ser eliminado, porque cuenta con sub gastos asociados.",
            show: false,
            cargando: false,
          });
          this.showNotification();
          return;
        }
      }
      this.EliminarTipoGasto(this.state.TipoGastoSeleccionado._id);
    } else {
      this.setState({ show: false });
    }
  }

  EliminarTipoGasto(id) {
    fetch(Globales.Url + "Catalogos/TipoDeGastos/EliminarTipoGasto", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ _id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error === undefined) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger", cargando: false });
        } else if (data.error) {
          //Error al obtener los registros
          this.setState({ colorNotificacion: "danger", cargando: false });
        } else {
          //Obtuvo los registros correctamente
          this.setState({ colorNotificacion: "success", cargando: false });
        }
        if (this.state.RowSeleccionado) {
          const Tabla = document.getElementById(this.state.RowSeleccionado);
          Tabla.style.backgroundColor = "#FFFFFF";
        }
        this.setState({
          mensaje: data.mensaje
            ? data.mensaje
            : "El tipo de gasto no puede ser eliminado, porque cuenta con Subtipo de gastos asociados.",
          show: false,
          ListaTipoGasto: [],
          ListaTipoGastoTemp: [],
          TipoGastoSeleccionado: "",
          IdGasto: {
            _increment: "0",
            _machine: "0",
            _pid: "0",
            _timestamp: "0",
          },
          RowSeleccionado: "",
        });
        this.showNotification();
        this.ListaTipoGasto();
      });
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  eventoModalConcepto = (mensaje, Color, Tipo) => {
    this.setState({showAgregar: false})
    //Cambiar valores por default
    if(mensaje !== "esc"){
      this.setState({
        Titulo: "Nuevo Concepto",
        mensaje: mensaje,
        InformacionModal: "",
        op: 0,
        entroEliminar: true,
        colorNotificacion: Color,
      });
      this.showNotification();
      if (Tipo !== 1) {
        this.ListaSubtipoGasto(this.state.IdGasto);
      } else {
        this.ListaTipoGasto();
      }
    }
  };

  OpenModalTipoGasto = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Nuevo tipo de gasto",
      InformacionModal: {
        Tipo: 1,
        Conceptos: this.state.ListaTipoGastoPadreTemp,
        IdGasto: this.state.IdGasto,
      },
      op: 0,
    });
  };

  OpenModalSubTipoGasto = () => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Nuevo subtipo de gasto",
      InformacionModal: {
        Tipo: 2,
        Conceptos: this.state.ListaTipoGastoTemp,
        IdGasto: this.state.IdGasto,
      },
      op: 0,
    });
  };

  OpenModalEditarTipoGasto = (e) => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Editar tipo de gasto",
      InformacionModal: {
        Tipo: 1,
        Conceptos: this.state.ListaTipoGastoPadreTemp,
        ...e,
      },
      op: 1,
    });
  };

  OpenModalEditarSubTipoGasto = (e) => {
    //Cambiar valores por default
    this.setState({
      showAgregar: true,
      Titulo: "Editar Subtipo de gasto",
      InformacionModal: {
        Tipo: 2,
        Conceptos: this.state.ListaTipoGastoTemp,
        ...e,
      },
      op: 1,
    });
  };

  render() {
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }
    let agregar;
    if (this.state.showAgregar == true) {
      agregar = (
        <ModalTipoDeGasto
          xs={18}
          Titulo={this.state.Titulo}
          InformacionModal={this.state.InformacionModal}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalConcepto}
          op={this.state.op}
        />
      );
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 className="pull-left">Tipo de gastos</h4>
                    <div className="pull-right">
                      <Button
                        color="info"
                        round
                        justIcon
                        size="sm"
                        onClick={() => this.OpenModalTipoGasto()}
                      >
                        <Add />
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="center">Nombre</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ListaTipoGastoPadre.map((Concepto, index) => {
                        return (
                          <tr
                            key={JSON.stringify(Concepto._id) + "-" + index}
                            id={JSON.stringify(Concepto._id) + "-" + index}
                          >
                            <td
                              onClickCapture={(e) =>
                                this.MedioClick(e, Concepto, index)
                              }
                              style={{verticalAlign: "middle", cursor: "pointer"}}
                            >
                              {Concepto.Nombre}
                            </td>
                            <td style={{verticalAlign: "middle", cursor: "pointer"}}>
                            <Button
                                color="danger"
                                round
                                justIcon
                                className="pull-right"
                                size="sm"
                                onClick={() =>
                                  this.eventoModalEliminar(Concepto)
                                }
                              >
                                <Close />
                              </Button>
                              <Button
                                color="info"
                                round
                                justIcon
                                className="pull-right mr-2"
                                size="sm"
                                onClick={() =>
                                  this.OpenModalEditarTipoGasto(Concepto)
                                }
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 className="pull-left">Subtipo de gastos</h4>
                    <div className="pull-right">
                      <Button
                        color="info"
                        round
                        justIcon
                        size="sm"
                        disabled={!this.state.RowSeleccionado}
                        onClick={() => this.OpenModalSubTipoGasto()}
                      >
                        <Add />
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                  <table
                    className="table table-sm table-hover"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th className="center">Nombre</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ListaTipoGasto.map((SubConcepto, index) => {
                        return (
                          <tr key={SubConcepto._id + "-" + index}>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>{SubConcepto.Nombre.slice(0, 30)}</td>
                            <td style={{verticalAlign: "middle", cursor: "default"}}>
                            <Button
                                color="danger"
                                round
                                justIcon
                                className="pull-right"
                                size="sm"
                                onClick={() =>
                                  this.eventoModalEliminar(SubConcepto)
                                }
                              >
                                <Close />
                              </Button>
                              <Button
                                color="info"
                                round
                                justIcon
                                className="pull-right mr-2"
                                size="sm"
                                onClick={() =>
                                  this.OpenModalEditarSubTipoGasto(SubConcepto)
                                }
                              >
                                <Edit />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar el tipo de gasto"}
            Cuerpo={"¿Está seguro de dar de baja?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(CatTipoGasto);
