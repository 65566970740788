import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import Globales from "utils/Globales";
import { Edit, People, DoneOutline, GetApp, Close, AssignmentReturned, AddCircle, PlusOne, DoubleArrow, ArrowUpward, Clear, DoneAll, AssignmentTurnedIn } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import cookie from 'js-cookie'


class ReporteGastos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaIdiomas: [],
      idIdioma: "TODO",
      Titulo: "",
      OpcionReportes: "",
      OpcionEstadoAlumno: "Activo",
      FechaInicio: moment().format("YYYY-MM-DD"),
      FechaFin: moment().format("YYYY-MM-DD"),

      //Checkboxes alumno
      matricula: true,
      nombre: true,
      clase: true,
      grupo: true,
      idioma: true,
      FechaInicioGrupo: true,
      FechaAsignacion: true,
      EstadoGrupo: true,
      Calificación: true,
      FechaTerminacion: true,
      FechaNacimiento: false,
      LugarNacimiento: false,
      DatosFiscales: false,
      EstadoAlumno: false,
      FamiliaresEstudiando: false,
      direccion: false,
      telefono: false,
      celular: false,
      Correo: false,
      Descuentos: false,
      FechaRegistro: false,
      medioInformacion: false,
      Parentesco: false,

      Campos: [0,1,3,4,5,40,44,41,42,43],
      errorFechainicio: false,
      errorFechaFin: false,
      colorNotificacion: 'success',
      colorTableRow: "white",
      pdfBase64: null,

      //Mensaje del cargando default
      CuerpoCargando: "Cargando"
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    
  }

  componentDidMount() {
    this.ListaGeneralIdiomas();
  }

  async ListaGeneralIdiomas() {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Prospectos/Prospectos/ListaGeneralIdiomas', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                if (data.ListaIdiomas.length > 1) {
                    this.setState({ ListaIdiomas: data.ListaIdiomas, Activo: 1, cargando: false });
                }
                else {
                    let ListaAuxEmpresas = [];
                    ListaAuxEmpresas.push(data.ListaIdiomas);
                    //console.log(ListaAux);
                    this.setState({ ListaIdiomas: ListaAuxEmpresas, Activo: 1, cargando: false });
                }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

  async ReporteExcelAlumnosBeca() {
    this.setState({cargando: true});

    await fetch(Globales.Url + 'Reportes/Reportes/ReporteExcelAlumnosBeca', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                this.DescargarPDF(data.ReporteExcel,3);
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

async ReporteAlumnosEstado() {
  this.setState({cargando: true});

  await fetch(Globales.Url + 'Reportes/Reportes/ReporteAlumnosEstado', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({EstadoAlumno: this.state.OpcionEstadoAlumno})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
            this.setState({cargando: false, pdfBase64: data.ReporteExcel});

              this.DescargarPDF(data.ReporteExcel,1);

          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

async ReporteAlumnosEstadoExcel() {
  this.setState({cargando: true});

  await fetch(Globales.Url + 'Reportes/Reportes/ReporteAlumnosEstadoExcel', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({EstadoAlumno: this.state.OpcionEstadoAlumno})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              this.DescargarPDF(data.ReporteExcel,0);
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

async ReporteAlumnosNuevoIngreso() {
  this.setState({cargando: true});
  const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

  await fetch(Globales.Url + 'Reportes/Reportes/ReporteAlumnosNuevoIngreso', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin, idIdioma: this.state.idIdioma !== "TODO" ? this.state.idIdioma : idVacio, Campos: this.state.Campos.toString()})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              this.DescargarPDF(data.ReporteExcel,2);
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

DescargarPDF(archivoBase64, int) {
  let nombre;
  let url;
  let fileName;
  if(int == 0){
    var ArchivoUTF8 = atob(archivoBase64)
    var universalBOM = "\uFEFF";
    url = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+ArchivoUTF8)
    // url = 'data:application/csv;base64,' + archivoBase64;
    nombre = 'Reporte alumnos por estado'
    fileName = `${nombre}.csv`;
  }
  else if(int == 1){
    url = 'data:application/pdf;base64,' + archivoBase64;
    nombre = 'Reporte alumnos por estado'
    fileName = `${nombre}.pdf`;
  }
  else if(int == 2){
    var ArchivoUTF8 = atob(archivoBase64)
    var universalBOM = "\uFEFF";
    url = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+ArchivoUTF8)
    // url = 'data:application/csv;base64,' + archivoBase64;
    nombre = 'Reporte alumnos nuevo ingreso'
    fileName = `${nombre}.csv`;
  }
  else{
    var ArchivoUTF8 = atob(archivoBase64)
    var universalBOM = "\uFEFF";
    url = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+ArchivoUTF8)
    // url = 'data:application/csv;base64,' + archivoBase64;
    nombre = 'Reporte alumnos con beca'
    fileName = `${nombre}.csv`;
  }
  
  const linkSource = `${url}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  this.setState({
    cargando: false,
    mensaje: "El archivo se generó exitosamente",
    colorNotificacion: "success",
  });
  this.showNotification();
}

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    
    this.setState({ [name]: value });
  }

  handleChangeCheck(e) {
    const { name, value, checked } = e.target;
  
    if(checked){
      //console.log(value)
      this.setState({ [name]: true, Campos: [...this.state.Campos, parseInt(value)]});
    }
    else{
      let index = this.state.Campos.findIndex(elem => elem == value)

      this.state.Campos.splice(index, 1);

      this.setState({ [name]: false, Campos: this.state.Campos});
    }
  }

  validarFechas(){
    let valido = true;

    if(this.state.FechaInicio !== "" || this.state.FechaFin !== ""){
      if(this.state.FechaFin < this.state.FechaInicio){
        valido = false;
        this.setState({errorFechainicio: false, errorFechaFin: true, mensaje: "La fecha final no puede ser antes de la fecha de inicio", colorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        valido = true;
        this.setState({errorFechaFin: false});
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos", colorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
      this.ReporteAlumnosNuevoIngreso();
    }
  }


  render() {
    const { 
      OpcionReportes,
      Campos,
      matricula, 
      nombre, 
      clase, 
      grupo, 
      idioma, 
      FechaInicioGrupo,
      FechaAsignacion,
      EstadoGrupo,
      Calificación,
      FechaTerminacion,
      FechaNacimiento, 
      LugarNacimiento, 
      DatosFiscales, 
      EstadoAlumno,
      FamiliaresEstudiando,
      direccion,
      telefono,
      celular,
      Correo,
      Descuentos,
      FechaRegistro,
      medioInformacion,
      Parentesco,
     } = this.state;
    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    let contenido;
    let Titulo = ""
    switch(OpcionReportes){
      case "Beca":
        Titulo = "Reporte de alumnos con beca";
        contenido = <Button size="sm" type="submit" color="info" round className="ml-3 mt-2" onClick={() => this.ReporteExcelAlumnosBeca()}><GetApp/>&nbsp;Exportar a excel</Button>;
        break;

      case "Estado":
        Titulo = "Reporte de alumnos por estado";
        contenido =
            <>
              <FormControl className={classes.formControl} size="small" variant="outlined">
              <InputLabel style={{marginTop: "10px"}} id="opciones">Estado</InputLabel>
                  <Select
                      labelId="opciones"
                      //fullWidth={true}
                      value={this.state.OpcionEstadoAlumno}
                      onChange={this.handleChange.bind(this)}
                      name="OpcionEstadoAlumno"
                      label="Estado"
                      style={{ width: "26.9em", marginTop: "10px" }}
                  >
                    <MenuItem disabled>Seleccione un estado</MenuItem>
                    <MenuItem value={"Activo"}>Activo</MenuItem>
                    <MenuItem value={"Inactivo"}>Inactivo</MenuItem>

                  </Select>
              </FormControl>
              <Button size="sm" type="submit" color="info" round className="ml-3 mt-3" onClick={() => this.ReporteAlumnosEstado()}><GetApp/>&nbsp;Obtener reporte</Button>
              <Button size="sm" type="submit" color="info" round className="ml-3 mt-3" onClick={() => this.ReporteAlumnosEstadoExcel()}><GetApp/>&nbsp;Exportar a excel</Button>
            </>;
        break;
      
      case "NvoIngreso":
        Titulo = "Reporte de alumnos de nuevo ingreso";
        contenido = 
          <>
            <div>
              <div style={{fontWeight: "bold"}}>Seleccione el periodo y el idioma</div>
              <TextField
                id="FechaInicio"
                style={{ width: "15em", marginTop: "10px", marginRight: "10px" }}
                variant="outlined"
                type="date"
                format="DD-MM-YYYY"
                size="small"
                label="Fecha inicio"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  onChange: this.handleChange.bind(this),
                  value: this.state.FechaInicio,
                  name: "FechaInicio",
                  id: "FechaInicio",
                  error: this.state.errorFechainicio,
                  success: !this.state.errorFechainicio
                }}
              />

              <TextField
                id="FechaFin"
                style={{ width: "15em", marginTop: "10px" }}
                variant="outlined"
                type="date"
                format="DD-MM-YYYY"
                size="small"
                label="Fecha fin"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  onChange: this.handleChange.bind(this),
                  value: this.state.FechaFin,
                  name: "FechaFin",
                  id: "FechaFin",
                  error: this.state.errorFechaFin,
                  success: !this.state.errorFechaFin
                }}
              />
            </div>
            <div>
              <FormControl className={classes.formControl} size="small" variant="outlined">
              <InputLabel style={{marginTop: "10px"}} id="idioma">Idioma</InputLabel>
                  <Select
                      labelId="idioma"
                      //fullWidth={true}
                      value={this.state.idIdioma}
                      onChange={this.handleChange.bind(this)}
                      name="idIdioma"
                      label="Idioma"
                      style={{ width: "26.9em", marginTop: "10px" }}
                  >

                    <MenuItem value={"TODO"}>Todos los idiomas</MenuItem>
                    {this.state.ListaIdiomas.map(idioma =>
                      <MenuItem value={idioma._id}>{(idioma.Nombre)}</MenuItem>
                    )}

                  </Select>
              </FormControl>
              <Button size="sm" type="submit" color="info" round className="ml-3 mt-3" onClick={() => this.validarFechas()}><GetApp/>&nbsp;Exportar a excel</Button>
            </div>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Información del alumno</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={matricula} value={"0"} onChange={this.handleChangeCheck.bind(this)} name="matricula" />}
                          label="Matrícula"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={nombre} value={"1"} onChange={this.handleChangeCheck.bind(this)} name="nombre" />}
                          label="Nombre"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={clase} value={"3"} onChange={this.handleChangeCheck.bind(this)} name="clase" />}
                          label="Clase"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={grupo} value={"4"} onChange={this.handleChangeCheck.bind(this)} name="grupo" />}
                          label="Grupo"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={idioma} value={"5"} onChange={this.handleChangeCheck.bind(this)} name="idioma" />}
                          label="Idioma"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={FechaInicioGrupo} value={"40"} onChange={this.handleChangeCheck.bind(this)} name="FechaInicioGrupo" />}
                          label="Fecha de Inicio del grupo"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={FechaAsignacion} value={"44"} onChange={this.handleChangeCheck.bind(this)} name="FechaAsignacion" />}
                          label="Fecha de asignación"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={EstadoGrupo} value={"41"} onChange={this.handleChangeCheck.bind(this)} name="EstadoGrupo" />}
                          label="Estado(Grupo)"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={Calificación} value={"42"} onChange={this.handleChangeCheck.bind(this)} name="Calificación" />}
                          label="Calificación"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={FechaTerminacion} value={"43"} onChange={this.handleChangeCheck.bind(this)} name="FechaTerminacion" />}
                          label="Fecha de terminación"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={FechaNacimiento} value={"6"} onChange={this.handleChangeCheck.bind(this)} name="FechaNacimiento" />}
                          label="Fecha de Nacimiento"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={LugarNacimiento} value={"7"} onChange={this.handleChangeCheck.bind(this)} name="LugarNacimiento" />}
                          label="Lugar de nacimiento"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={DatosFiscales} value={"8"} onChange={this.handleChangeCheck.bind(this)} name="DatosFiscales" />}
                          label="Datos fiscales"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={EstadoAlumno} value={"9"} onChange={this.handleChangeCheck.bind(this)} name="EstadoAlumno" />}
                          label="Estado del alumno"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={FamiliaresEstudiando} value={"10"} onChange={this.handleChangeCheck.bind(this)} name="FamiliaresEstudiando" />}
                          label="Familiares estudiando"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={direccion} value={"11"} onChange={this.handleChangeCheck.bind(this)} name="direccion" />}
                          label="Dirección"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={telefono} value={"12"} onChange={this.handleChangeCheck.bind(this)} name="telefono" />}
                          label="Teléfono"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={celular} value={"13"} onChange={this.handleChangeCheck.bind(this)} name="celular" />}
                          label="Celular"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={Correo} value={"14"} onChange={this.handleChangeCheck.bind(this)} name="Correo" />}
                          label="Correo electronico"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={FechaRegistro} value={"19"} onChange={this.handleChangeCheck.bind(this)} name="FechaRegistro" />}
                          label="Fecha de registro"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={medioInformacion} value={"20"} onChange={this.handleChangeCheck.bind(this)} name="medioInformacion" />}
                          label="Medio por el cual conoció la escuela"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={Parentesco} value={"45"} onChange={this.handleChangeCheck.bind(this)} name="Parentesco" />}
                          label="Parentesco"
                        />
                      </FormGroup>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </>
        break;
      default:
        break;
    }

    //console.log(Campos)
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Reportes alumnos</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="opciones">Tipo de reporte</InputLabel>
                        <Select
                            labelId="opciones"
                            //fullWidth={true}
                            value={this.state.OpcionReportes}
                            onChange={this.handleChange.bind(this)}
                            name="OpcionReportes"
                            label="Tipo de reporte"
                            style={{ width: "26.9em", marginTop: "10px" }}
                        >

                          <MenuItem disabled={true} value={""}>Seleccione un tipo de reporte</MenuItem>
                          <MenuItem value={"Estado"}>Alumnos por estado</MenuItem>
                          <MenuItem value={"Beca"}>Alumnos con beca</MenuItem>
                          <MenuItem value={"NvoIngreso"}>Alumnos nuevo ingreso</MenuItem>

                        </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card hidden={this.state.OpcionReportes == ""}>
            <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>{Titulo}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {contenido}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar grupo"}
            Cuerpo={"¿Está seguro que desea eliminar grupo?"}
            show={this.state.show}
          eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(ReporteGastos);