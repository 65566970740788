import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalRegistrarGasto from "./ModalRegistrarGasto.jsx"
import ModalImportarGastos from "./ModalImportarGastos.jsx"
import ModalPreguntaAfectaEfectivo from "./ModalPreguntaAfectaEfectivo";
import Globales from "utils/Globales";
import { Edit, Info, InfoTwoTone, Description, InfoOutlined, Close, AddCircle, Search, Clear, Publish, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'

var Permisos = localStorage.getItem("Permisos") == null ? { Modulo:[] } : JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}
else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

class Colaboradores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            GastoSeleccionado: '',
            FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaFin: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            ListaEmpresas: [],
            IDEmpresa: "",
            ListaTipoGasto: [],
            TipoGastoSeleccionado: "",
            ListaTipoSubGasto: [],
            SubtipoGastoSeleccionado: "",
            ListaProveedor: [],
            ProveedorSeleccionado: "",
            opcionOrigenGasto: this.ValidarModulo(72) ? null : "Caja",
            opcionDetalleGastos: 'Realizados',
            ListaGastos: [],
            Disabled: false,
            mensaje: "",
            br: false,
            show: false,
            cargando: false,
            CheckAll: false,
            colorNotificacion: 'success',
            colorTableRow: "white",
            errorIDIdioma: false,
            entroFiltrar: false,
            notificacion: false,
            TotalGastos: 0,
            TotalSeleccionado: 0,
            CuerpoCargando: "Cargando",
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
        //this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.obtenerEmpresasActivas();
        this.TipoGastoListado();
        this.ListaGeneralProveedores();
    }

    //Función para pasar datos de los alumnos desde el modal hacia la vista
    RegresarMensaje = (mensaje) => {
        this.setState({mensaje: mensaje, colorNotificacion: "success", opcionDetalleGastos: "Realizados"});
        this.showNotification();
        this.ListadoGastosFiltrado(false);
    }

    async obtenerEmpresasActivas() {
        this.setState({cargando:true})
        await fetch(Globales.Url + 'Facturacion/Facturacion/ListadoEmpresas', {  //ObtenerInformacionMultipleEmpresa  //ListadoEmpresas
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify()
        }
        ).then(res => res.json())
          .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, cargando:false });
              this.showNotification();
            } else { //Filtrado exitoso
    
              if (data.ListaEmpresas.length > 1) {
                this.setState({ ListaEmpresas: data.ListaEmpresas, Activo: 1, cargando:false });
              }
              else {
                let ListaAuxEmpresas = [];
                ListaAuxEmpresas.push(data.ListaEmpresas);
                //console.log(ListaAux);
                this.setState({ ListaEmpresas: ListaAuxEmpresas, Activo: 1, cargando:false });
              }
            }
          })
          .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
      }

    async TipoGastoListado() {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Gastos/Gastos/TipoGastoListado', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify()
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    if (data.ListaTipoGasto.length > 1) {
                        this.setState({ ListaTipoGasto: data.ListaTipoGasto, Activo: 1, cargando: false });
                    }
                    else {
                        let ListaAux = [];
                        ListaAux.push(data.ListaTipoGasto);
                        //console.log(ListaAux);
                        this.setState({ ListaTipoGasto: ListaAux, Activo: 1, cargando: false });
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async TipoSubGastoListado(idGasto) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Gastos/Gastos/TipoSubGastoListado', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({id: idGasto})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    if (data.ListaTipoSubGasto.length > 1) {
                        this.setState({ ListaTipoSubGasto: data.ListaTipoSubGasto, Activo: 1, cargando: false });
                    }
                    else {
                        let ListaAux = [];
                        ListaAux.push(data.ListaTipoSubGasto);
                        //console.log(ListaAux);
                        this.setState({ ListaTipoSubGasto: ListaAux, Activo: 1, cargando: false });
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async ListaGeneralProveedores() {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Gastos/Gastos/ListaGeneralProveedores', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify()
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    if (data.ListaProveedor.length > 1) {
                        this.setState({ ListaProveedor: data.ListaProveedor, Activo: 1, cargando: false });
                    }
                    else {
                        let ListaAux = [];
                        ListaAux.push(data.ListaProveedor);
                        //console.log(ListaAux);
                        this.setState({ ListaProveedor: ListaAux, Activo: 1, cargando: false });
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    RedireccionDetalleGastos(){
        switch(this.state.opcionDetalleGastos){
            case "Realizados":
                this.ListadoGastosFiltrado(true);
                break;

            case "Cancelados":
                this.ListadoGastosFiltradoCancelados(true);
                break;
        }
    }

    async ListadoGastosFiltrado(bool) {
        let idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
        this.setState({cargando: true, ListaGastos: [], TotalGastos: 0});
        await fetch(Globales.Url + 'Gastos/Gastos/ListadoGastosFiltrado', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({
                FechaInicio: this.state.FechaInicio,
                FechaFinal: this.state.FechaFin,
                IdProveedor: this.state.ProveedorSeleccionado !== '' ? this.state.ProveedorSeleccionado : idVacio,
                idTipoGasto: this.state.TipoGastoSeleccionado !== '' ? this.state.TipoGastoSeleccionado._id : idVacio,
                idSubtipoGasto: this.state.SubtipoGastoSeleccionado !== '' ? this.state.SubtipoGastoSeleccionado : idVacio,
                idEmpresa: this.state.IDEmpresa !== '' ? this.state.IDEmpresa : idVacio,
                origen: this.ValidarModulo(72) ? this.state.opcionOrigenGasto : "Caja"
            })
        }
        ).then(res => res.json())
            .then(data => {
                // console.log(data);
                if (data.error) { //Error en la respuesta
                    if(bool){
                        this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false, ListaGastos: [] });
                        this.showNotification();
                    }
                    else{
                        this.setState({ cargando: false, ListaGastos: [] });
                    }
                    
                } else { //Filtrado exitoso
                    if (data.ListaGastos.length > 1) {
                        let aux = 0;
                        for(const item of data.ListaGastos){
                            aux += parseFloat(item.Monto);
                        }

                        this.setState({ ListaGastos: data.ListaGastos, TotalGastos: aux, Activo: 1, cargando: false });
                    }
                    else {
                        let ListaAux = [];
                        ListaAux.push(data.ListaGastos);
                        //console.log(ListaAux);
                        this.setState({ ListaGastos: ListaAux, TotalGastos: parseFloat(data.ListaGastos.Monto), Activo: 1, cargando: false });
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async ListadoGastosFiltradoCancelados(bool) {
        let idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Gastos/Gastos/ListadoGastosFiltradoCancelados', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({
                FechaInicio: this.state.FechaInicio,
                FechaFinal: this.state.FechaFin,
                IdProveedor: this.state.ProveedorSeleccionado !== '' ? this.state.ProveedorSeleccionado : idVacio,
                idTipoGasto: this.state.TipoGastoSeleccionado !== '' ? this.state.TipoGastoSeleccionado._id : idVacio,
                idSubtipoGasto: this.state.SubtipoGastoSeleccionado !== '' ? this.state.SubtipoGastoSeleccionado : idVacio,
                idEmpresa: this.state.IDEmpresa !== '' ? this.state.IDEmpresa : idVacio,
                origen: this.state.opcionOrigenGasto
            })
        }
        ).then(res => res.json())
            .then(data => {
                // console.log(data);
                if (data.error) { //Error en la respuesta
                    if(bool){
                        this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false, ListaGastos: [], TotalGastos: 0 });
                        this.showNotification();
                    }
                    else{
                        this.setState({ cargando: false, ListaGastos: [], TotalGastos: 0 });
                    }
                    
                } else { //Filtrado exitoso
                    if (data.ListaGastos.length > 1) {

                        let aux = 0;
                        for(const item of data.ListaGastos){
                            aux += parseFloat(item.Monto);
                        }

                        this.setState({ ListaGastos: data.ListaGastos, TotalGastos: aux, Activo: 1, cargando: false });
                    }
                    else {
                        let ListaAux = [];
                        ListaAux.push(data.ListaGastos);
                        //console.log(ListaAux);
                        this.setState({ ListaGastos: ListaAux, TotalGastos: parseFloat(data.ListaGastos.Monto), Activo: 1, cargando: false });
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async CancelarGasto(bool) {
        let IDcookie = JSON.parse(cookie.get('id'));
        //this.setState({cargando: true});
        await fetch(Globales.Url + 'Gastos/Gastos/CancelarGasto', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({IdGasto: this.state.GastoSeleccionado._id, IdUsuario: IDcookie, AfectaEfectivo: bool })
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    this.setState({mensaje: data.mensaje, colorNotificacion: "success", cargando: false})
                    this.showNotification();

                    this.ListadoGastosFiltrado(false);
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

//Funciones para abrir modales

    openModalRegistrarGasto = () => {
        this.setState({ GastoSeleccionado: [], showModalRegistrarGasto: true, Titulo: "...", op: 0 });
    }

    openModalEditarGasto = (gasto) => {
        this.setState({ GastoSeleccionado: gasto, showModalRegistrarGasto: true, Titulo: "...", op: 1 });
    }

    openModalImportarGasto = () => {
        this.setState({ showModalImportarGasto: true, Titulo: "Importar gastos", op: 0 });
    }

    openModalPregunta = (gasto) => {
        this.setState({ GastoSeleccionado: gasto, show: true });
    }

    openPreguntaAfectaEfectivo = () => {
        this.setState({ showAfectaEf: true });
    }

//Funciónes que recuperan valores para los modales

    eventoModalRegistrarGasto = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showModalRegistrarGasto: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalImportarGasto = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showModalImportarGasto: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalPregunta = (valor) => {
        this.setState({ show: false })
        if(valor){
            if(this.state.GastoSeleccionado.TipoPago !== "Efectivo"){
                this.openPreguntaAfectaEfectivo();
            }
            else if(this.state.GastoSeleccionado.TipoPago == "Efectivo"){
                this.CancelarGasto(true);
            }
        }
    }

    eventoModalPreguntaAfectaEfectivo = (valor) => {
        this.setState({ showAfectaEf: false })
        if(valor){
            this.CancelarGasto(true);
        }
        else{
            this.CancelarGasto(false);
        }
    }

    //Función que habilita la notificación
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
        //console.log(e.target);
        //console.log(value)

        switch (name){
            case "TipoGastoSeleccionado":
                if(value !== ""){
                    this.TipoSubGastoListado(value._id);
                    this.setState({ [name]: value });
                }
                else{
                    this.setState({ [name]: value, ListaTipoSubGasto: [], SubtipoGastoSeleccionado: "" });
                }
                
                break;

            case "opcionDetalleGastos":
                this.setState({[name]: value, ListaGastos: []})
                break;
            default:
                this.setState({ [name]: value });
                break;
        }

        //this.setState({ [name]: value });
    }

    ValidarModulo(modulo){
        let valido = false;
    
        Permisos.map(permiso => {
          if(parseInt(permiso.NumeroModulo) == modulo){
            valido = true
          }
        })
    
        return valido
      }

    render() {
    const { classes } = this.props;
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    const convertirPesos = (number) => {
        return new Intl.NumberFormat("ES-MX", {
          style: 'currency',
          currency: 'MXN'
        }).format(number);
      }

        let agregar;
        if (this.state.showModalRegistrarGasto == true) {
            agregar =
                <ModalRegistrarGasto
                    Titulo={this.state.Titulo}
                    showModalRegistrarGasto={this.state.showModalRegistrarGasto}
                    eventClick={this.eventoModalRegistrarGasto}
                    op={this.state.op}
                    GastoSeleccionado={this.state.GastoSeleccionado}
                    parentCallback={this.RegresarMensaje}
                />
        }

        if (this.state.showModalImportarGasto == true) {
            agregar =
                <ModalImportarGastos
                    Titulo={this.state.Titulo}
                    showModalImportarGasto={this.state.showModalImportarGasto}
                    eventClick={this.eventoModalImportarGasto}
                    op={this.state.op}
                    //GastoSeleccionado={this.state.GastoSeleccionado}
                    parentCallback={this.RegresarMensaje}
                />
        }

        if(this.state.showAfectaEf == true){
            agregar =
                <ModalPreguntaAfectaEfectivo
                    Titulo={"Cancelación de pago"}
                    Cuerpo={"¿La cancelación de este pago afecta su efectivo?"}
                    show={this.state.showAfectaEf}
                    eventClick={this.eventoModalPreguntaAfectaEfectivo}
                />
        }
        

        if (this.state.cargando == true) {
            agregar = (
              <ModalCargando
                Cuerpo={this.state.CuerpoCargando}
                show={this.state.cargando}
              />
            );
          };

        let FormaPago;
        let Gastos;
        let Origen;
        if(this.state.ListaGastos.length > 0){
            if(this.state.opcionDetalleGastos == "Realizados"){
                Gastos =
                this.state.ListaGastos.map(gasto => {

                    switch(gasto.TipoPago){
                        case "Tarjeta_de_Crédito":
                            FormaPago = "Tarjeta de Crédito"
                            break;
                        
                        case "Tarjeta_de_Débito":
                            FormaPago = "Tarjeta de Débito"
                            break;

                        default:
                            FormaPago = gasto.TipoPago
                            break;
                    }

                    if(gasto.OrigenEfectivo == "Caja_Administración"){
                        Origen = "Caja administración"
                    }
                    else{
                        Origen = gasto.OrigenEfectivo;
                    }

                    return(
                        <tr style={{ cursor: "default"}} hover>
                            <td onClick={() => this.openModalEditarGasto(gasto)} style={{verticalAlign: "middle", fontSize: "12.5px"}}>{moment(gasto.FechaRegistro).format("DD-MM-YYYY")}</td>
                            <td onClick={() => this.openModalEditarGasto(gasto)} style={{verticalAlign: "middle", fontSize: "12.5px", width: "10px"}}>{Origen}</td>
                            <td onClick={() => this.openModalEditarGasto(gasto)} style={{verticalAlign: "middle", fontSize: "12.5px"}}>{gasto.NombreTipoGasto !== null && gasto.NombreTipoGasto !== undefined ? gasto.NombreTipoGasto : "Sin especificar"}</td>
                            <td onClick={() => this.openModalEditarGasto(gasto)} style={{verticalAlign: "middle", fontSize: "12.5px", width: "10%"}}>{gasto.NombreSubtipoGasto !== null && gasto.NombreSubtipoGasto !== undefined ? gasto.NombreSubtipoGasto : "Sin especificar"}</td>
                            <td onClick={() => this.openModalEditarGasto(gasto)} style={{verticalAlign: "middle", fontSize: "12.5px"}}>{gasto.NombreProveedor !== null ? gasto.NombreProveedor : "Sin especificar"}</td>
                            <td onClick={() => this.openModalEditarGasto(gasto)} style={{verticalAlign: "middle", fontSize: "12.5px", width: "26%"}}>{gasto.Descripcion}</td>
                            <td onClick={() => this.openModalEditarGasto(gasto)} style={{verticalAlign: "middle", fontSize: "12.5px"}}>{FormaPago}</td>
                            <td onClick={() => this.openModalEditarGasto(gasto)} style={{verticalAlign: "middle", fontSize: "12.5px"}}>{convertirPesos(gasto.Monto)}</td>
                            <td onClick={() => this.openModalEditarGasto(gasto)} style={{verticalAlign: "middle", fontSize: "12.5px"}}>{gasto.NombreEmpresa}</td>
                            <td style={{width: "10%"}}>
                                <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar gasto" color="danger" className="pull-right" round justIcon size="sm" onClick={() => this.openModalPregunta(gasto)}><Close /></Button>
                                {this.ValidarModulo(71) ? <Button data-toggle="tooltip" data-placement="bottom" title="Editar gasto" color="info" className="pull-right" round justIcon size="sm"  onClick={() => this.openModalEditarGasto(gasto)}><Edit /></Button> : ""}
                            </td>
                        </tr>
                    )
                })
            }
            else if(this.state.opcionDetalleGastos == "Cancelados"){
                Gastos =
                this.state.ListaGastos.map(gasto => {

                    switch(gasto.TipoPago){
                        case "Tarjeta_de_Crédito":
                            FormaPago = "Tarjeta de Crédito"
                            break;
                        
                        case "Tarjeta_de_Débito":
                            FormaPago = "Tarjeta de Débito"
                            break;

                        default:
                            FormaPago = gasto.TipoPago
                            break;
                    }

                    return(
                        <tr style={{ cursor: "default"}} hover>
                            <td style={{paddingTop: "10px", paddingBottom: "10px", verticalAlign: "middle"}}>{moment(gasto.FechaRegistro).format("DD-MM-YYYY")}</td>
                            <td style={{verticalAlign: "middle"}}>{moment(gasto.FechaCancelacion).format("DD-MM-YYYY")}</td>
                            <td style={{verticalAlign: "middle"}}>{gasto.OrigenEfectivo}</td>
                            <td style={{verticalAlign: "middle"}}>{gasto.NombreTipoGasto !== null && gasto.NombreTipoGasto !== undefined ? gasto.NombreTipoGasto : "Sin especificar"}</td>
                            <td style={{verticalAlign: "middle"}}>{gasto.NombreSubtipoGasto !== null && gasto.NombreSubtipoGasto !== undefined ? gasto.NombreSubtipoGasto : "Sin especificar"}</td>
                            <td style={{verticalAlign: "middle"}}>{gasto.NombreProveedor !== null ? gasto.NombreProveedor : "Sin especificar"}</td>
                            <td style={{verticalAlign: "middle"}}>{gasto.Descripcion}</td>
                            <td style={{verticalAlign: "middle"}}>{FormaPago}</td>
                            <td style={{verticalAlign: "middle"}}>{convertirPesos(gasto.Monto)}</td>
                            <td style={{verticalAlign: "middle"}}>{gasto.NombreEmpresa}</td>
                        </tr>
                    )
                })
            }
            
        }
        else{
            Gastos = 
            <>
                <td colSpan={9} style={{textAlign: "center"}}>NO HAY GASTOS REGISTRADOS</td>
            </>
        }


//console.log(this.state.ListaTipoGasto)
//console.log(this.state.ListaTipoSubGasto)
//console.log(this.state.TipoGastoSeleccionado)
//console.log(this.state.ListaGastos)
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Gastos</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="FechaInicio"
                                        style={{ width: "18.2vw", marginTop: "10px" }}
                                        variant="outlined"
                                        type="date"
                                        format="DD-MM-YYYY"
                                        size="small"
                                        label="Fecha de inicio"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            onChange: this.handleChange.bind(this),
                                            value: this.state.FechaInicio,
                                            name: "FechaInicio",
                                            id: "FechaInicio",
                                            //error: this.state.errorFechainicio,
                                            //success: !this.state.errorFechainicio
                                        }}
                                    />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="FechaFin"
                                        style={{ width: "18.2vw", marginTop: "10px" }}
                                        variant="outlined"
                                        type="date"
                                        format="DD-MM-YYYY"
                                        size="small"
                                        label="Fecha final"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            onChange: this.handleChange.bind(this),
                                            value: this.state.FechaFin,
                                            name: "FechaFin",
                                            id: "FechaFin",
                                            //error: this.state.errorFechainicio,
                                            //success: !this.state.errorFechainicio
                                        }}
                                    />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <FormControl className={classes.formControl} size="small" variant="outlined">
                                        <InputLabel style={{marginTop: "10px"}} id="empresalabel">Empresa</InputLabel>
                                            <Select
                                                labelId="empresalabel"
                                                //fullWidth={true}
                                                value={this.state.IDEmpresa}
                                                onChange={this.handleChange.bind(this)}
                                                name="IDEmpresa"
                                                //fullWidth={true}
                                                label="Empresa"
                                                style={{ width: "18.2vw", marginTop: "10px" }}
                                                //error={this.state.errorIDIdioma}
                                                //success={!this.state.errorIDIdioma}
                                            >
                                                <MenuItem disabled>Seleccione una empresa</MenuItem>
                                                <MenuItem value={""}>Todo</MenuItem>
                                                {this.state.ListaEmpresas.map(Empresa =>
                                                <MenuItem value={Empresa._id}>{(Empresa.RazonSocial).toUpperCase()}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <FormControl className={classes.formControl} size="small" variant="outlined">
                                        <InputLabel style={{marginTop: "10px"}} id="tipolabel">Tipo de gasto</InputLabel>
                                            <Select
                                                labelId="tipolabel"
                                                //fullWidth={true}
                                                value={this.state.TipoGastoSeleccionado}
                                                defaultValue={this.state.IdDefault}
                                                onChange={this.handleChange.bind(this)}
                                                name="TipoGastoSeleccionado"
                                                //fullWidth={true}
                                                label="Tipo de gasto"
                                                style={{ width: "18.2vw", marginTop: "10px" }}
                                                //error={this.state.errorIDIdioma}
                                                //success={!this.state.errorIDIdioma}
                                            >
                                                <MenuItem disabled>Seleccione un tipo de gasto</MenuItem>
                                                <MenuItem value={""}>Todo</MenuItem>
                                                {this.state.ListaTipoGasto.map(tipo =>
                                                <MenuItem value={tipo}>{(tipo.Nombre).toUpperCase()}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <FormControl className={classes.formControl} size="small" variant="outlined">
                                        <InputLabel style={{marginTop: "10px"}} id="tipolabel">Subtipo de gasto</InputLabel>
                                            <Select
                                                labelId="tipolabel"
                                                //fullWidth={true}
                                                value={this.state.SubtipoGastoSeleccionado}
                                                defaultValue={this.state.IdDefault}
                                                onChange={this.handleChange.bind(this)}
                                                name="SubtipoGastoSeleccionado"
                                                //fullWidth={true}
                                                label="Subtipo de gasto"
                                                style={{ width: "18.2vw", marginTop: "10px" }}
                                                //error={this.state.errorIDIdioma}
                                                //success={!this.state.errorIDIdioma}
                                            >
                                                <MenuItem disabled>Seleccione un subtipo de gasto</MenuItem>
                                                <MenuItem value={""}>Todo</MenuItem>
                                                {this.state.ListaTipoSubGasto.map(subtipo =>
                                                <MenuItem value={subtipo._id}>{(subtipo.Nombre).toUpperCase()}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <FormControl className={classes.formControl} size="small" variant="outlined">
                                        <InputLabel style={{marginTop: "10px"}} id="proveedorlabel">Proveedor</InputLabel>
                                            <Select
                                                labelId="proveedorlabel"
                                                //fullWidth={true}
                                                value={this.state.ProveedorSeleccionado}
                                                defaultValue={this.state.IdDefault}
                                                onChange={this.handleChange.bind(this)}
                                                name="ProveedorSeleccionado"
                                                //fullWidth={true}
                                                label="Proveedor"
                                                style={{ width: "18.2vw", marginTop: "10px" }}
                                                //error={this.state.errorIDIdioma}
                                                //success={!this.state.errorIDIdioma}
                                            >
                                                <MenuItem disabled>Seleccione un proveedor</MenuItem>
                                                <MenuItem value={""}>Todo</MenuItem>
                                                {this.state.ListaProveedor.map(proveedor =>
                                                <MenuItem value={proveedor._id}>{(proveedor.Razonsocial).toUpperCase()}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    {this.ValidarModulo(72) ? <GridItem xs={12} sm={12} md={4}>
                                        <FormControl className={classes.formControl} size="small" variant="outlined">
                                        <InputLabel style={{marginTop: "10px"}} id="origenlabel">Origen de gasto</InputLabel>
                                            <Select
                                                labelId="origenlabel"
                                                //fullWidth={true}
                                                value={this.state.opcionOrigenGasto}
                                                onChange={this.handleChange.bind(this)}
                                                name="opcionOrigenGasto"
                                                //fullWidth={true}
                                                label="Origen de gasto"
                                                style={{ width: "18.2vw", marginTop: "10px" }}
                                                //error={this.state.errorIDIdioma}
                                                //success={!this.state.errorIDIdioma}
                                            >
                                                <MenuItem value={null}>Todo</MenuItem>
                                                <MenuItem value={"Caja"}>Caja</MenuItem>
                                                <MenuItem value={"Banco"}>Banco</MenuItem>
                                                <MenuItem value={"Caja_Administración"}>Caja Administración</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem> : ""}
                                    <GridItem xs={12} sm={12} md={8}>
                                        <FormControl className={classes.formControl} size="small" variant="outlined">
                                        <InputLabel style={{marginTop: "10px"}} id="origenlabel">Vista de detalle de gastos</InputLabel>
                                            <Select
                                                labelId="origenlabel"
                                                //fullWidth={true}
                                                value={this.state.opcionDetalleGastos}
                                                onChange={this.handleChange.bind(this)}
                                                name="opcionDetalleGastos"
                                                //fullWidth={true}
                                                label="Vista de detalle de gastos"
                                                style={{ width: "18.2vw", marginTop: "10px" }}
                                                //error={this.state.errorIDIdioma}
                                                //success={!this.state.errorIDIdioma}
                                            >
                                                <MenuItem disabled>Seleccione una opción</MenuItem>
                                                <MenuItem value={'Realizados'}>Realizados</MenuItem>
                                                <MenuItem value={'Cancelados'}>Cancelados</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Button size="sm" type="submit" color="info" round className="ml-3 mt-3" onClick={() => this.RedireccionDetalleGastos()}><Search/>&nbsp;Buscar</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="info" text>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <h4 className="pull-left">Detalle de gastos realizados</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <h4 className="pull-right" style={{ marginRight: "15px", marginTop: 0 }}>Total: {convertirPesos(this.state.TotalGastos)}</h4>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="table-responsive-xl table-wrapper-scroll-y">
                                            <table hidden={this.state.opcionDetalleGastos !== "Realizados"} className="table table-sm table-hover">
                                                <thead>
                                                    <th>Fecha</th>
                                                    <th>Origen</th>
                                                    <th>Tipo</th>
                                                    <th>Subtipo</th>
                                                    <th>Proveedor</th>
                                                    <th>Descripción</th>
                                                    <th>Forma de pago</th>
                                                    <th>Monto</th>
                                                    <th>Empresa</th>
                                                    <th></th>
                                                </thead>
                                                <tbody>
                                                    {Gastos}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="table-responsive-xl table-wrapper-scroll-y">
                                            <table hidden={this.state.opcionDetalleGastos !== "Cancelados"} className="table table-sm table-hover">
                                                <thead>
                                                    <th>Fecha</th>
                                                    <th>Cancelación</th>
                                                    <th>Origen</th>
                                                    <th>Tipo</th>
                                                    <th>Subtipo</th>
                                                    <th>Proveedor</th>
                                                    <th>Descripción</th>
                                                    <th>Forma de pago</th>
                                                    <th>Monto</th>
                                                    <th>Empresa</th>
                                                </thead>
                                                <tbody>
                                                    {Gastos}
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <GridItem xs={12} sm={12} md={12}>
                            {this.ValidarModulo(72) ? <Button size="sm" type="submit" round className="pull-right mb-3 ml-2" onClick={() => this.openModalImportarGasto()}><Publish/>&nbsp;Importar gasto</Button> : ""}
                            <Button size="sm" type="submit" color="info" round className="pull-right mb-3" onClick={() => this.openModalRegistrarGasto()}><AddCircle/>&nbsp;Registrar gasto</Button>
                        </GridItem>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <ModalPregunta
                        Titulo={"Gastos"}
                        Cuerpo={"¿Seguro que desea cancelar este gasto?"}
                        show={this.state.show}
                        eventClick={this.eventoModalPregunta}
                    />
                    {agregar}
                </div>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
                
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(Colaboradores);