import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import Globales from "utils/Globales";
import { Edit, People, DoneOutline, GetApp, Close, AssignmentReturned, AddCircle, PlusOne, DoubleArrow, ArrowUpward, Clear, DoneAll, AssignmentTurnedIn } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import cookie from 'js-cookie'


class ReporteGastos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      FechaInicio: moment().format("YYYY-MM-DD"),
      FechaFin: moment().format("YYYY-MM-DD"),
      Campos: "FECHA DE REGISTRO,Hora DE REGISTRO,Dia,Concepto,Proveedor,Tipo,Subtipo,Documento,Estatus,Fecha de Pago,Mes,Método de Pago,Subtotal,Iva,Retencion Iva,Retencion ISR,Total",

      //Checkboxes Gastos
      Fecha: true,
      Hora: true,
      Mes: true,
      Dia: true,
      Concepto: true,
      Proveedor: true,
      Tipo: true,
      Subtipo: true,
      Documento: true,
      Estatus: true,
      FechaPago: true,
      MetodoPago: true,
      Total: true,

      errorFechainicio: false,
      errorFechaFin: false,
      colorNotificacion: 'success',
      colorTableRow: "white",

      //Mensaje del cargando default
      CuerpoCargando: "Cargando"
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    
  }

  componentDidMount() {
  }

  async ReporteExcelGastos() {
    this.setState({cargando: true});

    await fetch(Globales.Url + 'Reportes/Reportes/ReporteExcelGastos', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin, Campos: this.state.Campos})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                this.DescargarPDF(data.ReporteExcel);
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

DescargarPDF(archivoBase64) {
  var url 

  var ArchivoUTF8 = atob(archivoBase64)
  var universalBOM = "\uFEFF";
  url = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+ArchivoUTF8)
  // url = 'data:application/csv;base64,' + archivoBase64;
  let nombre;

  nombre = 'Reporte gastos'
  const linkSource = `${url}`;
  const downloadLink = document.createElement("a");
  const fileName = `${nombre}.csv`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  this.setState({
    cargando: false,
    mensaje: "El archivo se generó exitosamente",
    colorNotificacion: "success",
  });
  this.showNotification();
}

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    
    this.setState({ [name]: value });
  }

  handleChangeCheck(e) {
    const { name, value, checked } = e.target;
  
    if(checked){
      //console.log(value)
      this.setState({ [name]: true, Campos: this.state.Campos !== "" ? this.state.Campos.concat(',', value) : value});
    }
    else{
      if(this.state.Campos.includes(`${value},`)){
        this.setState({ [name]: false, Campos: this.state.Campos.replace(`${value},`, "") });
      }
      else if(this.state.Campos.includes(`,${value}`)){
        this.setState({ [name]: false, Campos: this.state.Campos.replace(`,${value}`, "") });
      }  
      else{
        this.setState({ [name]: false, Campos: this.state.Campos.replace(value, "") });
      }
    }
  }

  validarFechas(){
    let valido = true;

    if(this.state.FechaInicio !== "" || this.state.FechaFin !== ""){
      if(this.state.FechaFin < this.state.FechaInicio){
        valido = false;
        this.setState({errorFechainicio: false, errorFechaFin: true, mensaje: "La fecha final no puede ser antes de la fecha de inicio", colorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        valido = true;
        this.setState({errorFechainicio: false, errorFechaFin: false});
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos", colorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
      this.ReporteExcelGastos();
    }
  }


  render() {
    const { 
      Fecha,
      Hora,
      Mes,
      Dia,
      Concepto,
      Proveedor,
      Tipo,
      Subtipo,
      Documento,
      Estatus,
      FechaPago,
      MetodoPago,
      Total,
      Campos
     } = this.state;

    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    //console.log(Campos)
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Reporte gastos</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{fontWeight: "bold"}}>Seleccione el periodo</div>
                      <TextField
                        id="FechaInicio"
                        style={{ width: "15em", marginTop: "10px", marginRight: "10px" }}
                        variant="outlined"
                        type="date"
                        format="DD-MM-YYYY"
                        size="small"
                        label="Fecha inicio"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaInicio,
                          name: "FechaInicio",
                          id: "FechaInicio",
                          error: this.state.errorFechainicio,
                          success: !this.state.errorFechainicio
                        }}
                      />

                      <TextField
                        id="FechaFin"
                        style={{ width: "15em", marginTop: "10px" }}
                        variant="outlined"
                        type="date"
                        format="DD-MM-YYYY"
                        size="small"
                        label="Fecha fin"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaFin,
                          name: "FechaFin",
                          id: "FechaFin",
                          error: this.state.errorFechaFin,
                          success: !this.state.errorFechaFin
                        }}
                      />
                      <Button size="sm" type="submit" color="info" round className="ml-3 mt-2" onClick={() => this.validarFechas()}><GetApp/>&nbsp;Exportar a excel</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Información del alumno</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={Fecha} value={"FECHA DE REGISTRO"} onChange={this.handleChangeCheck.bind(this)} name="Fecha" />}
                            label="Fecha"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Hora} value={"HORA DE REGISTRO"} onChange={this.handleChangeCheck.bind(this)} name="Hora" />}
                            label="Hora"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Dia} value={"Dia"} onChange={this.handleChangeCheck.bind(this)} name="Dia" />}
                            label="Dia"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Concepto} value={"Concepto"} onChange={this.handleChangeCheck.bind(this)} name="Concepto" />}
                            label="Concepto"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Proveedor} value={"Proveedor"} onChange={this.handleChangeCheck.bind(this)} name="Proveedor" />}
                            label="Proveedor"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Tipo} value={"Tipo"} onChange={this.handleChangeCheck.bind(this)} name="Tipo" />}
                            label="Tipo"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Subtipo} value={"Subtipo"} onChange={this.handleChangeCheck.bind(this)} name="Subtipo" />}
                            label="Subtipo"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Documento} value={"Documento"} onChange={this.handleChangeCheck.bind(this)} name="Documento" />}
                            label="Documento"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Estatus} value={"Estatus"} onChange={this.handleChangeCheck.bind(this)} name="Estatus" />}
                            label="Estatus"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FechaPago} value={"Fecha de Pago"} onChange={this.handleChangeCheck.bind(this)} name="FechaPago" />}
                            label="Fecha de Pago"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Mes} value={"Mes"} onChange={this.handleChangeCheck.bind(this)} name="Mes" />}
                            label="Mes"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={MetodoPago} value={"Método de Pago"} onChange={this.handleChangeCheck.bind(this)} name="MetodoPago" />}
                            label="Método de Pago"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Total} value={"Subtotal,Iva,Retencion Iva,Retencion ISR,Total"} onChange={this.handleChangeCheck.bind(this)} name="Total" />}
                            label="Total"
                          />
                        </FormGroup>
                      </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar grupo"}
            Cuerpo={"¿Está seguro que desea eliminar grupo?"}
            show={this.state.show}
          eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(ReporteGastos);