import React from "react";
// core components

import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";

// material-ui icons
import { GetApp } from "@material-ui/icons";
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;




class ReportExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            DatosReporte:[],
            VerReporte:false,
            Totales:[],
            Datos:[],
            Lotes:[],
        };





    }

    componentDidMount(){
        /* console.log({
            columns: this.props.TiulosReporte,
            data: this.props.DatosReporte,
        }) */
        this.EstablecerDatos()
    }
    // AjustarDatos(datos){

    //     var listalotes = []
    //     var cajas = []

    //     var ListatotalesTemp = []
    //     var Listatotales = []
    //     var columns=[]
    //     columns.push("CAJAS")
    //     columns.push("FOLIO")
    //     columns.push("KG DESCABEZADOS")
    //     // columns.push("TOTAL A PAGAR")

    //     var ListaDatos = []
    //     var totales = []
    //     datos.ListaCajas.map(caja =>{
    //         ListaDatos.push({
    //             caja: caja.NombreCaja,
    //             lote: caja.Lote,
    //             totalKG: caja.Peso,
    //             totalMonto: caja.Peso * caja.PagoKG,
    //         })
            
    //         var repetido = false
    //         ListatotalesTemp.map(total => {
    //             if (total.caja == caja.NombreCaja) {
    //                 repetido = true
    //                 total.totalKG += caja.Peso
    //                 total.totalMonto += caja.Peso * caja.PagoKG
    //             }
    //         })

    //         if (!repetido) {
    //             ListatotalesTemp.push({
    //                 caja: caja.NombreCaja,
    //                 totalKG: caja.Peso,
    //                 totalMonto: caja.Peso * caja.PagoKG,
    //             })
    //         }


    //     })
    //     ListatotalesTemp.map(total => {
    //         var totalAux =[]
    //         totalAux.push(total.caja)
    //         totalAux.push(total.totalKG)
    //         totalAux.push("$ "+ parseFloat(total.totalMonto).toFixed(2))
    //         Listatotales.push(totalAux)
    //     })
    //     var data = ListaDatos.sort((a, b) => new Date(b.nombre) - new Date(a.nombre))

    //     data.map(total =>{
    //         //listar datos
    //         var CajaTemporal = []
    //         CajaTemporal.push(total.caja)
    //         CajaTemporal.push(total.lote)
    //         CajaTemporal.push(total.totalKG)
    //         // CajaTemporal.push(total.totalMonto)
    //         totales.push(CajaTemporal)


    //     })


    //     this.setState({
    //         Totales:Listatotales,
    //         Datos:totales,
    //         Lotes:columns
    //     },()=>{
    //         this.EstablecerDatos()
    //     })


    // }

    EstablecerDatos(){

        this.state.DatosReporte.push(
            {
                xSteps: 2, // Will start putting cell with 1 empty cell on left most
                // ySteps: 5, //will put space of 5 rows,
                columns: [],
                data: [
                    [
                        { xSteps: 1 },
                        { xSteps: 1 },

                        {
                            value: "CONGELADORA JL",
                            style: {
                                alignment: {
                                    horizontal: "center"
                                },
                                font: {
                                    sz: "22", bold: true,
                                    color: {
                                        rgb: "0080D7"
                                    }
                                }
                            }
                        },
                        { xSteps: 1 },
                        { xSteps: 1 },
                        { xSteps: 1 },
                        { value: "FECHA", style: { font: { sz: "12", bold: true } } },

                    ],
                    [
                        { xSteps: 1 },
                        { xSteps: 1 },
                        {
                            value: "Circuito Industrial 860, Parque Industrial Canacintra I, Culiacán, Sin, C.P. 80150.",
                            style: {
                                alignment: {
                                    horizontal: "center"
                                },
                                font: {
                                    sz: "12", bold: true
                                }
                            }
                        },
                        { xSteps: 1 },
                        { xSteps: 1 },
                        { xSteps: 1 },
                        { value: new Date().toLocaleDateString("en-GB"), style: { font: { sz: "12", bold: true } } },

                    ],
                    [
                        { xSteps: 1 },
                        { xSteps: 1 },
                        {
                            value: "TEL 667-146-8787 / 667-146-8788",
                            style: {
                                alignment: {
                                    horizontal: "center"
                                },
                                font: {
                                    sz: "12", bold: true
                                }
                            }
                        }
                    ],

                    [
                        { xSteps: 1 },
                        { xSteps: 1 },
                        {
                            value: "congeladorajl@hotmail.com",
                            style: {
                                alignment: {
                                    horizontal: "center"
                                },
                                font: { sz: "12", bold: true }
                            }
                        }
                    ],
                    [
                        { xSteps: 1 },
                        { xSteps: 1 },
                        {
                            value: "",
                            style: {
                                alignment: {
                                    horizontal: "center"
                                },
                                font: { sz: "12", bold: true }
                            }
                        }
                    ],




                ]
            },

            /**Datos cliente */
            {
                // ySteps: 1, //will put space,
                columns: [],
                data: [

                    // [

                    //     { xSteps: 1 },
                    //     { xSteps: 1 },
                    //     { xSteps: 1 },
                    //     { xSteps: 1 },
                    //     { value: "", style: { font: { sz: "15", bold: true, color: { rgb: "0080D7" } }, border: { bottom: { style: "medium", color: { rgb: "0080D7" } } } } },



                    // ],

                    [
                        { xSteps: 1 },
                        { xSteps: 1 },
                        { xSteps: 1 },
                        { xSteps: 1 },


                        {
                            value: this.props.NombreArchivo,
                            style: {
                                alignment: {
                                    horizontal: "center"
                                },
                                font: {
                                    sz: "15", bold: true, color: { rgb: "0080D7" }
                                },

                            }
                        },



                    ],
                ]

            },
        )

        // console.log("--------------------------------",this.state.Datos)
        this.state.DatosReporte.push(
            {
                // xSteps: 1,
                ySteps: 1,
                columns: this.props.TiulosReporte,
                data: this.props.DatosReporte,

            }
        )

        this.setState({ VerReporte: true })

    }


    render() {
        const { classes } = this.props;

        return (
            <div>
                {
                    this.state.VerReporte == true ?
                        <ExcelFile filename={this.props.NombreArchivo} element={
                            <Button className="pull-right" color="info" size="sm">  <GetApp />EXPORTAR</Button>
                        }>
                            <ExcelSheet dataSet={this.state.DatosReporte} name="REPORTE" />
                        </ExcelFile>
                        : null
                }
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(ReportExcel);