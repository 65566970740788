import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import Globales from "utils/Globales";
import { Edit, People, DoneOutline, GetApp, Close, AssignmentReturned, AddCircle, PlusOne, DoubleArrow, ArrowUpward, Clear, DoneAll, AssignmentTurnedIn } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import cookie from 'js-cookie'


class ReporteCobranza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaIdiomas: [],
      FechaInicio: moment().format("YYYY-MM-DD"),
      FechaFin: moment().format("YYYY-MM-DD"),
      idIdioma: "Todo",
      Campos: [0,1,6,19,18,26,23,25,9,10],

      //Checkboxes alumno
      matricula: true,
      nombre: true,
      clase: false,
      grupo: true,
      idioma: true,
      FechaNacimiento: false,
      LugarNacimiento: false,
      DatosFiscales: false,
      EstadoAlumno: true,
      FamiliaresEstudiando: false,
      direccion: false,
      telefono: true,
      celular: true,
      Correo: false,
      Descuentos: false,
      FechaRegistro: false,
      medioInformacion: false,
      Parentesco: false,

      //Checkboxes adeudo
      EstadoAdeudo: false,
      FechaCancelacion: false,
      MotivoCancelacion: false,
      FechaLimitePago: true,
      MontoPagar: false,
      MontoPendiente: true,
      TipoConcepto: true,

      errorFechainicio: false,
      errorFechaFin: false,
      colorNotificacion: 'success',
      colorTableRow: "white",

      //Mensaje del cargando default
      CuerpoCargando: "Cargando"
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    
  }

  componentDidMount() {
    this.ListaGeneralIdiomas()
  }

  async ListaGeneralIdiomas() {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Prospectos/Prospectos/ListaGeneralIdiomas', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                if (data.ListaIdiomas.length > 1) {
                    this.setState({ ListaIdiomas: data.ListaIdiomas, Activo: 1, cargando: false });
                }
                else {
                    let ListaAuxEmpresas = [];
                    ListaAuxEmpresas.push(data.ListaIdiomas);
                    //console.log(ListaAux);
                    this.setState({ ListaIdiomas: ListaAuxEmpresas, Activo: 1, cargando: false });
                }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

  async ReporteExcelCobranza() {
    this.setState({cargando: true});
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

    await fetch(Globales.Url + 'Reportes/Reportes/ReporteExcelCobranza', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin, idIdioma: this.state.idIdioma !== "Todo" ? this.state.idIdioma : idVacio, Campos: this.state.Campos.toString(), idVacio: idVacio})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                this.DescargarPDF(data.ReporteExcel);
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

DescargarPDF(archivoBase64) {
  var url 

  var ArchivoUTF8 = atob(archivoBase64)
  var universalBOM = "\uFEFF";
  url = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+ArchivoUTF8)
  // url = 'data:application/csv;base64,' + archivoBase64;

  let nombre;

  nombre = 'Reporte cobranza'
  const linkSource = `${url}`;
  const downloadLink = document.createElement("a");
  const fileName = `${nombre}.csv`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  this.setState({
    cargando: false,
    mensaje: "El archivo se generó exitosamente",
    colorNotificacion: "success",
  });
  this.showNotification();
}

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    
    this.setState({ [name]: value });
  }

  handleChangeCheck(e) {
    const { name, value, checked } = e.target;
  
    if(checked){
      //console.log(value)
      this.setState({ [name]: true, Campos: [...this.state.Campos, parseInt(value)]});
    }
    else{
      let index = this.state.Campos.findIndex(elem => elem == value)

      this.state.Campos.splice(index, 1);

      this.setState({ [name]: false, Campos: this.state.Campos});
    }
  }

  validarFechas(){
    let valido = true;

    if(this.state.FechaInicio !== "" || this.state.FechaFin !== ""){
      if(this.state.FechaFin < this.state.FechaInicio){
        valido = false;
        this.setState({errorFechainicio: false, errorFechaFin: true, mensaje: "La fecha final no puede ser antes de la fecha de inicio", colorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        valido = true;
        this.setState({errorFechainicio: false, errorFechaFin: false});
      }
    }
    else{
      valido = false;
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos", colorNotificacion: "danger"});
      this.showNotification();
    }

    if(valido){
      this.ReporteExcelCobranza();
    }
  }


  render() {
    const { 
      Campos,
      matricula, 
      nombre, 
      clase, 
      grupo, 
      idioma, 
      FechaNacimiento, 
      LugarNacimiento, 
      DatosFiscales, 
      EstadoAlumno,
      FamiliaresEstudiando,
      direccion,
      telefono,
      celular,
      Correo,
      Descuentos,
      FechaRegistro,
      medioInformacion,
      EstadoAdeudo,
      FechaCancelacion,
      MotivoCancelacion,
      FechaLimitePago,
      MontoPagar,
      MontoPendiente,
      TipoConcepto,
      Parentesco,
     } = this.state;

    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    //console.log(Campos)
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Reporte cobranza</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{fontWeight: "bold"}}>Seleccione el periodo y el idioma</div>
                      <TextField
                        id="FechaInicio"
                        style={{ width: "15em", marginTop: "10px", marginRight: "10px" }}
                        variant="outlined"
                        type="date"
                        format="DD-MM-YYYY"
                        size="small"
                        label="Fecha inicio"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaInicio,
                          name: "FechaInicio",
                          id: "FechaInicio",
                          error: this.state.errorFechainicio,
                          success: !this.state.errorFechainicio
                        }}
                      />

                      <TextField
                        id="FechaFin"
                        style={{ width: "15em", marginTop: "10px" }}
                        variant="outlined"
                        type="date"
                        format="DD-MM-YYYY"
                        size="small"
                        label="Fecha fin"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaFin,
                          name: "FechaFin",
                          id: "FechaFin",
                          error: this.state.errorFechaFin,
                          success: !this.state.errorFechaFin
                        }}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="idioma">Idioma</InputLabel>
                        <Select
                            labelId="idioma"
                            //fullWidth={true}
                            value={this.state.idIdioma}
                            onChange={this.handleChange.bind(this)}
                            name="idIdioma"
                            label="Idioma"
                            style={{ width: "26.9em", marginTop: "10px" }}
                        >

                          <MenuItem value={"Todo"}>Todos los idiomas</MenuItem>
                          {this.state.ListaIdiomas.map(idioma =>
                            <MenuItem value={idioma._id}>{(idioma.Nombre)}</MenuItem>
                          )}

                        </Select>
                    </FormControl>
                    <Button size="sm" type="submit" color="info" round className="ml-3 mt-3" onClick={() => this.validarFechas()}><GetApp/>&nbsp;Exportar a excel</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Información del alumno</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={matricula} value={"0"} onChange={this.handleChangeCheck.bind(this)} name="matricula" />}
                            label="Matrícula"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={nombre} value={"1"} onChange={this.handleChangeCheck.bind(this)} name="nombre" />}
                            label="Nombre"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={clase} value={"2"} onChange={this.handleChangeCheck.bind(this)} name="clase" />}
                            label="Clase"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FechaNacimiento} value={"3"} onChange={this.handleChangeCheck.bind(this)} name="FechaNacimiento" />}
                            label="Fecha de nacimiento"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={LugarNacimiento} value={"4"} onChange={this.handleChangeCheck.bind(this)} name="LugarNacimiento" />}
                            label="Lugar de nacimiento"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={DatosFiscales} value={"5"} onChange={this.handleChangeCheck.bind(this)} name="DatosFiscales" />}
                            label="Datos fiscales"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={EstadoAlumno} value={"6"} onChange={this.handleChangeCheck.bind(this)} name="EstadoAlumno" />}
                            label="Estado del alumno"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FamiliaresEstudiando} value={"7"} onChange={this.handleChangeCheck.bind(this)} name="FamiliaresEstudiando" />}
                            label="Familiares estudiando"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={direccion} value={"8"} onChange={this.handleChangeCheck.bind(this)} name="direccion" />}
                            label="Dirección"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={telefono} value={"9"} onChange={this.handleChangeCheck.bind(this)} name="telefono" />}
                            label="Teléfono"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={celular} value={"10"} onChange={this.handleChangeCheck.bind(this)} name="celular" />}
                            label="Celular"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Correo} value={"11"} onChange={this.handleChangeCheck.bind(this)} name="Correo" />}
                            label="Correo electronico"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Parentesco} value={"29"} onChange={this.handleChangeCheck.bind(this)} name="Parentesco" />}
                            label="Parentesco"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={Descuentos} value={"15"} onChange={this.handleChangeCheck.bind(this)} name="Descuentos" />}
                            label="Descuentos"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FechaRegistro} value={"16"} onChange={this.handleChangeCheck.bind(this)} name="FechaRegistro" />}
                            label="Fecha de registro"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={medioInformacion} value={"17"} onChange={this.handleChangeCheck.bind(this)} name="medioInformacion" />}
                            label="Medio por el cual conoció la escuela"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={grupo} value={"18"} onChange={this.handleChangeCheck.bind(this)} name="grupo" />}
                            label="Grupo"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={idioma} value={"19"} onChange={this.handleChangeCheck.bind(this)} name="idioma" />}
                            label="Idioma"
                          />
                        </FormGroup>
                      </FormControl>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Información del pago</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={EstadoAdeudo} value={"20"} onChange={this.handleChangeCheck.bind(this)} name="EstadoAdeudo" />}
                            label="Estado del Adeudo"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FechaCancelacion} value={"21"} onChange={this.handleChangeCheck.bind(this)} name="FechaCancelacion" />}
                            label="Fecha de Cancelación"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={MotivoCancelacion} value={"22"} onChange={this.handleChangeCheck.bind(this)} name="MotivoCancelacion" />}
                            label="Motivos de Cancelación"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={FechaLimitePago} value={"23"} onChange={this.handleChangeCheck.bind(this)} name="FechaLimitePago" />}
                            label="Fecha Límite de Pago"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={MontoPagar} value={"24"} onChange={this.handleChangeCheck.bind(this)} name="MontoPagar" />}
                            label="Monto a Pagar"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={MontoPendiente} value={"25"} onChange={this.handleChangeCheck.bind(this)} name="MontoPendiente" />}
                            label="Monto Pendiente"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={TipoConcepto} value={"26"} onChange={this.handleChangeCheck.bind(this)} name="TipoConcepto" />}
                            label="Tipo de Concepto"
                          />
                        </FormGroup>
                      </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar grupo"}
            Cuerpo={"¿Está seguro que desea eliminar grupo?"}
            show={this.state.show}
          eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(ReporteCobranza);