import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { CancelPresentation, Save, Clear, Check } from '@material-ui/icons';

export default class ModalPreguntaAfectaEfectivo extends Component {
  constructor(props, context) {
    super(props, context);
    // this.escFunction = this.escFunction.bind(this);
  }
  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick()
    }
  }
  componentDidMount() {

    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} className="static-modal" backdrop={"static"}>
          <Card >
            <CardHeader color={(this.props.headerColor == null || this.props.headerColor == undefined? "danger":this.props.headerColor)}>
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              {this.props.Cuerpo}
              <GridItem>
                <Button size="sm" color="success" round className="pull-right" onClick={() => this.props.eventClick(true)}><Check/>&nbsp;Si</Button>
                <Button size="sm" color="danger" round className="pull-right mr-2" onClick={() => this.props.eventClick(false)}><Clear/>&nbsp;No</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
      </div>
    );
  }
}