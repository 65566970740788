import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalPregunta from "components/Generales/ModalPregunta";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Block, Clear, Check, Edit, Close, AddCircle, AssignmentTurnedIn, Remove } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import IconButton from '@material-ui/core/IconButton';


let tableFloatTheadTable= {
  position: "sticky",
  // position: "-webkit-sticky",
  borderTop: "none",
  borderBottom: "none",
  zIndex: 10,
  backgroundColor: "#fff",
  top: 0,
}

let th = {
  position: "sticky",
  // top: "50px",
  top: "0",
  background: "white",
  paddingTop: "50px",
}
class ModalDocumentos extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      _id: '',
      ListaAsistenciasAPI: [],
      ListaAsistencias: [],
      ListaAlumnos: [],
      ListaDias: [],
      ListaIDsBorrar: [],
      GrupoSeleccionado: {},
      Año: moment(new Date()).format("YYYY"),
      OpcionMes: "",
      disabled:false,
      errorOpcionMes: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      cargando: false,
      CuerpoCargando: "Cargando",
    }
    this.handleChange = this.handleChange.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick(false);
    }
  }

  componentDidMount() {
    if(this.props.op == 1){
      //console.log(this.props.GrupoSeleccionado)

      this.ListaAlumnosGrupoBasica(this.props.GrupoSeleccionado._id);

      this.setState({GrupoSeleccionado: this.props.GrupoSeleccionado})
    }
  }

  DiasDeLaSemana(startDate, endDate, diaIndex, DiaFestivo){
    //console.log("DiaFestivo",DiaFestivo)
    //let count = 0;
    // console.log("startDate: ",startDate)
    // console.log("EndDate: ",endDate)
    let aux = [];
    const curDate = new Date(startDate.getTime());
    //console.log("curDate",curDate.getDate())
    while (curDate <= endDate) {
        const dayOfWeek = curDate.getDay();
        if(dayOfWeek == diaIndex && curDate.getDate() !== DiaFestivo){
          //count++;
          aux.push(curDate.getDate());
        } 
        curDate.setDate(curDate.getDate() + 1);
        
    }
    return aux;
  }

  ObtenerDias(mes, año){
    const getDays = (year, month) => {
      return new Date(year, month, 0).getDate();
    };

    let DiasFestivo = 0;
    switch(mes){
      case 1:
        DiasFestivo = 1
        break;
      case 5:
        DiasFestivo = 1
        break;
      case 9:
        DiasFestivo = 16
        break;
      case 12:
        DiasFestivo = 25
        break;
    }

    let startDate = new Date(año, (mes -1), "01");
    let diasMes = getDays(año, (mes))
    let endDate = new Date(año, (mes -1), diasMes);
    let Lunes = [];
    let Martes = [];
    let Miercoles = [];
    let Jueves = [];
    let Viernes = [];
    let Sabado = [];
    let auxConcat = [];

    if(this.state.GrupoSeleccionado.HorarioGrupo.Lunes == "true"){
      Lunes = this.DiasDeLaSemana(startDate, endDate, 1, DiasFestivo);
    }

    if(this.state.GrupoSeleccionado.HorarioGrupo.Martes == "true"){
      Martes = this.DiasDeLaSemana(startDate, endDate, 2, DiasFestivo);
    }

    if(this.state.GrupoSeleccionado.HorarioGrupo.Miercoles == "true"){
      Miercoles = this.DiasDeLaSemana(startDate, endDate, 3, DiasFestivo);
    }

    if(this.state.GrupoSeleccionado.HorarioGrupo.Jueves == "true"){
      Jueves = this.DiasDeLaSemana(startDate, endDate, 4, DiasFestivo);
    }

    if(this.state.GrupoSeleccionado.HorarioGrupo.Viernes == "true"){
      Viernes = this.DiasDeLaSemana(startDate, endDate, 5, DiasFestivo);
    }

    if(this.state.GrupoSeleccionado.HorarioGrupo.Sabado == "true"){
      Sabado = this.DiasDeLaSemana(startDate, endDate, 6, DiasFestivo);
    }

    auxConcat = Lunes.concat(Martes, Miercoles, Jueves, Viernes, Sabado)//.sort(function(a, b){return a-b})

    // auxConcat = auxConcat.filter((item, index) => {
    //   return (auxConcat.indexOf(item) == index)
    // })

    auxConcat = [...new Set([...Lunes,...Martes,...Miercoles,...Jueves,...Viernes,...Sabado])].sort(function(a, b){return a-b})

    //console.log(auxConcat)

    if(auxConcat.length !== 0){
      this.setState({ListaDias: auxConcat}, () => this.CrearAsistenciaVacia())
    }
    else{
      this.setState({mensaje: "Este grupo no tiene dias/horarios seleccionados", colorNotificacion: "danger", ListaDias: []});
      this.showNotification();
    }
  }

  CrearAsistenciaVacia(){
    let aux = [];

    let objAsistencia = {
      Alumno: "",
      Asistencia: "",
      Fecha: "",
      Grupo: this.state.GrupoSeleccionado._id,
      Justificante: false,
      Nivel: this.state.GrupoSeleccionado._idNivel,
    }

    this.state.ListaAlumnos.map(alumno => {
      this.state.ListaDias.map(dia => {
        objAsistencia = {
          Alumno: alumno._id,
          Asistencia: "",
          Fecha: moment(new Date(this.state.Año, (this.state.OpcionMes -1), dia)).format("YYYY-MM-DD"),
          Grupo: this.state.GrupoSeleccionado._id,
          Justificante: false,
          Nivel: this.state.GrupoSeleccionado._idNivel,
        }

        aux.push(objAsistencia);
      })
    })

    this.setState({ListaAsistencias: aux},() => this.ListaInasistenciasGrupoMes());
  }

  async ListaInasistenciasGrupoMes() {
    this.setState({cargando: true});
  
    await fetch(Globales.Url + 'Asistencia/Asistencia/ListaInasistenciasGrupoMes', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idGrupo: this.state.GrupoSeleccionado._id, Mes: this.state.OpcionMes, Ano: this.state.Año})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log("API", data.ListaAsistenciasAPI);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                //this.showNotification();
            } else { //Filtrado exitoso

              if(data.ListaAsistenciasAPI.length > 1){
                let aux = [];


                  this.state.ListaAsistencias.map((dia, index) => {
                    data.ListaAsistenciasAPI.map((asistencia, index) => {
                      if(asistencia.Justificante == "true"){
                        asistencia.Asistencia = "";
                      }

                      if(JSON.stringify(dia.Alumno) === JSON.stringify(asistencia.Alumno)){
                        if(moment(asistencia.Fecha).format("YYYY-MM-DD") === dia.Fecha){
                  
                          dia.Asistencia = asistencia.Asistencia !== "" && asistencia.Asistencia == "true" ? true : false;
                          dia.Justificante = asistencia.Justificante == "false" ? false : true
                          dia._id = asistencia._id
                        }
                      }
                    })
                  })


                this.setState({ListaAsistencias: this.state.ListaAsistencias, cargando: false})
              }
              else{
                let aux = [];
                let auxAsistencia = [];

                if(data.ListaAsistenciasAPI.Justificante == "true"){
                  data.ListaAsistenciasAPI.Asistencia = "";
                }

                aux.push(data.ListaAsistenciasAPI);

                this.state.ListaAsistencias.map((dia, index) => {
                  aux.map((asistencia, index) => {
                    if(asistencia.Justificante == "true"){
                      asistencia.Asistencia = "";
                    }

                    if(JSON.stringify(dia.Alumno) === JSON.stringify(asistencia.Alumno)){
                      if(moment(asistencia.Fecha).format("YYYY-MM-DD") === dia.Fecha){
                
                        dia.Asistencia = asistencia.Asistencia !== "" && asistencia.Asistencia == "true" ? true : false;
                        dia.Justificante = asistencia.Justificante == "false" ? false : true
                        dia._id = asistencia._id
                      }
                    }
                  })
                })

                this.setState({ListaAsistencias: this.state.ListaAsistencias, cargando: false})
              }
              
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  async ListaAlumnosGrupoBasica(idGrupo) {
    this.setState({cargando: true}); 
    await fetch(Globales.Url + 'Calificaciones/Calificaciones/ListaAlumnosGrupoBasica', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idGrupo: idGrupo})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log("API: ",data.Alumnos);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, ListaAlumnos: [], colorNotificacion: "danger", Activo: 1, cargando: false });
                //this.showNotification();
            } else { //Filtrado exitoso

              if(data.Alumnos.length > 1){
                this.setState({ListaAlumnos: data.Alumnos, cargando: false});
              }
              else{
                let aux = [];
                aux.push(data.Alumnos);

                this.setState({ListaAlumnos: aux, cargando: false});
              }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  PreparacionGuardado(){
    let IDcookie = JSON.parse(cookie.get('id'));
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    let ListaR = [];
    let ListaE = [];
    let valido = true;

    if(this.state.OpcionMes == ""){
      valido = false;
      this.setState({errorOpcionMes: true, mensaje: "Seleccione el mes de inicio", colorNotificacion: "danger"})
      this.showNotification();
    }
    else{
      this.setState({errorOpcionMes: false})
    }

    if(valido){
      let log = {
        Fecha: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        _idGrupo: this.state.GrupoSeleccionado._id,
        _idIdioma: this.state.GrupoSeleccionado._idIdioma,
        _idMaestro: this.state.GrupoSeleccionado._idMaestro,
        _idNivel: this.state.GrupoSeleccionado._idNivel,
        _idUsuario: IDcookie
      }

      if(this.state.ListaAsistencias.length !== 0){
        this.state.ListaAsistencias.map((asistencia, index) => {

          if(asistencia.Justificante === true){
            delete asistencia.Asistencia
          }

          if(asistencia.Asistencia !== "" || asistencia.Justificante === true){
            asistencia.Justificante = "true"
            ListaR.push(asistencia);
          }

          else if(asistencia.Asistencia === "" && JSON.stringify(asistencia._id) !== JSON.stringify(idVacio) && asistencia._id !== undefined){
            ListaE.push(asistencia._id);
          }
        })

        // console.log("ListaRegistrar: ", ListaR)
        // console.log("ListaEliminar: ", ListaE)
        //console.log(log)

        this.RegistrarAsistencia(ListaR, ListaE, log);
      }
      else{
        this.setState({mensaje: "Este grupo no tiene dias/horarios seleccionados", colorNotificacion: "danger", ListaDias: []});
        this.showNotification();
      }
    }
  }

  async RegistrarAsistencia(ListaR, ListaE, Log) {
    this.setState({cargando: true}); 
    await fetch(Globales.Url + 'Asistencia/Asistencia/RegistrarAsistencia', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ListaRegistrar: ListaR, ListaEliminar: ListaE, Log: Log})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log("API: ",data.Alumnos);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, ListaAlumnos: [], colorNotificacion: "danger", Activo: 1, cargando: false });
                //this.showNotification();
            } else { //Filtrado exitoso
              this.setState({cargando: false});
              this.props.eventClick(data.mensaje);
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  openModalPregunta(){
    this.setState({show: true});
  }

  eventoModalPregunta = (valor) => {
    this.setState({ show: false })
    if(valor !== false){
      this.props.eventClick(false);
    }
}

  handleChange(e) {
    const { name, value } = e.target;

    switch(name){
      case "OpcionMes":

        this.setState({[name]: value});
        this.ObtenerDias(value, this.state.Año);
        break;
      case "Año":

        this.setState({[name]: value});
        this.ObtenerDias(this.state.OpcionMes, value);
        break;
      default: 
        this.setState({[name]: value});
        break;
    }
  }

  handleFocus = (event) => event.target.select();

  handleBoton(index){
    if(this.state.ListaAsistencias[index].Justificante === true){
      this.state.ListaAsistencias[index].Asistencia = ""
      this.state.ListaAsistencias[index].Justificante = false;
    }
    else if(this.state.ListaAsistencias[index].Asistencia === false){
      this.state.ListaAsistencias[index].Asistencia = "";
      this.state.ListaAsistencias[index].Justificante = true
    }
    else if(this.state.ListaAsistencias[index].Asistencia === true){
      this.state.ListaAsistencias[index].Asistencia = false
    }
    else{
      this.state.ListaAsistencias[index].Asistencia = true
    }

    this.setState({ListaAsistencias: this.state.ListaAsistencias});
  }

  render() {
    const { classes } = this.props;
    //console.log(this.state.ListaDias)
    // console.log(this.state.ListaAsistencias)
    // console.log(this.state.ListaAsistenciasAPI)


    let AñoMax = moment(new Date()).add(2,'y').format("YYYY");
    let AñoMin = moment(new Date()).subtract(2,'y').format("YYYY");

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    let dias;
    if(this.state.ListaDias.length !== 0){
      dias = 
      this.state.ListaDias.map(dia => {
        return(
          <>
          <th style={th}>{dia}</th>
          </>
        )
      })
    }

    let alumno;
    let asistencia;
    let boton;
    if(this.state.ListaAlumnos.length !== 0){
      alumno =
      this.state.ListaAlumnos.map(alumno => {
        asistencia = (
          this.state.ListaAsistencias.map((asistencia, index) => {
            if(JSON.stringify(alumno._id) === JSON.stringify(asistencia.Alumno)){

              if(asistencia.Justificante === true){
                boton = (<IconButton size="small" onClick={() => this.handleBoton(index)} style={{backgroundColor: "#ffa21a"}}><Remove /></IconButton>);
                
              }
              else if(asistencia.Asistencia === false){
                boton = (<IconButton size="small" onClick={() => this.handleBoton(index)} style={{backgroundColor: "#f44336"}}><Close /></IconButton>)
                
              }
              else if(asistencia.Asistencia === true){
                boton = (<IconButton size="small" onClick={() => this.handleBoton(index)} style={{backgroundColor: "#4caf50"}}><Check /></IconButton>)
  
              }
              else{
                boton = (<IconButton size="small" style={{backgroundColor: "#999"}} onClick={() => this.handleBoton(index)}><Block /></IconButton>)
                
              }

              return(
                <td>
                  {boton}
                </td>
              )
            }
          })
        )

        return(
          <tr>
            <td>{alumno.NombreCompleto}</td>
            {asistencia}
          </tr>
        )
      })
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showEditarAsistencia}
          className="static-modal"
          size="lg"
          backdrop={"static"}
          style={{ maxWidth: "1680px", width: "100%" }}
        >
          <Card>
            <CardHeader color="info">Búsqueda</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p>Grupo: <b style={{fontWeight: "bold"}}>{this.props.GrupoSeleccionado.Nombre}</b></p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="Mes">Mes</InputLabel>
                      <Select
                          labelId="Mes"
                          //fullWidth={true}
                          value={this.state.OpcionMes}
                          onChange={this.handleChange.bind(this)}
                          name="OpcionMes"
                          label="Mes"
                          style={{ width: "11em", marginTop: "10px", marginRight: "10px" }}
                          error={this.state.errorOpcionMes}
                          success={!this.state.errorOpcionMes}
                      >
                        <MenuItem disabled>Seleccione un mes</MenuItem>
                      <MenuItem value={1}>Enero</MenuItem>
                      <MenuItem value={2}>Febrero</MenuItem>
                      <MenuItem value={3}>Marzo</MenuItem>
                      <MenuItem value={4}>Abril</MenuItem>
                      <MenuItem value={5}>Mayo</MenuItem>
                      <MenuItem value={6}>Junio</MenuItem>
                      <MenuItem value={7}>Julio</MenuItem>
                      <MenuItem value={8}>Agosto</MenuItem>
                      <MenuItem value={9}>Septiembre</MenuItem>
                      <MenuItem value={10}>Octubre</MenuItem>
                      <MenuItem value={11}>Noviembre</MenuItem>
                      <MenuItem value={12}>Diciembre</MenuItem>

                    </Select>
                  </FormControl>
                  <TextField
                    id="Año"
                    style={{ width: "12em", marginTop: "10px" }}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Año"
                    inputProps={{
                      min: parseInt(AñoMin),
                      max: parseInt(AñoMax),
                      onKeyDown: (event) => {
                         event.preventDefault();
                      },
                    }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.Año,
                      name: "Año",
                      id: "Año",
                      //error: this.state.errorNombre,
                      //success: !this.state.errorNombre
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Alumno</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <div className="table-responsive-xl table-wrapper-scroll-y">
                  <table className="table table-sm table-hover">
                      <thead style={tableFloatTheadTable}>
                          <th style={th}>{""}</th>
                          {dias}
                      </thead>
                      <tbody>
                      {alumno}
                      </tbody>
                  </table>
                </div>
                </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button tabIndex="1" size='sm' color="info" className='pull-right ml-2 mt-3' round onClick={() => this.PreparacionGuardado()}><AssignmentTurnedIn/>&nbsp;Guardar asistencias</Button>
                <Button tabIndex="1" size='sm' color="danger" className='pull-right ml-2 mt-3' round onClick={() => this.openModalPregunta()}><Clear/>&nbsp;Cancelar</Button>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>  
        </Modal>
        <div id="">
            <ModalPregunta
                Titulo={"Asistencia"}
                Cuerpo={"¿Está seguro de salir sin guardar los cambios?"}
                show={this.state.show}
                eventClick={this.eventoModalPregunta}
            />
            {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalDocumentos);