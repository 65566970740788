import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalPregunta from "components/Generales/ModalPregunta";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Visibility, VisibilityOff, CameraAlt, Info, AccountCircle, VpnKey, Save, ExpandMore, InfoOutlined, Close, AddCircle, Search, Clear, Filter9Plus, Add, ContactSupportOutlined } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import { AsYouType } from 'libphonenumber-js'
import moment from 'moment';
import cookie from 'js-cookie'
import Persona from '../../../assets/img/persona.png'
import ModalCapturarFoto from "./ModalCapturarFoto.jsx";
import ModalAsignarGrupo from "./ModalAsignarGrupo.jsx";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { string } from "prop-types";

var Permisos = localStorage.getItem("Permisos") == null ? { Modulo: [] } : JSON.parse(localStorage.getItem("Permisos"))
if (Permisos.length > 1) {
  Permisos = Permisos
}
else {
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

class ModalEditarColaborador extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      _idUsuario: '',
      MostrarModal: false,
      ObjColaborador: {},
      objUsuario: {},
      ColaboradorSeleccionado: {},
      DetalleMestro: {},
      DetalleUsuario: {},
      ListaGrupos: [],
      ListaPaises: [],
      ListaEstados: [],
      ListaMunicipio: [],
      FotoColaborador: null,
      OpcionTipo: "Maestro",
      Nombre: "",
      ApellidoPaterno: "",
      ApellidoMaterno: "",
      FechaNacimiento: "",
      RFC: "",
      CURP: "",
      Ocupacion: "",
      IdPais: "",
      IdEstado: "",
      IdMunicipio: "",
      ArrIdGrupos: [],
      Colonia: "",
      CalleNumero: "",
      Correo: "",
      Telefono: "",
      Celular: "",
      NombreEmergencia1: "",
      TelefonoEmergencia1: "",
      CorreoEmergencia1: "",
      NombreEmergencia2: "",
      TelefonoEmergencia2: "",
      CorreoEmergencia2: "",
      FechaInicioContrato: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaFinContrato: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaActual: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      ContratoIndefinido: false,
      Sueldo: 0,
      SueldoTemp: 0,
      HistorialSueldo: [],
      SueldoSeleccionado: {},
      NumeroFM3: "",
      Vigencia: "",
      Usuario: "",
      Contraseña: "",
      VerificarContraseña: "",
      disabled: false,
      cargando: false,
      errorNombre: false,
      errorApellidoPaterno: false,
      errorCorreo: false,
      errorCorreoEmergencia1: false,
      errorCorreoEmergencia2: false,
      errorFechainicio: false,
      errorFechaFin: false,
      errorUsuario: false,
      errorContraseña: false,
      errorVerificarContraseña: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      TituloModalPregunta: "",
      CuerpoModalPregunta: "",
      opcionModalPregunta: 0,
      DesactivarBoton: false,
      NombreGuardado: '',
      SubcategoriaHabilitada: false,
      aviso: "",
      visible: false,
      visible2: false,
      CuerpoCargando: "Cargando",
      show: false

    }
    this.handleChange = this.handleChange.bind(this);
    //this.handleFocus = this.handleFocus.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    // console.log(this.props.ColaboradorSeleccionado)

    if (this.props.op == 1) {
      if (JSON.stringify(this.props.ColaboradorSeleccionado._idPais) !== JSON.stringify(idVacio)) {
        this.ListaGeneralPaises(this.props.ColaboradorSeleccionado._idPais)
      }
      else {
        this.ListaGeneralPaises()
      }


      this.ListaHistorialSueldoPorMaestro(this.props.ColaboradorSeleccionado._id)

      // if(this.props.ColaboradorSeleccionado.ListaGrupos !== null){
      this.ListaInformacionGruposMaestros(this.props.ColaboradorSeleccionado._id)
      // }

      if (JSON.stringify(this.props.ColaboradorSeleccionado._idEstado) !== JSON.stringify(idVacio)) {
        this.ListaEstadosPais(this.props.ColaboradorSeleccionado._idPais, this.props.ColaboradorSeleccionado._idEstado)
      }

      if (JSON.stringify(this.props.ColaboradorSeleccionado._idCiudad) !== JSON.stringify(idVacio)) {
        this.ListaMunicipiosEstado(this.props.ColaboradorSeleccionado._idEstado, this.props.ColaboradorSeleccionado._idCiudad)
      }

      if (JSON.stringify(this.props.ColaboradorSeleccionado._idUsuario) !== JSON.stringify(idVacio)) {
        this.ObtenerInformacionUsuario(this.props.ColaboradorSeleccionado._idUsuario, null);
      }

      this.setState({
        ColaboradorSeleccionado: this.props.ColaboradorSeleccionado,
        FotoColaborador: this.props.ColaboradorSeleccionado.Foto !== null && this.props.ColaboradorSeleccionado.Foto !== undefined ? this.props.ColaboradorSeleccionado.Foto : null,
        OpcionTipo: this.props.ColaboradorSeleccionado.Tipo,
        Nombre: this.props.ColaboradorSeleccionado.Nombre,
        ApellidoPaterno: this.props.ColaboradorSeleccionado.ApellidoPaterno,
        ApellidoMaterno: this.props.ColaboradorSeleccionado.ApelidoMaterno !== null && this.props.ColaboradorSeleccionado.ApelidoMaterno !== undefined ? this.props.ColaboradorSeleccionado.ApelidoMaterno : "",
        FechaNacimiento: this.props.ColaboradorSeleccionado.FechaNacimiento > "01/01/2000" ? moment(this.props.ColaboradorSeleccionado.FechaNacimiento).format("YYYY-MM-DD") : "",
        RFC: this.props.ColaboradorSeleccionado.RFC !== null && this.props.ColaboradorSeleccionado.RFC !== undefined ? this.props.ColaboradorSeleccionado.RFC : "",
        CURP: this.props.ColaboradorSeleccionado.Curp !== null && this.props.ColaboradorSeleccionado.Curp !== undefined ? this.props.ColaboradorSeleccionado.Curp : "",
        Ocupacion: this.props.ColaboradorSeleccionado.Ocupacion !== null && this.props.ColaboradorSeleccionado.Ocupacion !== undefined ? this.props.ColaboradorSeleccionado.Ocupacion : "",
        Colonia: this.props.ColaboradorSeleccionado.Colonia !== null && this.props.ColaboradorSeleccionado.Colonia !== undefined ? this.props.ColaboradorSeleccionado.Colonia : "",
        CalleNumero: this.props.ColaboradorSeleccionado.CalleNumero !== null && this.props.ColaboradorSeleccionado.CalleNumero !== undefined ? this.props.ColaboradorSeleccionado.CalleNumero : "",
        Correo: this.props.ColaboradorSeleccionado.Correo !== null && this.props.ColaboradorSeleccionado.Correo !== undefined ? this.props.ColaboradorSeleccionado.Correo : "",
        Telefono: this.props.ColaboradorSeleccionado.Telefono !== null && this.props.ColaboradorSeleccionado.Telefono !== undefined ? this.props.ColaboradorSeleccionado.Telefono : "",
        Celular: this.props.ColaboradorSeleccionado.Celular !== null && this.props.ColaboradorSeleccionado.Celular !== undefined ? this.props.ColaboradorSeleccionado.Celular : "",
        NombreEmergencia1: this.props.ColaboradorSeleccionado.NombreContacto1 !== null && this.props.ColaboradorSeleccionado.NombreContacto1 !== undefined ? this.props.ColaboradorSeleccionado.NombreContacto1 : "",
        TelefonoEmergencia1: this.props.ColaboradorSeleccionado.TelefonoContacto1 !== null && this.props.ColaboradorSeleccionado.TelefonoContacto1 !== undefined ? this.props.ColaboradorSeleccionado.TelefonoContacto1 : "",
        CorreoEmergencia1: this.props.ColaboradorSeleccionado.CorreoContacto1 !== null && this.props.ColaboradorSeleccionado.CorreoContacto1 !== undefined ? this.props.ColaboradorSeleccionado.CorreoContacto1 : "",
        NombreEmergencia2: this.props.ColaboradorSeleccionado.NombreContacto2 !== null && this.props.ColaboradorSeleccionado.NombreContacto2 !== undefined ? this.props.ColaboradorSeleccionado.NombreContacto2 : "",
        TelefonoEmergencia2: this.props.ColaboradorSeleccionado.TelefonoContacto2 !== null && this.props.ColaboradorSeleccionado.TelefonoContacto2 !== undefined ? this.props.ColaboradorSeleccionado.TelefonoContacto2 : "",
        CorreoEmergencia2: this.props.ColaboradorSeleccionado.CorreoContacto2 !== null && this.props.ColaboradorSeleccionado.CorreoContacto2 !== undefined ? this.props.ColaboradorSeleccionado.CorreoContacto2 : "",
        FechaInicioContrato: this.props.ColaboradorSeleccionado.ContratoInicio > "01/01/2000" ? moment(this.props.ColaboradorSeleccionado.ContratoInicio).format("YYYY-MM-DD") : "",
        FechaFinContrato: this.props.ColaboradorSeleccionado.ContratoFin > "01/01/2000" ? moment(this.props.ColaboradorSeleccionado.ContratoFin).format("YYYY-MM-DD") : "0001-01-01",
        Sueldo: this.props.ColaboradorSeleccionado.CantidadContrato !== null && this.props.ColaboradorSeleccionado.CantidadContrato !== undefined ? this.props.ColaboradorSeleccionado.CantidadContrato : "",
        SueldoTemp: this.props.ColaboradorSeleccionado.CantidadContrato !== null && this.props.ColaboradorSeleccionado.CantidadContrato !== undefined ? convertirPesos(this.props.ColaboradorSeleccionado.CantidadContrato) : "",
        HistorialSueldo: this.props.ColaboradorSeleccionado.HistorialSueldo !== undefined && this.props.ColaboradorSeleccionado.HistorialSueldo !== null ? this.props.ColaboradorSeleccionado.HistorialSueldo.HistorialSueldo : [],
        NumeroFM3: this.props.ColaboradorSeleccionado.NumeroFM3 !== null && this.props.ColaboradorSeleccionado.NumeroFM3 !== undefined ? this.props.ColaboradorSeleccionado.NumeroFM3 : "",
        Vigencia: this.props.ColaboradorSeleccionado.AnoVigencia !== null && this.props.ColaboradorSeleccionado.AnoVigencia !== undefined ? this.props.ColaboradorSeleccionado.AnoVigencia : "",

        ContratoIndefinido: this.props.ColaboradorSeleccionado.ContratoIndefinido === "true" ? true : false

      }, () => {
        // if(this.props.ColaboradorSeleccionado.ListaGrupos !== null){
        this.ListaInformacionGruposMaestros(this.props.ColaboradorSeleccionado._id)
        // }
      })
    }
    else {
      this.ListaGeneralPaises();
    }
  }

  async ListaGeneralPaises(idPais) {
    await fetch(Globales.Url + 'Alumnos/Alumnos/ListaGeneralPaises', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1 });
          this.showNotification();
        } else { //Filtrado exitoso
          if (idPais !== undefined) {
            if (data.ListaPaises.length > 1) {

              data.ListaPaises.map(pais => {
                if (JSON.stringify(pais._id) == JSON.stringify(idPais)) {

                  this.setState({ ListaPaises: data.ListaPaises, IdPais: pais._id, Activo: 1 });
                }
              })
            }
            else {
              if (JSON.stringify(idPais) === JSON.stringify(data.ListaPaises._id)) {
                let ListaAux = [];
                ListaAux.push(data.ListaPaises);
                //console.log(ListaAux);
                this.setState({ ListaPaises: ListaAux, IdPais: data.ListaPaises._id, Activo: 1 });
              }
            }
          }
          else {
            if (data.ListaPaises.length > 1) {
              this.setState({ ListaPaises: data.ListaPaises, Activo: 1 });
            }
            else {
              let aux = [];
              aux.push(data.ListaPaises);
              this.setState({ ListaPaises: aux, Activo: 1 });
            }
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async ListaEstadosPais(idPais, idEstado) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Alumnos/Alumnos/ListaEstadosPais', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _idPais: idPais })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false, cargando: false });
          //this.showNotification();
        } else { //Filtrado exitoso
          if (idEstado !== 0) {
            if (data.ListaEstados.length > 1) {
              data.ListaEstados.map(estado => {
                if (JSON.stringify(estado._id) == JSON.stringify(idEstado)) {
                  //console.log(true)
                  this.setState({ ListaEstados: data.ListaEstados, IdEstado: estado._id, Activo: 1, cargando: false });
                }
              })
            }
            else {
              if (JSON.stringify(data.ListaEstados._id) == JSON.stringify(idEstado)) {
                let aux = [];
                aux.push(data.ListaEstados);


                this.setState({ ListaEstados: aux, IdEstado: data.ListaEstados._id, Activo: 1, cargando: false });
              }
            }

          }
          else {
            if (data.ListaEstados.length > 1) {
              this.setState({ ListaEstados: data.ListaEstados, Activo: 1, cargando: false });
            }
            else {
              let aux = [];
              aux.push(data.ListaEstados);

              this.setState({ ListaEstados: aux, Activo: 1, cargando: false });
            }

          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async ListaMunicipiosEstado(idEstado, idMunicipio) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Alumnos/Alumnos/ListaMunicipiosEstado', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _idEstado: idEstado })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          //this.showNotification();
        } else { //Filtrado exitoso
          if (idMunicipio !== 0) {
            if (data.ListaMunicipio.length > 1) {
              data.ListaMunicipio.map(ciudad => {
                if (JSON.stringify(ciudad._id) == JSON.stringify(idMunicipio)) {
                  //console.log(true)
                  this.setState({ ListaMunicipio: data.ListaMunicipio, IdMunicipio: ciudad._id, Activo: 1, cargando: false });
                }
              })
            }
            else {
              if (JSON.stringify(data.ListaMunicipio._id) == JSON.stringify(idMunicipio)) {
                let aux = [];
                aux.push(data.ListaMunicipio);
                //console.log(true)
                this.setState({ ListaMunicipio: aux, IdMunicipio: data.ListaMunicipio._id, Activo: 1, cargando: false });
              }
            }
          }
          else {
            if (data.ListaMunicipio.length > 1) {
              this.setState({ ListaMunicipio: data.ListaMunicipio, Activo: 1, cargando: false });
            }
            else {
              let aux = [];
              aux.push(data.ListaMunicipio);

              this.setState({ ListaMunicipio: aux, Activo: 1, cargando: false });
            }

          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async ObtenerInformacionUsuario(idUsuario, index) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Colaboradores/Colaboradores/ObtenerInformacionUsuario', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: idUsuario })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log("API", data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          //this.showNotification();
        } else { //Filtrado exitoso
          if (index == null) {
            this.setState({
              DetalleUsuario: data.DetalleUsuario,
              cargando: false,
              Usuario: data.DetalleUsuario.Usuario,
              Contraseña: data.DetalleUsuario.Contraseña,
              VerificarContraseña: data.DetalleUsuario.Contraseña,
            })
          }
          else {
            let ApPaterno = "";
            let ApMaterno = "";

            if (data.DetalleUsuario.ApellidoPaterno !== null && data.DetalleUsuario.ApellidoPaterno !== undefined) {
              ApPaterno = " " + data.DetalleUsuario.ApellidoPaterno;
            }

            if (data.DetalleUsuario.ApellidoMaterno !== null && data.DetalleUsuario.ApellidoMaterno !== undefined) {
              ApMaterno = " " + data.DetalleUsuario.ApellidoMaterno;
            }



            this.state.HistorialSueldo[index].NombreEmpleadoRegistro = (data.DetalleUsuario.Nombre + ApPaterno + ApMaterno)

            this.setState({ HistorialSueldo: this.state.HistorialSueldo, cargando: false })
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async ListaInformacionGruposMaestros(idMaestro) {
    this.setState({ cargando2: true });
    await fetch(Globales.Url + 'Colaboradores/Colaboradores/ListaInformacionGruposIniciadoMaestros', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idMaestro: idMaestro })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log("API", data.ListaGrupos);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando2: false });
          //this.showNotification();
        } else { //Filtrado exitoso
          if (data.ListaGrupos.length > 1) {
            let aux = [];
            let idAux = [];
            data.ListaGrupos.map(grupo => {
              if (grupo.Activo == "true") {
                aux.push(grupo)
                idAux.push(grupo._id)
              }
            })
            this.setState({ ListaGrupos: aux, ArrIdGrupos: idAux, cargando2: false });
          }
          else {
            if (data.ListaGrupos.Activo == "true") {
              let aux = [];
              let idAux = [];
              aux.push(data.ListaGrupos);
              idAux.push(data.ListaGrupos._id);
              this.setState({ ListaGrupos: aux, ArrIdGrupos: idAux, cargando2: false });
            }
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  CrearHistorialSueldos() {
    const IsEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    const ModulosDefault = [
      {
        Nombre: "Calificaciones",
        NumeroModulo: 9,
        _id: {
          _increment: "8614777",
          _machine: "595175",
          _pid: "1980",
          _timestamp: "1376321891"
        }
      },
      {
        Nombre: "Asistencia",
        NumeroModulo: 10,
        _id: {
          _increment: "8614778",
          _machine: "595175",
          _pid: "1980",
          _timestamp: "1376321891"
        }
      }
    ]

    let valor1 = 0;
    let valor2 = 0;
    let valor3 = 0;
    let valor4 = 0;
    let valor5 = 0;
    let valor6 = 0;
    let valor7 = 0;
    let valor8 = 0;
    let sueldoNuevo = {};
    if (this.state.Usuario != "") {
      if (this.state.OpcionTipo == "Maestro" && this.ValidarModulo(54)) {
        if (this.state.Contraseña == "") {
          valor8 = 1;
          this.setState({ errorUsuario: true, errorContraseña: true, errorVerificarContraseña: true, mensaje: "Complete los campos de acceso a la plataforma", colorNotificacion: "danger" });
          this.showNotification();
        }
        else {
          if (this.state.VerificarContraseña !== this.state.Contraseña) {
            valor8 = 1;
            this.setState({ errorUsuario: false, errorContraseña: true, errorVerificarContraseña: true, mensaje: "Las contraseñas no coinciden", colorNotificacion: "danger" });
            this.showNotification();
          }
          else {
            valor8 = 0;
            //console.log("Todo correcto")
            if (this.props.op == 0) {
              this.setState({
                errorUsuario: false,
                errorContraseña: false,
                errorVerificarContraseña: false,
                objUsuario: !this.state.Usuario ? {} : {
                  ApellidoMaterno: this.state.ApellidoMaterno,
                  ApellidoPaterno: this.state.ApellidoPaterno,
                  Contraseña: this.state.Contraseña,
                  ListaModulos: { Modulo: ModulosDefault },
                  Nombre: this.state.Nombre,
                  Tipo: this.state.OpcionTipo == "Maestro" ? "Maestro" : "Otro",
                  Usuario: this.state.Usuario,
                  _idEmpresa: idVacio
                }
              })
            }
            else {
              this.setState({
                errorUsuario: false,
                errorContraseña: false,
                errorVerificarContraseña: false,
                objUsuario: !this.state.Usuario ? {} : {
                  ApellidoMaterno: this.state.ApellidoMaterno,
                  ApellidoPaterno: this.state.ApellidoPaterno,
                  Contraseña: this.state.Contraseña,
                  ListaModulos: { Modulo: ModulosDefault },
                  Nombre: this.state.Nombre,
                  Tipo: this.state.OpcionTipo == "Maestro" ? "Maestro" : "Otro",
                  Usuario: this.state.Usuario,
                  _id: this.state.ColaboradorSeleccionado._idUsuario,
                  _idEmpresa: idVacio
                }
              })
            }

          }
        }
      }
    }

    if (this.ValidarModulo(54)) {
      if(this.state.ContratoIndefinido){
        valor7 = 0;
        this.setState({ errorFechaFin: false });
      }else if ( this.state.FechaFinContrato !== "") {
        if (this.state.FechaFinContrato < this.state.FechaInicioContrato ) {
          valor7 = 1;
          this.setState({ errorFechaFin: true, mensaje: "La fecha final no puede ser antes a la fecha de inicio", colorNotificacion: "danger" });
          this.showNotification();
        }
        else {
          valor7 = 0;
          this.setState({ errorFechaFin: false });
        }
      }
      else {
        valor7 = 1;
        this.setState({ errorFechaFin: true, mensaje: "Propocione fecha final", colorNotificacion: "danger" });
        this.showNotification();
      }

      if (this.state.FechaInicioContrato !== "") {
        valor6 = 0;
        this.setState({ errorFechainicio: false });
      }
      else {
        valor6 = 1;
        this.setState({ errorFechainicio: true, mensaje: "Propocione fecha de inicio", colorNotificacion: "danger" });
        this.showNotification();
      }
    }


    if (this.state.CorreoEmergencia2 !== "") {
      if (IsEmail.test(this.state.CorreoEmergencia2) === false) {
        valor5 = 1;
        this.setState({ errorCorreoEmergencia2: true, mensaje: "El correo que proporcionó no es valido", colorNotificacion: "danger" });
        this.showNotification();
      }
      else {
        valor5 = 0;
        this.setState({ errorCorreoEmergencia2: false });
      }
    }

    if (this.state.CorreoEmergencia1 !== "") {
      if (IsEmail.test(this.state.CorreoEmergencia1) === false) {
        valor4 = 1;
        this.setState({ errorCorreoEmergencia1: true, mensaje: "El correo que proporcionó no es valido", colorNotificacion: "danger" });
        this.showNotification();
      }
      else {
        valor4 = 0;
        this.setState({ errorCorreoEmergencia1: false });
      }
    }

    if (this.state.Correo !== "") {
      if (IsEmail.test(this.state.Correo) === false) {
        valor3 = 1;
        this.setState({ errorCorreo: true, mensaje: "El correo que proporcionó no es valido", colorNotificacion: "danger" });
        this.showNotification();
      }
      else {
        valor3 = 0;
        this.setState({ errorCorreo: false });
      }
    }

    if (this.state.ApellidoPaterno == "") {
      valor2 = 1;
      this.setState({ errorApellidoPaterno: true, mensaje: "Proporcione un apellido", colorNotificacion: "danger" });
      this.showNotification();
    }
    else {
      valor2 = 0;
      this.setState({ errorApellidoPaterno: false });
    }

    if (this.state.Nombre == "") {
      valor1 = 1;
      this.setState({ errorNombre: true, mensaje: "Proporcione un nombre", colorNotificacion: "danger" });
      this.showNotification();
    }
    else {
      valor1 = 0;
      this.setState({ errorNombre: false });
    }

    if (valor1 + valor2 + valor3 + valor4 + valor5 + valor6 + valor7 + valor8 == 0) {
      if (this.state.Sueldo !== this.state.ColaboradorSeleccionado.CantidadContrato || this.state.FechaInicioContrato !== moment(this.state.ColaboradorSeleccionado.ContratoInicio).format("YYYY-MM-DD") || this.state.FechaFinContrato !== moment(this.state.ColaboradorSeleccionado.ContratoFin).format("YYYY-MM-DD")) {
        sueldoNuevo = {
          FechaFin: this.state.FechaFinContrato,
          FechaInicio: this.state.FechaInicioContrato,
          FechaRegistro: moment(new Date()).format("YYYY-MM-DD"),
          Sueldo: parseFloat(this.state.Sueldo),
          _idColaborador: this.state.ColaboradorSeleccionado._id,
          _idEmpleadoRegistro: JSON.parse(cookie.get('id'))
        }

        this.setState({ HistorialSueldo: [...this.state.HistorialSueldo, sueldoNuevo] }, () => this.CrearObjColaborador());
      }
      else {
        this.CrearObjColaborador();
      }
    }
  }

  async ListaHistorialSueldoPorMaestro(idMaestro) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Colaboradores/Colaboradores/ListaHistorialSueldoPorMaestro', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idMaestro: idMaestro })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log("API", data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          //this.showNotification();
        } else { //Filtrado exitoso
          if (data.ListaHistorialSueldo.length > 1) {
            this.setState({ HistorialSueldo: data.ListaHistorialSueldo, cargando: false }, () => {
              this.state.HistorialSueldo.map((sueldo, index) => {
                this.ObtenerInformacionUsuario(sueldo._idEmpleadoRegistro, index);
              })
            });
          }
          else {
            let aux = [];
            aux.push(data.ListaHistorialSueldo);

            this.setState({ HistorialSueldo: aux, cargando: false }, () => {
              this.state.HistorialSueldo.map((sueldo, index) => {
                this.ObtenerInformacionUsuario(sueldo._idEmpleadoRegistro, index);
              })
            });
          }

        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async EliminarInformacionHistorialSueldo(sueldo) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Colaboradores/Colaboradores/EliminarInformacionHistorialSueldo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idSueldo: sueldo._id })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.EliminarSueldo(sueldo)
          this.setState({ mensaje: data.mensaje, colorNotificacion: "success", cargando: false });
          this.showNotification();
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  EliminarSueldo(sueldo) {
    let indexSueldo;

    indexSueldo = this.state.HistorialSueldo.indexOf(sueldo);
    this.state.HistorialSueldo.splice(indexSueldo, 1);

    this.setState({ HistorialSueldo: this.state.HistorialSueldo });
  }

  CrearObjColaborador() {
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    

    if (this.props.op == 0) {
      this.setState({
        ObjColaborador: {
          AnoVigencia: this.state.Vigencia !== "" ? this.state.Vigencia : null,
          ApelidoMaterno: this.state.ApellidoMaterno !== "" ? this.state.ApellidoMaterno : null,
          ApellidoPaterno: this.state.ApellidoPaterno,
          CalleNumero: this.state.CalleNumero !== "" ? this.state.CalleNumero : null,
          CantidadContrato: this.state.Sueldo !== 0 ? parseFloat(this.state.Sueldo) : 0,
          Celular: this.state.Celular !== "" ? this.state.CalleNumero : null,
          Colonia: this.state.Colonia !== "" ? this.state.Colonia : null,
          ContratoFin: this.state.FechaFinContrato !== "" ? moment(this.state.FechaFinContrato).format('YYYY-MM-DD') : "0001-01-01",
          ContratoIndefinido: this.state.ContratoIndefinido,
          ContratoInicio: this.state.FechaInicioContrato !== "" ? moment(this.state.FechaInicioContrato).format('YYYY-MM-DD') : "0001-01-01",
          Correo: this.state.Correo !== "" ? this.state.Correo : null,
          CorreoContacto1: this.state.CorreoEmergencia1 !== "" ? this.state.CorreoEmergencia1 : null,
          CorreoContacto2: this.state.CorreoEmergencia2 !== "" ? this.state.CorreoEmergencia2 : null,
          Curp: this.state.CURP !== "" ? this.state.CURP : null,
          FechaNacimiento: this.state.FechaNacimiento !== "" ? moment(this.state.FechaNacimiento).format('YYYY-MM-DD') : "0001-01-01",
          Foto: this.state.FotoColaborador,
          HistorialSueldo: this.state.HistorialSueldo.length > 0 ? { HistorialSueldo: this.state.HistorialSueldo } : null,
          ListaGrupos: this.state.ArrIdGrupos.length > 0 ? { ObjectId: this.state.ArrIdGrupos } : null,
          Nombre: this.state.Nombre,
          NombreCompleto: this.state.ApellidoMaterno !== "" ? this.state.Nombre + " " + this.state.ApellidoPaterno + " " + this.state.ApellidoMaterno : this.state.Nombre + " " + this.state.ApellidoPaterno,
          NombreContacto1: this.state.NombreEmergencia1 !== "" ? this.state.NombreEmergencia1 : null,
          NombreContacto2: this.state.NombreEmergencia2 !== "" ? this.state.NombreEmergencia2 : null,
          NumeroFM3: this.state.NumeroFM3 !== "" ? this.state.NumeroFM3 : null,
          Ocupacion: this.state.Ocupacion !== "" ? this.state.Ocupacion : null,
          RFC: this.state.RFC !== "" ? this.state.RFC : null,
          Telefono: this.state.Telefono !== "" ? this.state.Telefono : null,
          TelefonoContacto1: this.state.TelefonoEmergencia1 !== "" ? this.state.TelefonoEmergencia1 : null,
          TelefonoContacto2: this.state.TelefonoEmergencia2 !== "" ? this.state.TelefonoEmergencia2 : null,
          Tipo: this.state.OpcionTipo,
          _idCiudad: this.state.IdMunicipio !== "" ? this.state.IdMunicipio : idVacio,
          _idEstado: this.state.IdEstado !== "" ? this.state.IdEstado : idVacio,
          _idPais: this.state.IdPais !== "" ? this.state.IdPais : idVacio,
        }
      }, () => this.RegistrarMaestro())
    }
    else {
      
      this.setState({
        ObjColaborador: {
          AnoVigencia: this.state.Vigencia,
          ApelidoMaterno: this.state.ApellidoMaterno,
          ApellidoPaterno: this.state.ApellidoPaterno,
          CalleNumero: this.state.CalleNumero,
          CantidadContrato: parseFloat(this.state.Sueldo),
          // Celular: this.state.Celular,
          Celular: new AsYouType('MX').input(this.state.Celular),
          Colonia: this.state.Colonia,
          ContratoFin: this.state.FechaFinContrato !== "" ? moment(this.state.FechaFinContrato).format('YYYY-MM-DD') : "0001-01-01",
          ContratoIndefinido: this.state.ContratoIndefinido,
          ContratoInicio: this.state.FechaInicioContrato !== "" ? moment(this.state.FechaInicioContrato).format('YYYY-MM-DD') : "0001-01-01",
          Correo: this.state.Correo,
          CorreoContacto1: this.state.CorreoEmergencia1,
          CorreoContacto2: this.state.CorreoEmergencia2,
          Curp: this.state.CURP,
          FechaNacimiento: this.state.FechaNacimiento !== "" ? moment(this.state.FechaNacimiento).format('YYYY-MM-DD') : "0001-01-01",
          Foto: this.state.FotoColaborador,
          HistorialSueldo: { HistorialSueldo: this.state.HistorialSueldo },
          ListaGrupos: this.state.ArrIdGrupos.length > 0 ? { ObjectId: this.state.ArrIdGrupos } : null,
          Nombre: this.state.Nombre,
          NombreCompleto: this.state.Nombre + " " + this.state.ApellidoPaterno + " " + this.state.ApellidoMaterno,
          NombreContacto1: this.state.NombreEmergencia1,
          NombreContacto2: this.state.NombreEmergencia2,
          NumeroCuenta: this.state.ColaboradorSeleccionado.NumeroCuenta,
          NumeroFM3: this.state.NumeroFM3,
          Ocupacion: this.state.Ocupacion,
          RFC: this.state.RFC,
          // Telefono: this.state.Telefono,
          Telefono: new AsYouType('MX').input(this.state.Telefono),
          TelefonoContacto1: this.state.TelefonoEmergencia1,
          TelefonoContacto2: this.state.TelefonoEmergencia2,
          Tipo: this.state.OpcionTipo,
          _id: this.state.ColaboradorSeleccionado._id,
          _idCiudad: this.state.IdMunicipio !== "" ? this.state.IdMunicipio : idVacio,
          _idEstado: this.state.IdEstado !== "" ? this.state.IdEstado : idVacio,
          _idPais: this.state.IdPais !== "" ? this.state.IdPais : idVacio,
          _idUsuario: this.state.ColaboradorSeleccionado._idUsuario !== idVacio ? this.state.ColaboradorSeleccionado._idUsuario : idVacio,
        }
      }, () => this.RegistrarMaestro())
    }
  }

  async RegistrarMaestro() {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Colaboradores/Colaboradores/RegistrarMaestro', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ colaborador: this.state.ObjColaborador, usuario: this.state.objUsuario })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.setState({ cargando: false })
          this.RegresarMensaje(data.mensaje);
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  RegresarFoto = (foto) => {
    this.setState({ FotoColaborador: foto.split(',')[1] })
  }

  RegresarGrupos = (idGrupos, detalleGrupo) => {
    //console.log(idGrupos)
    this.setState({ ArrIdGrupos: idGrupos, ListaGrupos: detalleGrupo })
  }

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    this.setState({ op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  openModalCapturarFoto = () => {
    this.setState({ showCapturarFoto: true, Titulo: "Capturar imagen", op: 0 });
  }

  openModalAsignarGrupo = () => {
    this.setState({ showAsignarGrupo: true, Titulo: "Asignación de grupos", op: 1 });
  }

  openModalPregunta = (opcion, titulo, cuerpo) => {
    this.setState({ opcionModalPregunta: opcion, TituloModalPregunta: titulo, CuerpoModalPregunta: cuerpo, show: true });
  }

  eventoCapturarFoto = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showCapturarFoto: false, op: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }

  eventoAsignarGrupo = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showAsignarGrupo: false, op: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }

  eventoModalPregunta = (valor) => {
    this.setState({ show: false })
    if (valor) {
      this.EliminarInformacionHistorialSueldo(this.state.SueldoSeleccionado);
    }
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;

    switch (name) {
      case "SueldoTemp":
        let sueldo = value.replace(/[^0-9\.]/g, '');
        if (sueldo < 0 || isNaN(sueldo) || sueldo == "") {
          this.setState({ [name]: 0, Sueldo: 0 });
        }
        else {
          this.setState({ [name]: sueldo, Sueldo: sueldo });
        }
        break;

      case "IdPais":
        this.setState({
          [name]: value,
          IdEstado: "",
          IdMunicipio: "",
          ListaEstados: [],
          ListaMunicipio: [],
        });
        this.ListaEstadosPais(value, 0);
        break;

      case "IdEstado":
        this.setState({
          [name]: value,
          IdMunicipio: "",
          ListaMunicipio: [],
        });
        this.ListaMunicipiosEstado(value, 0)
        break;

      default:
        this.setState({ [name]: value, });
        break;
    }
  }

  handleFocus = (event) => {
    const e = event.target;
    this.setState({ SueldoTemp: this.state.Sueldo }, () => e.select())
  }

  handleBlur = (event) => {
    //console.log(event.target)
    const { name, value } = event.target;
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    this.setState({ [name]: convertirPesos(value) })
  }

  ContraseñaVisible() {
    if (this.state.visible) {
      this.setState({ visible: false })
    }
    else {
      this.setState({ visible: true })
    }
  }

  ContraseñaVisible2() {
    if (this.state.visible2) {
      this.setState({ visible2: false })
    }
    else {
      this.setState({ visible2: true })
    }
  }

  ValidarModulo(modulo) {
    let valido = false;

    Permisos.map(permiso => {
      if (parseInt(permiso.NumeroModulo) == modulo) {
        // console.log(permiso);
        valido = true
      }
    })

    return valido
  }

  handleMouseDownToken(event) {
    event.preventDefault();
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;
    const { HistorialSueldo, FechaInicioContrato } = this.state;
    const IdVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    //console.log(Permisos)

    let agregar;
    if (this.state.showCapturarFoto == true) {
      agregar =
        <ModalCapturarFoto
          Titulo={this.state.Titulo}
          showCapturarFoto={this.state.showCapturarFoto}
          eventClick={this.eventoCapturarFoto}
          op={this.state.op}
          parentCallback={this.RegresarFoto}
        />
    }

    if (this.state.showAsignarGrupo == true) {
      agregar =
        <ModalAsignarGrupo
          Titulo={this.state.Titulo}
          showAsignarGrupo={this.state.showAsignarGrupo}
          eventClick={this.eventoAsignarGrupo}
          op={this.state.op}
          ColaboradorSeleccionado={this.state.ColaboradorSeleccionado}
          parentCallback={this.RegresarGrupos}
        />
    }

    if (this.state.cargando || this.state.cargando2) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando || this.state.cargando2}
        />
      );
    }

    let Grupos;
    if (this.state.OpcionTipo == "Maestro") {
      if (this.state.ListaGrupos.length > 0) {
        Grupos =
          <>
            <Card>
              <CardHeader color="info">Grupos asignados</CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <table>
                      <thead>
                        <th style={{ textAlign: "center" }}>Grupo</th>
                        <th style={{ textAlign: "center" }}>Inicio de curso</th>
                        <th style={{ textAlign: "center" }}>Capacidad</th>
                        <th style={{ textAlign: "center" }}>Alumnos inscritos</th>
                      </thead>
                      <tbody>
                        {this.state.ListaGrupos.map(grupo => {
                          if (grupo.Activo == "true") {
                            return (
                              <tr>
                                <td style={{ textAlign: "center" }}>{grupo.Nombre}</td>
                                <td style={{ textAlign: "center" }}>{moment(grupo.FechaIncioCurso).format("DD-MM-YYYY")}</td>
                                <td style={{ textAlign: "center" }}>{grupo.CapacidadAula}</td>
                                <td style={{ textAlign: "center" }}>{grupo.TotalAlumnos}</td>
                              </tr>
                            )
                          }
                        })}
                      </tbody>
                    </table>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button color="info" tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.openModalAsignarGrupo()}>&nbsp;Asignar grupos</Button>
                    </GridItem>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </>
      }
      else {
        Grupos =
          <>
            <Card>
              <CardHeader color="info">Grupos asignados</CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <table>
                      <thead>
                        <th style={{ textAlign: "center" }}>Grupo</th>
                        <th style={{ textAlign: "center" }}>Inicio de curso</th>
                        <th style={{ textAlign: "center" }}>Capacidad</th>
                        <th style={{ textAlign: "center" }}>Alumnos inscritos</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ textAlign: "center" }}>NO TIENE GRUPOS ASIGNADOS</td>
                        </tr>
                      </tbody>
                    </table>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button color="info" tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.openModalAsignarGrupo()}>&nbsp;Asignar grupos</Button>
                    </GridItem>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </>
      }
    }

    let sueldo;
    if (this.ValidarModulo(54)) {
      if (HistorialSueldo.length > 0) {
        sueldo =
          HistorialSueldo.map(sueldo => {
            return (
              <tr>
                <td style={{ textAlign: "center", marginTop: "10px", verticalAlign: "middle" }}>{moment(sueldo.FechaRegistro).format("DD-MM-YYYY")}</td>
                <td style={{ textAlign: "center", marginTop: "10px", verticalAlign: "middle" }}>{sueldo.NombreEmpleadoRegistro}</td>
                <td style={{ textAlign: "center", marginTop: "10px", verticalAlign: "middle" }}>{moment(sueldo.FechaInicio).format("DD-MM-YYYY")}</td>
                <td style={{ textAlign: "center", marginTop: "10px", verticalAlign: "middle" }}>{
                  sueldo.FechaFin.includes("0001-01-01") ? "Indefinido" :
                moment(sueldo.FechaFin).format("DD-MM-YYYY")
                }</td>
                <td style={{ textAlign: "center", marginTop: "10px", verticalAlign: "middle" }}>{convertirPesos(sueldo.Sueldo)}</td>
                <td><Button data-toggle="tooltip" data-placement="bottom" title="Eliminar sueldo" color="danger" round justIcon size="sm" onClick={() => this.setState({ SueldoSeleccionado: sueldo, show: true })}><Close /></Button></td>
              </tr>
            )
          })
      }
      else {
        sueldo =
          <tr>
            <td colSpan={6} style={{ textAlign: "center", marginTop: "10px", verticalAlign: "middle" }}>NO HAY CAMBIOS REGISTRADOS</td>
          </tr>
      }
    }


    //console.log(this.state.Sueldo)
    //console.log(this.state.ColaboradorSeleccionado)
    //console.log(HistorialSueldo)
    //console.log(FechaInicioContrato)
    //console.log(this.state.SueldoSeleccionado)

    return (
      <div>
        <Modal
          isOpen={this.props.showModalEditarColaborador}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Información general</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={4}>
                  {this.state.FotoColaborador == null ?
                    <img className="overflow-hidden mt-3 ml-5" style={{ width: "150x", height: "150px", borderRadius: "5px" }} src={Persona} />
                    : <img className="overflow-hidden mt-3 ml-4" style={{ maxWidth: "15em", maxHeight: "15em", borderRadius: "5px" }} src={`data:image/png;base64,${this.state.FotoColaborador}`} />}
                  <Button color="info" round size="sm" style={{ marginLeft: "5.8em" }} className="mt-2" onClick={() => this.openModalCapturarFoto()}><CameraAlt />&nbsp;Capturar</Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{ marginTop: "10px" }} id="Tipolabel">Tipo</InputLabel>
                    <Select
                      labelId="Tipolabel"
                      //fullWidth={true}
                      value={this.state.OpcionTipo}
                      onChange={this.handleChange.bind(this)}
                      name="OpcionTipo"
                      label="Tipo"
                      style={{ width: "21.9em", marginTop: "10px" }}
                    >
                      <MenuItem disabled>Seleccione un tipo</MenuItem>
                      <MenuItem value={"Maestro"}>Maestro</MenuItem>
                      <MenuItem value={"Administrativo"}>Administrativo</MenuItem>
                      <MenuItem value={"Dirección"}>Dirección</MenuItem>
                      <MenuItem value={"Secretaría"}>Secretaría</MenuItem>
                      <MenuItem value={"Intendencia"}>Intendencia</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="Nombre"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Nombre"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Nombre,
                      name: "Nombre",
                      id: "Nombre",
                      error: this.state.errorNombre,
                      success: !this.state.errorNombre
                    }}
                  />
                  <TextField
                    id="ApellidoPaterno"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Apellido paterno"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.ApellidoPaterno,
                      name: "ApellidoPaterno",
                      id: "ApellidoPaterno",
                      error: this.state.errorApellidoPaterno,
                      success: !this.state.errorApellidoPaterno
                    }}
                  />

                  <TextField
                    id="ApellidoMaterno"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Apellido materno"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.ApellidoMaterno,
                      name: "ApellidoMaterno",
                      id: "ApellidoMaterno",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                  <TextField
                    id="FechaNacimiento"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="date"
                    format="DD-MM-YYYY"
                    size="small"
                    label="Fecha de nacimiento"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.FechaNacimiento,
                      name: "FechaNacimiento",
                      id: "FechaNacimiento",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                  <TextField
                    id="RFC"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="RFC"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.RFC,
                      name: "RFC",
                      id: "RFC",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                  <TextField
                    id="CURP"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="CURP"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.CURP,
                      name: "CURP",
                      id: "CURP",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                  <TextField
                    id="Ocupacion"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Ocupación"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Ocupacion,
                      name: "Ocupacion",
                      id: "Ocupacion",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Dirección y contacto</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{ marginTop: "10px" }} id="Pais">País</InputLabel>
                    <Select
                      labelId="Pais"
                      //fullWidth={true}
                      value={this.state.IdPais}
                      onChange={this.handleChange.bind(this)}
                      name="IdPais"
                      label="País"
                      style={{ width: "21.9em", marginTop: "10px" }}
                    >
                      <MenuItem disabled>Seleccione un país</MenuItem>
                      {this.state.ListaPaises.map(pais =>
                        <MenuItem value={pais._id}>{(pais.NombrePais)}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{ marginTop: "10px" }} id="Estado">Estado</InputLabel>
                    <Select
                      labelId="Estado"
                      //fullWidth={true}
                      value={this.state.IdEstado}
                      onChange={this.handleChange.bind(this)}
                      name="IdEstado"
                      label="Estado"
                      style={{ width: "21.9em", marginTop: "10px" }}
                    >
                      <MenuItem disabled>Seleccione un estado</MenuItem>
                      {this.state.ListaEstados.map(estado =>
                        <MenuItem value={estado._id}>{(estado.NombreEstado)}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{ marginTop: "10px" }} id="Ciudad">Ciudad</InputLabel>
                    <Select
                      labelId="Ciudad"
                      //fullWidth={true}
                      value={this.state.IdMunicipio}
                      onChange={this.handleChange.bind(this)}
                      name="IdMunicipio"
                      label="Ciudad"
                      style={{ width: "21.9em", marginTop: "10px" }}
                    >
                      <MenuItem disabled>Seleccione un municipio</MenuItem>
                      {this.state.ListaMunicipio.map(Ciudad =>
                        <MenuItem value={Ciudad._id}>{(Ciudad.NombreMunicipio)}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="CalleNumero"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Calle y número"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.CalleNumero,
                      name: "CalleNumero",
                      id: "CalleNumero",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="Colonia"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Colonia"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Colonia,
                      name: "Colonia",
                      id: "Colonia",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="Correo"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Correo"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Correo,
                      name: "Correo",
                      id: "Correo",
                      error: this.state.errorCorreo,
                      success: !this.state.errorCorreo
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="Telefono"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Teléfono"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: new AsYouType('MX').input(this.state.Telefono),
                      name: "Telefono",
                      id: "Telefono",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="Celular"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Celular"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: new AsYouType('MX').input(this.state.Celular),
                      name: "Celular",
                      id: "Celular",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Contactar en caso de emergencia (1)</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="NombreEmergencia1"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Nombre"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.NombreEmergencia1,
                      name: "NombreEmergencia1",
                      id: "NombreEmergencia1",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="TelefonoEmergencia1"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Teléfono"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: new AsYouType('MX').input(this.state.TelefonoEmergencia1),
                      name: "TelefonoEmergencia1",
                      id: "TelefonoEmergencia1",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="CorreoEmergencia1"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="email"
                    size="small"
                    label="Correo"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.CorreoEmergencia1,
                      name: "CorreoEmergencia1",
                      id: "CorreoEmergencia1",
                      error: this.state.errorCorreoEmergencia1,
                      success: !this.state.errorCorreoEmergencia1
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Contactar en caso de emergencia (2)</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="NombreEmergencia2"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Nombre"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.NombreEmergencia2,
                      name: "NombreEmergencia2",
                      id: "NombreEmergencia2",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="TelefonoEmergencia2"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Teléfono"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: new AsYouType('MX').input(this.state.TelefonoEmergencia2),
                      name: "TelefonoEmergencia2",
                      id: "TelefonoEmergencia2",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="CorreoEmergencia2"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="email"
                    size="small"
                    label="Correo"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.CorreoEmergencia2,
                      name: "CorreoEmergencia2",
                      id: "CorreoEmergencia2",
                      error: this.state.errorCorreoEmergencia2,
                      success: !this.state.errorCorreoEmergencia2
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {this.ValidarModulo(54) ?///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            <Card>
              <CardHeader color="info">Contrato</CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div className="table-responsive text-nowrap">
                      <table className="table">
                        <thead>
                          <th style={{ textAlign: "center" }}>Fecha inicio</th>
                          <th style={{ textAlign: "center" }}>Fecha fin</th>
                          <th style={{ textAlign: "center" }}>Sueldo</th>
                          <th></th>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              <TextField
                                id="FechaInicioContrato"
                                style={{ width: "15em", marginTop: "10px" }}
                                variant="outlined"
                                type="date"
                                format="DD-MM-YYYY"
                                size="small"
                                label="Inicio de contrato"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  onChange: this.handleChange.bind(this),
                                  value: this.state.FechaInicioContrato,
                                  name: "FechaInicioContrato",
                                  id: "FechaInicioContrato",
                                  error: this.state.errorFechainicio,
                                  success: !this.state.errorFechainicio
                                }}
                              />
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <TextField
                                id="FechaFinContrato"
                                style={{ width: "15em", marginTop: "10px" }}
                                variant="outlined"
                                type={!this.state.ContratoIndefinido ? "date" : "text"}
                                format="DD-MM-YYYY"
                                size="small"
                                label="Fin de contrato"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  onChange: this.handleChange.bind(this),
                                  value: !this.state.ContratoIndefinido ? this.state.FechaFinContrato : "indefinido",
                                  name: "FechaFinContrato",
                                  id: "FechaFinContrato",
                                  error: this.state.errorFechaFin,
                                  success: !this.state.errorFechaFin
                                }}
                              />
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <TextField
                                id="SueldoTemp"
                                style={{ width: "15em", marginTop: "10px" }}
                                variant="outlined"
                                type="text"
                                size="small"
                                label="Sueldo"
                                InputProps={{
                                  onChange: this.handleChange.bind(this),
                                  onFocus: this.handleFocus.bind(this),
                                  onBlur: this.handleBlur.bind(this),
                                  value: this.state.SueldoTemp,
                                  name: "SueldoTemp",
                                  id: "SueldoTemp",
                                  //error: this.state.errorFechainicio,
                                  //success: !this.state.errorFechainicio
                                }}
                              />
                            </td>
                            {/* <td><Button style={{marginTop: "15px"}} data-toggle="tooltip" data-placement="bottom" title="Agregar sueldo" color="success" round justIcon size="sm" onClick={() => this.CrearHistorialSueldos()}><Add /></Button></td> */}
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <Checkbox
                                onChange={() => this.setState({
                                  ContratoIndefinido: !this.state.ContratoIndefinido,
                                })}
                                name="ContratoIndefinido" checked={this.state.ContratoIndefinido} />
                              contrato indefinido
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Accordion TransitionProps={{ unmountOnExit: true }}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Historial de sueldos</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="table-responsive text-nowrap table-wrapper-scroll-y">
                          <table className="table">
                            <thead>
                              <th style={{ textAlign: "center" }}>Fecha movimiento</th>
                              <th style={{ textAlign: "center" }}>Realizó movimiento</th>
                              <th style={{ textAlign: "center" }}>Fecha inicio</th>
                              <th style={{ textAlign: "center" }}>Fecha fin</th>
                              <th style={{ textAlign: "center" }}>Sueldo</th>
                            </thead>
                            <tbody>
                              {sueldo}
                            </tbody>
                          </table>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card> : ""}
          <Card>
            <CardHeader color="info">Permiso de trabajo</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="NumeroFM3"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Número FM3"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.NumeroFM3,
                      name: "NumeroFM3",
                      id: "NumeroFM3",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="Vigencia"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Vigencia (MM/AAAA)"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Vigencia,
                      name: "Vigencia",
                      id: "Vigencia",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {this.state.OpcionTipo == "Maestro" && this.ValidarModulo(54) ? <Card>
            <CardHeader color="info">Datos de acceso a la plataforma</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="Usuario"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Usuario"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                      onChange: this.handleChange.bind(this),
                      value: this.state.Usuario,
                      name: "Usuario",
                      id: "Usuario",
                      error: this.state.errorUsuario,
                      success: !this.state.errorUsuario
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="Contraseña"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type={this.state.visible ? "text" : "password"}
                    size="small"
                    label="Contraseña"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKey />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.ContraseñaVisible()}
                            onMouseDown={this.handleMouseDownToken}
                            edge="end"
                          >
                            {this.state.visible ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      onChange: this.handleChange.bind(this),
                      value: this.state.Contraseña,
                      name: "Contraseña",
                      id: "Contraseña",
                      error: this.state.errorContraseña,
                      success: !this.state.errorContraseña
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="VerificarContraseña"
                    style={{ width: "25em", marginTop: "10px" }}
                    variant="outlined"
                    type={this.state.visible2 ? "text" : "password"}
                    size="small"
                    label="Confirmar contraseña"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKey />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.ContraseñaVisible2()}
                            onMouseDown={this.handleMouseDownToken}
                            edge="end"
                          >
                            {this.state.visible2 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      onChange: this.handleChange.bind(this),
                      value: this.state.VerificarContraseña,
                      name: "VerificarContraseña",
                      id: "VerificarContraseña",
                      error: this.state.errorVerificarContraseña,
                      success: !this.state.errorVerificarContraseña
                    }}
                  />
                </GridItem>

              </GridContainer>
            </CardBody>
          </Card> : ""}
          {Grupos}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button color="info" tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.CrearHistorialSueldos()}><Save />&nbsp;Guardar</Button>
              <Button tabIndex="1" size='sm' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick("esc")}><Clear />&nbsp;Cancelar</Button>
            </GridItem>
          </GridContainer>
        </Modal>
        <div id="">
          <ModalPregunta
            Titulo={"Historial de sueldos"}
            Cuerpo={"¿Seguro que desea eliminar este registro?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalEditarColaborador);