import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp, TransferWithinAStationSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import cookie from 'js-cookie'
import moment from "moment";
import Divider from '@material-ui/core/Divider';
var num2Let = require('numalet')({ unidad: 'MN', decimalesSiempre: true });

const EnumTipoPago = [
  "Efectivo",
  "Tarjeta_de_Crédito",
  "Tarjeta_de_Débito",
  "Cheque",
  "Depósito",
  "Transferencia",
]

class ModalCobrar extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      BuscarAlumno: "",
      MostrarModal: false,
      _id: null,
      IdUsuario: null,
      PagoSelecionado: [],
      MotivoCancelacion: "",
      ListaUsuarios: [],
      FormaPago: 0,
      numerocuenta: "",
      banco: "",
      numerocheque: "",
      ArrayPagos: [],
      Venta: {},
      numerofolio: "",
      numeromovimiento: "",
      ListaVenta: [],
      idEmpresas: [],
      infoEmpresa: [{ RFC: "", RazonSocial: "" }],
      RequiereFactura: false,
      Total: 0,
      Importe: 0,
      ImporteTotal: 0,
      Fecha: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      disabled: false,
      errorMotivoCancelacion: false,
      errorImporte: false,
      errorUsuarios: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton: false,
      NombreGuardado: '',
      SubcategoriaHabilitada: false,
      MostrarModalCargando: false,
      modeloVenta: [],
      modeloPagos: [],
      // Crear arrays para la venta
      Ventas: [],
      CuerpoCargando: "Cargando"

    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let IDcookie = JSON.parse(cookie.get('id'));
    this.setState({ IdUsuario: IDcookie })

    this.setState({
      // PagoSelecionado: this.props.PagoSelecionado,
      PagoAlumnos: this.props.ListaVenta,
      Total: this.props.Total,
      Importe: this.props.ImporteTotal,
      ImporteTotal: this.props.ImporteTotal,
      MostrarModalCargando: true,
    }, () => {
      this.ListarEmpresas()
      // this.CrearArregloPago()
    });
  }

  ListarEmpresas() {
    var empresas = []
    var PagoCobroAlumno = this.state.PagoAlumnos

    //console.log(PagoCobroAlumno)

    PagoCobroAlumno.map(pago => {
      if (empresas.length == 0) {
        // si la lista esta vacia
        empresas.push(pago.PagoCompraAlumno._idEmpresa)
      } else {
        // si la lista no esta vacia
        // comprobar que si existe la empresa
        var exist = false;
        empresas.map(empresa => {
          if (JSON.stringify(empresa) == JSON.stringify(pago.PagoCompraAlumno._idEmpresa)) {
            exist = true;
          }
        });
        //si no existe agregarla
        if (!exist) {
          empresas.push(pago.PagoCompraAlumno._idEmpresa)
        }
      }
    })

    this.setState({
      idEmpresas: empresas
    }, () => {
      this.ObtenerRFCs()
    });
  }

  FormValidacion() {
    let error = false;
    if (this.state.Importe == "") {
      error = true;
    }

    return error;
  }

  ObtenerCifraLetras(Numero) {
    var total = num2Let(Numero);

    if (Numero % 1 == 0) {
      //Es un numero entero
      var str1 = total.slice(0, -3)
      var str2 = "PESOS";
      var str3 = total.substr(-3, 3);

      return str1 + " " + str2 + " " + str3;
    } else {
      //Es un numero decimal
      var str1 = total.slice(0, -10)
      var str2 = "PESOS";
      var str3 = total.substr(-10, 10);

      return str1 + " " + str2 + " " + str3;

    }
  }
  ListarAlumnos() {
    var PagoAlumnos = this.state.PagoAlumnos
    var Alumnos = []

    PagoAlumnos.map(pago => {
      if (Alumnos.length == 0) {
        //si Alumnos esta vacio
        Alumnos.push(pago.PagoCompraAlumno._idAlumno)
      } else {
        //si Alumnos NO esta vacio
        var repetido = false
        Alumnos.map(Alum => {
          //validar que no sea repetido
          if (JSON.stringify(pago.PagoCompraAlumno._idAlumno) == JSON.stringify(Alum)) {
            repetido = true
          }
        })
        //si no esta repetido agregarlo
        if (repetido == false) {
          Alumnos.push(pago.PagoCompraAlumno._idAlumno)
        }
      }
    })

    return Alumnos
  }
  CrearArregloPago() {
    this.setState({ MostrarModalCargando: true, disabled: true })
    if (this.state.Importe < this.state.ImporteTotal) {
      this.setState({ colorNotificacion: 'danger', mensaje: "El el pago no puede ser menor al importe" });
      this.showNotification();
      this.setState({ MostrarModalCargando: false, disabled: false })
    } else {
      var ListVentaModel = []
      var Alumnos = this.ListarAlumnos()
      // console.log("Alumnos: ",Alumnos);
      let Pagos;
      let Venta;
      // let Aux = {};
      let Aux2 = [];
      // var PagosCompra = []

      //armar listas de ventas por alumnos
      Alumnos.map((alum, index) => {
        //console.log("ALUM: ", alum)
        let Aux = {};
        // let Aux2 = [];
        var PagosCompra = []
        //obtener pagos
        var PagosAlumno = this.state.PagoAlumnos.filter(pago => { return JSON.stringify(pago.PagoCompraAlumno._idAlumno) == JSON.stringify(alum) })
        // console.log("Alumnos: ",PagosAlumno);
        //sacar info de los pagos
        var descuentoTotal = 0
        var subtotal = 0
        var iva = 0
        var total = 0;
        var DescuentoFinal = 0;
        var subtotal = 0;
        var descontado = 0;
        //console.log("PagosAlumno: ", PagosAlumno)

        PagosAlumno.map(pago => {
          DescuentoFinal += (parseFloat(pago.Pendiente) * (pago.descuento / 100)) + pago.descuentopesos;

          if (pago.AplicaInventario) {
            pago.existencia = pago.existencia - pago.Cantidad
          }

          if (pago.TipoProducto !== "Producto") {
            PagosCompra.push({
              AplicaIVA: false,
              AplicaInventario: pago.AplicaInventario,
              Cantidad: pago.Cantidad,
              Descripcion: pago.Descripcion,
              Descuento: pago.descuento,
              DescuentoPesos: pago.descuentopesos,
              DescuentoTotalPesos: 0,
              Existencia: pago.existencia,
              Extemporaneo: pago.Extemporaneo,
              //FechaPago: this.state.Fecha,
              FechaRegistroAdeudo: pago.FechaRegistroAdeudo,
              IVA: pago.TipoProducto == "ConceptoPago" ? ((parseFloat(pago.Monto) / 1.16) * 0.16) : 0,
              IdGrupo: pago.IdGrupo,
              Monto: parseFloat(pago.Importe),
              Pendiente: parseFloat(pago.Pendiente),
              Precio: pago.Precio,
              Subtotal: (parseFloat(pago.Monto) / 1.16),
              TipoProducto: pago.TipoProducto,
              _idAlumno: pago.PagoCompraAlumno._idAlumno,
              _idConcepto: pago._idConcepto,
              _idEmpresa: pago.PagoCompraAlumno._idEmpresa,
              _idPagoAlumno: pago._idPagoAlumno,
              //_idVenta: pago._id
            })
          }
          else {
            PagosCompra.push({
              AplicaIVA: false,
              AplicaInventario: pago.AplicaInventario,
              Cantidad: pago.Cantidad,
              Descripcion: pago.Descripcion,
              Descuento: pago.descuento,
              DescuentoPesos: pago.descuentopesos,
              DescuentoTotalPesos: DescuentoFinal,
              Existencia: pago.existencia,
              Extemporaneo: pago.Extemporaneo,
              //FechaPago: this.state.Fecha,
              FechaRegistroAdeudo: pago.FechaRegistroAdeudo,
              IVA: 0,
              IdGrupo: pago.IdGrupo,
              Monto: parseFloat(pago.Importe),
              Pendiente: pago.descuento == 100 ? 0 : parseFloat(pago.Pendiente),
              Precio: pago.Precio,
              Subtotal: pago.Monto,
              TipoProducto: pago.TipoProducto,
              _idAlumno: pago.PagoCompraAlumno._idAlumno,
              _idConcepto: pago._idConcepto,
              _idEmpresa: pago.PagoCompraAlumno._idEmpresa,
              _idPagoAlumno: pago._idPagoAlumno,
              //_idVenta: pago._id
            })
          }

          total += pago.Importe
        })

        Venta = {
          Acreditado: true,
          Banco: this.state.banco,
          CancelacionAfectaEfectivo: false,
          Cancelada: false,
          Descuento: DescuentoFinal,
          Fecha: moment(this.state.Fecha).format("YYYY-MM-DD"),
          //FechaAcreditacion: "0001-01-01",
          //FechaCancelacion: "0001-01-01",
          IdUsuario: this.state.IdUsuario,
          IdUsuarioAutoriza: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
          IdUsuarioCancela: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
          Iva: Math.round((parseFloat(this.state.Total) / 1.16) * 0.16),
          MontoDevuelto: this.state.Importe < this.state.ImporteTotal ? 0 : (parseFloat(this.state.Importe) - parseFloat(this.state.ImporteTotal)),
          NombreAlumno: PagosAlumno[0].NombreAlumno,
          RazonSocial: this.state.infoEmpresa[0].RazonSocial,
          NumeroCheque: this.state.numerocheque.toString(),
          NumeroCuenta: this.state.numerocuenta.toString(),
          NumeroFolio: this.state.numerofolio.toString(),
          NumeroMovimiento: this.state.numeromovimiento.toString(),
          RequiereFactura: this.state.RequiereFactura,
          Subtotal: Math.round(parseFloat(this.state.Total)/*  + (parseFloat(this.state.Total) * 0.16) */),
          TipoPago: EnumTipoPago[this.state.FormaPago],
          Total: parseFloat(total),
          //VentaVirtual: false,
          _idAlumno: alum,
          _idEmpresa: PagosAlumno[0].PagoCompraAlumno._idEmpresa,
        }

        ListVentaModel.push({ Pagos: { PagoComprasAlumno: PagosCompra }, venta: Venta })

        Aux = {
          Pagos: { PagoComprasAlumno: PagosCompra },
          venta: Venta
        }

        Aux2.push(Aux)
      })

      // console.log("VENTAS: ",Aux2)


      this.setState({
        ListaVenta: { VentaModel: Aux2 },
        // MostrarModalCargando:false
      }, () => {
        this.RegistrarPago()
      })
    }

  }

  // Metodos Api---------------------------------------------------------------------------------------------------------------------------------------------------------
  RegistrarPago() {
    //this.RegresarMensaje(true);
    this.setState({ MostrarModalCargando: true });
    fetch(Globales.Url + 'Cobranza/Cobranza/RegistrarPagoCompraAlumnos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        ListadoVentas: this.state.ListaVenta,
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, MostrarModalCargando: false, disabled: false });
          this.showNotification();
        } else {
          //Filtrado exitoso
          this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, MostrarModalCargando: false });
          this.openPdf(data.Recibo)
          this.RegresarMensaje(data.mensaje);
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", MostrarModalCargando: true}));;
  }
  
  openPdf(basePdf) {
    let byteCharacters = atob(basePdf);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: 'application/pdf;base64' });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  ObtenerRFCs() {
    this.setState({ MostrarModalCargando: true })
    fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerInformacionMultipleEmpresa', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        ids: this.state.idEmpresas
      })
    })
      .then(res => res.json())
      .then(data => {
        //console.log(data)
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, MostrarModalCargando: false });
          this.showNotification();

        } else { //Filtrado exitoso
          if (data.idEmpresa.length > 1) {
            this.setState({ MostrarModalCargando: false, infoEmpresa: data.idEmpresa });
          }
          else {
            let aux = [];
            aux.push(data.idEmpresa);

            this.setState({ MostrarModalCargando: false, infoEmpresa: aux });
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", MostrarModalCargando: true}));;
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  RegresarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op1: 1 });
    // this.props.parentCallback(mensaje);
    this.props.eventClick('', this.state.ListaVenta);
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value, type, checked } = e.target;
    // this.state.Importe
    switch (name) {
      case "FormaPago":
        this.setState({ [name]: parseFloat(value), Importe: this.state.ImporteTotal });
        break;
      case "Importe":
        let importe = value.replace(/[^0-9\.]/g, '');
        this.setState({ [name]: importe });
        break;

      case "RequiereFactura":
        if (checked) {
          this.setState({ [name]: true });
        }
        else {
          this.setState({ [name]: false });
        }
        break;

      default:
        this.setState({ [name]: value });
        break;
    }
  }

  handleFocus = (event) => {
    const e = event.target;
    e.select()
  }

  handleBlur = (e) => {
    const { name, value } = e.target;

    if (value == "") {
      this.setState({ [name]: 0 })
    }
    else if (isNaN(this.state.Importe)) {
      this.setState({ [name]: 0 })
    }
    else if (value == 0) {
      this.setState({ [name]: 0 })
    }
  }

  obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;

    //console.log(this.state.PagoAlumnos)
    //console.log(this.state.infoEmpresa)
    //console.log(this.state.RequiereFactura)
    let agregar
    if (this.state.MostrarModalCargando == true) {
      agregar = (
        <Cargando
          show={this.state.MostrarModalCargando}
          Cuerpo={this.state.CuerpoCargando}
        />
      );
    }

    let InformacionEmpresas;
    if (this.state.infoEmpresa.length !== 0) {
      InformacionEmpresas = (
        this.state.infoEmpresa.map(empresa => {

          let TotalPorEmpresa = 0;
          this.props.ListaVenta.map(pago => {
            if (JSON.stringify(empresa._id) == JSON.stringify(pago.PagoCompraAlumno._idEmpresa)) {
              TotalPorEmpresa += parseFloat(pago.Importe)
            }
          })

          return (
            <>
              <tr>
                <td style={{ width: '15%' }}>RFC:</td>
                <td>{empresa.RFC}</td>
                <td className="pull-right">${parseFloat(TotalPorEmpresa).toFixed(2)}</td>
              </tr>
              <tr>
                <td colSpan="3">
                  {empresa.RazonSocial}
                </td>
              </tr>
              <tr><td className="mt-2" colSpan={5}><Divider /></td></tr>
            </>
          )
        })
      );
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showCobrar}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info"><h4>{this.props.Titulo}</h4></CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem className="center" xs={12} sm={12} md={6} lg={6}
                  style={{ marginTop: "2rem" }}>
                  <center>
                    <h4>Forma de pago</h4>
                  </center>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Fecha de Pago"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      // disabled: true,
                      //required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Fecha,
                      name: "Fecha",
                      id: "Fecha",
                      type: "date",
                      //error: this.state.errorToken,
                      //success: !this.state.errorToken
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Select
                    value={this.state.FormaPago}
                    onChange={this.handleChange}
                    name="FormaPago"
                    fullWidth={true}
                    variant="outlined"
                    // label="Productos"
                    style={{ marginTop: "25px" }}
                  //error={this.state.errorUsuarios}
                  //success={!this.state.errorUsuarios}
                  >
                    <MenuItem disabled>Seleccione una forma de pago</MenuItem>
                    <MenuItem value={0}>Efectivo</MenuItem>
                    <MenuItem value={1}>Tarjeta de Crédito</MenuItem>
                    <MenuItem value={2}>Tarjeta de Débito</MenuItem>
                    <MenuItem value={3}>Cheque</MenuItem>
                    <MenuItem value={4}>Depósito</MenuItem>
                    <MenuItem value={5}>Transferencia</MenuItem>
                  </Select>
                </GridItem>
              </GridContainer>
              <GridContainer hidden={this.state.FormaPago == 0}>
                {!(this.state.FormaPago == 1 || this.state.FormaPago == 2) ? null :
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <CustomInput
                      labelText="No. de cuenta"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        //required: true,
                        onChange: this.handleChange.bind(this),
                        value: this.state.numerocuenta,
                        name: "numerocuenta",
                        id: "numerocuenta",
                        type: "text",
                        //error: this.state.errorToken,
                        //success: !this.state.errorToken
                      }}
                    />
                    <CustomInput
                      labelText="Banco"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        //required: true,
                        onChange: this.handleChange.bind(this),
                        value: this.state.banco,
                        name: "banco",
                        id: "banco",
                        type: "text",
                        //error: this.state.errorToken,
                        //success: !this.state.errorToken
                      }}
                    />
                  </GridItem>}
                <GridItem hidden={this.state.FormaPago !== 3} xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Número de cheque"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      //required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.numerocheque,
                      name: "numerocheque",
                      id: "numerocheque",
                      type: "text",
                      //error: this.state.errorToken,
                      //success: !this.state.errorToken
                    }}
                  />
                </GridItem>
                <GridItem hidden={this.state.FormaPago !== 4} xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Número de folio"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      //required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.numerofolio,
                      name: "numerofolio",
                      id: "numerofolio",
                      type: "text",
                      //error: this.state.errorToken,
                      //success: !this.state.errorToken
                    }}
                  />
                </GridItem>
                <GridItem hidden={this.state.FormaPago !== 5} xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Número de movimiento"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      //required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.numeromovimiento,
                      name: "numeromovimiento",
                      id: "numeromovimiento",
                      type: "text",
                      //error: this.state.errorToken,
                      //success: !this.state.errorToken
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <br />
            <Card>
              <CardHeader color="info"><h4>Desglose por empresas</h4></CardHeader>
              <CardBody>
                <table style={{ fontSize: '1.1rem' }}>
                  <thead>
                    <th class="text-Left" colSpan="2" ><b>Razón social</b></th>
                    <th className="pull-right" style={{ width: '50%' }}><b>Total</b></th>
                  </thead>
                  <tbody>
                    {InformacionEmpresas}
                  </tbody>
                </table>
              </CardBody>
            </Card>
            <br />
            <CardHeader color="info">
              <h4>Total a pagar: {"$" + (parseFloat(this.state.ImporteTotal)).toFixed(2)}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <b>SON: </b>{this.ObtenerCifraLetras(parseFloat(this.state.ImporteTotal))}
                </GridItem>
                <GridItem hidden={this.state.FormaPago != 0} xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Cantidad recibida"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      //required: true,
                      onChange: this.handleChange.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      onBlur: this.handleBlur.bind(this),
                      value: this.state.Importe,
                      name: "Importe",
                      id: "Importe",
                      type: "text",
                      error: this.state.errorImporte,
                      success: !this.state.errorImporte
                    }}
                  />
                </GridItem>
                <GridItem hidden={this.state.FormaPago != 0} xs={12} sm={12} md={12} lg={12}>
                  <b>Cambio: </b>
                  {this.state.Importe < this.state.ImporteTotal ? "$0.00" : "$" + (parseFloat(this.state.Importe) - parseFloat(this.state.ImporteTotal)).toFixed(2)}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <Checkbox
                    name={"RequiereFactura"}
                    checked={this.state.RequiereFactura}
                    onChange={this.handleChange}
                  />
                  Requiere Factura
                </GridItem>
                {/* Botones */}
                <GridItem xs={12} sm={12} md={8} lg={8}>
                  <Button size='sm' color='danger' className='pull-right' round onClick={() => this.props.eventClick('esc')}>&nbsp;Cancelar</Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <Button size='sm' color='success' disabled={this.state.disabled} className='pull-right' round onClick={() => this.CrearArregloPago('esc')} >&nbsp;Cobrar</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        {agregar}
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalCobrar);