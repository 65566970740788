import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import cookie from 'js-cookie'

class ModalEliminarPago extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
        BuscarAlumno: "",
      MostrarModal:false,
      _id: null,
      PagoSelecionado: [],
      MotivoCancelacion: "",
      ListaUsuarios: [],
      Usuario: "usuarios",
      Token: "",
      disabled:false,
      errorMotivoCancelacion: false,
      errorToken: false,
      errorUsuarios: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: ""
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    //console.log(this.props)

    this.ObtenerUsuarios();
    let IDcookie = JSON.parse(cookie.get('id'));
    this.setState({_id: IDcookie})
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  /* FormValidacion(){
    let error = false;
    if(this.state.MotivoCancelacion == ""){
      error = true;
      this.setState({errorMotivoCancelacion: true});
    }
    if(this.state.Token == ""){
      error = true;
      this.setState({errorToken: true});
    }
    if(this.state.Usuario == "usuarios"){
      error = true;
      this.setState({errorUsuarios: true});
    }
    return error;
  } */

  ValidarToken(){
    this.state.ListaUsuarios.map(usuario => {
      if(this.state.Token == usuario.Token){
        this.props.eventClick(true);
        //console.log(true)
      }
      else{
        //console.log(false)
        this.setState({aviso: "* El token proporcionado no es válido, favor de verificarlo"})
      }
    })
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------
//   EliminarPago(){
//     var valor1 = 0;
//     var valor2 = 0;
//     var valor3 = 0;

//     if(this.state.MotivoCancelacion.trim() === ""){
//       this.setState({errorMotivoCancelacion: true, mensaje: "Ingrese el motivo de la cancelación", colorNotificacion: "danger"});
//       valor1 = 1;
//     }else{
//       this.setState({errorMotivoCancelacion: false});
//       valor1 = 0;
//     }
//     if(this.state.Usuario === "usuarios"){
//       this.setState({errorUsuarios: true, mensaje: "Seleccione un usuario", colorNotificacion: "danger"});
//       valor2 = 1;
//     }else{
//       this.setState({errorUsuarios: false});
//       valor2 = 0;
//     }
//     if(this.state.Token.trim() === ""){
//       this.setState({errorToken: true, mensaje: "Ingrese token", colorNotificacion: "danger"});
//       valor3 = 1;
//     }else{
//       this.setState({errorToken: false});
//       valor3 = 0;
//     }

//     if(valor1 + valor2 + valor3 == 0){

//     fetch(Globales.Url + 'Cobranza/Cobranza/EliminarPago', {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
//       }
//       , body: JSON.stringify(this.state)
//     }
//     ).then(res => res.json())
//       .then(data => {
//         if (data.error) { //Error en la respuesta
//           this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
//           this.showNotification();
//          } else { //Filtrado exitoso        
//             //console.log(data.ListaProducto);
//             this.setState({colorNotificacion: 'success', mensaje: data.mensaje });
//             this.RegresarMensaje(data.mensaje);
//         }
//       })
//       .catch(err => console.error(err)); 
//   }
//   else{
//     this.setState({mensaje: "Completa los campos", colorNotificacion: "danger"})
//     this.showNotification();
//   }
// }

  ObtenerUsuarios(){
    fetch(Globales.Url + 'Cobranza/Cobranza/ListaUsuarios', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify(this.state)
    })
    .then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
         } else { //Filtrado exitoso
            //console.log("📦",data.ListaUsuarios);
            var ListaUsers = []
            data.ListaUsuarios.map( user => {
              if ("Token" in user) {
                ListaUsers.push(user);
              }
            })
            this.setState({ ListaUsuarios: ListaUsers});
        }
      })
      .catch(err => console.error(err)); 
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  RegresarMensaje(mensaje) {
    this.setState({mensaje:mensaje, op1: 1 });
    this.props.parentCallback(mensaje);
    this.props.eventClick('esc');
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      aviso: ""
    });
  }

  obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  }

 
  render() {
    const { classes } = this.props;
    //console.log(this.state.PagoSelecionado);
    //console.log(this.state.ListaUsuarios)
    return (
      <div>
        <Modal
          isOpen={this.props.showToken}
          className="static-modal"
          size="md"
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div>Proporcione su token de autorización</div>
                <CustomInput
                    labelText="Token"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      //required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Token,
                      name: "Token",
                      id: "Token",
                      type: "password",
                      //error: this.state.errorToken,
                      //success: !this.state.errorToken
                    }}
                  />
                </GridItem>
                <div style={{color: "red"}}>{this.state.aviso}</div>
              </GridContainer>
            </CardBody>
          </Card>
              {/* Botones */}
              <div className='mt-4'>
                  <GridItem>
                    <Button tabIndex="1" size='sm' round onClick={() => this.props.eventClick(false)}>&nbsp;Cerrar</Button>
                    <Button tabIndex="0" id="btnGuardar" size='sm' color='danger' className='pull-right' round onClick={this.ValidarToken.bind(this)}>&nbsp;Autorizar</Button>
                  </GridItem>
                </div>

        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalEliminarPago);