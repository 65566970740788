import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Add, Check } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";

const EnumMes = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
class ModalBuscarAlumnos extends Component {

  constructor(props, context) {
    super(props, context);
    // this.escFunction = this.escFunction.bind(this);
    this.state = {
      idiomaSeleccionado:"null",
      AñoSeleccionado:new Date().getFullYear(),
      ListaIdiomas: [],
      AlumnoSeleciondado: {},
      Meses:[
        {Select:false,valor:1},
        {Select:false,valor:2},
        {Select:false,valor:3},
        {Select:false,valor:4},
        {Select:false,valor:5},
        {Select:false,valor:6},
        {Select:false,valor:7},
        {Select:false,valor:8},
        {Select:false,valor:9},
        {Select:false,valor:10},
        {Select:false,valor:11},
        {Select:false,valor:12},
      ],
      idconcepto:null,
      Cargando:false,
      PagosFuturos:[],
      grupos:[],
      CuerpoCargando: "Cargando",
      PrecioConcepto:0
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }
  CrearListaPeticion(){
    var pagofuturo = {
      Cantidad: 1,
      Devolucion: false,
      Estado: "No_Pagado",
      FechaPagado: new Date(),
      FechaPago: new Date(this.state.AñoSeleccionado,1,1).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaRegistro: new Date(this.state.AñoSeleccionado, 1, 1),
      Monto: this.state.PrecioConcepto,
      MontoTotal: this.state.PrecioConcepto,
      NombreConcepto: "Inscripción " + this.state.idiomaSeleccionado.Nombre + " (" + (this.state.AñoSeleccionado).toString() +")",
      TipoConcepto: "Inscripción",
      Vencido: false,
      _idAlumno: this.props.AlumnoSeleciondado._id,
      _idConcepto: this.state.idconcepto,
      _idGrupo: this.state.idiomaSeleccionado.idGrupo,
      _idIdioma: this.state.idiomaSeleccionado._id,
    }

    this.setState({ 
      PagosFuturos: pagofuturo
    },()=>{
      this.RegistrarPagoFuturo()
    })
  }


  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    this.setState({
      AlumnoSeleciondado: this.props.AlumnoSeleciondado._id,
      Cargando:true,
    })
    this.obtenerIdiomas()
    //console.log("Alumno", this.props.AlumnoSeleciondado)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------
  obtenerIdiomas(){
    this.setState({ Cargando:true, })
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaGeneralIdiomas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idAlumno: this.props.AlumnoSeleciondado._id
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ Cargando:false, colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
         } else { //Filtrado exitoso
           //console.log('idiomas exitoso',data);
          if(data.data.length >1){
            this.setState({ 
              ListaIdiomas: data.data,
              Cargando: false
            })
          }else{
            let aux = [data.data]
            this.setState({ 
              ListaIdiomas: aux,
              Cargando: false
            })

          }
        }
      })
      .catch(err => console.log(err)); 
  }

  ObtenerConceptosIdioma(){
    if(this.state.idiomaSeleccionado=="null"){
      this.setState({ colorNotificacion: 'danger', mensaje:'Seleccione un idioma antes de continuar'});
      this.showNotification();
    }else{
      this.setState({ Cargando:true, })
      fetch(Globales.Url + 'Cobranza/Cobranza/ListaConceptoPagoIdiomaInscripcion', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({
          idIdioma:this.state.idiomaSeleccionado,
        })
      }
      ).then(res => res.json())
        .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, Cargando: false });
            this.showNotification();
           } else { //Filtrado exitoso
            //console.log("concepto",data)

            let precio = 0;
            if(data.data.CobroClases !== null && data.data.CobroClases !== undefined){
              data.data.CobroClases.CobroClase.map(cobro =>{
                if((this.props.AlumnoSeleciondado.Clase) == cobro.Clase){
                  //console.log("Entra precio", cobro.Clase + " " + cobro.Costo)
                  precio = cobro.Costo
                }
              })
              this.setState({idconcepto: data.data._id, PrecioConcepto: precio, Cargando: false},()=>{this.CrearListaPeticion()});
            }
            else{
              this.setState({mensaje: "Este idioma no tiene conceptos registrados", colorNotificacion: "danger", Cargando: false});
              this.showNotification();
            }           
          }
        })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", Cargando: true})); 
    }
  }
  RegistrarPagoFuturo(){
    this.setState({ Cargando:true, })
    fetch(Globales.Url + 'Cobranza/Cobranza/RegistrarPagoInscripcion', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        Fecha: new Date(this.state.AñoSeleccionado, 1, 1),
        PagoAlumno: this.state.PagosFuturos,
        IdIdioma: this.state.idiomaSeleccionado._id
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, Cargando: false });
          this.showNotification();
         } else { //Filtrado exitoso
          this.setState({ Cargando:false, })
          this.closeModal("Inscripción realizada exitosamente")
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", Cargando: true})); 
  }


  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  SeleccionarAlumno(ListaAlumnos) {
    this.setState({AlumnoSeleciondado:ListaAlumnos, op: 1 });
    this.props.parentCallback(ListaAlumnos);
    this.props.eventClick('esc');
  }

  closeModal(mensaje) {
    this.props.eventClick(mensaje);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value, id, checked } = e.target;
    if(name == "AñoSeleccionado"){
      if((value == new Date().getFullYear()+5) ){
        this.setState({ colorNotificacion: 'danger', mensaje: "No puede inscribir con tanta anticipacion" });
        this.showNotification();
      }else {
        if((value < new Date().getFullYear()) ){
          this.setState({ colorNotificacion: 'danger', mensaje: "Favor se seleccionar una año valido" });
          this.showNotification();
        }else {
        this.setState({
          [name]: value,
        });
        }
      }
    }else if(name == "Meses"){
      var meses = this.state.Meses

      meses[id].Select = !meses[id].Select

      this.setState({
        Meses: meses,
      });
    }else{
    this.setState({
      [name]: value,
    });
    }
  }

  obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;
    //console.log((this.state.AñoSeleccionado).toString())

    let agregar;
    if(this.state.Cargando){
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.Cargando}
        />
      )
    }

    return (
      <div>
        <Modal isOpen={this.props.showInscripcion} className="static-modal" size="md" toggle={this.cerrarModal} backdrop={true}>
          <Card >
            <CardHeader color="info">
              Inscripción adelantada
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Select
                    value={this.state.idiomaSeleccionado}
                    onChange={this.handleChange}
                    name="idiomaSeleccionado"
                    fullWidth={true}
                    variant="outlined"
                    // label="Productos"
                    style={{ marginTop: "25px" }}
                  //error={this.state.errorUsuarios}
                  //success={!this.state.errorUsuarios}
                  >
                    <MenuItem value={"null"} disabled={true}>Selecionar idioma</MenuItem>
                    {this.state.ListaIdiomas.length == 0? null :
                    this.state.ListaIdiomas.map((idioma) =>{
                      return (<MenuItem value={idioma} >{idioma.Nombre}</MenuItem>)
                    })}
                  </Select>
                </GridItem>
              </GridContainer>
                <GridContainer xs={12} sm={12} md={12} lg={12}>
                  <GridItem style={{ margin: '0 auto' }} xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="Año de la inscripción"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.AñoSeleccionado,
                      name: "AñoSeleccionado",
                      id: "AñoSeleccionado",
                      type: "number",
                      error: this.state.errorNumFactura,
                      success: !this.state.errorNumFactura
                    }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={12} sm={12} md={12} lg={12}> 
                <GridItem xs={12} sm={12} md={12}>
                  <Button className="pull-right" color="info" round size="sm" onClick={() => this.ObtenerConceptosIdioma("Yei")}><Check /> Aceptar</Button>
                  <Button className="pull-right mr-2" round size="sm" onClick={() => this.closeModal("esc")}><Close /> Cerrar </Button>
                </GridItem>
                </GridContainer>
            </CardBody>
          </Card>
          
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        
        <div id="">
          {agregar}
        </div>
        
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalBuscarAlumnos);