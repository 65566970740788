import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus } from "@material-ui/icons";
import ReactLoading from "react-loading";
import NuevoPresupuesto from "./NuevoPresupuesto";
import PresupuestoExcel from "./PresupuestoExcel";
import Dashboard from "../../Dashboard/Dashboard";
import Cargando from '../../../components/Generales/ModalCargando';

class PresupuestosPrincipal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Nombre: '',
      Titulo: '',
      presupuestos: [],
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombre: false,
      errorTipo: false,
      entroFiltrar: false,
      notificacion: false,
      ObteniendoPresupuestos:true,
      VerListado: true,
      idPresupuesto:"",
      Opcion:0,
      DatosReporte:[],
      /**VER */
      VerReporte:false,
      MostrarModalCargando: false
    };   
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eliminarPresupuesto = this.eliminarPresupuesto.bind(this);
    this.EventoRegistro = this.EventoRegistro.bind(this);
    this.seleccionado = {};
  }

  componentDidMount() {
    this.obtenerAllpresupuestos();
  }

  //Obtener todos los registros guardados
  obtenerAllpresupuestos() {
    fetch(Globales.Url + 'Presupuestos/Presupuestos/ListadoPresupuesto', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }).then(res => {
      res.json().then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ presupuestos: [], ObteniendoPresupuestos:false });
        } else { //Obtuvo los registros correctamente
          this.setState({ presupuestos: data.data, ObteniendoPresupuestos: false });
        }
      });
    });
  }


  //Función de editar registro
  editarPresupuesto(id, Nombre, Tipo) {
    this.setState({ idPresupuesto: id, Nombre: Nombre, Opcion: 2, Titulo: "Editar Presupuesto",  VerListado: false });
  }

  consultarPresupuesto(idPresupuesto){
    this.setState({MostrarModalCargando:true})
    fetch(Globales.Url + 'Presupuestos/Presupuestos/ObtenerReportePresupuestoPorAno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idPresupuesto: idPresupuesto })
    }
    ).then(res => res.json())
      .then(data => {
        this.setState({MostrarModalCargando:false})
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
        } else {//Obtuvo los registros correctamente
          this.setState({DatosReporte:data},()=>{
            this.setState({
              VerReporte: true
            })
          })
        }
        this.setState({ colorNotificacion: 'success', mensaje: "Consulta exitosa, dar clic en la opción descargar", show: false });
        this.showNotification();
      
      });
  }

  //Función de eliminar registro
  eliminarPresupuesto(id) {
    fetch(Globales.Url + 'Presupuestos/Presupuestos/EliminarPresupuesto', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
        } else {//Obtuvo los registros correctamente
          this.setState({ colorNotificacion: 'success' });
        }
        this.setState({ mensaje: data.mensaje, show: false });
        this.showNotification();
        //this.limpiar();
        window.location.reload()
      });
  }

  //Función para el evento de eliminar
  deleteEvent = (values) => {
    this.seleccionado = values;
    this.setState({ show: true });
  }

  //Función para habilitar el modal de eliminar
  eventoModalEliminar(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }
    //Eliminar el elemento
    this.eliminarPresupuesto(this.seleccionado);
  }

  //Función para abrir el modal de agregar registro
  NuevoPresupuesto = () => {
    this.setState({
      VerListado: false,
      Titulo: "Nuevo Presupuesto",
      Opcion: 1,
      idPresupuesto:""
    });
  }

  EventoRegistro(mensaje) {
    this.setState({
      Opcion: 0,
      idPresupuesto: "",
    })

    if (mensaje !== "") {
      this.VerNotificacion(mensaje, "success");
    }
    this.obtenerAllpresupuestos();
    this.setState({
      VerListado: true
    })
  }

   //Función que habilita la notificación
   VerNotificacion(Mensaje, Color) {

    this.setState({
      VerNotificacion: true,
      MensajeNotificacion: Mensaje,
      ColorNotificacion: Color
    }, () => {
      this.alertTimeout = setTimeout(
        function () {
          this.setState({ VerNotificacion: false, notificacion: false });

        }.bind(this),
        4000
      );
    });


  }

  

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  eventoModalDescarga = (mensaje, colorNotificacion, listaMesesActualizada) => {
    this.setState({ VerReporte: false, DatosReporte: [] })
    
  }
   

  render() {

    const { classes } = this.props;
    var Componente= null;
    if(this.state.VerListado)
    {
      Componente= <div>
      <GridContainer justify="center">
        {/* <Dashboard /> */}
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="info" text>
              <h4 >Presupuestos</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  {/* <CustomInput
                    labelText="Buscar Presupuesto"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.Nombre,
                      name: "Nombre",
                      id: "Nombre",
                      type: "text",
                      error: this.state.errorNombre
                    }}
                    className="mt-5"
                  /> */}
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  {
                    this.state.VerReporte == true ?
                      <PresupuestoExcel
                      eventClick={this.eventoModalDescarga}
                        DatosReporte={this.state.DatosReporte}
                      />
                      : null
                  }
                </GridItem>
               
                <GridItem xs={12} sm={12} md={5}>
                  <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.NuevoPresupuesto()}><AddCircle />Agregar</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="info">
              <GridContainer>
                <GridItem xs={12} sm={12} md={7}>
                  <h4 className="pull-left">Lista de presupuestos</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {!this.state.ObteniendoPresupuestos ?
                <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12">
                    <thead>
                      <th className="center">Nombre</th>
                      <th className="center">Año</th>
                      <th className="pull-right">Acciones</th>
                    </thead>
                    <tbody>
                      {this.state.presupuestos.map(presupuesto => {
                        return (
                          <tr key={presupuesto._id}>
                            <td>{presupuesto.Nombre}</td>
                            <td>{presupuesto.Año}</td>
                            <td className="pull-right">
                            <Button color="success" round justIcon size="sm" onClick={() => this.consultarPresupuesto(presupuesto._id)}><Search /></Button>
                              <Button color="warning" round justIcon size="sm" onClick={() => this.editarPresupuesto(presupuesto._id, presupuesto.Nombre)}><Edit /></Button>
                              <Button color="danger" round justIcon size="sm" onClick={() => this.deleteEvent(presupuesto._id)}><Close /></Button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                :
                <GridContainer justify='center'>
                  <ReactLoading type={'bubbles'} color={'#CFCFCF'} height={'10%'} width={'10%'} />
                </GridContainer>
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <div id="">
        <ModalPregunta
          Titulo={"Eliminar Presupuesto"}
          Cuerpo={"¿Está seguro que desea eliminar el presupuesto?"}
          show={this.state.show}
          eventClick={this.eventoModalEliminar}
        />
      </div>
      <Cargando
                    show={this.state.MostrarModalCargando}
                    Cuerpo="Cargando"
                />
      <div id="">
        <Snackbar
          place="tr"
          color={this.state.colorNotificacion}
          message={this.state.mensaje}
          open={this.state.br}
          closeNotification={() => this.setState({ br: false })}
          close
        />
      </div>
    </div>
    }
    else{
      Componente = 
      <NuevoPresupuesto 
      EventoRegistro={this.EventoRegistro}
      Opcion={this.state.Opcion}
      idPresupuesto={this.state.idPresupuesto}
      />
    }
    
    
    return (
      <div>
      {Componente}
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(PresupuestosPrincipal);