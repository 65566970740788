import React from "react";
import { Modal } from "reactstrap";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { AsYouType } from "libphonenumber-js";

class ModalGuardarProveedor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      _idEstado: "",
      _idMunicipio: "",
      _idPais: "",
      RFC: "",
      RazonSocial: "",
      PersonaContacto: "",
      Correo: "",
      PaginaWeb: "",
      ListaEstados: [],
      ListaPaises: [],
      ListaMunicipios: [],
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorRFC: false,
      errorRazonSocial: false,
      errorRegimenFiscal: false,
      entroFiltrar: false,
      notificacion: false,
      Telefono: "",
      Telefono2: "",
      Calle: "",
      CodigoPostal: "",
      Colonia: "",
      DireccionCompleta: "",
      Estado: "",
      NumeroExterior: "",
      NumeroInterior: "",
      cargando: false,
      EsEditar: false,
      IdNormal: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    this.setState({
      cargando: true,
      entroEliminar: true,
    });
    await this.ListaGeneralPaises();

    if (this.props.op == 1) {
      const {
        _id,
        Rfc,
        Razonsocial,
        SitioWeb,
        Telefono,
        Calle,
        Codigopostal,
        Colonia,
        Estado,
        _idEstado,
        _idCuidad,
        _idPais,
        Contacto,
        Correo,
      } = this.props.InfoProveedor;

      //console.log(this.props.InfoProveedor)

      if (JSON.stringify(_idPais) !== JSON.stringify(this.state.IdNormal)) {
        await this.CargarDatos(_idPais, _idEstado);
      }
      this.setState({
        _id: _id || "",
        _idEstado: JSON.stringify(_idEstado) !== JSON.stringify(idVacio) ? JSON.stringify(_idEstado) : "",
        _idMunicipio: JSON.stringify(_idCuidad) !== JSON.stringify(idVacio) ? JSON.stringify(_idCuidad) : "",
        _idPais: JSON.stringify(_idPais) !== JSON.stringify(idVacio) ? JSON.stringify(_idPais) : "",
        RFC: Rfc || "",
        RazonSocial: Razonsocial || "",
        PersonaContacto: Contacto || "",
        PaginaWeb: SitioWeb || "",
        Telefono: Telefono || "",
        Calle: Calle || "",
        CodigoPostal: Codigopostal || "",
        Colonia: Colonia || "",
        Estado: Estado || "",
        Correo: Correo || "",
      });
    }
    this.setState({
      cargando: false,
    });
  }

  async CargarDatos(pais, estado) {
    await this.ListaGeneralEstados(JSON.stringify(pais));
    await this.ListaGeneralMunicipios(JSON.stringify(estado));
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  async ValidarProveedor() {
    /*  if (this.state.RFC.length < 12) {
      this.setState({
        colorNotificacion: "danger",
        errorRFC: true,
        mensaje:
          "El RFC es de captura obligatoria y no puede tener menos de 12 carácteres.",
        br: true,
      });
      return;
    } */
    if (this.state.RazonSocial.length < 10) {
      this.setState({
        colorNotificacion: "danger",
        errorRazonSocial: true,
        mensaje:
          "La razón social es de captura obligatoria, Verifique su información.",
      });
      this.showNotification();
      return;
    }
    /*  if (this.state.Telefono.length < 10) {
      this.setState({
        colorNotificacion: "danger",
        mensaje: "El telefóno es de captura obligatoria.",
        br: true,
      });
      return;
    }
    if (!this.state._idPais) {
      this.setState({
        colorNotificacion: "danger",
        mensaje: "Se debe seleccionar un país.",
        br: true,
      });
      return;
    }
    if (!this.state._idEstado) {
      this.setState({
        colorNotificacion: "danger",
        mensaje: "Se debe seleccionar un estado.",
        br: true,
      });
      return;
    }
    if (!this.state._idMunicipio) {
      this.setState({
        colorNotificacion: "danger",
        mensaje: "Se debe seleccionar un municipio.",
        br: true,
      });
      return;
    }
    if (!this.state.Colonia) {
      this.setState({
        colorNotificacion: "danger",
        mensaje: "Se debe capturar el nombre de la colonia.",
        br: true,
      });
      return;
    }
    if (this.state.CodigoPostal.length < 5) {
      this.setState({
        colorNotificacion: "danger",
        mensaje:
          "El código postal es de captura obligatoria, con minimo 5 carácteres.",
        br: true,
      });
      return;
    }
    if (!this.state.Calle) {
      this.setState({
        colorNotificacion: "danger",
        mensaje: "Se debe capturar el nombre de la calle.",
        br: true,
      });
      return;
    } */
    await this.RegistrarProveedor();
  }

  async ListaGeneralPaises() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Paises/ListaGeneralPaises", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          if (data.ListadePaises.Pais.length > 1) {
            this.setState({
              ListaPaises: data.ListadePaises.Pais,
              cargando: false,
              entroEliminar: false,
            });
          } else {
            let aux = [];
            aux.push(data.ListadePaises.Pais)
            this.setState({
              ListaPaises: aux,
              cargando: false,
              entroEliminar: false,
            });
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  RegresarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.eventClick(mensaje, "success");
  }

  async RegistrarProveedor() {
    this.setState({ cargando: true });
    await fetch(Globales.Url + "Catalogos/Proveedores/RegistrarProveedor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        Calle: this.state.Calle,
        Codigopostal: this.state.CodigoPostal,
        Colonia: this.state.Colonia,
        Contacto: this.state.PersonaContacto,
        Correo: this.state.Correo,
        Razonsocial: this.state.RazonSocial,
        Rfc: this.state.RFC,
        SitioWeb: this.state.PaginaWeb,
        Telefono: this.state.Telefono,
        _id: this.state._id,
        _idCuidad: this.state._idMunicipio
          ? JSON.parse(this.state._idMunicipio)
          : this.state.IdNormal,
        _idEstado: this.state._idEstado
          ? JSON.parse(this.state._idEstado)
          : this.state.IdNormal,
        _idPais: this.state._idPais
          ? JSON.parse(this.state._idPais)
          : this.state.IdNormal,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
          });
          this.showNotification();
        } else {
          //Filtrado exitoso
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "success",
            Activo: 1,
            cargando: false,
            Calle: "",
            CodigoPostal: "",
            Colonia: "",
            PersonaContacto: "",
            Correo: "",
            RazonSocial: "",
            RFC: "",
            PaginaWeb: "",
            Telefono: "",
            _id: "",
            _idMunicipio: "",
            _idEstado: "",
            _idPais: "",
            ListaEstados: [],
            ListaMunicipios: [],
            ListaPaises: [],
          });
          this.RegresarMensaje(data.mensaje);
        }
      })
      .catch((err) => console.error(err));
  }

  async ListaGeneralEstados(Pais) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Estados/ListaGeneralEstados", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        _idPais: JSON.parse(Pais),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          const TipoData = Array.isArray(data.ListadeEstados.Estado);
          const EstadosData = TipoData
            ? data.ListadeEstados.Estado
            : [{ ...data.ListadeEstados.Estado }];
          if (EstadosData.length > 0) {
            this.setState({
              ListaEstados: EstadosData,
              cargando: false,
            });
          } else {
            this.setState({
              ListaEstados: [],
              cargando: false,
              colorNotificacion: "danger",
              mensaje: "Aun no cuenta con estados registrados",
            });
            this.showNotification();
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  async ListaGeneralMunicipios(Estado) {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Municipios/ListaGeneralMunicipios", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        _idEstado: JSON.parse(Estado),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
            _idEstado: "",
          });
          this.showNotification();
        } else {
          const TipoData = Array.isArray(data.ListadeMunicipios.Municipio);
          const MunicipioData = TipoData
            ? data.ListadeMunicipios.Municipio
            : [{ ...data.ListadeMunicipios.Municipio }];
          if (MunicipioData.length > 0) {
            this.setState({
              ListaMunicipios: MunicipioData,
              cargando: false,
              entroEliminar: false,
            });
          } else {
            this.setState({
              ListaMunicipios: [],
              cargando: false,
              colorNotificacion: "danger",
              mensaje: "Aun no cuenta con municipios asignados",
            });
            this.showNotification();
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    if (name === "NombrePais") {
      this.setState({
        _idPais: value,
        _idMunicipio: "",
        _idEstado: "",
        ListaEstados: [],
        ListaMunicipios: [],
      });
      if (value) {
        this.ListaGeneralEstados(value);
      }
    } else if (name === "NombreEstado") {
      this.setState({
        _idEstado: value,
        ListaMunicipios: [],
      });
      if (value) {
        this.ListaGeneralMunicipios(value);
      }
    } else if (name === "CodigoPostal") {
      if (value.length > 5) {
        return;
      }
      this.setState({
        CodigoPostal: value,
      });
    } else if (name === "RFC") {
      if (value.length > 13) {
        return;
      }
      this.setState({
        RFC: value,
        errorRFC: false,
      });
    } else if (name === "RazonSocial") {
      if (value.length > 100) {
        return;
      }
      this.setState({
        RazonSocial: value,
        errorRazonSocial: false,
      });
    } else {
      this.setState({ [name]: value });
    }
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;
    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }
    return (
      <div>
        <Modal
          isOpen={this.props.showAgregar}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Información general</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="RFC"
                    style={{ width: "25em" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="RFC"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.RFC,
                      name: "RFC",
                      id: "RFC",
                      error: this.state.errorRFC,
                      success: !this.state.errorRFC ? "false" : "true",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="RazonSocial"
                    style={{ width: "25em" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Razon Social"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.RazonSocial,
                      name: "RazonSocial",
                      id: "RazonSocial",
                      error: this.state.errorRazonSocial,
                      success: !this.state.errorRazonSocial ? "false" : "true",
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Dirección y Contactos</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    className={classes.formControl}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel style={{ marginTop: "10px" }}>
                      País
                    </InputLabel>
                    <Select
                      value={this.state._idPais}
                      onChange={this.handleChange.bind(this)}
                      name="NombrePais"
                      label="País"
                      style={{ width: "21.9em", marginTop: "10px" }}
                    >
                      <MenuItem disabled>Seleccione un país</MenuItem>
                      {this.state.ListaPaises.map((Pais, Index) => (
                        <MenuItem
                          key={Index + Pais.NombrePais}
                          value={JSON.stringify(Pais._id)}
                        >
                          {Pais.NombrePais}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    className={classes.formControl}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel
                      style={{ marginTop: "10px" }}
                      id="Descubrirlabel"
                    >
                      Estado
                    </InputLabel>
                    <Select
                      labelId="Descubrirlabel"
                      fullWidth={true}
                      value={this.state._idEstado}
                      onChange={this.handleChange}
                      name="NombreEstado"
                      label="Estado"
                      style={{ width: "22em", marginTop: "10px" }}
                    >
                      <MenuItem disabled>Seleccione un estado</MenuItem>
                      {this.state.ListaEstados.map((Estado, Index) => (
                        <MenuItem
                          key={Index + Estado.NombreEstado}
                          value={JSON.stringify(Estado._id)}
                        >
                          {Estado.NombreEstado}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    className={classes.formControl}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel style={{ marginTop: "10px" }}>
                      Municipio
                    </InputLabel>
                    <Select
                      value={this.state._idMunicipio}
                      onChange={this.handleChange}
                      name="_idMunicipio"
                      style={{ width: "22em", marginTop: "10px" }}
                      labelId="Claselabel"
                      label="Municipio"
                    >
                      <MenuItem disabled>Seleccione un municipio</MenuItem>
                      {this.state.ListaMunicipios.map((Municipio, Index) => (
                        <MenuItem
                          key={Index + Municipio.NombreMunicipio}
                          value={JSON.stringify(Municipio._id)}
                        >
                          {Municipio.NombreMunicipio}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="Colonia"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Colonia"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Colonia,
                      name: "Colonia",
                      id: "Colonia",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="Calle"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Calle y número"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Calle,
                      name: "Calle",
                      id: "Calle",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="CodigoCP"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Código Postal"
                    onChange={this.handleChange}
                    value={this.state.CodigoPostal}
                    name={"CodigoPostal"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="Correo"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="email"
                    size="small"
                    label="Correo"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Correo,
                      name: "Correo",
                      id: "Correo",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="Telefono"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="tel"
                    size="small"
                    label="Teléfono"
                    inputProps={{ maxLength: 12 }}
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: new AsYouType("MX").input(this.state.Telefono),
                      name: "Telefono",
                      id: "Telefono",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="PersonaContacto"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Persona de contacto"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.PersonaContacto,
                      name: "PersonaContacto",
                      id: "PersonaContacto",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="PaginaWeb"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Sitio Web"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.PaginaWeb,
                      name: "PaginaWeb",
                      id: "PaginaWeb",
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <div>
          <Button
              size="sm"
              type="submit"
              color="info"
              round
              className="pull-right mx-2 mb-2"
              onClick={() => this.ValidarProveedor()}
            >
              Guardar
            </Button>
            <Button
              tabIndex="1"
              size="sm"
              className="pull-right mx-2 mb-2"
              round
              onClick={() => this.props.eventClick("esc")}
            >
              &nbsp;Cancelar
            </Button>
          </div>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(ModalGuardarProveedor);
