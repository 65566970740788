import React from "react";
import { Modal } from "reactstrap";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import Globales from "utils/Globales";
import { Close, VisibilityOff, Save, Visibility } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import cookie from 'js-cookie'

class ModalCrearUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      Nombre: "",
      ApellidoPaterno: "",
      ApellidoMaterno: "",
      Empresa: "",
      Usuario: "",
      UsuarioTemp: "",
      Contrasena: "",
      SubContrasena: "",
      Token: "",
      Tipo: "",
      IdNuevo: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      ArrModulos: [],
      ModulosSeleccionados: [],
      ListaModulos: [],
      ListaModulosOrg: [],
      ListaEmpresas: [],
      UsuarioSeleccionado: {},
      Disabled: false,
      showPassword: false,
      ShowSubPassword: false,
      ShowToken: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      CheckAll: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorNombre: false,
      errorApellidoPaterno: false,
      errorApellidoMaterno: false,
      errorPass: false,
      errorPass2: false,
      errorToken: false,
      errorUsuario: false,
      errorEmpresa: false,
      notificacion: false,
      Total: 0,
      TotalSeleccionado: 0,
    };
    global.entrar = true;
  }

  componentDidMount() {
    //console.log(this.props.UsuarioSeleccionado)
    this.ListaGeneralEmpresas();
    this.setState({
      ListaModulos: this.props.ListaModulos
    });
    if (this.props.op === 1) {
      this.setState({_id: this.props.UsuarioSeleccionado._id, UsuarioSeleccionado: this.props.UsuarioSeleccionado}, () => {
        this.ObtenerInformacionUsuario(this.props.UsuarioSeleccionado._id)
      });
    }
  }
  VerificarUsuarioExiste() {
    let valido = true;
    this.props.ListaUsuariosTemp.map(usuario => {
      if((this.state.Usuario.trim()).toLocaleLowerCase() == (usuario.Usuario).toLocaleLowerCase()){
        valido = false;
      }
    })

    return valido;
  }

  Validaciones() {
    if (this.state.Nombre === "") {
      this.setState({
        errorNombre: "true",
        mensaje: "El nombre es de captura obligatoria.",
        colorNotificacion: "danger",
      });
      this.showNotification();
      return;
    }
    if (
      this.state.ApellidoMaterno === "" &&
      this.state.ApellidoPaterno === ""
    ) {
      this.setState({
        errorApellidoPaterno: "true",
        errorApellidoMaterno: "true",
        mensaje: "Capturar al menos un apellido.",
        colorNotificacion: "danger",
      });
      this.showNotification();
      return;
    }

    /*  if (this.state.Empresa === "") {
      this.setState({
        errorEmpresa: "true",
        mensaje: "La empresa es de captura obligatoria.",
        colorNotificacion: "danger",
      });
      return;
    } */

    if (this.state.Usuario === "") {
      this.setState({
        errorUsuario: "true",
        mensaje: "El usuario es de captura obligatoria.",
        colorNotificacion: "danger",
      });
      this.showNotification();
      return;
    }

    if((this.state.Usuario.trim()) !== (this.state.UsuarioTemp.trim())){
      if(this.VerificarUsuarioExiste() === false){
        this.setState({
          errorUsuario: "true",
          mensaje: "El nombre de usuario ya existe",
          colorNotificacion: "danger",
        });
        this.showNotification();
        return;
      }
    }
      

    if (this.state.Contrasena === "") {
      this.setState({
        errorPass: "true",
        mensaje: "La contraseña es de captura obligatoria.",
        colorNotificacion: "danger",
      });
      this.showNotification();
      return;
    }

    if (this.state.SubContrasena === "") {
      this.setState({
        errorPass2: "true",
        mensaje: "Confirmar la contraseña.",
        colorNotificacion: "danger",
      });
      this.showNotification();
      return;
    }

    if (this.state.Contrasena !== this.state.SubContrasena) {
      this.setState({
        errorPass2: "true",
        mensaje: "Las contraseñas no coinciden",
        colorNotificacion: "danger",
      });
      this.showNotification();
      return;
    }

    if (this.state.ArrModulos.length === 0) {
      this.setState({
        errorPass2: "true",
        mensaje: "Asignar al menos un permiso.",
        colorNotificacion: "danger"
      });
      this.showNotification();
      return;
    }

    let Usuario = {
      ApellidoMaterno: this.state.ApellidoMaterno,
      ApellidoPaterno: this.state.ApellidoPaterno,
      Contraseña: this.state.Contrasena,
      ListaModulos: { Modulo: this.state.ArrModulos },
      Nombre: this.state.Nombre,
      Tipo: this.props.op == 1 ? this.state.UsuarioSeleccionado.Tipo : "Otro",
      Token: this.state.Token,
      Usuario: this.state.Usuario,
      _id: this.props.op == 1 ? this.state.UsuarioSeleccionado._id : this.state.IdNuevo,
      _idEmpresa: this.state.Empresa !== "" ? JSON.parse(this.state.Empresa) : this.state.IdNuevo,
    }

    //console.log("Entro a registrar")
    this.RegistrarUsuarios(Usuario);
  }

  async ObtenerInformacionUsuario(id) {
    this.setState({cargando: true,});
    await fetch(Globales.Url + "Catalogos/Usuarios/ObtenerInformacionUsuario", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({ _id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
      //console.log(data)
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            cargando: false,
          });
          this.showNotification();
        } else {
          const {
            ApellidoMaterno,
            ApellidoPaterno,
            Contraseña,
            ListaModulos,
            Nombre,
            Tipo,
            Token,
            Usuario,
            _idEmpresa,
          } = data.Listausuarios;

          let aux = [];
          if(ListaModulos.Modulo.length > 1){
            this.ActivarModulos(ListaModulos.Modulo);
          }
          else{
            aux.push(ListaModulos.Modulo);
            this.ActivarModulos(aux);
          }

          this.setState({

            ArrModulos: ListaModulos.Modulo.length > 1 ? ListaModulos.Modulo : aux,
            Nombre: Nombre,
            ApellidoPaterno: ApellidoPaterno,
            ApellidoMaterno: ApellidoMaterno,
            Empresa:
              JSON.stringify(_idEmpresa) === JSON.stringify(this.state.IdNuevo)
                ? ""
                : JSON.stringify(_idEmpresa),
            Usuario,
            UsuarioTemp: Usuario,
            Contrasena: Contraseña,
            SubContrasena: Contraseña,
            Token,
            Tipo,
            cargando: false,
          });
        }
      })
      .catch((err) => console.error(err));
  }

  ActivarModulos(DataModulos) {
    //console.log("DataModulos", DataModulos)

    if(DataModulos.length !== 0){
      DataModulos.map(data => {
        this.state.ListaModulos.map(mod => { //DataModulos.map(data
          if(JSON.stringify(data._id) == JSON.stringify(mod._id)){
            mod.Activo = true
          }
        })
      })

      this.setState({ListaModulos: this.state.ListaModulos})
    }
  }

  async ListaGeneralEmpresas() {
    await fetch(Globales.Url + "Catalogos/Empresas/ListarEmpresas", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          if (data.ListadeEmpresas.Empresa.length > 1) {
            this.setState({
              ListaEmpresas: data.ListadeEmpresas.Empresa,
              ListaEmpresasTemp: data.ListadeEmpresas.Empresa,
              Activo: 1,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          } else {
            this.setState({
              ListaEmpresas: data.ListadeEmpresas.Empresa,
              ListaEmpresasTemp: [...data.ListadeEmpresas.Empresa],
              Activo: data.ListadeEmpresas.Empresa.length,
              entroEliminar: false,
              FiltrarNombres: "",
            });
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  closeModal() {
    this.props.eventClick("esc");
    this.setState({
      _id: "",
      Nombre: "",
      ApellidoPaterno: "",
      ApellidoMaterno: "",
      Empresa: "",
      Usuario: "",
      Contrasena: "",
      SubContrasena: "",
      Token: "",
      Tipo: "",
      IdNuevo: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      ArrModulos: [],
      ListaModulos: [],
      ListaModulosOrg: [],
      ListaEmpresas: [],
      Disabled: false,
      showPassword: false,
      ShowSubPassword: false,
      ShowToken: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      CheckAll: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorNombre: false,
      errorApellidoPaterno: false,
      errorApellidoMaterno: false,
      errorPass: false,
      errorPass2: false,
      errorToken: false,
      errorUsuario: false,
      errorEmpresa: false,
      notificacion: false,
      Total: 0,
      TotalSeleccionado: 0,
    });
  }

  RegresarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.parentCallback(mensaje);
    this.closeModal();
  }

  async RegistrarUsuarios(Usuario) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + "Catalogos/Usuarios/RegistrarUsuario", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({Usuario: Usuario}),
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
          });
          this.showNotification();
        } else {
          if(cookie.get('id') == JSON.stringify(this.props.UsuarioSeleccionado._id)){
            localStorage.setItem('Permisos', JSON.stringify(this.state.ArrModulos));
            this.RegresarMensaje(data.mensaje);
            this.setState({
              mensaje: data.mensaje,
              colorNotificacion: "success",
              Activo: 1,
              cargando: false,
              FechaExamen: "",
              ArrFechaExamen: [],
              Nombre: "",
              ApellidoPaterno: "",
              ApellidoMaterno: "",
              Correo: "",
              NumTelefono: "",
              NumCelular: "",
              OpcionClase: "",
              Empresa: "",
              NumFamiliaresEstudiando: "",
              MedioConocioEscuela: "",
              OpcionNivelRes: "",
              ListaProspecto: {},
              ListaIdiomas: [],
              ListaNiveles: [],
              IDIdioma: "",
              NombreIdioma: "",
              ArrNombreIdioma: [],
              NombreNivel: "",
              ArrNombreNivel: [],
              ArrIdiomaSeleccionados: [],
              ArrEstudiosAnte: [],
              ArrModulos: [],
            });
            window.location.reload()
          }
          else{
            this.setState({
              mensaje: data.mensaje,
              colorNotificacion: "success",
              Activo: 1,
              cargando: false,
              FechaExamen: "",
              ArrFechaExamen: [],
              Nombre: "",
              ApellidoPaterno: "",
              ApellidoMaterno: "",
              Correo: "",
              NumTelefono: "",
              NumCelular: "",
              OpcionClase: "",
              Empresa: "",
              NumFamiliaresEstudiando: "",
              MedioConocioEscuela: "",
              OpcionNivelRes: "",
              ListaProspecto: {},
              ListaIdiomas: [],
              ListaNiveles: [],
              IDIdioma: "",
              NombreIdioma: "",
              ArrNombreIdioma: [],
              NombreNivel: "",
              ArrNombreNivel: [],
              ArrIdiomaSeleccionados: [],
              ArrEstudiosAnte: [],
              ArrModulos: [],
            });
            this.RegresarMensaje(data.mensaje);
          }
        }
      })
      .catch((err) => console.error(err));
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorNombre: false,
      errorApellidoPaterno: false,
      errorApellidoMaterno: false,
      errorEmpresa: false,
      errorUsuario: false,
      errorPass: false,
      errorPass2: false,
      errorPass2: false,
    });
  }

  handleClickShowToken(Opc) {
    if (Opc === 1) {
      this.setState({
        showPassword: !this.state.showPassword,
      });
    } else if (Opc === 2) {
      this.setState({
        ShowSubPassword: !this.state.ShowSubPassword,
      });
    } else {
      this.setState({
        ShowToken: !this.state.ShowToken,
      });
    }
  }

  handleMouseDownToken(event) {
    event.preventDefault();
  }

  handleModulo(e, item) {
    const {name, id, checked} = e.target;

    // console.log(e.target)

    if(checked){
      this.state.ListaModulos[id].Activo = true;

      this.setState({ArrModulos: [...this.state.ArrModulos, item], ListaModulos: this.state.ListaModulos});
    }
    else{
      this.state.ListaModulos[id].Activo = false;

      let index = this.state.ArrModulos.findIndex((elem) => JSON.stringify(elem._id) === JSON.stringify(item._id));
      //console.log(index)

      this.state.ArrModulos.splice(index, 1);

      this.setState({ArrModulos: this.state.ArrModulos, ListaModulos: this.state.ListaModulos});
    }
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;

    // console.log("ArrModulos", this.state.ArrModulos)
    //console.log("ListaModulos", this.state.ListaModulos)

    let agregar;

    if (this.state.cargando) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }

    let Modulos;
    if (this.state.ListaModulos.length > 0) {
      Modulos = (
        <div className="table-wrapper-scroll-y">
          <table className="col-xs-12 col-sm-12 col-md-12">
            <thead>
              <th> Nombre</th>
              <th />
            </thead>
            <tbody>
              {this.state.ListaModulos.map((Modul, index) => {
                return (
                  <tr
                    key={index}
                    id={index}
                  >
                    <td>{Modul.Nombre.toUpperCase()}</td>
                    <td className="pull-right">
                      {" "}
                      <Checkbox
                        id={index}
                        checked={Modul.Activo}
                        onChange={(e) => this.handleModulo(e, Modul)}
                        name="checkedInactivos"
                      />{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      Modulos = (
        <div className="my-3" style={{ textAlign: "center" }}>
          NO HAY MODULOS REGISTRADOS
        </div>
      );
    }
    return (
      <div>
        <Modal
          isOpen={this.props.showEditarUsuario}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Información del usuario</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="1"
                    style={{ width: "25em", marginLeft: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Nombre"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Nombre,
                      name: "Nombre",
                      id: "NombreUsuario",
                      error: this.state.errorNombre,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="2"
                    style={{ width: "25em" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Apellido paterno"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.ApellidoPaterno,
                      name: "ApellidoPaterno",
                      id: "ApellidoPaterno",
                      error: this.state.errorApellidoPaterno,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="ApellidoMaterno"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Apellido materno"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.ApellidoMaterno,
                      name: "ApellidoMaterno",
                      id: "ApellidoMaterno",
                      error: this.state.errorApellidoMaterno,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    size="small"
                    style={{ marginBottom: "27px" }}
                    error={this.state.errorEmpresa}
                  >
                    <InputLabel
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      Empresa
                    </InputLabel>
                    <Select
                      value={this.state.Empresa}
                      onChange={this.handleChange.bind(this)}
                      name="Empresa"
                      id="Empresa"
                      defaultValue={""}
                      variant="outlined"
                      label="Empresa"
                      style={{
                        marginTop: "10px",
                        width: "22em",
                      }}
                    >
                      <MenuItem value={JSON.stringify({ _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" })}>Sin empresa</MenuItem>
                      {this.state.ListaEmpresas.map((empresaa, Index) => (
                        <MenuItem
                          key={Index + empresaa.RazonSocial}
                          value={JSON.stringify(empresaa._id)}
                        >
                          {empresaa.RazonSocial}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} />
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Datos de acceso al sistema</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="1"
                    style={{
                      width: "25em",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Usuario"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Usuario,
                      name: "Usuario",
                      id: "Usuario",
                      error: this.state.errorUsuario,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} />
                <GridItem xs={12} sm={12} md={6} lg={6}>
                <TextField
                    style={{
                      width: "25em",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                    variant="outlined"
                    type={this.state.showPassword ? "text" : "password"}
                    size="small"
                    label="Contraseña"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowToken(1)}
                            onMouseDown={this.handleMouseDownToken}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      onChange: this.handleChange.bind(this),
                      value: this.state.Contrasena,
                      name: "Contrasena",
                      id: "Contrasena",
                      error: this.state.errorPass,
                      success: !this.state.errorPass
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                <TextField
                    style={{
                      width: "25em",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                    variant="outlined"
                    type={this.state.ShowSubPassword ? "text" : "password"}
                    size="small"
                    label="Confirmar contraseña"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowToken(2)}
                            onMouseDown={this.handleMouseDownToken}
                            edge="end"
                          >
                            {this.state.ShowSubPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      onChange: this.handleChange.bind(this),
                      value: this.state.SubContrasena,
                      name: "SubContrasena",
                      id: "SubContrasena",
                      error: this.state.errorPass2,
                      success: !this.state.errorPass2
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Contraseña de autorización</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                      style={{
                        width: "25em",
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                      variant="outlined"
                      type={this.state.ShowToken ? "text" : "password"}
                      size="small"
                      label="Token"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => this.handleClickShowToken()}
                              onMouseDown={this.handleMouseDownToken}
                              edge="end"
                            >
                              {this.state.ShowToken ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        onChange: this.handleChange.bind(this),
                        value: this.state.Token,
                        name: "Token",
                        id: "Token",
                      }}
                    />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Modulos</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {Modulos}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <div>
            <Button
              size="sm"
              type="submit"
              color="info"
              round
              className="pull-right mx-2"
              onClick={() => this.Validaciones()}
            >
              <Save />
              &nbsp;Guardar
            </Button>
            <Button
              tabIndex="1"
              size="sm"
              className="pull-right mx-2"
              round
              onClick={() => this.closeModal()}
            >
              <Close />
              &nbsp;Cancelar
            </Button>
          </div>
        </Modal>
        <div id="">
          {agregar}
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(ModalCrearUsuarios);
