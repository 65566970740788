import React, { useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

// material-ui icons
import Globales from "utils/Globales";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import "react-datepicker/dist/react-datepicker.css";
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';


class SeleccionarEmpresa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idIdioma: null,
            ListaIdiomas: [],
            ExtraAll:false,
            errorEmpresa: null
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
    }

    componentDidMount() {
        if(this.props.ExtraAll != undefined){
            this.setState({ExtraAll:this.props.ExtraAll})
        }
        this.obtenerIdiomas();
    }

    //Función para obtener las empresas
    obtenerIdiomas() {
        fetch(Globales.Url + 'Idiomas/Idiomas/ListaGeneralIdiomas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
        }
        ).then(res => res.json())
            .then(data => {
                if (data.error) { //Error en la respuesta
                    this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
                    this.showNotification();
                   } else { //Filtrado exitoso
                    //console.log('Filtrado exitoso',data.data);
                    var ListaIdiomas = []
                    data.data.map( idioma =>{
                      if(idioma.Consecutivo != "0" ){
                        ListaIdiomas.push(idioma)
                      }
                    })
                    this.setState({ ListaIdiomas:ListaIdiomas})
                  }
            })
            .catch(err => console.error(err));
    }
    
    idiomaSeleccionado = event => {
        // console.log("select;:",event.target.value._id)
        this.setState({
            // razonSocial: event.target.value.RazonSocial,
            // idEmpresa: event.target.value._id
            idIdioma: event.target.value
        });
        
        this.props.eventoClick(event.target.value)
    }

    render() {

        // var ListaEmpresas = [];
        // if (this.state.ListaEmpresas.length > 0) {
        //     ListaEmpresas = this.state.ListaEmpresas.map((d) => d.RazonSocial);
        // }

        const { classes } = this.props;
        return (
            <div>
                {/* <GridItem xs={12} sm={12} md={6} lg={6}> */}
                    <FormControl
                        fullWidth
                        style={{ width: "18.2vw" }}
                        variant="outlined"
                        size="small"
                        error={this.state.errorEmpresa}>
                        <InputLabel id="ILEmpresa">Idioma</InputLabel>
                        <Select
                            value={this.state.idIdioma}
                            onChange={this.idiomaSeleccionado}
                            name={this.state.razonSocial}
                            label={this.state.razonSocial}
                            size="small"
                            labelId="ILEmpresa"
                            id="idEmpresa"                            
                            defaultValue={"Seleccione"}
                            inputProps={{
                                required: true,
                                error: this.state.errorEmpresa,
                                success: !this.state.errorEmpresa
                            }}
                        >
                        {this.state.ExtraAll == false ?
                            <MenuItem value={"null"} disabled={true}>Selecionar idioma</MenuItem> 
                            : //else
                            <MenuItem value={"null"} >TODOS</MenuItem>
                        }
                        {this.state.ListaIdiomas.length == 0 ? null :
                            this.state.ListaIdiomas.map((idioma) => {
                                return (<MenuItem value={idioma._id} >{idioma.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                {/* </GridItem> */}
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(SeleccionarEmpresa);