import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Login from "./views/Login/Login";
import FirmaReglamento from "./views/FirmaReglamentos/Layout";

import MenuPrincipal from "./views/MenuPrincipal/MenuPrincipal";
import CambioContrasena from "./views/Login/CambiarContrasena";

// core components
import Admin from "layouts/Admin.jsx";
//import control from "layouts/Admin_IngEgr.jsx";
import cobranza from "layouts/Admin_Cobranza.jsx";
import prospectos from "layouts/Admin_Prospectos.jsx";
import alumnos from "layouts/Admin_Alumnos.jsx";
import Catalogos from "../src/layouts/Catalogos";
import presupuestos from "layouts/Admin_Presupuestos.jsx";
import Asesorias from "layouts/Asesorias.jsx";
import reportes from "layouts/Reportes.jsx";
import Calificaciones from "layouts/Califaciones.jsx";
import colaboradores from "layouts/Admin_Colaboradores.jsx";
import Grupos from "layouts/Grupos.jsx";
import Gastos from "layouts/Admin_Gastos.jsx";
import Inventario from "layouts/Admin_Inventario.jsx";
import Asistencia from "layouts/Admin_Asistencia.jsx";

import "assets/css/material-dashboard-react.css?v=1.6.0";
import "components/Generales/Styles/StyleBasics.css";
import CookiesU from "universal-cookie";
const cookies = new CookiesU();
// cookies.remove();

// const cookie = cookies.getAll();
//console.log(cookie);

class App extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    /* if (Object.keys(cookie).length === 0) {
    } */

    return (
      <HashRouter>
        <Switch>
          <Route path="/FirmaReglamento" component={FirmaReglamento} />
          <Route path="/admin" component={Admin} />
          <Route path="/cobranza" component={cobranza} />
          <Route path="/prospectos" component={prospectos} />
          <Route path="/alumnos" component={alumnos} />
          <Route path="/colaboradores" component={colaboradores} />
          <Route path="/presupuestos" component={presupuestos} />
          <Route path="/Catalogos" component={Catalogos} />
          <Route path="/Citas" component={Asesorias} />
          <Route path="/reportes" component={reportes} />
          <Route path="/Grupos" component={Grupos} />
          <Route path="/gastos" component={Gastos} />
          <Route path="/inventario" component={Inventario} />
          <Route path="/asistencia" component={Asistencia} />
          <Route path="/calificaciones" component={Calificaciones} />
          <Route path="/MenuPrincipal" name="Menu Principal" component={MenuPrincipal} />
          <Route path="/CambioContrasena" name="Cambiar Contraseña" component={CambioContrasena} />
          <Route path="/" name="Login" component={Login} />
          <Route path="/Login" name="Login" component={Login} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
