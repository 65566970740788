import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/HeaderLogin.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import image from "assets/img/FondoBlanco.jpg";
import Globales from "utils/Globales";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CookiesU from 'universal-cookie';

import ModalCargando from "../../components/Generales/ModalCargando";

const cookies = new CookiesU();
class LoginPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      Usuario: '',
      Contrasena: '',
      errorUsuario: '',
      errorContrasena: '',
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      cargando:false,
      NombreUsuario: '',
      NombreCompleto: ''
    }
    global.nombreUsuario = '';
    this.IniciarSesion = this.IniciarSesion.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.enterFunction = this.enterFunction.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.addEventListener("keypress", this.enterFunction, false);
    
    localStorage.removeItem('Permisos');
  }

  enterFunction(event) {
    if (event.keyCode === 13) {
      this.IniciarSesion();
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  IniciarSesion() {
    this.setState({cargando:true})
    //Validar que los campos no esten vacios
    if (this.state.Usuario !== '' && this.state.Contrasena !== '') {
      fetch(Globales.Url + 'IniciarSesion', {
        method: 'POST', headers: {
          'Accept': 'application/json', 'Content-Type': 'application/json'
        }, body: JSON.stringify(this.state)
      })
        .then(res => res).then(data => {
          if (data.status !== 404) {
            var a = data.json();
            a.then(r => {
              this.setState({ auth: r });
              let NombreCompleto = "";
              NombreCompleto = r.usuario.Nombre + " " + r.usuario.ApellidoPaterno + " " + r.usuario.ApellidoMaterno;
            
// console.log("🌌Permisos ",cookies.get("Permisos"))
                document.removeEventListener("keypress", this.enterFunction, false);
                cookies.set('Usuario', r.usuario.Usuario, { path: '/' });
                cookies.set('NombreUsuario', r.usuario.Nombre, { path: '/' });
                cookies.set('NombreUsuarioCompleto', NombreCompleto, { path: '/' });
                cookies.set('TipoUsuario', r.usuario.Tipo, { path: '/' });
                cookies.set('id', r.usuario._id, { path: '/' });
                cookies.set('token', r.usuario.Token, { path: '/' });
                cookies.set('Permisos', r.Modulos, { path: '/' });
                cookies.set('FechaServidor', r.FechaServidor, { path: '/' });
                Globales.token = 'Bearer ' + cookies.get('token');
                let dt = new Date();
                localStorage.setItem('Permisos', JSON.stringify(r.usuario.ListaModulos.Modulo));
                localStorage.setItem('Usuario', JSON.stringify(r.usuario));
                localStorage.setItem('expira', new Date(dt.getTime() + 20 * 60000));
                cookies.set('mensaje', 'Bienvenido ' + NombreCompleto, { path: '/' });
                window.location.href = '#/MenuPrincipal';
                window.location.reload()
              
            });
          } else {
            this.setState({cargando:false, colorNotificacion: 'danger', mensaje: 'Usuario y/o Contraseña incorrectos' });
            this.showNotification();
          }
        });
    } else {
      this.setState({cargando:false, errorUsuario: true, errorContrasena: true, colorNotificacion: 'danger', mensaje: 'Complete los campos para iniciar sesión' });
      this.showNotification();
    }
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  render() {
    const { classes } = this.props;
    var agregar 
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={"Cargando"}
          show={this.state.cargando}
        />
      );
    }

    return (
      <div>
        <Header
          absolute
          color="transparent"
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
             backgroundSize: "100% 100%",
             backgroundPosition: "center",
             backgroundRepeat:"no-repeat"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader
                      color="info"
                      signup
                      className={classes.cardHeader}
                    >
                      <h4>JUTTA TESCHE</h4>
                    </CardHeader>
                    <CardBody signup>
                      <CustomInput
                        labelText="Usuario"
                        id="first"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          onChange: this.handleChange.bind(this),
                          value: this.state.Usuario,
                          name: "Usuario",
                          id: "Usuario",
                          type: "text",
                          error: this.state.errorUsuario,
                          autoComplete: "off"
                        }}
                      />

                      <CustomInput
                        labelText="Contraseña"
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          onChange: this.handleChange.bind(this),
                          value: this.state.Contrasena,
                          name: "Contrasena",
                          id: "Contrasena",
                          type: "password",
                          error: this.state.errorContrasena
                        }}
                      />
                    </CardBody>
                  </form>
                  <div className={classes.textCenter}>
                    <Button type="submit" round color="default" onClick={this.IniciarSesion.bind(this)}>Iniciar Sesión</Button>
                  </div>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        {agregar}
      </div>
    );
  }
}

export default withStyles(loginPageStyle)(LoginPage);
