// core components/views for Admin layout

import Listado from "views/Modulos/Calificaciones/CalificacionesListado.jsx";

import MenuPrincipal from '../views/MenuPrincipal/MenuPrincipal';

// @material-ui/icons
import { CalendarToday, Grade, Grain, ScatterPlot, Timeline,EventNote, BarChart, Whatshot, MergeType, Home, Menu,WalletMembership,Contacts } from "@material-ui/icons";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [  
  {
    path: "/Calificaciones",
    name: "Calificaciones",
    icon: Grade,
    component: Listado,
    layout: "/calificaciones",
    idModulo:"9"
  },
]

let Permisos =localStorage.getItem("Permisos") == null ? {Modulo:[]}:JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

// console.log("🌌Permisos ", (Permisos).sort((a, b) => a.NumeroModulo - b.NumeroModulo))
var dashboardRoutesAccess = [];

for (var keyRoutes = 0; keyRoutes < dashboardRoutes.length; keyRoutes++) {
  if (Permisos.find(permiso => permiso.NumeroModulo == dashboardRoutes[keyRoutes].idModulo)) {
    dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
  }
}
export default dashboardRoutesAccess;