import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Edit, Close, Print, Search, Clear, Event, Add, Check } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
//Modales
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import ModalBuscarAlumnos from "../../../components/Generales/ModalBuscarAlumnos";
//modales del modulo
import ModalInfoCita from "./ModalInfoCita";
import ModalProgramarCita from "./ModalProgramarCita";

class Facturacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      Citas: [],
      Folios: [],
      FoliosCancelados: [],
      AlumnoSeleciondado: [],
      CitaSeleccionda: [],
      NombreAlumno: [],
      NombreEmpresa: "",
      FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaFin: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      idUsuario: null,
      //banderas
      cargando: false,
      showInfoCita:false,
      showProgramar:false,
      //notificación
      mensaje: "",
      colorNotificacion: 'success',
      //checks
      Agendada:true,
      Atendida:true,
      Cancelada:true,
      Inasistencia:true,
      CuerpoCargando: "Cargando",
      //variables modal
      op:0
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eventoClickAsignar = this.eventoClickAsignar.bind(this);
    
  }

  componentDidMount() {
    let IDcookie = JSON.parse(cookie.get('id'));
    this.setState({ idUsuario: IDcookie })

    var FechaInicio= new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    var FechaFin= new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)

    this.setState({
      FechaInicio: new Date(FechaInicio).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaFin: new Date(FechaFin).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    },() => {
      this.BuscarCitas()
    })

  }

  handleCallback = (AlumnoSeleciondado) => {
    this.setState({ AlumnoSeleciondado: AlumnoSeleciondado })
  }

  //Función para obtener los pagos pendientes de los alumnos
  BuscarCitas() {
    this.setState({ Citas: [],cargando: true })
    fetch(Globales.Url + 'Citas/Citas/BuscarCitas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        Inicio:this.state.FechaInicio,
        Fin: this.state.FechaFin
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: "No hay citas disponibles", colorNotificacion: "danger", Citas: [],  cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          // ColorEstado
          this.FiltrarCitas(data.Citas)
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  BuscarInformacionProspecto(_idAlumno) {
    fetch(Globales.Url + 'Prospectos/Prospectos/ObtenerInformacionProspecto', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        _id:_idAlumno,
      })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data.ProspectoDetalles.NombreCompleto);
        var citas = this.state.Citas

        let index = citas.findIndex( m => m._idAlumno == _idAlumno)
        if(index != -1){
          citas[index].NombreAlumno = data.ProspectoDetalles.NombreCompleto
        }
        // return (data.ProspectoDetalles.NombreCompleto);

        this.setState({
          Citas:citas
        })
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  VerificarProspectos(){
    var citas = this.state.Citas

    for(let i = 0; i < citas.length;i++){
      if(citas[i].NombreAlumno == null){
        this.BuscarInformacionProspecto(citas[i]._idAlumno)
      }
    }

  }

  FiltrarCitas(citas){
    var ListaCitas = []

    if (citas.length > 1) {
      citas.map( cita=>{
        if(cita.Estado == "Agendada" && this.state.Agendada == true){
          cita.ColorEstado = "warning"
          ListaCitas.push(cita)
        }
        if(cita.Estado == "Atendida" && this.state.Atendida == true){
          cita.ColorEstado = "success"
          ListaCitas.push(cita)
        }
        if(cita.Estado == "Cancelada" && this.state.Cancelada == true){
          cita.ColorEstado = ""
          ListaCitas.push(cita)
        }
        if(cita.Estado == "Inasistencia" && this.state.Inasistencia == true){
          cita.ColorEstado = "danger"
          ListaCitas.push(cita)
        }
      })
    }else{
      if(citas.Estado == "Agendada" && this.state.Agendada == true){
        citas.ColorEstado = "warning"
        ListaCitas.push(citas)
      }
      if(citas.Estado == "Atendida" && this.state.Atendida == true){
        citas.ColorEstado = "success"
        ListaCitas.push(citas)
      }
      if(citas.Estado == "Cancelada" && this.state.Cancelada == true){
        citas.ColorEstado = ""
        ListaCitas.push(citas)
      }
      if(citas.Estado == "Inasistencia" && this.state.Inasistencia == true){
        citas.ColorEstado = "danger"
        ListaCitas.push(citas)
      }
    }

    if( !(ListaCitas.length > 0)) {
      this.setState({
        mensaje: "No hay citas disponibles", 
        colorNotificacion: "danger",
        Citas: [],
        cargando: false 
      })
    }else{
      var CitasOrdenby =  ListaCitas.sort((a, b) => new Date(b.Fecha) - new Date(a.Fecha))
      this.setState({
        Citas: CitasOrdenby,
        cargando: false 
      },()=>{
        this.VerificarProspectos()
      })
    }


  }

  ObtenerNombreAlumno(IdAlumno) {
    this.setState({ cargando: true })
    fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerNombreAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: IdAlumno })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          //console.log("NO encontro Folio")
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", NombreAlumno: "", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso

          //console.log(data.NombreAlumno)
          let Folios = this.state.Folios
          Folios.map(folio => {
            if (JSON.stringify(folio._idAlumno) == JSON.stringify(IdAlumno)) {
              folio.NombreAlumno = data.NombreAlumno;
            }
          })

          this.setState({ Folios: Folios, cargando: false })
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  ExportarPDF() {

    if (this.state.Citas.length == 0) {
      this.setState({ mensaje: "Genere una consulta antes de continuar", colorNotificacion: "danger", });
      this.showNotification();
    }else{
      var estados= {EstadoAsesoria:[]}
      if(this.state.Agendada == true){
        estados.EstadoAsesoria.push("Agendada")
      }
      if(this.state.Atendida == true){
        estados.EstadoAsesoria.push("Atendida")
      }
      if(this.state.Cancelada == true){
        estados.EstadoAsesoria.push("Cancelada")
      }
      if(this.state.Inasistencia == true){
        estados.EstadoAsesoria.push("Inasistencia")
      }
      // estados = {
      //   EstadoAsesoria: [
      //     "Agendada",
      //     // "Atendida",
      //     // "Cancelada",
      //     // "Inasistencia",
      //   ]
      // }
      //console.log("Estados", estados);
      this.setState({ cargando: true })
      fetch(Globales.Url + 'Citas/Citas/ReporteAsesoriasRangoFechas' , {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({
          Inicio: this.state.FechaInicio,
          Fin: this.state.FechaFin,
          Estados: estados
        })
      }
      ).then(res => res.json())
        .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
            this.showNotification();
          } else { //Filtrado exitoso
            //console.log(data.Folios)
            this.DescargarReporte(data.PDF)
          }
        })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }
  }

  DescargarReporte(archivoBase64) {
    const url = 'data:application/pdf;base64,' + archivoBase64;

    let nombre = 'Reporte de citas'
    const linkSource = `${url}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    this.setState({
      cargando: false,
      mensaje: "Reporte generado exitosamente",
      colorNotificacion: "success",
    });
    this.showNotification();
  }

  openModalBuscarAlumnos = () => {
    this.setState({ showAgregar: true, Titulo: "Buscar Alumnos", op: 1 });
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, } = e.target;

    this.setState({ 
      [name]: value,
    });
  }
  handleChangeCheck(e) {
    const { name,checked } = e.target;

    this.setState({ 
      [name]: checked,
    });
  }

  OpenModalInfoCita(cita){
    this.setState({ 
      CitaSeleccionda:cita,
      showInfoCita:true,
    });
  }
  CerrarModalInfoCita = (mensaje) =>{
    this.setState({ 
      showInfoCita:false,
      showProgramar:false,
      mensaje: mensaje,
      colorNotificacion: "success",
    });
    if(mensaje != "esc" && mensaje != "Editar"){
      this.showNotification();
      this.BuscarCitas()
    }
    if(mensaje == "Editar"){
      this.EditarCita()
    }
  }
  eventoClickAsignar = (mensaje) => {
    // console.log("MENSAJE:",mensaje)
    this.setState({ 
      showProgramar:false,
      mensaje: mensaje,
      colorNotificacion: "success",
    });
    if(mensaje != "esc"){
      this.showNotification();
      this.BuscarCitas()
    }
  }
  EditarCita(){
    this.setState({
      op:1,
      // CitaSeleccionda:cita,
      showProgramar:true,
    })
  }

  render() {

    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <ModalBuscarAlumnos
          Titulo={this.state.Titulo}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalcobranza}
          op={this.state.op}
          ocultar={true}
          parentCallback={this.handleCallback}
        />
    }

    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }
    
    if (this.state.showInfoCita == true) {
      agregar = (
        <ModalInfoCita
          eventClick={this.CerrarModalInfoCita}
          cita={this.state.CitaSeleccionda}
          show={this.state.showInfoCita}
        />
      );
    }
    
    if (this.state.showProgramar == true) {
      agregar = (
        <ModalProgramarCita
          eventClick={this.eventoClickAsignar}
          cita={this.state.CitaSeleccionda}
          op={this.state.op}
          show={this.state.showProgramar}
        />
      );
    }
    
    
    const { classes } = this.props;

    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    return (
      <div >
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Parámetros de consulta</h4>
              </CardHeader>
              <CardBody>
                <GridContainer >
                  <GridItem id="Empresa de búsqueda" xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                    <TextField
                      id="FechaInicio"
                      style={{ width: "18em" }}
                      variant="outlined"
                      type="date"
                      format="DD-MM-YYYY"
                      size="small"
                      label="Fecha de inicio"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.FechaInicio,
                        name: "FechaInicio",
                        id: "FechaInicio",
                        error: this.state.errorFechainicio,
                        success: !this.state.errorFechainicio
                      }}
                    />
                    <TextField
                      className="ml-2"
                      id="FechaFin"
                      style={{ width: "18em" }}
                      variant="outlined"
                      size="small"
                      type="date"
                      format="DD-MM-YYYY"
                      label="Fecha de terminación"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.FechaFin,
                        name: "FechaFin",
                        id: "FechaFin",
                        error: this.state.errorFechaFin,
                        success: !this.state.errorFechaFin
                      }}
                    />
                    
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} style={{ marginTop: 10 }}>
                    <Button color="warning" style={{ height: "10px",width: "13px"}} round justIcon size="sm"/>
                    {" Agendada "}
                    <Checkbox
                      onChange={this.handleChangeCheck}
                      checked={this.state.Agendada}
                      name="Agendada"
                    />
                    <Button color="success" style={{ height: "10px",width: "13px"}} round justIcon size="sm"/>
                    {" Atendida "}
                    <Checkbox
                      onChange={this.handleChangeCheck}
                      checked={this.state.Atendida}
                      name="Atendida"
                    />
                    <Button color="" style={{ height: "10px",width: "13px"}} round justIcon size="sm"/>
                    {" Cancelada "}
                    <Checkbox
                      onChange={this.handleChangeCheck}
                      checked={this.state.Cancelada}
                      name="Cancelada"
                    />
                    <Button color="danger" style={{ height: "10px",width: "13px"}} round justIcon size="sm"/>
                    {" Inasistencia "}
                    <Checkbox
                      onChange={this.handleChangeCheck}
                      checked={this.state.Inasistencia}
                      name="Inasistencia"
                    />

                    <Button size="sm" className="pull-right" type="submit" color="info" round onClick={() => this.BuscarCitas()}><Search />Buscar</Button>
                    
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2} style={{ marginTop: 10 }}>
                    <Button size="sm" className="pull-right" type="submit" color="info" round onClick={() => this.ExportarPDF()}><Print />Imprimir </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2} style={{ marginTop: 10 }}>
                    <Button size="sm" className="pull-right" type="submit" color="info" round onClick={() => this.setState({ op:0,showProgramar: true})}><Event />Programar cita</Button>
                  </GridItem>

                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Listado de citas</h4>
              </CardHeader>
              <CardBody xs={12} sm={12} md={12}>
                <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                  <thead>
                    <tr>
                      <th style={{ width: "5%"}}></th>
                      <th className={classes.label}>Fecha</th>
                      <th className={classes.label}>Hora</th>
                      <th className={classes.label}>Maestro</th>
                      <th className={classes.label}>Alumno</th>
                    </tr>
                  </thead>
                  <tbody >
                    {this.state.Citas.length < 1 ?
                      <tr><td colSpan="5"><h4 className="text-center">No hay citas agendadas</h4></td></tr>
                      : this.state.Citas.map(cita => {
                        return (
                          <tr style={{ cursor: "pointer"}} hover>
                            <td onClick={() => this.OpenModalInfoCita(cita)}>
                              <Button color={cita.ColorEstado} style={{ height: "10px", width: "13px" }} round justIcon size="sm" />
                            </td>
                            <td onClick={() => this.OpenModalInfoCita(cita)}>{new Date(cita.Fecha).toLocaleDateString('en-GB')}</td>
                            <td onClick={() => this.OpenModalInfoCita(cita)}>{new Date(cita.Fecha).toLocaleTimeString('en-US')}</td>
                            <td onClick={() => this.OpenModalInfoCita(cita)}>{cita.NombreMaestro}</td>
                            <td onClick={() => this.OpenModalInfoCita(cita)}>{cita.NombreAlumno}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar cobranza"}
            Cuerpo={"¿Está seguro que desea eliminar el cobranza?"}
            show={this.state.show}
          //eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Facturacion);