// core components/views for Admin layout
//import Egresos from "views/Modulos/IngEgr/Egresos/Egresos.jsx";
//import Ingresos from "views/Modulos/IngEgr/Ingresos/Ingresos.jsx";
import Cobranza from "views/Modulos/Cobranza/Cobranza.jsx";
import CorteCaja from "views/Modulos/CorteCaja/CorteCaja.jsx";

// import Facturacion from "views/Modulos/Cobranza/Facturacion/Facturacion.jsx";
import Facturacion from "views/Modulos/Cobranza/Facturacion/FacturacionListado.jsx";

import Acreditaciones from "views/Modulos/Cobranza/Acreditaciones/Acreditaciones.jsx";
import Cancelaciones from "views/Modulos/Cobranza/Cancelaciones/Listado.jsx";
import Bitacora from "views/Modulos/Cobranza/BitacoraCobranza/LIstadoBitacora.jsx";


/* import ExportacionEgresos from "views/Modulos/Administracion/Exportacion/Egresos/Egresos.jsx";
import ExportacionIngresos from "views/Modulos/Administracion/Exportacion/Ingresos/Ingresos.jsx"; */
import MenuPrincipal from '../views/MenuPrincipal/MenuPrincipal';
import DashboardPage from "views/Dashboard/Dashboard.jsx";

// @material-ui/icons
import { Close, AttachFile, AccountBalance, AttachMoney, Timeline, Block, LocalOffer, LinearScale, BarChart, Whatshot, MergeType, Home, Menu, WalletMembership, Contacts, CreditCard } from "@material-ui/icons";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [
  {
    path: "/Ventas",
    name: "Cobros",
    icon: LocalOffer,
    component: Cobranza,
    layout: "/cobranza",
    idModulo: "6"
  },
  {
    path: "/corteCaja",
    name: "Pagos",
    icon: AccountBalance,
    component: CorteCaja,
    layout: "/cobranza",
    idModulo: "51" 
  },
  {
    path: "/Bitacora",
    name: "Bitácora de cobranza",
    icon: Home,
    component: Bitacora,
    layout: "/cobranza",
    idModulo:"6"
  },
  {
    path: "/facturacion",
    name: "Facturación",
    icon: AttachMoney,
    component: Facturacion,
    layout: "/cobranza",
    idModulo: "6"
  },
  {
    path: "/Cancelaciones",
    name: "Cancelaciones",
    icon: Block,
    component: Cancelaciones,
    layout: "/cobranza",
    idModulo:"53"
  },
  {
    path: "/acreditaciones",
    name: "Acreditaciones",
    icon: CreditCard,
    component: Acreditaciones,
    layout: "/cobranza",
    idModulo:"55"
  },
]


let Permisos =localStorage.getItem("Permisos") == null ? {Modulo:[]}:JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

// console.log("🌌Permisos ", (Permisos).sort((a, b) => a.NumeroModulo - b.NumeroModulo))
var dashboardRoutesAccess = [];

for (var keyRoutes = 0; keyRoutes < dashboardRoutes.length; keyRoutes++) {
  if (Permisos.find(permiso => permiso.NumeroModulo == dashboardRoutes[keyRoutes].idModulo.trim())) {
    dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
  }
}

export default dashboardRoutesAccess;