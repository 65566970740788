import React, { useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

// material-ui icons
import Globales from "utils/Globales";
import Dropdown from 'react-dropdown';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";


class SeleccionarAlumno extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AlumnoSeleciondado: {},
            ListaAlumnos: [],
            errorEmpresa: null,
            NombreAlumno: "",
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
    }

    componentDidMount() {

    }

    //Función para obtener las empresas
    ListaAlumnosBusquedaFiltrada(texto) {
        fetch(Globales.Url + 'Alumnos/Alumnos/ListaAlumnosBusquedaFiltrada', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ Nombre: texto, isInactivo: this.props.inactivo })
        }
        ).then(res => res.json())
            .then(data => {
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje });
                } else { //Filtrado exitoso

                  // console.log("API", data.ListaAlumnos)
                    if (data.ListaAlumnos.length > 0) {
                      this.setState({ListaAlumnos: data.ListaAlumnos});
                    }
                    else{
                      let aux = [];
                      aux.push(data.ListaAlumnos);
                      this.setState({ListaAlumnos: aux});
                    }
                }
            })
            .catch(err => console.error(err));
    }

    handleChangeAutocomplete(e, newValue){

        // console.log("Autocomplete seleccionado", typeof newValue != 'string')
    
        if(typeof newValue != 'string'){
          this.setState({AlumnoSeleciondado: newValue})
          this.props.eventClick(newValue)
        }
      }

      InputChange(e, newInputValue){
        //const { name, value2 } = e.target;

        if(newInputValue.length > 0){
          this.ListaAlumnosBusquedaFiltrada(newInputValue);
        }

        this.setState({NombreAlumno: newInputValue});
      }

      handleMouseDownToken(event) {
        event.preventDefault();
      }
    
      handleClickEliminarTexto(){
        this.setState({NombreAlumno: "", AlumnoSeleciondado: {}});
        this.props.eventClick([])
      }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Autocomplete
                    freeSolo
                    id="NombreAlumno"
                    noOptionsText={'No hay opciones'}
                    fullWidth={this.props.fullWidth}
                    style={{ maxWidth: this.props.fullWidth ? "100%" : "18.2vw" }}
                    disableClearable
                    value={this.state.AlumnoSeleciondado}
                    inputValue={this.state.NombreAlumno}
                    onChange={this.handleChangeAutocomplete.bind(this)}
                    onInputChange={this.InputChange.bind(this)}
                    options={this.state.ListaAlumnos}
                    getOptionLabel={(option) => option.NumeroCuenta_NombreCompleto}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth={this.props.fullWidth}
                        label="Nombre del alumno"
                        margin="normal"
                        size="small"
                        variant="outlined"
                        InputProps={{ 
                          ...params.InputProps, 
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => this.handleClickEliminarTexto()}
                                onMouseDown={this.handleMouseDownToken}
                                edge="end"
                              >
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          ),
                          type: 'search',
                          name: "NombreAlumno",
                          id: "NombreAlumno",
                          //error: this.state.errorNombre,
                          //success: !this.state.errorNombre
                        }}
                      />
                    )}
                  />
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(SeleccionarAlumno);