// core components/views for Admin layout
import Reglamento from "views/Modulos/Administracion/Reglamento/Reglamento.jsx";
import Configuracion from 'views/Modulos/Administracion/Configuraciones/Configuracion.jsx';
import CatTipoReglamento from "../views/Modulos/Catalogos/TipoReglamento/TipoReglamento";


import MenuPrincipal from '../views/MenuPrincipal/MenuPrincipal';

// @material-ui/icons
import { Streetview, Grain, Edit, Timeline,LinearScale, BarChart, Build, MergeType, Home, Menu,WalletMembership,Contacts } from "@material-ui/icons";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [  
  // {
  //   path: "/Reglamento",
  //   name: "Reglamentos",
  //   icon: Edit,
  //   component: Reglamento,
  //   layout: "/admin",
  //   idModulo:"11"
  // },
  {
    path: "/Configuracion",
    name: "Configuración",
    icon: Build,
    component: Configuracion,
    layout: "/admin",
    idModulo:"11"
  },
  // {
  //   path: "/Reglamento",
  //   name: "Reglamentos",
  //   icon: Edit,
  //   component: CatTipoReglamento,
  //   layout: "/admin",
  //   idModulo:"11"
  // },
]

let Permisos =localStorage.getItem("Permisos") == null ? {Modulo:[]}:JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

// console.log("🌌Permisos ", (Permisos).sort((a, b) => a.NumeroModulo - b.NumeroModulo))

var dashboardRoutesAccess = [];

for (var keyRoutes = 0; keyRoutes < dashboardRoutes.length; keyRoutes++) {
  if (Permisos.find(permiso => permiso.NumeroModulo == dashboardRoutes[keyRoutes].idModulo)) {
    dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
  }
}


export default dashboardRoutesAccess;