import React from "react";
import { Form, Modal } from "reactstrap";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalInfoVenta from "../Cobranza/Facturacion/ModalInfoVenta.jsx";
import ModalCargando from "../../../components/Generales/ModalCargando";
import ModalEstudiosAnte from "./ModalEstudiosAnte.jsx"
import ModalSeguimiento from "./ModalSeguimiento.jsx"
import ModalSeguimientoInfo from "./ModalSeguimientoInfo.jsx"
import Globales from "utils/Globales";
import { Edit, Close, InfoTwoTone, AddCircle, Delete, Clear, Save, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
import { AsYouType } from 'libphonenumber-js'

class ModalCrearProspectos extends React.Component {
    constructor(props) {
        super(props);
        const inputs = [1, 2, 3, 4, 5];
        this.state = {
            _id: '',
            inputs: inputs,
            originalInputsLength: inputs.length,
            FechaExamen: "",
            ArrFechaExamen: [],
            Nombre: "",
            ApellidoPaterno: "",
            ApellidoMaterno: "",
            Correo: "",
            NumTelefono: "",
            NumCelular: "",
            OpcionClase: "",
            Empresa: "",
            NumFamiliaresEstudiando: '',
            MedioConocioEscuela: "",
            OpcionNivelRes:  { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
            ListaProspecto: {},
            ListaIdiomas: [],
            ListaNiveles: [[]],
            IDIdioma: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
            NombreIdioma: "",
            ArrIdiomaSeleccionados: [{
                FechaAplicacionExamen: "",
                _idNivelResultado: "",
                _ididioma: ""
            }],
            NombreNivel: "",
            ArrNombreNivel: [],
            ArrEstudiosAnte: [],
            ArrSeguimiento: [],
            ProspectoDetalles: {},
            ProspectoEmpresa: {},
            ProspectoIdioma: [],
            ProspectoIdiomaEstudios: [],
            Disabled: false,
            mensaje: "",
            br: false,
            show: false,
            cargando: false,
            CheckAll: false,
            colorNotificacion: 'success',
            colorTableRow: "white",
            errorIDIdioma: false,
            errorFechaExamen: false,
            errorOpcionNivelRes: false,
            errorNombre: false,
            errorApellidoPaterno: false,
            errorCorreo: false,
            entroFiltrar: false,
            notificacion: false,
            Total: 0,
            TotalSeleccionado: 0,
            CuerpoCargando: "Cargando",
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        global.entrar = true;
        this.handleChangeIdioma = this.handleChangeIdioma.bind(this);
        //this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.ListaGeneralIdiomas();

        if(this.props.op == 1){
            this.setState({
                _id: this.props._id
            }, () => this.ObtenerInformacionProspecto(this.props._id))
        }
    }

/************************************************************ INICIO EDICIÓN PROSPECTO **********************************************************************************/

    async ObtenerInformacionProspecto(id) {
        this.setState({cargando: true, ProspectoIdioma: [], ProspectoIdiomaEstudios: []});
        await fetch(Globales.Url + 'Prospectos/Prospectos/ObtenerInformacionProspecto', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({_id: id})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

        /********* IDIOMAS DE INTERES ***********/
                    if(data.ProspectoDetalles.Idiomas !== null){
                        let aux = [];
                        let aux2 = [];
                        if(data.ProspectoDetalles.Idiomas.IdiomaProspecto.length > 1){
                            data.ProspectoDetalles.Idiomas.IdiomaProspecto.map((idioma, index) => {
                                delete idioma.Nivel;

                                if(JSON.stringify(idioma._idNivelResultado) === JSON.stringify(idVacio)){
                                    idioma._idNivelResultado = "";
                                }

                                if(moment(idioma.FechaAplicacionExamen).format('YYYY-MM-DD') == "0001-01-01"){
                                    idioma.FechaAplicacionExamen = "";
                                }
                                else if(moment(idioma.FechaAplicacionExamen).format('YYYY-MM-DD') == "0000-12-31"){
                                    idioma.FechaAplicacionExamen = "";
                                }
                                else{
                                    idioma.FechaAplicacionExamen = moment(idioma.FechaAplicacionExamen).format('YYYY-MM-DD');
                                }

                                aux.push(idioma)
                                aux2.push([]) // Inicializar ListaNiveles con los arrays vacios necesarios para cada idioma registrado y evita que se trabe el sistema, al final se llenan los arrays en el metodo ListaNivelesIdioma
                            })
                        }
                        else if(Object.keys(data.ProspectoDetalles.Idiomas.IdiomaProspecto).length !== 0){
                            delete data.ProspectoDetalles.Idiomas.IdiomaProspecto.Nivel;

                            if(JSON.stringify(data.ProspectoDetalles.Idiomas.IdiomaProspecto._idNivelResultado) === JSON.stringify(idVacio)){
                                data.ProspectoDetalles.Idiomas.IdiomaProspecto._idNivelResultado = "";
                            }

                            if(moment(data.ProspectoDetalles.Idiomas.IdiomaProspecto.FechaAplicacionExamen).format('YYYY-MM-DD') == "0001-01-01"){
                                data.ProspectoDetalles.Idiomas.IdiomaProspecto.FechaAplicacionExamen = "";
                            }
                            else if(moment(data.ProspectoDetalles.Idiomas.IdiomaProspecto.FechaAplicacionExamen).format('YYYY-MM-DD') == "0000-12-31"){
                                data.ProspectoDetalles.Idiomas.IdiomaProspecto.FechaAplicacionExamen = "";
                            }
                            else{
                                data.ProspectoDetalles.Idiomas.IdiomaProspecto.FechaAplicacionExamen = moment(data.ProspectoDetalles.Idiomas.IdiomaProspecto.FechaAplicacionExamen).format('YYYY-MM-DD');
                            }
                            
                            aux.push(data.ProspectoDetalles.Idiomas.IdiomaProspecto);
                            aux2.push([]);
                        }

                        this.setState({ArrIdiomaSeleccionados: aux, ListaNiveles: aux2}, () => {
                            this.state.ArrIdiomaSeleccionados.map((idioma, index) => {
                                this.ListaNivelesIdioma(idioma._ididioma, index);
                            })
                        })
                    }

        /*********ESTUDIOS ANTERIORES***********/
                    if(data.ProspectoDetalles.EstudiosAnteriores !== null){
                        if(data.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto.length > 1){
                            data.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto.map(estudio => {
                                this.ObtenerInformacionIdiomaEstudiosAnte(estudio._ididioma);
                            })
                        }
                        else if(Object.keys(data.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto).length !== 0){
                            this.ObtenerInformacionIdiomaEstudiosAnte(data.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto._ididioma);
                        }
                    }
                    this.setState({ ProspectoDetalles: data.ProspectoDetalles, Activo: 1, cargando: false }, () => this.ObtenerInformacionDatoFiscal(data.ProspectoDetalles._idInstitucion))
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async ObtenerInformacionDatoFiscal(id) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Prospectos/Prospectos/ObtenerInformacionDatoFiscal', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({_id: id})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    //console.log(data.ProspectoEmpresa)
                    this.setState({ ProspectoEmpresa: data.ProspectoEmpresa, Activo: 1, cargando: false }, () => this.AsignarEdicionInfo());
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async ObtenerInformacionIdiomaEstudiosAnte(id) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Prospectos/Prospectos/ObtenerInformacionIdiomaEstudiosAnte', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({_id: id})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    this.setState({ ProspectoIdiomaEstudios: [...this.state.ProspectoIdiomaEstudios, data.ProspectoIdiomaEstudios], Activo: 1, cargando: false });
                    this.AsignarEdicionEstudiosAntes();
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    AsignarEdicionInfo(){
        this.setState({
            Nombre: this.state.ProspectoDetalles.Nombre,
            ApellidoPaterno: this.state.ProspectoDetalles.ApellidoPaterno,
            ApellidoMaterno: this.state.ProspectoDetalles.ApellidoMaterno !== null && this.state.ProspectoDetalles.ApellidoMaterno !== undefined ? this.state.ProspectoDetalles.ApellidoMaterno : "",
            Correo: this.state.ProspectoDetalles.Correo !== null && this.state.ProspectoDetalles.Correo !== undefined ? this.state.ProspectoDetalles.Correo : "",
            NumTelefono: this.state.ProspectoDetalles.Telefono !== null && this.state.ProspectoDetalles.Telefono !== undefined ? this.state.ProspectoDetalles.Telefono : "",
            NumCelular: this.state.ProspectoDetalles.Celular !== null && this.state.ProspectoDetalles.Celular !== undefined ? this.state.ProspectoDetalles.Celular : "",
            OpcionClase: this.state.ProspectoDetalles.Clase,
            Empresa: this.state.ProspectoEmpresa.RazonSocial !== null && this.state.ProspectoEmpresa.RazonSocial !== undefined ? this.state.ProspectoEmpresa.RazonSocial : "",
            NumFamiliaresEstudiando: this.state.ProspectoDetalles.NumeroFamiliaresEstudiando,
            MedioConocioEscuela: this.state.ProspectoDetalles.MedioConocioEscuela,
        }, () => this.AsignarEdicionSeguimiento())
    }

    AsignarEdicionEstudiosAntes(){
        let aux = {};
        let aux2 = [];
        if(this.state.ProspectoDetalles.EstudiosAnteriores !== null){
            if(this.state.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto.length > 0){
                this.state.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto.map((estudio, index) => {
                    aux = {
                        Duracion: estudio.Duracion,
                        Lugar: estudio.Lugar,
                        Nombreidioma: this.state.ProspectoIdiomaEstudios[index].Nombre,
                        TiempoTranscurrido: estudio.TiempoTranscurrido,
                        _ididioma: estudio._ididioma
                    }
                    aux2.push(aux)
                })
                this.setState({ArrEstudiosAnte: aux2})
            }
            else if(Object.keys(this.state.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto).length !== 0){
                this.setState({ArrEstudiosAnte: [...this.state.ArrEstudiosAnte, {
                    Duracion: this.state.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto.Duracion,
                    Lugar: this.state.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto.Lugar,
                    Nombreidioma: this.state.ProspectoIdiomaEstudios[0].Nombre,
                    TiempoTranscurrido: this.state.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto.TiempoTranscurrido,
                    _ididioma: this.state.ProspectoDetalles.EstudiosAnteriores.EstudiosAnterioresProspecto._ididioma
                }]})
            }
        }
    }

    AsignarEdicionSeguimiento(){
        let aux = {};
        let aux2 = [];
        if(this.state.ProspectoDetalles.Contactos !== null){
            if(this.state.ProspectoDetalles.Contactos.ContactoProspecto.length > 0){
                this.state.ProspectoDetalles.Contactos.ContactoProspecto.map((contacto, index) => {
                    aux = {
                        Asunto: contacto.Asunto,
                        Comentarios: contacto.Comentarios,
                        Fecha: moment(contacto.Fecha).format('YYYY-MM-DD'),
                        TipoContacto: contacto.TipoContacto
                    }
                    aux2.push(aux)
                })
                this.setState({ArrSeguimiento: aux2})
            }
            else if(Object.keys(this.state.ProspectoDetalles.Contactos.ContactoProspecto).length !== 0){
                this.setState({ArrSeguimiento: [...this.state.ArrSeguimiento, {
                    Asunto: this.state.ProspectoDetalles.Contactos.ContactoProspecto.Asunto,
                    Comentarios: this.state.ProspectoDetalles.Contactos.ContactoProspecto.Comentarios,
                    Fecha: moment(this.state.ProspectoDetalles.Contactos.ContactoProspecto.Fecha).format('YYYY-MM-DD'),
                    TipoContacto: this.state.ProspectoDetalles.Contactos.ContactoProspecto.TipoContacto
                }]})
            }
        }
    }

/************************************************************ FIN EDICIÓN PROSPECTO **********************************************************************************/

  closeModal() {
    this.props.eventClick("esc");
  }

    RegresarEstudioAnterior = (objeto) => {
        this.setState({ArrEstudiosAnte: [...this.state.ArrEstudiosAnte, objeto], mensaje: "La información de estudios anteriores se agrego exitosamente", colorNotificacion: "success"})
        this.showNotification();
    }

    RegresarSeguimiento = (objeto) => {
        this.setState({ArrSeguimiento: [...this.state.ArrSeguimiento, objeto], mensaje: "La información de seguimiento se agregó exitosamente", colorNotificacion: "success"})
        this.showNotification();
    }

    EliminarObjIdioma(index){
        this.state.ArrIdiomaSeleccionados.splice(index,1)
        this.state.ListaNiveles.splice(index,1)


        this.setState({ArrIdiomaSeleccionados: this.state.ArrIdiomaSeleccionados, ListaNiveles: this.state.ListaNiveles})
    }

    EliminarObjEstudios(index){
        this.state.ArrEstudiosAnte.splice(index,1)

        this.setState({ArrEstudiosAnte: [...this.state.ArrEstudiosAnte]})
    }

    EliminarObjSeguimiento(index){
        this.state.ArrSeguimiento.splice(index,1)

        this.setState({ArrSeguimiento: [...this.state.ArrSeguimiento]})
    }

    CrearObjProspecto(){
        let valor1 = 0;
        let valor2 = 0;
        let valor3 = 0;

        const IsEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

        this.state.ArrIdiomaSeleccionados.map(idioma =>{
            if(idioma._ididioma == ""){
                valor3 = 1;
            }
        })

        if(valor3 == 1){
            this.setState({mensaje: "El campo idioma no debe estar vacío", colorNotificacion: "danger"});
            this.showNotification();
        }

        if(this.state.Correo !== "" && IsEmail.test(this.state.Correo) === false){
            valor2 = 1;
            this.setState({errorCorreo: true, mensaje: "El correo que proporciono no es valido", colorNotificacion: "danger"});
            this.showNotification();
        }
        else{
            valor2 = 0;
            this.setState({errorCorreo: false})
        }

        if(this.state.Nombre == "" || this.state.ApellidoPaterno == ""){
            valor1 = 1;
            this.setState({errorNombre: true, errorApellidoPaterno: true, mensaje: "Complete los campos requeridos", colorNotificacion: "danger"});
            this.showNotification();
        }
        else{
            valor1 = 0;
            this.setState({errorNombre: false, errorApellidoPaterno: false})
        }

        if(valor1 + valor2 + valor3 == 0){
        this.state.ArrIdiomaSeleccionados.map(idioma =>{
            if(idioma.FechaAplicacionExamen == ""){
                idioma.FechaAplicacionExamen = "0001-01-01";
            }

            if(idioma._idNivelResultado == ""){
                idioma._idNivelResultado = idVacio;
            }
        })

        this.setState({
            ListaProspecto: {
                ApellidoMaterno: this.state.ApellidoMaterno,
                ApellidoPaterno: this.state.ApellidoPaterno,
                Celular: this.state.NumCelular,
                Clase: this.state.OpcionClase !== "" ? this.state.OpcionClase : "Niño",
                Contactos: {ContactoProspecto: this.state.ArrSeguimiento},   /*ArrayOfContactoProspecto*/    
                Correo: this.state.Correo,
                Empresa: this.state.Empresa,
                EstudiosAnteriores: {EstudiosAnterioresProspecto: this.state.ArrEstudiosAnte},  /*ArrayOfEstudiosAnterioresProspecto*/
                FechaRegistro: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
                Idiomas: {IdiomaProspecto: this.state.ArrIdiomaSeleccionados},
                MedioConocioEscuela: this.state.MedioConocioEscuela !== "" ? this.state.MedioConocioEscuela : "Periódico",
                Nombre: this.state.Nombre,
                NombreCompleto: (this.state.Nombre + " " + this.state.ApellidoPaterno + " " + this.state.ApellidoMaterno).toUpperCase(),
                NumeroFamiliaresEstudiando: this.state.NumFamiliaresEstudiando !== "" ? this.state.NumFamiliaresEstudiando : 0,
                Telefono:  this.state.NumTelefono,
                _id: Object.keys(this.state.ProspectoDetalles).length > 0 ? this.state.ProspectoDetalles._id : { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" }
            }
        }, () => this.RegistrarProspecto())
        //console.log(this.state.ListaProspecto)
        }
    }

    async ListaGeneralIdiomas() {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Prospectos/Prospectos/ListaGeneralIdiomas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({prospecto: this.state.ListaProspecto})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso
                    if (data.ListaIdiomas.length > 1) {
                        this.setState({ ListaIdiomas: data.ListaIdiomas, Activo: 1, cargando: false });
                    }
                    else {
                        let ListaAuxEmpresas = [];
                        ListaAuxEmpresas.push(data.ListaIdiomas);
                        //console.log(ListaAux);
                        this.setState({ ListaIdiomas: ListaAuxEmpresas, Activo: 1, cargando: false });
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    async ListaNivelesIdioma(id, index) {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Prospectos/Prospectos/ListaNivelesIdioma', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({idIdioma: id})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log("API ",data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
                    //this.showNotification();
                } else { //Filtrado exitoso
                    let Lista = this.state.ListaNiveles;

                    if (data.ListaNiveles.length > 1 ) {
                        //this.setState({ ListaNiveles: data.ListaNiveles, Activo: 1, cargando: false });

                            //console.log("aqui entro")
                            Lista[index] = data.ListaNiveles
    
                            this.setState({ ListaNiveles: Lista, Activo: 1, cargando: false });
                        // else{
                        //     let aux = [];
                        //     aux.push(data.ListaNiveles);

                        //     this.setState({ ListaNiveles: aux, Activo: 1, cargando: false });
                        // }
                    }
                    else {
                        // if(this.state.ListaNiveles.findIndex(e => e == index)){
                            let aux = [];
                            aux.push(data.ListaNiveles);

                            Lista[index] = aux;
    
                            this.setState({ ListaNiveles: Lista, Activo: 1, cargando: false });
                        // }
                        // else{
                        //     let aux = [];
                        //     aux.push(data.ListaNiveles);

                        //     this.setState({ ListaNiveles: [aux], Activo: 1, cargando: false });
                        // }
                    }
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    EliminarInformacionProspecto(id) {
        fetch(Globales.Url + 'Prospectos/Prospectos/EliminarInformacionProspecto', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({_id: id})
        }
        ).then(res => res.json())
            .then(data => {
                // console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1 });
                    this.showNotification();
                } else { //Filtrado exitoso

                    this.setState({ mensaje: data.mensaje, closeNotification: "success", Activo: 1 });
                    this.RegresarMensaje(data.mensaje);
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }

    RegresarMensaje(mensaje,Prospecto) {
        this.setState({ mensaje: mensaje, op: 1 });
        this.props.parentCallback(mensaje);
        this.props.eventClick(Prospecto);
        this.closeModal();
      }

    async RegistrarProspecto() {
        this.setState({cargando: true});
        await fetch(Globales.Url + 'Prospectos/Prospectos/RegistrarProspecto', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({prospecto: this.state.ListaProspecto})
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.error) { //Error en la respuesta
                    this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                    this.showNotification();
                } else { //Filtrado exitoso

                    this.RegresarMensaje(data.mensaje, data.data);
                }
            })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
    }


    //Función para abrir el modal de buscar alumnos
    openModalInfoVenta = (Venta) => {
        this.setState({ VentaSeleccionada: Venta, showInfoVenta: true, Titulo: "Información general", op: 1 });
    }

    openModalEstudiosAnte = () => {
        this.setState({  showEstudiosAnte: true, Titulo: "Agregar estudio anterior", op: 0 });
    }

    openModalSeguimiento = () => {
        this.setState({  showSeguimiento: true, Titulo: "Registro de actividad", op: 0 });
    }

    openModalSeguimientoInfo = (contacto) => {
        this.setState({ ContactoProspecto: contacto, showSeguimientoInfo: true, Titulo: "Información de contacto", op: 1 });
    }

    openModalPregunta = () => {
        this.setState({ show: true });
    }

    //Función que recupera valores para el modal
    eventoModalInfoVenta = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showInfoVenta: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalEstudiosAnte = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showEstudiosAnte: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalSeguimiento = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showSeguimiento: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoSeguimientoInfo = (mensaje) => {
        //Cambiar valores por default
        this.setState({ showSeguimientoInfo: false, op: 0 })
        if (mensaje != 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: "success" })
            this.showNotification();
        }
    }

    eventoModalPregunta = (valor) => {
        this.setState({ show: false })
        if(valor){
            this.EliminarInformacionProspecto(this.state.ProspectoDetalles._id)
        }
    }

    //Función que habilita la notificación
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    handleChangeIdioma(e, item){
        const { name, value } = e.target;

        let index = this.state.ArrIdiomaSeleccionados.indexOf(item);
        let json;

        switch(name){
            case "IDIdioma":

            if(value == ""){
                this.state.ArrIdiomaSeleccionados[index]._ididioma = "";
                this.state.ListaNiveles[index] = [];
                this.setState({ArrIdiomaSeleccionados: this.state.ArrIdiomaSeleccionados, ListaNiveles: this.state.ListaNiveles})
            }
            else{
                json = JSON.parse(value);
                this.ListaNivelesIdioma(json, index);
                this.state.ArrIdiomaSeleccionados[index]._ididioma = json;

                this.setState({ArrIdiomaSeleccionados: this.state.ArrIdiomaSeleccionados})
            }
                break;
            case "FechaExamen":
                this.state.ArrIdiomaSeleccionados[index].FechaAplicacionExamen = value;

                this.setState({ArrIdiomaSeleccionados: this.state.ArrIdiomaSeleccionados})
                break;

            case "idNivelResultado":
                if(value == ""){
                    this.state.ArrIdiomaSeleccionados[index]._idNivelResultado = "";
                }
                else{
                    json = JSON.parse(value);
                    this.state.ArrIdiomaSeleccionados[index]._idNivelResultado = json;
                }

                this.setState({ArrIdiomaSeleccionados: this.state.ArrIdiomaSeleccionados})
                break;
        }
    }

    AgregarIdiomaInteres(){
        let IdiomaPref = {
            FechaAplicacionExamen: "",
            _idNivelResultado: "",
            _ididioma: ""
        };

        this.setState({ArrIdiomaSeleccionados: [...this.state.ArrIdiomaSeleccionados, IdiomaPref], ListaNiveles: [...this.state.ListaNiveles, []]})
    }

    cerrarModal = () => {
        this.props.eventClick("esc")
      }

    render() {
    const { classes } = this.props;

    //console.log("IdiomaSeleccionado ", this.state.ArrIdiomaSeleccionados)
    //console.log("ListaNiveles ", this.state.ListaNiveles)

        let agregar;
        if (this.state.showInfoVenta == true) {
            agregar =
                <ModalInfoVenta
                    Titulo={this.state.Titulo}
                    showInfoVenta={this.state.showInfoVenta}
                    eventClick={this.eventoModalInfoVenta}
                    op={3}
                    VentaSeleccionada={this.state.VentaSeleccionada}
                    NombreAlumno={this.state.NombreAlumno}
                    //parentCallback={this.eventoModalInfoVenta}
                />
        }

        if (this.state.showEstudiosAnte == true) {
            agregar =
                <ModalEstudiosAnte
                    Titulo={this.state.Titulo}
                    showEstudiosAnte={this.state.showEstudiosAnte}
                    eventClick={this.eventoModalEstudiosAnte}
                    parentCallback={this.RegresarEstudioAnterior}
                />
        }

        if (this.state.showSeguimiento == true) {
            agregar =
                <ModalSeguimiento
                    Titulo={this.state.Titulo}
                    showSeguimiento={this.state.showSeguimiento}
                    eventClick={this.eventoModalSeguimiento}
                    parentCallback={this.RegresarSeguimiento}
                />
        }

        if (this.state.showSeguimientoInfo == true) {
            agregar =
                <ModalSeguimientoInfo
                    Titulo={this.state.Titulo}
                    showSeguimientoInfo={this.state.showSeguimientoInfo}
                    eventClick={this.eventoSeguimientoInfo}
                    op={this.state.op}
                    ContactoProspecto={this.state.ContactoProspecto}
                    //parentCallback={this.RegresarEstudioAnterior}
                />
        }

        if (this.state.cargando == true) {
            agregar = (
              <ModalCargando
                Cuerpo={this.state.CuerpoCargando}
                show={this.state.cargando}
              />
            );
          }

        let disabled
        if(this.state.IDIdioma !== ''){
            disabled = false
        }
        else{
            disabled = true
        }

    let Seleccionado;
    //console.log(this.state.ArrIdiomaSeleccionados)
    if(this.state.ArrIdiomaSeleccionados.length > 0){
        Seleccionado =
        this.state.ArrIdiomaSeleccionados.map((datos, index) => {
            let boton
            if(index == 0){
                boton = <Button data-toggle="tooltip" data-placement="bottom" title="Agregar otro idioma" color="success" round justIcon size="sm" style={{ marginTop: "10px", marginLeft: "10px" }} onClick={() => this.AgregarIdiomaInteres()}><Add /></Button>

            }
            else{
                boton = <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar idioma" color="danger" round justIcon size="sm" style={{ marginTop: "10px", marginLeft: "10px" }} onClick={() => this.EliminarObjIdioma(index)}><Close /></Button>
            }
            
            return(
                <tr>
                    <td>
                    <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="Idiomalabel">Idioma</InputLabel>
                        <Select
                            labelId="Idiomalabel"
                            fullWidth={true}
                            value={datos._ididioma !== "" ? JSON.stringify(datos._ididioma) : datos._ididioma}
                            onChange={(e) => this.handleChangeIdioma(e, datos)}
                            name="IDIdioma"
                            //fullWidth={true}
                            label="Idioma"
                            style={{ width: "15em", marginTop: "10px" }}
                        >
                            <MenuItem value={""}>{"(CAMPO VACIO)"}</MenuItem>
                            {this.state.ListaIdiomas.map(idioma => {
                                return(<MenuItem value={JSON.stringify(idioma._id)}>{idioma.Nombre}</MenuItem>)
                            })}
                            
                        </Select>
                    </FormControl>
                    </td>
                    <td>
                    <TextField
                        id="FechaExamen"
                        style={{ width: "15em", marginTop: "10px", marginLeft: "10px" }}
                        variant="outlined"
                        type="date"
                        format="DD-MM-YYYY"
                        size="small"
                        label="Fecha de examen"
                        onChange={(e) => this.handleChangeIdioma(e, datos)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            //onChange: this.handleChangeIdioma.bind(this),
                            value: datos.FechaAplicacionExamen,
                            name: "FechaExamen",
                            id: "FechaExamen",
                            //error: this.state.errorFechainicio,
                            //success: !this.state.errorFechainicio
                        }}
                    />
                </td>
                <td>
                    <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px", marginLeft: "10px"}} id="Nivellabel">Nivel resultado</InputLabel>
                        <Select
                            labelId="Nivellabel"
                            fullWidth={true}
                            value={datos._idNivelResultado !== "" ? JSON.stringify(datos._idNivelResultado) : datos._idNivelResultado}
                            onChange={(e) => this.handleChangeIdioma(e, datos)}
                            name="idNivelResultado"
                            //fullWidth={true}
                            label="Nivel resultado"
                            style={{ width: "15em", marginTop: "10px", marginLeft: "10px" }}
                        >
                            <MenuItem value={""}>{"(CAMPO VACIO)"}</MenuItem>
                            {this.state.ListaNiveles[index].map(nivel => {
                                return(
                                    <MenuItem value={JSON.stringify(nivel._id)}>{nivel.Nombre}</MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>
                </td>
                <td>{boton}</td>
            </tr>
            )
        })
    }

 //console.log(this.state.ArrEstudiosAnte)
/*TABLA DE ESTUDIOS ANTERIORES*/
    let Estudios;
    if(this.state.ArrEstudiosAnte.length > 0){
        Estudios = 
            <table>
                <thead>
                    <th>IDIOMA</th>
                    <th>LUGAR</th>
                    <th>DURACIÓN</th>
                    <th>MES/AÑO</th>
                </thead>
                <tbody>
                    {this.state.ArrEstudiosAnte.map((estudio, index) => {
                    return(
                        <tr>
                            <td>{(estudio.Nombreidioma).toUpperCase()}</td>
                            <td>{(estudio.Lugar).toUpperCase()}</td>
                            <td>{estudio.Duracion !== null && estudio.Duracion !== undefined ? (estudio.Duracion).toUpperCase() : ""}</td>
                            <td>{estudio.TiempoTranscurrido !== null && estudio.TiempoTranscurrido !== undefined ? (estudio.TiempoTranscurrido).toUpperCase() : ""}</td>
                            <td className="pull-right"><Button data-toggle="tooltip" data-placement="bottom" title="Eliminar estudios anteriores" color="danger" round justIcon size="sm" style={{ marginLeft: "10px" }} onClick={() => this.EliminarObjEstudios(index)}><Close /></Button></td>
                        </tr>
                        )
                    })}
                </tbody>
            </table>
    }
    else{
        Estudios = <div className="my-3" style={{textAlign: "center"}}>NO TIENE ESTUDIOS ANTERIORES REGISTRADOS</div>;
    }

/*TABLA DE SEGUIMIENTO*/
//console.log(this.state.ArrSeguimiento)
    let Seguimiento;
    if(this.state.ArrSeguimiento.length > 0){
        Seguimiento = 
            <table>
                <thead>
                    <th>FECHA</th>
                    <th>TIPO DE CONTACTO</th>
                </thead>
                <tbody>
                    {this.state.ArrSeguimiento.map((contacto, index) => {
                    return(
                        <tr>
                            <td>{moment(contacto.Fecha).format('DD-MM-YYYY')}</td>
                            <td>{(contacto.TipoContacto).toUpperCase()}</td>
                            <td className="pull-right">
                                <Button data-toggle="tooltip" data-placement="bottom" title="Información de seguimiento" color="info" round justIcon size="sm" style={{ marginLeft: "10px" }} onClick={() => this.openModalSeguimientoInfo(contacto)}><InfoTwoTone /></Button>
                                <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar información de seguimiento" color="danger" round justIcon size="sm" style={{ marginLeft: "10px" }} onClick={() => this.EliminarObjSeguimiento(index)}><Close /></Button>
                            </td>
                        </tr>
                        )
                    })}
                </tbody>
            </table>
    }
    else{
        Seguimiento = <div className="my-3" style={{textAlign: "center"}}>NO HAY SEGUIMIENTO REGISTRADO</div>;
    }

        return (
            <div>
                <Modal
                    isOpen={this.props.showEditarProspecto}
                    className="static-modal"
                    size="lg"
                    toggle={this.cerrarModal}
                    backdrop={true}
                >
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Información general</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="1"
                                            style={{ width: "25em", marginLeft: "10px" }}
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            label="Nombre"
                                            InputProps={{
                                                onChange: this.handleChange.bind(this),
                                                value: this.state.Nombre,
                                                name: "Nombre",
                                                id: "Nombre",
                                                error: this.state.errorNombre,
                                                success: !this.state.errorNombre
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="2"
                                            style={{ width: "25em" }}
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            label="Apellido paterno"
                                            InputProps={{
                                                onChange: this.handleChange.bind(this),
                                                value: this.state.ApellidoPaterno,
                                                name: "ApellidoPaterno",
                                                id: "ApellidoPaterno",
                                                error: this.state.errorApellidoPaterno,
                                                success: !this.state.errorApellidoPaterno
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="ApellidoMaterno"
                                            style={{ width: "25em", marginTop: "10px", marginLeft: "10px" }}
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            label="Apellido materno"
                                            InputProps={{
                                                onChange: this.handleChange.bind(this),
                                                value: this.state.ApellidoMaterno,
                                                name: "ApellidoMaterno",
                                                id: "ApellidoMaterno",
                                                //error: this.state.errorFechainicio,
                                                //success: !this.state.errorFechainicio
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="Correo"
                                            style={{ width: "25em", marginTop: "10px" }}
                                            variant="outlined"
                                            type="email"
                                            size="small"
                                            label="Correo electrónico"
                                            InputProps={{
                                                onChange: this.handleChange.bind(this),
                                                value: this.state.Correo,
                                                name: "Correo",
                                                id: "Correo",
                                                error: this.state.errorCorreo,
                                                success: !this.state.errorCorreo
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="NumTelefono"
                                            style={{ width: "25em", marginTop: "10px", marginLeft: "10px" }}
                                            variant="outlined"
                                            type="tel"
                                            size="small"
                                            label="Número de teléfono"
                                            inputProps={{ maxLength: 12 }}
                                            InputProps={{
                                                onChange: this.handleChange.bind(this),
                                                value: new AsYouType('MX').input(this.state.NumTelefono),
                                                name: "NumTelefono",
                                                id: "NumTelefono",
                                                //error: this.state.errorFechainicio,
                                                //success: !this.state.errorFechainicio
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="NumCelular"
                                            style={{ width: "25em", marginTop: "10px" }}
                                            variant="outlined"
                                            type="tel"
                                            size="small"
                                            label="Número de celular"
                                            inputProps={{ maxLength: 12 }}
                                            InputProps={{
                                                onChange: this.handleChange.bind(this),
                                                value: new AsYouType('MX').input(this.state.NumCelular),
                                                name: "NumCelular",
                                                id: "NumCelular",
                                                maxlength: 10,
                                                //error: this.state.errorFechainicio,
                                                //success: !this.state.errorFechainicio
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <FormControl className={classes.formControl} size="small" variant="outlined">
                                        <InputLabel style={{marginTop: "10px", marginLeft: "10px"}} id="Claselabel">Clase</InputLabel>
                                            <Select
                                                labelId="Claselabel"
                                                //fullWidth={true}
                                                value={this.state.OpcionClase}
                                                onChange={this.handleChange.bind(this)}
                                                name="OpcionClase"
                                                label="Clase"
                                                style={{ width: "21.9em", marginTop: "10px", marginLeft: "10px" }}
                                            >
                                                <MenuItem disabled>Seleccione una clase</MenuItem>
                                                <MenuItem value={"Niño"}>Niño</MenuItem>
                                                <MenuItem value={"Adolescente"}>Adolescente</MenuItem>
                                                <MenuItem value={"Adulto"}>Adulto</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="Empresa"
                                            style={{ width: "25em", marginTop: "10px" }}
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            label="Empresa"
                                            InputProps={{
                                                onChange: this.handleChange.bind(this),
                                                value: this.state.Empresa,
                                                name: "Empresa",
                                                id: "Empresa",
                                                //error: this.state.errorFechainicio,
                                                //success: !this.state.errorFechainicio
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="NumFamiliaresEstudiando"
                                            style={{ width: "25em", marginTop: "10px", marginLeft: "10px" }}
                                            variant="outlined"
                                            type="number"
                                            size="small"
                                            label="Número de familiares estudiando"
                                            InputProps={{
                                                onChange: this.handleChange.bind(this),
                                                value: this.state.NumFamiliaresEstudiando,
                                                name: "NumFamiliaresEstudiando",
                                                id: "NumFamiliaresEstudiando",
                                                //error: this.state.errorFechainicio,
                                                //success: !this.state.errorFechainicio
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <FormControl className={classes.formControl} size="small" variant="outlined">
                                        <InputLabel style={{marginTop: "10px"}} id="Descubrirlabel">Como conoció la escuela</InputLabel>
                                            <Select
                                                labelId="Descubrirlabel"
                                                fullWidth={true}
                                                value={this.state.MedioConocioEscuela}
                                                onChange={this.handleChange.bind(this)}
                                                name="MedioConocioEscuela"
                                                //fullWidth={true}
                                                label="Como conoció la escuela"
                                                style={{ width: "21.9em", marginTop: "10px" }}
                                            >
                                                <MenuItem disabled>Seleccione un medio</MenuItem>
                                                <MenuItem value={"Periódico"}>Periódico</MenuItem>
                                                <MenuItem value={"Volante"}>Volante</MenuItem>
                                                <MenuItem value={"Internet"}>Internet</MenuItem>
                                                <MenuItem value={"Recomendación"}>Recomendación</MenuItem>
                                                <MenuItem value={"Otros"}>Otros</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <Card>
                        <CardHeader color="info" text>
                            <h4 className={classes.cardTitle}>Idiomas de interés</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <table style={{width: "100%"}}>
                                        <thead>
                                            <th>IDIOMA</th>
                                            <th>FECHA EXAMEN</th>
                                            <th>NIVEL RESULTADO</th>
                                        </thead>
                                        <tbody>
                                            {Seleccionado}
                                            
                                        </tbody>
                                    </table>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        </Card>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Estudios anteriores</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        {Estudios}
                                        <Button size="sm" type="submit" color="info" round className="pull-right mt-3" onClick={() => this.openModalEstudiosAnte()}><AddCircle/>&nbsp;Agregar</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Seguimiento</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        {Seguimiento}
                                        <Button size="sm" type="submit" color="info" round className="pull-right mt-3" onClick={() => this.openModalSeguimiento()}><AddCircle/>&nbsp;Agregar</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <div>
                            <Button size="sm" type="submit" color="info" round className="pull-right mx-2" onClick={() => this.CrearObjProspecto()}><Save/>&nbsp;Guardar</Button>
                            <Button hidden={this.props.op !== 1} tabIndex="1" size='sm' color="danger" className='pull-right mx-2' round onClick={() => this.openModalPregunta()}><Delete/>&nbsp;Eliminar</Button>
                            <Button tabIndex="1" size='sm' className='pull-right mx-2' round onClick={() => this.props.eventClick("esc")}><Close/>&nbsp;Cancelar</Button>
                        </div>

                <div id="">
                    <ModalPregunta
                        Titulo={"Prospectos"}
                        Cuerpo={"¿Esta seguro de eliminar el prospecto?"}
                        show={this.state.show}
                        eventClick={this.eventoModalPregunta}
                    />
                    {agregar}
                </div>
                </Modal>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(ModalCrearProspectos);