import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Snackbar from "components/Snackbar/Snackbar.jsx";
// import ProductosAutocomplete from "../../../Generales/AutoComplete/Productos";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Save, Clear, SubdirectoryArrowLeft} from "@material-ui/icons";
import ModalEditarMeses from "./ModalEditarMeses";
import Cargando from '../../../components/Generales/ModalCargando';




const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        padding: 5

    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

class NuevoPresupuesto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            _id: '',
            Nombre: "",           
            Fecha: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            Año:"",        
            ListaCategorias: [],     
            ListaCategoriasEgresos:[],      
            showEditarMeses:false,
            /**Notificaciones */
            MensajeNotificacion: "",
            ColorNotificacion: "",
            /**Errores */
            Error: {
            },
            //Propiedades edicion de meses
            NombreCategoria:"",
            Tipo:0,
            idCategoria:null,
            idSubcategoria:null,
            ListaValoresMeses:[0,0,0,0,0,0,0,0,0,0,0,0],
            MostrarModalCargando:false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEgresos = this.handleChangeEgresos.bind(this);
        this.ObtenerTodasCategoriasPadres = this.ObtenerTodasCategoriasPadres.bind(this);
        this.ObtenerPresupuestoEdicion = this.ObtenerPresupuestoEdicion.bind(this);
    }


    componentDidMount() {

        if(this.props.idPresupuesto==""){
            var fecha = new Date(this.state.Fecha)
            this.setState({Año: fecha.getUTCFullYear()})
            this.ObtenerTodasCategoriasPadres(0);
        }
        else{
            this.setState({_id: this.props.idPresupuesto},()=>{                                
                this.ObtenerTodasCategoriasPadres(1);
            })
            
        }
       
    }

    
    ObtenerTodasCategoriasPadres(parametro) {
        this.setState({ MostrarModalCargando: true })
        fetch(Globales.Url + 'Presupuestos/Presupuestos/ListadoCategoriasPresupuestos', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
        }
        ).then(res => res.json())
            .then(data => {
                if (!data.error) {
                    this.setState({
                        ListaCategorias: data.ListaCategoriasIngresos,        
                        ListaCategoriasEgresos: data.ListaCategoriasEgresos                                
                    })
                    if(parametro==1){
                        this.ObtenerPresupuestoEdicion();
                    }
                    else{
                        this.setState({ MostrarModalCargando: false })
                    }    
                } else {
                    this.setState({ MostrarModalCargando: false })
                }
            })
            .catch(err => console.error(err));
    }

    Registrar() {
        if (this.FormValido()) {
            if(this.state._id==""){
                this.setState({ Cargando: true })
                fetch(Globales.Url + 'Presupuestos/Presupuestos/RegistrarPresupuesto', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                    }, body: JSON.stringify({
                        _id: this.state._id,
                        Nombre: this.state.Nombre,
                        Fecha: this.state.Fecha,
                        Año: this.state.Año,
                        ListaCategorias: this.state.ListaCategorias,
                        ListaCategoriasEgresos: this.state.ListaCategoriasEgresos
                    })
                }
                ).then(res => res.json())
                    .then(data => {
                        if (!data.error) {
                            this.setState({ Cargando: false })
                            this.props.EventoRegistro(data.mensaje);
                        } else {
                            this.VerNotificacion(data.mensaje, "danger")
                            this.setState({ Cargando: false })
                        }
                    })
                    .catch(err => console.error(err));
            }
            else{
                //Es actualizar
                this.setState({ Cargando: true })
                fetch(Globales.Url + 'Presupuestos/Presupuestos/ActualizarPresupuesto', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                    }, body: JSON.stringify({
                        _id: this.state._id,
                        Nombre: this.state.Nombre,
                        Fecha: this.state.Fecha,
                        Año: this.state.Año,
                        ListaCategorias: this.state.ListaCategorias,
                        ListaCategoriasEgresos: this.state.ListaCategoriasEgresos
                    })
                }
                ).then(res => res.json())
                    .then(data => {
                        if (!data.error) {
                            this.setState({ Cargando: false })
                            this.props.EventoRegistro(data.mensaje);
                        } else {
                            this.VerNotificacion(data.mensaje, "danger")
                            this.setState({ Cargando: false })
                        }
                    })
                    .catch(err => console.error(err));
            }
        }
    }


    ObtenerPresupuestoEdicion() {
        fetch(Globales.Url + 'Presupuestos/Presupuestos/ObtenerPresupuestoEdicion', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }, body: JSON.stringify({ idPresupuesto: this.state._id })
        }
        ).then(res => res.json())
            .then(data => {
                //console.log(data.data)
                if (!data.error) {

                    this.setState({
                        Fecha: new Date(data.data.Fecha).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
                        Nombre: data.data.Nombre,     
                        ListaCategorias: data.data.ListaCategorias,
                        ListaCategoriasEgresos: data.data.ListaCategoriasEgresos,
                        Año: data.data.Año,
                        MostrarModalCargando: false           
                    })

                } else {
                    this.setState({ MostrarModalCargando: false })

                }
            })
            .catch(err => console.error(err));

    }

    FormValido() {
        var Valido = true;
        var errores = {};

        if (this.state.Nombre == "") {
            Valido = false;
            errores["Nombre"] = true;
        }

        if (Valido == false) {
            this.VerNotificacion("Faltan campos por capturar", "warning")
        }      

        this.setState({
            Error: errores
        })

        return Valido;
    }

     //Función que habilita la notificación
   VerNotificacion(Mensaje, Color) {

    this.setState({
      VerNotificacion: true,
      MensajeNotificacion: Mensaje,
      ColorNotificacion: Color
    }, () => {
      this.alertTimeout = setTimeout(
        function () {
          this.setState({ VerNotificacion: false, notificacion: false });

        }.bind(this),
        4000
      );
    });


  }

  FocusText(e){
    const { name, value, id } = e.target;

  }

    handleChange(e) {
        const { name, value, id } = e.target;
        //console.log(value)
        if(name=="Fecha"){
            //Obtener el año
            var año = new Date(value).getUTCFullYear();
            this.setState({Año:año, Fecha: value})
            
        }
        else if(name=="Nombre"){
            this.setState({[name]:value})
        } else {
        //Buscar la sub para asignar el valor      
            if(name != id){
                var totalDetalleAnual=0;
                this.state.ListaCategorias.map(cat=>{            
                    if(cat._id==id){
                        totalDetalleAnual=0;
                        cat.Subcategorias.map(sub=>{
                            if(sub._id==name){                                
                                //sub.Detalle= value=="" ? 0 : Number(value);
                                //verificar a primera posicion del value                                
                                sub.Detalle = this.replaceNumber(value=="" || value == "e" ? "0" : value);
                                if(sub.Detalle.length>1){
                                    sub.Detalle = sub.Detalle.replace(/^0+/, '');
                                }
                            }                            
                                var detalle= parseFloat(sub.Detalle.toString().replace(/,/g, ''))
                                totalDetalleAnual+= parseFloat(detalle);                            
                        })
                        cat.TotalAnual= totalDetalleAnual;
                    }            
                })
            }
            else{
                // es categoria sin sub
                this.state.ListaCategorias.map(cat=>{            
                    if(cat._id==id){
                       //cat.Detalle= value=="" ? 0 : Number(value);
                       //cat.TotalAnual = value=="" ? 0 : Number(value);
                       cat.Detalle = this.replaceNumber(value=="" || value == "e" ? "0" : value);
                       if(cat.Detalle.length>1){
                        cat.Detalle = cat.Detalle.replace(/^0+/, '');
                        }
                       var detalle= parseFloat(cat.Detalle.replace(/,/g, ''))
                       cat.TotalAnual = detalle;
                    }            
                })
            }
        
            this.setState({a:""})         
        }   
    }

    handleChangeEgresos(e) {
        const { name, value, id } = e.target;       
        //Buscar la sub para asignar el valor
            if(name != id){
                var totalDetalleAnual=0;
                this.state.ListaCategoriasEgresos.map(cat=>{            
                    if(cat._id==id){
                        totalDetalleAnual=0;
                        cat.Subcategorias.map(sub=>{
                            if(sub._id==name){
                                //sub.Detalle= value=="" ? 0 : value;
                                sub.Detalle = this.replaceNumber(value=="" || value == "e" ? "0" : value);
                                if(sub.Detalle.length>1){
                                    sub.Detalle = sub.Detalle.replace(/^0+/, '');
                                }
                            }
                            //totalDetalleAnual+= parseFloat(sub.Detalle);
                                var detalle= parseFloat(sub.Detalle.toString().replace(/,/g, ''))
                                totalDetalleAnual+= parseFloat(detalle);
                            
                        })
                        cat.TotalAnual= totalDetalleAnual;
                    }            
                })
            }
            else{
                // es categoria sin sub
                this.state.ListaCategoriasEgresos.map(cat=>{            
                    if(cat._id==id){
                        //cat.Detalle= value=="" ? 0 : value;
                        //cat.TotalAnual = value=="" ? 0 : value;                       
                        cat.Detalle = this.replaceNumber(value=="" || value == "e" ? "0" : value);
                        if(cat.Detalle.length>1){
                            cat.Detalle = cat.Detalle.replace(/^0+/, '');
                        }
                        var detalle= parseFloat(cat.Detalle.replace(/,/g, ''))
                        cat.TotalAnual = detalle;
                    }            
                })
            }
            this.setState({a:""})             
    }   


    replaceNumber(value){
        return value.replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1.$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    }

    openModalEditarMeses = (NombreCat,idCat,idSub,listaValoresMes, tipo) => {

        this.setState({ NombreCategoria:NombreCat, Tipo:tipo, idCategoria:idCat, idSubcategoria:idSub, ListaValoresMeses: listaValoresMes, showEditarMeses: true });
      }

  eventoModalMeses = (mensaje, colorNotificacion, listaMesesActualizada) => {
    this.setState({ showEditarMeses: false})
    //Validar que el mensaje venga diferente de vacio
    if (mensaje !== 'esc') {
        //Buscar a donde se va actualizar la informacion
        if(this.state.Tipo==0){ //es categoria de ingresos
            this.state.ListaCategorias.map(cat=>{
                if(cat._id==this.state.idCategoria){
                    cat.ListaValoresMeses= listaMesesActualizada;
                }
            })
        }
        else if(this.state.Tipo==1){ // es subcategorias de ingresos

        }
        else if(this.state.Tipo==2){ // es categorias de egresos

        }
       
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.VerNotificacion()
    }
  }
   

    render() {

        const { classes } = this.props;
        const handleFocus = (event) => event.target.select();     
        const convertirPesos = (number) => {
                return new Intl.NumberFormat("ES-MX",{
                    style: 'currency',
                    currency: 'MXN'
                }).format(number);                  
        }

        let editarM;
        if (this.state.showEditarMeses == true) {
            editarM =
            <ModalEditarMeses
              NombreCategoria={this.state.NombreCategoria}
              Tipo={this.state.Tipo}
              idCategoria={this.state.idCategoria}
              idSubcategoria={this.state.idSubcategoria}
              ListaValoresMeses={this.state.ListaValoresMeses}
              showEditarMeses={this.state.showEditarMeses}
              eventClick={this.eventoModalMeses}
            />
        }

        return (
            <div>
                <GridContainer justify={"center"}>
                    {/**DATOS GENERALES */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>
                                    {this.props.Opcion == 1 ? "Nuevo Presupuesto" : "Nuevo Presupuesto"}
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={2} lg={2}>
                                        <CustomInput
                                            labelText="Nombre"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.handleChange,
                                                required: true,
                                                value: this.state.Nombre,
                                                name: "Nombre",
                                                id: "Nombre",
                                                type: "text",
                                                error: this.state.Error.Nombre
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2} lg={2}>
                                        <CustomInput
                                            labelText="Fecha"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.handleChange,
                                                required: true,
                                                value: this.state.Fecha,
                                                name: "Fecha",
                                                id: "Fecha",
                                                type: "date",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2} lg={2}>
                                        <CustomInput
                                            labelText="Año"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                required: true,
                                                disable: true,
                                                value: this.state.Año,
                                                name: "Año",
                                                id: "Año",
                                                type: "text",
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <br />
                                
                                <GridContainer style={{backgroundColor:"#70CD70", height:"40px", paddingTop:"10px"}}>
                                    <GridItem md={8} lg={8}>
                                        <GridContainer>
                                            <GridItem style={{fontWeight:"bold"}} md={5} lg={5}>INGRESOS</GridItem>
                                            <GridItem style={{fontWeight:"bold"}} md={2} lg={2}>DETALLE</GridItem>
                                            <GridItem style={{fontWeight:"bold"}} md={2} lg={2}>ANUAL</GridItem>
                                            <GridItem style={{fontWeight:"bold"}} md={2} lg={2}>EDITAR MESES</GridItem>
                                        </GridContainer>                                    
                                    </GridItem>
                                    
                                </GridContainer>                            
                                    {
                                        this.state.ListaCategorias.map((cat,index)=>                                             
                                        cat.Subcategorias.length>0 ?                                                             
                                            cat.Subcategorias.map((sub,index2)=>                                             
                                                <div>
                                                {
                                                    index2 == 0 ?
                                                    <GridContainer>
                                                            <GridItem md={8} lg={8} style={{ marginTop:"2px", marginBottom:"2px", backgroundColor:"#DEDEDE"}}>
                                                                <GridContainer>
                                                                    <GridItem style={{fontWeight:"bold", marginTop:"5px", marginBottom:"5px" }} md={5} lg={5}>{cat.Nombre}</GridItem>
                                                                    <GridItem md={2} lg={2}></GridItem>
                                                                    <GridItem style={{fontWeight:"bold", marginTop:"5px", marginBottom:"5px" }} md={2} lg={2}>{convertirPesos(cat.TotalAnual)}</GridItem>
                                                                </GridContainer>                                       
                                                            </GridItem>                                                           
                                                    </GridContainer>
                                                    : null 
                                                }
                                                <GridContainer>
                                                    <GridItem md={8} lg={8} style={{marginTop:"5px", marginBottom:"5px", padding:"5px"}}>
                                                        <GridContainer>
                                                            <GridItem md={5} lg={5} >{sub.Nombre}</GridItem>
                                                            <GridItem md={2} lg={2}>
                                                               <input name={sub._id} id={cat._id} className="form-control form-control-sm" type="text" min="0" pattern="^[0-9]+" value={sub.Detalle} onChange={this.handleChange} onFocus={handleFocus} ></input>
                                                            </GridItem>
                                                            <GridItem md={2} lg={2}>                                                            
                                                            </GridItem>
                                                            <GridItem md={2} lg={2}>
                                                                <Button color="warning" round justIcon size="sm" onClick={() => this.openModalEditarMeses(sub.Nombre, cat._id,sub._id, sub.ListaValoresMeses, 1)}><Edit /></Button>
                                                            </GridItem>
                                                        </GridContainer>                                       
                                                    </GridItem>
                                                </GridContainer>
                                                </div>
                                            )                                        
                                        : <GridContainer >
                                            <GridItem md={8} lg={8} style={{ marginTop:"2px", marginBottom:"2px", backgroundColor:"#DEDEDE"}}>
                                                <GridContainer>
                                                    <GridItem style={{fontWeight:"bold", marginTop:"5px"}} md={5} lg={5}>{cat.Nombre}</GridItem>
                                                    <GridItem style={{ marginTop:"5px"}} md={2} lg={2}>
                                                        <input name={cat._id} id={cat._id} className="form-control form-control-sm" type="text" value={cat.Detalle} onChange={this.handleChange} onFocus={handleFocus}></input>
                                                    </GridItem>
                                                    <GridItem style={{fontWeight:"bold", marginTop:"5px", marginBottom:"5px" }} md={2} lg={2}>{convertirPesos(cat.TotalAnual)}</GridItem>
                                                    <GridItem md={2} lg={2}>
                                                                <Button color="warning" round justIcon size="sm" onClick={() => this.openModalEditarMeses(cat.Nombre,cat._id, null, cat.ListaValoresMeses, 0)}><Edit /></Button>
                                                            </GridItem>
                                                </GridContainer>                                       
                                            </GridItem>                                                           
                                         </GridContainer>
                                            )
                                    }    

                                     <br />
                                     <br />
                                
                                <GridContainer style={{backgroundColor:"#70CD70", height:"40px", paddingTop:"10px"}}>
                                <GridItem md={8} lg={8}>
                                        <GridContainer>
                                            <GridItem style={{fontWeight:"bold"}} md={5} lg={5}>EGRESOS</GridItem>
                                            <GridItem style={{fontWeight:"bold"}} md={2} lg={2}>DETALLE</GridItem>
                                            <GridItem style={{fontWeight:"bold"}} md={2} lg={2}>ANUAL</GridItem>
                                        </GridContainer>                                    
                                    </GridItem>
                                    
                                </GridContainer>                            
                                    {
                                        this.state.ListaCategoriasEgresos.map((cat,index)=>                                             
                                        cat.Subcategorias.length>0 ?                                                             
                                            cat.Subcategorias.map((sub,index2)=>                                             
                                                <div>
                                                {
                                                    index2 == 0 ?
                                                    <GridContainer >
                                                            <GridItem md={8} lg={8} style={{ marginTop:"2px", marginBottom:"2px", backgroundColor:"#DEDEDE"}}>
                                                                <GridContainer>
                                                                    <GridItem style={{fontWeight:"bold", marginTop:"5px", marginBottom:"5px"}} md={5} lg={5}>{cat.Nombre}</GridItem>
                                                                    <GridItem md={2} lg={2}></GridItem>
                                                                    <GridItem style={{fontWeight:"bold", marginTop:"5px", marginBottom:"5px" }} md={2} lg={2}>{convertirPesos(cat.TotalAnual)}</GridItem>
                                                                </GridContainer>                                       
                                                            </GridItem>                                                           
                                                    </GridContainer>
                                                    : null 
                                                }
                                                <GridContainer>
                                                    <GridItem md={8} lg={8} style={{marginTop:"5px", marginBottom:"5px", padding:"5px"}}>
                                                        <GridContainer>
                                                            <GridItem md={5} lg={5}>{sub.Nombre}</GridItem>
                                                            <GridItem md={2} lg={2}>
                                                               <input name={sub._id} id={cat._id} className="form-control form-control-sm" type="text" value={sub.Detalle} onChange={this.handleChangeEgresos} onFocus={handleFocus}></input>
                                                            </GridItem>
                                                            <GridItem md={2} lg={2}></GridItem>
                                                            <GridItem md={2} lg={2}>
                                                                <Button color="warning" round justIcon size="sm" onClick={() => this.openModalEditarMeses(sub.Nombre, cat._id,sub._id, sub.ListaValoresMeses, 2)}><Edit /></Button>
                                                            </GridItem>
                                                        </GridContainer>                                       
                                                    </GridItem>
                                                </GridContainer>
                                                </div>
                                            )                                        
                                        : <GridContainer >
                                            <GridItem md={8} lg={8} style={{ marginTop:"2px", marginBottom:"2px", backgroundColor:"#DEDEDE"}}>
                                                <GridContainer>
                                                    <GridItem style={{fontWeight:"bold", marginTop:"5px"}} md={5} lg={5}>{cat.Nombre}</GridItem>
                                                    <GridItem style={{ marginTop:"5px"}} md={2} lg={2}><input name={cat._id} id={cat._id} className="form-control form-control-sm" type="text" value={cat.Detalle} onChange={this.handleChangeEgresos} onFocus={handleFocus}></input></GridItem>
                                                    <GridItem  style={{fontWeight:"bold", marginTop:"5px", marginBottom:"5px" }} md={2} lg={2}>{convertirPesos(cat.TotalAnual)}</GridItem>
                                                    <GridItem md={2} lg={2}>
                                                                <Button color="warning" round justIcon size="sm" onClick={() => this.openModalEditarMeses(cat.Nombre,cat._id, null, cat.ListaValoresMeses, 3)}><Edit /></Button>
                                                            </GridItem>
                                                </GridContainer>                                       
                                            </GridItem>                                                           
                                         </GridContainer>
                                            )
                                    }                                                                       
                               

                                <GridContainer id="Botones">

                                <div style={{ marginBottom: 60 }}>
                                     <div style={{ textAlign: 'right', height: 60, backgroundColor: 'white', position: 'fixed', bottom: 0, left: 0, width: '100%', right: 20 }} className="border-top border-dark">
                                        <div style={{ marginTop: 10 }}>
                                        <Button tabIndex="0" disabled={this.state.disable} id="btnGuardar" size='sm' color='info' className='pull-right' round onClick={this.Registrar.bind(this)}><Save />&nbsp;GUARDAR</Button>
                                        <Button tabIndex="1" size='sm' color='warning' className='pull-right' round onClick={() => this.props.EventoRegistro('esc')}><Clear />&nbsp;CANCELAR</Button>
                                        
                                        </div>
                                    </div>
                                 </div>
                                </GridContainer>

                            </CardBody>
                        </Card>
                    </GridItem>

                </GridContainer>

                <Cargando
                    show={this.state.MostrarModalCargando}
                    Cuerpo="Cargando"
                />
                
                {editarM}
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.ColorNotificacion}
                        message={this.state.MensajeNotificacion}
                        open={this.state.VerNotificacion}
                        closeNotification={() => this.setState({ VerNotificacion: false })}
                        close
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(NuevoPresupuesto);