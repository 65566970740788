import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, CameraAlt, Clear, Add, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp, Close} from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'

class ModalAsignarGrupo extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      ListaGrupos: [],
      ListaGruposTemp: [],
      ListaGruposAsignados: [],
      ListaIdiomas: [],
      ArrIdGrupos: [],
      IdiomaSeleccionado: "",
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: "",
      CuerpoCargando: "Cargando",
      cargando: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    if(this.props.op == 1){
      this.setState({
        ColaboradorSeleccionado: this.props.ColaboradorSeleccionado
      })
      this.ListaInformacionGruposMaestros(this.props.ColaboradorSeleccionado._id);
    }
    this.ListaGeneralIdiomas();
  }

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje() {
    this.props.parentCallback(this.state.ArrIdGrupos, this.state.ListaGruposAsignados);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  async ListaGeneralIdiomas() {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Prospectos/Prospectos/ListaGeneralIdiomas', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                if (data.ListaIdiomas.length > 1) {
                    this.setState({ ListaIdiomas: data.ListaIdiomas, Activo: 1, cargando: false });
                }
                else {
                    let ListaAuxEmpresas = [];
                    ListaAuxEmpresas.push(data.ListaIdiomas);
                    //console.log(ListaAux);
                    this.setState({ ListaIdiomas: ListaAuxEmpresas, Activo: 1, cargando: false });
                }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  async ListaInformacionGruposMaestros(idMaestro) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Colaboradores/Colaboradores/ListaInformacionGruposIniciadoMaestros', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idMaestro: idMaestro})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              //this.showNotification();
            } else { //Filtrado exitoso
              if(data.ListaGrupos.length > 1){
                let idAux = [];
                data.ListaGrupos.map (grupo => {
                  idAux.push(grupo._id)
                })
                this.setState({ListaGruposAsignados: data.ListaGrupos, ArrIdGrupos: idAux, cargando: false});
              }
              else{
                let aux = [];
                let idAux = [];
                aux.push(data.ListaGrupos);
                idAux.push(data.ListaGrupos._id);
                this.setState({ListaGruposAsignados: aux, ArrIdGrupos: idAux, cargando: false});
              }        
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  async ListaGruposIdiomaDisponiblesAsignacionMaestros(idIdioma) {
    this.setState({cargando: true, ListaGrupos: this.state.ListaGruposTemp});
    await fetch(Globales.Url + 'Colaboradores/Colaboradores/ListaGruposIdiomaDisponiblesAsignacionMaestros', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({id: idIdioma})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.error) { //Error en la respuesta
              this.setState({ cargando: false, ListaGrupos: [] });
              //this.showNotification();
            } else { //Filtrado exitoso
              if(data.ListaGrupos.length > 1){

                if(this.state.ListaGruposAsignados > 0){
                  this.state.ListaGruposAsignados.map(grupo => {
                    data.ListaGrupos.map((grupo2, index) => {
                      if(JSON.stringify(grupo._id) == JSON.stringify(grupo2._id)){
                        
                        data.ListaGrupos.splice(index, 1);
                      }
                    })
                  })
                }
                this.setState({ListaGrupos: data.ListaGrupos, cargando: false});
              }
              else{
                let aux = [];
                if(this.state.ListaGruposAsignados > 0){
                  this.state.ListaGruposAsignados.map(grupo => {
                    if(JSON.stringify(data.ListaGrupos._id) == JSON.stringify(grupo._id)){
                      aux = [];
                    }
                    else{
                      aux.push(data.ListaGrupos);
                    }
                  })
                  this.setState({ListaGrupos: aux, cargando: false});
                }
                else{
                  aux.push(data.ListaGrupos);
                  this.setState({ListaGrupos: aux, cargando: false});
                }
                
              }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  AgregarGrupo(grupo){
    let GrupoIndex = this.state.ListaGrupos.indexOf(grupo);
    this.state.ListaGrupos.splice(GrupoIndex, 1);

    this.setState({ListaGruposAsignados: [...this.state.ListaGruposAsignados, grupo], ArrIdGrupos: [...this.state.ArrIdGrupos, grupo._id], ListaGrupos: [...this.state.ListaGrupos]});
  }

  EliminarGrupo(grupo){
    let idIndex = this.state.ArrIdGrupos.indexOf(grupo._id);
    this.state.ArrIdGrupos.splice(idIndex, 1);

    let GrupoIndex = this.state.ListaGruposAsignados.indexOf(grupo);
    this.state.ListaGruposAsignados.splice(GrupoIndex, 1);

    this.setState({ ListaGruposAsignados: [...this.state.ListaGruposAsignados], ListaGrupos: [...this.state.ListaGrupos, grupo], ListaGruposTemp: [...this.state.ListaGruposTemp, grupo]});
  }



  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;

    if(name == "IdiomaSeleccionado"){
      this.ListaGruposIdiomaDisponiblesAsignacionMaestros(value._id);
      this.setState({[name]: value});
    }
    else{
      this.setState({[name]: value});
    }
  }
  
  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;
    //console.log(this.state._id)
    // console.log("Lista grupos",this.state.ListaGrupos)
    //console.log("Lista grupos asignados",this.state.ListaGruposAsignados)
    //console.log("ids grupos",this.state.ArrIdGrupos)
    //console.log(this.state.IdiomaSeleccionado)


    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    let Grupos;
        if(this.state.ListaGrupos.length > 0){
          Grupos = 
          <>
          <Card>
            <CardHeader color="info">Lista de grupos</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <table>
                    <thead>
                      <th style={{textAlign: "center"}}>Grupo</th>
                      <th style={{textAlign: "center"}}>Inicio de curso</th>
                      <th style={{textAlign: "center"}}>Capacidad</th>
                      <th style={{textAlign: "center"}}>Alumnos inscritos</th>
                    </thead>
                    <tbody>
                      {this.state.ListaGrupos.map(grupo => {
                        if(JSON.stringify(grupo._idIdioma) == JSON.stringify(this.state.IdiomaSeleccionado._id)){
                          return(
                          <tr>
                            <td style={{textAlign: "center"}}>{grupo.Nombre}</td>
                            <td style={{textAlign: "center"}}>{moment(grupo.FechaIncioCurso).format("DD-MM-YYYY")}</td>
                            <td style={{textAlign: "center"}}>{grupo.CapacidadAula}</td>
                            <td style={{textAlign: "center"}}>{grupo.TotalAlumnos}</td>
                            <td><Button data-toggle="tooltip" data-placement="bottom" title="Asignar grupo" color="success" round justIcon size="sm" className="ml-2" onClick={() => this.AgregarGrupo(grupo)}><Add /></Button></td>
                          </tr>
                          )
                        }
                      })}
                    </tbody>
                  </table>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          </>
        }
        else{
          Grupos =
            <>
            <Card>
              <CardHeader color="info">Grupos seleccionados</CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <table>
                      <thead>
                        <th style={{textAlign: "center"}}>Grupo</th>
                        <th style={{textAlign: "center"}}>Inicio de curso</th>
                        <th style={{textAlign: "center"}}>Capacidad</th>
                        <th style={{textAlign: "center"}}>Alumnos inscritos</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{textAlign: "center"}}>NO HAY GRUPOS DISPONIBLES PARA EL IDIOMA SELECCIONADO</td>
                        </tr>
                      </tbody>
                    </table>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            </>
        }

        let GruposAsignados;
        if(this.state.ListaGruposAsignados.length > 0){
          GruposAsignados = 
          <>
          <Card>
            <CardHeader color="info">Grupos asignados</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <table>
                    <thead>
                      <th style={{textAlign: "center"}}>Grupo</th>
                      <th style={{textAlign: "center"}}>Inicio de curso</th>
                      <th style={{textAlign: "center"}}>Capacidad</th>
                      <th style={{textAlign: "center"}}>Alumnos inscritos</th>
                    </thead>
                    <tbody>
                      {this.state.ListaGruposAsignados.map(grupo => {
                        if(grupo.Activo == "true"){
                          return(
                            <tr>
                              <td style={{textAlign: "center"}}>{grupo.Nombre}</td>
                              <td style={{textAlign: "center"}}>{moment(grupo.FechaIncioCurso).format("DD-MM-YYYY")}</td>
                              <td style={{textAlign: "center"}}>{grupo.CapacidadAula}</td>
                              <td style={{textAlign: "center"}}>{grupo.TotalAlumnos}</td>
                              <td><Button data-toggle="tooltip" data-placement="bottom" title="Desasignar grupo" color="danger" round justIcon size="sm" className="ml-2" onClick={() => this.EliminarGrupo(grupo)}><Close /></Button></td>
                            </tr>
                          )
                        }
                      })}
                    </tbody>
                  </table>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          </>
        }
        else{
          GruposAsignados =
            <>
            <Card>
              <CardHeader color="info">Grupos asignados</CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <table>
                      <thead>
                        <th style={{textAlign: "center"}}>Grupo</th>
                        <th style={{textAlign: "center"}}>Inicio de curso</th>
                        <th style={{textAlign: "center"}}>Capacidad</th>
                        <th style={{textAlign: "center"}}>Alumnos inscritos</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{textAlign: "center"}}>NO TIENE GRUPOS ASIGNADOS</td>
                        </tr>
                      </tbody>
                    </table>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            </>
        }

    return (
      <div>
        <Modal
          isOpen={this.props.showAsignarGrupo}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="idioma">Seleccione un idioma</InputLabel>
                      <Select
                          labelId="idioma"
                          //fullWidth={true}
                          value={this.state.IdiomaSeleccionado}
                          onChange={this.handleChange.bind(this)}
                          name="IdiomaSeleccionado"
                          label="Seleccione un idioma"
                          style={{ width: "21.9em", marginTop: "10px" }}
                      >
                        <MenuItem disabled>Seleccione un idioma</MenuItem>
                          {this.state.ListaIdiomas.map(idioma =>
                            <MenuItem value={idioma}>{(idioma.Nombre)}</MenuItem>
                          )}
                      </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>  
          {Grupos}  
          {GruposAsignados} 
          <GridItem xs={12} sm={12} md={12}>
            <Button color="info" tabIndex="1" size='sm' className='pull-right ml-2 mb-3' round onClick={() => this.RegresarMensaje()}>&nbsp;Guardar</Button>
            <Button tabIndex="1" size='sm' className='pull-right ml-2 mb-3' round onClick={() => this.props.eventClick("esc")}>&nbsp;Cancelar</Button>
          </GridItem>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAsignarGrupo);