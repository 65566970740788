import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Edit, Close, Print, Search, Clear, Event, ArrowForward, ArrowBack } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
//Modales
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import ModalBuscarAlumnos from "../../../components/Generales/ModalBuscarAlumnos";
//modales del modulo
import ModalInfoCita from "./ModalInfoCita";
import ModalProgramarCita from "./ModalProgramarCita";

const EnumMes = [
  "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
]

class Facturacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      Citas: [],
      Folios: [],
      FoliosCancelados: [],
      AlumnoSeleciondado: [],
      CitaSeleccionda: [],
      NombreAlumno: [],
      NombreEmpresa: "",
      FechasCalendario: [],
      Calendario: [],
      Mes: [],
      FechaInicio: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      FechaFin: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
      idUsuario: null,
      cargando: false,
      showInfoCita: false,
      //modal cargando
      mensaje: "",
      colorNotificacion: 'success',
      //checks
      Agendada: true,
      Atendida: true,
      Cancelada: true,
      Inasistencia: true,
      CuerpoCargando: "Cargando",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.eventoClickAsignar = this.eventoClickAsignar.bind(this);
  }

  componentDidMount() {
    let IDcookie = JSON.parse(cookie.get('id'));

    this.setState({
      idUsuario: IDcookie,
    })

    this.crearListaDias()
  }

  sumarDiasFecha(fecha, dias) {
    fecha = new Date(fecha)
    fecha.setDate(fecha.getDate() + dias)
    return fecha
  }

  CalcularDiasTranscurridos(fecha) {
    fecha = new Date(this.sumarDiasFecha(fecha, 1))
    var fechaActual = new Date(this.state.FechaInicio)

    var Diferencia = (fechaActual - fecha)

    return Math.round(Diferencia / (1000 * 60 * 60 * 24))
  }
  sumarMes() {
    var fechainicio = this.state.FechaInicio
    var fechafin = this.state.FechaFin

    var inicio = new Date(new Date(fechainicio).setMonth(new Date(fechainicio).getMonth() + 1))
    var fin = new Date(new Date(fechafin).setMonth(new Date(fechafin).getMonth() + 1))

    this.setState({
      FechaInicio: inicio,
      FechaFin: fin,
    }, () => {
      this.crearListaDias()
    })
  }

  RestarMes() {
    var fechainicio = this.state.FechaInicio
    var fechafin = this.state.FechaFin

    var inicio = new Date(new Date(fechainicio).setMonth(new Date(fechainicio).getMonth() - 1))
    var fin = new Date(new Date(fechafin).setMonth(new Date(fechafin).getMonth() - 1))

    this.setState({
      FechaInicio: inicio,
      FechaFin: fin,
    }, () => {
      this.crearListaDias()
    })
  }


  crearListaDias() {
    this.setState({
      FechasCalendario: [],
      cargando: true,
    })

    var listaFechas = []
    var LimiteFecha = 0

    //Calcular dias transcurridos
    LimiteFecha = (this.state.FechaFin - this.state.FechaInicio)
    LimiteFecha = Math.round(LimiteFecha / (1000 * 60 * 60 * 24))

    //listar los dias
    for (var i = 1; i <= LimiteFecha; i++) {
      listaFechas.push({
        Fecha: new Date(new Date(this.state.FechaInicio).getFullYear(), new Date(this.state.FechaInicio).getMonth(), i),
        Dia: i,
        Citas: []
      })
    }

    //console.log("💜 listaFechas:", listaFechas)
    this.setState({
      FechasCalendario: listaFechas,
    }, () => {
      this.BuscarCitas()
    })
  }
  CrearCalendario() {

    var Calendario = []
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var semana = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
    }
    var mes = []

    var semanaAux = semana
    this.state.FechasCalendario.map(dia => {
      var day = days[new Date(dia.Fecha).getDay()];
      semanaAux[day] = dia

      if (day == 'Saturday' || dia.Dia == this.state.FechasCalendario.length) {
        mes.push(semanaAux)
        semanaAux = {
          Sunday: [],
          Monday: [],
          Tuesday: [],
          Wednesday: [],
          Thursday: [],
          Friday: [],
          Saturday: [],
        }
      }
    })

    // console.log("MES -------",mes)
    this.setState({
      Mes: mes,
      cargando: false
    },()=>{
      // this.VerificarProspectos()
    })
  }

  handleCallback = (AlumnoSeleciondado) => {
    this.setState({ AlumnoSeleciondado: AlumnoSeleciondado })
  }

  //Función para obtener los pagos pendientes de los alumnos
  BuscarCitas() {
    this.setState({ Citas: [] })
    fetch(Globales.Url + 'Citas/Citas/BuscarCitas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        Inicio: this.state.FechaInicio,
        Fin: this.state.FechaFin
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.CrearCalendario()
        } else { //Filtrado exitoso
          var aux =[]
          if (!(data.Citas.length > 1)) {
            aux.push(data.Citas)
            data.Citas = aux
          }
          this.state.Citas = data.Citas.filter(cita => new Date(cita.Fecha) >= this.state.FechaInicio )
          // this.state.Citas = data.Citas
          
          this.VerificarProspectos() 
          // this.setState({
          //   Citas:data.Citas
          // }, () => { 
          // })
          
          // this.CrearCalendario()
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }

  BuscarInformacionProspecto(_idAlumno) {
    fetch(Globales.Url + 'Prospectos/Prospectos/ObtenerInformacionProspecto', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        _id:_idAlumno,
      })
    }
    ).then(res => res.json())
      .then(data => {
        var citas = this.state.Citas

        // let index = citas.findIndex( m => m._idAlumno == _idAlumno)
        // if(index != -1){
        //   citas[index].NombreAlumno = data.ProspectoDetalles.NombreCompleto
        // }

        for(let i = 0; i < citas.length;i++){
          if(citas[i]._idAlumno == _idAlumno){
            citas[i].NombreAlumno  = data.ProspectoDetalles.NombreCompleto
          }
        }

        this.setState({
          Citas:citas
        })
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }
  VerificarProspectos(){
    var citas = this.state.Citas

    for(let i = 0; i < citas.length;i++){
      if(citas[i].NombreAlumno == null){
        this.BuscarInformacionProspecto(citas[i]._idAlumno)
      }
    }
    
    // console.log(this.state.Citas)
    this.FiltrarCitas(this.state.Citas)

  }
  FiltrarCitas() {
    var citas = this.state.Citas
    var calendario = this.state.FechasCalendario
    var ListaCitas = []

    
    citas.map(cita => {
      switch (cita.Estado) {
        case "Agendada":
          cita.ColorEstado = "warning";
          break;
        case "Atendida":
          cita.ColorEstado = "success";
          break;
        case "Cancelada":
          cita.ColorEstado = "";
          break;
        case "Inasistencia":
          cita.ColorEstado = "danger";
          break;
      }

      // calendario.map(dia => {
      //   var fecha = new Date(cita.Fecha).getDate()

      //   if (dia.Dia == fecha) {
      //     dia.Citas.push(cita)
      //   }
      // })

    })

    //console.log("💜 calendario:", calendario)

    this.setState({
      Citas: citas,
      cargando: false
    },()=>{
      // this.VerificarProspectos()
      this.CrearCalendario()
    })
  }

  ExportarCorte() {
    var metodoPDF = ""
    if (this.state.opcionBuscar == "FolioVenta") {
      this.setState({ mensaje: "No se permite explortar por folio", colorNotificacion: "danger" });
      this.showNotification();
    } else {

      if (Object.keys(this.state.AlumnoSeleciondado).length == 0) {
        metodoPDF = "CorteDeCajaPDFRangoFechas"
      } else {
        metodoPDF = "CorteDeCajaPDFRangoFechasAlumno"
      }
    }
    if (metodoPDF != "") {
      this.setState({ cargando: true })
      fetch(Globales.Url + 'CorteCaja/CorteCaja/' + metodoPDF, {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({
          Inicio: this.state.FechaInicio,
          Fin: this.state.FechaFin,
        })
      }
      ).then(res => res.json())
        .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
            this.showNotification();
          } else { //Filtrado exitoso
            //console.log(data.Folios)
            this.DescargarReporte(data.PDF)
          }
        })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
    }
  }
  imprimirElemento() {
    var printContents = document.getElementById("imprimible").innerHTML;
    var originalContents = document.body.innerHTML;

    document.title = "Calendario de citas "+EnumMes[new Date(this.state.FechaInicio).getMonth()]+" "+new Date(this.state.FechaInicio).getFullYear()
    document.body.innerHTML = printContents;

    window.print();
    window.location.reload();
    // window.location.replace('/#/Citas/Calendario');
    // location.reload();
  }

  DescargarReporte(archivoBase64) {
    const url = 'data:application/pdf;base64,' + archivoBase64;

    let nombre = 'Reporte de citas'
    const linkSource = `${url}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    this.setState({
      cargando: false,
      mensaje: "Reporte generado exitosamente",
      colorNotificacion: "success",
    });
    this.showNotification();
  }

  openModalBuscarAlumnos = () => {
    this.setState({ showAgregar: true, Titulo: "Buscar Alumnos", op: 1 });
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, } = e.target;

    this.setState({
      [name]: value,
    });
  }
  handleChangeCheck(e) {
    const { name, checked } = e.target;

    this.setState({
      [name]: checked,
    });
  }
 
  OpenModalInfoCita(cita){
    this.setState({ 
      CitaSeleccionda:cita,
      showInfoCita:true,
    });
  }
  CerrarModalInfoCita = (mensaje) =>{
    this.setState({ 
      showInfoCita:false,
      showProgramar:false,
      mensaje: mensaje,
      colorNotificacion: "success",
    });
    if(mensaje != "esc" && mensaje != "Editar"){
      this.showNotification();
      this.crearListaDias()
    }
    if(mensaje == "Editar"){
      if(new Date(this.state.CitaSeleccionda.Fecha) < new Date()){
        this.setState({ 
          mensaje: "No se puede reagendar una cita anterior",
          colorNotificacion: "warning",
        });
        //console.log("🌌 CITA ANTERIOR")
        this.showNotification();
      }else{
        this.EditarCita()
      }
    }
  }
  eventoClickAsignar = (mensaje) => {
    this.setState({ 
      showProgramar:false,
      mensaje: mensaje,
      colorNotificacion: "success",
    });
    if(mensaje != "esc"){
      this.showNotification();
      this.crearListaDias()
    }
  }
  EditarCita(){
    this.setState({
      op:1,
      // CitaSeleccionda:cita,
      showProgramar:true,
    })
  }
  render() {
    // console.log(this.state.Citas)

    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <ModalBuscarAlumnos
          Titulo={this.state.Titulo}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalcobranza}
          op={this.state.op}
          ocultar={true}
          parentCallback={this.handleCallback}
        />
    }

    if (this.state.showInfoCita == true) {
      agregar = (
        <ModalInfoCita
          eventClick={this.CerrarModalInfoCita}
          cita={this.state.CitaSeleccionda}
          show={this.state.showInfoCita}
        />
      );
    }
    
    if (this.state.showProgramar == true) {
      agregar = (
        <ModalProgramarCita
          eventClick={this.eventoClickAsignar}
          cita={this.state.CitaSeleccionda}
          op={this.state.op}
          show={this.state.showProgramar}
        />
      );
    }
    if (this.state.show == true) {
      agregar = (
        <ModalPregunta
          Titulo={"Eliminar cobranza"}
          Cuerpo={"¿Está seguro que desea eliminar el cobranza?"}
          show={this.state.show}
        //eventClick={this.eventoModalEliminar}
        />
      );
    }
    if (this.state.br == true) {
      agregar = (
        <Snackbar
          place="tr"
          color={this.state.colorNotificacion}
          message={this.state.mensaje}
          open={this.state.br}
          closeNotification={() => this.setState({ br: false })}
          close
        />
      );
    }
    if(this.state.cargando){
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      )
    }




    const { classes } = this.props;
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    var css = '@page { size: landscape; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

head.appendChild(style);

    return (
      <div style={{ height: '100vh', }}>
        <GridContainer id="NOMBRE DEL MES" xs={12} sm={12} md={12}>
          <br />

          <GridItem xs={12} sm={12} md={10}>
            
            <Button size="sm" className="mr-2 mb-3 pull-right" type="submit" color="info"
              round onClick={() => this.setState({ op: 0, showProgramar: true })}><Event />Programar cita</Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button size="sm" style={{ width: '14em'}} className="mr-2 mb-3 pull-right" type="submit" color="info" round onClick={() => this.imprimirElemento()}><Print />Imprimir </Button>
          </GridItem>

        <br/>

          <GridItem xs={12} sm={12} md={4}>
            <Button style={{ width: '14em'}}  tabIndex="1" size='sm' color='info' className='mr-2 mb-3' round onClick={() => this.RestarMes('esc')}><ArrowBack />&nbsp;Mes anterior</Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div className="text-center" style={{ fontWeight: "bold" }}><h4>{EnumMes[new Date(this.state.FechaInicio).getMonth()]}</h4></div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Button style={{ width: '14em'}} tabIndex="1" size='sm' color='info' className='pull-right mr-2 mb-3' round onClick={() => this.sumarMes()}><ArrowForward />&nbsp;Mes siguiente</Button>
          </GridItem>
        </GridContainer>
        <div id="imprimible">
        <table >
          <thead>
            <tr>
              <th className="text-center" style={{ width: "5%" }}><h5>DOM</h5></th>
              <th className="text-center" style={{ width: "15%" }}><h5>LUN</h5></th>
              <th className="text-center" style={{ width: "15%" }}><h5>MAR</h5></th>
              <th className="text-center" style={{ width: "15%" }}><h5>MIE</h5></th>
              <th className="text-center" style={{ width: "15%" }}><h5>JUE</h5></th>
              <th className="text-center" style={{ width: "15%" }}><h5>VIE</h5></th>
              <th className="text-center" style={{ width: "15%" }}><h5>SAB</h5></th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "12px" }}>
            
          {this.state.Mes.map(semana =>{
            return(
              <tr style={{ height: "15vh" }}>
                {/* {domingo} */}
                <td style={{ verticalAlign: "top", Overflow: "hidden", border: "1px solid #BABCDB" }}>
                  {semana.Sunday == [] ? null :
                  <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                    <thead>
                      <th>
                        <td><h5>{semana.Sunday.Dia}</h5></td>
                        <td></td>
                      </th>
                    </thead>
                    <tbody >
                          {this.state.Citas.map(cita => {
                            let dia = new Date(cita.Fecha).getDate()
                            if (semana.Sunday.Dia == dia) {
                              return (
                                <tr onClick={() => this.OpenModalInfoCita(cita)} style={{ cursor: "pointer" }} hover>
                                  <td>
                                    <Button color={cita.ColorEstado} style={{ height: "10px", width: "13px" }} round justIcon size="sm" />
                                  </td>
                                  <td >
                                    {cita.NombreAlumno}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                    </tbody>
                  </table>
                  </div>}
                </td>
                {/* {Lunes} */}
                <td style={{ verticalAlign: "top", Overflow: "hidden", border: "1px solid #BABCDB" }}>
                  {semana.Monday == [] ? null :
                  <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                    <thead>
                      <th>
                        <td><h5>{semana.Monday.Dia}</h5></td>
                        <td></td>
                      </th>
                    </thead>
                    <tbody >
                          {this.state.Citas.map(cita => {
                            let dia = new Date(cita.Fecha).getDate()
                            if (semana.Monday.Dia == dia) {
                              return (
                                <tr onClick={() => this.OpenModalInfoCita(cita)} style={{ cursor: "pointer" }} hover>
                                  <td>
                                    <Button color={cita.ColorEstado} style={{ height: "10px", width: "13px" }} round justIcon size="sm" />
                                  </td>
                                  <td >
                                    {cita.NombreAlumno}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                    </tbody>
                  </table>
                  </div>
                    }
                </td>
                {/* {martes} */}
                <td style={{ verticalAlign: "top", Overflow: "hidden", border: "1px solid #BABCDB" }}>
                  {semana.Tuesday == [] ? null :
                  <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                    <thead>
                      <th>
                        <td><h5>{semana.Tuesday.Dia}</h5></td>
                        <td></td>
                      </th>
                    </thead>
                    <tbody >
                          {this.state.Citas.map(cita => {
                            let dia = new Date(cita.Fecha).getDate()
                            if (semana.Tuesday.Dia == dia) {
                              return (
                                <tr onClick={() => this.OpenModalInfoCita(cita)} style={{ cursor: "pointer" }} hover>
                                  <td>
                                    <Button color={cita.ColorEstado} style={{ height: "10px", width: "13px" }} round justIcon size="sm" />
                                  </td>
                                  <td >
                                    {cita.NombreAlumno}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                    </tbody>
                  </table>
                  </div>
                    }
                </td>
                {/* {miercoles} */}
                <td style={{ verticalAlign: "top", Overflow: "hidden", border: "1px solid #BABCDB" }}>
                  {semana.Wednesday == [] ? null :
                  <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                    <thead>
                      <th>
                        <td><h5>{semana.Wednesday.Dia}</h5></td>
                        <td></td>
                      </th>
                    </thead>
                    <tbody >
                          {this.state.Citas.map(cita => {
                            let dia = new Date(cita.Fecha).getDate()
                            if (semana.Wednesday.Dia == dia) {
                              return (
                                <tr onClick={() => this.OpenModalInfoCita(cita)} style={{ cursor: "pointer" }} hover>
                                  <td>
                                    <Button color={cita.ColorEstado} style={{ height: "10px", width: "13px" }} round justIcon size="sm" />
                                  </td>
                                  <td >
                                    {cita.NombreAlumno}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                    </tbody>
                  </table>
                  </div>
                    }
                </td>
                {/* {jueves} */}
                <td style={{ verticalAlign: "top", Overflow: "hidden", border: "1px solid #BABCDB" }}>
                  {semana.Thursday == [] ? null :
                  <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                    <thead>
                      <th>
                        <td><h5>{semana.Thursday.Dia}</h5></td>
                        <td></td>
                      </th>
                    </thead>
                    <tbody >
                          {this.state.Citas.map(cita => {
                            let dia = new Date(cita.Fecha).getDate()
                            // console.log(dia)
                            if (semana.Thursday.Dia == dia) {
                              return (
                                <tr onClick={() => this.OpenModalInfoCita(cita)} style={{ cursor: "pointer" }} hover>
                                  <td>
                                    <Button color={cita.ColorEstado} style={{ height: "10px", width: "13px" }} round justIcon size="sm" />
                                  </td>
                                  <td >
                                    {cita.NombreAlumno}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                    </tbody>
                  </table>
                  </div>
                    }
                </td>
                {/* {viernes} */}
                <td style={{ verticalAlign: "top", Overflow: "hidden", border: "1px solid #BABCDB" }}>
                  {semana.Friday == [] ? null :
                  <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                    <thead>
                      <th>
                        <td><h5>{semana.Friday.Dia}</h5></td>
                        <td></td>
                      </th>
                    </thead>
                    <tbody >
                          {this.state.Citas.map(cita => {
                            let dia = new Date(cita.Fecha).getDate()
                            if (semana.Friday.Dia == dia) {
                              return (
                                <tr onClick={() => this.OpenModalInfoCita(cita)} style={{ cursor: "pointer" }} hover>
                                  <td>
                                    <Button color={cita.ColorEstado} style={{ height: "10px", width: "13px" }} round justIcon size="sm" />
                                  </td>
                                  <td >
                                    {cita.NombreAlumno}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                    </tbody>
                  </table>
                  </div>
                    }
                </td>
                {/* {sabado} */}
                <td style={{ verticalAlign: "top", Overflow: "hidden", border: "1px solid #BABCDB" }}>
                  {semana.Saturday == [] ? null :
                  <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                    <thead>
                      <th>
                        <td><h5>{semana.Saturday.Dia}</h5></td>
                        <td></td>
                      </th>
                    </thead>
                    <tbody >
                          {this.state.Citas.map(cita => {
                            let dia = new Date(cita.Fecha).getDate()
                            if (semana.Saturday.Dia == dia) {
                              return (
                                <tr onClick={() => this.OpenModalInfoCita(cita)} style={{ cursor: "pointer" }} hover>
                                  <td>
                                    <Button color={cita.ColorEstado} style={{ height: "10px", width: "13px" }} round justIcon size="sm" />
                                  </td>
                                  <td >
                                    {cita.NombreAlumno}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                    </tbody>
                  </table>
                  </div>
                    }
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
        </div>

        <div id="">
          {agregar}
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Facturacion);