// core components/views for Admin layout

import Inventario from "views/Modulos/Inventario/Inventario.jsx";
// @material-ui/icons
import { Streetview, AttachMoney, PersonAdd, AssignmentTurnedIn, Grain, ScatterPlot, Timeline, LinearScale, BarChart, Whatshot, MergeType, Home, Menu,WalletMembership,Contacts } from "@material-ui/icons";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [
  {
    path: "/Inventario",
    name: "Inventario",
    icon: AssignmentTurnedIn,
    component: Inventario,
    layout: "/inventario",
    idModulo:"12"
  }
  
]

let Permisos =localStorage.getItem("Permisos") == null ? {Modulo:[]}:JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

// console.log("🌌Permisos ", (Permisos).sort((a, b) => a.NumeroModulo - b.NumeroModulo))
var dashboardRoutesAccess = [];

for (var keyRoutes = 0; keyRoutes < dashboardRoutes.length; keyRoutes++) {
  if (Permisos.find(permiso => permiso.NumeroModulo == dashboardRoutes[keyRoutes].idModulo)) {
    dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
  }
}
export default dashboardRoutesAccess;