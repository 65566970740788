import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";


class ModalAgregarProducto extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
        BuscarAlumno: "",
        BuscarProducto: "",
        ListaAlumnos: [],
        ListaProducto: [],
        AlumnoSeleciondado:{},
        ProductoSelecionado: {},
        checkedInactivos: false,
      MostrarModal:false,
      _id: "",
      disabled:false,
      cobranza: [],
      proveedores:[],
      errorNumFactura: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    //console.log(this.props)
    if (this.props.op1 == 1) {
      this.setState({
        NombreEmpresa: this.props.NombreEmpresa,
        NumeroFactura: this.props.NumeroFactura,
        Rfc:this.props.Rfc,
        MontoRecibir: this.props.MontoRecibir,
        Divisa: this.props.Divisa,
        TotalRecibir: this.props.TotalRecibir,
        EstatusFactura: this.props.EstatusFactura,
        Comentarios: this.props.Comentarios,
        CuentaBancoOrigen: this.props.CuentaBancoOrigen,
        MesPago: this.props.MesPago,
        FormaPago: this.props.FormaPago,
        FechaCobranza: new Date(this.props.FechaCobranza).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        idCategoria: this.props.idCategoria,
        idSubcategoria: this.props.idSubcategoria,        
        _id: this.props._id,
        SubcategoriaHabilitada:false
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------
  obtenerProductos(){
    fetch(Globales.Url + 'Cobranza/Cobranza/ListadoProductos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify(this.state)
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
         } else { //Filtrado exitoso
          if(data.ListaProducto.length > 1){
            //console.log(data.ListaProducto);
            this.setState({colorNotificacion: 'success', ListaProducto: data.ListaProducto });
          }
          else {
            let ListaAux = [];
            ListaAux.push(data.ListaProducto);
            //console.log(ListaAux);
            this.setState({ ListaProducto: ListaAux });
          }
        }
      })
      .catch(err => console.error(err)); 
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  SeleccionarProducto(ListaProducto) {
    this.setState({ProductoSelecionado:ListaProducto, op1: 1 });
    this.props.parentCallback(ListaProducto);
    this.props.eventClick('esc');
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  }

 
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregarProd} className="static-modal" size="lg">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="NOMBRE DEL PRODUCTO"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.BuscarProducto,
                      name: "BuscarProducto",
                      id: "BuscarProducto",
                      type: "text",
                      //error: this.state.errorNumFactura,
                      //success: !this.state.errorNumFactura
                    }}
                  />
                </GridItem>
              </GridContainer>
              {/* Botones */}
              <div className='mt-4'>
                  <GridItem>
                  <Button tabIndex="1" size='sm' color='danger' className='pull-right' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
                    <Button tabIndex="0" disabled={this.state.disable} id="btnGuardar" size='sm' color='info' className='pull-right' round onClick={this.obtenerProductos.bind(this)}><Search />&nbsp;Buscar</Button>
                  </GridItem>
                </div>
            </CardBody>
            <CardBody>
            <table className="col-xs-12 col-sm-12 col-md-12">
                      <thead>           
                        <th className="center">Cantidad</th>
                        <th className="center">Descripción</th>
                        <th className="center">Precio</th>
                      </thead>
                      <tbody>
                      {this.state.ListaProducto.map(ListaProducto => {
                          return (
                            <tr key={ListaProducto._id}>
                              <td>1</td>
                              <td>{ListaProducto.Descripcion}</td>
                              <td>{ListaProducto.Precio}</td>
                              <td className="pull-right">
                                <Button data-toggle="tooltip" data-placement="bottom" title="Agregar producto a la venta" color="success" round justIcon size="sm" onClick={() => this.SeleccionarProducto(ListaProducto)} ><ArrowForwardIos /></Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className='mt-4'>
                  <GridItem>
                    {/* <Button tabIndex="0" disabled={this.state.disable} id="btnGuardar" size='sm' color='info' className='pull-right' round onClick={this.registrarCobranza.bind(this)}><Save />&nbsp;Seleccionar</Button> */}
                    {/* <Button tabIndex="1" size='sm' color='warning' className='pull-right' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button> */}
                  </GridItem>
                </div>
            </CardBody>
          </Card>
          
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAgregarProducto);