import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
// import { View, Image, StyleSheet } from 'react-native'

import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, Add, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import ModalAbonos from './ModalAbonos.jsx'
import moment from 'moment';
// import PlaceHolder from '../../../../assets/img/placeholder.jpg'
import Persona from '../../../../assets/img/persona.png'

class ModalInfoVenta extends Component {

  constructor(props, context) {
    super(props, context);
    // this.escFunction = this.escFunction.bind(this);
    this.state = {
      colorNotificacion: "success",
      mensaje: "",
      InfoAlumno: null,
      ImagenAlumno: "",
      Deudas: [],
      showAbonos:false,
      Abonos:[],
      cargando: false,
      CuerpoCargando: "Cargando",

    }
    this.EventoModalAbono = this.EventoModalAbono.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    this.ObtenerInformacionAlumno()
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------
  ObtenerInformacionAlumno() {
    this.setState({cargando: true})
    fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerInformacionBasicaAlumnoFoto', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idAlumno: this.props.Venta._idAlumno })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: 'danger', InfoAlumno: [], cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso

          this.setState({
            InfoAlumno: data.InfoAlumno,
            ImagenAlumno: data.InfoAlumno.Foto,
            cargando: false
          }, () => {
            this.preparAdeudos()
          });
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }
  preparAdeudos() {
    let aux = [];

    if(this.props.Venta.Deudas !== undefined){
      var Deudas = this.props.Venta.Deudas
      Deudas.map(adeudo => {
        adeudo["MostrarAbonos"] = false
      })
      this.setState({
        Deudas: Deudas
      })
    }
    else{
      aux.push(this.props.Venta);
      this.setState({
        Deudas: aux
      })
    }
  }

  MandarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.eventClick('esc');
  }

  //Alumno seleccionado mandarlo a Cobranza principal***********************************************************************************************************
  /* SeleccionarAlumno(ListaAlumnos) {
    this.setState({AlumnoSeleciondado:ListaAlumnos, op: 1 });
    this.props.parentCallback(ListaAlumnos);
    this.props.eventClick('esc');
  } */

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  OpenModalAbonos(Abonos){
    this.setState({
      showAbonos:true,
      Abonos:Abonos
    })
  }

  EventoModalAbono(){
    this.setState({
      showAbonos:false,
      Abonos:[]
    })
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  /* obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  } */

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
  
  render() {
    const { classes } = this.props;
    // console.log(this.state.InfoAlumno);
    //console.log(this.props.Venta)

    let agregar
    
    if (this.state.showAbonos== true) {
      agregar =
        <ModalAbonos
          Titulo={"Detalle de Abonos de la venta"}
          showAbonos={this.state.showAbonos}
          eventClick={this.EventoModalAbono}
          Abonos={this.state.Abonos}
        />
    }
    if (this.state.cargando== true) {
      agregar =
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
    }

    return (
      <div>
        <Modal isOpen={this.props.showDetalleVenta} className="static-modal" size="lg" toggle={this.cerrarModal} backdrop={true}>
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridContainer xs={12} sm={12} md={12} lg={12}>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {(this.state.InfoAlumno == null) ? null :
                    <table>
                      <tbody>
                        <tr>
                          <td rowSpan="4" style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                            {this.state.ImagenAlumno == undefined ?
                              <img className="overflow-hidden" style={{ width: "150x", height: "150px", borderRadius: "5px" }} src={Persona} />
                              : <img className="overflow-hidden" style={{ width: "150x", height: "150px", borderRadius: "5px" }} src={`data:image/png;base64,${this.state.ImagenAlumno}`} />}
                          </td>
                          <td colSpan="4"><h4><b>{this.state.InfoAlumno.NombreCompleto}</b></h4></td>
                        </tr>
                        <tr>
                          <td><b>Matrícula</b></td>
                          <td>{this.state.InfoAlumno.NumeroCuenta}</td>
                          <td><b>Clase</b></td>
                          <td>{this.state.InfoAlumno.Clase}</td>
                        </tr>
                        <tr>
                          <td><b>Correo</b></td>
                          <td>{this.state.InfoAlumno.Correo}</td>
                        </tr>
                        <tr>
                          <td><b>Grupos</b></td>
                          <td>{(this.state.InfoAlumno.ListaGrupos == null) ? "Sin asignar" :
                            this.state.InfoAlumno.ListaGrupos.GrupoAlumno.length > 1 ?
                              this.state.InfoAlumno.ListaGrupos.GrupoAlumno.map(grupo => {
                                if (grupo.Estado == "En_Curso") {
                                  return (
                                    grupo.NombreGrupo + ", "
                                  )
                                }
                              })
                              : this.state.InfoAlumno.ListaGrupos.GrupoAlumno.Estado == "En_Curso" ?
                                this.state.InfoAlumno.ListaGrupos.GrupoAlumno.NombreGrupo : "Sin asignar"
                          }</td>
                        </tr>
                      </tbody>
                    </table>}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Detalle de la venta</CardHeader>
            <CardBody>
              <table className="col-xs-12 col-sm-12 col-md-12 table-hover">
                <thead>
                  <th style={{ fontSize: "14px" }} className="center"></th>
                  <th style={{ fontSize: "14px" }} className="center">Cant.</th>
                  <th style={{ fontSize: "14px" }} className="center">Concepto pago</th>
                  <th style={{ fontSize: "14px", textAlign: "center" }} className="center">Monto</th>
                  <th style={{ fontSize: "14px", textAlign: "center" }} className="center">Precio </th>
                  <th style={{ fontSize: "14px", textAlign: "center" }} className="center">Fecha límite de pago</th>
                </thead>
                <tbody>
                  {this.state.Deudas.map((Pagos, index) => {
                    if(Pagos.Abonos == null){
                      return (
                        <tr key={index}>
                          <td style={{ width: "3%", fontSize: "13px" }} >
                             
                          </td>
                          <td style={{ fontSize: "13px", textAlign: "center" }} >
                            {Pagos.Cantidad}
                          </td>
                          <td style={{ width: "43%", fontSize: "13px" }} >
                            {Pagos.NombreConcepto}
                          </td>
                          <td style={{ fontSize: "13px", textAlign: "center" }} >
                            {"$" + parseInt(Pagos.Monto).toFixed(2)}
                          </td>
                          <td style={{ fontSize: "13px", textAlign: "center" }} >
                            {"$" + parseInt(Pagos.MontoTotal)}
                          </td>
                          <td style={{ fontSize: "13px", textAlign: "center" }} >
                            {
                            new Date(Pagos.FechaPago).toLocaleDateString('es-MX', { day: '2-digit', month: '2-digit', year: 'numeric', })
                            }
                          </td>
                        </tr>)
                    }else{
                      return (
                        <tr key={index}>
                          <td style={{ width: "3%", fontSize: "13px" }} onClick={() => this.OpenModalAbonos(Pagos.Abonos)}>
                            +
                          </td>
                          <td style={{ fontSize: "13px", textAlign: "center" }} onClick={() => this.OpenModalAbonos(Pagos.Abonos)}>
                            {Pagos.Cantidad}
                          </td>
                          <td style={{ width: "43%", fontSize: "13px" }} onClick={() => this.OpenModalAbonos(Pagos.Abonos)}>
                            {Pagos.NombreConcepto}
                          </td>
                          <td style={{ fontSize: "13px", textAlign: "center" }} onClick={() => this.OpenModalAbonos(Pagos.Abonos)}>
                            {"$" + parseInt(Pagos.Monto).toFixed(2)}
                          </td>
                          <td style={{ fontSize: "13px", textAlign: "center" }} onClick={() => this.OpenModalAbonos(Pagos.Abonos)}>
                            {"$" + parseInt(Pagos.MontoTotal)}
                          </td>
                          <td style={{ fontSize: "13px", textAlign: "center" }} onClick={() => this.OpenModalAbonos(Pagos.Abonos)}>
                            {
                            new Date(Pagos.FechaPago).toLocaleDateString('es-MX', { day: '2-digit', month: '2-digit', year: 'numeric', })
                            }
                          </td>
                        </tr>)
                    }
                  })
                  }
                </tbody>
              </table>
            </CardBody>
          </Card>
          <GridItem>
            {/* <Button tabIndex="0" id="btnGuardar" size='sm' color='success' className='pull-right  mb-3' round onClick={() => this.ActualizarFolioFactura()}><Save />&nbsp;REGISTRAR FACTURA</Button> */}
            <Button tabIndex="1" size='sm' color='danger' className='pull-right mr-2 mb-3' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CERRAR</Button>
          </GridItem>
        </Modal>
        {agregar}
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalInfoVenta);