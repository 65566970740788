import React from "react";
import { Modal } from "reactstrap";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import ModalLibros from "./ModalLibros";
import Globales from "utils/Globales";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalCargando from "../../../../components/Generales/ModalCargando";
import { ColorLens,Edit,Close, KeyboardArrowLeftOutlined } from "@material-ui/icons";

class ModalGuardarEmpresa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      IdNuevo: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      _idIdioma: "",
      Nombre: "",
      Descripcion: "",
      Orden: 0,
      ClaseNivel: "",
      ListaNiveles: [],
      ListaLibros: [],
      LibrosSeleccionados: [],
      ListaUnidades: [],
      Disabled: false,
      mensaje: "",
      br: false,
      show: false,
      cargando: false,
      colorNotificacion: "success",
      colorTableRow: "white",
      errorNombre: false,
      errorDescripcion: false,
      errorMes: false,
      errorClase: false,
      errorOrden: false,
      entroFiltrar: false,
      notificacion: false,
      EsEditar: false,
      EsUltimo: false,
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    //console.log(this.props.Niveles)

    this.setState({
      cargando: true,
      entroEliminar: true,
      _idIdioma: this.props.Idioma,
    });
    if (this.props.op == 1) {
      const {
        Descripcion,
        Duracion,
        Nombre,
        Orden,
        UltimoNivel,
        _id,
        Libros,
        Clase
      } = this.props.InfoNivel;

      let libros = [];
      if (Libros != null) {
        if (Libros.Libro.length > 1) {
          libros = Libros.Libro;
        } else {
          libros.push(Libros.Libro);
        }
      }

      this.setState({
        _id: _id !== undefined && _id !== null ? _id : "",
        Nombre,
        Descripcion,
        ListaLibros: libros,
        Meses: Duracion,
        Orden,
        ListaNiveles: this.props.Niveles,
        ClaseNivel: Clase,
        EsUltimo: UltimoNivel == "false" ? false : true,
      });
    }
    this.setState({
      cargando: false,
    });
  }


  async ValidarNivel() {
    let valido = true;

    if (this.state.Nombre === "") {
      this.setState({
        colorNotificacion: "danger",
        errorNombre: true,
        mensaje: "El nombre es de captura obligatoria.",
      });
      this.showNotification();
      valido = false;
    }
    if (! (this.state.Orden > 0)) {
      this.setState({
        colorNotificacion: "danger",
        errorOrden: true,
        mensaje: "El número de orden es obligatorio.",
      });
      this.showNotification();
      valido = false;
    }
    if (this.state.Descripcion === "") {
      this.setState({
        colorNotificacion: "danger",
        errorDescripcion: true,
        mensaje:
          "La descripción es de captura obligatoria, Verifique su información.",
      });
      this.showNotification();
      valido = false;
    }

    if(this.props.op == 1){
      if(this.props.Niveles.length !== 0){
        this.props.Niveles.map(nivel => {
          if(parseInt(nivel.Orden) == this.state.Orden && JSON.stringify(nivel._id) !== JSON.stringify(this.props.InfoNivel._id)){
            this.setState({
              colorNotificacion: "danger",
              errorOrden: true,
              mensaje:
                "El número de orden no puede ser igual al de otro nivel",
            });
            this.showNotification();
            valido = false;
          }
        })
      }
    }
    else{
      if(this.props.Niveles.length !== 0){
        this.props.Niveles.map(nivel => {
          if(parseInt(nivel.Orden) == this.state.Orden){
            this.setState({
              colorNotificacion: "danger",
              errorOrden: true,
              mensaje:
                "El número de orden no puede ser igual al de otro nivel",
            });
            this.showNotification();
            valido = false;
          }
        })
      }
    }
    

    if(valido){
      await this.RegistrarNivel();
    } 
  }

  async NuevaUnidad() {
    this.setState({
      showLibro: true,
      indexLibro: -1,
      op:0,
      LibroSelecionado: [],
    });
  }

  eventClickLibros = (libro) => {
    this.setState({ showLibro: false, });

    if( libro != "esc"){
      let ListaLibros = this.state.ListaLibros
      if(this.state.indexLibro == -1){
        ListaLibros.push(libro);
      }else{
        ListaLibros[this.state.indexLibro] = libro;
      }

      this.setState({
        ListaLibros:ListaLibros,
        indexLibro:-1,
      })
    }
  };

  RegresarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.eventClick(mensaje, "success");
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }
  eventoModalEditar(Libro){
    this.setState({
      indexLibro: this.state.ListaLibros.indexOf(Libro),
      Idioma: this.state._idIdioma,
      LibroSelecionado: Libro,
      showLibro: true,
      op: 1,
    })
  }


  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, checked } = e.target;

    if (name == "EsUltimo") {
      if (checked) {
        this.setState({ [name]: true });
      } else {
        this.setState({ [name]: false });
      }
    } else {
      this.setState({ [name]: value });
    }
  }

  async RegistrarNivel() {
    this.setState({ cargando: true });
    await fetch(Globales.Url + "Catalogos/Niveles/RegistrarNivel", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        Clase: "Adulto",
        descripcion: this.state.Descripcion,
        duracion: this.state.Meses,
        Libros: { Libro: this.state.ListaLibros },
        nombre: this.state.Nombre,
        Orden: this.state.Orden,
        UltimoNivel: this.state.EsUltimo,
        _IdIdioma: this.state._idIdioma,
        _Id: this.props.op == 1 ? this.state._id : this.state.IdNuevo,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
          });
          this.showNotification();
        } else {
          //Filtrado exitoso
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "success",
            cargando: false,
            _id: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
            _idIdioma: "",
            Nombre: "",
            Descripcion: "",
            Meses: "",
            Orden: "",
            ListaNiveles: [],
            ListaLibros: [],
            LibrosSeleccionados: [],
            ListaUnidades: [],
          });
          this.RegresarMensaje(data.mensaje);
        }
      })
      .catch((err) => console.error(err));
  }
  EliminarLibro(index){
    let libros = this.state.ListaLibros

    libros.splice(index,1)

    this.setState({
      ListaLibros: libros,
    });
  }

  handleFocus = (event) => event.target.select();

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;
    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando Cuerpo={"Cargando"} show={this.state.cargando} />
      );
    }

    if (this.state.showLibro == true) {
      agregar = (
        <ModalLibros
          xs={18}
          Titulo={this.state.Titulo}
          Idioma={this.state._idIdioma}
          _id={this.state._id}
          InfoNivel={this.state.InfoNivel}
          LibroSelecionado={this.state.LibroSelecionado}
          showModalLibros={this.state.showLibro}
          eventClick={this.eventClickLibros}
          op={this.state.op}
        />
      );
    }

    let libros;
    if ( this.state.ListaLibros.length > 0 ) {
      libros = 
      this.state.ListaLibros.map((libro,index) => {
        let unidades;
        if (libro.Unidades !== undefined && libro.Unidades !== null) {
          if (libro.Unidades.Unidad.length > 1) {
            unidades = libro.Unidades.Unidad.length;
          } else {
            unidades = 1;
          }
        } else {
          unidades = 0;
        }
        return (
          <tr>
            <td style={{verticalAlign: "middle", cursor: "default"}}>{libro.Nombre}</td>
            <td style={{verticalAlign: "middle", cursor: "default"}}>{unidades}</td>
            <td style={{verticalAlign: "middle", cursor: "default"}}>
            <Button color="danger" round className="pull-right" justIcon size="sm"
                onClick={() => this.EliminarLibro(index)} >
                <Close /> </Button>
              <Button color="info" round justIcon className="pull-right mr-2" size="sm"
                onClick={() => this.eventoModalEditar(libro)} >
                <Edit /> </Button>
            </td>
          </tr>
        );
      });
    } else {
      libros = (
        <tr>
          <td colSpan={2} style={{ textAlign: "center" }}>
            {"NO HAY LIBROS REGISTRADOS"}
          </td>
        </tr>
      );
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showAgregar}
          className="static-modal"
          size="lg"
          // toggle={this.cerrarModal}
          // backdrop={true}
        >
          <Card>
            <CardHeader color="info" text>
              <h4 className={classes.cardTitle}>Información general</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="RFC"
                    style={{ width: "25em" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Nombre"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Nombre,
                      name: "Nombre",
                      id: "Nombre",
                      error: this.state.errorNombre,
                      success: !this.state.errorNombre ? "false" : "true",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    style={{ width: "25em" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Descripción"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Descripcion,
                      name: "Descripcion",
                      id: "Descripcion",
                      error: this.state.errorDescripcion,
                      success: !this.state.errorDescripcion ? "false" : "true",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="Meses"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Duración de meses"
                    onChange={this.handleChange}
                    value={this.state.Meses}
                    name={"Meses"}
                    onFocus={this.handleFocus.bind(this)}
                    inputProps={{
                      min: 0,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="Meses"
                    style={{
                      width: "25em",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Orden"
                    onChange={this.handleChange}
                    value={this.state.Orden}
                    name={"Orden"}
                    error={this.state.errorOrden}
                    success={!this.state.errorOrden}
                    onFocus={this.handleFocus.bind(this)}
                    inputProps={{
                      min: 0,
                    }}
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    // fullWidth
                    className={classes.formControl}
                    size="small"
                    variant="outlined"
                    // style={{ marginBottom: "27px" }}
                  >
                    <InputLabel style={{marginTop: "10px"}}>Clase</InputLabel>
                    <Select
                      value={this.state.ClaseNivel}
                      size="small"
                      label="Clase"
                      onChange={this.handleChange}
                      name="ClaseNivel"
                      id="ClaseNivel"
                      style={{ width: "8vw", marginTop: "10px" }}
                    >
                      <MenuItem value={""} disabled>Seleccione una clase</MenuItem>
                      <MenuItem value={"Adulto"}>Adulto</MenuItem>
                      <MenuItem value={"Adolescente"}>Adolescente</MenuItem>
                      <MenuItem value={"Niño"}>Niño</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem> */}
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  {" Último nivel "}
                  <Checkbox
                    id={"UltimoNivel"}
                    checked={this.state.EsUltimo}
                    onChange={this.handleChange.bind(this)}
                    name="EsUltimo"
                  />{" "}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info" text>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <h4 className={classes.cardTitle}>
                    Libros que corresponden al nivel
                  </h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <Button
                    size="sm"
                    type="submit"
                    color="info"
                    round
                    className="pull-right mx-2"
                    onClick={() => this.NuevaUnidad()}
                  >
                    Nuevo Libro
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className="table-responsive table-wrapper-scroll-y">
                    <table className="table table-sm table-hover">
                      <thead>
                        <th>Libro</th>
                        <th>Unidades</th>
                        <th></th>
                      </thead>
                      <tbody>{libros}</tbody>
                    </table>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <div>
            <Button
              size="sm"
              type="submit"
              color="info"
              round
              className="pull-right mx-2"
              onClick={() => this.ValidarNivel()}
            >
              Guardar
            </Button>
            <Button tabIndex="1"  size="sm" className="pull-right mx-2" round
              onClick={() => this.props.eventClick("esc")}
            >
              &nbsp;Cancelar
            </Button>
          </div>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(ModalGuardarEmpresa);
