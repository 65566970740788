import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import Globales from "utils/Globales";
import { Edit, People, DoneOutline, GetApp, Close, AssignmentReturned, AddCircle, PlusOne, DoubleArrow, ArrowUpward, Clear, DoneAll, AssignmentTurnedIn } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import cookie from 'js-cookie'


class ReporteLibros extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaIdiomas: [],
      ListaAux: [],
      OpcionReportes: "",
      FechaInicio: moment().format("YYYY-MM-DD"),
      FechaFin: moment().format("YYYY-MM-DD"),
      idIdioma: "TODO",
      OpcionPeriodo: "",
      //OpcionReportes: "",

      errorFechainicio: false,
      errorFechaFin: false,
      errorPeriodo: false,
      colorNotificacion: 'success',
      colorTableRow: "white",

      //Mensaje del cargando default
      CuerpoCargando: "Cargando"
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
    
  }

  componentDidMount() {
    this.ListaGeneralIdiomas()
  }

  async ListaGeneralIdiomas() {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Prospectos/Prospectos/ListaGeneralIdiomas', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                if (data.ListaIdiomas.length > 1) {
                    this.setState({ ListaIdiomas: data.ListaIdiomas, Activo: 1, cargando: false });
                }
                else {
                    let ListaAuxEmpresas = [];
                    ListaAuxEmpresas.push(data.ListaIdiomas);
                    //console.log(ListaAux);
                    this.setState({ ListaIdiomas: ListaAuxEmpresas, Activo: 1, cargando: false });
                }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

  async ReporteExcelLibrosVendidos() {
    this.setState({cargando: true});
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

    await fetch(Globales.Url + 'Reportes/Reportes/ReporteExcelLibrosVendidos', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idIdioma: this.state.idIdioma !== "TODO" ? this.state.idIdioma : idVacio, FechaInicio: this.state.FechaInicio, FechaFin: this.state.FechaFin})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                this.DescargarPDF(data.Reporte, 0);
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

async ReporteProductos() {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Reportes/Reportes/ReporteProductos', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              this.DescargarPDF(data.Reporte, 1);
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
}

DescargarPDF(archivoBase64, int) {
  let url;
  let nombre;
  let fileName;

  switch(int){
    case 0:
      var ArchivoUTF8 = atob(archivoBase64)
      var universalBOM = "\uFEFF";
      url = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM+ArchivoUTF8)
      // url = 'data:application/csv;base64,' + archivoBase64;
      nombre = 'Reporte libros vendidos'
      fileName = `${nombre}.csv`;
      break;
    case 1:
      url = 'data:application/pdf;base64,' + archivoBase64;
      nombre = 'Reporte inventarios de productos'
      fileName = `${nombre}.pdf`;
      break;
  }

  const linkSource = `${url}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  this.setState({
    cargando: false,
    mensaje: "El archivo se generó exitosamente",
    colorNotificacion: "success",
  });
  this.showNotification();
}

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, checked } = e.target;
    
      this.setState({ [name]: value });

  }

  validarFechas(int){
    let valido = true;

    if(int == 0){
      if(this.state.FechaInicio !== "" || this.state.FechaFin !== ""){
        if(this.state.FechaFin < this.state.FechaInicio){
          valido = false;
          this.setState({ errorFechainicio: false, errorFechaFin: true, mensaje: "La fecha final no puede ser antes de la fecha de inicio", colorNotificacion: "danger"});
          this.showNotification();
        }
        else{
          valido = true;
          this.setState({errorFechainicio: false, errorFechaFin: false});
        }
      }
      else{
        valido = false;
        this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos", colorNotificacion: "danger"});
        this.showNotification();
      }
    }  

    if(valido){
      switch(int){
        case 0:
          this.ReporteExcelLibrosVendidos();
          break;
        case 1:
          this.ReporteProductos();
          break;
      }
    }
  }


  render() {
    const { 
      OpcionReportes,
     } = this.state;

    const { classes } = this.props;

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    let Titulo;
    let Contenido;
    switch(OpcionReportes){
      case "LibrosVendidos":
        Titulo = "Reporte de libros vendidos";
        Contenido = 
          <>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{fontWeight: "bold"}}>Seleccione el periodo y el idioma</div>
                <TextField
                  id="FechaInicio"
                  style={{ width: "15em", marginTop: "10px", marginRight: "10px" }}
                  variant="outlined"
                  type="date"
                  format="DD-MM-YYYY"
                  size="small"
                  label="Fecha inicio"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.FechaInicio,
                    name: "FechaInicio",
                    id: "FechaInicio",
                    error: this.state.errorFechainicio,
                    success: !this.state.errorFechainicio
                  }}
                />

                <TextField
                  id="FechaFin"
                  style={{ width: "15em", marginTop: "10px" }}
                  variant="outlined"
                  type="date"
                  format="DD-MM-YYYY"
                  size="small"
                  label="Fecha fin"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    onChange: this.handleChange.bind(this),
                    value: this.state.FechaFin,
                    name: "FechaFin",
                    id: "FechaFin",
                    error: this.state.errorFechaFin,
                    success: !this.state.errorFechaFin
                  }}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl className={classes.formControl} size="small" variant="outlined">
              <InputLabel style={{marginTop: "10px"}} id="idioma">Idioma</InputLabel>
                  <Select
                      labelId="idioma"
                      //fullWidth={true}
                      value={this.state.idIdioma}
                      onChange={this.handleChange.bind(this)}
                      name="idIdioma"
                      label="Idioma"
                      style={{ width: "26.9em", marginTop: "10px" }}
                  >

                    <MenuItem value={"TODO"}>Todos los idiomas</MenuItem>
                    {this.state.ListaIdiomas.map(idioma =>
                      <MenuItem value={idioma._id}>{(idioma.Nombre)}</MenuItem>
                    )}

                  </Select>
              </FormControl>
              <Button size="sm" type="submit" color="info" round className="ml-3 mt-2" onClick={() => this.validarFechas(0)}><GetApp/>&nbsp;Exportar a excel</Button>
            </GridItem>
          </>
        break;
      
      case "InventarioProductos":
        Titulo = "Reporte de inventarios de productos";
        Contenido = 
          <>
            <GridItem xs={12} sm={12} md={12}>
                
                <Button size="sm" type="submit" color="info" round className="ml-3 mt-3" onClick={() => this.validarFechas(1)}><GetApp/>&nbsp;Obtener reporte</Button>
            </GridItem>
          </>
        break;
    }

    //console.log(ListaAux)
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Reporte de libros</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginTop: "10px"}} id="opciones">Tipo de reporte</InputLabel>
                        <Select
                            labelId="opciones"
                            //fullWidth={true}
                            value={this.state.OpcionReportes}
                            onChange={this.handleChange.bind(this)}
                            name="OpcionReportes"
                            label="Tipo de reporte"
                            style={{ width: "26.9em", marginTop: "10px" }}
                        >

                          <MenuItem disabled={true} value={""}>Seleccione un tipo de reporte</MenuItem>
                          <MenuItem value={"InventarioProductos"}>Inventarios de productos</MenuItem>
                          <MenuItem value={"LibrosVendidos"}>Libros vendidos</MenuItem>

                        </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card hidden={this.state.OpcionReportes == ""}>
            <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>{Titulo}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {Contenido}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar grupo"}
            Cuerpo={"¿Está seguro que desea eliminar grupo?"}
            show={this.state.show}
          eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(ReporteLibros);