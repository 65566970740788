import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import ModalCargando from "../../../../components/Generales/ModalCargando";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalInfoVenta from "./ModalInfoVenta.jsx";
import SeleccionarAlumno from "../../../Generales/ComponentesEmpresas/SeleccionarAlumno.jsx"
import SeleccionarDatosFiscales from "../../../Generales/ComponentesEmpresas/SeleccionarDatosFiscales.jsx"

import ModalAsignarFolioFactura from "./ModalAsignarFolioFactura.jsx";
import ModalNuevoRecibo from "./ModalNuevoRecibo.jsx"
import ModalAsignarFolioFiscal from "./ModalAsignarFolioFiscal.jsx";
import ModalDatosFiscales from './ModalDatosFiscales';
import ModalFactura from "./ModalFactura.jsx"
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Add, ArrowDownward, Save } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import Cookies from 'js-cookie'
import { Container } from "reactstrap";

var Permisos = localStorage.getItem("Permisos") == null ? { Modulo: [] } : JSON.parse(localStorage.getItem("Permisos"))
if (Permisos.length > 1) {
  Permisos = Permisos
}
else {
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

const clientefiscalVacio = {
  _id: null,
  RFC: "",
  RazonSocial: "",
  CodigoPostal: "",
  ClaveRegimen: "",
  UsoCFDI: "",
}

const ClassFactura = {
  idAlumno: null,
  FechaFacturacion: new Date().toLocaleString('sv', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(' ', 'T'),
  Empresa: {
    _id: null,
    Calle: "RIO ELOTA #100 PTE",
    CodigoPostal: "80220",
    Colonia: "GUADALUPE",
    Estado: "Activo",
    PaginaWeb: "www.juttatesche.com.mx",
    RFC: "EIJ980819DQ0",
    Telefono: "6677124135",
    RazonSocial: "ESCUELA DE IDIOMAS JUTTA TESCHE",
    RegimenFiscal: "REGIMEN DE INCORPORACION FISCAL",
    ClaveRegimen: "601",
    Consecutivo: 0,
  },
  ClienteFiscal: {
    _id: null,
    RFC: "",
    RazonSocial: "",
    CodigoPostal: "",
    ClaveRegimen: "",
  },
  Global: null,
  MetodoDePago: 0,
  TipoComprobante: 0,
  TipoMoneda: 0,
  FormaDePago: 9,
  UsoDeCFDI: "",
  ListaConceptos: [],
  Consecutivo: 0,
  Total: 0,
  IVA: 0,
  Observaciones: "",
}
// #region STATE
class Facturacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaEmpresas: [],
      Folios: [],
      AlumnoSeleciondado: null,
      ClienteFiscal: {
        _id: null,
        RFC: "",
        RazonSocial: "",
        CodigoPostal: "",
        ClaveRegimen: "",
        UsoCFDI: "",
      },
      EmpresaFactura: { RFC: null },
      Factura: {},
      VentaSeleccionada: {},
      ListaVentas: [],
      ListaVentasFacturar: [],
      NombreAlumno: [],
      NombreFiscal: [],
      SumaTotalFacturados: {},
      ventaAux: {},
      ArrEmpresas: [],
      opcionBuscar: "Fechas",
      FolioVenta: "",
      Consecutivo: 0,
      IDvacio: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      IDEmpresa: null,
      NombreEmpresa: "",
      FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaFin: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      Total: 0,
      TotalSeleccionado: 0,
      CheckAll: false,
      checked: false,
      Disabled: false,
      cargando: false,
      mensaje: "",
      mensajePagos: "",
      br: false,
      colorNotificacion: 'success',
      colorTableRow: "white",
      errorFechainicio: false,
      errorFechaFin: false,
      errorFolioVenta: false,
      entroFiltrar: false,
      notificacion: false,
      CuerpoCargando: "Cargando",
      show: false
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    //this.handleChange = this.handleChange.bind(this);
  }
  // #endregion

  componentDidMount() {
    // console.log(this.props);
    this.obtenerEmpresasActivas();
  }

  handleCallback = (AlumnoSeleciondado) => {
    // console.log(AlumnoSeleciondado);
    this.setState({
      AlumnoSeleciondado: AlumnoSeleciondado
    }, () => {
      if (AlumnoSeleciondado && AlumnoSeleciondado != []) {
        this.ObtenerDatosFiscalesAlumno()
      }
    })
  }
  handleCallbackFiscal = async (DatosFiscales) => {
    this.state.ClienteFiscal = await this.ValidarDatosFiscales(DatosFiscales)
    this.setState({ a: "" })
  }
  async ValidarDatosFiscales(DatosFiscales) {
    let valido = true
    if (DatosFiscales) {
      //si existen los datos fiscales
      if (!DatosFiscales.ClaveRegimen && DatosFiscales.ClaveRegimen != "") {
        valido = false
      }
      if (!DatosFiscales.CodigoPostal && DatosFiscales.CodigoPostal != "") {
        valido = false
      }
      if (!DatosFiscales.RazonSocial && DatosFiscales.RazonSocial != "") {
        valido = false
      }
      if (!DatosFiscales.RFC && DatosFiscales.RFC != "") {
        valido = false
      }
    } else {
      //no existen los datos fiscales
      valido = false
    }

    if (valido) {
      return DatosFiscales
    } else {
      this.setState({ mensaje: "Cliente no valido: datos incompletos", colorNotificacion: "danger" });
      this.showNotification();
      return DatosFiscales
    }
  }

  //Función para obtener los pagos pendientes de los alumnos
  obtenerEmpresasActivas() {
    this.setState({ cargando: true })
    fetch(Globales.Url + 'Facturacion/Facturacion/ListadoEmpresas', {  //ObtenerInformacionMultipleEmpresa  //ListadoEmpresas
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
    }).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          let Empresas = []
          if (data.ListaEmpresas.length > 1) {
            Empresas = data.ListaEmpresas
          }
          else {
            let ListaAuxEmpresas = [];
            Empresas.push(data.ListaEmpresas);
          }
          this.setState({ ListaEmpresas: Empresas, Activo: 1, cargando: false });

        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }
  obtenerEmpresaFactura = (e) => {
    const { name, value } = e.target;

    this.setState({ cargando: true })
    let EmpresaFactura = this.state.ListaEmpresas.find(Emp => Emp.RFC == value)
    if (!EmpresaFactura) {
      EmpresaFactura = null
      this.setState({ mensaje: "No se encontro empressa para facturar", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true })
    } else {
      this.setState({ EmpresaFactura: EmpresaFactura, cargando: false });
    }
  }
  async obtenerConsecutivoEmpresa() {
    let Folio = false
    await fetch(Globales.Url + 'Facturacion/Facturas/ObtenerConsecutivoEmpresa', {  //ObtenerInformacionMultipleEmpresa  //ListadoEmpresas
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: this.state.EmpresaFactura._id })
    }).then(res => res.json())
      .then(async (data) => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, cargando: false });
          this.showNotification();
          Folio = false
        } else { //Filtrado exitoso
          this.state.Consecutivo = data.data
          Folio = true
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    return Folio
  }

  async ObtenerInfoMultiEmpresas(ids) {
    this.setState({ cargando: true })
    await fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerInformacionMultipleEmpresa', {  //ObtenerInformacionMultipleEmpresa  //ListadoEmpresas
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ ids: ids })
    }).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.setState({ EmpresaFactura: data.idEmpresa, cargando: false })
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  //Función para obtener las ventas por folio de venta
  obtenerVentaFolioVenta() {
    let valor = 0;

    if (this.state.FolioVenta.trim() === "") {
      this.setState({ errorFolioVenta: true, mensaje: "Proporcione un folio", colorNotificacion: "danger" })
      this.showNotification();
      valor = 1;
    }
    else { valor = 0; this.setState({ errorFolioVenta: false }) }

    if (valor == 0) {
      this.setState({ cargando: true })
      fetch(Globales.Url + 'Facturacion/Facturacion/BuscarFolios', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify(this.state)
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) { //Error en la respuesta
            this.setState({ mensaje: "Venta no disponible para factura", colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
            this.showNotification();
          } else { //Filtrado exitoso
            // 113223
            let suma = 0;
            let aux = [];
            if (!data.Folios.FolioFactura && (data.Folios.Cancelada == "false")) {
              aux = [data.Folios]

              aux.map(ventas => {
                ventas.Seleccionado = false
                suma += parseFloat(ventas.Total);
                this.ObtenerNombreAlumno(ventas);
              })

              this.setState({ Folios: aux, Total: suma, Activo: 1, cargando: false, CheckAll: false }, () => this.CheckBoxBusqueda());
            } else {
              this.setState({ mensaje: "Venta no disponible para factura", colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
              this.showNotification();
            }
          }
        })
    }
  }

  ObtenerNombreAlumno(IdAlumno) {
    this.setState({ cargando: true })
    fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerNombreAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: IdAlumno._idAlumno })
    }).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", NombreAlumno: "", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso

          let Folios = this.state.Folios
          Folios.map(folio => {
            if (JSON.stringify(folio._idAlumno) == JSON.stringify(IdAlumno._idAlumno)) {
              folio.NombreAlumno = data.NombreAlumno;
            }
          })

          this.setState({ Folios: Folios, cargando: false })
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async VentasPorAlumnoRangoFecha() {
    let valido = true

    if (this.state.AlumnoSeleciondado == null) {
      valido = false
      this.setState({ mensaje: "Seleccione un alumno", colorNotificacion: "danger" });
      this.showNotification();
    }

    if (this.state.FechaInicio == "" || this.state.FechaFin == "") {
      valido = false
      this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos de fechas", colorNotificacion: "danger" })
      this.showNotification();
    }
    else {
      this.setState({ errorFechainicio: false, errorFechaFin: false })
    }

    if (this.state.FechaInicio > this.state.FechaFin) {
      valido = false
      this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", colorNotificacion: "danger" })
      this.showNotification();
    }
    else {
      this.setState({ errorFechainicio: false, errorFechaFin: false })
    }

    if (valido) {
      this.setState({ cargando: true })

      fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerListaVentasPorAlumnoRangoFecha', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({
          _idAlumno: this.state.AlumnoSeleciondado['_id'],
          Inicio: this.state.FechaInicio,
          Fin: this.state.FechaFin,
          idEmpresa: this.state.IDEmpresa,
          Usuario: JSON.parse(Cookies.get("id")),
        })
      }
      ).then(res => res.json())
        .then(async (data) => {
          if (data.error) { //Error en la respuesta
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
            this.showNotification();
          } else { //Filtrado exitoso
            let datos = data.Folios
            let suma = 0;

            if (datos.length > 1) {
              /*Suma los totales*/
              datos.map(ventas => {
                suma += parseFloat(ventas.Total);
              })

            }
            else {
              suma += parseFloat(data.Folios.Total);
              datos = [data.Folios]
            }

            this.setState({ Folios: datos, Total: suma, Activo: 1, cargando: false, CheckAll: false }, () => this.CheckBoxBusqueda());
          }
        })
        // .catch(err => console.log(err));
        .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }
  }
  async ObtenerDatosFiscalesAlumno() {
    await fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerDatosFiscalesAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        _id: this.state.AlumnoSeleciondado._id,
      })
    }).then(res => res.json())
      .then(async (data) => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.state.ClienteFiscal = await this.ValidarDatosFiscales(data.data)
          if (this.state.ClienteFiscal != null) {
          }

        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }
  VentasPorClienteFiscal() {
    if (!this.state.ClienteFiscal) {
      this.setState({ mensaje: "Proporcione una razon social", colorNotificacion: "danger", Folios: [], });
      this.showNotification();
      return
    }
    this.setState({ cargando: true })
    fetch(Globales.Url + 'Facturacion/Facturacion/obtenerVentasPorClienteFiscal', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        _id: this.state.ClienteFiscal._id,
        Inicio: this.state.FechaInicio,
        Fin: this.state.FechaFin,
        Usuario: JSON.parse(Cookies.get("id")),
      })
    }).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          let datos = data.data
          let suma = 0;

          if (datos.length > 1) {
            /*Suma los totales*/
            datos.map(ventas => {
              suma += parseFloat(ventas.Total);
            })
          }
          else {
            suma += parseFloat(data.data.Total);
            datos = [data.data]
          }

          this.setState({ Folios: datos, Total: suma, Activo: 1, cargando: false, CheckAll: false }, () => this.CheckBoxBusqueda());
        }
      })
      .catch(err => {
        this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true })
      });
  }

  VentasListadoPorPeriodo() {
    let valor1 = 0;
    let valor2 = 0;
    let valor3 = 0;

    if (this.state.FechaInicio == "" || this.state.FechaFin == "") {
      valor1 = 1
      this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos de fechas", colorNotificacion: "danger" })
      this.showNotification();
    }
    else {
      valor1 = 0
      this.setState({ errorFechainicio: false, errorFechaFin: false })
    }

    if (this.state.FechaInicio > this.state.FechaFin) {
      valor2 = 1
      this.setState({ errorFechainicio: true, errorFechaFin: true, mensaje: "La fecha de inicio no puede ser mayor a la fecha final", colorNotificacion: "danger" })
      this.showNotification();
    }
    else {
      valor2 = 0
      this.setState({ errorFechainicio: false, errorFechaFin: false })
    }

    if (valor1 + valor2 + valor3 == 0) {
      this.setState({ cargando: true })
      fetch(Globales.Url + 'Facturacion/Facturacion/VentasListadoPorPeriodo', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({
          Inicio: this.state.FechaInicio,
          Fin: this.state.FechaFin,
          idEmpresa: this.state.IDEmpresa,
          Usuario: JSON.parse(Cookies.get("id")),
        })
      }
      ).then(res => res.json())
        .then(data => {
          // console.log(data)
          if (data.error) { //Error en la respuesta
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
            this.showNotification();
          } else { //Filtrado exitoso
            let datos = data.Folios
            let suma = 0;

            if (datos.length > 1) {
              /*Suma los totales*/
              datos.map(ventas => {
                suma += parseFloat(ventas.Total);
              })

            }
            else {
              suma += parseFloat(data.Folios.Total);
              datos = [data.Folios]
            }

            this.setState({ Folios: datos, Total: suma, Activo: 1, cargando: false, CheckAll: false }, () => this.CheckBoxBusqueda());
          }
        })
        .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
    }
  }


  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    //console.log(e.target);
    if (name == "opciones") {
      this.setState({
        [name]: value,
        opcionBuscar: value,
        FolioVenta: "",
        Folios: [],
        AlumnoSeleciondado: null,
        FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        FechaFin: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        NombreAlumno: "",
        NombreEmpresa: "",
        Total: 0,
        TotalSeleccionado: 0,
        CheckAll: false,
        SumaTotalFacturados: {}
      });
    }
    else {
      this.setState({ [name]: value });
    }
  }


  //#region CHECK BOX
  CheckBoxBusqueda() {    //Handle para los checkboxes individuales al buscar, se autoseleccionan los que no sean efectivo-----------------------------------------------------------------
    //const { checked } = e.target;
    let aux1 = [];
    let suma = 0;
    var folios = this.state.Folios.filter(folio => folio.FolioRecibo)

    folios.map(folio => {
      folio.Seleccionado = false
    })

    this.setState({
      ListaVentas: [],
      TotalSeleccionado: 0,
      Folios: folios,

    })
  }

  handleCheckAll(e) {    //Handle para el checkbox "maestro", selecciona todos los folios-----------------------------------------------------------------
    const { checked } = e.target;
    let ventas = [];
    let suma = 0;
    var folios = this.state.Folios

    if (checked) {
      folios.map(folio => {
        folio.Seleccionado = true
        suma += parseFloat(folio.Total);
      })
    } else {
      folios.map(folio => {
        folio.Seleccionado = false
      })
    }

    this.setState({
      ListaVentas: ventas,
      TotalSeleccionado: suma,
      Folios: folios,
      CheckAll: checked,
    })
  }
  handleCheckBox(e, item) {     //Handle para los checkboxes individuales----------------------------------------------------------------------------------
    const { checked } = e.target;
    var folios = this.state.Folios
    var total = this.state.TotalSeleccionado
    var checkall = false

    if (checked) {
      let Index = folios.indexOf(item);
      if (Index != -1) {
        folios[Index].Seleccionado = true

        this.ObtenerInfoMultiEmpresas([item._idEmpresa])

        total = this.state.TotalSeleccionado + parseFloat(item.Total)
        // verificar si estan seleccionados todos
        let foliosSeleccionados = folios.filter(folio => folio.Seleccionado)
        checkall = folios.length == foliosSeleccionados.length ? true : false
      }
    }  else {
      let idIndex = this.state.ListaVentas.indexOf(item);
      this.state.ListaVentas.splice(idIndex, 1);

      let Index = folios.indexOf(item);
      folios[Index].Seleccionado = false

      total = this.state.TotalSeleccionado - parseFloat(item.Total)
    }

    
    this.setState({
      TotalSeleccionado:total,
      Folios: folios,
      CheckAll: checkall,
    })
  }
  //#endregion

  //#region REGISTRAR
  ValidarModulo(modulo) {
    let valido = false;

    Permisos.map(permiso => {
      if (parseInt(permiso.NumeroModulo) == modulo) {
        valido = true
      }
    })

    return valido
  }
  async formValido() {
    let valido = true
    let folios = this.state.Folios.filter(folio => folio.Seleccionado)
    if (folios.length == 0) {
      valido = false
      this.setState({ mensaje: "Seleccione al menos una venta para facturar", colorNotificacion: "danger" })
      this.showNotification();
    }

    if (!this.state.EmpresaFactura.RFC || this.state.EmpresaFactura.RFC == "") {
      valido = false
      this.setState({ mensaje: "Seleccione una empresa", colorNotificacion: "danger" })
      this.showNotification();
    }
    return valido
  }
  async Registrar() {

    this.setState({ cargando: true })
    let passValido = await this.formValido()
    let passConsecutivo = await this.obtenerConsecutivoEmpresa()
    this.setState({ cargando: false })
    if (passValido && passConsecutivo) {
      let Factura = JSON.parse(JSON.stringify(ClassFactura))
      Factura.ClienteFiscal = this.state.ClienteFiscal != null ? this.state.ClienteFiscal : clientefiscalVacio
      Factura.Consecutivo = this.state.Consecutivo
      Factura.ListaConceptos = this.state.Folios.filter(folio => folio.Seleccionado)
      Factura.Total = this.state.TotalSeleccionado
      Factura.IVA = (this.state.TotalSeleccionado * .16).toFixed(2)
      Factura.Empresa = this.state.EmpresaFactura
      Factura.Empresa.Consecutivo = this.state.Consecutivo
      Factura.Empresa = this.state.EmpresaFactura
      Factura.idAlumno = this.state.AlumnoSeleciondado ? this.state.AlumnoSeleciondado._id : null
      this.setState({ Factura: Factura, showFacturar: true })
    }
  }
  //#endregion

  //#region EVENTOS MODAL
  RegresarFolioNvoRecibo = (Folio, mensaje) => {
    this.setState({ Folios: [...this.state.Folios, Folio], Total: this.state.Total + parseFloat(Folio['Total']), mensaje: mensaje, colorNotificacion: "success" })
    this.showNotification();
  }

  //Función para pasar datos de los alumnos desde el modal hacia la vista
  RegresarMensaje = (mensaje) => {
    this.setState({ mensaje: mensaje, colorNotificacion: "success" });
    this.showNotification();

    switch (this.state.opcionBuscar) {
      case "FolioVenta":
        this.obtenerVentaFolioVenta()
        break;
      case "Alumno":
        this.VentasPorAlumnoRangoFecha()
        break;
      case "Fechas":
        if (this.state.FechaInicio !== "" && this.state.FechaFin !== "") {
          this.VentasListadoPorPeriodo()
        }
        break;
    }
  }
  //Función para abrir el modal de buscar alumnos
  openModalInfoVenta = (Venta) => {
    this.setState({ VentaSeleccionada: Venta, showInfoVenta: true, Titulo: "Información general", op: 1 });
  }

  openModalBuscarAlumnos = () => {
    this.setState({ showAgregar: true, Titulo: "Buscar Alumnos", op: 1 });
  }

  openModalNvoRecibo = () => {
    this.setState({ showNvoRecibo: true, Titulo: "Nuevo Recibo", op: 0 });
  }
  openModalFiscal = () => {
    this.setState({ showFiscal: true, Titulo: "Asignar Folio Fiscal", op1: 0 });
  }

  openModalAsignarFactura = () => {
    if (this.state.ListaVentas.length > 0) {
      let Arrayids = this.state.ListaVentas.map(venta => {
        return venta._id
      })

      this.setState({ showAgregarFactura: true, Titulo: "Asignar Folio Factura", ArrayIdVentas: Arrayids, op: 1 });
    }
    else {
      this.setState({ mensaje: "Seleccione al menos un recibo", colorNotificacion: "danger" })
      this.showNotification();
    }
  }

  openModalPregunta = (venta) => {
    this.setState({ show: true, ventaAux: venta });
  }

  //Función que recupera valores para el modal
  eventoModalInfoVenta = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showInfoVenta: false, op: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }

  eventoModalcobranza = (ListaAlumnos, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, op: 0 })
    /* if (ListaAlumnos !== 'esc') {
      this.setState({ ListaAlumnos: ListaAlumnos, colorNotificacion: colorNotificacion })
      this.showNotification(); } */
  }

  eventoAsignarFactura = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showAgregarFactura: false, op: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }

  eventoNvoRecibo = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showNvoRecibo: false, op1: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }
  eventoModalFiscal = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showFiscal: false, op1: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }

  eventoModalPregunta = (valor) => {
    this.setState({ show: false })
    if (valor) {
      this.VentasFEliminar();
    }
  }

  eventFactura = (Exito) => {
    this.setState({ Factura: null, showFacturar: false })
    if (Exito) {
      this.props.eventClick(Exito)
    }
  }

  eventDatosFiscales = (DatosFiscales) => {
    this.setState({ ShowDatosFiscales: false })
    if (DatosFiscales) {
      let ClienteFiscal = this.state.ClienteFiscal
      ClienteFiscal = DatosFiscales
      this.setState({ ClienteFiscal: ClienteFiscal })
    }
  }
  //#endregion


  // #region RENDER
  render() {
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    // #region AGREGAR
    let agregar;
    if (this.state.showAgregarFactura == true) {
      agregar =
        <ModalAsignarFolioFactura
          Titulo={this.state.Titulo}
          showAsignarFactura={this.state.showAgregarFactura}
          eventClick={this.eventoAsignarFactura}
          op={this.state.op}
          parentCallback={this.RegresarMensaje}
          ArrayIdVentas={this.state.ArrayIdVentas}
          ArrayIdVentasVirtual={[]}
          TotalSeleccioado={this.state.TotalSeleccionado}
        />
    }
    if (this.state.showNvoRecibo == true) {
      agregar =
        <ModalNuevoRecibo
          Titulo={this.state.Titulo}
          showNvoRecibo={this.state.showNvoRecibo}
          eventClick={this.eventoNvoRecibo}
          //op={this.state.op}
          parentCallback={this.RegresarFolioNvoRecibo}
        />
    }
    if (this.state.ShowDatosFiscales) {
      agregar = (
        <ModalDatosFiscales
          idFiscal={this.state.ClienteFiscal._id}
          eventClick={this.eventDatosFiscales}
          show={this.state.ShowDatosFiscales}
        />
      )
    }
    if (this.state.showInfoVenta == true) {
      agregar =
        <ModalInfoVenta
          Titulo={this.state.Titulo}
          showInfoVenta={this.state.showInfoVenta}
          eventClick={this.eventoModalInfoVenta}
          op={this.state.op}
          VentaSeleccionada={this.state.VentaSeleccionada}
          NombreAlumno={this.state.NombreAlumno}
          parentCallback={this.RegresarMensaje}
        />
    }

    if (this.state.showFiscal == true) {
      agregar =
        <ModalAsignarFolioFiscal
          Titulo={this.state.Titulo}
          showFiscal={this.state.showFiscal}
          eventClick={this.eventoModalFiscal}
          op1={this.state.op1}
          parentCallback={this.RegresarMensaje}
        />
    }

    if (this.state.showFacturar) {
      agregar =
        <ModalFactura
          // Titulo={this.state.Titulo}
          Factura={this.state.Factura}
          show={this.state.showFacturar}
          eventClick={this.eventFactura}
        //op={this.state.op}
        // parentCallback={this.RegresarFolioNvoRecibo}
        />
    }

    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    //#endregion
    // #region LISTADO VENTAS
    let Ventas;
    if (this.state.Folios.length !== 0) {
      Ventas =
        this.state.Folios.map((Venta, index) => {
          let Aux = "Sin Especificar"
            if (Venta.RazonSocial !== undefined && Venta.NombreEmpresa !== null) {
              Aux = Venta.RazonSocial
            } else if (Venta.VentaVirtual == "false") {
              this.state.ArrEmpresas.map(empresa => {
                if (JSON.stringify(Venta._idEmpresa) == JSON.stringify(empresa._id)) {
                  Aux = empresa.RazonSocial
                }
              })
            }

            let TipoPagoNombre = Venta.TipoPago.replace(/[_]/g, " ")

            return (
              <tr key={index} style={{ cursor: "pointer" }} hover>
                <td style={{ verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(Venta)}>{Aux}</td>
                <td style={{ verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(Venta)}>{Venta.FolioRecibo}</td>
                <td style={{ verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(Venta)}>{moment(Venta.Fecha).format('DD-MM-YYYY')}</td>
                <td style={{ verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(Venta)}>{Venta.NombreAlumno !== null ? Venta.NombreAlumno : "Sin Especificar"}</td>
                <td style={{ verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(Venta)}>{TipoPagoNombre}</td>
                <td style={{ verticalAlign: "middle" }} onClick={() => this.openModalInfoVenta(Venta)}>{convertirPesos(Venta.Total)}</td>
                <td hidden={this.props.op != 0}><Checkbox onChange={(e) => this.handleCheckBox(e, Venta)} name="checkedindiv" checked={Venta.Seleccionado==true} /></td>
              </tr>
            )
        })
    }
    else {
      Ventas =
        <tr>
          <td colSpan={9} style={{ textAlign: "center" }}>NO HAY VENTAS POR FACTURAR</td>
        </tr>
    }

    //#endregion
    const { classes } = this.props;
    return (
      <div style={{ height: "100 vw" }}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Facturación</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h6>Empresa emisora</h6>
                  </GridItem>
                  <GridItem id="Empresa de Fac" xs={12} sm={12} md={5}>
                    <FormControl size="small">
                      <Select
                        fullWidth={true}
                        // value={this.state.IDEmpresa}
                        value={this.state.EmpresaFactura.RFC}
                        onChange={this.obtenerEmpresaFactura.bind(this)}
                        name="IDEmpresa"
                        //fullWidth={true}
                        variant="outlined"
                        // label="Productos"
                        style={{ width: "18.2vw" }}
                      //error={this.state.errorUsuarios}
                      //success={!this.state.errorUsuarios}
                      >
                        {this.state.ListaEmpresas.map(Empresa =>
                          <MenuItem value={Empresa.RFC}>{(Empresa.RazonSocial).toUpperCase()}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h6>Criterio de búsqueda para la(s) venta(s)</h6>
                  </GridItem>
                  <GridItem id="tipo de búsqueda" xs={12} sm={12} md={4}>
                    <FormControl fullWidth size="small">
                      <div>Tipo de búsqueda</div>
                      <Select
                        fullWidth={true}
                        value={this.state.opcionBuscar}
                        onChange={this.handleChange.bind(this)}
                        name="opciones"
                        //fullWidth={true}
                        variant="outlined"
                      // label="Productos"
                      // style={{ width: "18.2vw" }}
                      //error={this.state.errorUsuarios}
                      //success={!this.state.errorUsuarios}
                      >
                        <MenuItem disabled>Seleccione un tipo de busqueda</MenuItem>
                        <MenuItem value="FolioVenta">Folio de venta</MenuItem>
                        <MenuItem value="Alumno">Alumno</MenuItem>
                        <MenuItem value="DatosFiscales">Razon social</MenuItem>
                        <MenuItem value="Fechas">Periodo de fechas</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  {/* </GridContainer>
                <GridContainer> */}
                  <GridItem style={{ marginTop: "16px" }} hidden={this.state.opcionBuscar !== "FolioVenta"} id="Parametros de búsqueda" xs={12} sm={12} md={4}>
                    <TextField
                      id="FolioVenta"
                      // style={{ width: "15em" }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Folio"
                      error={this.state.errorFolioVenta}
                      success={!this.state.errorFolioVenta}
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.FolioVenta,
                        name: "FolioVenta",
                        id: "FolioVenta",
                      }}
                    /> &nbsp; &nbsp;&nbsp;&nbsp;
                  </GridItem>

                  {/*Opcion Alumno*/}<GridItem hidden={this.state.opcionBuscar !== "Alumno"} id="Parametros de búsqueda" xs={12} sm={12} md={4}>
                    <SeleccionarAlumno
                      fullWidth={true}
                      inactivo={false}
                      eventClick={this.handleCallback}
                    />
                  </GridItem>
                  {/*Opcion RazonSocial*/}<GridItem hidden={this.state.opcionBuscar !== "DatosFiscales"} id="Parametros de búsqueda" xs={12} sm={12} md={4}>
                    <SeleccionarDatosFiscales
                      fullWidth={true}
                      inactivo={false}
                      eventClick={this.handleCallbackFiscal}
                    />
                  </GridItem>

                  <GridItem hidden={this.state.opcionBuscar != "Alumno" && this.state.opcionBuscar != "DatosFiscales"} style={{ marginTop: "16px" }} id="Parametros de búsqueda" xs={12} sm={12} md={2}>
                    <Button size="sm" type="submit" color="info" round onClick={() => this.setState({ ShowDatosFiscales: true })}><Edit />&nbsp;Editar Datos ficales</Button>
                  </GridItem>
                  {/*Opcion Fechas*/} <GridItem style={{ marginTop: "16px" }} hidden={this.state.opcionBuscar == "FolioVenta"} id="Parametros de búsqueda" xs={12} sm={12} md={3}>
                    <TextField
                      fullWidth={true}
                      id="FechaInicio"
                      variant="outlined"
                      type="date"
                      format="DD-MM-YYYY"
                      size="small"
                      label="Fecha Inicial"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.FechaInicio,
                        name: "FechaInicio",
                        id: "FechaInicio",
                        error: this.state.errorFechainicio,
                        success: !this.state.errorFechainicio
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ marginTop: "16px" }} hidden={this.state.opcionBuscar == "FolioVenta"} id="Parametros de búsqueda" xs={12} sm={12} md={3}>
                    <TextField
                      fullWidth={true}
                      id="FechaFin"
                      variant="outlined"
                      size="small"
                      type="date"
                      format="DD-MM-YYYY"
                      label="Fecha Final"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.FechaFin,
                        name: "FechaFin",
                        id: "FechaFin",
                        error: this.state.errorFechaFin,
                        success: !this.state.errorFechaFin
                      }}
                    />
                  </GridItem>

                  <GridItem style={{ marginTop: "16px" }} id="Parametros de búsqueda" xs={12} sm={12} md={2}>
                    <Button hidden={this.state.opcionBuscar !== "Alumno"} style={{ marginTop: "8px" }} size="sm" type="submit" color="info" round onClick={() => this.VentasPorAlumnoRangoFecha()}><Search />Buscar</Button>
                    <Button hidden={this.state.opcionBuscar !== "FolioVenta"} size="sm" type="submit" color="info" round onClick={() => this.obtenerVentaFolioVenta()}><Search />Buscar</Button>
                    <Button hidden={this.state.opcionBuscar !== "DatosFiscales"} style={{ marginTop: "8px" }} size="sm" type="submit" color="info" round onClick={() => this.VentasPorClienteFiscal()}><Search />Buscar</Button>
                    <Button hidden={this.state.opcionBuscar !== "Fechas"} size="sm" type="submit" color="info" round onClick={() => this.VentasListadoPorPeriodo()}><Search />&nbsp;Buscar</Button>
                  </GridItem>

                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={18}>
            <Card>
              <CardHeader color="info" text>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={9}>
                    <h4 className={classes.cardTitle}>Lista de facturas</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <h4 class="pull-right" className={classes.cardTitle}>TOTAL: {convertirPesos(this.state.TotalSeleccionado)} &nbsp;&nbsp;</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="table-responsive-xl table-wrapper-scroll-y">
                      <table className="table table-sm table-hover">
                        <thead>
                          <th className="center">Empresa</th>
                          <th className="center">Folio de venta</th>
                          {/* <th className="center">Folio(s) de factura</th> */}
                          <th className="center">Fecha</th>
                          <th className="center">Alumno</th>
                          <th className="center">Tipo de pago</th>
                          <th className="center">Cantidad</th>
                          <th hidden={this.props.op != 0}><Checkbox onChange={this.handleCheckAll.bind(this)} name="CheckAll" checked={this.state.CheckAll} /></th>
                          <th></th>
                        </thead>
                        <tbody>
                          {Ventas}
                        </tbody>
                      </table>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer style={{
          position: "-webkit-sticky",
          position: "sticky",
          Button: 0,
        }}>
          <GridItem xs={12} sm={12} md={10}>
            <Button size="sm" type="submit" color="danger" round className="pull-right" onClick={() => this.props.eventClick(false)}><Close />&nbsp; Salir &nbsp;</Button>
            {/* <Button size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.openModalAsignarFactura()}>Asignar folio factura</Button> */}
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.Registrar()}><Save />&nbsp; Facturar &nbsp;</Button>
          </GridItem>


          <GridItem>
            {/* <Button size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.openModalAsignarFactura()}>Asignar folio factura</Button> */}
            {/* {this.ValidarModulo(50) ? <div hidden={this.state.opcionBuscar !== "Fechas"}>
              <Button size="sm" type="submit" round className="mr-2 pull-right" onClick={() => this.openModalNvoRecibo()}>Nuevo recibo</Button>
              <Button size="sm" type="submit" round className="mr-2 pull-right" onClick={() => this.openModalFiscal()}>Asignar folio fiscal</Button>
            </div> : ""} */}
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Facturar"}
            Cuerpo={"¿desea facturar a publico en general?"}
            show={this.state.show}
            eventClick={this.eventoModalPregunta}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }

  //#endregion
}

export default withStyles(headerLinksStyle)(Facturacion);