// core components/views for Admin layout

import Asistencia from "views/Modulos/Asistencia/Asistencia.jsx";
// @material-ui/icons
import { Streetview, AttachMoney, PersonAdd, List, Grain, ScatterPlot, Timeline, LinearScale, BarChart, Whatshot, MergeType, Home, Menu,WalletMembership,Contacts } from "@material-ui/icons";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [
  {
    path: "/Asistencia",
    name: "Asistencia",
    icon: List,
    component: Asistencia,
    layout: "/asistencia",
    idModulo:"10"
  }
  
]

let Permisos =localStorage.getItem("Permisos") == null ? {Modulo:[]}:JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

// console.log("🌌Permisos ", (Permisos).sort((a, b) => a.NumeroModulo - b.NumeroModulo))
var dashboardRoutesAccess = [];

for (var keyRoutes = 0; keyRoutes < dashboardRoutes.length; keyRoutes++) {
  if (Permisos.find(permiso => permiso.NumeroModulo == dashboardRoutes[keyRoutes].idModulo)) {
    dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
  }
}
export default dashboardRoutesAccess;