import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, CameraAlt, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import Autocomplete from '@material-ui/lab/Autocomplete';

class ModalDocumentos extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      _id: '',
      ListaFiltradaObjetos: [],
      ObjetoSeleccionado: {},
      NombreObjeto: "",
      Cantidad: 0,
      MarcaModelo: "",
      Caracteristicas: "",
      errorNombreObjeto: false,
      errorCantidad: false,
      errorMarcaModelo: false,
      errorCaracteristicas: false,
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      cargando: false,
      CuerpoCargando: "Cargando",
    }
    this.handleChange = this.handleChange.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick(false);
    }
  }

  componentDidMount() {
    if(this.props.op == 1){
      this.setState({
        ObjetoSeleccionado: this.props.ObjetoSeleccionado,
        NombreObjeto: this.props.ObjetoSeleccionado.Nombre,
        Cantidad: parseInt(this.props.ObjetoSeleccionado.Cantidad),
        MarcaModelo: this.props.ObjetoSeleccionado.MarcaModelo,
        Caracteristicas: this.props.ObjetoSeleccionado.Caracteristicas,
      })
    }
  }

  async FiltradoObjeto(texto) {
    //this.setState({cargando: true});
    await fetch(Globales.Url + 'Inventario/Inventario/ListaFiltradoObjeto', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({Texto: texto})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                //this.showNotification();
            } else { //Filtrado exitoso
              if(data.ListaObjetos.length > 1){
                this.setState({ListaFiltradaObjetos: data.ListaObjetos, NombreObjeto: texto})
              }
              else{
                let aux = [];
                aux.push(data.ListaObjetos);

                this.setState({ListaFiltradaObjetos: aux, NombreObjeto: texto})
              }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

CrearObjeto(){
  let valido = true;

  if(this.state.NombreObjeto == ""){
    valido = false;
    this.setState({errorNombreObjeto: true, mensaje: "Proporcione un nombre", colorNotificacion: "danger"});
    this.showNotification();
  }
  else{
    this.setState({errorNombreObjeto: false});
  }

  if(valido){
    if(Object.keys(this.state.ObjetoSeleccionado).length !== 0){
      let Objeto = {
        Caracteristicas: this.state.Caracteristicas,
        MarcaModelo: this.state.MarcaModelo,
        Nombre: this.state.NombreObjeto,
        _id: this.state.ObjetoSeleccionado._id,
      }

      this.RegistrarObjeto(Objeto);
    }
    else{
      let Objeto = {
        Caracteristicas: this.state.Caracteristicas,
        MarcaModelo: this.state.MarcaModelo,
        Nombre: this.state.NombreObjeto,
      }

      this.RegistrarObjeto(Objeto);
    }
    
  }
}

async RegistrarObjeto(Objeto) {
  this.setState({cargando: true});

  await fetch(Globales.Url + 'Inventario/Inventario/RegistrarObjeto', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Objeto: Objeto})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              this.setState({cargando: false});
              let aux = {
                Cantidad: this.state.Cantidad,
                Caracteristicas: this.state.Caracteristicas,
                IdObjeto: data.idNuevoObjeto,
                MarcaModelo: this.state.MarcaModelo,
                Nombre: this.state.NombreObjeto
              }

              this.props.eventClick(aux);
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;

    if(name == "Cantidad"){
      let cant = parseInt(value.replace(/[^0-9]/g, ''))

      this.setState({[name]: cant});
    }

    this.setState({[name]: value});
  }

  handleFocus = (event) => event.target.select();

  handleBlur = (e) => {
    const {name, value} = e.target;

    //Quitar el punto decimal del valor
    let NoEsFloat = Math.trunc( value )

    if(NoEsFloat == ""){
      this.setState({[name]: 0});
    }
    else if(isNaN(NoEsFloat)){
      this.setState({[name]: 0});
    }
    else{
      this.setState({[name]: NoEsFloat});
    }
  }

  handleChangeAutocomplete(e, newValue){

    if(newValue !== undefined && newValue !== null){
    
      //console.log(newValue)
      this.setState({
        ObjetoSeleccionado: newValue,
        MarcaModelo: newValue.MarcaModelo !== undefined && newValue.MarcaModelo !== null ? newValue.MarcaModelo : "",
        Caracteristicas: newValue.Caracteristicas !== undefined && newValue.Caracteristicas !== null ? newValue.Caracteristicas : "",
      });
    }
  }

  InputChange(e, newInputValue){

    if(newInputValue.length > 0){
      this.FiltradoObjeto(newInputValue);
    }

    this.setState({NombreObjeto: newInputValue});
  }

  cerrarModal = () => {
    this.props.eventClick(false)
  }
 
  render() {
    const { classes } = this.props;
    //console.log(this.state.ObjetoSeleccionado)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showAgregarObjeto}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">Información del objeto</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <Autocomplete
                    freeSolo={true}
                    id="NombreObjeto"
                    noOptionsText={'No hay opciones'}
                    style={{ maxWidth: "32.7em" }}
                    disableClearable
                    value={this.state.ObjetoSeleccionado}
                    inputValue={this.state.NombreObjeto}
                    onChange={this.handleChangeAutocomplete.bind(this)}
                    onInputChange={this.InputChange.bind(this)}
                    options={this.state.ListaFiltradaObjetos}
                    getOptionLabel={(option) => option.Nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nombre del objeto"
                        margin="normal"
                        size="small"
                        variant="outlined"
                        InputProps={{ 
                          ...params.InputProps, 
                          type: 'search',
                          name: "NombreObjeto",
                          id: "NombreObjeto",
                          error: this.state.errorNombreObjeto,
                          success: !this.state.errorNombreObjeto
                        }}
                      />
                    )}
                  />
                  <TextField
                    id="Cantidad"
                    style={{ width: "32.7em"}}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Cantidad"
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        value: this.state.Cantidad,
                        name: "Cantidad",
                        id: "Cantidad",
                        error: this.state.errorCantidad,
                        success: !this.state.errorCantidad
                    }}
                />
                <TextField
                    id="MarcaModelo"
                    style={{ width: "32.7em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Marca/Modelo"
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.MarcaModelo,
                        name: "MarcaModelo",
                        id: "MarcaModelo",
                        error: this.state.errorMarcaModelo,
                        success: !this.state.errorMarcaModelo
                    }}
                />
                <TextField
                    id="Caracteristicas"
                    style={{ width: "32.7em", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Caracteristicas"
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.Caracteristicas,
                        name: "Caracteristicas",
                        id: "Caracteristicas",
                        error: this.state.errorCaracteristicas,
                        success: !this.state.errorCaracteristicas
                    }}
                />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button tabIndex="1" size='sm' color="info" className='pull-right ml-2 mt-3' round onClick={() => this.CrearObjeto()}><Save/>&nbsp;Guardar</Button>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick(false)}><Clear/>&nbsp;Cerrar</Button>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalDocumentos);