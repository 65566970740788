import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, CameraAlt, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'

class ModalDocumentos extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      _id: '',
      ListaConstancias: [],
      constanciaSeleccionada: "",
      ListaIdiomas: [],
      IdiomaSeleccionado: "",
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      errorConstancia: false,
      errorIdioma: false,
      cargando: false,
      CuerpoCargando: "Cargando",
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    this.ObtenerListaConstancias();
    this.ListaGeneralIdiomas();
  }

  async ObtenerListaConstancias() {
    this.setState({cargando: true});

    await fetch(Globales.Url + 'Reportes/Reportes/ObtenerListaConstancias', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({Texto: "", Clase: "Alumnos"})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log("API ", data.ListaConstancias);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                if(data.ListaConstancias.length > 1){
                  let aux = []
                  let index;
                  if(this.props.EstadoAlumno == "Activo"){
                    aux = data.ListaConstancias.filter((el) => el.Nombre.toLowerCase().includes("inactivo"));
                    index = data.ListaConstancias.indexOf(aux[0])
                    
                    if(index !== -1){
                      data.ListaConstancias.splice(index, 1)
                    }
                  }

                  this.setState({ListaConstancias: data.ListaConstancias, cargando: false});
                }
                else{
                  let aux = [];
                  aux.push(data.ListaConstancias);

                  if(this.props.EstadoAlumno == "Activo"){
                    aux = data.ListaConstancias.filter((el) => el.Nombre.toLowerCase().includes("inactivo"));

                    if(aux.length !== 0){
                      this.setState({ListaConstancias: aux, cargando: false});
                    }
                  }
                  else{
                    this.setState({ListaConstancias: aux, cargando: false});
                  }
                }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  async ListaGeneralIdiomas() {
    this.setState({cargando: true});

    await fetch(Globales.Url + 'Colaboradores/Colaboradores/ListaGeneralIdiomas', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                if(data.ListaIdiomas.Idioma.length > 1){
                  this.setState({ListaIdiomas: data.ListaIdiomas.Idioma, cargando: false});
                }
                else{
                  let aux = [];
                  aux.push(data.ListaIdiomas.Idioma);

                  this.setState({ListaIdiomas: aux, cargando: false});
                }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({[name]: value});
  }

  verificarCampos(){
    let valido = true;

    if(this.state.constanciaSeleccionada == ""){
      valido = false;
      this.setState({errorConstancia: true});
    }
    else{
      this.setState({errorConstancia: false});
    }

    if(this.state.IdiomaSeleccionado == ""){
      valido = false;
      this.setState({errorIdioma: true});
    }
    else{
      this.setState({errorIdioma: false});
    }

    if(valido){
      this.props.eventClick(this.state.IdiomaSeleccionado, this.state.constanciaSeleccionada);
    }
    else{
      this.setState({mensaje: "Complete los campos", colorNotificacion: "danger"});
      this.showNotification();
    }
  }

  cerrarModal = () => {
    this.props.eventClick(false)
  }
 
  render() {
    const { classes } = this.props;
    //console.log(this.state._id)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    //console.log(this.state.ListaConstancias)
    //console.log(this.state.ListaIdiomas)
    return (
      <div>
        <Modal
          isOpen={this.props.showModalTipoConstancia}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="constancia">Tipo de constancia</InputLabel>
                      <Select
                          labelId="constancia"
                          //fullWidth={true}
                          value={this.state.constanciaSeleccionada}
                          onChange={this.handleChange.bind(this)}
                          name="constanciaSeleccionada"
                          label="Tipo de constancia"
                          style={{ width: "28.5em", marginTop: "10px" }}
                          error={this.state.errorConstancia}
                          success={!this.state.errorConstancia}
                      >
                        <MenuItem disabled value="">Seleccione un tipo de constancia</MenuItem>
                          {this.state.ListaConstancias.map(constancia =>
                            <MenuItem value={constancia._id}>{(constancia.Nombre)}</MenuItem>
                          )}
                      </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="idioma">idioma</InputLabel>
                      <Select
                          labelId="idioma"
                          //fullWidth={true}
                          value={this.state.IdiomaSeleccionado}
                          onChange={this.handleChange.bind(this)}
                          name="IdiomaSeleccionado"
                          label="idioma"
                          style={{ width: "28.5em", marginTop: "10px" }}
                          error={this.state.errorIdioma}
                          success={!this.state.errorIdioma}
                      >
                        <MenuItem disabled value="">Seleccione un idioma</MenuItem>
                          {this.state.ListaIdiomas.map(idioma =>
                            <MenuItem value={idioma._id}>{(idioma.Nombre)}</MenuItem>
                          )}
                      </Select>
                  </FormControl>
                </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                  <Button color="info" tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.verificarCampos()}><Save/>&nbsp;Generar</Button>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick(false)}><Clear/>&nbsp;Cerrar</Button>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>     
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalDocumentos);