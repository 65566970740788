import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, Close, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'

class ModalSeguimientoInfo extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      ContactoProspecto: {},
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: ""
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    if(this.props.op == 1){
      this.setState({
        ContactoProspecto: this.props.ContactoProspecto
      })
    }
  }

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  /* RegresarObjContacto() {
    this.setState({ op1: 1 });
    this.props.parentCallback(objeto);
    this.props.eventClick("esc");
  } */

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      aviso: ""
    });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;
    //console.log(this.state.ContactoProspecto)

    return (
      <div>
        <Modal
          isOpen={this.props.showSeguimientoInfo}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4}>
                  <div className="my-2">FECHA</div>
                  <div className="my-2">TIPO DE CONTACTO</div>
                  <div className="my-2">ASUNTO</div>
                  <div className="my-2">COMENTARIOS</div>
                </GridItem>
                <GridItem xs={6}>
                  <div style={{fontWeight: "bold"}} className="my-2">{this.state.ContactoProspecto.Fecha}</div>
                  <div style={{fontWeight: "bold"}} className="my-2">{this.state.ContactoProspecto.TipoContacto}</div>
                  <div style={{fontWeight: "bold"}} className="my-2">{this.state.ContactoProspecto.Asunto}</div>
                  <div style={{fontWeight: "bold"}} className="my-2">{this.state.ContactoProspecto.Comentarios}</div>
                </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick("esc")}><Close/>&nbsp;Cancelar</Button>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>     
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalSeguimientoInfo);