import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import cookie from 'js-cookie'

class ModalSeguimiento extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      _id: '',
      MedioContacto: "",
      MotivoCantacto: "",
      Comentarios: "",
      ObjContacto: {},
      disabled:false,
      errorMedioContacto: false,
      errorMotivoCantacto: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton:false,
      NombreGuardado:'',
      SubcategoriaHabilitada:false,
      aviso: ""
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    //Verificar si es editar

    let IDcookie = JSON.parse(cookie.get('id'));
    this.setState({_id: IDcookie})
  }

  CrearObjContacto(){
    let valor = 0;

    if(this.state.MedioContacto == "" || this.state.MotivoCantacto == ""){
      valor = 1;
      this.setState({errorMedioContacto: true, errorMotivoCantacto: true, mensaje: "Complete los campos requeridos", colorNotificacion: "danger"})
      this.showNotification();
    }
    else{
      valor = 0;
      this.setState({errorMedioContacto: false, errorMotivoCantacto: false})
    }

    if(valor == 0){
    this.setState({
      ObjContacto: {
        Asunto: this.state.MotivoCantacto,
        Comentarios: this.state.Comentarios,
        Fecha: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        TipoContacto: this.state.MedioContacto
      }
    }, () => this.RegresarObjContacto(this.state.ObjContacto))
    }
  }

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarObjContacto(objeto) {
    this.setState({ op1: 1 });
    this.props.parentCallback(objeto);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      aviso: ""
    });
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }
 
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.showSeguimiento}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
              <FormControl className={classes.formControl} size="small" variant="outlined">
              <InputLabel style={{marginTop: "10px"}} id="Mediolabel">Medio de contacto con el prospecto</InputLabel>
                  <Select
                      labelId="MedioContacto"
                      value={this.state.MedioContacto}
                      onChange={this.handleChange.bind(this)}
                      name="MedioContacto"
                      //fullWidth={true}
                      label="Medio de contacto con el prospecto"
                      style={{ width: "23.7vw", marginTop: "10px" }}
                      error={this.state.errorMedioContacto}
                      success={!this.state.errorMedioContacto}
                  >
                    <MenuItem disabled>Seleccione un medio</MenuItem>
                      <MenuItem value={"Correo"}>Correo</MenuItem>
                      <MenuItem value={"Personal"}>Personal</MenuItem>
                      <MenuItem value={"Teléfono"}>Teléfono</MenuItem>
                  </Select>
                </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="MotivoCantacto"
                    style={{ width: "23.7vw", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Motivo del contacto con el prospecto"
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.MotivoCantacto,
                        name: "MotivoCantacto",
                        id: "MotivoCantacto",
                        error: this.state.errorMotivoCantacto,
                        success: !this.state.errorMotivoCantacto
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="Comentarios"
                    style={{ width: "23.7vw", marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Comentarios"
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.Comentarios,
                        name: "Comentarios",
                        id: "Comentarios",
                        //error: this.state.errorLugarEstudio,
                        //success: !this.state.errorLugarEstudio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Button tabIndex="0" id="btnGuardar" size='sm' color='info' className='pull-right mt-3' round onClick={() => this.CrearObjContacto()}><Save/>&nbsp;Guardar</Button>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cancelar</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>     
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalSeguimiento);