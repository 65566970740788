import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, CameraAlt, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import logoEstadoCuenta from 'assets/img/bill.png'
import logoHistorialAcademico from 'assets/img/catalog.png'
import logoReglamento from 'assets/img/compliant.png'
import logoConstancia from 'assets/img/contract.png'
import ModalTipoConstancia from './ModalTipoConstancia.jsx'

class ModalDocumentos extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      _id: '',
      ColaboradorSeleccionado: "",
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      CuerpoCargando: "Cargando",
      cargando: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    // console.log(this.props)
    this.setState({ColaboradorSeleccionado: this.props.ColaboradorSeleccionado})
  }

// async ReglamentoEscolar(tipo, ididioma) {
//   this.setState({cargando: true});

//   await fetch(Globales.Url + 'Reportes/Reportes/ReglamentoEscolar', {
//       method: 'POST',
//       headers: {
//           Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
//       }
//       , body: JSON.stringify({idAlumno: this.state.AlumnoSeleccionado._id, Tipo: tipo, idIdioma: ididioma})
//   }
//   ).then(res => res.json())
//       .then(data => {
//           //console.log(data);
//           if (data.error) { //Error en la respuesta
//               this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
//               this.showNotification();
//           } else { //Filtrado exitoso
//             this.openPdf(data.ReporteHistorial)
//             this.setState({ cargando: false });
//               // this.DescargarPDF(data.ReporteHistorial,2);
//           }
//       })
//   .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
// }

async ConstanciaMaestros(idTipo) {
  this.setState({cargando: true});

  await fetch(Globales.Url + 'Reportes/Reportes/ConstanciaMaestros', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idMaestro: this.state.ColaboradorSeleccionado, idTipo: idTipo})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              this.openPdf(data.ReporteHistorial)
              this.setState({ cargando: false });
              // this.DescargarPDF(data.ReporteHistorial,3);
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

openPdf(basePdf) {
    let byteCharacters = atob(basePdf);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: 'application/pdf;base64' });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }  

DescargarPDF(archivoBase64, int) {
  let nombre;
  let url;
  let fileName;
  if(int == 0){
    url = 'data:application/pdf;base64,' + archivoBase64;
    nombre = 'Reporte estado de cuenta alumno'
    fileName = `${nombre}.pdf`;
  }
  else if(int == 1){
    url = 'data:application/pdf;base64,' + archivoBase64;
    nombre = 'Reporte historial academico'
    fileName = `${nombre}.pdf`;
  }
  else if(int == 2){
    url = 'data:application/pdf;base64,' + archivoBase64;
    nombre = 'Reglamento escolar'
    fileName = `${nombre}.pdf`;
  }
  else if(int == 3){
    url = 'data:application/pdf;base64,' + archivoBase64;
    nombre = 'Constancia de estudios alumno'
    fileName = `${nombre}.pdf`;
  }
  
  const linkSource = `${url}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  this.setState({
    cargando: false,
    mensaje: "El archivo se generó exitosamente",
    colorNotificacion: "success",
  });
  this.showNotification();
}

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({[name]: value});
  }

  openModalTipoConstancia = () => {
    this.setState({showModalTipoConstancia: true})
  }

  eventoModalTipoConstancia = (valor1) => {
    this.setState({showModalTipoConstancia: false})
    if(valor1 !== false){
      this.ConstanciaMaestros(valor1);
    }
  }
 
  cerrarModal = () => {
    this.props.eventClick(false)
  }
  
  render() {
    const { classes } = this.props;
    //console.log(this.state._id)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    if(this.state.showModalTipoConstancia == true){
      agregar = (
        <ModalTipoConstancia
          Titulo={"Impresión de constancias"}
          showModalTipoConstancia={this.state.showModalTipoConstancia}
          eventClick={this.eventoModalTipoConstancia}
          EstadoAlumno={this.props.EstadoAlumno}
        />
      )
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showModalDocumentos}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    {/* <GridItem xs={12} sm={12} md={6}>
                      <Card>
                        <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                          <center><h5>Estado de cuenta</h5></center>
                        </CardHeader>
                        <div class="hovereffect">
                          <a style={{ cursor: "pointer" }} onClick={() => this.EstadodeCuentaAlumno(this.state.AlumnoSeleccionado._id)}>
                          <CardBody   >
                            <center><img class="img-responsive" width='45%' src={logoEstadoCuenta} alt="" /></center>
                            <div class="overlay"></div>
                          </CardBody>
                          </a>
                        </div>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Card>
                        <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                          <center><h5>Historial academico</h5></center>
                        </CardHeader>
                        <div class="hovereffect">
                          <a style={{ cursor: "pointer" }} onClick={() => this.ReporteHistorialAcademico(this.state.AlumnoSeleccionado._id)}>
                          <CardBody   >
                            <center><img class="img-responsive" width='45%' src={logoHistorialAcademico} alt="" /></center>
                            <div class="overlay"></div>
                          </CardBody>
                          </a>
                        </div>
                      </Card>
                    </GridItem> */}
                    {/* <GridItem xs={12} sm={12} md={6}>
                      <Card>
                        <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                          <center><h5>Reglamentos</h5></center>
                        </CardHeader>
                        <div class="hovereffect">
                          <a style={{ cursor: "pointer" }} onClick={() => this.openModalTipoReglamento()}>
                          <CardBody   >
                            <center><img class="img-responsive" width='45%' src={logoReglamento} alt="" /></center>
                            <div class="overlay"></div>
                          </CardBody>
                          </a>
                        </div>
                      </Card>
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={6}>
                      <Card>
                        <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                          <center><h5>Constancia</h5></center>
                        </CardHeader>
                        <div class="hovereffect">
                          <a style={{ cursor: "pointer" }} onClick={() => this.openModalTipoConstancia()}>
                          <CardBody   >
                            <center><img class="img-responsive" width='45%' src={logoConstancia} alt="" /></center>
                            <div class="overlay"></div>
                          </CardBody>
                          </a>
                        </div>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick(false)}><Clear/>&nbsp;Cerrar</Button>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>     
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalDocumentos);