import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
// import { View, Image, StyleSheet } from 'react-native'
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, Block, ArrowForwardIos, Add, Close } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import ModalPregunta from "components/Generales/ModalPregunta";
import SeleccionarIdioma from "../../../views/Generales/ComponentesEmpresas/SeleccionarIdioma.jsx"
import SeleccionarAula from "../../../views/Generales/ComponentesEmpresas/SeleccionarAula.jsx"
import ModalBuscarAlumnos from "components/Generales/ModalBuscarAlumnos";
import ModalBuscarMaestros from "components/Generales/ModalBuscarMaestros";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ModalCrearProspectos from "../Prospectos/ModalCrearProspectos"

// var moment = require('moment')
// let dateNow = moment().format('YYYY-MM-DDTHH:MM:SS')

// const ClassAsesoria = {
//   Estado: "Agendada",
//   Fecha: new Date(),
//   Modalidad: "",
//   MotivoCita:"",
//   MotivosCancelacion:"",
//   NombreAlumno:"",
//   NombreAula:"",
//   NombreIdioma:"",
//   NombreMaestro:"",
//   _id: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   _idAlumno: null,
//   _idAula: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
//   _idIdioma: null,
//   _idMaestro: null,
// }


class ModalInfoVenta extends Component {

  constructor(props, context) {
    super(props, context);
    // this.escFunction = this.escFunction.bind(this);
    this.state = {
      ClassAsesoria: {
        Estado: "Agendada",
        Fecha: new Date(),
        Modalidad: "",
        MotivoCita:"",
        MotivosCancelacion:"",
        NombreAlumno:"",
        NombreAula:"",
        NombreIdioma:"",
        NombreMaestro:"",
        _id: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
        _idAlumno: null,
        _idAula: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
        _idIdioma: null,
        _idMaestro: null,
      },

      Metodo:"RegistrarAsesoria",
      // _id: null,
      Fecha: moment().format("YYYY-MM-DD HH:mm"),
      Aula: "null",
      Idioma: "null",
      Maestro: "null",
      NombreMaestro:"",
      Alumno: "null",
      NombreAlumno:"",
      Modalidad: "null",
      AqueViene: "null",


      //banderas
      showAlumnos: false,
      showMaestros:false,
      BuscarProspectos: false,

      IncluirInactivos:false,
      // notificación
      br: false,
      mensaje:"",
      ColorNotificacion:"",

      ListaAlumnos: [],
      ListaMaestros:[],
      ListaIdiomas:[],
      ListaAulas:[],
      cargando: false,
      CuerpoCargando: "Cargando",

    }
    
    this.EventoSeleccionarAula = this.EventoSeleccionarAula.bind(this);
    this.EventoSeleccionarIdioma = this.EventoSeleccionarIdioma.bind(this);
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.cerrarModales = this.cerrarModales.bind(this);
    this.eventoclickProspeto = this.eventoclickProspeto.bind(this)
    
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    if(this.props.op == 1){
      //console.log(this.props.cita)
      this.setState({
        _id: this.props.cita._id,
        Fecha: moment(this.props.cita.Fecha).format("YYYY-MM-DD HH:mm"),
        Alumno: {_id: this.props.cita._idAlumno, NombreCompleto: this.props.cita.NombreAlumno,},
        Maestro: {_id: this.props.cita._idMaestro, NombreCompleto: this.props.cita.NombreMaestro,},
        Modalidad: this.props.cita.Modalidad,
        AqueViene: this.props.cita.MotivoCita,

      },()=>{
        this.ListarIdiomas()
        this.ListarAulas()
      })

    }else{
      this.ListarIdiomas()
      this.ListarAulas()
      this.setState({
        _id: {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'},
      })

    }
  }

  //Obtener listado de Alumnos---------------------------------------------------------------------------------------------------------------------------------------------------------

  ListarAulas() {
    fetch(Globales.Url + 'Alumnos/Alumnos/ListaGeneralAulas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Filtrado exitoso
          var ListaAulas = []
          if(data.Aulas.length > 1){
            ListaAulas = data.Aulas;
          }
          else{
            ListaAulas.push(data.Aulas);
          }

          this.setState({ ListaAulas: ListaAulas }, () => {
            if(this.props.op == 1){
              this.setState({
                Aula: JSON.stringify(this.props.cita._idAula),
              })
            }
          })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }

  ListarIdiomas() {
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaGeneralIdiomas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Filtrado exitoso
          var ListaIdiomas = []
          if(data.data.length > 1){
            ListaIdiomas = data.data;
          }
          else{
            ListaIdiomas.push(data.data);
          }
          
          this.setState({ ListaIdiomas: ListaIdiomas },()=>{
            if(this.props.op == 1){
              this.setState({ Idioma: JSON.stringify(this.props.cita._idIdioma) })
            }
            
          })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }
  ListaMaestrosBusquedaFiltrada(texto) {
    fetch(Globales.Url + 'Alumnos/Alumnos/ListaMaestrosBusquedaFiltrada', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Nombre: (texto).trim() })
    }
    ).then(res => res.json())
      .then(data => {

        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje });
        } else { //Filtrado exitoso
          if (data.ListaMaestros.length > 0) {

            this.setState({ ListaMaestros: data.ListaMaestros }, () => {
              if ( JSON.stringify(data.ListaMaestros[0]) == JSON.stringify(this.props.cita._idMaestro) ) {
                this.setState({ 
                  Maestro: data.ListaMaestros[0],
                  NombreMaestro: data.ListaMaestros[0].NombreCompleto,
                 })
              }
            })
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }
  ListaAlumnosBusquedaFiltrada(texto) {
    let MetodoURL = 'Alumnos/Alumnos/ListaAlumnosBusquedaFiltrada'
    // let MetodoURL = 'Prospectos/Prospectos/ListaProspectosBusquedaFiltrada'
    fetch(Globales.Url + MetodoURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Nombre: (texto).trim(),IncluirInactivos: this.state.IncluirInactivos })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje });
        } else { //Filtrado exitoso
          if (data.ListaAlumnos.length > 0) {
            this.setState({ ListaAlumnos: data.ListaAlumnos }, () => {
              if ( JSON.stringify(data.ListaAlumnos[0]) == JSON.stringify(this.props.cita._idMaestro) ) {
                this.setState({ 
                  Alumno: data.ListaAlumnos[0],
                  NombreAlumno: data.ListaAlumnos[0].NombreCompleto,
                 })
              }
            })
          }
          else{
            let aux = [];
            aux.push(data.ListaAlumnos)
            this.setState({ ListaAlumnos: aux }, () => {
              if ( JSON.stringify(data.ListaAlumnos) == JSON.stringify(this.props.cita._idMaestro) ) {
                this.setState({ 
                  Alumno: data.ListaAlumnos,
                  NombreAlumno: data.ListaAlumnos.NombreCompleto,
                 })
              }
            })
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }
  ListaProspectosBusquedaFiltrada(texto) {
    let MetodoURL = 'Prospectos/Prospectos/ListaProspectosBusquedaFiltrada'
    fetch(Globales.Url + MetodoURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Texto: (texto).trim() })
    }
    ).then(res => res.json())
      .then(data => {

        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje });
        } else { //Filtrado exitoso
          if (data.ListaProspectos.length > 0) {
            
            this.setState({ ListaAlumnos: data.ListaProspectos }, () => {
              if ( JSON.stringify(data.ListaProspectos[0]) == JSON.stringify(this.props.cita._idMaestro) ) {
                this.setState({ 
                  Alumno: data.ListaProspectos[0],
                  NombreAlumno: data.ListaProspectos[0].NombreCompleto,
                 })
              }
            })
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }
  obtenerPagos(_idAlumno) {
    this.setState({ ListaPagos: [], cargando: true })

    fetch(Globales.Url + 'Cobranza/Cobranza/ListadoPagos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: _idAlumno })
    }
    ).then(res => res.json())
      .then(data => {
        // console.table(data)
        if (data.error) { //Error en la respuesta
          this.setState({ cargando: false });
        } else { //Filtrado exitoso
          if(!(data.ListaPagos.length >= 1)){
            data.ListaPagos = [data.ListaPagos]
          }
          let adeudos = data.ListaPagos.filter((pago) => new Date(pago.FechaPago) > new Date() )
          if(adeudos.length >= 2){
            this.setState({ mensaje: "El alumno tiene adeudos pendientes", ColorNotificacion: 'warning' });
            this.showNotification();
          }

          this.setState({ cargando: false });
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true})
    });;
  }
  
  FormValido(){
    var valido = true
    var cita = this.state.ClassAsesoria

    if(new Date(this.state.Fecha) <= new Date()){
      valido = false
      this.setState({ mensaje: "Seleccione una fecha valida", ColorNotificacion: 'danger' });
      this.showNotification();
    }else{
      if(new Date(this.state.Fecha) <= new Date()){
        valido = false
        this.setState({ mensaje: "Seleccione una fecha valida", ColorNotificacion: 'danger' });
        this.showNotification();
      }
      if(this.state.Alumno == "null"){
        valido = false
        this.setState({ mensaje: "Seleccione un alumno ", ColorNotificacion: 'danger' });
        this.showNotification();
      }
      if(this.state.Idioma == "null"){
        valido = false
        this.setState({ mensaje: "Seleccione un idioma ", ColorNotificacion: 'danger' });
        this.showNotification();
      }
      if(this.state.Modalidad == "null"){
        valido = false
        this.setState({ mensaje: "Seleccione una modalidad ", ColorNotificacion: 'danger' });
        this.showNotification();
      }
      if(this.state.Modalidad == "Presencial"){
        if(this.state.Aula == "null"){
          valido = false
          this.setState({ mensaje: "Seleccione un aula ", ColorNotificacion: 'danger' });
          this.showNotification();
        }
      }
      if(this.state.AqueViene == "null"){
        valido = false
        this.setState({ mensaje: "Seleccione Motivo de la cita", ColorNotificacion: 'danger' });
        this.showNotification();
      }
      if(this.state.Maestro == "null"){
        valido = false
        this.setState({ mensaje: "Seleccione un maestro", ColorNotificacion: 'danger' });
        this.showNotification();
      }
    }

    if(valido){

      
      if(this.props.op == 1){
        cita["_id"] = this.state._id
      }else{
        cita["_id"] = {_increment: '0', _machine: '0', _pid: '0', _timestamp: '0'}
      }

      cita._idAlumno = this.state.Alumno._id
      cita.Fecha = new Date(this.state.Fecha)
      cita.NombreAlumno = this.state.Alumno.NombreCompleto
      cita.MotivoCita = this.state.AqueViene
      cita.Modalidad = this.state.Modalidad
      cita._idMaestro = this.state.Maestro._id
      cita.NombreMaestro = this.state.Maestro.NombreCompleto
      cita._idIdioma = JSON.parse(this.state.Idioma)
      
      if(this.state.Modalidad == "Presencial"){
        cita._idAula = JSON.parse(this.state.Aula)
      }else{
        cita._idAula = this.state.ListaAulas[0]._id
      }

      this.GuardarCita(cita)
    }
  }
  GuardarCita(cita) {
    fetch(Globales.Url + 'Citas/Citas/RegistrarAsesoria', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        Cita: cita,
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, ColorNotificacion: 'danger', InfoAlumno: [] });
          // this.setState({ mensaje: "El alumno ya tiene 2 asesorias en la semana", ColorNotificacion: 'danger', InfoAlumno: [] });
          this.showNotification();
        } else { //Filtrado exitoso
          this.props.eventClick('Cita registrada exitosamente')
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));;
  }

  MandarMensaje(mensaje) {
    this.setState({ mensaje: mensaje, op: 1 });
    this.props.eventClick('esc');
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  OpenModalAbonos(Abonos) {
    this.setState({
      showAbonos: true,
      Abonos: Abonos
    })
  }

  EventoModalAbono() {
    this.setState({
      showAbonos: false,
      Abonos: []
    })
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  eventoModalEliminar(Exito) {
    this.setState({
      show: false,
    });
    if (Exito) {
      this.GuardarCita('Cancelada');
    }
  }

  EventoSeleccionarIdioma(idIdioma) {
    this.setState({ Idioma: idIdioma });
  }
  EventoSeleccionarAula(Aula) {
    this.setState({ Aula: Aula });
  }
  handleCallback = (AlumnoSeleciondado) =>{
    this.setState({Alumno: AlumnoSeleciondado, showAlumnos:false})
  }
  handleCallbackMaestros = (maestro) =>{
    this.setState({Maestro: maestro, showMaestros:false})
  }
  cerrarModales(){
    this.setState({showMaestros:false, showAlumnos:false})
  }
  eventoclickProspeto(Prospecto){
    this.setState({ showProspecto: false })
    if (Prospecto != 'esc') {
      this.setState({
        Alumno: { _id: Prospecto._idAlumno, NombreCompleto: Prospecto.NombreCompleto, },
      })
        // this.showNotification();
    }
  }
  RegresarMensaje = (mensaje) => {
    this.setState({ 
      mensaje: mensaje, 
      colorNotificacion: "success" ,
    });
    // this.showNotification();
}
  handleChangeAutocompleteMaestro(e, newValue){
    if(newValue !== undefined && newValue !== null){
      this.setState({Maestro: newValue})
    }
  }
  InputChangeMaestro(e, newInputValue){
      this.setState({NombreMaestro: newInputValue});
      this.ListaMaestrosBusquedaFiltrada(newInputValue);
  }
  handleChangeAutocompleteAlumno(e, newValue){
    if(newValue !== undefined && newValue !== null){
      this.setState({Alumno: newValue}, () => {
        // console.table(newValue)
        this.obtenerPagos(newValue._id)
      })
    }
  }
  InputChangeAlumno(e, newInputValue){
      this.setState({NombreAlumno: newInputValue});
      if(this.state.BuscarProspectos){
        this.ListaProspectosBusquedaFiltrada(newInputValue)
      }else{
        this.ListaAlumnosBusquedaFiltrada(newInputValue);
      }
  }

  cerrarModal = () => {
    this.props.eventClick('esc')
  }
  
  render() {
    const { classes } = this.props;

    let agregar

    if (this.state.show == true) {
      agregar = (
        <ModalPregunta
          Titulo={"Cancelar cita"}
          Cuerpo={"¿Está seguro que desea cancelar la cita?"}
          show={this.state.show}
          eventClick={this.eventoModalEliminar}
        />
      );
    }
    
    if (this.state.showProspecto == true) {
      agregar = (
        <ModalCrearProspectos
        Titulo={"Registrar prospecto"}
        showEditarProspecto={this.state.showProspecto}
        eventClick={this.eventoclickProspeto}
        op={0}
        // _id={this.state._id}
        parentCallback={this.RegresarMensaje}
        />
      );
    }

    if (this.state.cargando == true) {
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    // console.log(this.state.Fecha)
    return (
      <div>
        <Modal isOpen={this.props.show} className="static-modal" size="md" toggle={this.cerrarModal} backdrop={true} style={{ maxWidth: "650px", width: "100%" }}>
          <Card >
            <CardHeader color={"info"}>
              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} md={10}>
                <h4>{this.props.op == 1 ? "Actualizar cita" :
                  "Programar cita"}</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <table className="col-xs-12 col-sm-12 col-md-12">
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold"}}> Fecha: </td>
                    <td colSpan={0}>
                      <TextField
                        InputLabelProps={{
                          // fullWidth:true
                            // shrink: true,
                        }}
                        inputProps={{
                          //required: true,
                          onChange: this.handleChange.bind(this),
                          value: this.state.Fecha,
                          name: "Fecha",
                          id: "Fecha",
                          type: "datetime-local",
                          //error: this.state.errorToken,
                          //success: !this.state.errorToken
                        }}
                      />
                    </td>
                    <td>
                  <Button tabIndex="2" size='sm' color='success' className='pull-right' round onClick={() => this.setState({showProspecto:true})}><Save />&nbsp;Prospecto</Button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Motivo:</td>
                    <td colSpan={2}>
                      <FormControl
                        fullWidth
                        // style={{ width: "22em" }}
                      >
                        <Select
                          value={this.state.AqueViene}
                          onChange={this.handleChange}
                          defaultValue={"Seleccione"}
                          name="AqueViene"
                        >
                        <MenuItem value={"null"} disabled={true}>Seleccione Motivo</MenuItem>
                          <MenuItem value={"Asesoria"} >Asesoría</MenuItem>
                          <MenuItem value={"Examen de ubicacion"}>Examen de ubicación</MenuItem>
                          <MenuItem value={"Examen TOEFL"}>Examen TOEFL</MenuItem>
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Modalidad:</td>
                    <td colSpan={2}>
                      <FormControl
                        fullWidth
                        // style={{ width: "22em" }}
                      >
                        <Select
                          value={this.state.Modalidad}
                          onChange={this.handleChange}
                          defaultValue={"Seleccione"}
                          name="Modalidad"
                        >
                          <MenuItem value={"null"} disabled={true}>Seleccione modalidad</MenuItem>
                          <MenuItem value={"En_linea"}>En linea</MenuItem>
                          <MenuItem value={"Presencial"}>Presencial</MenuItem>
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold"}}> Idioma:</td>
                    <td colSpan={2}>
                    <FormControl
                        fullWidth
                        // style={{ width: "22em" }}
                        >
                        <Select
                            value={this.state.Idioma}
                            onChange={this.handleChange}         
                            defaultValue={"Seleccione"}
                            name="Idioma"  
                        >
                          <MenuItem value={"null"} disabled={true}>seleccionar idioma</MenuItem> 
                        {this.state.ListaIdiomas.length == 0 ? null :
                            this.state.ListaIdiomas.map((idioma) => {
                                return (<MenuItem value={JSON.stringify(idioma._id)} >{idioma.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold"}}> Aula:</td>
                    <td colSpan={2}>
                    <FormControl
                        fullWidth
                        // style={{ width: "22em" }}
                        >
                        <Select
                            value={this.state.Aula}
                            onChange={this.handleChange}       
                            name="Aula"  
                            defaultValue={"Seleccione"}
                        >
                          <MenuItem value={"null"} disabled={true}>seleccionar aula</MenuItem> 
                        {this.state.ListaAulas.length == 0 ? null :
                            this.state.ListaAulas.map((aula) => {
                                return (<MenuItem value={JSON.stringify(aula._id)} >{aula.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold"}}> Alumno:</td>
                    <td colSpan={1} className="align-middle">
                      <Autocomplete
                        //freeSolo
                        id="NombreMaestro"
                        noOptionsText={'No hay opciones'}
                        disableClearable
                        value={this.state.Alumno}
                        inputValue={this.state.NombreAlumno}
                        onChange={this.handleChangeAutocompleteAlumno.bind(this)}
                        onInputChange={this.InputChangeAlumno.bind(this)}
                        options={this.state.ListaAlumnos}
                        getOptionLabel={(option) => option.NombreCompleto}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Nombre del maestro"
                            margin="normal"
                            size="small"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                              name: "NombreMaestro",
                              id: "NombreMaestro",
                              //error: this.state.errorNombre,
                              //success: !this.state.errorNombre
                            }}
                          />
                        )}
                      />
                    </td>
                    <td>
                    <Checkbox onChange={(e) => this.setState({BuscarProspectos: !this.state.BuscarProspectos})} name="checkedindiv" checked={this.state.BuscarProspectos}> </Checkbox>Buscar prospectos
                    {/* <Button hidden={this.state.BuscarProspectos} size='sm' color='info' className='pull-right' round onClick={() => this.setState({BuscarProspectos: true})}><Search/>&nbsp;cambiar a prospectos</Button>
                    <Button hidden={!this.state.BuscarProspectos} size='sm' color='warning' className='pull-right' round onClick={() => this.setState({BuscarProspectos: false})}><Search/>&nbsp;Buscar Alumno</Button> */}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold"}}> Maestro: </td>
                    <td colSpan={2} className="align-middle">
                      <Autocomplete
                        //freeSolo
                        id="NombreMaestro"
                        noOptionsText={'No hay opciones'}
                        // style={{ maxWidth: "25em" }}
                        disableClearable
                        value={this.state.Maestro}
                        inputValue={this.state.NombreMaestro}
                        onChange={this.handleChangeAutocompleteMaestro.bind(this)}
                        onInputChange={this.InputChangeMaestro.bind(this)}
                        options={this.state.ListaMaestros}
                        getOptionLabel={(option) => option.NombreCompleto}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Nombre del maestro"
                            margin="normal"
                            size="small"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                              name: "NombreMaestro",
                              id: "NombreMaestro",
                              //error: this.state.errorNombre,
                              //success: !this.state.errorNombre
                            }}
                          />
                        )}
                      />
                    </td>
                    {/* <td>
                    <Button tabIndex="2" size='sm' color='info' className='pull-right' round onClick={() => this.setState({showMaestros: true})}><Search/>&nbsp;Buscar</Button>
                    </td> */}
                  </tr>
                </tbody>
              </table>

              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={8}>
                  <Button tabIndex="2" size='sm' color='' className='pull-right ' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;Cerrar</Button>
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <Button tabIndex="2" size='sm' color='success' className='pull-right' round onClick={() => this.FormValido()}><Save />&nbsp;Guardar</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
        {agregar}
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.ColorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalInfoVenta);