// core components/views for Admin layout
//import Egresos from "views/Modulos/IngEgr/Egresos/Egresos.jsx";
//import Ingresos from "views/Modulos/IngEgr/Ingresos/Ingresos.jsx";
//import CrearProspectos from "views/Modulos/Prospectos/CrearProspectos.jsx";
import MainProspectos from "views/Modulos/Prospectos/Prospectos.jsx";

import MenuPrincipal from '../views/MenuPrincipal/MenuPrincipal';
import DashboardPage from "views/Dashboard/Dashboard.jsx";

// @material-ui/icons
import { Close, PersonAdd, People, AttachFile, AccountBalance, AttachMoney, Timeline, Block, LocalOffer, LinearScale, BarChart, Whatshot, MergeType, Home, Menu, WalletMembership, Contacts, CreditCard } from "@material-ui/icons";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [
  {
    path: "/Prospectos",
    name: "Prospectos",
    icon: PersonAdd,
    component: MainProspectos,
    layout: "/prospectos",
    idModulo: "1"
  },
  // {
  //   path: "/AñadirProspectos",
  //   name: "Añadir prospectos",
  //   icon: PersonAdd,
  //   component: CrearProspectos,
  //   layout: "/prospectos",
  //   idModulo: "5d08217a198ea43abc040db4"
  // },
 
]

let Permisos =localStorage.getItem("Permisos") == null ? {Modulo:[]}:JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

// console.log("🌌Permisos ", (Permisos).sort((a, b) => a.NumeroModulo - b.NumeroModulo))
var dashboardRoutesAccess = [];

for (var keyRoutes = 0; keyRoutes < dashboardRoutes.length; keyRoutes++) {
  if (Permisos.find(permiso => permiso.NumeroModulo == dashboardRoutes[keyRoutes].idModulo)) {
    dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
  }
}

export default dashboardRoutesAccess;