import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Close, Clear, Search, Check, ArrowForward, ArrowForwardIos, Add } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie';
import ModalCargando from "../../../components/Generales/ModalCargando";
import Autocomplete from '@material-ui/lab/Autocomplete';

var Permisos = localStorage.getItem("Permisos") == null ? { Modulo:[] } : JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}
else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}

class ModalRegistrarGasto extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal:false,
      GastoSeleccionado: {},
      _id: '',
      objProducto: {},
      ListaEmpresas: [],
      IDEmpresa: '',
      IdDefault: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      ListaTipoGasto: [],
      TipoGastoSeleccionado: '',
      ListaTipoSubGasto: [],
      SubtipoGastoSeleccionado: '',
      ListaProveedor: [],
      ProveedorSeleccionado: '',
      DescripcionProducto: "",
      DescripcionGasto: "",
      PrecioProducto: 0,
      PrecioProductoTemp: 0,
      PrecioTotal: 0,
      CantidadProd: 1,
      ListaProductos: [],
      FechaPago: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      opcionTipoPago: "Efectivo",
      opcionOrigenGasto: "Caja",
      opcionTipoRecibo: "Factura",
      Subtotal: 0,
      IVA: 0,
      RetencionIVA: 0,
      RetencionIRS: 0,
      Total: 0,
      checked: false,
      ProductoSeleccionado: {},
      ProductosProveedor:[],
      errorDescripcionProducto: false,
      errorPrecioProductoTemp: false,
      errorEmpresa: false,
      errorProductos: false,
      errorDescrGasto: false,
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      cargando: false,
      CuerpoCargando: "Cargando",
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  componentDidMount() {
    if(this.props.op == 1){
      // console.log(this.props.GastoSeleccionado)
      const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
      let producto;
      let aux = [];
      if(this.props.GastoSeleccionado.Productos !== undefined && this.props.GastoSeleccionado.Productos !== null){
        if(this.props.GastoSeleccionado.Productos.ProductoProveedor.length > 1){
          this.props.GastoSeleccionado.Productos.ProductoProveedor.map(prod => {
          producto = {
            AgregarInventario: prod.AgregarInventario === "false" ? false : true,
            DescripcionProducto: prod.DescripcionProducto,
            IdProducto: prod.IdProducto,
            Precio: parseFloat(prod.Precio),
            TotalPagar: parseFloat(prod.TotalPagar),
            TotalProductos: parseInt(prod.TotalProductos)
            }
            aux.push(producto);
          })
        }
        else{
          producto = {
            AgregarInventario: this.props.GastoSeleccionado.Productos.ProductoProveedor.AgregarInventario === "false" ? false : true,
            DescripcionProducto: this.props.GastoSeleccionado.Productos.ProductoProveedor.DescripcionProducto,
            IdProducto: this.props.GastoSeleccionado.Productos.ProductoProveedor.IdProducto,
            Precio: parseFloat(this.props.GastoSeleccionado.Productos.ProductoProveedor.Precio),
            TotalPagar: parseFloat(this.props.GastoSeleccionado.Productos.ProductoProveedor.TotalPagar),
            TotalProductos: parseInt(this.props.GastoSeleccionado.Productos.ProductoProveedor.TotalProductos)
            }
            aux.push(producto);
        }
      }

      //console.log(aux)
      

      this.setState({
        GastoSeleccionado: this.props.GastoSeleccionado,
        ListaProductos: aux,
        DescripcionGasto: this.props.GastoSeleccionado.Descripcion,
        FechaPago: moment(this.props.GastoSeleccionado.FechaPago).format("YYYY-MM-DD"),
        opcionTipoPago: this.props.GastoSeleccionado.TipoPago,
        opcionOrigenGasto: this.props.GastoSeleccionado.OrigenEfectivo,
        opcionTipoRecibo: this.props.GastoSeleccionado.Comprobante,
        Subtotal: parseFloat(this.props.GastoSeleccionado.Subtotal),
        IVA: parseFloat(this.props.GastoSeleccionado.IVA),
        RetencionIVA: parseFloat(this.props.GastoSeleccionado.RetencionIVA),
        RetencionIRS: parseFloat(this.props.GastoSeleccionado.RetencionISR),
        Total: parseFloat(this.props.GastoSeleccionado.Monto),
        disabled: this.ValidarModulo(71) ? false : true
      })

      this.obtenerEmpresasActivas(this.props.GastoSeleccionado._idEmpresa);
      
      if(JSON.stringify(this.props.GastoSeleccionado.TipoGasto) !== JSON.stringify(idVacio)){
        this.TipoGastoListado(this.props.GastoSeleccionado.TipoGasto);
      }
      else{
        this.TipoGastoListado(0);
      }
      
      if(JSON.stringify(this.props.GastoSeleccionado.SubtipoGasto) !== JSON.stringify(idVacio)){
        this.TipoSubGastoListado(this.props.GastoSeleccionado.TipoGasto, this.props.GastoSeleccionado.SubtipoGasto)
      }

      if(JSON.stringify(this.props.GastoSeleccionado._idProveedor) !== JSON.stringify(idVacio)){
        this.ListaGeneralProveedores(this.props.GastoSeleccionado._idProveedor);
      }
      else{
        this.ListaGeneralProveedores(0);
      }
        
    }else{
      this.obtenerEmpresasActivas(0);
      this.TipoGastoListado(0);
      this.ListaGeneralProveedores(0);
    }
  }

  async obtenerEmpresasActivas(idEmpresa) {
    this.setState({cargando:true})
    await fetch(Globales.Url + 'Facturacion/Facturacion/ListadoEmpresas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, cargando:false });
          this.showNotification();
        } else { //Filtrado exitoso
          if(idEmpresa !== 0){
            if (data.ListaEmpresas.length > 1) {
              data.ListaEmpresas.map(empresa => {
                if(JSON.stringify(empresa._id) == JSON.stringify(idEmpresa)){
                  this.setState({ ListaEmpresas: data.ListaEmpresas, IDEmpresa: empresa, Activo: 1, cargando:false });
                }
              })   
            }
            else {
              if(JSON.stringify(data.ListaEmpresas._id) == JSON.stringify(idEmpresa)){
                let ListaAuxEmpresas = [];
                ListaAuxEmpresas.push(data.ListaEmpresas);
                //console.log(ListaAux);
                this.setState({ ListaEmpresas: ListaAuxEmpresas, IDEmpresa: data.ListaEmpresas, Activo: 1, cargando:false });
              }
            }
          }
          else{
            if (data.ListaEmpresas.length > 1) {
            this.setState({ ListaEmpresas: data.ListaEmpresas, Activo: 1, cargando:false });
            }
            else {
              let ListaAuxEmpresas = [];
              ListaAuxEmpresas.push(data.ListaEmpresas);
              //console.log(ListaAux);
              this.setState({ ListaEmpresas: ListaAuxEmpresas, Activo: 1, cargando:false });
            }
          }
          
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

async TipoGastoListado(idTipoGasto) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Gastos/Gastos/TipoGastoListado', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              let id = "";
              if(idTipoGasto !== 0){
                if (data.ListaTipoGasto.length > 1) {
                  data.ListaTipoGasto.map(tipo => {
                    if(JSON.stringify(tipo._id) == JSON.stringify(idTipoGasto)){
                      id = tipo
                    }
                  })

                  this.setState({ ListaTipoGasto: data.ListaTipoGasto, TipoGastoSeleccionado: id, Activo: 1, cargando: false });
                }
                else {
                  let ListaAux = [];
                  if(JSON.stringify(data.ListaTipoGasto._id) == JSON.stringify(idTipoGasto)){
                    ListaAux.push(data.ListaTipoGasto);
                    id = data.ListaTipoGasto;

                  }
                  this.setState({ ListaTipoGasto: ListaAux, TipoGastoSeleccionado: id, Activo: 1, cargando: false });
                }
              }
              else{
                if (data.ListaTipoGasto.length > 1) {
                    this.setState({ ListaTipoGasto: data.ListaTipoGasto, Activo: 1, cargando: false });
                }
                else {
                    let ListaAux = [];
                    ListaAux.push(data.ListaTipoGasto);
                    //console.log(ListaAux);
                    this.setState({ ListaTipoGasto: ListaAux, Activo: 1, cargando: false });
                }
              }
                
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async TipoSubGastoListado(idGasto, idSubGasto) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Gastos/Gastos/TipoSubGastoListado', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({id: idGasto})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                //this.showNotification();
            } else { //Filtrado exitoso
              let Subtipo = "";
              if(idSubGasto !== 0){
                if (data.ListaTipoSubGasto.length > 1) {
                  data.ListaTipoSubGasto.map(subtipo => {
                    if(JSON.stringify(subtipo._id) == JSON.stringify(idSubGasto)){
                      Subtipo =subtipo;
                    }
                  })

                  this.setState({ ListaTipoSubGasto: data.ListaTipoSubGasto, SubtipoGastoSeleccionado: Subtipo, Activo: 1, cargando: false });
                }
                else {
                  let ListaAux = [];
                  if(JSON.stringify(data.ListaTipoSubGasto._id) == JSON.stringify(idSubGasto)){
                    ListaAux.push(data.ListaTipoSubGasto);
                    Subtipo = data.ListaTipoSubGasto;
                  }

                  this.setState({ ListaTipoSubGasto: ListaAux, SubtipoGastoSeleccionado: Subtipo, Activo: 1, cargando: false });
                }
              }
              else{
                if (data.ListaTipoSubGasto.length > 1) {
                    this.setState({ ListaTipoSubGasto: data.ListaTipoSubGasto, Activo: 1, cargando: false });
                }
                else {
                    let ListaAux = [];
                    ListaAux.push(data.ListaTipoSubGasto);
                    //console.log(ListaAux);
                    this.setState({ ListaTipoSubGasto: ListaAux, Activo: 1, cargando: false });
                }
              }
                
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ListaGeneralProveedores(IdProveedor) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Gastos/Gastos/ListaGeneralProveedores', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              let prov = "";

              if(IdProveedor !== 0){
                if (data.ListaProveedor.length > 1) {
                  data.ListaProveedor.map(proveedor => {
                    if(JSON.stringify(proveedor._id) == JSON.stringify(IdProveedor)){
                      prov = proveedor;
                    }
                  })

                  this.setState({ ListaProveedor: data.ListaProveedor, ProveedorSeleccionado: prov, Activo: 1, cargando: false });
                }
                else {
                  let ListaAux = [];
                  if(JSON.stringify(data.ListaProveedor._id) == JSON.stringify(IdProveedor)){
                    ListaAux.push(data.ListaProveedor);

                    prov = data.ListaProveedor;
                  }

                  this.setState({ ListaProveedor: ListaAux, ProveedorSeleccionado: prov, Activo: 1, cargando: false });
                }
              }
              else{
                if (data.ListaProveedor.length > 1) {
                    this.setState({ ListaProveedor: data.ListaProveedor, Activo: 1, cargando: false });
                }
                else {
                    let ListaAux = [];
                    ListaAux.push(data.ListaProveedor);
                    //console.log(ListaAux);
                    this.setState({ ListaProveedor: ListaAux, Activo: 1, cargando: false });
                }
              }
                
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

AgregarProducto(){
  let valor1 = 0;
  let valor2 = 0;
  const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

  if(this.state.PrecioProductoTemp == 0){
    valor2 = 1;
    this.setState({errorPrecioProductoTemp: true, mensaje: "Proporcione un precio valido", colorNotificacion: "danger"});
    this.showNotification();
  }
  else{
    valor2 = 0;
    this.setState({errorPrecioProductoTemp: false});
  }

  if(this.state.DescripcionProducto == ""){
    valor1 = 1;
    this.setState({errorDescripcionProducto: true, mensaje: "Proporcione una descripción", colorNotificacion: "danger"});
    this.showNotification();
  }
  else{
    valor1 = 0;
    this.setState({errorDescripcionProducto: false});
  }


  if(valor1 + valor2 == 0){
    let producto = {
    AgregarInventario: false,
    DescripcionProducto: this.state.DescripcionProducto,
    IdProducto: idVacio,
    Precio: this.state.PrecioProducto,
    TotalPagar: this.state.PrecioProducto,
    TotalProductos: 1
    }

    this.setState({
      ListaProductos: [...this.state.ListaProductos, producto],
      Subtotal: parseFloat(this.state.Subtotal) + parseFloat(this.state.PrecioProducto),
      Total: parseFloat(this.state.Total) + parseFloat(this.state.PrecioProducto),
      DescripcionProducto: "",
      PrecioProductoTemp: 0,
      PrecioProducto: 0,
    });
  }
}

EliminarProducto(prod){
  let index
  index = this.state.ListaProductos.indexOf(prod);
  this.state.ListaProductos.splice(index, 1);

  this.setState({ListaProductos: this.state.ListaProductos, Subtotal: parseFloat(this.state.Subtotal) - parseFloat(prod.TotalPagar), Total: parseFloat(this.state.Total) - parseFloat(prod.TotalPagar)});
}

CrearObjProducto(){
  let valor1 = 0;
  let valor2 = 0;
  let valor3 = 0;
  let descripcion = "";
  let IDcookie = JSON.parse(cookie.get('id'));
  const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

  if(this.props.op == 1){
    if(this.state.GastoSeleccionado.Productos !== undefined && this.state.GastoSeleccionado.Productos !== null){
      if(this.state.ListaProductos.length == 0){
        valor2 = 1;
        this.setState({errorProductos: true, mensaje: "Agregue productos o servicios a la lista", colorNotificacion: "danger"})
        this.showNotification();
      }
      else{
        valor2 = 0;
        this.setState({errorProductos: false})
      }
    }
    else{
      if(this.state.DescripcionGasto == ""){
        valor3 = 1;
        this.setState({errorDescrGasto: true, mensaje: "Proporcione una descripcion", colorNotificacion: "danger"});
        this.showNotification();
      }
      else{
        valor3 = 0;
        this.setState({errorDescrGasto: false});
      }
    }
  }
  else{
    if(this.state.ListaProductos.length == 0){
      valor2 = 1;
      this.setState({errorProductos: true, mensaje: "Agregue productos o servicios a la lista", colorNotificacion: "danger"})
      this.showNotification();
    }
    else{
      valor2 = 0;
      this.setState({errorProductos: false})
    }
  }

  if(this.state.IDEmpresa == ''){
    valor1 = 1;
    this.setState({errorEmpresa: true, mensaje: "Seleccione una empresa", colorNotificacion: "danger"})
    this.showNotification();
  }
  else{
    valor1 = 0;
    this.setState({errorEmpresa: false})
  }

  if(valor1 + valor2 + valor3 == 0){   
    if(this.props.op == 1){

      if(this.state.GastoSeleccionado.Productos == undefined && this.state.GastoSeleccionado.Productos == null){
        descripcion = this.state.DescripcionGasto;
      }
      else{
        descripcion =  this.state.ListaProductos.map(prod => {
          return prod.DescripcionProducto
        }).join(', ');
      }

      this.setState({
        objProducto: {
          CancelacionAfectaEfectivo: false,
          Comprobante: this.state.opcionTipoRecibo,
          Descripcion: descripcion,
          Estado: "Pagado",
          FechaCancelacion: "0001-01-01",
          FechaPagado: this.state.GastoSeleccionado.FechaPagado,
          FechaPago: this.state.FechaPago,
          FechaRegistro: this.state.GastoSeleccionado.FechaRegistro,
          IVA: this.state.IVA,
          IdUsuario: this.state.GastoSeleccionado.IdUsuario,
          IdUsuarioCancela: this.state.GastoSeleccionado.IdUsuarioCancela,
          Monto: this.state.Total,
          NombreEmpresa: this.state.IDEmpresa.RazonSocial,
          NombreProveedor: this.state.ProveedorSeleccionado !== '' ? this.state.ProveedorSeleccionado.Razonsocial : null,
          NombreSubtipoGasto: this.state.SubtipoGastoSeleccionado !== '' ? this.state.SubtipoGastoSeleccionado.Nombre : null,
          NombreTipoGasto: this.state.TipoGastoSeleccionado !== '' ? this.state.TipoGastoSeleccionado.Nombre : null,
          OrigenEfectivo: this.state.opcionOrigenGasto,
          Productos: {ProductoProveedor: this.state.ListaProductos},
          RetencionISR: this.state.RetencionIRS,
          RetencionIVA: this.state.RetencionIVA,
          SubtipoGasto: this.state.SubtipoGastoSeleccionado !== '' ? this.state.SubtipoGastoSeleccionado._id : idVacio,
          Subtotal: this.state.Subtotal,
          TipoGasto: this.state.TipoGastoSeleccionado !== '' ? this.state.TipoGastoSeleccionado._id : idVacio,
          TipoPago: this.state.opcionTipoPago,
          _id: this.state.GastoSeleccionado._id,
          _idEmpresa: this.state.IDEmpresa._id,
          _idProveedor: this.state.ProveedorSeleccionado !== '' ? this.state.ProveedorSeleccionado._id : idVacio,
        }
      }, () => this.RegistrarGasto())
    }
    else{

      descripcion =  this.state.ListaProductos.map(prod => {
        return prod.DescripcionProducto
      }).join(', ');

      this.setState({
      objProducto: {
        CancelacionAfectaEfectivo: false,
        Comprobante: this.state.opcionTipoRecibo,
        Descripcion: descripcion,
        Estado: "Pagado",
        FechaCancelacion: "0001-01-01",
        FechaPagado: this.state.FechaPago,
        FechaPago: this.state.FechaPago,
        FechaRegistro: moment(new Date()).format("YYYY-MM-DD"),
        IVA: this.state.IVA,
        IdUsuario: IDcookie,
        IdUsuarioCancela: idVacio,
        Monto: this.state.Total,
        NombreEmpresa: this.state.IDEmpresa.RazonSocial,
        NombreProveedor: this.state.ProveedorSeleccionado !== '' ? this.state.ProveedorSeleccionado.Razonsocial : null,
        NombreSubtipoGasto: this.state.SubtipoGastoSeleccionado !== '' ? this.state.SubtipoGastoSeleccionado.Nombre : null,
        NombreTipoGasto: this.state.TipoGastoSeleccionado !== '' ? this.state.TipoGastoSeleccionado.Nombre : null,
        OrigenEfectivo: this.state.opcionOrigenGasto,
        Productos: {ProductoProveedor: this.state.ListaProductos},
        RetencionISR: this.state.RetencionIRS,
        RetencionIVA: this.state.RetencionIVA,
        SubtipoGasto: this.state.SubtipoGastoSeleccionado !== '' ? this.state.SubtipoGastoSeleccionado._id : idVacio,
        Subtotal: this.state.Subtotal,
        TipoGasto: this.state.TipoGastoSeleccionado !== '' ? this.state.TipoGastoSeleccionado._id : idVacio,
        TipoPago: this.state.opcionTipoPago,
        _idEmpresa: this.state.IDEmpresa._id,
        _idProveedor: this.state.ProveedorSeleccionado !== '' ? this.state.ProveedorSeleccionado._id : idVacio,
      }
    }, () => this.RegistrarGasto())
    }   
  }
}

async RegistrarGasto() {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Gastos/Gastos/RegistrarGasto', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Producto: this.state.objProducto})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              this.setState({cargando: false});
              this.RegresarMensaje(data.mensaje);
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ListaProductosBusquedaFiltradaPorProveedor(Texto) {
  const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
  //this.setState({cargando: true});
  await fetch(Globales.Url + 'Gastos/Gastos/ListaProductosBusquedaFiltradaPorProveedor', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        idProveedor: this.state.ProveedorSeleccionado !== '' ? this.state.ProveedorSeleccionado._id : idVacio,
        Texto: Texto
      })
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, ProductosProveedor: [], colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
            if(data.ProductosProveedor.length > 1){
              this.setState({ProductosProveedor: data.ProductosProveedor, cargando: false})
            }
            else{
              let aux = [];
              aux.push(data.ProductosProveedor);
              this.setState({ProductosProveedor: aux, cargando: false})
            }
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje) {
    this.props.parentCallback(mensaje);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value, checked } = e.target;
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    let suma = 0;
    let aux;
    
    switch (name){
      case "TipoGastoSeleccionado":
        if(JSON.stringify(value) !== ''){
            this.TipoSubGastoListado(value._id, 0);
            this.setState({ [name]: value });
        }
        else{
            this.setState({ [name]: value, ListaTipoSubGasto: [], SubtipoGastoSeleccionado: idVacio });
        }
        break;

      case "PrecioProductoTemp":
        let precioTemp = value.replace(/[^0-9\.]/g, '');
        this.setState({[name]: precioTemp, PrecioProducto: precioTemp});
        break;

      case "CantidadProd":
        let cantidad = value.replace(/[^0-9]/g, '');
        if(cantidad < 1){
          this.setState({[name]: 1});
        }
        else if(cantidad > 100){
          this.setState({[name]: 100});
        }
        else{
          this.setState({[name]: cantidad});
        }
        break;

      case "IVA":
        let iva = 0;

        if (value !== ""){
          iva = value.replace(/[^0-9\.]/g, '');
          suma = parseFloat(this.state.Subtotal) + parseFloat(iva) - parseFloat(this.state.RetencionIVA) - parseFloat(this.state.RetencionIRS);
          this.setState({[name]: iva, Total: suma})
        }
        else{
          suma = parseFloat(this.state.Subtotal) + parseFloat(iva) - parseFloat(this.state.RetencionIVA) - parseFloat(this.state.RetencionIRS);
          this.setState({[name]: value, Total: suma})
        }
   
        break;

      case "RetencionIVA":
        let RIVA = 0;

        if(value !== ""){
          RIVA = value.replace(/[^0-9\.]/g, '');
          suma = parseFloat(this.state.Subtotal) + parseFloat(this.state.IVA) - parseFloat(RIVA) - parseFloat(this.state.RetencionIRS);
          this.setState({[name]: RIVA, Total: suma})
        }
        else{
          suma = parseFloat(this.state.Subtotal) + parseFloat(this.state.IVA) - parseFloat(RIVA) - parseFloat(this.state.RetencionIRS);
          this.setState({[name]: value, Total: suma})
        }
        break;

      case "RetencionIRS":
        let RIRS = 0;

        if(value !== ""){
          RIRS = value.replace(/[^0-9\.]/g, '');
          suma = parseFloat(this.state.Subtotal) + parseFloat(this.state.IVA) - parseFloat(this.state.RetencionIVA) - parseFloat(RIRS);
          this.setState({[name]: RIRS, Total: suma})
        }
        else{
          suma = parseFloat(this.state.Subtotal) + parseFloat(this.state.IVA) - parseFloat(this.state.RetencionIVA) - parseFloat(RIRS);
          this.setState({[name]: value, Total: suma})
        }

      break;

      case "opcionTipoPago":
        switch(value){
          case "Efectivo":
            this.setState({[name]: value, disabled: false});
            break;
          case "Tarjeta_de_Crédito":
            this.setState({[name]: value, opcionOrigenGasto: "Banco", disabled: true});
            break;
          case "Tarjeta_de_Débito":
            this.setState({[name]: value, opcionOrigenGasto: "Banco", disabled: true});
            break;
          case "Cheque":
            this.setState({[name]: value, opcionOrigenGasto: "Banco", disabled: true});
            break;
          case "Depósito":
            this.setState({[name]: value, disabled: false});
            break;
          case "Transferencia":
            this.setState({[name]: value, opcionOrigenGasto: "Banco", disabled: true});
            break;
        }
        break;

      case "Otros":
        if(checked){
          this.setState({checked: true})
        }
        else{
          this.setState({checked: false})
        }
        break;

      case "ProveedorSeleccionado":
        this.setState({ 
          [name]: value,
          ListaProductos: [],
          Subtotal: 0,
          IVA: 0,
          RetencionIVA: 0,
          RetencionIRS: 0,
          Total: 0
        });
        break;

      default:
        this.setState({ [name]: value });
        break;
    }
  }


//Controla el cambio del TextField 'Cantidad' en los productos
  handleChangeCantidad(e, item){
    const { name, value } = e.target;
    let cantidad = value.replace(/[^0-9]/g, '');
    let productos = this.state.ListaProductos;
    let seleccionado;
    let suma;
    let sumaAux = 0;

    seleccionado = productos.indexOf(item);

      if(cantidad < 1){
        productos[seleccionado].TotalProductos = 1;
        suma = parseFloat(productos[seleccionado].Precio) * 1;
        productos[seleccionado].TotalPagar = suma;

        productos.map(prod => {
          sumaAux += parseFloat(prod.TotalPagar)
        })

        this.setState({ListaProductos: productos, Subtotal: sumaAux, Total: sumaAux})
      }
      else if(cantidad > 100){
        productos[seleccionado].TotalProductos = 100;
        suma = parseFloat(productos[seleccionado].Precio) * 100;
        productos[seleccionado].TotalPagar = suma;

        productos.map(prod => {
          sumaAux += parseFloat(prod.TotalPagar)
        })

        this.setState({ListaProductos: productos, Subtotal: sumaAux, Total: sumaAux})
      }
      else{
        productos[seleccionado].TotalProductos = cantidad;
        suma = parseFloat(productos[seleccionado].Precio) * cantidad;
        productos[seleccionado].TotalPagar = suma;

        productos.map(prod => {
          sumaAux += parseFloat(prod.TotalPagar)
        })

        this.setState({ListaProductos: productos, Subtotal: sumaAux, Total: sumaAux})
      }
  }

  handleChangeEdit(e, item){
    const { name, value } = e.target;
    let productos = this.state.ListaProductos;
    let seleccionado;
    let suma = 0;
    let sumaAux = 0;
    let sumaAuxAux = 0;

    seleccionado = productos.indexOf(item);

    switch(name){
      case "PrecioProd":
        let precio = value.replace(/[^0-9]/g, '');
        if(precio < 1){
          productos[seleccionado].Precio = 1;
          suma = 1 * parseInt(productos[seleccionado].TotalProductos);
          productos[seleccionado].TotalPagar = suma;
    
          productos.map(prod => {
            sumaAux += parseFloat(prod.TotalPagar)
          })
    
          sumaAuxAux = sumaAux + parseFloat(this.state.IVA) - parseFloat(this.state.RetencionIVA) - parseFloat(this.state.RetencionIRS);
    
          this.setState({ListaProductos: productos, Subtotal: sumaAuxAux, Total: sumaAuxAux})
        }
        else{
          productos[seleccionado].Precio = precio;
          suma = precio * parseInt(productos[seleccionado].TotalProductos);
          productos[seleccionado].TotalPagar = suma;
    
          productos.map(prod => {
            sumaAux += parseFloat(prod.TotalPagar)
          })
    
          sumaAuxAux = sumaAux + parseFloat(this.state.IVA) - parseFloat(this.state.RetencionIVA) - parseFloat(this.state.RetencionIRS);
    
          this.setState({ListaProductos: productos, Subtotal: sumaAuxAux, Total: sumaAuxAux})
        }
        break;

      case "DescripcionProd":
        productos[seleccionado].DescripcionProducto = value;

        this.setState({ListaProductos: productos})
        break;
    }
  }

  handleFocus = (event) => {
    const e = event.target;
    // const { name, value } = event.target;

    if(e.name == "PrecioProductoTemp"){
      this.setState({PrecioProductoTemp: this.state.PrecioProducto}, () => e.select())
    }
    else{
      e.select()
    }
  }

  handleBlur = (event) => {
    //console.log(event.target)
    const { name, value } = event.target;
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    if(name == "PrecioProductoTemp"){
      if(isNaN(value)){
        this.setState({[name]: convertirPesos(0), PrecioProducto: 0})
      }
      else if(value == ""){
        this.setState({[name]: convertirPesos(0), PrecioProducto: 0})
      }
      else{
        this.setState({[name]: convertirPesos(value), PrecioProducto: value})
      }
    }
    else{
      if(isNaN(value)){
        this.setState({[name]: 0})
      }
      else if(value == ""){
        this.setState({[name]: 0})
      }
      else{
        this.setState({[name]: value})
      }
    }
    
  }

  handleCheck  = (e, item) => {
    const { checked } = e.target;
    let productos = this.state.ListaProductos;
    let seleccionado;

    if(checked){
      seleccionado = productos.indexOf(item);
      productos[seleccionado].AgregarInventario = true;

      this.setState({ListaProductos: productos})
    }
    else{
      seleccionado = productos.indexOf(item);
      productos[seleccionado].AgregarInventario = false;

      this.setState({ListaProductos: productos})
    }
  }

  handleChangeAutocomplete(e, newValue){

    //console.log("Autocomplete seleccionado", newValue)

    if(newValue !== undefined && newValue !== null){
      
        let producto = {
        AgregarInventario: false,
        DescripcionProducto: newValue.Descripcion,
        IdProducto: newValue._id,
        Precio: newValue.Precio,
        TotalPagar: newValue.Precio,
        TotalProductos: 1
        }
      

      this.setState({
        ListaProductos: [...this.state.ListaProductos, producto],
        Subtotal: parseFloat(this.state.Subtotal) + parseFloat(newValue.Precio),
        Total: parseFloat(this.state.Total) + parseFloat(newValue.Precio),
      })
    }
  }

  InputChange(e, newInputValue){
    const { name, value2 } = e.target;
    if(newInputValue !== '' && this.state.ProveedorSeleccionado !== ''){
      this.ListaProductosBusquedaFiltradaPorProveedor(newInputValue);
    }
   
    //this.setState({[name]: newInputValue});
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  ValidarModulo(modulo){
    let valido = false;

    Permisos.map(permiso => {
      if(parseInt(permiso.NumeroModulo) == modulo){
        valido = true
      }
    })

    return valido
  }

  render() {
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    const { classes } = this.props;
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }
    //console.log(this.state._id)

    let agregar;
    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    };

    let productos;
    let GastoImp = false;
    if(this.props.op == 1){

      if(this.state.GastoSeleccionado.Productos == undefined && this.state.GastoSeleccionado.Productos == null){
        GastoImp = true
      }
      
      if(this.state.ListaProductos.length > 0){
        productos =
          this.state.ListaProductos.map(prod => {
            return(
              <tr style={{cursor: "default"}}>
                <td>
                  <TextField
                      id="CantidadProd"
                      style={{ width: "7em"}}
                      variant="outlined"
                      type="number"
                      size="small"
                      label="Cantidad"
                      disabled={this.state.disabled}
                      onChange={(e) => this.handleChangeCantidad(e, prod)}
                      inputProps={{ maxLength: 3 }}
                      InputProps={{
                        //onChange: this.handleChange.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        value: prod.TotalProductos,
                        name: "CantidadProd",
                        id: "CantidadProd",
                        //error: this.state.errorFechainicio,
                        //success: !this.state.errorFechainicio
                      }}
                  />
                </td>
                <td>
                  <TextField
                      id="DescripcionProd"
                      style={{ width: "10em"}}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Descripción"
                      disabled={this.state.disabled}
                      onChange={(e) => this.handleChangeEdit(e, prod)}
                      InputProps={{
                        //onChange: this.handleChange.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        value: prod.DescripcionProducto,
                        name: "DescripcionProd",
                        id: "DescripcionProd",
                        //error: this.state.errorFechainicio,
                        //success: !this.state.errorFechainicio
                      }}
                  />
                </td>
                <td>
                  <TextField
                      id="PrecioProd"
                      style={{ width: "7em"}}
                      variant="outlined"
                      type="number"
                      size="small"
                      label="Precio"
                      disabled={this.state.disabled}
                      onChange={(e) => this.handleChangeEdit(e, prod)}
                      InputProps={{
                        //onChange: this.handleChange.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        value: prod.Precio,
                        name: "PrecioProd",
                        id: "PrecioProd",
                        //error: this.state.errorFechainicio,
                        //success: !this.state.errorFechainicio
                      }}
                  />
                </td>
                <td>{convertirPesos(prod.TotalPagar)}</td>
                <td><Checkbox onChange={(e) => this.handleCheck(e, prod)} name="CheckIndv" checked={prod.AgregarInventario} disabled={JSON.stringify(prod.IdProducto) == JSON.stringify(idVacio) || !this.ValidarModulo(71) ? true : false}/></td>
                <td><Button disabled={this.state.disabled} data-toggle="tooltip" data-placement="bottom" title="Eliminar producto" color="danger" round justIcon size="sm" className="ml-2" onClick={() => this.EliminarProducto(prod)}><Close /></Button></td>
              </tr>
            )
          })
      }
      else{
        productos =
        <tr>
          <td colSpan={6} style={{textAlign: "center"}}>NINGÚN PRODUCTO AGREGADO</td>
        </tr>
      }
    }
    else{
      GastoImp = false;

      if(this.state.ListaProductos.length > 0){
      productos =
        this.state.ListaProductos.map(prod => {
          return(
            <tr style={{cursor: "default"}}>
              <td>
                <TextField
                    id="CantidadProd"
                    style={{ width: "7em"}}
                    variant="outlined"
                    type="number"
                    size="small"
                    label="Cantidad"
                    onChange={(e) => this.handleChangeCantidad(e, prod)}
                    inputProps={{ maxLength: 3 }}
                    InputProps={{
                      //onChange: this.handleChange.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: prod.TotalProductos,
                      name: "CantidadProd",
                      id: "CantidadProd",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                />
              </td>
              <td>{prod.DescripcionProducto}</td>
              <td>{convertirPesos(prod.Precio)}</td>
              <td>{convertirPesos(prod.TotalPagar)}</td>
              <td><Checkbox disabled={JSON.stringify(idVacio) === JSON.stringify(prod.IdProducto)} onChange={(e) => this.handleCheck(e, prod)} name="CheckIndv" checked={prod.AgregarInventario}/></td>
              <td><Button data-toggle="tooltip" data-placement="bottom" title="Eliminar producto" color="danger" round justIcon size="sm" className="ml-2" onClick={() => this.EliminarProducto(prod)}><Close /></Button></td>
            </tr>
          )
        })
      }
      else{
        productos =
        <tr>
          <td colSpan={6} style={{textAlign: "center"}}>NINGÚN PRODUCTO AGREGADO</td>
        </tr>
      }
    }
    

    //console.log(this.state.ListaProductos)
    //console.log(this.state.Total)
    //console.log(this.state.TipoGastoSeleccionado)
    //console.log(this.state.SubtipoGastoSeleccionado)
    //console.log(this.state.ProveedorSeleccionado)
    //console.log(this.state.IDEmpresa)
    // console.log(this.props.GastoSeleccionado)
    //console.log(this.state.ProductosProveedor)
    // console.log(this.state.objProducto)
    return (
      <div>
        <Modal
          isOpen={this.props.showModalRegistrarGasto}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">Información general del gasto</CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl} size="small" variant="outlined">
                <InputLabel style={{marginTop: "10px"}} id="tipolabel">Tipo de gasto</InputLabel>
                    <Select
                        labelId="tipolabel"
                        //fullWidth={true}
                        value={this.state.TipoGastoSeleccionado}
                        onChange={this.handleChange.bind(this)}
                        name="TipoGastoSeleccionado"
                        //fullWidth={true}
                        label="Tipo de gasto"
                        style={{ width: "22em", marginTop: "10px" }}
                        disabled={this.state.disabled}
                        //error={this.state.errorIDIdioma}
                        //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione un tipo de gasto</MenuItem>
                        {this.state.ListaTipoGasto.map(tipo =>
                        <MenuItem value={tipo}>{(tipo.Nombre).toUpperCase()}</MenuItem>
                        )}
                    </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl} size="small" variant="outlined">
                <InputLabel style={{marginTop: "10px"}} id="tipolabel">Subtipo de gasto</InputLabel>
                    <Select
                        labelId="tipolabel"
                        //fullWidth={true}
                        value={this.state.SubtipoGastoSeleccionado}
                        onChange={this.handleChange.bind(this)}
                        name="SubtipoGastoSeleccionado"
                        //fullWidth={true}
                        label="Subtipo de gasto"
                        style={{ width: "22em", marginTop: "10px" }}
                        disabled={this.state.disabled}
                        //error={this.state.errorIDIdioma}
                        //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione un subtipo de gasto</MenuItem>
                        {this.state.ListaTipoSubGasto.map(subtipo =>
                        <MenuItem value={subtipo}>{(subtipo.Nombre).toUpperCase()}</MenuItem>
                        )}
                    </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl} size="small" variant="outlined">
                <InputLabel style={{marginTop: "10px"}} id="proveedorlabel">Proveedor</InputLabel>
                    <Select
                        labelId="proveedorlabel"
                        //fullWidth={true}
                        value={this.state.ProveedorSeleccionado}
                        onChange={this.handleChange.bind(this)}
                        name="ProveedorSeleccionado"
                        //fullWidth={true}
                        label="Proveedor"
                        style={{ width: "22em", marginTop: "10px" }}
                        disabled={this.state.disabled}
                        //error={this.state.errorIDIdioma}
                        //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione un proveedor</MenuItem>
                        {this.state.ListaProveedor.map(proveedor =>
                        <MenuItem value={proveedor}>{(proveedor.Razonsocial).toUpperCase()}</MenuItem>
                        )}
                    </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl} size="small" variant="outlined">
                <InputLabel style={{marginTop: "10px"}} id="empresalabel">Empresa</InputLabel>
                    <Select
                        labelId="empresalabel"
                        //fullWidth={true}
                        value={this.state.IDEmpresa}
                        onChange={this.handleChange.bind(this)}
                        name="IDEmpresa"
                        //fullWidth={true}
                        label="Empresa"
                        style={{ width: "22em", marginTop: "10px" }}
                        error={this.state.errorEmpresa}
                        success={!this.state.errorEmpresa}
                        disabled={this.state.disabled}
                    >
                      <MenuItem disabled>Seleccione una empresa</MenuItem>
                        {this.state.ListaEmpresas.map(Empresa =>
                        <MenuItem value={Empresa}>{(Empresa.RazonSocial).toUpperCase()}</MenuItem>
                        )}
                    </Select>
                </FormControl>
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>  
          <Card>
            <CardHeader color="info">{GastoImp == false ? "Detalle de productos y/o servicios" : "Gasto importado"}</CardHeader>
            {GastoImp == false ? <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <Autocomplete
                    //freeSolo
                    id="NombreProducto"
                    noOptionsText={'No hay opciones'}
                    style={{ width: "25em" }}
                    //disableClearable
                    value={this.state.ProductoSeleccionado}
                    onChange={this.handleChangeAutocomplete.bind(this)}
                    onInputChange={this.InputChange.bind(this)}
                    options={this.state.ProductosProveedor}
                    getOptionLabel={(option) => option.Descripcion}
                    disabled={this.state.disabled}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Producto"
                        margin="normal"
                        size="small"
                        variant="outlined"
                        InputProps={{ 
                          ...params.InputProps, 
                          type: 'search',
                          name: "NombreProducto",
                          id: "NombreProducto",
                          //error: this.state.errorNombre,
                          //success: !this.state.errorNombre
                        }}
                      />
                    )}
                  />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7}>
                  <FormControlLabel
                  style={{marginTop: "15px", marginLeft: "25px"}}
                    control={
                      <Checkbox
                      disabled={this.state.disabled}
                        checked={this.state.checked}
                        onChange={this.handleChange.bind(this)}
                        name="Otros"
                        color="primary"
                      />
                    }
                    label="Otro"
                  />
                </GridItem>
                <div hidden={this.state.checked == false}>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="DescripcionProducto"
                    style={{ width: "20em", marginRight: "10px"}}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Descripción"
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.DescripcionProducto,
                        name: "DescripcionProducto",
                        id: "DescripcionProducto",
                        error: this.state.errorDescripcionProducto,
                        success: !this.state.errorDescripcionProducto
                    }}
                  />
                  <TextField
                    id="PrecioProductoTemp"
                    style={{ width: "10em"}}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Precio"
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        value: this.state.PrecioProductoTemp,
                        name: "PrecioProductoTemp",
                        id: "PrecioProductoTemp",
                        error: this.state.errorPrecioProductoTemp,
                        success: !this.state.errorPrecioProductoTemp
                    }}
                  />
                  <Button data-toggle="tooltip" data-placement="bottom" title="Agregar producto" justIcon color="info" tabIndex="1" size='sm' className='ml-3' round onClick={() => this.AgregarProducto()}><Add/></Button>
                </GridItem>
                </div>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <table class="table">
                    <thead>
                      <th>Cantidad</th>
                      <th>Descripción</th>
                      <th>Precio unitario</th>
                      <th>Total</th>
                      <th>Agregar a<br/>inventario</th>
                    </thead>
                    <tbody>
                      {productos}
                    </tbody>
                  </table>
                </GridItem>
              </GridContainer>
            </CardBody> : 
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="DescripcionGasto"
                    style={{ width: "53.5em"}}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Descripción del gasto"
                    disabled={this.state.disabled}
                    onChange={this.handleChange.bind(this)}
                    InputProps={{
                      //onChange: this.handleChange.bind(this),
                      onFocus: this.handleFocus.bind(this),
                      value: this.state.DescripcionGasto,
                      name: "DescripcionGasto",
                      id: "DescripcionGasto",
                      error: this.state.errorDescrGasto,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                    {/* <p><h4>{this.state.GastoSeleccionado.Descripcion}</h4></p> */}
                </GridItem>
              </GridContainer>
            </CardBody>
            }
          </Card>
          <Card>
            <CardHeader color="info">Detalle de pago</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                      id="FechaPago"
                      style={{ width: "25.1em", marginTop: "10px" }}
                      variant="outlined"
                      type="date"
                      format="DD-MM-YYYY"
                      size="small"
                      label="Fecha de pago"
                      disabled={this.state.disabled}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FechaPago,
                          name: "FechaPago",
                          id: "FechaPago",
                          //error: this.state.errorFechainicio,
                          //success: !this.state.errorFechainicio
                      }}
                  />
                </GridItem>
                {this.ValidarModulo(72) ? <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="pagolabel">Tipo de pago</InputLabel>
                    <Select
                        labelId="pagolabel"
                        //fullWidth={true}
                        value={this.state.opcionTipoPago}
                        onChange={this.handleChange.bind(this)}
                        name="opcionTipoPago"
                        //fullWidth={true}
                        label="Tipo de pago"
                        style={{ width: "22em", marginTop: "10px" }}
                        disabled={this.state.disabled}
                        //error={this.state.errorIDIdioma}
                        //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione un tipo de pago</MenuItem>
                        <MenuItem value={"Efectivo"}>Efectivo</MenuItem>
                        <MenuItem value={"Tarjeta_de_Crédito"}>Tarjeta de Crédito</MenuItem>
                        <MenuItem value={"Tarjeta_de_Débito"}>Tarjeta de Débito</MenuItem>
                        <MenuItem value={"Cheque"}>Cheque</MenuItem>
                        <MenuItem value={"Depósito"}>Depósito</MenuItem>
                        <MenuItem value={"Transferencia"}>Transferencia</MenuItem>
                        

                    </Select>
                  </FormControl>
                </GridItem> : ""}
                {this.ValidarModulo(72) ? <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined" disabled={this.state.disabled}>
                  <InputLabel style={{marginTop: "10px"}} id="origenlabel">Origen gasto</InputLabel>
                    <Select
                        labelId="origenlabel"
                        //fullWidth={true}
                        value={this.state.opcionOrigenGasto}
                        onChange={this.handleChange.bind(this)}
                        name="opcionOrigenGasto"
                        //fullWidth={true}
                        label="Origen gasto"
                        style={{ width: "22em", marginTop: "10px" }}
                        disabled={this.state.disabled}
                        //error={this.state.errorIDIdioma}
                        //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione un origen de gasto</MenuItem>
                        <MenuItem value={"Caja"}>Caja</MenuItem>
                        <MenuItem value={"Banco"}>Banco</MenuItem>
                        <MenuItem value={"Caja_Administración"}>Caja Administración</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem> : ""}
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                  <InputLabel style={{marginTop: "10px"}} id="recibolabel">Comprobante</InputLabel>
                    <Select
                        labelId="recibolabel"
                        //fullWidth={true}
                        value={this.state.opcionTipoRecibo}
                        onChange={this.handleChange.bind(this)}
                        name="opcionTipoRecibo"
                        //fullWidth={true}
                        label="Comprobante"
                        style={{ width: "22em", marginTop: "10px" }}
                        disabled={this.state.disabled}
                        //error={this.state.errorIDIdioma}
                        //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem disabled>Seleccione un tipo de recibo</MenuItem>
                    <MenuItem value={"Factura"}>Factura</MenuItem>
                    <MenuItem value={"Recibo"}>Recibo</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridItem xs={12} sm={12} md={12}>
          <table>
            <thead>
              <th>Subtotal</th>
              <th style={{marginLeft: "10px"}}>IVA</th>
              <th style={{marginLeft: "10px"}}>Retención IVA</th>
              <th style={{marginLeft: "10px"}}>Retención IRS</th>
              <th>Total</th>
            </thead>
            <tbody>
              <tr>
                <td>{convertirPesos(this.state.Subtotal)}</td>
                <td>
                <TextField
                    id="IVA"
                    style={{ width: "8em", marginRight: "10px"}}
                    variant="outlined"
                    type="text"
                    size="small"
                    //label="IVA"
                    disabled={this.state.disabled}
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        value: this.state.IVA,
                        name: "IVA",
                        id: "IVA",
                        //error: this.state.errorDescripcionProducto,
                        //success: !this.state.errorDescripcionProducto
                    }}
                  />
                </td>
                <td>
                <TextField
                    id="RetencionIVA"
                    style={{ width: "8em", marginRight: "10px"}}
                    variant="outlined"
                    type="text"
                    size="small"
                    //label="Retención IVA"
                    disabled={this.state.disabled}
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        value: this.state.RetencionIVA,
                        name: "RetencionIVA",
                        id: "RetencionIVA",
                        //error: this.state.errorDescripcionProducto,
                        //success: !this.state.errorDescripcionProducto
                    }}
                  />
                </td>
                <td>
                <TextField
                    id="RetencionIRS"
                    style={{ width: "8em", marginRight: "10px"}}
                    variant="outlined"
                    type="text"
                    size="small"
                    //label="Retención IRS"
                    disabled={this.state.disabled}
                    InputProps={{
                        onChange: this.handleChange.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        value: this.state.RetencionIRS,
                        name: "RetencionIRS",
                        id: "RetencionIRS",
                        //error: this.state.errorDescripcionProducto,
                        //success: !this.state.errorDescripcionProducto
                    }}
                  />
                </td>
                <td>{convertirPesos(this.state.Total)}</td>
              </tr>
            </tbody>
          </table>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
              {this.state.disabled == false ? <Button color="info" tabIndex="1" size='sm' className='pull-right ml-2 my-3' round onClick={() => this.CrearObjProducto()}><Save/>&nbsp;Registrar</Button> : ""}
              <Button tabIndex="1" size='sm' className='pull-right ml-2 my-3' round onClick={() => this.props.eventClick("esc")}><Clear/>&nbsp;Cancelar</Button>
          </GridItem>   
        </Modal>
        <div>
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalRegistrarGasto);